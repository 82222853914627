import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, ButtonGroup, Button, Dropdown } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { useLazyQuery, gql, useReactiveVar, useMutation } from '@apollo/client'
import AdminSessionDetailModal from './AdminSessionDetailModal'
import { useHistory } from 'react-router-dom'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import JobDetailModal from '../schedule/JobDetailModal'
import DateFilter from '../common/DateFilter'
import {
  CheckCircle,
  ClockHistory,
  CaretRight,
  CaretDown,
  CloudArrowDown,
  BarChartLine,
  Funnel,
  PlusCircle,
} from 'react-bootstrap-icons'
import SendInvoiceModal from '../payments/SendInvoiceModal'
import SessionHistoryModal from './SessionHistoryModal'
import toast from 'react-hot-toast'
import LocationModal from '../location/LocationModal'
import { useParams } from 'react-router-dom'
import SessionDetailModal from '../subject_sessions/SessionDetailModal'
import FotomerchantGalleryPasswordModal from '../fotomerchant/FotomerchantGalleryPasswordModal'
import SessionReportModal from './SessionReportModal'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Loading from '../common/Loading'
import { useDownloadFile } from '../../libs/downloadFile'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { useFormik } from 'formik'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import { formatTimezone } from '../../libs/utils'
import moment from 'moment'
import { DateTime } from 'luxon'
import { formatRegion } from '../../libs/utils'

const Sessions = (props) => {
  const {
    couponId,
    subjectGroupId,
    organizationId,
    sessionPackageId,
    detailComponent,
    invoiceRecipient,
    sessionIds,
    sendInvoiceModal,
    invoiceModal,
    hideOrganization,
    showSessions,
  } = props

  const location = useLocation()
  const { toTimezone } = useDateTimeConverter()
  const now = new Date()
  let initialStartDateFilter =
    location.pathname === '/sessions'
      ? new Date(
          Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
        )
      : null

  let initialEndDateFilter =
    location.pathname === '/sessions'
      ? new Date(
          Date.UTC(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 7,
            23,
            59,
            59
          )
        )
      : null

  if (
    initialEndDateFilter &&
    initialEndDateFilter.getMonth() !== now.getMonth()
  ) {
    initialEndDateFilter = new Date(
      Date.UTC(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
    )
  }
  const { downloadAndDeleteFile } = useDownloadFile()
  const history = useHistory()
  const { contact } = useParams()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canCreateSession = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const defaultOrderBy = '-start_date_time'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [filter, setFilter] = useState('all')
  const [sessionHistoryId, setSessionHistoryId] = useState()
  const [displaySessionHistory, setDisplaySessionHistory] = useState(false)
  const [hideSchoolContactFields, setHideSchoolContactFields] = useState(null)
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [viewLocation, setViewLocation] = useState(false)
  const [fotomerchantGalleryPassword, setFotomerchantGalleryPassword] =
    useState()
  const [displaySessions, setDisplaySessions] = useState(
    showSessions ? showSessions : false
  )
  const [checkedSessionIds, setCheckedSessionIds] = useState([])
  const [checkedSessionDownloadIds, setCheckedSessionDownloadIds] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [sessionDetailId, setSessionDetailId] = useState()
  const [showSessionDetailModal, setShowSessionDetailModal] = useState(false)
  const [showSubjectSessionDetailModal, setShowSubjectSessionDetailModal] =
    useState(false)
  const [showJobModal, setShowJobModal] = useState(false)
  const [jobId, setJobId] = useState()
  const [startDateFilter, setStartDateFilter] = useState(initialStartDateFilter)
  const [endDateFilter, setEndDateFilter] = useState(initialEndDateFilter)
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [showChartModal, setShowChartModal] = useState(false)
  const [prevNextDateClick, setPrevNextDateClick] = useState(false)
  const [prevNextStartDateFilter, setPrevNextStartDateFilter] = useState()
  const [prevNextEndDateFilter, setPrevNextEndDateFilter] = useState()

  const settings = useReactiveVar(settingsVar)

  const [downloadSessions] = useMutation(
    gql`
      mutation DownloadSessions($input: DownloadSessionsInput!) {
        downloadSessions(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadSessions.file.fileName,
          data.downloadSessions.file.displayName,
          data.downloadSessions.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )
  const handleSessionCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedSessionIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedSessionIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const [getSubjectGroup] = useLazyQuery(
    gql`
      query SessionsSubjectGroupQuery($id: ID!) {
        subjectGroup(id: $id) {
          organization {
            id
            category
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: subjectGroupId,
      },
      onCompleted: (data) => {
        if (loggedInUser.organizationContacts) {
          let _hideSchoolContactFields = false
          loggedInUser.organizationContacts.edges.forEach((edge) => {
            if (
              edge.node.id === data.subjectGroup.organization.id &&
              edge.node.category === 'SCHOOL'
            ) {
              if (!_hideSchoolContactFields) {
                _hideSchoolContactFields = true
              }
            }
          })
          setHideSchoolContactFields(_hideSchoolContactFields)
        }
      },
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (subjectGroupId && contact) {
      getSubjectGroup()
    }
  }, [subjectGroupId, contact])

  useEffect(() => {
    if (contact && organizationId) {
      let _hideSchoolContactFields = false
      loggedInUser.organizationContacts.edges.forEach((edge) => {
        if (
          edge.node.id === organizationId &&
          edge.node.category === 'SCHOOL'
        ) {
          if (!_hideSchoolContactFields) {
            _hideSchoolContactFields = true
          }
        }
      })
      setHideSchoolContactFields(_hideSchoolContactFields)
    }
  }, [contact, organizationId])

  let tableColumns = [
    {
      Header: 'ID',
      id: 'id',
      accessor: 'recordId',
      serverSort: true,
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'status',
      serverSort: true,
      orderBy: 'start_date_time',
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: 'subject',
      serverSort: true,
      orderBy: 'subject__gaia_user__full_name',
    },
    {
      Header: 'Job',
      id: 'job',
      accessor: 'job',
      serverSort: true,
      orderBy: 'job__name',
    },
    {
      Header: 'Session Package',
      id: 'sessionPackage',
      accessor: 'sessionPackage',
      serverSort: true,
      orderBy: 'session_package__title',
    },
    {
      Header: 'Package Category',
      id: 'packageCategory',
      accessor: 'packageCategory',
      serverSort: true,
      orderBy: 'package_category__name',
    },
    {
      Header: 'Subject Group',
      id: 'subjectGroup',
      accessor: 'subjectGroup',
      serverSort: true,
      orderBy: 'subject_group__name',
    },
    {
      Header: 'Organization',
      id: 'organization',
      accessor: 'organization',
      serverSort: true,
      orderBy: 'organization__name',
    },
    {
      Header: 'Location',
      id: 'location',
      accessor: 'location',
      serverSort: true,
      orderBy: 'job__location__name',
    },
  ]

  if (settings?.sessionStages) {
    tableColumns.splice(1, 0, {
      Header: 'Stage',
      accessor: 'stage',
      id: 'stage',
    })
  }

  if (contact) {
    tableColumns.splice(0, 1)
  }

  if (hideSchoolContactFields) {
    tableColumns.splice(8, 3)
  }
  if (hideOrganization || contact) {
    tableColumns.splice(6, 1)
  }
  if (!sendInvoiceModal && !invoiceModal) {
    tableColumns.splice(
      8,
      0,
      ...[
        {
          Header: 'Booked',
          id: 'booked',
          accessor: 'booked',
          serverSort: true,
          orderBy: 'created',
        },
      ]
    )
    if (canCreateSession) {
      tableColumns.splice(10, 0, {
        Header: 'History',
        accessor: (row) => {
          return (
            <Button
              variant="link"
              onClick={() => {
                setSessionHistoryId(row.id)
                setDisplaySessionHistory(true)
              }}
            >
              <ClockHistory />
            </Button>
          )
        },
      })
    }
  }

  if (canCreateSession) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  sessionsData.sessions.edges.forEach((session) => {
                    if (!checkedSessionDownloadIds.includes(session.node.id)) {
                      appendIds.push(session.node.id)
                    }
                  })
                  setCheckedSessionDownloadIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedSessionDownloadIds([])
                }
              }}
            />
            {checkedSessionDownloadIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedSessionDownloadIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'download',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedSessionDownloadIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  const onTdClick = (cell) => {
    setSessionDetailId(cell.row.original.node.id)
    toggleSessionDetailModal()
  }

  if (
    organizationId &&
    !loggedInUser.permissions.isOrgContact &&
    canCreateSession
  ) {
    tableColumns.splice(tableColumns.length - 1, 0, {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendCheckedSessionIds = []
                  sessionsData.sessions.edges.forEach((session) => {
                    const price = parseFloat(session.node.price)
                    if (
                      price > 0 &&
                      !checkedSessionIds.includes(session.node.id) &&
                      !session.node.billSubject &&
                      !session.node.rescheduled &&
                      !session.node.sessionPackageChanged &&
                      !session.node.cancelled
                    ) {
                      appendCheckedSessionIds.push(session.id)
                    }
                  })
                  setCheckedSessionIds((prevState) => {
                    return [...prevState, ...appendCheckedSessionIds]
                  })
                } else {
                  setCheckedSessionIds([])
                }
              }}
            />
            <div className="mt-1">
              Invoice
              {checkedSessionIds.length > 0 && (
                <span style={{ fontSize: '14px', marginTop: '5px' }}>
                  {' '}
                  ({checkedSessionIds.length})
                </span>
              )}
            </div>
          </Form.Group>
        </>
      ),
      id: 'check',
      accessor: (row) => {
        const price = parseFloat(row.price.replace('$', ''))
        if (
          price > 0 &&
          !row.node.billSubject &&
          !row.node.rescheduled &&
          !row.node.sessionPackageChanged &&
          !row.node.cancelled
        ) {
          const paid = parseFloat(row.paid.replace('$', ''))
          let check
          if (paid >= price) {
            check = (
              <>
                <CheckCircle className="mr-2 mt-1" style={{ color: 'green' }} />
                <small style={{ color: 'green' }}>Invoice Paid</small>
              </>
            )
          } else if (row.node.stripeInvoiceItems?.nodeCount > 0) {
            check = (
              <>
                <CheckCircle
                  className="mr-2 mt-1"
                  style={{ color: '#FFAE42' }}
                />
                <small style={{ color: '#FFAE42' }}>Invoice Sent</small>
              </>
            )
          }
          return (
            <>
              <Form.Group as={ButtonGroup} className="align-items-center">
                <Form.Check
                  className="ml-2 mt-2"
                  type="checkbox"
                  checked={checkedSessionIds.includes(row.id)}
                  onChange={(e) => handleSessionCheck(e, row)}
                />
                {check}
              </Form.Group>
            </>
          )
        }
      },
    })
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedSessionDownloadIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedSessionDownloadIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const toggleSessionDetailModal = () => {
    if (showSessionDetailModal) {
      setSessionDetailId(null)
    }
    if (contact) {
      setShowSubjectSessionDetailModal(!showSubjectSessionDetailModal)
    } else {
      setShowSessionDetailModal(!showSessionDetailModal)
    }
  }

  const toggleJobModal = () => {
    if (showJobModal) {
      setJobId(null)
    }
    setShowJobModal(!showJobModal)
  }
  const handleAddWeek = () => {
    if (startDateFilter) {
      const startDate =
        startDateFilter instanceof DateTime
          ? startDateFilter.toJSDate()
          : startDateFilter
      const firstday = moment(startDate)
        .add(1, 'weeks')
        .startOf('week')
        .format()
      const firstDayDate = new Date(firstday)
      var lastday = moment(startDate).add(1, 'weeks').endOf('week').format()
      const lastDayDate = new Date(lastday)
      setPrevNextStartDateFilter(firstDayDate)
      setPrevNextEndDateFilter(lastDayDate)
      setPrevNextDateClick(true)
    }
  }
  const handleSubtractWeek = () => {
    if (startDateFilter) {
      const startDate =
        startDateFilter instanceof DateTime
          ? startDateFilter.toJSDate()
          : startDateFilter
      const firstday = moment(startDate)
        .subtract(1, 'weeks')
        .startOf('week')
        .format()
      const firstDayDate = new Date(firstday)
      var lastday = moment(startDate)
        .subtract(1, 'weeks')
        .endOf('week')
        .format()
      const lastDayDate = new Date(lastday)
      setPrevNextStartDateFilter(firstDayDate)
      setPrevNextEndDateFilter(lastDayDate)
      setPrevNextDateClick(true)
    }
  }

  const [
    sessionsQuery,
    { error, data: sessionsData, fetchMore: queryFetchMore, loading },
  ] = useLazyQuery(
    gql`
      query SessionsQuery(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $organizationId: ID
        $failedPayments: Boolean
        $sessionPackageId: ID
        $couponId: ID
        $mobile: Boolean
        $subjectGroupId: ID
        $startDateGte: DateTime
        $startDateLte: DateTime
        $cancelled: Boolean
        $live: Boolean
        $sessionIds: [ID]
        $regionIds: [ID]
      ) {
        sessions(
          after: $cursor
          first: 15
          startDateTime_Gte: $startDateGte
          startDateTime_Lte: $startDateLte
          failedPayments: $failedPayments
          orderBy: $orderBy
          mobile: $mobile
          coupon_Id: $couponId
          subjectGroup_Id: $subjectGroupId
          sessionPackage_Id: $sessionPackageId
          organization_Id: $organizationId
          search: $searchTerm
          live: $live
          cancelled: $cancelled
          id: $sessionIds
          region: $regionIds
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              created
              price
              billSubject
              cancelled
              region {
                id
                name
                timezone
              }
              stage {
                name
                color
              }
              latestStripeInvoice {
                voided
                paidManually
              }
              paid
              recordId
              organization {
                id
                name
                category
              }
              stripeInvoiceItems(
                stripeInvoice_Paid: false
                stripeInvoice_Voided: false
                orderBy: "-created"
                first: 1
              ) {
                nodeCount
              }
              packageCategory {
                id
                name
              }
              sessionPackage {
                id
                title
                description
                durationHighMinutes
                durationLowMinutes
              }
              coupon {
                code
                percentSavings
                dollarSavings
              }
              subject {
                id
                gaiaUser {
                  firstName
                  lastName
                  fullName
                  stripeCustomer {
                    stripePaymentMethods(primary: true) {
                      edges {
                        node {
                          stripeId
                          stripeResource
                          created
                        }
                      }
                    }
                  }
                }
              }
              subjectGroup {
                name
                id
              }
              organization {
                name
                id
              }
              startDateTime
              endDateTime
              refundAmount
              job {
                name
                startDateTime
                id
                location {
                  id
                  name
                  addressLineOne
                  addressLineTwo
                  city
                  state
                  zipCode
                  archived
                  latitude
                  mapDefault
                  longitude
                  studio
                  contentType {
                    model
                    id
                  }
                  subject {
                    id
                    gaiaUser {
                      firstName
                      lastName
                    }
                    organization {
                      name
                    }
                  }
                  organization {
                    id
                    name
                  }
                }
              }
              noShow
              cancelled
              updated
              resitScheduled
              rescheduled
              sessionPackageChanged
              completed
              waiveRescheduleCancelFee
              waiveBookingFee
              futuristSession {
                id
                startDateTime
                cancelled
                rescheduled
              }
              subjectGroup {
                id
                name
                endDateTime
                resitsAvailable
              }
              created
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      // pollInterval: 30000,
      fetchPolicy: invoiceModal ? 'no-cache' : 'cache-and-network',
    }
  )

  const formik = useFormik({
    initialValues: {
      regions: loggedInUser?.defaultRegions,
      timezone: loggedInUser?.defaultRegion?.formattedTimezone,
    },
    validateOnChange: false,
  })

  useEffect(() => {
    formik.setFieldValue('regions', loggedInUser?.defaultRegions)
  }, [loggedInUser?.defaultRegions])

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = constructQueryVariables()
      variables.searchTerm = searchValue
      sessionsQuery({ variables })
    },
    [
      organizationId,
      sessionPackageId,
      couponId,
      subjectGroupId,
      orderBy,
      startDateFilter,
      endDateFilter,
      sessionIds,
      filter,
      sessionsQuery,
    ]
  )

  if (formik?.values?.regions?.length > 1) {
    tableColumns.splice(10, 0, {
      Header: 'Region',
      id: 'region',
      accessor: (row) => {
        return <>{formatRegion(row.node.region)}</>
      },
      serverSort: true,
      orderBy: 'region__name',
    })
  }

  const constructQueryVariables = useCallback(() => {
    const variables = {
      cursor: sessionsData?.sessions.pageInfo.endCursor || null,
      organizationId,
      sessionPackageId,
      couponId,
      subjectGroupId,
    }

    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (filter === 'cancelled') {
      variables.cancelled = true
      variables.live = false
    } else {
      variables.live = true
      variables.cancelled = false
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (sessionIds) {
      variables.sessionIds = sessionIds
    }
    if (filter === 'web') {
      variables.mobile = false
    } else if (filter === 'mobile') {
      variables.mobile = true
    }
    if (formik.values.regions.length > 0) {
      variables.regionIds = formik.values.regions.map((region) => region.id)
    }

    return variables
  }, [
    sessionsData?.sessions.pageInfo.endCursor,
    organizationId,
    sessionPackageId,
    couponId,
    subjectGroupId,
    searchTerm,
    filter,
    orderBy,
    startDateFilter,
    endDateFilter,
    sessionIds,
    formik.values.regions,
  ])

  useEffect(() => {
    if (formik.values.regions) {
      const variables = constructQueryVariables()
      variables.cursor = null
      sessionsQuery({ variables })
    }
  }, [formik.values.regions])

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      let variables = constructQueryVariables()
      variables = {
        ...variables,
        cursor: null,
        live: true,
        cancelled: false,
        orderBy: defaultOrderBy,
        startDateGte: initialStartDateFilter,
        startDateLte: initialEndDateFilter,
      }
      sessionsQuery({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        return {
          sessions: {
            ...fetchMoreResult.sessions,
            edges: [...prev.sessions.edges, ...fetchMoreResult.sessions.edges],
            pageInfo: fetchMoreResult.sessions.pageInfo,
          },
        }
      },
    })
  }

  const handleSearchTermChange = useCallback(
    (event) => {
      const currentSearchTerm = event.target.value || null
      setSearchTerm(currentSearchTerm)
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      const newTimeout = setTimeout(() => {
        executeSearchQuery(currentSearchTerm)
      }, 500)

      setDebounceTimeout(newTimeout)
    },
    [executeSearchQuery, debounceTimeout]
  )

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const handleSortByChange = useCallback(
    (currentOrderBy) => {
      if (currentOrderBy === '' && orderBy === defaultOrderBy) return

      currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
      setOrderBy(currentOrderBy)
      const variables = constructQueryVariables()
      variables.cursor = null
      variables.orderBy = currentOrderBy
      sessionsQuery({ variables })
    },
    [constructQueryVariables, sessionsQuery]
  )

  useEffect(() => {
    if (initialQueryRun) {
      const variables = constructQueryVariables()
      variables.cursor = null
      sessionsQuery({ variables })
    }
  }, [startDateFilter, endDateFilter, filter, formik.values.regions])

  const constructCompletedCanResitSession = (sessionNode, timezone) => {
    let action
    const sessionStartDateTimeStr = toTimezone(sessionNode.startDateTime, {
      humanReadableSort: true,
      timezone: timezone,
    })
    const status = `Complete on ${sessionStartDateTimeStr}`
    return {
      status,
      action,
    }
  }

  const construstCurrentSession = (sessionNode) => {
    let status
    let action
    let paid
    const bill = sessionNode.billSubject ? 'Subject' : 'Organization'
    let price = `$${sessionNode.price}`
    if (sessionNode.waiveBookingFee) {
      paid = '$0'
    } else {
      paid = `$${sessionNode.paid}`
    }
    const timezone = formatTimezone(sessionNode.region?.timezone)
    if (sessionNode.noShow) {
      const sessionStartDateTimeStr = toTimezone(sessionNode.startDateTime, {
        humanReadableSort: true,
        timezone: timezone,
      })
      status = `Missed on ${sessionStartDateTimeStr}`
    } else if (sessionNode.completed) {
      if (sessionNode.futuristSession) {
        if (
          !sessionNode.futuristSession.cancelled &&
          !sessionNode.futuristSession.rescheduled
        ) {
          const resitSessionStartDateTimeStr = toTimezone(
            sessionNode.futuristSession.startDateTime,
            { humanReadableSort: true, timezone: timezone }
          )
          status = `Resit on ${resitSessionStartDateTimeStr}`
        } else {
          const statusAction = constructCompletedCanResitSession(
            sessionNode,
            timezone
          )
          status = statusAction.status
        }
      } else {
        const statusAction = constructCompletedCanResitSession(
          sessionNode,
          timezone
        )
        status = statusAction.status
      }
    } else if (sessionNode.cancelled) {
      const cancelledDateTimeStr = toTimezone(sessionNode.updated, {
        humanReadableSort: true,
        timezone: timezone,
      })
      status = `Cancelled on ${cancelledDateTimeStr}`
    } else {
      const sessionStartDateTimeStr = toTimezone(sessionNode.startDateTime, {
        humanReadableSort: true,
        timezone: timezone,
      })
      status = `Upcoming on ${sessionStartDateTimeStr}`
    }
    const currentSession = {
      id: sessionNode.id,
      recordId: sessionNode.recordId,
      node: sessionNode,
      fotomerchantSubject: sessionNode.fotomerchantSubject,
      subject: sessionNode?.subject?.gaiaUser?.fullName,
      created: toTimezone(sessionNode.created, {
        humanReadableSort: true,
        timezone: timezone,
      }),
      sessionPackage: sessionNode.sessionPackage.title,
      packageCategory: sessionNode.packageCategory?.name
        ? sessionNode.packageCategory.name
        : null,
      location: sessionNode?.job?.location.name
        ? sessionNode?.job?.location.name
        : sessionNode?.job?.location.addressLineOne,
      bill: bill,
      price,
      event: sessionNode.subjectGroup?.name,
      subjectGroupId: sessionNode.subjectGroup?.id,
      subjectGroup: sessionNode.subjectGroup?.name,
      organization: sessionNode.organization?.name,
      organizationId: sessionNode.organization?.id,
      session: sessionNode.sessionPackage,
      coupon: sessionNode.coupon,
      job: sessionNode.job?.name,
      jobId: sessionNode.job?.id,
      booked: toTimezone(sessionNode.created, {
        humanReadableSort: true,
        timezone: timezone,
      }),
      status,
      paid,
      action,
      stage: sessionNode.stage ? sessionNode.stage.name : '',
    }
    return currentSession
  }

  let tableHeight = 700
  if (sendInvoiceModal || invoiceModal) {
    tableHeight = 300
  }
  if (
    (!initialQueryRun && !sessionsData?.sessions) ||
    (contact && hideSchoolContactFields === null)
  )
    return (
      <div className="mt-3">
        <Loading />
      </div>
    )
  if (error) return <>Error loading Session</>
  const sessions =
    sessionsData?.sessions.edges.map(({ node }) =>
      construstCurrentSession(node)
    ) || []

  const filterButtonsJSX = (
    <>
      <Button
        variant="link"
        onClick={() => {
          setShowChartModal(true)
        }}
      >
        <BarChartLine className="mr-2" />
        Report
      </Button>
      <Dropdown>
        <Dropdown.Toggle variant="link" id="filter-dropdown">
          <Funnel className="mr-2" />
          {filter === 'all' && 'Filter'}
          {filter === 'web' && 'Web'}
          {filter === 'mobile' && 'Mobile'}
          {filter === 'cancelled' && 'Cancelled'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setFilter('all')
            }}
          >
            All
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setFilter('web')
            }}
          >
            Web
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setFilter('mobile')
            }}
          >
            Mobile
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              setFilter('cancelled')
            }}
          >
            Cancelled
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
  const downloadButtonsJSX = (
    <>
      {checkedSessionDownloadIds.length > 0 && (
        <>
          <Button
            variant="link"
            disabled={downloadingExcel || downloadingPdf}
            onClick={() => {
              setDownloadingPdf(true)
              downloadSessions({
                variables: {
                  input: {
                    sessionIds: [checkedSessionDownloadIds],
                    jobId: null,
                    fileType: 'pdf',
                    file: 'sessions',
                  },
                },
              })
            }}
          >
            {downloadingPdf && <Loading height={'20'} />}
            {!downloadingPdf && (
              <>
                <CloudArrowDown className="mr-2" />
                Download PDF
              </>
            )}
          </Button>
          <Button
            variant="link"
            disabled={downloadingExcel || downloadingPdf}
            onClick={() => {
              setDownloadingExcel(true)
              downloadSessions({
                variables: {
                  input: {
                    sessionIds: [checkedSessionDownloadIds],
                    fileType: 'xlsx',
                    file: 'sessions',
                  },
                },
              })
            }}
          >
            {downloadingExcel && <Loading height={'20'} />}
            {!downloadingExcel && (
              <>
                <CloudArrowDown className="mr-2" />
                Download Excel
              </>
            )}
          </Button>
        </>
      )}
    </>
  )
  return (
    <>
      {showChartModal && (
        <SessionReportModal
          showModal={showChartModal}
          startDateTime={startDateFilter}
          organizationId={organizationId}
          subjectGroupId={subjectGroupId}
          sessionPackageId={sessionPackageId}
          couponId={couponId}
          endDataTime={endDateFilter}
          toggleModal={() => {
            setShowChartModal()
          }}
        />
      )}
      {(sendInvoiceModal || invoiceModal) && (
        <Row>
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplaySessions(!displaySessions)}
              className="px-0 btn-link mr-1"
              style={{ marginTop: '-10px' }}
            >
              <>
                {displaySessions ? (
                  <CaretDown size={17} />
                ) : (
                  <CaretRight size={17} />
                )}
              </>
            </button>
            <Form.Label>Sessions</Form.Label>
          </Col>
        </Row>
      )}
      {!detailComponent && !sendInvoiceModal && !invoiceModal && !couponId && (
        <>
          <Row>
            <Col>
              <h1 className="d-flex justify-content-between mb-0 mt-3">
                Sessions
              </h1>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="d-flex align-items-center">
              {canCreateSession && (
                <>
                  <Button
                    variant="link"
                    onClick={() => {
                      history.push({
                        pathname: '/job',
                        state: {
                          jobFormType: 'manual',
                        },
                      })
                    }}
                  >
                    <PlusCircle className="mr-2" />
                    New Job
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => history.push('/book-new-session')}
                  >
                    <PlusCircle className="mr-2" />
                    Book Session
                  </Button>
                </>
              )}
              {downloadButtonsJSX}
              {filterButtonsJSX}
            </Col>
          </Row>
        </>
      )}
      {(displaySessions || (!invoiceModal && !sendInvoiceModal)) && (
        <>
          <Row>
            <Col className="d-md-flex">
              {checkedSessionIds.length > 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowInvoiceModal(true)
                  }}
                >
                  <PlusCircle className="mr-2" />
                  New Invoice
                </Button>
              )}
              {(detailComponent || sendInvoiceModal || invoiceModal) && (
                <>{downloadButtonsJSX}</>
              )}
              {detailComponent && <>{filterButtonsJSX}</>}
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mt-2">
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  name="searchTerm"
                  placeholder={'Search Sessions'}
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Form.Group>
            </Col>
            <Col md={'auto'}>
              <DateFilter
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                placeholderStart="Sessions From"
                placeholderEnd={'Sessions Until'}
                prevNextDateClick={prevNextDateClick}
                setPrevNextDateClick={setPrevNextDateClick}
                prevNextStartDateFilter={prevNextStartDateFilter}
                prevNextEndDateFilter={prevNextEndDateFilter}
                timezone={formik.values.timezone}
              />
            </Col>
            {loggedInUser?.canManageRegions && settings?.tenantRegions && (
              <Col md={2} className="mt-2">
                <RegionSearchInput
                  dropdown
                  multiple
                  formik={formik}
                  setAdditionalFields={(node, _) => {
                    if (node?.timezone) {
                      formik.setFieldValue(
                        'timezone',
                        formatTimezone(node.timezone)
                      )
                    }
                  }}
                />
              </Col>
            )}
          </Row>
          <Row>
            <div className="rbc-btn-group rbc-toolbar mt-2 small">
              <button type="button" onClick={handleSubtractWeek}>
                Previous Week
              </button>
              <button type="button" onClick={handleAddWeek}>
                Next Week
              </button>
            </div>
          </Row>
          <Row>
            <Col>
              <SortableInfiniteTable
                tableHeight={tableHeight}
                loading={loading}
                tableData={sessions}
                tableColumns={tableColumns}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={
                  sessionsData?.sessions.pageInfo.hasNextPage || false
                }
                loadingMessage="Loading Sessions..."
                rowPointer
                onTdClicks={{
                  id: onTdClick,
                  status: onTdClick,
                  subject: onTdClick,
                  job: onTdClick,
                  sessionPackage: onTdClick,
                  packageCategory: onTdClick,
                  subjectGroup: onTdClick,
                  organization: onTdClick,
                  location: onTdClick,
                  bill: onTdClick,
                  booked: onTdClick,
                  region: onTdClick,
                }}
                hideGlobalFilter
                handleSortByChange={handleSortByChange}
                trStyleGenerator={(row) => {
                  const style = {
                    cursor: 'pointer',
                  }
                  if (
                    settings?.sessionStages &&
                    row.original.node?.stage?.color
                  ) {
                    style.backgroundColor = row.original.node?.stage?.color
                  }
                  return style
                }}
              />
            </Col>
          </Row>
        </>
      )}

      <AdminSessionDetailModal
        sessionId={sessionDetailId}
        showModal={showSessionDetailModal}
        toggleModal={toggleSessionDetailModal}
      />
      <SessionDetailModal
        sessionId={sessionDetailId}
        showModal={showSubjectSessionDetailModal}
        toggleModal={toggleSessionDetailModal}
      />
      <JobDetailModal
        jobId={jobId}
        showModal={showJobModal}
        toggleModal={toggleJobModal}
      />
      <SendInvoiceModal
        toggleModal={setShowInvoiceModal}
        showModal={showInvoiceModal}
        sessionIds={checkedSessionIds}
        organizationId={organizationId}
        recipient={invoiceRecipient}
        hideOrganization={hideOrganization}
      />
      <SessionHistoryModal
        showSessionHistoryModal={displaySessionHistory}
        toggleSessionHistoryModal={setDisplaySessionHistory}
        sessionId={sessionHistoryId}
      />
      <FotomerchantGalleryPasswordModal
        showModal={fotomerchantGalleryPassword}
        toggleModal={setFotomerchantGalleryPassword}
        fotomerchantPassword={fotomerchantGalleryPassword}
      />
      <LocationModal
        location={viewLocation}
        showModal={viewLocation}
        toggleModal={setViewLocation}
      />
    </>
  )
}

export default Sessions
