import React, { useState } from 'react'
import { gql, useMutation, useLazyQuery, useReactiveVar } from '@apollo/client'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { DateTime } from 'luxon'
import {
  XCircle,
  PlusCircle,
  Trash,
  CloudArrowDown,
  CalendarDate,
  ClockHistory,
  Bell,
  Tools,
} from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../common/Loading'
import * as Yup from 'yup'
import EquipmentJobTable from '../EquipmentJobTable'
import Repairs from '../Repair/Repairs'
import AuditLog from '../../audit_log/AuditLog'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { useDownloadFile } from '../../../libs/downloadFile'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'
import MetaDataTable from '../../common/MetaData'
import { useDateTimeConverter } from '../../../libs/useDateTime'
import { Tab } from 'react-bootstrap'
import Tabs from '../../common/Tabs'
import NotificationsTable from '../../notifications/NotificationsTable'
const ItemForm = (props) => {
  const {
    item,
    afterSubmit,
    employeeId,
    employeeName,
    itemTypeId,
    itemTypeName,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { toTimezone } = useDateTimeConverter()
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const settings = useReactiveVar(settingsVar)
  const [currentTab, setCurrentTab] = useState('Schedule')
  const [submitting, setSubmitting] = useState(false)
  const [displayItemTypeResults, setDisplayItemTypeResults] = useState(false)
  const [displayEmployeeResults, setDisplayEmployeeResults] = useState(false)
  const [metaData, setMetaData] = useState(
    JSON.parse(item?.metadata ? item?.metadata : '{}')
  )
  const [downloadingType, setDownloadingType] = useState('')

  const { downloadFile } = useDownloadFile()

  const [deleteEquipmentItem] = useMutation(
    gql`
      mutation DeleteEquipmentItem(
        $deleteEquipmentItemInput: DeleteEquipmentItemInput!
      ) {
        deleteEquipmentItem(input: $deleteEquipmentItemInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentRepairs',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )
  console.log(itemTypeId, itemTypeName)
  const [createEquipmentItem] = useMutation(
    gql`
      mutation CreateEquipmentItem($input: CreateEquipmentItemInput!) {
        createEquipmentItem(input: $input) {
          equipmentItems {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [updateEquipmentItem] = useMutation(
    gql`
      mutation UpdateEquipmentItem($input: UpdateEquipmentItemInput!) {
        updateEquipmentItem(input: $input) {
          equipmentItem {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [
    searchEmployees,
    { data: employeesData, fetchMore: fetchMoreEmployees },
  ] = useLazyQuery(gql`
    query EquipmentBagEmployees(
      $after: String
      $first: Int
      $nameIcontains: String
      $regionIds: String
    ) {
      employees(
        after: $after
        first: $first
        gaiaUser_FullName_Icontains: $nameIcontains
        regions: $regionIds
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              fullName
            }
          }
        }
      }
    }
  `)

  const [
    searchItemTypes,
    { data: itemTypesData, fetchMore: fetchMoreItemTypes },
  ] = useLazyQuery(
    gql`
      query EquipmentItemTypes($after: String, $first: Int, $name: String) {
        equipmentItemTypes(
          after: $after
          first: $first
          name_Icontains: $name
          miscellaneous: false
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: item
      ? {
          name: item.name,
          notes: item.notes,
          underRepair: item.underRepair,
          dueDate: item.dueDate
            ? toTimezone(item.dueDate, {
                noTimezone: true,
                isoFormat: true,
              })
            : null,
          count: 1,
          categoryName: item.equipmentCategory
            ? item.equipmentCategory.name
            : '',
          categoryId: item.equipmentCategory ? item.equipmentCategory.id : null,
          itemTypeName: item.equipmentItemType
            ? item.equipmentItemType.name
            : '',
          itemTypeId: item.equipmentItemType ? item.equipmentItemType.id : null,
          itemTypeMiscellaneous: item.equipmentItemType
            ? item.equipmentItemType.itemTypeMiscellaneous
            : null,
          returned: item.returned,
          employeeName: item.employee ? item.employee.gaiaUser.fullName : '',
          employeeId: item.employee ? item.employee.id : null,
          serialNumber: item.serialNumber,
          vendor: item.vendor ? item.vendor : null,
          price: item.price ? item.price : null,
          invoiceNumber: item.invoiceNumber ? item.invoiceNumber : null,
          regionId: item.region?.id,
          returnedAndUnassign: null,
        }
      : {
          name: '',
          notes: '',
          categoryName: '',
          categoryId: null,
          dueDate: null,
          returned: true,
          itemTypeName: itemTypeName ? itemTypeName : '',
          itemTypeId: itemTypeId ? itemTypeId : null,
          employeeName: employeeName ? employeeName : '',
          employeeId: employeeId ? employeeId : null,
          serialNumber: '',
          vendor: '',
          price: '',
          count: 1,
          invoiceNumber: '',
          regionId: loggedInUser?.defaultRegion?.id,
          returnedAndUnassign: null,
        },
    validationSchema: Yup.object().shape({
      count: Yup.number().required('required'),
      dueDate: Yup.date().nullable(),
      name: Yup.string().required('required'),
      serialNumber: Yup.string().nullable(),
      returnedAndUnassign: Yup.boolean().nullable(),
      vendor: Yup.string().nullable(),
      price: Yup.string().nullable(),
      invoiceNumber: Yup.string().nullable(),
      notes: Yup.string().nullable(),
      categoryName: Yup.string().nullable(),
      categoryId: Yup.string().nullable(),
      employeeName: Yup.string().nullable(),
      employeeId: Yup.string().nullable(),
      itemTypeName: Yup.string().nullable(),
      itemTypeId: Yup.string().nullable(),
      itemTypeMiscellaneous: Yup.boolean().nullable(),
      returned: Yup.boolean().nullable(),
      regionId: Yup.string().nullable(),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      setSubmitting(true)
      if (item) {
        updateEquipmentItem({
          variables: {
            input: {
              equipmentItemInput: {
                id: item.id,
                name: values.name,
                dueDate: values.dueDate
                  ? toTimezone(values.dueDate, {
                      noTimezone: true,
                      isoFormat: true,
                    })
                  : null,
                metadata: JSON.stringify(metaData),
                notes: values.notes,
                equipmentCategoryId: values.categoryId,
                equipmentItemTypeId: values.itemTypeId,
                returned: values.returned,
                underRepair: values.underRepair,
                employeeId: values.employeeId,
                vendor: values.vendor,
                invoiceNumber: values.invoiceNumber,
                price: values.price ? Number(values.price) : null,
                serialNumber: values.serialNumber,
                regionId: values.regionId,
                returnedAndUnassign: values.returnedAndUnassign,
              },
            },
          },
        })
      } else {
        createEquipmentItem({
          variables: {
            input: {
              equipmentItemInput: {
                name: values.name,
                dueDate: values.dueDate
                  ? toTimezone(values.dueDate, {
                      noTimezone: true,
                      isoFormat: true,
                    })
                  : null,
                notes: values.notes,
                metadata: JSON.stringify(metaData),
                equipmentCategoryId: values.categoryId,
                equipmentItemTypeId: values.itemTypeId,
                returned: values.returned,
                count: values.count,
                employeeId: values.employeeId,
                vendor: values.vendor,
                underRepair: values.underRepair,
                invoiceNumber: values.invoiceNumber,
                price: values.price ? Number(values.price) : null,
                serialNumber: values.serialNumber,
                regionId: values.regionId,
              },
            },
          },
        })
      }
    },
  })

  const handleDelete = () => {
    setSubmitting(true)
    deleteEquipmentItem({
      variables: {
        deleteEquipmentItemInput: {
          equipmentItemIds: item.id,
        },
      },
    })
  }

  const handleEmployeeBlur = () => {
    setDisplayEmployeeResults(false)
    formik.setFieldTouched(`employeeId`, true)
  }

  const handleEmployeeChange = (e) => {
    if (!e.target.value) {
      setDisplayEmployeeResults(false)
    } else {
      setDisplayEmployeeResults(true)
    }
    formik.setFieldValue(`employeeName`, e.target.value)
    searchEmployees({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
        regionIds: formik.values.regionId,
      },
    })
  }

  const handleEmployeeXClick = () => {
    formik.setFieldValue(`employeeName`, '')
    formik.setFieldValue(`employeeId`, '')
  }

  const handleEmployeeClick = (node) => {
    setDisplayEmployeeResults(false)
    formik.setFieldValue(`employeeName`, node.gaiaUser.fullName)
    formik.setFieldValue(`employeeId`, node.id)
  }

  const handleItemTypeBlur = () => {
    setDisplayItemTypeResults(false)
    formik.setFieldTouched(`itemTypeId`, true)
  }

  const handleItemTypeChange = (e) => {
    if (!e.target.value) {
      setDisplayItemTypeResults(false)
    } else {
      setDisplayItemTypeResults(true)
    }
    formik.setFieldValue(`itemTypeName`, e.target.value)
    searchItemTypes({
      variables: {
        after: null,
        first: 10,
        name: e.target.value,
      },
    })
  }

  const handleItemTypeXClick = () => {
    formik.setFieldValue(`itemTypeName`, '')
    formik.setFieldValue(`itemTypeId`, '')
    formik.setFieldValue(`itemTypeMiscellaneous`, '')
  }

  const handleItemTypeClick = (node) => {
    formik.setFieldValue(`itemTypeName`, node.name)
    formik.setFieldValue(`itemTypeId`, node.id)
    formik.setFieldValue(`itemTypeMiscellaneous`, node.miscellaneous)
  }
  let itemTypeSeachInput = formik.values.itemTypeName
  if (formik.values.itemTypeId && formik.values.itemTypeMiscellaneous) {
    itemTypeSeachInput = `${itemTypeSeachInput} (misc)`
  }
  return (
    <>
      <div id="equipmentBagTypeForm">
        <Form onSubmit={formik.handleSubmit}>
          {(item || (!itemTypeId && !item)) && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Item Type
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <Form.Control
                  placeholder="Search Item Types"
                  value={itemTypeSeachInput}
                  onBlur={handleItemTypeBlur}
                  disabled={!canMutate}
                  onChange={(e) => handleItemTypeChange(e)}
                  readOnly={Boolean(formik.values.itemTypeId)}
                  className={
                    formik.values.itemTypeId
                      ? ' border form-control form-control-sm'
                      : 'form-control form-control-sm'
                  }
                />
                {itemTypesData && displayItemTypeResults && (
                  <InfiniteScroll
                    height={100}
                    dataLength={itemTypesData.equipmentItemTypes.edges.length}
                    next={() => {
                      fetchMoreItemTypes({
                        variables: {
                          after:
                            itemTypesData.equipmentItemTypes.pageInfo.endCursor,
                          first: 10,
                          nameIcontains: formik.values.itemTypeName,
                        },
                      })
                    }}
                    hasMore={
                      itemTypesData?.equipmentItemTypes.pageInfo.hasNextPage
                    }
                    loader={<Loading />}
                  >
                    <Table size="sm" hover>
                      <tbody>
                        {itemTypesData.equipmentItemTypes.edges.map(
                          (itemType) => {
                            const { node } = itemType
                            return (
                              <tr
                                onMouseDown={() => handleItemTypeClick(node)}
                                key={node.id}
                                className="hover text-decoration-none"
                              >
                                <td>
                                  <small>
                                    {node.name}{' '}
                                    {node.miscellaneous && (
                                      <span className="ml-1">(misc)</span>
                                    )}
                                  </small>
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                )}
              </Col>
              <Col>
                {formik.values.itemTypeId && canMutate && (
                  <button
                    type="button"
                    className="p-0 mr-1 btn-link"
                    onClick={handleItemTypeXClick}
                  >
                    <XCircle />
                  </button>
                )}
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="name"
                className="form-control-sm"
                disabled={!canMutate}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {!item && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Number Of Items To Create
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <Form.Control
                  name="count"
                  type="number"
                  className="form-control-sm"
                  disabled={!canMutate}
                  value={formik.values.count}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.count}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.count}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}
          {(!employeeId || (employeeId && item)) && (
            <>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Long Term Employee Assignment
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    placeholder="Search Employees"
                    disabled={!canMutate}
                    value={formik.values.employeeName}
                    onBlur={handleEmployeeBlur}
                    onChange={(e) => handleEmployeeChange(e)}
                    readOnly={Boolean(formik.values.employeeId)}
                    className={
                      formik.values.employeeId
                        ? ' border form-control form-control-sm'
                        : 'form-control form-control-sm'
                    }
                  />
                  {employeesData && displayEmployeeResults && (
                    <InfiniteScroll
                      height={100}
                      dataLength={employeesData.employees.edges.length}
                      next={() => {
                        fetchMoreEmployees({
                          variables: {
                            after: employeesData.employees.pageInfo.endCursor,
                            first: 10,
                            nameIcontains: formik.values.employeeName,
                          },
                        })
                      }}
                      hasMore={employeesData?.employees.pageInfo.hasNextPage}
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {employeesData.employees.edges.map((org) => {
                            const { node } = org
                            return (
                              <tr
                                onMouseDown={() => handleEmployeeClick(node)}
                                key={node.id}
                                className="hover text-decoration-none"
                              >
                                <td>
                                  <small>{node.gaiaUser.fullName}</small>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  )}
                </Col>
                <Col>
                  {formik.values.employeeId && canMutate && (
                    <button
                      type="button"
                      className="p-0 mr-1 btn-link"
                      onClick={handleEmployeeXClick}
                    >
                      <Trash />
                    </button>
                  )}
                </Col>
              </Form.Group>
            </>
          )}
          {item && item.equipmentBag && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Bag
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <Form.Control
                  name="bag"
                  className="form-control-sm"
                  value={item.equipmentBag.name}
                  disabled={true}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Serial Number
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="serialNumber"
                className="form-control-sm"
                disabled={!canMutate}
                value={formik.values.serialNumber}
                onChange={formik.handleChange}
                isInvalid={formik.errors.serialNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.serialNumber}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {canMutate && (
            <>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Vendor
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="vendor"
                    className="form-control-sm"
                    value={formik.values.vendor}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.vendor}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.vendor}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Invoice Number
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="invoiceNumber"
                    className="form-control-sm"
                    value={formik.values.invoiceNumber}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.invoiceNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.invoiceNumber}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Price
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="price"
                    className="form-control-sm"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.price}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {loggedInUser?.canManageRegions && settings?.tenantRegions && (
                <Form.Group as={Row}>
                  <Col md={3}>
                    <Form.Label column sm="12" md="auto">
                      Region
                    </Form.Label>
                  </Col>
                  <Col sm="12" md={6}>
                    <RegionSearchInput
                      dropdown
                      formik={formik}
                      error={formik.errors.regionId}
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Under Repair
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Check
                    type="checkbox"
                    name="underRepair"
                    checked={formik.values.underRepair}
                    onChange={() => {
                      formik.setFieldValue(
                        'underRepair',
                        !formik.values.underRepair
                      )
                    }}
                    isInvalid={formik.errors.underRepair}
                    feedback={formik.errors.underRepair}
                  />
                </Col>
              </Form.Group>
            </>
          )}

          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Checked In
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <span
                onClick={() =>
                  formik.setFieldValue(`returned`, !formik.values.returned)
                }
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Form.Check
                  inline
                  name={`returned`}
                  type="switch"
                  label={formik.values.returned ? 'Yes' : 'No'}
                  checked={formik.values.returned}
                  style={{ fontSize: '0.9rem', color: '#333' }}
                />
              </span>
            </Col>
          </Form.Group>
          {item && !item.returned && item.employee && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Checked In and Unassigned
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <span
                  onClick={() =>
                    formik.setFieldValue(
                      `returnedAndUnassign`,
                      !formik.values.returnedAndUnassign
                    )
                  }
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Form.Check
                    inline
                    name={`returnedAndUnassign`}
                    type="switch"
                    label={formik.values.returnedAndUnassign ? 'Yes' : 'No'}
                    checked={formik.values.returnedAndUnassign}
                    style={{ fontSize: '0.9rem', color: '#333' }}
                  />
                </span>
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Due On
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <DatePicker
                name="dueDate"
                className="form-control form-control-sm"
                showPopperArrow={false}
                popperPlacement="auto"
                disabled={!canMutate}
                selected={
                  formik.values.dueDate
                    ? formik.values.dueDate instanceof DateTime
                      ? formik.values.dueDate.toJSDate()
                      : formik.values.dueDate
                    : null
                }
                onChange={(date) =>
                  formik.setFieldValue(
                    'dueDate',
                    date ? DateTime.fromJSDate(date) : null
                  )
                }
                popperModifiers={{
                  flip: {
                    behavior: ['bottom'],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
              {formik.values.dueDate && (
                <span
                  className="btn-link ml-2"
                  onClick={() => {
                    formik.setFieldValue('dueDate', null)
                  }}
                >
                  <Trash size={14} className="mr-2" />
                </span>
              )}
              <Form.Control.Feedback type="invalid">
                {formik.errors.dueDate}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Notes
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                as="textarea"
                name="notes"
                disabled={!canMutate}
                value={formik.values.notes}
                onChange={formik.handleChange}
                isInvalid={formik.errors.notes}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.notes}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={10} style={{ marginLeft: '10px' }}>
              <MetaDataTable
                metaData={metaData}
                setMetaData={setMetaData}
                noMessage
                edit={canMutate}
              />
            </Col>
          </Form.Group>

          {item && (
            <>
              <Tabs
                activeKey={currentTab}
                onSelect={(tab) => setCurrentTab(tab)}
              >
                <Tab
                  eventKey="Schedule"
                  title={
                    <b>
                      <CalendarDate className="mr-2" />
                      Schedule
                    </b>
                  }
                >
                  {currentTab === 'Schedule' && (
                    <div className="mt-3">
                      <EquipmentJobTable itemId={item.id} tableHeight={300} />
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="Notifications"
                  title={
                    <b>
                      <Bell className="mr-2" />
                      Notifications
                    </b>
                  }
                >
                  {currentTab === 'Notifications' && (
                    <div className="mt-3">
                      <NotificationsTable
                        equipmentItem={item}
                        tableHeight={300}
                      />
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="Repairs"
                  title={
                    <b>
                      <Tools className="mr-2" />
                      Repairs
                    </b>
                  }
                >
                  {currentTab === 'Repairs' && (
                    <div className="mt-3">
                      <Repairs
                        itemId={item.id}
                        tableHeight={300}
                        showRepairTableOnlyforItemTable={true}
                        prefilledRepairDetail={item}
                      />
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="Download"
                  title={
                    <b>
                      <CloudArrowDown className="mr-2" />
                      Download
                    </b>
                  }
                >
                  {currentTab === 'Download' && (
                    <Row className="mt-3 mb-3">
                      <Col>
                        {settings?.equipmentQrCodes && (
                          <>
                            <Button
                              variant="link"
                              disabled={downloadingType}
                              onClick={() => {
                                setDownloadingType('QR Code')
                                downloadFile(
                                  item.qrCodeFile.fileName,
                                  item.qrCodeFile.displayName,
                                  () => {
                                    toast.success(`QR Code Downloaded`)
                                    setDownloadingType('')
                                  }
                                )
                              }}
                              className="btn btn-link btn-sm"
                            >
                              <CloudArrowDown className="mr-2" />
                              QR Code{' '}
                            </Button>
                            <Button
                              variant="link"
                              disabled={downloadingType}
                              onClick={() => {
                                setDownloadingType('QR Code Label')
                                downloadFile(
                                  item.qrCodeLabelFile.fileName,
                                  item.qrCodeLabelFile.displayName,
                                  () => {
                                    toast.success(`QR Code Downloaded`)
                                    setDownloadingType('')
                                  }
                                )
                              }}
                              className="btn btn-link btn-sm"
                            >
                              <CloudArrowDown className="mr-2" />
                              QR Code Label{' '}
                            </Button>
                          </>
                        )}
                        {settings?.equipmentBarcodes && (
                          <>
                            <Button
                              variant="link"
                              disabled={downloadingType}
                              onClick={() => {
                                setDownloadingType('Barcode')
                                downloadFile(
                                  item.barcodeFile.fileName,
                                  item.barcodeFile.displayName,
                                  () => {
                                    toast.success(`Barcode Downloaded`)
                                    setDownloadingType('')
                                  }
                                )
                              }}
                              className="btn btn-link btn-sm"
                            >
                              <CloudArrowDown className="mr-2" />
                              Barcode{' '}
                            </Button>
                            <Button
                              variant="link"
                              disabled={downloadingType}
                              onClick={() => {
                                setDownloadingType('Barcode Label')
                                downloadFile(
                                  item.barcodeLabelFile.fileName,
                                  item.barcodeLabelFile.displayName,
                                  () => {
                                    toast.success(`Barcode Label Downloaded`)
                                    setDownloadingType('')
                                  }
                                )
                              }}
                              className="btn btn-link btn-sm"
                            >
                              <CloudArrowDown className="mr-2" />
                              Barcode Label{' '}
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  )}
                </Tab>
                {canMutate && (
                  <Tab
                    eventKey="History"
                    title={
                      <b>
                        <ClockHistory className="mr-2" />
                        History
                      </b>
                    }
                  >
                    {currentTab === 'History' && (
                      <div className="mt-3">
                        <AuditLog
                          contentType={item.contentType.model}
                          id={item.id}
                          tableHeight={300}
                        />
                      </div>
                    )}
                  </Tab>
                )}
              </Tabs>
            </>
          )}
          {canMutate && (
            <Form.Row className="mt-2">
              <Col md={3}>
                <Button
                  type="submit"
                  block
                  variant="outline-primary"
                  disabled={submitting}
                >
                  <PlusCircle className="mr-2" />
                  Save
                </Button>
              </Col>

              {item && (
                <Col md={3}>
                  <Button
                    variant="outline-danger"
                    block
                    onClick={handleDelete}
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              )}
            </Form.Row>
          )}
          {submitting && (
            <Row className="mt-3">
              <Col>
                <Loading message="Saving Item..." />
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </>
  )
}

export default ItemForm
