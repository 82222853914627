import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Calendar, Trash } from 'react-bootstrap-icons'
import { useDateTimeConverter } from '../../libs/useDateTime'

const DateFilter = (props) => {
  const {
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    placeholderStart,
    placeholderEnd,
    oneDate,
    prevNextDateClick,
    setPrevNextDateClick,
    prevNextStartDateFilter,
    prevNextEndDateFilter,
    timezone,
  } = props
  const { toUTC } = useDateTimeConverter()
  let jsStartDateFilter = ''
  if (startDateFilter instanceof Date) {
    jsStartDateFilter = startDateFilter
  } else if (startDateFilter?.c) {
    jsStartDateFilter = new Date(
      startDateFilter.c.year,
      startDateFilter.c.month - 1,
      startDateFilter.c.day
    )
  }
  let jsEndDateFilter = ''
  if (endDateFilter) {
    if (endDateFilter instanceof Date) {
      jsEndDateFilter = endDateFilter
    } else if (endDateFilter?.c) {
      jsEndDateFilter = new Date(
        endDateFilter.c.year,
        endDateFilter.c.month - 1,
        endDateFilter.c.day
      )
    }
  }

  const formik = useFormik({
    initialValues: {
      startDateFilter: jsStartDateFilter,
      endDateFilter: jsEndDateFilter,
    },
    validationSchema: Yup.object().shape({
      startDateFilter: Yup.date().test(
        'isLessThanEndDate',
        'Start date must not exceed end date',
        (value, context) => {
          let valid = true
          if (prevNextDateClick) {
            return valid
          }
          if (!oneDate && value && context.parent.endDateFilter) {
            const startDate = new Date(value)
            const endDate = new Date(context.parent.endDateFilter)
            endDate.setHours(23)
            endDate.setMinutes(59)
            endDate.setSeconds(59)
            if (endDate < startDate) {
              valid = false
            }
          }
          return valid
        }
      ),
      endDateFilter: Yup.date(),
    }),
  })

  useEffect(() => {
    let startDate
    if (formik.values.startDateFilter) {
      startDate = toUTC(formik.values.startDateFilter, {
        timezone: timezone,
        startOfDay: true,
      })
    }
    let endDate
    if (!oneDate) {
      if (formik.values.endDateFilter) {
        endDate = toUTC(formik.values.endDateFilter, {
          timezone: timezone,
          endOfDay: true,
        })
      }
    }
    if (
      startDate &&
      (!endDate || startDate < endDate) &&
      startDate !== startDateFilter
    ) {
      setStartDateFilter(startDate)
    }
    if (
      endDate &&
      (!startDate || startDate < endDate) &&
      endDate !== endDateFilter
    ) {
      setEndDateFilter(endDate)
    }
  }, [formik.values])

  useEffect(() => {
    if (prevNextDateClick && prevNextStartDateFilter && prevNextEndDateFilter) {
      formik.setFieldValue('endDateFilter', prevNextEndDateFilter)
      formik.setFieldValue('startDateFilter', prevNextStartDateFilter)
      setPrevNextDateClick(false)
    }
  }, [prevNextEndDateFilter, prevNextStartDateFilter, prevNextDateClick])

  const handleRemoveDateFilterClick = (dateType) => {
    if (dateType === 'start') {
      formik.setFieldValue('startDateFilter', '')
      setStartDateFilter(null)
    } else if (dateType === 'end') {
      formik.setFieldValue('endDateFilter', '')
      setEndDateFilter(null)
    }
  }
  return (
    <div className="mt-2">
      <Row>
        <Col
          md="auto"
          className="d-flex align-items-center pr-0"
          style={{ zIndex: 2 }}
        >
          {formik.values.startDateFilter ? (
            <div
              className="btn-link"
              style={{ cursor: 'pointer' }}
              onClick={() => handleRemoveDateFilterClick('start')}
            >
              <Trash className="mr-2" />
            </div>
          ) : null}
          <DatePicker
            placeholderText={placeholderStart}
            name="startDateFilter"
            className="form-control form-control-sm"
            showPopperArrow={false}
            popperPlacement="bottom"
            selected={formik.values.startDateFilter}
            onChange={(date) => formik.setFieldValue('startDateFilter', date)}
            popperModifiers={{
              flip: {
                behavior: ['bottom'],
              },
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            }}
          />
          <Calendar
            className="position-absolute mr-2 small text-secondary"
            style={{ right: 0 }}
          />
        </Col>
        {!oneDate && (
          <Col
            md="auto"
            className="d-flex align-items-center px-0 ml-3"
            style={{ zIndex: 2 }}
          >
            {formik.values.endDateFilter ? (
              <div
                style={{ cursor: 'pointer' }}
                className="btn-link"
                onClick={() => handleRemoveDateFilterClick('end')}
              >
                <Trash className="mr-2" />
              </div>
            ) : (
              <></>
            )}
            <DatePicker
              name="endDateFilter"
              className="form-control form-control-sm"
              showPopperArrow={false}
              placeholderText={placeholderEnd}
              selected={formik.values.endDateFilter}
              onChange={(date) => formik.setFieldValue('endDateFilter', date)}
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                },
                preventOverflow: {
                  enabled: false,
                },
                hide: {
                  enabled: false,
                },
              }}
            />
            <Calendar
              className="position-absolute mr-2 small text-secondary"
              style={{ right: 0 }}
            />
          </Col>
        )}
      </Row>
      {formik.errors.startDateFilter ? (
        <Row>
          <Col className="ml-4">
            <span>
              <small className="text-danger">
                {formik.errors.startDateFilter}
              </small>
            </span>
          </Col>
        </Row>
      ) : null}
    </div>
  )
}

export default DateFilter
