import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'
import { formatRegion } from '../../../libs/utils'

const RegionSearchInput = (props) => {
  const {
    required,
    formik,
    disabled,
    formikValue,
    multiple,
    searchDescription,
    setAdditionalFields,
    error,
    formikMultipleValue,
    variables,
    placeholder,
    dropdown,
    mustHaveValue,
    query,
  } = props
  return (
    <SearchInput
      formik={formik}
      required={required}
      placeholder={placeholder}
      nodeName="region"
      searchDescription={searchDescription ? searchDescription : 'Regions'}
      nodeNamePlural="regions"
      formikMultipleValue={formikMultipleValue}
      formikValue={formikValue}
      multiple={multiple}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      dropdown={dropdown}
      mustHaveValue={mustHaveValue}
      gql={
        query
          ? query
          : gql`
              query Regions($first: Int, $after: String, $search: String) {
                regions(
                  first: $first
                  after: $after
                  orderBy: "name"
                  search: $search
                ) {
                  nodeCount
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  edges {
                    node {
                      id
                      name
                      timezone
                    }
                  }
                }
              }
            `
      }
      formatDescription={(node) => {
        return formatRegion(node)
      }}
    />
  )
}

export default RegionSearchInput
