import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Tab, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import {
  Trash,
  CalendarDate,
  InfoCircle,
  Camera,
  Person,
  People,
  Cash,
  PlusCircle,
  ReceiptCutoff,
  CardChecklist,
  PencilSquare,
  GeoAlt,
  PersonRolodex,
  Bell,
  FileEarmarkText,
  ClockHistory,
  CloudArrowDown,
  Globe,
} from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import { useAWSS3 } from '../../libs/aws'
import Loading from '../common/Loading'
import OrganizationModal from './OrganizationModal'
import DeleteOrganizationModal from './DeleteOrganizationModal'
import Notifications from '../notifications/Notifications'
import { useBoxEnabled } from '../../libs/box'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import SessionList from '../sessions/Sessions'
import Schedule from '../schedule/Schedule'
import AuditLog from '../audit_log/AuditLog'
import Tabs from '../common/Tabs'
import InvoiceTable from '../payments/InvoiceTable'
import Locations from '../location/Locations'
import ESignRequests from '../files/box/ESignRequests'
import Contacts from './Contacts'
import SubjectGroups from '../subject_group/SubjectGroups'
import Subjects from '../subject/Subjects'
import Policies from '../common/Policies'
import PaymentsTable from '../payments/PaymentsTable'
import StatusCircle from '../common/StatusCircle'
import BoxFolder from '../files/box/BoxFolder'
import NotificationsTable from '../notifications/NotificationsTable'
import FilesTable from '../files/FilesTable'
import { useDownloadFile } from '../../libs/downloadFile'
import Tasks from '../task/Tasks'
import FormsFormSubmissions from '../form/FormsFormSubmissions'
import OrganizationRegions from './OrganizationRegions'

const OrganizationDetail = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const canSeeHistory = ![
    'Subject',
    'Organization Contact',
    'General Staff',
  ].includes(loggedInUser?.permissions?.group)
  const cantSeePayments = ['General Staff', 'Equipment Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const boxEnabled = useBoxEnabled()
  const { organizationId, contact } = useParams()
  const awsS3 = useAWSS3()
  const { downloadAndDeleteFile } = useDownloadFile()
  const [logoImage, setLogoImage] = useState()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [processingSubjects, setProcessingSubjects] = useState(false)
  const [currentBoxFolderId, setCurrentBoxFolderId] = useState()
  const [displayFiles, setDisplayFiles] = useState(false)
  const [orgUpdateSubmitting, setOrgUpdateSubmitting] = useState(false)
  const [showOrgModal, setShowOrgModal] = useState(false)
  const [activeTab, setActiveTab] = useState('Schedule')
  const [activeFilesTab, setActiveFilesTab] = useState('Files')
  const [activeBillingTab, setActiveBillingTab] = useState('Payments')
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [pollInterval, setPollInterval] = useState(null)

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab)
    if (selectedTab === 'Files') {
      setDisplayFiles(!displayFiles)
    }
  }

  const toggleDeleteOrganizationModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  useEffect(() => {
    if (organizationId) {
      getOrganization({
        variables: {
          organizationId,
        },
      })
    }
  }, [organizationId])

  const [downloadSubjects] = useMutation(
    gql`
      mutation DownloadOrganizations($input: DownloadOrganizationsInput!) {
        downloadOrganizations(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadOrganizations.file.fileName,
          data.downloadOrganizations.file.displayName,
          data.downloadOrganizations.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const [updateOrg, { error: updateOrgError }] = useMutation(
    gql`
      mutation UpdateOrganizationMutations(
        $updateOrganizationInput: UpdateOrganizationInput!
      ) {
        updateOrganization(input: $updateOrganizationInput) {
          organization {
            id
            name
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setOrgUpdateSubmitting(false)
        if (!updateOrgError) {
          orgModalShowChange()
          toast.success('Organization Saved')
        }
      },
      refetchQueries: [
        'OrganizationDetail',
        'OrganizationsSubjects',
        'OrganizationSubjectGroupsQuery',
      ],
    }
  )

  const [
    getOrganization,
    { error: organizationQueryError, data: organizationQueryData },
  ] = useLazyQuery(
    gql`
      query OrganizationDetail($organizationId: ID!) {
        organization(id: $organizationId) {
          id
          category
          name
          invoiceEmail
          resitFeePaidSessions
          resitFeeFreeSessions
          rootBoxFolderId
          contactsBoxFolderId
          processingSubjects
          refundPolicy
          organizationStage {
            color
            name
          }
          applyNoShowPolicyFree
          applyNoShowPolicyPaid
          applyPolicyFree
          applyPolicyPaid
          timeRefundHours
          timeRefundFee
          resitFee
          timeRefundSessionPackageCost
          noShowFee
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          usingNoShowPolicy
          usingCancellationPolicy
          usingResitPolicy
          syncingNotifications
          resitsIncludeFreeSessions
          processingSubjectsProgress
          notes
          subjectGroupSharedCanSeeFiles
          subjectGroupSharedCanCreateFiles
          subjectGroupSharedCanCreateFolders
          metadata
          contentType {
            model
          }
          folder {
            id
          }
          staffSharedFolder {
            id
          }
          sharedFolder {
            id
          }
          accountManager {
            id
            gaiaUser {
              firstName
              lastName
              fullName
            }
          }
          fotomerchantClient {
            fotomerchantId
          }
          paidPackages {
            edges {
              node {
                id
              }
            }
          }
          domains {
            edges {
              node {
                name
              }
            }
          }
          logoImage {
            defaultImageName
          }
          stripeCustomer {
            id
            stripePaymentMethods {
              edges {
                node {
                  stripeResource
                  created
                }
              }
            }
          }
          sessionPackageBackgrounds {
            edges {
              node {
                id
                backgroundColor
                sessionPackage {
                  title
                  price
                  description
                }
              }
            }
          }
          tags {
            id
            name
          }
          organizationRegions(first: 1, default: true) {
            edges {
              node {
                id
                default
                region {
                  id
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval,
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (organizationQueryData?.organization?.processingSubjects === true) {
      setProcessingSubjects(true)
    } else if (
      organizationQueryData?.organization?.processingSubjects === false
    ) {
      if (processingSubjects) {
        setProcessingSubjects(false)
        toast.success('CSV Subject Upload Complete')
      }
    }
  }, [
    organizationQueryData?.organization?.processingSubjects,
    processingSubjects,
  ])

  async function getS3Object(Key) {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          setLogoImage(awsS3.encodeS3ImageData(data.Body))
        }
      }
    )
  }

  const formatCategory = (category) => {
    let transformedCategory
    if (category === 'SCHOOL') {
      transformedCategory = 'School'
    } else if (category === 'COMMERCIAL') {
      transformedCategory = 'Commercial'
    } else if (category === 'OTHER') {
      transformedCategory = 'Other'
    }
    return transformedCategory
  }
  useEffect(() => {
    if (organizationQueryData?.organization && awsS3.client) {
      if (organizationQueryData.organization.logoImage) {
        getS3Object(
          organizationQueryData.organization.logoImage.defaultImageName
        )
      }
      if (organizationQueryData.organization.syncingNotifications) {
        setPollInterval(5000)
      } else {
        setPollInterval(null)
      }
    }
  }, [organizationQueryData, awsS3.client])

  const orgModalShowChange = () => {
    setShowOrgModal((prevShowOrgModal) => !prevShowOrgModal)
  }

  useEffect(() => {
    if (organizationQueryData?.organization) {
      const { organization } = organizationQueryData
      setCurrentBoxFolderId(organization.rootBoxFolderId)
    }
  }, [organizationQueryData])

  if (organizationQueryError) return <p>Error loading organization</p>
  if (!organizationQueryData || !awsS3?.client) {
    return (
      <Row className="mt-3">
        <Col>
          <Loading message="Loading Organization..." />
        </Col>
      </Row>
    )
  }

  const { organization } = organizationQueryData
  return (
    <>
      {showOrgModal && (
        <OrganizationModal
          orgId={organizationId}
          updateOrg={updateOrg}
          updatingOrg={true}
          showOrgModal={showOrgModal}
          orgModalShowChange={orgModalShowChange}
          setIsSubmitting={setOrgUpdateSubmitting}
          isSubmitting={orgUpdateSubmitting}
        />
      )}

      <DeleteOrganizationModal
        showModal={showDeleteModal}
        toggleModal={toggleDeleteOrganizationModal}
        organizationId={organization.id}
      />

      <div className="organizationDetail">
        <Row className="mt-3">
          <Col>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <h1 className="mb-0" style={{ marginRight: '10px' }}>
                {organization.name}
              </h1>
              {organization.syncingNotifications && !contact && (
                <span style={{ marginTop: '5px' }}>
                  <StatusCircle message="Syncing Notifications" />
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={2} className="d-md-flex mb-3">
            {!contact &&
              ['Administrator', 'Scheduling Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <>
                  <Button variant="link" onClick={orgModalShowChange}>
                    <PlusCircle className="mr-2" /> Edit
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => toggleDeleteOrganizationModal()}
                  >
                    <Trash className="mr-2" />
                    <span>Delete</span>
                  </Button>
                </>
              )}
            <Button
              variant="link"
              disabled={downloadingPdf}
              onClick={() => {
                setDownloadingPdf(true)
                downloadSubjects({
                  variables: {
                    input: {
                      organizationIds: [organization.id],
                      subjects: true,
                      fileType: 'pdf',
                    },
                  },
                })
              }}
            >
              <CloudArrowDown className="mr-2" />
              <span>Download PDF</span>
            </Button>
            <Button
              variant="link"
              disabled={downloadingExcel}
              onClick={() => {
                setDownloadingExcel(true)
                downloadSubjects({
                  variables: {
                    input: {
                      organizationIds: [organization.id],
                      subjects: true,
                      fileType: 'xlsx',
                    },
                  },
                })
              }}
            >
              <CloudArrowDown className="mr-2" />
              <span>Download Excel</span>
            </Button>
          </Col>
        </Row>
        <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
          <Tab
            key="Schedule"
            eventKey="Schedule"
            style={{ marginTop: '10px' }}
            title={
              <b style={{ fontSize: '0.9rem' }}>
                <CalendarDate className="mr-1" />
                Schedule
              </b>
            }
          >
            <Row className={contact ? '' : 'mt-3'}>
              <Col md={12}>
                <Schedule organizationId={organization.id} isOrgView={true} />
              </Col>
            </Row>
          </Tab>
          {!contact && (
            <Tab
              key="Details"
              eventKey="Details"
              style={{ marginTop: '10px' }}
              title={
                <b style={{ fontSize: '0.9rem' }}>
                  <InfoCircle className="mr-1" />
                  Details
                </b>
              }
            >
              {logoImage && (
                <>
                  <Row>
                    <Col>
                      <h4 className="mt-3">Logo</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <img
                        className="img-fluid"
                        style={{ height: '100px' }}
                        src={`data:image/jpeg;base64,${logoImage}`}
                        alt="Organization logo"
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col>
                  <h4 className="mt-3">Stage</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  {organization.organizationStage ? (
                    <>
                      <span
                        style={{
                          backgroundColor: organization.organizationStage.color,
                        }}
                      >
                        {organization.organizationStage.name}
                      </span>
                    </>
                  ) : (
                    <span className="text-secondary">- - -</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-3">Category</h4>
                </Col>
              </Row>
              <Row>
                <Col>{formatCategory(organization.category)}</Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-3">Tags</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  {organization.tags.map((tag) => tag.name).join(', ')}
                  {organization.tags.length === 0 && (
                    <span className="text-secondary">- - -</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-3">Account Manager</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  {organization?.accountManager ? (
                    <>{organization?.accountManager?.gaiaUser?.fullName}</>
                  ) : (
                    <span className="text-secondary">- - -</span>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4 className="mt-3">Notes</h4>
                </Col>
              </Row>
              <Row className="mt-3 mb-3">
                <Col md={8}>
                  {organization.notes ? (
                    <Form.Control
                      as="textarea"
                      disabled={true}
                      style={{ height: '200px' }}
                      value={organization.notes}
                    />
                  ) : (
                    <span className="text-secondary">- - -</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-3">Session Package Backgrounds</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  {organizationQueryData?.organization
                    ?.sessionPackageBackgrounds?.edges.length > 0 ? (
                    <ul>
                      {organizationQueryData?.organization?.sessionPackageBackgrounds?.edges?.map(
                        (sessionPackage, index) => (
                          <li key={index}>
                            {sessionPackage.node.sessionPackage.title} (
                            {sessionPackage.node.backgroundColor})
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    <span className="text-secondary">- - -</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-3">Scheduling Policies</h4>
                </Col>
              </Row>
              <Policies nodeType="organization" node={organization} />
            </Tab>
          )}
          <Tab
            key="Sessions"
            eventKey="Sessions"
            style={{ marginTop: '10px' }}
            title={
              <b style={{ fontSize: '0.9rem' }}>
                <Camera className="mr-1" />
                Sessions
              </b>
            }
          >
            {activeTab === 'Sessions' && (
              <Row className="mt-3 mb-3">
                <Col md={12}>
                  <SessionList
                    contact={contact}
                    organizationId={organization.id}
                    detailComponent
                    hideOrganization
                    invoiceRecipient={organization.invoiceEmail}
                  />
                </Col>
              </Row>
            )}
          </Tab>
          <Tab
            key="Subjects"
            eventKey="Subjects"
            style={{ marginTop: '10px' }}
            title={
              <b style={{ fontSize: '0.9rem' }}>
                <Person className="mr-1" />
                Subjects
              </b>
            }
          >
            {activeTab === 'Subjects' && (
              <Subjects organization={organization} poll />
            )}
          </Tab>
          <Tab
            key="SubjectGroups"
            eventKey="SubjectGroups"
            style={{ marginTop: '10px' }}
            title={
              <b style={{ fontSize: '0.9rem' }}>
                <People className="mr-1" />
                Subject Groups
              </b>
            }
          >
            {activeTab === 'SubjectGroups' && (
              <SubjectGroups organization={organization} />
            )}
          </Tab>
          {!cantSeePayments && (
            <Tab
              key="Billing"
              eventKey="Billing"
              style={{ marginTop: '10px' }}
              title={
                <b style={{ fontSize: '0.9rem' }}>
                  <Cash className="mr-2" />
                  Billing
                </b>
              }
            >
              <Tabs
                activeKey={activeBillingTab}
                onSelect={(tab) => setActiveBillingTab(tab)}
              >
                <Tab
                  key="Payments"
                  eventKey="Payments"
                  style={{ marginTop: '10px' }}
                  title={
                    <b style={{ fontSize: '0.9rem' }}>
                      <Cash className="mr-2" />
                      Payments
                    </b>
                  }
                >
                  {activeTab === 'Billing' &&
                    activeBillingTab === 'Payments' && (
                      <PaymentsTable organizationId={organization.id} />
                    )}
                </Tab>
                <Tab
                  key="Invoices"
                  eventKey="Invoices"
                  style={{ marginTop: '10px' }}
                  title={
                    <b style={{ fontSize: '0.9rem' }}>
                      <ReceiptCutoff className="mr-2" />
                      Invoices
                    </b>
                  }
                >
                  {activeTab === 'Billing' &&
                    activeBillingTab === 'Invoices' && (
                      <InvoiceTable
                        stripeCustomerId={organization?.stripeCustomer?.id}
                        organizationId={organization?.id}
                        invoiceRecipient={organization?.invoiceEmail}
                      />
                    )}
                </Tab>
              </Tabs>
            </Tab>
          )}
          {!contact && settings.tenantSubscription !== 'Basic' && (
            <Tab
              key="Task"
              eventKey="Task"
              style={{ marginTop: '10px' }}
              title={
                <b style={{ fontSize: '0.9rem' }}>
                  <CardChecklist className="mr-2" />
                  Tasks
                </b>
              }
            >
              {activeTab === 'Task' && (
                <Tasks
                  organizationId={organization.id}
                  organizationName={organization.name}
                />
              )}
            </Tab>
          )}
          <Tab
            key="Contact"
            eventKey="Contact"
            style={{ marginTop: '10px' }}
            title={
              <b style={{ fontSize: '0.9rem' }}>
                <PersonRolodex className="mr-2" />
                Contacts
              </b>
            }
          >
            <>
              <Contacts organization={organization} />
            </>
          </Tab>
          <Tab
            key="Location"
            eventKey="Location"
            style={{ marginTop: '10px' }}
            title={
              <b style={{ fontSize: '0.9rem' }}>
                <GeoAlt className="mr-2" />
                Locations
              </b>
            }
          >
            {activeTab === 'Location' && (
              <Locations organizationId={organization.id} />
            )}
          </Tab>
          {loggedInUser?.canManageRegions && settings?.tenantRegions && (
            <Tab
              key="Region"
              eventKey="Region"
              style={{ marginTop: '10px' }}
              title={
                <b style={{ fontSize: '0.9rem' }}>
                  <Globe className="mr-2" />
                  Regions
                </b>
              }
            >
              {activeTab === 'Region' && (
                <OrganizationRegions organizationId={organization.id} />
              )}
            </Tab>
          )}
          {(canSeeHistory || contact) && (
            <Tab
              key="Notifications"
              eventKey="Notifications"
              style={{ marginTop: '10px' }}
              title={
                <b style={{ fontSize: '0.9rem' }}>
                  <Bell className="mr-2" />
                  Notifications
                </b>
              }
            >
              {contact && activeTab === 'Notifications' && (
                <div className="mt-4">
                  <NotificationsTable organization={organization} />
                </div>
              )}
              {!contact && activeTab === 'Notifications' && (
                <div className="mt-3">
                  <Notifications
                    organization={organization}
                    tableOnly={contact}
                  />
                </div>
              )}
            </Tab>
          )}
          {(loggedInUser.permissions.isEmployee ||
            !contact ||
            boxEnabled ||
            organization.sharedCanSeeFiles) && (
            <Tab
              key="Files"
              eventKey="Files"
              style={{ marginTop: '10px' }}
              title={
                <b style={{ fontSize: '0.9rem' }}>
                  <FileEarmarkText className="mr-2" />
                  Files
                </b>
              }
            >
              {activeTab === 'Files' && (
                <>
                  {boxEnabled && (
                    <Tabs
                      activeKey={activeFilesTab}
                      onSelect={setActiveFilesTab}
                    >
                      <Tab
                        key="Files"
                        eventKey="Files"
                        style={{ marginTop: '10px' }}
                        title={<b style={{ fontSize: '0.9rem' }}>Files</b>}
                      >
                        <BoxFolder
                          canDelete={!contact}
                          canRename={!contact}
                          rootBoxFolderId={
                            contact
                              ? organization.contactsBoxFolderId
                              : organization.rootBoxFolderId
                          }
                          setCurrentBoxFolderId={setCurrentBoxFolderId}
                        />
                      </Tab>
                      <Tab
                        key="Sign Requests"
                        eventKey="Sign Requests"
                        style={{ marginTop: '10px' }}
                        title={
                          <b style={{ fontSize: '0.9rem' }}>Sign Requests</b>
                        }
                      >
                        <ESignRequests
                          currentBoxFolderId={currentBoxFolderId}
                          organizationId={organization.id}
                          contact={contact}
                        />
                      </Tab>
                    </Tabs>
                  )}
                  {!boxEnabled && organization?.folder?.id && (
                    <div className="mt-4">
                      <FilesTable
                        organizationId={organization.id}
                        organizationDescriptio={organization.name}
                        sharedCanCreateFiles={organization.sharedCanCreateFiles}
                        sharedCanCreateFolders={
                          organization.sharedCanCreateFolders
                        }
                        folderId={
                          contact
                            ? organization.sharedFolder.id
                            : loggedInUser.permissions.group === 'General Staff'
                              ? organization.staffSharedFolder?.id
                              : organization.folder.id
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </Tab>
          )}
          {loggedInUser.permissions.isEmployee && (
            <Tab
              key="Forms"
              eventKey="Forms"
              style={{ marginTop: '10px' }}
              title={
                <b style={{ fontSize: '0.9rem' }}>
                  <PencilSquare className="mr-2" />
                  Forms
                </b>
              }
            >
              <FormsFormSubmissions
                organizationId={organization.id}
                organizationDescription={organization.name}
              />
            </Tab>
          )}
          {canSeeHistory && (
            <Tab
              key="History"
              eventKey="History"
              style={{ marginTop: '10px' }}
              title={
                <b style={{ fontSize: '0.9rem' }}>
                  <ClockHistory className="mr-1" />
                  History
                </b>
              }
            >
              {activeTab === 'History' && (
                <Row className="mt-3 mb-2">
                  <Col md={12}>
                    <AuditLog
                      tableHeight={600}
                      contentType={organization.contentType.model}
                      id={organization.id}
                      searchWidth={4}
                    />
                  </Col>
                </Row>
              )}
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  )
}

export default OrganizationDetail
