import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Globe, PlusCircle, Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import OrganizationSearchInput from '../common/node_search_input/OrganizationSearchInput'

const OrganizationRegionModal = (props) => {
  const { showModal, organizationRegion, organizationId, toggleModal } = props
  const [submitting, setSubmitting] = useState(false)

  const [deleteOrganizationRegion] = useMutation(
    gql`
      mutation DeleteOrganizationRegion(
        $deleteOrganizationRegionInput: DeleteOrganizationRegionInput!
      ) {
        deleteOrganizationRegion(input: $deleteOrganizationRegionInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success('Organization Region Deleted')
        toggleModal()
      },
      refetchQueries: ['OrganizationRegions'],
      errorPolicy: 'all',
    }
  )

  const [createOrganizationRegion] = useMutation(
    gql`
      mutation CreateOrganizationRegion(
        $input: CreateOrganizationRegionInput!
      ) {
        createOrganizationRegion(input: $input) {
          organizationRegion {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Organization Region Saved`)
        toggleModal()
      },
      onError: (data) => {
        formik.setErrors({
          regionId: `region already exists`,
        })
        setSubmitting(false)
      },
      refetchQueries: ['OrganizationRegions'],
    }
  )

  const [updateOrganizationRegion] = useMutation(
    gql`
      mutation UpdateOrganizationRegion(
        $input: UpdateOrganizationRegionInput!
      ) {
        updateOrganizationRegion(input: $input) {
          organizationRegion {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Organization Region Saved`)
        toggleModal()
      },
      onError: (data) => {
        formik.setErrors({
          regionId: `region already exists`,
        })
        setSubmitting(false)
      },
      refetchQueries: ['OrganizationRegions'],
    }
  )

  const handleDeleteClick = () => {
    setSubmitting(true)
    deleteOrganizationRegion({
      variables: {
        deleteOrganizationRegionInput: {
          organizationRegionIds: [organizationRegion.id],
        },
      },
    })
  }

  const formik = useFormik({
    initialValues: organizationRegion
      ? {
          regionId: organizationRegion.region.id,
          organizationId: organizationRegion.organization.id,
          default: organizationRegion.default,
        }
      : {
          regionId: '',
          organizationId: organizationId || '',
          default: false,
        },
    validationSchema: Yup.object().shape({
      regionId: Yup.string().required('required'),
      organizationId: Yup.string().required('required'),
      default: Yup.boolean().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (organizationRegion) {
        updateOrganizationRegion({
          variables: {
            input: {
              organizationRegionInput: {
                id: organizationRegion.id,
                regionId: values.regionId,
                organizationId: values.organizationId,
                default: values.default,
              },
            },
          },
        })
      } else {
        createOrganizationRegion({
          variables: {
            input: {
              organizationRegionInput: {
                regionId: values.regionId,
                organizationId: values.organizationId,
                default: values.default,
              },
            },
          },
        })
      }
    },
  })

  if (!showModal) return <></>
  return (
    <div className="addEditOrganizationRegionsModal">
      <Modal size={'md'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Globe className="mr-2" />
            {organizationRegion ? (
              <>Edit Organization Region</>
            ) : (
              <>New Region</>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            {!organizationId && (
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Organization
                  </Form.Label>
                </Col>
                <Col sm="12" md={7}>
                  <OrganizationSearchInput
                    dropdown
                    formik={formik}
                    error={formik.errors.organizationId}
                  />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Region
                </Form.Label>
              </Col>
              <Col sm="12" md={7}>
                <RegionSearchInput
                  dropdown
                  formik={formik}
                  error={formik.errors.regionId}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Default
                </Form.Label>
              </Col>
              <Col sm="12" md={7}>
                <Form.Check
                  className="ml-1 mt-1"
                  type="checkbox"
                  checked={formik.values.default}
                  onChange={(e) => {
                    formik.setFieldValue('default', e.target.checked)
                  }}
                  isInvalid={formik.errors.default}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.default}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Row className="mt-2">
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  type="submit"
                  variant="outline-primary"
                  block
                  disabled={submitting}
                >
                  <PlusCircle className="mr-2" />
                  Save
                </Button>
              </Col>
            </Form.Row>

            {organizationRegion && !organizationRegion.default && (
              <Form.Row className="mt-2">
                <Col md={{ span: 8, offset: 2 }}>
                  <Button
                    variant="outline-danger"
                    onClick={handleDeleteClick}
                    block
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              </Form.Row>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default OrganizationRegionModal
