import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import EmployeeScheduleModal from './EmployeeScheduleModal'
import { DateTime } from 'luxon'
import DateFilter from '../../common/DateFilter'
import EmployeeScheduleCalendar from './EmployeeScheduleCalendar'
import { CalendarWeek, List, PlusCircle } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import { useDateTimeConverter } from '../../../libs/useDateTime'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { formatRegion, formatTimezone } from '../../../libs/utils'
import { useFormik } from 'formik'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'

const EmployeeSchedule = (props) => {
  const { employeeNode } = props
  const { toTimezone, toUTC } = useDateTimeConverter()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const now = new Date()
  let initialStartDateFilter = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
  )
  let initialEndDateFilter = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 7, 23, 59, 59)
  )

  if (
    initialEndDateFilter &&
    initialEndDateFilter.getMonth() !== now.getMonth()
  ) {
    initialEndDateFilter = new Date(
      Date.UTC(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
    )
  }

  const getUtcDt = (jobDateDt) => {
    return DateTime.utc(
      jobDateDt.getFullYear(),
      jobDateDt.getMonth() + 1,
      jobDateDt.getDate(),
      jobDateDt.getHours(),
      jobDateDt.getMinutes()
    )
  }

  const [searchTerm, setSearchTerm] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [showNewEditModal, setShowNewEditModal] = useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [employeeEditing, setEmployeeEditing] = useState(false)
  const [hasMoreEmployeesSchedule, setHasMoreEmployeesSchedule] = useState(true)
  const [view, setView] = useState('list')
  const [editNode, setEditNode] = useState()
  const [startDateFilter, setStartDateFilter] = useState(
    getUtcDt(initialStartDateFilter)
  )
  const [endDateFilter, setEndDateFilter] = useState(
    getUtcDt(initialEndDateFilter)
  )
  const [calendarStartDateFilter, setCalendarStartDateFilter] = useState()
  const [calendarEndDateFilter, setCalendarEndDateFilter] = useState()
  const [calendarDefaultDate, setCalendarDefaultDate] = useState()
  const [calendarDefaultView, setCalendarDefaultView] = useState('week')
  const [queryFirst, setQueryFirst] = useState(25)

  const formik = useFormik({
    initialValues: {
      regions: loggedInUser?.defaultRegions,
      timezone: loggedInUser?.defaultRegion?.formattedTimezone,
    },
  })

  useEffect(() => {
    formik.setFieldValue('regions', loggedInUser?.defaultRegions)
  }, [loggedInUser?.defaultRegions])

  const adminTableColumns = [
    {
      Header: 'Employee',
      accessor: (row) => row.node.employee?.gaiaUser.fullName,
    },
    {
      Header: 'Event Type',
      accessor: (row) => {
        if (row.node.eventType === 'EMPLOYEE_WORKING') return 'Working'
        if (row.node.eventType === 'EMPLOYEE_PTO') return 'Time off'
        if (row.node.eventType === 'EMPLOYEE_SICK') return 'Sick'
        return row.node.eventType
      },
    },
    {
      Header: 'Location',
      accessor: (row) => {
        let locationDescr = ''
        if (row.node.location) {
          const location = row.node.location
          locationDescr = location.name
            ? `${location.name} ${location.fullAddress}`
            : location.fullAddress
        }
        return locationDescr
      },
    },
    {
      Header: 'Status',
      accessor: (row) => {
        if (row.node.approvalStatus === 'PENDING') return 'Pending'
        if (row.node.approvalStatus === 'APPROVED') return 'Approved'
        if (row.node.approvalStatus === 'DENIED') return 'Denied'
        return row.node.approvalStatus
      },
    },
    {
      Header: 'Start',
      accessor: (row) => {
        const timezone = formatTimezone(row.node.region?.timezone)
        return toTimezone(row.node.startDateTime, {
          humanReadable: true,
          timezone: timezone,
        })
      },
    },
    {
      Header: 'End',
      accessor: (row) => {
        const timezone = formatTimezone(row.node.region?.timezone)
        return toTimezone(row.node.endDateTime, {
          humanReadable: true,
          timezone: timezone,
        })
      },
    },
    {
      Header: 'Submitted',
      accessor: (row) => {
        const timezone = formatTimezone(row.node.region?.timezone)
        return toTimezone(row.node.created, {
          humanReadable: true,
          timezone: timezone,
        })
      },
    },
  ]

  const employeeTableColumns = [
    {
      Header: 'Event Type',
      accessor: (row) => {
        if (row.node.eventType === 'EMPLOYEE_WORKING') return 'Working'
        if (row.node.eventType === 'EMPLOYEE_PTO') return 'Time off'
        if (row.node.eventType === 'EMPLOYEE_SICK') return 'Sick'
        return row.node.eventType
      },
    },
    {
      Header: 'Location',
      accessor: (row) => {
        let locationDescr = ''
        if (row.node.location) {
          const location = row.node.location
          locationDescr = location.name
            ? `${location.name} ${location.fullAddress}`
            : location.fullAddress
        }
        return locationDescr
      },
    },
    {
      Header: 'Status',
      accessor: (row) => {
        if (row.node.approvalStatus === 'PENDING') return 'Pending'
        if (row.node.approvalStatus === 'APPROVED') return 'Approved'
        if (row.node.approvalStatus === 'DENIED') return 'Denied'
        return row.node.approvalStatus
      },
    },
    {
      Header: 'Start',
      accessor: (row) => {
        const timezone = formatTimezone(row.node.region?.timezone)
        return toTimezone(row.node.startDateTime, {
          humanReadable: true,
          timezone: timezone,
        })
      },
    },
    {
      Header: 'End',
      accessor: (row) => {
        const timezone = formatTimezone(row.node.region?.timezone)
        return toTimezone(row.node.endDateTime, {
          humanReadable: true,
          timezone: timezone,
        })
      },
    },
    {
      Header: 'Submitted',
      accessor: (row) => {
        const timezone = formatTimezone(row.node.region?.timezone)
        return toTimezone(row.node.created, {
          humanReadable: true,
          timezone: timezone,
        })
      },
    },
  ]

  if (
    loggedInUser?.canManageRegions &&
    settings?.tenantRegions &&
    formik?.values.regions.length > 1
  ) {
    employeeTableColumns.push({
      Header: 'Region',
      id: 'region',
      accessor: (row) => <>{formatRegion(row.node.region)}</>,
    })
    adminTableColumns.push({
      Header: 'Region',
      id: 'region',
      accessor: (row) => <>{formatRegion(row.node.region)}</>,
    })
  }

  const [query, { error, data, fetchMore }] = useLazyQuery(
    gql`
      query EmployeeSchedule(
        $cursor: String
        $searchTerm: String
        $employeeId: ID
        $startDateGte: DateTime
        $endDateLte: DateTime
        $first: Int
        $regionIds: [ID]
      ) {
        employeeSchedules(
          orderBy: "-start_date_time"
          first: $first
          search: $searchTerm
          employee: $employeeId
          after: $cursor
          startDateTime_Gte: $startDateGte
          endDateTime_Lte: $endDateLte
          region: $regionIds
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              startDateTime
              endDateTime
              hourlyPay
              eventType
              approvalStatus
              created
              notes
              region {
                id
                name
                timezone
              }
              contentType {
                model
                id
              }
              employee {
                id
                gaiaUser {
                  id
                  fullName
                }
              }
              location {
                id
                name
                fullAddress
                addressLineTwo
                addressLineOne
                city
                state
                zipCode
                organization {
                  name
                }
              }
              approvingEmployee {
                gaiaUser {
                  fullName
                  id
                }
              }
              timesheet {
                id
                timesheetEvents(orderBy: "created") {
                  edges {
                    node {
                      id
                      dateTime
                      eventType
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          employeeId: employeeNode?.id,
          first: queryFirst,
        },
      })
    }
  }, [initialQueryRun])

  useEffect(() => {
    if (data?.employeeSchedules) {
      setLoadingSearch(false)
      setHasMoreEmployeesSchedule(data.employeeSchedules.pageInfo.hasNextPage)
      if (editNode) {
        const updatedNode = data.employeeSchedules.edges.find(
          (edge) => edge.node.id === editNode.id
        )?.node
        if (updatedNode) setEditNode(updatedNode)
      }
    }
  }, [data, editNode])

  const fetchMoreResults = () => {
    fetchMore({
      variables: {
        ...constructQueryVariables(),
        cursor: data?.employeeSchedules?.pageInfo.endCursor,
      },
    })
  }

  const constructQueryVariables = useCallback(() => {
    const variables = {
      first: queryFirst,
      searchTerm,
      employeeId: employeeNode?.id,
    }

    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    } else if (view === 'calendar' && calendarStartDateFilter) {
      variables.startDateGte = toUTC(calendarStartDateFilter, {
        endOfDay: true,
        timezone: formik.values.timezone,
      })
    }

    if (endDateFilter) {
      variables.endDateLte = endDateFilter
    } else if (view === 'calendar' && calendarEndDateFilter) {
      variables.endDateLte = toUTC(calendarStartDateFilter, {
        endOfDay: true,
        timezone: formik.values.timezone,
      })
    }

    if (formik.values.regions.length > 0) {
      variables.regionIds = formik.values.regions.map((region) => region.id)
    }

    return variables
  }, [
    queryFirst,
    searchTerm,
    employeeNode?.id,
    startDateFilter,
    endDateFilter,
    view,
    calendarStartDateFilter,
    calendarEndDateFilter,
    formik.values.timezone,
    formik.values.regions,
  ])

  const toggleNewModal = () => {
    setShowNewEditModal(!showNewEditModal)
  }

  const toggleEditModal = (node = null) => {
    setEditNode(node)
    setShowNewEditModal(!showNewEditModal)
  }

  const onRowClick = (row) => {
    if (employeeNode) {
      setEmployeeEditing(true)
    }
    toggleEditModal(row.original.node)
  }

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = constructQueryVariables()
      variables.searchTerm = searchValue || undefined
      variables.cursor = null
      setLoadingSearch(true)
      console.log('variables')
      console.log(variables)
      query({ variables })
    },
    [constructQueryVariables, query]
  )

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value || undefined
    setSearchTerm(currentSearchTerm)

    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const newTimeout = setTimeout(() => {
      executeSearchQuery(currentSearchTerm)
    }, 500)

    setDebounceTimeout(newTimeout)
  }

  const calendarDateTimes = () => {
    let currentCalendarDefaultDate = calendarDefaultDate || new Date()
    const month = currentCalendarDefaultDate.getMonth()
    const year = currentCalendarDefaultDate.getFullYear()
    return {
      currentCalendarDefaultDate,
      currentCalendarStartDateFilter: new Date(year, month - 1),
      currentCalendarEndDateFilter: new Date(year, month + 2),
    }
  }

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  useEffect(() => {
    if (initialQueryRun) {
      if (view === 'calendar' && (!startDateFilter || !endDateFilter)) {
        const { currentCalendarStartDateFilter, currentCalendarEndDateFilter } =
          calendarDateTimes()
        if (!startDateFilter) {
          setCalendarStartDateFilter(currentCalendarStartDateFilter)
        }
        if (!endDateFilter) {
          setCalendarEndDateFilter(currentCalendarEndDateFilter)
        }
      }
      const variables = constructQueryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [startDateFilter, endDateFilter])

  useEffect(() => {
    if (initialQueryRun) {
      const variables = constructQueryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [calendarEndDateFilter, calendarStartDateFilter, formik.values.regions])

  const handleViewChange = () => {
    if (view === 'list') {
      const { currentCalendarStartDateFilter, currentCalendarEndDateFilter } =
        calendarDateTimes()
      if (!startDateFilter || !endDateFilter) {
        setQueryFirst(25)
        if (!startDateFilter) {
          setCalendarStartDateFilter(currentCalendarStartDateFilter)
        }
        if (!endDateFilter) {
          setCalendarEndDateFilter(currentCalendarEndDateFilter)
        }
      }
      setView('calendar')
    } else {
      setCalendarStartDateFilter(null)
      setCalendarEndDateFilter(null)
      setQueryFirst(25)
      query({
        variables: {
          first: queryFirst,
          employeeId: employeeNode?.id,
        },
      })
      setView('list')
    }
  }

  if (!initialQueryRun && !data) {
    return (
      <Row>
        <Col>
          <Loading message="Loading Work Schedule..." />
        </Col>
      </Row>
    )
  }

  if (error) return <>Error loading employee schedule</>

  const calendarSchedules =
    data?.employeeSchedules?.edges.map((edge) => ({
      node: edge.node,
      id: edge.node.id,
      startDateTime: new Date(edge.node.startDateTime),
      endDateTime: new Date(edge.node.endDateTime),
      calendarName: `${edge.node.employee?.gaiaUser?.fullName || ''} ${
        edge.node.approvalStatus === 'PENDING'
          ? 'Pending'
          : edge.node.approvalStatus === 'APPROVED'
            ? 'Approved'
            : 'Denied'
      } ${
        edge.node.eventType === 'EMPLOYEE_WORKING'
          ? 'Working'
          : edge.node.eventType === 'EMPLOYEE_PTO'
            ? 'Time off'
            : 'Sick'
      }`,
    })) || []

  return (
    <>
      <div>
        {employeeNode ? (
          <>
            <Row>
              <Col>
                <Button
                  variant="link"
                  onClick={handleViewChange}
                  className="mr-2"
                >
                  <div className="d-flex align-items-center">
                    {view === 'calendar' ? (
                      <CalendarWeek className="text-primary mr-1" />
                    ) : (
                      <List className="text-primary mr-1" />
                    )}
                    {view === 'calendar' ? (
                      <span>Calendar</span>
                    ) : (
                      <span>List</span>
                    )}
                  </div>
                </Button>
                <Button variant="link" onClick={toggleNewModal}>
                  <PlusCircle className="mr-2" />
                  New Work Event
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <Button
                  variant="link"
                  className="mr-2"
                  onClick={handleViewChange}
                >
                  <div className="d-flex align-items-center">
                    {view === 'calendar' ? (
                      <CalendarWeek className="text-primary mr-1" />
                    ) : (
                      <List className="text-primary mr-1" />
                    )}
                    {view === 'calendar' ? (
                      <span>Calendar</span>
                    ) : (
                      <span>List</span>
                    )}
                  </div>
                </Button>
                <Button variant="link" onClick={toggleNewModal}>
                  <PlusCircle className="mr-2" />
                  New Event
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="searchTerm"
                    className="form-control-sm"
                    placeholder={'Search Events'}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                </Form.Group>
              </Col>
              <Col md={'auto'} style={{ marginTop: '-8px' }}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="Event From"
                  placeholderEnd="Event Until"
                  timezone={formik.values.timezone}
                />
              </Col>
              {loggedInUser?.canManageRegions && settings?.tenantRegions && (
                <Col md={2}>
                  <RegionSearchInput
                    placeholder="Select Region"
                    formik={formik}
                    dropdown
                    multiple
                    mustHaveValue={
                      loggedInUser.permissions.group === 'Administrator'
                        ? false
                        : true
                    }
                    setAdditionalFields={(node, _) => {
                      if (node?.timezone) {
                        formik.setFieldValue(
                          'timezone',
                          formatTimezone(node.timezone)
                        )
                      }
                    }}
                  />
                </Col>
              )}
            </Row>
          </>
        )}
        <Row className="mt-2">
          <Col>
            {view === 'list' ? (
              <SortableInfiniteTable
                loading={loadingSearch || (!initialQueryRun && !data)}
                tableData={data?.employeeSchedules?.edges || []}
                tableColumns={
                  employeeNode ? employeeTableColumns : adminTableColumns
                }
                fetchMoreTableData={fetchMoreResults}
                hasMoreTableData={hasMoreEmployeesSchedule}
                tableHeight={700}
                loadingMessage="Loading Work Schedule..."
                hideGlobalFilter
                onRowClick={onRowClick}
                rowPointer
              />
            ) : (
              <EmployeeScheduleCalendar
                employeeSchedules={calendarSchedules}
                startDateFilter={startDateFilter}
                endDateFilter={endDateFilter}
                calendarStartDateFilter={calendarStartDateFilter}
                calendarEndDateFilter={calendarEndDateFilter}
                setCalendarStartDateFilter={setCalendarStartDateFilter}
                setCalendarEndDateFilter={setCalendarEndDateFilter}
                setCalendarDefaultDate={setCalendarDefaultDate}
                calendarDefaultDate={calendarDefaultDate}
                calendarDefaultView={calendarDefaultView}
                setCalendarDefaultView={setCalendarDefaultView}
                toggleEditModal={toggleEditModal}
              />
            )}
          </Col>
        </Row>
      </div>
      {showNewEditModal && (
        <EmployeeScheduleModal
          showModal={showNewEditModal}
          toggleModal={toggleEditModal}
          editNode={editNode}
          employeeNode={employeeNode}
          employeeEditing={employeeEditing}
          setEmployeeEditing={setEmployeeEditing}
        />
      )}
    </>
  )
}

export default EmployeeSchedule
