import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql, useReactiveVar, useMutation } from '@apollo/client'
import { Row, Col, Table, Form, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { settingsVar } from '../../libs/apollo'
import DateFilter from '../common/DateFilter'
import Loading from '../common/Loading'
import toast from 'react-hot-toast'
import { useDownloadFile } from '../../libs/downloadFile'
import { CloudArrowDown } from 'react-bootstrap-icons'
import { useDateTimeConverter } from '../../libs/useDateTime'

const AuditLog = (props) => {
  const {
    contentType,
    id,
    contentTypesRelayIds,
    tableHeight,
    searchWidth,
    fetchPolicy,
    includeContentType,
  } = props
  const settings = useReactiveVar(settingsVar)
  const { downloadAndDeleteFile } = useDownloadFile()
  const { toTimezone } = useDateTimeConverter()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [cursor, setCursor] = useState()
  const [events, setEvents] = useState([])
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [hasMoreEvents, setHasMoreEvents] = useState(true)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  let tableHeight_ = tableHeight ? tableHeight : 600

  const [downloadLogEntries] = useMutation(
    gql`
      mutation DownloadLogEntries($input: DownloadLogEntriesInput!) {
        downloadLogEntries(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadLogEntries.file.fileName,
          data.downloadLogEntries.file.displayName,
          data.downloadLogEntries.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query LogEntries(
        $cursor: String
        $contentTypesRelayIds: String
        $contentType: String
        $relayId: String
        $searchTerm: String
        $startDateTimeGte: DateTime
        $startDateTimeLte: DateTime
      ) {
        logEntries(
          first: 50
          after: $cursor
          contentType_Model: $contentType
          relayId: $relayId
          timestamp_Gte: $startDateTimeGte
          timestamp_Lte: $startDateTimeLte
          objectRepr_Iexact: $searchTerm
          contentTypesRelayIds: $contentTypesRelayIds
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              timestamp
              objectPk
              changes
              action
              objectRepr
              apiCalls {
                edges {
                  node {
                    id
                    apiKey {
                      name
                    }
                  }
                }
              }
              actor {
                fullName
                email
                subject {
                  id
                }
                employee {
                  id
                }
                organizationContacts {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
              contentType {
                model
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: fetchPolicy ? fetchPolicy : 'network-only',
      errorPolicy: 'all',
      variables: {
        contentType,
        relayId: id,
        contentTypesRelayIds: contentTypesRelayIds
          ? JSON.stringify(contentTypesRelayIds)
          : null,
      },
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query()
    }
  }, [initialQueryRun])

  useEffect(() => {
    if (queryData?.logEntries) {
      setCursor(queryData.logEntries.pageInfo.endCursor)
      setHasMoreEvents(queryData.logEntries.pageInfo.hasNextPage)
      setEvents(queryData.logEntries.edges)
    }
  }, [queryData])

  const fetchMoreEvents = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (startDateFilter) {
      variables.startDateTimeGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateTimeLte = endDateFilter
    }
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        setCursor(fetchMoreResult.logEntries.pageInfo.endCursor)
        setHasMoreEvents(fetchMoreResult.logEntries.pageInfo.hasNextPage)
        setEvents((prevEvents) => [
          ...prevEvents,
          ...fetchMoreResult.logEntries.edges,
        ])
        return prev
      },
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      setCursor(null)
      const variables = {
        cursor: null,
        searchTerm,
        startDateTimeGte: startDateFilter,
        startDateTimeLte: endDateFilter,
      }
      query({ variables })
    }
  }, [startDateFilter, endDateFilter])

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = {
        cursor: null, // Reset cursor for fresh search
        searchTerm: searchValue || undefined,
        startDateTimeGte: startDateFilter,
        startDateTimeLte: endDateFilter,
        contentType,
        relayId: id,
        contentTypesRelayIds: contentTypesRelayIds
          ? JSON.stringify(contentTypesRelayIds)
          : null,
      }
      query({ variables })
    },
    [
      startDateFilter,
      endDateFilter,
      contentType,
      id,
      contentTypesRelayIds,
      query,
    ]
  )

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value || undefined
    setSearchTerm(currentSearchTerm)

    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const newTimeout = setTimeout(() => {
      executeSearchQuery(currentSearchTerm)
    }, 500) // 500ms delay

    setDebounceTimeout(newTimeout)
  }

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const tableColumns = [
    {
      Header: `Time (${settings.timezone})`,
      accessor: (row) => {
        return toTimezone(row.node.timestamp, {
          standard: true,
          settingTimezone: true,
        })
      },
    },
    {
      Header: 'Action',
      accessor: (row) => {
        let action
        if (row.node.action.includes('0')) {
          action = 'Create'
        } else if (row.node.action.includes('1')) {
          action = 'Update'
        } else if (row.node.action.includes('2')) {
          action = 'Delete'
        } else if (row.node.action.includes('3')) {
          action = 'Access'
        }
        return action
      },
    },
    {
      Header: 'User',
      accessor: (row) => {
        return row.node.actor?.fullName
      },
    },
    {
      Header: 'User Type',
      accessor: (row) => {
        let type
        if (row.node.actor?.employee?.id) {
          type = 'Employee'
        } else if (row.node.actor?.subject?.id) {
          type = 'Subject'
        } else if (row.node.actor?.organizationContacts?.edges?.length > 0) {
          type = 'Organization Contact'
        } else if (row.node?.apiCalls?.edges.length > 0) {
          type = 'API'
        } else {
          type = 'System'
        }
        return type
      },
    },
    {
      Header: 'Changes',
      accessor: (row) => {
        if (row.node.changes) {
          let action
          if (row.node.action.includes('0')) {
            action = 'Create'
          } else if (row.node.action.includes('1')) {
            action = 'Update'
          } else if (row.node.action.includes('2')) {
            action = 'Delete'
          } else if (row.node.action.includes('3')) {
            action = 'Access'
          }
          if (action === 'Create') {
            return (
              <Table>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(JSON.parse(row.node.changes)).map(
                    (values, i) => {
                      let field = values[0]
                      if (field === 'gaia_user') {
                        field = 'user'
                      }
                      return (
                        <tr key={i}>
                          <td>{field}</td>
                          <td>{values[1][1]}</td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </Table>
            )
          } else if (action === 'Update') {
            return (
              <Table>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Initial</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(JSON.parse(row.node.changes)).map(
                    (values, i) => {
                      let field = values[0]
                      if (field === 'gaia_user') {
                        field = 'user'
                      }
                      return (
                        <tr key={i}>
                          <td>{field}</td>
                          <td>{values[1][0]}</td>
                          <td>{values[1][1]}</td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </Table>
            )
          }
        }
      },
    },
  ]

  if (includeContentType) {
    tableColumns.splice(1, 0, {
      Header: 'Record Type',
      accessor: (row) => {
        return row.node.contentType?.model === 'gaiauser'
          ? 'user'
          : row.node.contentType?.model
      },
    })
  }

  if ((!initialQueryRun && !queryData) || !settings)
    return <Loading message="Loading History..." />
  if (queryError) return <>Error loading audit log</>

  return (
    <>
      <div>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                size="sm"
                type="text"
                name="searchTerm"
                placeholder={'Search Records'}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col md={'auto'} style={{ marginTop: '-8px' }}>
            <DateFilter
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              placeholderStart="History From"
              placeholderEnd="History Until"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <SortableInfiniteTable
              searchWidth={searchWidth}
              hideGlobalFilter
              loadingMessage="Loading History..."
              tableColumns={tableColumns}
              tableData={events}
              fetchMoreTableData={fetchMoreEvents}
              hasMoreTableData={hasMoreEvents}
              tableHeight={tableHeight_}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AuditLog
