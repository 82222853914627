import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import Report from '../chart/LineChart'
import DateFilter from '../common/DateFilter'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import Loading from '../common/Loading'
import { Cash, Funnel } from 'react-bootstrap-icons'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import { formatRegion, formatTimezone } from '../../libs/utils'
import { useFormik } from 'formik'

const PaymentChartModal = (props) => {
  const {
    showModal,
    toggleModal,
    organizationId,
    subjectGroupId,
    subjectId,
    sessionId,
    jobId,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [chartSeries, setChartSeries] = useState([])
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [averageRevenue, setAverageRevenue] = useState(0)
  const [totalFees, setTotalFees] = useState(0)
  const [averageFees, setAverageFees] = useState(0)
  const [chartCategories, setChartCategories] = useState([])
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [paymentIntents, setPaymentIntents] = useState([])
  const [revenueType, setRevenueType] = useState('Total Revenue')
  const [fetchedAllPaymentIntents, setFetchedAllPaymentIntents] =
    useState(false)
  const [filterDeclined, setFilterDecline] = useState(false)
  const [filterInvoice, setFilterInvoice] = useState(false)
  const [filterSession, setFilterSession] = useState(false)
  const [averageDays, setAverageDays] = useState(1)

  const generateDateRange = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const dates = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }
    return dates
  }

  const [query, { data: stripePaymentIntentData, loading }] = useLazyQuery(
    gql`
      query StripePaymentIntentsQuery(
        $cursor: String
        $job: String
        $startDateGte: DateTime
        $startDateLte: DateTime
        $organization: String
        $subjectGroup: ID
        $session: String
        $subject: ID
        $declined: Boolean
        $invoices: Boolean
        $sessions: Boolean
        $regionIds: [ID]
      ) {
        stripePaymentIntents(
          first: 250
          declined: $declined
          invoices: $invoices
          job: $job
          sessions: $sessions
          refunded: false
          after: $cursor
          session_Subject_Id: $subject
          session: $session
          organization: $organization
          session_SubjectGroup_Id: $subjectGroup
          updated_Gte: $startDateGte
          updated_Lte: $startDateLte
          region: $regionIds
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              created
              amount
              netAmount
              fee
              region {
                id
                name
                timezone
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: {
      regions: [],
      timezone: loggedInUser?.defaultRegion?.formattedTimezone,
    },
    validateOnChange: false,
  })

  const queryVariables = () => {
    const variables = {
      startDateGte: startDateFilter,
      startDateLte: endDateFilter,
      session: sessionId,
      organization: organizationId,
      subjectGroup: subjectGroupId,
      subject: subjectId,
      job: jobId,
    }
    if (filterDeclined) {
      variables.declined = true
    }
    if (filterSession) {
      variables.sessions = true
    }
    if (filterInvoice) {
      variables.invoices = true
    }
    if (formik.values.regions.length > 0) {
      variables.regionIds = formik.values.regions.map((region) => region.id)
    }
    return variables
  }

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      const daysDiff = Math.max(
        1,
        Math.ceil(
          (new Date(endDateFilter) - new Date(startDateFilter)) /
            (1000 * 60 * 60 * 24)
        ) + 1
      )
      setAverageDays(daysDiff)
      query({ variables: queryVariables() })
    }
    setFetchedAllPaymentIntents(false)
    setChartSeries([])
    setPaymentIntents([])
    setChartCategories([])
  }, [
    startDateFilter,
    endDateFilter,
    filterDeclined,
    filterSession,
    filterInvoice,
    formik.values.regions,
  ])

  useEffect(() => {
    if (stripePaymentIntentData?.stripePaymentIntents) {
      setPaymentIntents((prevState) => [
        ...prevState,
        ...stripePaymentIntentData.stripePaymentIntents.edges,
      ])
      if (stripePaymentIntentData.stripePaymentIntents.pageInfo.hasNextPage) {
        const variables = {
          ...queryVariables(),
          cursor:
            stripePaymentIntentData.stripePaymentIntents.pageInfo.endCursor,
        }
        query({ variables })
      } else {
        setFetchedAllPaymentIntents(true)
      }
    }
  }, [stripePaymentIntentData])

  useEffect(() => {
    if (fetchedAllPaymentIntents) {
      const dateRange = generateDateRange(startDateFilter, endDateFilter)
      const aggregatedData = {}
      paymentIntents.forEach(({ node }) => {
        const date = new Date(node.created).toISOString().split('T')[0]
        const region = formatRegion(node.region)
        const amount = node.amount / 100
        const fee = node.fee / 100
        const netAmount = node.netAmount / 100

        if (!aggregatedData[region]) {
          aggregatedData[region] = {}
        }
        if (!aggregatedData[region][date]) {
          aggregatedData[region][date] = { amount: 0, fee: 0, netAmount: 0 }
        }

        aggregatedData[region][date].amount += amount
        aggregatedData[region][date].fee += fee
        aggregatedData[region][date].netAmount += netAmount
      })

      setChartCategories(dateRange)
      const totalRevenue_ = Object.values(aggregatedData).reduce(
        (sum, regionData) =>
          sum + Object.values(regionData).reduce((s, d) => s + d.amount, 0),
        0
      )
      const totalFees_ = Object.values(aggregatedData).reduce(
        (sum, regionData) =>
          sum + Object.values(regionData).reduce((s, d) => s + d.fee, 0),
        0
      )

      setTotalRevenue(
        totalRevenue_.toLocaleString('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )
      setTotalFees(
        totalFees_.toLocaleString('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )

      setAverageRevenue(
        (totalRevenue_ / averageDays).toLocaleString('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )
      setAverageFees(
        (totalFees_ / averageDays).toLocaleString('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )

      let series = []
      Object.keys(aggregatedData).forEach((region) => {
        let amountSeriesData = []
        let feeSeriesData = []
        let netAmountSeriesData = []

        if (revenueType === 'Daily Revenue') {
          amountSeriesData = dateRange.map(
            (date) => aggregatedData[region][date]?.amount || 0
          )
          feeSeriesData = dateRange.map(
            (date) => aggregatedData[region][date]?.fee || 0
          )
          netAmountSeriesData = dateRange.map(
            (date) => aggregatedData[region][date]?.netAmount || 0
          )
        } else {
          let runningTotalAmount = 0
          let runningTotalFee = 0
          let runningTotalNetAmount = 0
          amountSeriesData = dateRange.map((date) => {
            runningTotalAmount += aggregatedData[region][date]?.amount || 0
            return runningTotalAmount
          })
          feeSeriesData = dateRange.map((date) => {
            runningTotalFee += aggregatedData[region][date]?.fee || 0
            return runningTotalFee
          })
          netAmountSeriesData = dateRange.map((date) => {
            runningTotalNetAmount +=
              aggregatedData[region][date]?.netAmount || 0
            return runningTotalNetAmount
          })
        }
        const regionSuffix =
          loggedInUser?.canManageRegions && settings?.tenantRegions
            ? ` (${region})`
            : ''
        series.push(
          { name: `Total Revenue${regionSuffix}`, data: amountSeriesData },
          { name: `Net Revenue${regionSuffix}`, data: netAmountSeriesData },
          { name: `Stripe Fees${regionSuffix}`, data: feeSeriesData }
        )
      })
      setChartSeries(series)
    }
  }, [fetchedAllPaymentIntents, startDateFilter, endDateFilter, revenueType])

  const innerToggle = () => {
    setChartCategories([])
    setChartSeries([])
    toggleModal()
  }

  return (
    <>
      <div className="invoiceModal">
        <Modal size={'xl'} show={showModal} onHide={innerToggle}>
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              <Cash className="mr-2" />
              Payment Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col md={'auto'}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="Payments From"
                  placeholderEnd={'Payment Until'}
                  timezone={formik.values.timezone}
                />
              </Col>
              <Col className="mt-2" md={2}>
                <Form.Control
                  as="select"
                  className="form-control-sm"
                  value={revenueType}
                  onChange={(e) => setRevenueType(e.target.value)}
                >
                  <option value="Daily Revenue">Daily Revenue</option>
                  <option value="Total Revenue">Total Revenue</option>
                </Form.Control>
              </Col>
              {loggedInUser?.canManageRegions && settings?.tenantRegions && (
                <Col md={2} className="mt-2">
                  <RegionSearchInput
                    formik={formik}
                    dropdown
                    placeholder="Select Region"
                    multiple
                    setAdditionalFields={(node, _) => {
                      if (node?.timezone) {
                        formik.setFieldValue(
                          'timezone',
                          formatTimezone(node.timezone)
                        )
                      }
                    }}
                  />
                </Col>
              )}
            </Row>
            {startDateFilter && endDateFilter && (
              <Row className="mb-4">
                <Col>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterDecline(!filterDeclined)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterDeclined ? <>Declined</> : <>All</>}
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterInvoice(!filterInvoice)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterInvoice ? <>Invoice</> : <>All</>}
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterSession(!filterSession)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterSession ? <>Session</> : <>All</>}
                    </span>
                  </Button>
                </Col>
              </Row>
            )}
            {startDateFilter && endDateFilter && !fetchedAllPaymentIntents && (
              <Row className="mt-4">
                <Col>
                  <Loading message="Loading Payment Chart..." />
                </Col>
              </Row>
            )}
            {fetchedAllPaymentIntents && (
              <>
                <div className="statistics-summary">
                  <Row className="mb-4 justify-content-md-center">
                    <Col md={4} className="statistic">
                      <h5>
                        {revenueType === 'Daily Revenue'
                          ? 'Average Revenue Per Day'
                          : 'Total Revenue'}
                      </h5>
                      <p>
                        $
                        {revenueType === 'Daily Revenue'
                          ? averageRevenue
                          : totalRevenue}
                      </p>
                    </Col>
                    <Col md={4} className="statistic">
                      <h5>
                        {revenueType === 'Daily Revenue'
                          ? 'Average Stripe Fees Per Day'
                          : 'Total Stripe Fees'}
                      </h5>
                      <p>
                        $
                        {revenueType === 'Daily Revenue'
                          ? averageFees
                          : totalFees}
                      </p>
                    </Col>
                    <Col md={4} className="statistic">
                      <h5>
                        {revenueType === 'Daily Revenue'
                          ? 'Average Payments Per Day'
                          : 'Total Payments'}
                      </h5>
                      <p>
                        {revenueType === 'Daily Revenue'
                          ? (
                              paymentIntents.length / averageDays
                            ).toLocaleString('en-US', {
                              style: 'decimal',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : paymentIntents.length.toLocaleString('en-US', {
                              style: 'decimal',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                      </p>
                    </Col>
                  </Row>
                </div>
                {fetchedAllPaymentIntents && (
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Report
                        series={chartSeries}
                        categories={chartCategories}
                        yAxisFormatter={(value) => {
                          if (value < 0.01 && value > -0.01) {
                            return '$0'
                          }
                          return `$${value}`
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default PaymentChartModal
