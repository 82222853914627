import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, useMutation, gql } from '@apollo/client'
import { Row, Col, Button, Form, ButtonGroup, Dropdown } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { PlusCircle, Trash, Funnel } from 'react-bootstrap-icons'
import CustomFieldSetModal from './CustomFieldSetModal'
import { MODEL_TYPES } from './CustomFieldSetForm'
import DeleteCustomFieldSetModal from './DeleteCustomFieldSetModal'
import toast from 'react-hot-toast'

const GET_CUSTOM_FIELD_SETS = gql`
  query CustomFieldSets(
    $cursor: String
    $search: String
    $orderBy: String
    $model: String
  ) {
    customFieldSets(
      first: 100
      after: $cursor
      search: $search
      orderBy: $orderBy
      model: $model
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          model
          customFields
        }
      }
    }
  }
`

const CREATE_CUSTOM_FIELD_SET = gql`
  mutation CreateCustomFieldSet($input: CreateCustomFieldSetInput!) {
    createCustomFieldSet(input: $input) {
      customFieldSet {
        id
        name
        model
        customFields
      }
    }
  }
`

const UPDATE_CUSTOM_FIELD_SET = gql`
  mutation UpdateCustomFieldSet($input: UpdateCustomFieldSetInput!) {
    updateCustomFieldSet(input: $input) {
      customFieldSet {
        id
        name
        model
        customFields
      }
    }
  }
`

const CustomFieldSets = () => {
  // State
  const [checkedIds, setCheckedIds] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [modelFilter, setModelFilter] = useState(null)
  const [orderBy, setOrderBy] = useState('name')
  const [showForm, setShowForm] = useState(false)
  const [editFieldSet, setEditFieldSet] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState(null)

  // GraphQL Operations
  const [query, { data, fetchMore, refetch, loading }] = useLazyQuery(
    GET_CUSTOM_FIELD_SETS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  )

  const [createCustomFieldSet] = useMutation(CREATE_CUSTOM_FIELD_SET, {
    onCompleted: () => {
      toast.success('Custom Field Set Created')
      refetch()
      setShowForm(false)
    },
  })

  const [updateCustomFieldSet] = useMutation(UPDATE_CUSTOM_FIELD_SET, {
    onCompleted: () => {
      toast.success('Custom Field Set Updated')
      refetch()
      setShowForm(false)
    },
  })

  // Combined filter effect
  useEffect(() => {
    const variables = {
      search: searchTerm || undefined,
      model: modelFilter || undefined,
      orderBy,
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const timeout = setTimeout(() => {
      query({ variables })
    }, 500)

    setDebounceTimeout(timeout)

    return () => clearTimeout(timeout)
  }, [searchTerm, modelFilter, orderBy])

  // Initial load
  useEffect(() => {
    query({
      variables: {
        orderBy: 'name',
        search: undefined,
        model: undefined,
      },
    })
  }, [])

  // Handlers
  const handleCheck = (e, row) => {
    e.stopPropagation()
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  const onTdClick = (cell) => {
    if (cell.column.id !== 'actions') {
      toggleModal(cell.row.original.node)
    }
  }

  const toggleModal = (fieldSet = null) => {
    setEditFieldSet(fieldSet)
    setShowForm((prev) => !prev)
  }

  const handleCreateOrUpdate = (fieldSet) => {
    if (fieldSet.id) {
      updateCustomFieldSet({
        variables: {
          input: {
            customFieldSetInput: {
              id: fieldSet.id,
              name: fieldSet.name,
              model: fieldSet.model,
              customFields: JSON.stringify(fieldSet.customFields),
            },
          },
        },
      })
    } else {
      createCustomFieldSet({
        variables: {
          input: {
            customFieldSetInput: {
              name: fieldSet.name,
              model: fieldSet.model,
              customFields: JSON.stringify(fieldSet.customFields),
            },
          },
        },
      })
    }
  }

  const handleDeleteModalToggle = () => {
    setShowDeleteModal(false)
    setCheckedIds([])
  }

  const fetchMoreData = () => {
    const endCursor = data?.customFieldSets?.pageInfo?.endCursor
    if (!endCursor) return

    fetchMore({
      variables: {
        cursor: endCursor,
        search: searchTerm || undefined,
        orderBy,
        model: modelFilter || undefined,
      },
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === 'name') return
    currentOrderBy = currentOrderBy ? currentOrderBy : 'name'
    setOrderBy(currentOrderBy)
  }

  // Components
  const CustomFieldsCell = ({ value }) => {
    try {
      const parsedValue = JSON.parse(value || '{}')
      const fields = Array.isArray(parsedValue)
        ? parsedValue
        : Object.entries(parsedValue).map(([fieldName, fieldValue]) => ({
            fieldName,
            fieldValue,
          }))

      if (fields.length === 0) return <span>None</span>

      return (
        <div className="custom-fields-container">
          <table className="table table-sm bg-light rounded mb-0">
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Field Value</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => (
                <tr key={index}>
                  <td>{field.fieldName}</td>
                  <td>{field.fieldValue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    } catch (error) {
      console.error('Error parsing custom fields:', error)
      return <span>Error displaying fields</span>
    }
  }

  const ModelFilter = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="link" id="model-filter-dropdown">
          <Funnel className="mr-2" />
          {modelFilter
            ? MODEL_TYPES.find((t) => t.value === modelFilter)?.label ||
              modelFilter
            : 'All Models'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => setModelFilter(null)}
            active={!modelFilter}
          >
            All Models
          </Dropdown.Item>
          {MODEL_TYPES.map((type) => (
            <Dropdown.Item
              key={type.value}
              onClick={() => setModelFilter(type.value)}
              active={modelFilter === type.value}
            >
              {type.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              className="form-control-sm"
              type="text"
              placeholder="Search Custom Field Sets"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <div className="d-flex align-items-center">
            <ModelFilter />
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <PlusCircle className="mr-2" />
              New Custom Field Set
            </Button>
            {checkedIds.length > 0 && (
              <Button
                variant="link"
                onClick={() => {
                  setShowDeleteModal(true)
                }}
              >
                <Trash className="mr-2" />
                {checkedIds.length === 1 ? (
                  <>Delete Field Set</>
                ) : (
                  <>Delete Field Sets</>
                )}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md={12}>
          <SortableInfiniteTable
            tableData={data?.customFieldSets?.edges || []}
            loading={loading}
            loadingMessage="Loading Custom Field Sets..."
            fetchMoreTableData={fetchMoreData}
            hasMoreTableData={data?.customFieldSets?.pageInfo?.hasNextPage}
            tableHeight={500}
            tableColumns={[
              {
                Header: 'Name',
                accessor: 'node.name',
                serverSort: true,
                id: 'name',
                orderBy: 'name',
              },
              {
                Header: 'Record Type',
                accessor: 'node.model',
                serverSort: true,
                id: 'model',
                orderBy: 'model',
                Cell: ({ value }) => {
                  const type = MODEL_TYPES.find(
                    (t) => t.value === value.toLowerCase()
                  )
                  return type ? type.label : value
                },
              },
              {
                Header: 'Custom Fields',
                accessor: 'node.customFields',
                id: 'customFields',
                Cell: ({ value }) => <CustomFieldsCell value={value} />,
                disableSortBy: true,
              },
              {
                disableSortBy: true,
                Header: (
                  <>
                    <Form.Group as={ButtonGroup} className="align-items-center">
                      <Form.Check
                        className="ml-2 mt-2"
                        type="checkbox"
                        onChange={(e) => {
                          e.stopPropagation()
                          if (e.target.checked) {
                            const appendIds = []
                            data?.customFieldSets?.edges.forEach((fieldSet) => {
                              if (!checkedIds.includes(fieldSet.node.id)) {
                                appendIds.push(fieldSet.node.id)
                              }
                            })
                            setCheckedIds((prevState) => {
                              return [...prevState, ...appendIds]
                            })
                          } else {
                            setCheckedIds([])
                          }
                        }}
                      />
                      {checkedIds.length > 0 && (
                        <span style={{ fontSize: '14px', marginTop: '5px' }}>
                          ({checkedIds.length})
                        </span>
                      )}
                    </Form.Group>
                  </>
                ),
                id: 'actions',
                accessor: (row) => {
                  return (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          checked={checkedIds.includes(row.node.id)}
                          onChange={(e) => handleCheck(e, row)}
                        />
                      </Form.Group>
                    </>
                  )
                },
              },
            ]}
            onTdClicks={{
              name: (cell) => onTdClick(cell),
              model: (cell) => onTdClick(cell),
              customFields: (cell) => onTdClick(cell),
            }}
            rowPointer
            hideGlobalFilter
            handleSortByChange={handleSortByChange}
          />
        </Col>
      </Row>
      {showForm && (
        <CustomFieldSetModal
          showModal={showForm}
          fieldSet={editFieldSet}
          toggleModal={toggleModal}
          onSubmit={handleCreateOrUpdate}
        />
      )}
      <DeleteCustomFieldSetModal
        refetch={refetch}
        showModal={showDeleteModal}
        toggleModal={handleDeleteModalToggle}
        ids={checkedIds}
      />
    </>
  )
}

export default CustomFieldSets
