import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Report from '../../chart/LineChart'
import DateFilter from '../../common/DateFilter'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import Loading from '../../common/Loading'
import moment from 'moment'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { useFormik } from 'formik'
import { formatTimezone } from '../../../libs/utils'

const JobReport = (props) => {
  const { showModal, organizationId, subjectGroupId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [chartSeries, setChartSeries] = useState([])
  const [chartCategories, setChartCategories] = useState([])
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [viewType, setViewType] = useState('Jobs Per Day')
  const [jobs, setJobs] = useState([])
  const [fetchedAllJobs, setFetchedAllJobs] = useState(false)

  const generateDateRange = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const dates = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }
    return dates
  }

  const [query, { data: jobsData, loading }] = useLazyQuery(
    gql`
      query JobReportData(
        $cursor: String
        $startDateGte: DateTime
        $endDateLte: DateTime
        $subjectGroups: [ID]
        $organizations: [ID]
        $regionId: ID
      ) {
        jobs(
          first: 250
          after: $cursor
          subjectGroups: $subjectGroups
          organizations: $organizations
          startDateTime_Gte: $startDateGte
          endDateTime_Lte: $endDateLte
          cancelled: false
          region: $regionId
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              startDateTime
              endDateTime
              region {
                id
                name
                timezone
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: {
      regionId: loggedInUser?.defaultRegion?.id,
      timezone: loggedInUser?.defaultRegion?.formattedTimezone,
    },
    validateOnChange: false,
  })

  const queryVariables = () => {
    return {
      startDateGte: startDateFilter,
      endDateLte: endDateFilter,
      organizations: organizationId && [organizationId],
      subjectGroups: subjectGroupId && [subjectGroupId],
      regionId: formik.values.regionId,
    }
  }

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      query({
        variables: queryVariables(),
      })
    }
    setFetchedAllJobs(false)
    setChartSeries([])
    setJobs([])
    setChartCategories([])
  }, [startDateFilter, endDateFilter, formik.values.regionId])

  useEffect(() => {
    if (jobsData?.jobs) {
      setJobs((prevState) => [
        ...prevState,
        ...jobsData.jobs.edges.map((edge) => edge.node),
      ])
      if (jobsData.jobs.pageInfo.hasNextPage) {
        const variables = {
          ...queryVariables(),
          cursor: jobsData.jobs.pageInfo.endCursor,
        }
        query({ variables })
      } else {
        setFetchedAllJobs(true)
      }
    }
  }, [jobsData])

  useEffect(() => {
    if (fetchedAllJobs) {
      const dateRange = generateDateRange(startDateFilter, endDateFilter)
      const jobsCount = jobs.reduce((acc, session) => {
        const startDate = moment(session.startDateTime)
          .toISOString()
          .split('T')[0]
        acc[startDate] = (acc[startDate] || 0) + 1
        return acc
      }, {})
      setChartCategories(dateRange)
      let cumulativeTotal = 0
      const seriesData =
        viewType === 'Jobs Per Day'
          ? dateRange.map((date) => jobsCount[date] || 0)
          : dateRange.map((date) => {
              cumulativeTotal += jobsCount[date] || 0
              return cumulativeTotal
            })
      setChartSeries([{ name: viewType, data: seriesData }])
    }
  }, [fetchedAllJobs, startDateFilter, endDateFilter, viewType, jobs])

  useEffect(() => {
    if (!showModal) {
      setChartCategories([])
      setChartSeries([])
    }
  }, [showModal])

  return (
    <>
      <Row className="mb-4 mt-4">
        <Col md={'auto'}>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Jobs From"
            placeholderEnd={'From Until'}
            timezone={formik.values.timezone}
          />
        </Col>
        <Col md={2} className="mt-2">
          <Form.Control
            as="select"
            className="form-control-sm"
            value={viewType}
            onChange={(e) => setViewType(e.target.value)}
          >
            <option value="Jobs Per Day">Jobs Per Day</option>
            <option value="Total Jobs">Total Jobs</option>
          </Form.Control>
        </Col>
        {loggedInUser?.canManageRegions && settings?.tenantRegions && (
          <Col md={2} className="mt-2">
            <RegionSearchInput
              formik={formik}
              placeholder="Select Region"
              mustHaveValue
              dropdown
              setAdditionalFields={(node, _) => {
                if (node?.timezone) {
                  formik.setFieldValue(
                    'timezone',
                    formatTimezone(node.timezone)
                  )
                }
              }}
            />
          </Col>
        )}
      </Row>
      {startDateFilter && endDateFilter && !fetchedAllJobs && (
        <Row>
          <Col>
            <Loading message="Loading Jobs Chart..." />
          </Col>
        </Row>
      )}
      {fetchedAllJobs && (
        <>
          <div className="statistics-summary">
            <Row className="mb-4 mt-5">
              <Col md={4} className="statistic">
                <h5>Total Jobs</h5>
                <p>{jobs.length}</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="d-flex justify-content-center">
              <Report
                series={chartSeries}
                categories={chartCategories}
                yAxisFormatter={(value) => `${value}`}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default JobReport
