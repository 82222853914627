import React from 'react'
import { Modal } from 'react-bootstrap'
import ItemForm from './ItemForm'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import { Camera } from 'react-bootstrap-icons'

const ItemModal = (props) => {
  const {
    showModal,
    toggleModal,
    item,
    employeeId,
    employeeName,
    itemTypeId,
    itemTypeName,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  if (!showModal) return <></>
  const size = item ? 'xl' : 'lg'
  return (
    <>
      <div id="itemModal">
        <Modal size={size} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Camera className="mr-2" />
              {item ? (
                <>
                  {canMutate ? (
                    <>Edit {item.name} Item </>
                  ) : (
                    <>{item.name} Item</>
                  )}
                </>
              ) : (
                <>New Item</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ItemForm
              item={item}
              afterSubmit={toggleModal}
              employeeId={employeeId}
              employeeName={employeeName}
              itemTypeId={itemTypeId}
              itemTypeName={itemTypeName}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ItemModal
