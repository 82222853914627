import React, { useEffect, useState, useRef } from 'react'
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Badge,
  Table,
  Tab,
} from 'react-bootstrap'
import { useFormik } from 'formik'
import { useMutation, useLazyQuery, gql, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import RefundPaymentModal from '../payments/RefundPaymentModal'
import ChargePaymentModal from '../payments/ChargePaymentModal'
import { DateTime } from 'luxon'
import {
  ArrowCounterclockwise,
  Bell,
  Cash,
  Clipboard2Check,
  ClockHistory,
  FileEarmarkText,
  PlusCircle,
  ReceiptCutoff,
  Stickies,
} from 'react-bootstrap-icons'
import { useImagequixEnabled } from '../../libs/imagequix'
import SessionHistory from './SessionHistory'
import { useParams } from 'react-router-dom'
import CurrencyInput from 'react-currency-input-field'
import JobDetailModal from '../schedule/JobDetailModal'
import NotificationsTable from '../notifications/NotificationsTable'
import PaymentsTable from '../payments/PaymentsTable'
import InvoiceTable from '../payments/InvoiceTable'
import { useBoxEnabled } from '../../libs/box'
import Loading from '../common/Loading'
import BoxFolder from '../files/box/BoxFolder'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDownloadFile } from '../../libs/downloadFile'
import { debounce } from 'lodash'
import MapModal from '../location/MapModal'
import FilesTable from '../files/FilesTable'
import Tabs from '../common/Tabs'
import FormSubmissions from '../form/FormSubmissions'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { formatTimezone } from '../../libs/utils'

const AdminSessionDetailModal = (props) => {
  const { toTimezone } = useDateTimeConverter()
  const { sessionId, showModal, toggleModal, setRemoveCancelledSession } = props
  const { contact } = useParams()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()
  const canMutate = [
    'Scheduling Manager',
    'Administrator',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const { downloadAndDeleteFile } = useDownloadFile()
  const iqEnabled = useImagequixEnabled()
  const [sessionNotesSaved, setSessionNotesSaved] = useState(false)
  const [savingBilling, setSavingBilling] = useState(false)
  const [subjectNotesSaved, setSubjectNotesSaved] = useState(false)
  const [savingNoShow, setSavingNoShow] = useState(false)
  const [showJobDetailModal, setShowJobDetailModal] = useState(false)
  const [editSessionModal, setEditSessionModal] = useState(false)
  const [savingNotNoShow, setSavingNotNoShow] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [billing, setBilling] = useState()
  const [activeTab, setActiveTab] = useState('actions')
  const [savingPrice, setSavingPrice] = useState()
  const [sessionId_, setSessionId] = useState()
  const [previousSessionId, setPreviousSessionId] = useState()
  const [savingComplete, setSavingComplete] = useState(false)
  const [savingCancelled, setSavingCancelled] = useState(false)
  const [savingNotComplete, setSavingNotComplete] = useState(false)
  const [showSessionRefundModal, setShowSessionRefundModal] = useState()
  const [showChargeModal, setShowChargeModal] = useState()
  const boxEnabled = useBoxEnabled()
  const [downloadingFile, setDownloadingFile] = useState()
  const [sessionPackagePrice, setSessionPackagePrice] = useState()

  useEffect(() => {
    if (!showModal && activeTab !== 'actions') {
      setActiveTab('actions')
    }
  }, [showModal, activeTab])

  const debouncedUpdateSession = useRef(
    debounce((value, sessionId) => {
      setSavingPrice(true)
      updateSessionMutation({
        variables: {
          updateSessionInput: {
            sessionInput: {
              id: sessionId,
              sessionPackagePrice: value !== undefined ? value : 0,
            },
          },
        },
      }).finally(() => {
        setSavingPrice(false)
      })
    }, 1000)
  ).current

  useEffect(() => {
    if (!showModal) {
      setShowChargeModal(false)
      setShowSessionRefundModal(false)
    }
  }, [showModal])

  const { data: settingsData } = useQuery(
    gql`
      query StudioSettingsQuery {
        settings {
          edges {
            node {
              id
              chargeStateSalesTax
              sessionStages
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [sessionQuery, { data: sessionQueryData, error: sessionQueryError }] =
    useLazyQuery(
      gql`
        query SessionQuery($sessionId: ID!) {
          session(id: $sessionId) {
            id
            created
            recordId
            waiveNoShowFee
            endDateTime
            frameNumber
            updated
            mobile
            cancelled
            subjectDescription
            stage {
              id
              name
              color
            }
            employee {
              id
              gaiaUser {
                fullName
              }
            }
            coupon {
              id
            }
            sessionPackage {
              id
              title
              price
              durationLowMinutes
              durationHighMinutes
              customPriceAndDuration
              description
            }
            resitReason {
              reason
            }
            region {
              timezone
              name
            }
            rootBoxFolderId
            sharedBoxFolderId
            feeNoShow
            feeResit
            feeCancellation
            feeReschedule
            coupon {
              id
            }
            imagequixSubject {
              imagequixId
            }
            stripePaymentIntents(
              orderBy: "-created"
              description: "Session Package Fee"
            ) {
              edges {
                node {
                  id
                  stripeId
                  refunded
                  description
                  created
                  updated
                  stripeRefundIntents(orderBy: "-created") {
                    edges {
                      node {
                        refundedBySystem
                        amount
                        refundedBy {
                          fullName
                        }
                      }
                    }
                  }
                }
              }
            }
            stripeInvoiceItems(
              stripeInvoice_Paid: false
              stripeInvoice_Voided: false
              orderBy: "-created"
              first: 1
            ) {
              nodeCount
              edges {
                node {
                  stripePriceResource
                  stripeInvoice {
                    paid
                  }
                }
              }
            }
            organizationPackageBackground {
              backgroundColor
            }
            job {
              name
              id
              subjectNotes
              notes
              location {
                name
                id
                fullAddress
                latitude
                longitude
              }
            }
            refundAmount
            amountToBeRefund
            subject {
              notes
              id
              gaiaUser {
                id
                fullName
                stripeCustomer {
                  stripePaymentMethods(primary: true) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
            resitPreviousSession {
              id
              startDateTime
              resitReason {
                reason
              }
            }
            folder {
              id
            }
            sharedFolder {
              id
            }
            billSubject
            refunded
            subjectGroup {
              name
              endDateTime
              id
              notes
              organization {
                name
                id
              }
            }
            resitScheduled
            waiveRescheduleCancelFee
            waiveBookingFee
            resitReason {
              reason
            }
            organization {
              category
              name
              notes
              id
            }
            notes
            startDateTime
            endDateTime
            noShow
            paid
            price
            salesTax
            sessionPackagePrice
            fullSessionPackagePrice
            couponSavings
            cancelled
            completed
            upsell
            resitScheduled
            sessionPackageChanged
            resitSession {
              id
              startDateTime
            }
            noShowResitScheduled
            noShowRescheduleSession {
              startDateTime
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      }
    )

  const [updateSessionMutation, { loading: updateSessionLoading }] =
    useMutation(
      gql`
        mutation UpdateSession($updateSessionInput: UpdateSessionInput!) {
          updateSession(input: $updateSessionInput) {
            session {
              id
            }
          }
        }
      `,
      {
        onCompleted: () => {
          if (!savingPrice && setRemoveCancelledSession) {
            setRemoveCancelledSession(session.id)
          }
          setSavingPrice(false)
          setSessionNotesSaved(true)
          if (savingBilling) {
            setSavingBilling(false)
            toast.success('Billing Saved')
          }
          if (savingNoShow) {
            toast.success('Session No Show')
            setSavingNoShow(false)
          }
          if (savingCancelled) {
            toast.success('Session Cancelled')
            setSavingCancelled(false)
            toggleModal()
          }
          if (savingComplete) {
            toast.success('Session Complete')
            setSavingComplete(false)
          }
          if (savingNotComplete) {
            toast.success('Session Not Complete')
            setSavingNotComplete(false)
          }
          if (savingNotNoShow) {
            toast.success('Session Not No Show')
            setSavingNotNoShow(false)
          }
        },
        errorPolicy: 'all',
        refetchQueries: [
          'JobDetailSessionsQuery',
          'SessionQuery',
          'SessionsQuery',
          'SubjectSessionsQuery',
          'SubjectGroupsNotBookedQuery',
          'StripePaymentIntentsQuery',
        ],
      }
    )

  const [updateSubjectMutation] = useMutation(
    gql`
      mutation UpdateSubject($updateSubjectInput: UpdateSubjectInput!) {
        updateSubject(input: $updateSubjectInput) {
          subject {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubjectNotesSaved(true)
      },
      errorPolicy: 'all',
    }
  )

  const [downloadSessionsReport] = useMutation(
    gql`
      mutation DownloadSessions($input: DownloadSessionsInput!) {
        downloadSessions(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadSessions?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadSessions.file.fileName,
            data.downloadSessions.file.displayName,
            data.downloadSessions.file.id,
            () => {
              toast.success(`Session Ticket Downloaded`)
              setDownloadingFile()
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (subjectNotesSaved && sessionNotesSaved) {
      if (!canMutate) {
        toast.success('Session Notes Saved')
      } else {
        toast.success('Subject and Session Notes Saved')
      }
      setSessionNotesSaved(false)
      setSubjectNotesSaved(false)
    }
  }, [subjectNotesSaved, sessionNotesSaved])
  const EditSessionSchema = Yup.object().shape({
    sessionNotes: Yup.string().max(10000, 'Notes are too long'),
    subjectNotes: Yup.string().max(10000, 'Notes are too long'),
  })
  const formik = useFormik({
    initialValues: {
      sessionNotes: '',
      subjectNotes: '',
      jobNotes: '',
    },
    validationSchema: EditSessionSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      setSubjectNotesSaved(false)
      setSessionNotesSaved(false)
      updateSessionMutation({
        variables: {
          updateSessionInput: {
            sessionInput: {
              id: session.id,
              notes: values.sessionNotes,
              subjectDescription: values.subjectDescription,
            },
          },
        },
      })
      updateSubjectMutation({
        variables: {
          updateSubjectInput: {
            subjectInput: {
              id: session.subject.id,
              notes: values.subjectNotes,
            },
          },
        },
      })
    },
  })

  const formatSessionStartTime = (sessionStartTime, timezone) => {
    return toTimezone(sessionStartTime, {
      humanReadableSort: true,
      timezone: timezone,
    })
  }

  const constructSessionStartTimeLabel = (session) => {
    let message
    if (session.noShow) {
      message = 'Missed on'
    } else if (session.completed) {
      message = 'Complete on'
    } else {
      message = 'Upcoming on'
    }
    return message
  }

  useEffect(() => {
    if (sessionId_) {
      sessionQuery({
        variables: {
          sessionId: sessionId_,
        },
      })
    }
  }, [sessionId_])

  useEffect(() => {
    setSessionId(sessionId)
  }, [sessionId])

  useEffect(() => {
    if (sessionQueryData?.session) {
      setSessionPackagePrice(sessionQueryData?.session.sessionPackagePrice)
      let sessionNotes = ''
      if (sessionQueryData.session.notes) {
        sessionNotes = sessionQueryData.session.notes
      }
      let subjectNotes = ''
      if (sessionQueryData.session.subject?.notes) {
        subjectNotes = sessionQueryData.session.subject?.notes
      }
      let jobNotes = ''
      if (sessionQueryData.session?.job?.subjectNotes) {
        jobNotes = sessionQueryData.session.job?.subjectNotes
      }
      if (sessionQueryData.session.billSubject) {
        setBilling('subject')
      } else {
        setBilling('organization')
      }
      formik.setValues({
        sessionNotes,
        subjectNotes,
        jobNotes,
      })
    }
  }, [sessionQueryData])

  if (sessionQueryError) return <>Error loading session</>
  if (!showModal || !sessionQueryData || !sessionId_) return <></>
  const session = sessionQueryData.session
  if (!session) return <></>
  let subjectComponent
  if (session.subject) {
    subjectComponent = (
      <Row>
        <Col>
          <h5>Subject</h5>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/subject/${session?.subject.id}`)}
            className="btn-link"
          >
            <p>{session?.subject?.gaiaUser.fullName}</p>
          </span>
        </Col>
      </Row>
    )
  }
  let orgComponent
  if (session?.organization && session?.organization?.category === 'School') {
    orgComponent = (
      <Row>
        <Col>
          <h5>School</h5>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(`/organization/${session?.organization.id}`)
            }
            className="btn-link"
          >
            <p>{session?.organization?.name}</p>
          </span>
        </Col>
      </Row>
    )
  } else if (
    session?.organization &&
    session?.organization?.category !== 'School'
  ) {
    orgComponent = (
      <Row>
        <Col>
          <h5>Organization</h5>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(`/organization/${session?.organization.id}`)
            }
            className="btn-link"
          >
            <p>{session?.organization?.name}</p>
          </span>
        </Col>
      </Row>
    )
  }
  let subjectGroupComponent
  if (session?.subjectGroup) {
    subjectGroupComponent = (
      <Row>
        <Col>
          <h5>Subject Group</h5>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(`/subject-group/${session?.subjectGroup.id}`)
            }
            className="btn-link"
          >
            <p>{session?.subjectGroup?.name}</p>
          </span>
        </Col>
      </Row>
    )
  }
  let adminActionsComponent
  if (
    session.waiveBookingFee ||
    session.waiveRescheduleCancelFee ||
    session.waiveNoShowFee
  ) {
    adminActionsComponent = (
      <Row>
        <Col>
          <h5>Waived Fees</h5>
          {session.waiveBookingFee &&
            session.waiveRescheduleCancelFee &&
            !session.waiveNoShowFee && (
              <p>Waived booking, reschedule and cancellation fees</p>
            )}
          {!session.waiveRescheduleCancelFee &&
            !session.waiveNoShowFee &&
            session.waiveBookingFee && <p>Waived booking fee</p>}
          {!session.waiveBookingFee && session.waiveRescheduleCancelFee && (
            <p>Waived reschedule and cancellation fees</p>
          )}
          {session.waiveBookingFee &&
            session.waiveRescheduleCancelFee &&
            session.waiveNoShowFee && (
              <p>Waived booking, reschedule, cancellation and no show fees</p>
            )}
        </Col>
      </Row>
    )
  }
  const handleNoShowClick = () => {
    setSavingNoShow(true)
    updateSessionMutation({
      variables: {
        updateSessionInput: {
          sessionInput: {
            id: session.id,
            noShow: true,
          },
        },
      },
    })
  }

  const toggleSessionRefundModal = () => {
    setShowSessionRefundModal(!showSessionRefundModal)
  }

  const toggleChargeModal = () => {
    setShowChargeModal(!showChargeModal)
  }

  const handleRefundClick = () => {
    toggleSessionRefundModal()
  }

  const handleNotNoShowClick = () => {
    setSavingNotNoShow(true)
    updateSessionMutation({
      variables: {
        updateSessionInput: {
          sessionInput: {
            id: session.id,
            noShow: false,
          },
        },
      },
    })
  }

  const handleCancelClick = () => {
    setSavingCancelled(true)
    updateSessionMutation({
      variables: {
        updateSessionInput: {
          sessionInput: {
            id: session.id,
            cancelled: true,
          },
        },
      },
    })
  }
  const handleCompleteClick = () => {
    setSavingComplete(true)
    updateSessionMutation({
      variables: {
        updateSessionInput: {
          sessionInput: {
            id: session.id,
            completed: true,
          },
        },
      },
    })
  }
  const handleNotCompleteClick = () => {
    setSavingNotComplete(true)
    updateSessionMutation({
      variables: {
        updateSessionInput: {
          sessionInput: {
            id: session.id,
            completed: false,
          },
        },
      },
    })
  }

  const toggleJobDetailModal = () => {
    setEditSessionModal(false)
    setShowJobDetailModal(false)
  }

  if (!settingsData) return <></>
  const settings = settingsData.settings.edges[0].node
  let paymentIntent
  let refund = null
  let lastPaymentRefund
  if (session?.stripePaymentIntents?.edges.length > 0) {
    session.stripePaymentIntents.edges.forEach((edge) => {
      if (!paymentIntent) {
        paymentIntent = edge.node
      }
      if (
        !lastPaymentRefund &
        (paymentIntent.stripeRefundIntents.edges.length > 0)
      ) {
        lastPaymentRefund = paymentIntent.stripeRefundIntents.edges[0].node
      }
      paymentIntent.stripeRefundIntents.edges.forEach((edge) => {
        const { node } = edge
        if (refund === null) {
          refund = 0
        }
        refund += node.amount / 100
      })
    })
  }
  if (lastPaymentRefund) {
    let refundType = refund == session.price ? 'refunded' : 'partially refunded'
    const refundAmountFormatted = refund.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    if (lastPaymentRefund.refundedBySystem) {
      refund = `${refundAmountFormatted} ${refundType} by Airstudio`
    } else if (lastPaymentRefund.refundedBy) {
      refund = `${refundAmountFormatted} ${refundType} by ${lastPaymentRefund.refundedBy?.fullName}`
    }
  }

  let feeReschedule = session.feeReschedule ? session.feeReschedule : 0
  let feeNoShow = session.feeNoShow && session.noShow ? session.feeNoShow : 0
  let feeResit = session.feeResit ? session.feeResit : 0
  let feeCancellation = session.feeCancellation ? session.feeCancellation : 0
  let sessionDuration
  if (session.sessionPackage.customPriceAndDuration) {
    const startDateTime = DateTime.fromISO(session.startDateTime)
    const endDateTime = DateTime.fromISO(session.endDateTime)
    sessionDuration = endDateTime
      .diff(startDateTime, 'minutes')
      .toObject().minutes
  }
  const sessionPackageCost = session.sessionPackage.customPriceAndDuration
    ? session.sessionPackagePrice
    : session.fullSessionPackagePrice

  const sessionTotal =
    Math.ceil(
      (sessionPackageCost -
        session.couponSavings +
        session.salesTax +
        feeResit +
        feeNoShow +
        feeCancellation +
        feeReschedule) *
        100
    ) / 100
  let stripePaymentMethod
  if (
    session.subject?.gaiaUser?.stripeCustomer?.stripePaymentMethods?.edges
      .length > 0
  ) {
    stripePaymentMethod =
      session.subject?.gaiaUser?.stripeCustomer?.stripePaymentMethods.edges[0]
        .node
  }
  let due
  if (sessionTotal > session.paid) {
    due = sessionTotal - session.paid
  }
  if (lastPaymentRefund) {
    due = sessionTotal
  }
  let activeInvoice = session.stripeInvoiceItems.nodeCount > 0 ? true : false
  const timezone = formatTimezone(session?.region?.timezone)
  return (
    <>
      <div className="subjectSessionModal">
        <Modal
          size={'xl'}
          show={showModal}
          onHide={() => {
            toggleModal()
            setSessionId()
            setPreviousSessionId()
          }}
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title className="d-flex align-items-center">
                <h3>
                  {session.job.name} Session {session.recordId}
                </h3>
                {(sessionQueryData?.session?.noShow ||
                  sessionQueryData?.session?.completed) && (
                  <h6 style={{ marginTop: '3px' }}>
                    <Badge
                      className="text-white ml-2"
                      style={{
                        backgroundColor: sessionQueryData.session.noShow
                          ? 'red'
                          : sessionQueryData.session.completed
                            ? 'green'
                            : '',
                      }}
                    >
                      {sessionQueryData.session.noShow
                        ? 'no show'
                        : sessionQueryData.session.completed
                          ? 'completed'
                          : ''}
                    </Badge>
                  </h6>
                )}
                {activeInvoice && (
                  <h6 style={{ marginTop: '3px' }}>
                    <Badge
                      className="text-white ml-2"
                      style={{
                        backgroundColor: 'green',
                      }}
                    >
                      invoiced
                    </Badge>
                  </h6>
                )}
                {session.resitScheduled && (
                  <h6 style={{ marginTop: '3px' }}>
                    <Badge
                      className="text-white ml-2"
                      style={{
                        backgroundColor: 'green',
                      }}
                    >
                      {' '}
                      resit scheduled
                    </Badge>
                  </h6>
                )}
                {session.mobile && (
                  <h6 style={{ marginTop: '3px' }}>
                    <Badge
                      className="text-white ml-2"
                      style={{
                        backgroundColor: 'green',
                      }}
                    >
                      {' '}
                      mobile
                    </Badge>
                  </h6>
                )}
                {session.resitPreviousSession && (
                  <h6 style={{ marginTop: '3px' }}>
                    <Badge
                      className="text-white ml-2"
                      style={{
                        backgroundColor: 'green',
                      }}
                    >
                      {' '}
                      resit
                    </Badge>
                  </h6>
                )}
                {session.cancelled && (
                  <h6 style={{ marginTop: '3px' }}>
                    <Badge
                      className="text-white ml-2"
                      style={{
                        backgroundColor: 'red',
                      }}
                    >
                      {' '}
                      cancelled
                    </Badge>
                  </h6>
                )}
                {sessionQueryData.session?.upsell && (
                  <h6>
                    <Badge
                      className="ml-2"
                      style={{ backgroundColor: 'green' }}
                    >
                      upsell
                    </Badge>
                  </h6>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {subjectComponent && subjectComponent}
              {subjectGroupComponent && subjectGroupComponent}
              {orgComponent && orgComponent}
              <Row>
                <Col>
                  <h5>Job</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowJobDetailModal(true)}
                    className="btn-link"
                  >
                    <p>{session?.job?.name}</p>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>Location</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowMap(true)}
                    className="btn-link"
                  >
                    <p>{session?.job?.location?.fullAddress}</p>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h5>Session Package</h5>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <p>
                    {session?.sessionPackage?.title},{' '}
                    {sessionDuration && <>{sessionDuration} minutes</>}
                    {!sessionDuration && (
                      <>
                        {session?.sessionPackage?.durationLowMinutes}-
                        {session?.sessionPackage?.durationHighMinutes} minutes
                        {session.organizationPackageBackground ? (
                          <>
                            ,{' '}
                            {
                              session.organizationPackageBackground
                                .backgroundColor
                            }
                          </>
                        ) : null}{' '}
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>{session?.sessionPackage.description}</p>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <h5>Booked On</h5>
                  <p>{formatSessionStartTime(session.created, timezone)}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h5>
                    Occur
                    {session.completed && !session.cancelled ? (
                      <>red</>
                    ) : (
                      <>s</>
                    )}{' '}
                    On
                  </h5>
                  <p>
                    {constructSessionStartTimeLabel(session)}{' '}
                    {formatSessionStartTime(session.startDateTime, timezone)}
                  </p>
                </Col>
              </Row>
              {session?.cancelled && (
                <Row className="mt-3">
                  <Col>
                    <h5>Cancelled On</h5>
                    <p>{formatSessionStartTime(session.updated, timezone)}</p>
                  </Col>
                </Row>
              )}
              {session.resitScheduled && session.resitSession && (
                <>
                  <Row>
                    <Col md={6}>
                      <h5>
                        {previousSessionId && (
                          <span
                            className="btn-link"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSessionId(previousSessionId)
                              setPreviousSessionId()
                            }}
                          >
                            <ArrowCounterclockwise className="mr-2" />
                          </span>
                        )}
                        Resit
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mt-1">
                      <p
                        className="btn-link"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPreviousSessionId(sessionId)
                          setSessionId(session.resitSession.id)
                        }}
                      >
                        {formatSessionStartTime(
                          session.resitSession?.startDateTime,
                          timezone
                        )}{' '}
                        because {session.resitReason?.reason}
                      </p>
                    </Col>
                  </Row>
                </>
              )}
              {session.resitPreviousSession && (
                <>
                  <Row>
                    <Col md={6}>
                      <h5>
                        {previousSessionId && (
                          <span
                            className="btn-link"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSessionId(previousSessionId)
                              setPreviousSessionId()
                            }}
                          >
                            <ArrowCounterclockwise className="mr-2" />
                          </span>
                        )}
                        Resit Of
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mt-1">
                      <p
                        className="btn-link"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPreviousSessionId(sessionId)
                          setSessionId(session.resitPreviousSession.id)
                        }}
                      >
                        {formatSessionStartTime(
                          session.resitPreviousSession?.startDateTime,
                          timezone
                        )}{' '}
                        because{' '}
                        {session.resitPreviousSession.resitReason?.reason}
                      </p>
                    </Col>
                  </Row>
                </>
              )}
              {session.noShowResitScheduled &&
                session.noShowRescheduleSession && (
                  <>
                    <Row>
                      <Col md={6}>
                        <h5>Rescheduled</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mt-1">
                        <p>
                          {formatSessionStartTime(
                            session.noShowRescheduleSession?.startDateTime,
                            timezone
                          )}
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
              {adminActionsComponent && adminActionsComponent}
              {settings.sessionStages && (
                <Row className="mb-2">
                  <Col>
                    <h5 className="mb-1">Stage</h5>
                    {session?.stage && (
                      <>
                        <span
                          style={{
                            backgroundColor: session.stage.color,
                          }}
                        >
                          {session.stage.name}
                        </span>
                      </>
                    )}
                    {!session.stage && <p>- - -</p>}
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <h5>Photographer</h5>
                  <p>
                    {session.employee && session.employee.gaiaUser
                      ? session.employee.gaiaUser.fullName
                      : '- - -'}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h5>Frame Number</h5>
                  <p>{session.frameNumber ? session.frameNumber : '- - -'}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h5 style={{ marginBottom: 0, marginRight: 10 }}>
                      Billing
                    </h5>
                    {session.subject && session.organization && canMutate && (
                      <select
                        className="form-control-sm form-select"
                        name="group"
                        value={billing}
                        onChange={(e) => {
                          setBilling(e.target.value)
                        }}
                        style={{ display: 'inline-block', width: '50%' }}
                      >
                        <option value="subject">Subject</option>
                        <option value="organization">Organization</option>
                      </select>
                    )}
                    {((billing === 'subject' && !session.billSubject) ||
                      (billing === 'organization' && session.billSubject)) && (
                      <small
                        className="btn-link ml-2"
                        onClick={() => {
                          setSavingBilling(true)
                          updateSessionMutation({
                            variables: {
                              updateSessionInput: {
                                sessionInput: {
                                  id: session.id,
                                  billSubject:
                                    billing === 'subject' ? true : false,
                                },
                              },
                            },
                          })
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        Save
                      </small>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table size="sm" style={{ width: '250px' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <small>Session Package</small>
                        </td>
                        <td className="text-end" style={{ width: '100px' }}>
                          {(!session.sessionPackage.customPriceAndDuration ||
                            activeInvoice ||
                            contact) && (
                            <small>
                              {session?.fullSessionPackagePrice.toLocaleString(
                                'en-US',
                                {
                                  style: 'currency',
                                  currency: 'USD',
                                }
                              )}
                            </small>
                          )}
                          {session.sessionPackage.customPriceAndDuration &&
                            !contact &&
                            !activeInvoice && (
                              <CurrencyInput
                                name={'session package price'}
                                placeholder="enter price"
                                value={sessionPackagePrice}
                                onValueChange={(value, name) => {
                                  setSessionPackagePrice(value)
                                  debouncedUpdateSession(value, session.id)
                                }}
                                className="form-control form-control-sm"
                                decimalsLimit={2}
                                prefix="$"
                              />
                            )}
                        </td>
                      </tr>
                      {session.couponSavings > 0 && (
                        <tr>
                          <td>
                            <small>Coupon</small>
                          </td>
                          <td className="text-end">
                            <small>
                              -{' '}
                              {session.couponSavings.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {feeReschedule > 0 && (
                        <tr>
                          <td>
                            <small>Reschedule Fee</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {feeReschedule.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {feeCancellation > 0 && (
                        <tr>
                          <td>
                            <small>Cancellation Fee</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {feeCancellation.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {feeNoShow > 0 && (
                        <tr>
                          <td>
                            <small>No Show Fee</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {feeNoShow.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {feeResit > 0 && (
                        <tr>
                          <td>
                            <small>Resit Fee</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {feeResit.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {settings.chargeStateSalesTax && (
                        <tr>
                          <td>
                            <small>Tax</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {session.salesTax.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="table-success">
                          <small>Total</small>
                        </td>
                        <td className="text-end">
                          <small>
                            {sessionTotal.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </small>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-success">
                          <small>
                            Paid{' '}
                            {session.billSubject ? (
                              <>by Subject</>
                            ) : (
                              <>by Organization</>
                            )}
                          </small>
                        </td>
                        <td className="text-end">
                          <small>
                            {session.paid.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </small>
                        </td>
                      </tr>
                      {refund && (
                        <tr>
                          <td className="table-success">
                            <small>Refunded</small>
                          </td>
                          <td className="text-end">
                            <small>{refund}</small>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Tabs
                className="mt-4"
                activeKey={activeTab}
                onSelect={(tab) => {
                  setActiveTab(tab)
                }}
              >
                <Tab
                  key="actions"
                  eventKey="actions"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <PlusCircle className="mr-2" />
                      Actions
                    </b>
                  }
                >
                  <>
                    <Row className="mt-2">
                      <Col md={{ span: 8, offset: 2 }}>
                        <Button
                          variant="outline-primary"
                          block
                          onClick={() => {
                            setEditSessionModal(true)
                            setShowJobDetailModal(true)
                          }}
                        >
                          Edit Session
                        </Button>
                      </Col>
                    </Row>
                    {iqEnabled && session.imagequixSubject?.imagequixId && (
                      <>
                        <Row className="mt-2">
                          <Col md={{ span: 8, offset: 2 }}>
                            <Button
                              variant="outline-primary"
                              block
                              disabled={downloadingFile || updateSessionLoading}
                              onClick={() => {
                                window.open(
                                  `https://vando.imagequix.com/s${session.imagequixSubject?.imagequixId}`,
                                  '_blank'
                                )
                              }}
                            >
                              Gallery
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                    {!session.completed && !session?.cancelled ? (
                      <Row className="mt-2">
                        <Col md={{ span: 8, offset: 2 }}>
                          <Button
                            block
                            variant="outline-primary"
                            disabled={downloadingFile || updateSessionLoading}
                            onClick={handleCompleteClick}
                          >
                            Session Complete
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                    {session.completed && !session?.cancelled ? (
                      <Row className="mt-2">
                        <Col md={{ span: 8, offset: 2 }}>
                          <Button
                            block
                            variant="outline-primary"
                            disabled={downloadingFile || updateSessionLoading}
                            onClick={handleNotCompleteClick}
                          >
                            Session Not Complete
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                    {!session.noShow && !session?.cancelled ? (
                      <Row className="mt-2">
                        <Col md={{ span: 8, offset: 2 }}>
                          <Button
                            block
                            variant="outline-primary"
                            disabled={downloadingFile || updateSessionLoading}
                            onClick={handleNoShowClick}
                          >
                            Session No Show
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                    {session.noShow && !session?.cancelled ? (
                      <Row className="mt-2">
                        <Col md={{ span: 8, offset: 2 }}>
                          <Button
                            block
                            variant="outline-primary"
                            disabled={downloadingFile || updateSessionLoading}
                            onClick={handleNotNoShowClick}
                          >
                            Session Not No Show
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                    {canMutate && paymentIntent && !paymentIntent.refunded ? (
                      <Row className="mt-2">
                        <Col md={{ span: 8, offset: 2 }}>
                          <Button
                            block
                            disabled={downloadingFile || updateSessionLoading}
                            variant="outline-primary"
                            onClick={handleRefundClick}
                          >
                            Refund Session Payment
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                    {(lastPaymentRefund || due) &&
                      canMutate &&
                      session.billSubject &&
                      stripePaymentMethod && (
                        <Row className="mt-2">
                          <Col md={{ span: 8, offset: 2 }}>
                            <Button
                              block
                              variant="outline-primary"
                              disabled={downloadingFile || updateSessionLoading}
                              onClick={toggleChargeModal}
                            >
                              Charge{' '}
                              {due.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}{' '}
                              Session Payment
                            </Button>
                          </Col>
                        </Row>
                      )}
                    {!contact && (
                      <>
                        <Row className="mt-2">
                          <Col md={{ span: 8, offset: 2 }}>
                            <Button
                              variant="outline-primary"
                              block
                              disabled={downloadingFile || updateSessionLoading}
                              onClick={() => {
                                setDownloadingFile(true)
                                downloadSessionsReport({
                                  variables: {
                                    input: {
                                      sessionIds: [session.id],
                                      jobId: null,
                                      fileType: 'pdf',
                                      file: 'sessions_sitting',
                                    },
                                  },
                                })
                              }}
                            >
                              Download Ticket
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}

                    {!session.completed &&
                    !session.noShow &&
                    canMutate &&
                    !session?.cancelled ? (
                      <Row className="mt-2">
                        <Col md={{ span: 8, offset: 2 }}>
                          <Button
                            block
                            disabled={downloadingFile || updateSessionLoading}
                            variant="outline-danger"
                            onClick={handleCancelClick}
                          >
                            Cancel Session
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                  </>
                </Tab>
                <Tab
                  key="notes"
                  eventKey="notes"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Stickies className="mr-2" />
                      Notes
                    </b>
                  }
                >
                  <>
                    <Row>
                      <Form.Group as={Col} xs={12} md={{ span: 10, offset: 1 }}>
                        <Form.Label>
                          <h5>Session Notes</h5>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="sessionNotes"
                          value={formik.values.sessionNotes}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.sessionNotes}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.sessionNotes}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} xs={12} md={{ span: 10, offset: 1 }}>
                        <Form.Label>
                          <h5>Subject Description</h5>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="subjectDescription"
                          value={formik.values.subjectDescription}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.subjectDescription}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.subjectDescription}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    {session.subject && !contact && (
                      <>
                        <Row>
                          <Form.Group
                            as={Col}
                            xs={12}
                            md={{ span: 10, offset: 1 }}
                          >
                            <Form.Label>
                              <h5>Subject Notes</h5>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="subjectNotes"
                              disabled={!canMutate}
                              value={formik.values.subjectNotes}
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.subjectNotes}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.subjectNotes}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </>
                    )}
                    {!contact && (
                      <>
                        <Row>
                          <Form.Group
                            as={Col}
                            xs={12}
                            md={{ span: 10, offset: 1 }}
                          >
                            <Form.Label>
                              <h5>Job Notes</h5>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              disabled={true}
                              value={session.job.notes}
                            />
                          </Form.Group>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Form.Group as={Col} xs={12} md={{ span: 10, offset: 1 }}>
                        <Form.Label>
                          <h5>
                            {contact ? <>Job Notes</> : <>Job Subject Notes</>}
                          </h5>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          disabled={true}
                          value={session.job.subjectNotes}
                        />
                      </Form.Group>
                    </Row>
                    {session.subjectGroup && (
                      <>
                        <Row>
                          <Form.Group
                            as={Col}
                            xs={12}
                            md={{ span: 10, offset: 1 }}
                          >
                            <Form.Label>
                              <h5>Subject Group Notes</h5>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              disabled={true}
                              value={session.subjectGroup.notes}
                            />
                          </Form.Group>
                        </Row>
                      </>
                    )}
                    {session.organization && (
                      <>
                        <Row>
                          <Form.Group
                            as={Col}
                            xs={12}
                            md={{ span: 10, offset: 1 }}
                          >
                            <Form.Label>
                              <h5>Organization Notes</h5>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              disabled={true}
                              value={session.organization.notes}
                            />
                          </Form.Group>
                        </Row>
                      </>
                    )}
                    <Row className="mt-2">
                      <Col md={{ span: 10, offset: 1 }}>
                        <Button
                          type="submit"
                          variant="outline-primary"
                          block
                          disabled={updateSessionLoading}
                        >
                          <PlusCircle className="mr-2" />
                          Save Notes
                        </Button>
                      </Col>
                    </Row>
                  </>
                </Tab>
                <Tab
                  key="notifications"
                  eventKey="notifications"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Bell className="mr-2" />
                      Notifications
                    </b>
                  }
                >
                  {activeTab === 'notifications' && (
                    <NotificationsTable session={session} />
                  )}
                </Tab>
                <Tab
                  key="payments"
                  eventKey="payments"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Cash className="mr-2" />
                      Payments
                    </b>
                  }
                >
                  <PaymentsTable
                    sessionId={session.id}
                    fetchPolicy="no-cache"
                    tableHeight={200}
                  />
                </Tab>
                <Tab
                  key="invoices"
                  eventKey="invoices"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <ReceiptCutoff className="mr-2" />
                      Invoices
                    </b>
                  }
                >
                  <InvoiceTable
                    sessionId={session.id}
                    tableHeight={200}
                    fetchPolicy="no-cache"
                  />
                </Tab>
                <Tab
                  key="files"
                  eventKey="files"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <FileEarmarkText className="mr-2" />
                      Files
                    </b>
                  }
                >
                  {boxEnabled && session?.rootBoxFolderId && (
                    <BoxFolder
                      rootBoxFolderId={
                        loggedInUser.permissions.isEmployee
                          ? session.rootBoxFolderId
                          : session.sharedBoxFolderId
                      }
                    />
                  )}
                  {!boxEnabled && session.folder?.id && (
                    <div className="mt-3">
                      <FilesTable
                        sessionId={session.id}
                        sessionDescription={session.id}
                        tableHeight={300}
                        folderId={
                          contact ? session.sharedFolder.id : session.folder.id
                        }
                      />
                    </div>
                  )}
                </Tab>
                {!contact && (
                  <Tab
                    key="Forms Submissions"
                    eventKey="Forms Submissions"
                    style={{ marginTop: '10px' }}
                    title={
                      <b>
                        <Clipboard2Check className="mr-2" />
                        Form Submissions
                      </b>
                    }
                  >
                    <div className="mt-3">
                      <FormSubmissions sessionId={session.id} />
                    </div>
                  </Tab>
                )}
                {!contact &&
                  loggedInUser?.permissions?.group !== 'General Staff' && (
                    <Tab
                      key="history"
                      eventKey="history"
                      style={{ marginTop: '10px' }}
                      title={
                        <b>
                          <ClockHistory className="mr-2" />
                          History
                        </b>
                      }
                    >
                      <SessionHistory
                        onlyTable
                        sessionId={sessionQueryData.session.id}
                      />
                    </Tab>
                  )}
              </Tabs>
              {updateSessionLoading && !savingPrice && (
                <Row className="mt-3">
                  <Col>
                    <Loading />
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
      {showSessionRefundModal && (
        <RefundPaymentModal
          smallModel
          description="Session Package Fee"
          sessionId={session.id}
          maxAmountCanRefund={session.amountToBeRefund - session.refundAmount}
          showModal={showSessionRefundModal}
          toggleModal={toggleSessionRefundModal}
        />
      )}

      {showChargeModal && (
        <ChargePaymentModal
          smallModel
          sessionId={session.id}
          amount={due}
          description="Session Package Fee"
          stripePaymentMethodId={stripePaymentMethod?.id}
          showModal={showChargeModal}
          toggleModal={toggleChargeModal}
          gaiaUserId={session?.subject?.gaiaUser?.id}
        />
      )}

      {showJobDetailModal && (
        <JobDetailModal
          singleSessionsOnly={editSessionModal}
          sessionId={editSessionModal ? sessionId : null}
          jobId={session?.job?.id}
          showModal={showJobDetailModal}
          toggleModal={toggleJobDetailModal}
        />
      )}

      {showMap && (
        <MapModal
          location={session?.job?.location}
          showModal={showMap}
          toggleModal={setShowMap}
        />
      )}
    </>
  )
}

export default AdminSessionDetailModal
