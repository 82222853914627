import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import SessionType from './SessionType'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'

export default function BookSessionHomepage() {
  const history = useHistory()
  let settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  useEffect(() => {
    if (loggedInUser && !loggedInUser.autoLogOut) {
      if (loggedInUser.permissions.isEmployee) {
        history.push('/schedule')
      } else if (loggedInUser.permissions.isSubject) {
        history.push(`/subject/sessions`)
      } else if (loggedInUser.permissions.isOrgContact) {
        if (loggedInUser.organizationContacts.edges.length === 1) {
          const orgId = loggedInUser.organizationContacts.edges[0].node.id
          history.push(`/organization/${orgId}/contact`)
        } else {
          history.push(`/organizations/contact`)
        }
      }
    }
  }, [loggedInUser])

  if (!settings) return <></>
  return (
    <div className="homepage-container mt-4">
      <Row className="homepage-row">
        <Col md={{ span: 6, offset: 3 }}>
          <h3 className="homepage-header">
            Book a Session with {settings.name}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <SessionType />
        </Col>
      </Row>
    </div>
  )
}
