import React, { useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { PlusCircle } from 'react-bootstrap-icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import MetaDataTable from '../../common/MetaData'
import Loading from '../../common/Loading'

export const MODEL_TYPES = [
  { value: 'job', label: 'Job' },
  { value: 'session', label: 'Session' },
  { value: 'organization', label: 'Organization' },
  { value: 'subject_group', label: 'Subject Group' },
  { value: 'subject', label: 'Subject' },
  { value: 'task', label: 'Task' },
  { value: 'employee', label: 'Employee' },
  { value: 'equipment_bag', label: 'Equipment Bag' },
  { value: 'equipment_item', label: 'Equipment Item' },
  { value: 'location', label: 'Location' },
  { value: 'payment', label: 'Payment' },
  { value: 'invoice', label: 'Invoice' },
  { value: 'file', label: 'File' },
  { value: 'folder', label: 'Folder' },
  { value: 'form', label: 'Form' },
]

const CustomFieldSetForm = ({ onSubmit, fieldSet, afterSubmit }) => {
  fieldSet = fieldSet || {}
  const [submitting, setSubmitting] = useState(false)
  const [metaData, setMetaData] = useState(
    fieldSet.customFields ? JSON.parse(fieldSet?.customFields || {}) : {}
  )

  const formik = useFormik({
    initialValues: {
      name: fieldSet.name || '',
      model: fieldSet?.model?.toLowerCase() || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      model: Yup.string().required('Record Type is required'),
    }),
    onSubmit: (values) => {
      setSubmitting(true)

      onSubmit(
        fieldSet.id
          ? {
              id: fieldSet.id,
              name: values.name,
              model: values.model,
              customFields: metaData,
            }
          : {
              name: values.name,
              model: values.model,
              customFields: metaData,
            },
        () => {
          setSubmitting(false)
          if (afterSubmit) {
            afterSubmit()
          }
        }
      )
    },
  })

  return (
    <div id="customFieldSetForm">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3} className="text-sm-right">
            Name
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3} className="text-sm-right">
            Record Type
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              as="select"
              name="model"
              value={formik.values.model}
              onChange={formik.handleChange}
              isInvalid={formik.touched.model && formik.errors.model}
            >
              <option value="">Select Record Type</option>
              {MODEL_TYPES.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.model}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3} className="text-sm-right">
            Custom Fields
          </Form.Label>
          <Col sm={9}>
            <MetaDataTable
              metaData={metaData}
              setMetaData={setMetaData}
              edit={true}
              noMessage={true}
              header={true}
              btnStyle={{ fontSize: '14px' }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mt-4">
          <Col sm={{ span: 3 }}>
            <Button
              type="submit"
              block
              variant="outline-primary"
              disabled={submitting}
            >
              <PlusCircle className="mr-2" />
              Save
            </Button>
          </Col>

          {submitting && <Loading />}
        </Form.Group>
      </Form>
    </div>
  )
}

export default CustomFieldSetForm
