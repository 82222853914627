import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { settingsVar } from '../../../libs/apollo'
import { Cash, CloudArrowDown, Funnel, PlusCircle } from 'react-bootstrap-icons'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import Loading from '../../common/Loading'
import DateFilter from '../../common/DateFilter'
import { useAWSS3 } from '../../../libs/aws'
import validator from 'validator'
import toast from 'react-hot-toast'
import { useDateTimeConverter } from '../../../libs/useDateTime'

const TenantInvoiceTable = (props) => {
  const awsS3 = useAWSS3()
  const { toTimezone } = useDateTimeConverter()
  const settings = useReactiveVar(settingsVar)
  const [invoiceEmail, setInvoiceEmail] = useState()
  const [searchText, setSearchText] = useState()
  const [loading, setLoading] = useState(false)
  const [stateChanged, setStateChange] = useState(false)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [invoices, setInvoices] = useState([])
  const [filterPaid, setFilterPaid] = useState(false)
  const [filterPaymentFailed, setFilterPaymentFailed] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)

  useEffect(() => {
    if (settings) {
      setInvoiceEmail(settings.airstudioInvoiceRecipient)
    }
  }, [settings])

  const [updateSettings] = useMutation(
    gql`
      mutation UpdateSettings($updateSettingsInput: UpdateSettingsInput!) {
        updateSettings(input: $updateSettingsInput) {
          settings {
            id
            name
            tenantId
            airstudioInvoiceRecipient
            chargeProductStateSalesTax
            productTaxRate
            displayLogoInNav
            displayNameInNav
            websiteUrl
            phoneNumber
            openAiEnabled
            thumbnailImageName
            timezone
            intercomAppId
            showLastNameIdLogin
            tenantActive
            chargeStateSalesTax
            email
            openAiEnabled
            colorScheme
            facebookUrl
            equipmentBarcodes
            equipmentQrCodes
            twitterUrl
            linkedinUrl
            instagramUrl
            instagramInFooter
            facebookInFooter
            twitterInFooter
            linkedinInFooter
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        settingsVar(data.settings)
        setLoading(false)
        toast.success('Invoice Recipient Saved')
      },
      errorPolicy: 'all',
      refetchQueries: ['PublicSettingsQuery', 'SettingsQuery'],
    }
  )

  const [query, { error, data, fetchMore: queryFetchMore }] = useLazyQuery(
    gql`
      query TenantStripeInvoices(
        $tenant: ID!
        $cursor: String
        $status: String
        $paid: Boolean
        $paymentFailed: Boolean
        $searchTerm: String
        $startDateGte: DateTime
        $startDateLte: DateTime
      ) {
        tenantStripeInvoices(
          first: 20
          after: $cursor
          paid: $paid
          status: $status
          paymentFailed: $paymentFailed
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          search_Icontains: $searchTerm
          stripeCustomer_Tenant: $tenant
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              recordId
              created
              paymentFailed
              paidOn
              price
              status
              recipientEmail
              amountRemaining
              amountPaid
              amountDue
              effectiveAt
              dueDate
              stripeInvoiceId
              invoicePdfUrl
              invoicePaymentUrl
              stripePaymentIntents {
                edges {
                  node {
                    processing
                  }
                }
              }
              stripeCustomer {
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: data?.stripeInvoices?.pageInfo?.endCursor,
      tenant: settings.tenantId,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (filterOpen) {
      variables.status = 'Open'
    }
    if (filterPaid) {
      variables.paid = true
    }
    if (filterPaymentFailed) {
      variables.paymentFailed = true
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun && settings) {
      const variables = queryVariables()
      variables.cursor = null
      setInitialQueryRun(true)
      query({ variables })
    }
  }, [initialQueryRun, settings, setInitialQueryRun])

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterOpen, filterPaid, filterPaymentFailed])

  useEffect(() => {
    if (data?.tenantStripeInvoices?.edges) {
      let text = 'Search 0 Invoices'
      const current = data.tenantStripeInvoices.edges.map((stripeInvoice) => ({
        node: stripeInvoice.node,
      }))
      setInvoices(current)
      if (data.tenantStripeInvoices.nodeCount > 0) {
        text = `Search ${data.tenantStripeInvoices.nodeCount} Invoices`
      }
      setSearchText(text)
    }
  }, [data])

  const fetchMore = () => {
    const variables = queryVariables()
    queryFetchMore({
      variables,
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      variables.startDateGte = startDateFilter
      variables.startDateLte = endDateFilter
      query({ variables })
    }
  }, [startDateFilter, endDateFilter])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    variables.searchTerm = currentSearchTerm
    query({ variables })
  }

  let tableColumns = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => {
        return row.node.recordId
      },
    },
    {
      Header: 'Invoice #',
      id: 'number',
      accessor: (row) => {
        return row.node.stripeInvoiceId
      },
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => {
        if (row.node.paymentFailed) {
          return <span style={{ color: 'red' }}>Payment Failed</span>
        } else if (
          row.node.stripePaymentIntents.edges.length > 0 &&
          row.node.stripePaymentIntents.edges[0].node.processing
        ) {
          return <span style={{ color: 'red' }}>Processing Payment</span>
        }
        const status = row.node.status
        let color
        if (['Open', 'Uncollectible'].includes(status)) {
          color = 'red'
        } else if (status === 'Void') {
          color = 'orange'
        } else if (status === 'Paid') {
          color = 'green'
        }
        return <span style={{ color }}>{status}</span>
      },
    },
    {
      Header: 'Emailed To',
      id: 'emailedTo',
      accessor: (row) => {
        return row.node.recipientEmail
      },
    },
    {
      Header: 'Amount Due',
      id: 'due',
      accessor: (row) => {
        return `$${(row.node.amountDue / 100).toFixed(2)}`
      },
    },
    {
      Header: 'Amount Paid',
      id: 'paid',
      accessor: (row) => {
        if (row.node.paidManually) {
          return `$${(row.node.amountDue / 100).toFixed(2)}`
        } else {
          return `$${(row.node.amountPaid / 100).toFixed(2)}`
        }
      },
    },
    {
      Header: 'Sent On',
      id: 'sentOn',
      accessor: (row) => {
        return toTimezone(row.node.effectiveAt, {
          onlyDate: true,
          settingTimezone: true,
        })
      },
    },
    {
      Header: 'Paid On',
      id: 'paidOn',
      accessor: (row) => {
        if (row.node.paidOn) {
          return toTimezone(row.node.paidOn, {
            onlyDate: true,
            settingTimezone: true,
          })
        }
      },
    },
    {
      Header: 'Download',
      id: 'download',
      accessor: (row) => {
        return (
          <Button variant="link">
            <a href={row.node.invoicePdfUrl} download>
              <CloudArrowDown />
            </a>
          </Button>
        )
      },
    },
    {
      Header: 'Open Stripe',
      id: 'openStripe',
      accessor: (row) => {
        return (
          <Button
            variant="link"
            onClick={() => {
              window.open(row.node.invoicePaymentUrl, '_blank')
            }}
          >
            <Cash />
          </Button>
        )
      },
    },
  ]

  if (!initialQueryRun || !awsS3?.client) return <></>
  if (error) return <>Error loading</>
  return (
    <>
      <div>
        <Form>
          <Form.Row className="mb-2 mt-3">
            <Col md={3}>
              <Form.Label>Invoice Recipient</Form.Label>
              <Form.Control
                type="text"
                name="invoiceEmail"
                className="form-control-sm"
                value={invoiceEmail}
                onChange={(e) => {
                  setInvoiceEmail(e.target.value)
                  if (!stateChanged) {
                    setStateChange(true)
                  }
                }}
              />
            </Col>
            {stateChanged && (
              <Col className="mt-4">
                <Button
                  disabled={loading}
                  variant="link"
                  onClick={() => {
                    if (!validator.isEmail(invoiceEmail)) {
                      toast.error('Invalid Email')
                    } else {
                      setLoading(true)
                      updateSettings({
                        variables: {
                          updateSettingsInput: {
                            settingsInput: {
                              id: settings.id,
                              airstudioInvoiceRecipient: invoiceEmail,
                            },
                          },
                        },
                      })
                    }
                  }}
                >
                  <PlusCircle className="mr-2" /> Save
                </Button>
              </Col>
            )}
          </Form.Row>
        </Form>
        <Row>
          <Col>
            <Button
              variant="link"
              onClick={() => {
                setFilterOpen(!filterOpen)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterOpen ? <>Open</> : <>All</>}
              </span>
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setFilterPaid(!filterPaid)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterPaid ? <>Paid</> : <>All</>}
              </span>
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setFilterPaymentFailed(!filterPaymentFailed)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterPaymentFailed ? <>Payment Failed</> : <>All</>}
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 d-flex">
          <Col md={startDateFilter && endDateFilter ? 3 : 4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col md={'auto'} style={{ marginTop: '-8px' }}>
            <DateFilter
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              placeholderStart="Invoices From"
              placeholderEnd={'Invoices Until'}
            />
          </Col>
        </Row>

        {!data && <Loading message="Loading Invoices..." />}
        {data && (
          <>
            <Row className="mt-3 mb-3">
              <Col md={12}>
                <SortableInfiniteTable
                  tableData={invoices}
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMore}
                  loadingMessage="Loading Invoices..."
                  hasMoreTableData={
                    data?.tenantStripeInvoices?.pageInfo?.hasNextPage
                  }
                  tableHeight={700}
                  hideGlobalFilter
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  )
}
export default TenantInvoiceTable
