import React, { useEffect } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { Camera } from 'react-bootstrap-icons'
import { gql, useLazyQuery } from '@apollo/client'
import AnonymousBookingOrganizationSearchInput from '../common/node_search_input/AnonymousBookingOrganizationSearchInput'
import AnonymousBookingSubjectGroupSearchInput from '../common/node_search_input/AnonymousBookingSubjectGroupSearchInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

const GET_ANONYMOUS_BOOKING_SUBJECT = gql`
  query AnonymousBookingSubject(
    $subjectGroupId: ID!
    $firstName: String!
    $lastName: String!
  ) {
    anonymousBookingSubject(
      subjectGroupId: $subjectGroupId
      firstName: $firstName
      lastName: $lastName
    ) {
      id
    }
  }
`

const SchoolSelectionForm = () => {
  const history = useHistory()

  const [getAnonymousBookingSubject] = useLazyQuery(
    GET_ANONYMOUS_BOOKING_SUBJECT,
    {
      onCompleted: (data) => {
        if (data?.anonymousBookingSubject?.id) {
          toast.error(
            'Account Already Exists. Please Enter Your Student ID and Last Name Above To Book.'
          )
        } else {
          redirectToBooking()
        }
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      anonymousBookingOrganizationId: null,
      anonymousBookingSubjectGroupId: null,
      firstName: '',
      lastName: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('required'),
      lastName: Yup.string().required('required'),
    }),
    onSubmit: (values) => {
      getAnonymousBookingSubject({
        variables: {
          subjectGroupId: values.anonymousBookingSubjectGroupId,
          firstName: values.firstName,
          lastName: values.lastName,
        },
      })
    },
  })

  const redirectToBooking = () => {
    history.push({
      pathname: '/book',
      state: {
        initialStage: 'createSubject',
        bookingType: 'anonymousFlow',
        incommingOrganization: {
          id: formik.values.anonymousBookingOrganizationId,
        },
        incommingGroup: {
          id: formik.values.anonymousBookingSubjectGroupId,
          name: formik.values.anonymousBookingSubjectGroupName,
          regionId: formik.values.anonymousBookingSubjectGroupRegionId,
        },
        incommingSubject: {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
        },
      },
    })
  }

  const setAdditionalSubjectGroupFields = (selectedNode, fieldName) => {
    if (selectedNode) {
      formik.setFieldValue(
        'anonymousBookingSubjectGroupName',
        selectedNode.name
      )
      formik.setFieldValue(
        'anonymousBookingSubjectGroupRegionId',
        selectedNode.regionId
      )
    }
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <hr />
          <p style={{ fontSize: '18px' }}>Select School</p>
          <AnonymousBookingOrganizationSearchInput
            dropdown
            mustHaveValue
            formik={formik}
            variables={{
              category: 'school',
            }}
          />
        </Col>
      </Row>
      {formik.values.anonymousBookingOrganizationId && (
        <Row className="mt-4">
          <Col md={4}>
            <p style={{ fontSize: '18px' }}>Select Session</p>
            <AnonymousBookingSubjectGroupSearchInput
              dropdown
              mustHaveValue
              formik={formik}
              setAdditionalFields={setAdditionalSubjectGroupFields}
              variables={{
                organizationId: formik.values.anonymousBookingOrganizationId,
              }}
            />
          </Col>
        </Row>
      )}
      {formik.values.anonymousBookingSubjectGroupId && (
        <>
          <Row className="mt-4">
            <Col md={2}>
              <p style={{ fontSize: '18px' }}>First Name</p>
            </Col>
            <Col md={2}>
              <p style={{ fontSize: '18px' }}>Last Name</p>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Form.Control
                type="text"
                name="firstName"
                className="form-control-sm"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </Col>
            <Col md={2}>
              <Form.Control
                type="text"
                name="lastName"
                className="form-control-sm"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        </>
      )}
      {formik.values.anonymousBookingSubjectGroupId && (
        <Row className="mt-3">
          <Col>
            <Button
              variant="link"
              onClick={() => {
                if (!formik.values.firstName || !formik.values.lastName) {
                  toast.error('First Name And Last Name Required')
                } else {
                  formik.handleSubmit()
                }
              }}
            >
              <Camera className="mr-2" /> Continue
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default SchoolSelectionForm
