import React, { useEffect, useState } from 'react'
import { Col, Form, Table, Row } from 'react-bootstrap'
import { useLazyQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { XCircle } from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import SortableTable from '../../common/SortableTable'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Loading from '../../common/Loading'
import { useDateTimeConverter } from '../../../libs/useDateTime'
import { formatTimezone } from '../../../libs/utils'

const AvailableSessions = (props) => {
  const { showModal } = props
  const { toTimezone, toUTC } = useDateTimeConverter()
  const [displayLocationResults, setDisplayLocationResults] = useState(true)
  const [displaySessionPackageResults, setDisplaySessinoPackageResults] =
    useState(true)

  const [availableSessionsTransformed, setAvailableSessionsTransformed] =
    useState({})

  useEffect(() => {
    if (!showModal) {
      formik.resetForm()
    }
  }, [showModal])
  const [
    searchSessionPackages,
    { data: sessionPackageData, fetchMore: fetchMoreSessionPackages },
  ] = useLazyQuery(
    gql`
      query SessionPackages(
        $nameIcontains: String
        $first: Int
        $after: String
      ) {
        sessionPackages(
          title_Icontains: $nameIcontains
          first: $first
          customPriceAndDuration: false
          after: $after
        ) {
          edges {
            cursor
            node {
              id
              durationHighMinutes
              durationLowMinutes
              price
              title
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [
    searchLocations,
    { data: locationData, fetchMore: fetchMoreLocations },
  ] = useLazyQuery(
    gql`
      query Locations($nameIcontains: String, $first: Int, $after: String) {
        locations(
          archived: false
          fullAddress_Icontains: $nameIcontains
          first: $first
          after: $after
        ) {
          edges {
            cursor
            node {
              id
              studio
              archived
              addressLineOne
              addressLineTwo
              city
              state
              name
              zipCode
              organization {
                id
                name
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [availableSessions, { data: availableSessionsQueryData }] =
    useLazyQuery(
      gql`
        query ScheduleSessionAvailableSessionsQuery(
          $sessionPackageId: ID!
          $locationId: ID
          $startDateTime: DateTime
          $endDateTime: DateTime
        ) {
          availableSessions(
            sessionPackageId: $sessionPackageId
            locationId: $locationId
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            includePast: true
          ) {
            startDateTime
            scheduledSessions
            openSessions
            job {
              id
              region {
                timezone
              }
              location {
                id
                name
                latitude
                longitude
                addressLineOne
                addressLineTwo
                city
                state
                zipCode
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  useEffect(() => {
    if (availableSessionsQueryData?.availableSessions) {
      let currentLocationsSessions = {}
      availableSessionsQueryData.availableSessions.forEach((session) => {
        const timezone = formatTimezone(session.job.region.timezone)
        const startDate = toTimezone(session.startDateTime, {
          longCalendarDate: true,
          timezone: timezone,
        })
        const currentSession = {
          jobNode: session.job,
          openSessions: session.openSessions,
          startDateTime: session.startDateTime,
          formattedStartTime: toTimezone(session.startDateTime, {
            onlyTime: true,
            timezone: timezone,
          }),
        }
        const locationId = session.job.location.id
        if (currentLocationsSessions[locationId]) {
          if (!currentLocationsSessions[locationId].sessions[startDate]) {
            currentLocationsSessions[locationId].sessions[startDate] = []
          }
          currentLocationsSessions[locationId].sessions[startDate].push(
            currentSession
          )
        } else {
          let address = session.job.location.addressLineOne
          if (session.job.location.addressLineTwo) {
            address += ` ${session.job.location.addressLineTwo}`
          }
          currentLocationsSessions[locationId] = {
            location: {
              address,
              addressLineOne: session.job.location.addressLineOne,
              longitude: session.job.location.longitude,
              latitude: session.job.location.latitude,
              city: session.job.location.city,
              name: session.job.location.name || '',
              zipCode: session.job.location.zipCode,
            },
            sessions: {
              [startDate]: [currentSession],
            },
          }
        }
      })
      Object.keys(currentLocationsSessions).forEach((locationId) => {
        const sortedSessions = {}
        const dates = Object.keys(currentLocationsSessions[locationId].sessions)
        dates.sort(
          (a, b) =>
            DateTime.fromFormat(a, 'EEE LLL d yyyy').toJSDate() -
            DateTime.fromFormat(b, 'EEE LLL d yyyy').toJSDate()
        )
        dates.forEach((date) => {
          sortedSessions[date] =
            currentLocationsSessions[locationId].sessions[date]
        })
        currentLocationsSessions[locationId].sessions = sortedSessions
      })
      setAvailableSessionsTransformed(currentLocationsSessions)
    }
  }, [availableSessionsQueryData])

  const formik = useFormik({
    initialValues: {
      location: null,
      locationId: null,
      sessionPackageId: null,
      sessionPackage: null,
      startDate: null,
      endDate: null,
    },
    validationSchema: Yup.object().shape({
      location: Yup.string().nullable(),
      locationId: Yup.string().nullable(),
      jobId: Yup.string().nullable(),
      sessionPackageId: Yup.string().nullable(),
      sessionPackage: Yup.string().nullable(),
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
    }),
    validateOnChange: true,
  })

  useEffect(() => {
    if (
      formik.values.sessionPackageId &&
      formik.values.locationId &&
      formik.values.startDate &&
      formik.values.endDate
    ) {
      availableSessions({
        variables: {
          locationId: formik.values.locationId,
          sessionPackageId: formik.values.sessionPackageId,
          startDateTime: toUTC(formik.values.startDate, {
            startOfDay: true,
            isoFormat: true,
          }),
          endDateTime: toUTC(formik.values.endDate, {
            endOfDay: true,
            isoFormat: true,
          }),
        },
      })
    }
  }, [
    formik.values.startDate,
    formik.values.endDate,
    formik.values.locationId,
    formik.values.sessionPackageId,
  ])

  const handleFetchMoreLocations = () => {
    fetchMoreLocations({
      variables: {
        nameIcontains: formik.values.location,
        first: 10,
        after: locationData.locations.pageInfo.endCursor,
      },
    })
  }

  const handleLocationBlur = () => {
    setDisplayLocationResults(false)
  }

  const handleLocationChange = (e) => {
    setDisplayLocationResults(true)
    formik.setFieldValue('location', e.target.value)
    searchLocations({
      variables: {
        nameIcontains: e.target.value,
        first: 10,
      },
    })
  }

  const handleFetchMoreSessionPackages = () => {
    fetchMoreSessionPackages({
      variables: {
        nameIcontains: formik.values.sessionPackage,
        first: 10,
        after: sessionPackageData.sessionPackages.pageInfo.endCursor,
      },
    })
  }

  const handleSessinoPackageBlur = () => {
    setDisplaySessinoPackageResults(false)
  }

  const handleSessionPackageChange = (e) => {
    setDisplaySessinoPackageResults(true)
    formik.setFieldValue('sessionPackage', e.target.value)
    searchSessionPackages({
      variables: {
        nameIcontains: e.target.value,
        first: 10,
      },
    })
  }

  const formatAddress = (locationNode) => {
    const addressName = locationNode.name ? `${locationNode.name}, ` : ''
    const orgName = locationNode.organization?.name
      ? `, ${locationNode.organization.name}`
      : ''
    const addressLineTwo = locationNode.addressLineTwo
      ? `${locationNode.addressLineTwo}, `
      : ''
    const displayAddress = `${addressName}${locationNode.addressLineOne}, ${addressLineTwo}${locationNode.city}, ${locationNode.state}, ${locationNode.zipCode} ${orgName}`

    return displayAddress
  }

  const handleLocationClick = (node) => {
    const displayAddress = formatAddress(node)
    formik.setValues({
      location: displayAddress,
      locationId: node.id,
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
      sessionPackageId: formik.values.sessionPackageId,
    })
    setDisplayLocationResults(false)
  }

  const handleSessionPackageClick = (node) => {
    formik.setValues({
      location: formik.values.location,
      locationId: formik.values.locationId,
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
      sessionPackageId: node.id,
      calendarStep: formik.values.calendarStep,
      sessionPackage: `${node.title}, $${node.price}, ${node.durationLowMinutes}-${node.durationHighMinutes} minutes`,
    })
    setDisplaySessinoPackageResults(false)
  }

  if (!formik.values) return <></>
  let resultList
  if (
    availableSessionsQueryData &&
    formik.values.startDate &&
    formik.values.endDate
  ) {
    let startDate = new Date(formik.values.startDate)
    let endDate = new Date(formik.values.endDate)
    const dates = []
    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      dates.push(date.toDateString())
    }
    resultList = dates.map((date) => {
      let sessionsCount = 0
      Object.values(availableSessionsTransformed).forEach((location) => {
        if (location.sessions && location.sessions[date]) {
          location.sessions[date].forEach((openSession) => {
            sessionsCount += openSession.openSessions
          })
        }
      })
      return { date, sessions: sessionsCount }
    })
  }
  return (
    <>
      <Form.Row className="mt-2">
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              Location
              {formik.values.locationId && (
                <span
                  type="button"
                  className="p-0 ml-2 btn-link"
                  onClick={() => {
                    formik.setFieldValue('locationId', null)
                    formik.setFieldValue('location', '')
                  }}
                >
                  <XCircle />
                </span>
              )}
            </Form.Label>
            <Form.Control
              onBlur={handleLocationBlur}
              disabled={Boolean(formik.values.locationId)}
              placeholder="Search Locations"
              className={
                formik.values.locationId
                  ? 'form-control-sm border  d-inline'
                  : 'form-control-sm'
              }
              value={formik.values.location}
              onChange={(e) => handleLocationChange(e)}
            />

            {locationData && displayLocationResults ? (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                <InfiniteScroll
                  height={100}
                  dataLength={locationData.locations.edges.length}
                  next={handleFetchMoreLocations}
                  hasMore={locationData?.locations.pageInfo.hasNextPage}
                  loader={<Loading />}
                >
                  <Table size="sm" hover>
                    <tbody>
                      {locationData.locations.edges.map((location) => {
                        const { node } = location
                        return (
                          <tr
                            onMouseDown={() => handleLocationClick(node)}
                            key={node.id}
                            className="hover text-decoration-none"
                          >
                            <td>
                              {node.addressLineTwo && node.name ? (
                                <small>
                                  {`${node.name}, ${node.addressLineOne}, ${node.addressLineTwo}, ${node.city} ${node.zipCode}`}
                                </small>
                              ) : (
                                <>
                                  {node.name ? (
                                    <small>{`${node.name}, ${node.addressLineOne}, ${node.city} ${node.zipCode}`}</small>
                                  ) : (
                                    <small>{`${node.addressLineOne}, ${node.city} ${node.zipCode}`}</small>
                                  )}
                                </>
                              )}
                              <>
                                {node.organization ? (
                                  <small className="ml-3 p-1 bg-secondary text-white small rounded">
                                    {node.organization?.name}
                                  </small>
                                ) : null}
                              </>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              </div>
            ) : null}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              Session Package
              {formik.values.sessionPackageId && (
                <span
                  type="button"
                  className="p-0 ml-2 btn-link"
                  onClick={() => {
                    formik.setFieldValue('sessionPackageId', null)
                    formik.setFieldValue('sessionPackage', '')
                  }}
                >
                  <XCircle />
                </span>
              )}
            </Form.Label>
            <Form.Control
              onBlur={handleSessinoPackageBlur}
              disabled={Boolean(formik.values.sessionPackageId)}
              placeholder="search session packages"
              className={
                formik.values.sessionPackageId
                  ? 'form-control-sm border  d-inline'
                  : 'form-control-sm'
              }
              value={formik.values.sessionPackage}
              onChange={(e) => handleSessionPackageChange(e)}
            />
            {sessionPackageData && displaySessionPackageResults ? (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 100,
                  backgroundColor: 'white',
                }}
              >
                <InfiniteScroll
                  height={100}
                  dataLength={sessionPackageData.sessionPackages.edges.length}
                  next={handleFetchMoreSessionPackages}
                  hasMore={
                    sessionPackageData?.sessionPackages.pageInfo.hasNextPage
                  }
                  loader={<Loading />}
                >
                  <Table size="sm" hover>
                    <tbody>
                      {sessionPackageData.sessionPackages.edges.map((sp) => {
                        const { node } = sp
                        return (
                          <tr
                            onMouseDown={() => handleSessionPackageClick(node)}
                            key={node.id}
                            className="hover text-decoration-none"
                          >
                            <td>
                              {`${node.title}, $${node.price}, ${node.durationLowMinutes}-${node.durationHighMinutes} minutes`}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              </div>
            ) : null}
          </Form.Group>
        </Col>
        <Form.Group as={Col} md={2}>
          <Form.Label className="d-block">
            Start Date
            {formik.values.startDate && (
              <span
                type="button"
                className="p-0 ml-2 btn-link"
                onClick={() => {
                  formik.setFieldValue('startDate', null)
                }}
              >
                <XCircle />
              </span>
            )}
          </Form.Label>
          <DatePicker
            name="startDate"
            className="form-control form-control-sm"
            showPopperArrow={false}
            popperPlacement="bottom-start"
            selected={formik.values.startDate}
            onChange={(date) => {
              formik.setFieldValue('startDate', date)
            }}
            popperModifiers={{
              flip: {
                behavior: ['bottom'],
              },
              preventOverflow: {
                enabled: true,
              },
              hide: {
                enabled: true,
              },
            }}
          />
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label className="d-block">
            End Date
            {formik.values.endDate && (
              <span
                type="button"
                className="p-0 ml-2 btn-link"
                onClick={() => {
                  formik.setFieldValue('endDate', null)
                }}
              >
                <XCircle />
              </span>
            )}
          </Form.Label>
          <DatePicker
            name="endDate"
            className="form-control form-control-sm"
            showPopperArrow={false}
            popperPlacement="bottom-start"
            selected={formik.values.endDate}
            onChange={(date) => {
              formik.setFieldValue('endDate', date)
            }}
            popperModifiers={{
              flip: {
                behavior: ['bottom'],
              },
              preventOverflow: {
                enabled: true,
              },
              hide: {
                enabled: true,
              },
            }}
          />
        </Form.Group>
        {availableSessionsQueryData &&
          resultList &&
          formik.values.locationId &&
          formik.values.sessionPackageId &&
          formik.values.startDate &&
          formik.values.endDate && (
            <Row className="mt-3">
              <Col>
                <SortableTable
                  columns={[
                    {
                      Header: 'Date',
                      id: 'date',
                      accessor: 'date',
                    },
                    {
                      Header: 'Available Sessions',
                      id: 'available',
                      accessor: 'sessions',
                    },
                  ]}
                  data={resultList}
                />
              </Col>
            </Row>
          )}
      </Form.Row>
    </>
  )
}

export default AvailableSessions
