import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Modal, Table, Tab } from 'react-bootstrap'
import {
  ErrorMessage,
  Field,
  FieldArray,
  FormikProvider,
  useFormik,
} from 'formik'
import { useMutation, gql, useLazyQuery, useApolloClient } from '@apollo/client'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useReactiveVar } from '@apollo/client'
import { settingsVar, loggedInUserVar } from '../../libs/apollo'
import validator from 'validator'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Dot,
  PersonRolodex,
  PlusCircle,
  Trash,
  Bell,
  ClockHistory,
  CardChecklist,
  CalendarDate,
  CaretDown,
  CaretRight,
} from 'react-bootstrap-icons'
import { useHistory, useLocation } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import Schedule from '../schedule/Schedule'
import PhoneInput from 'react-phone-input-2'
import AuditLog from '../audit_log/AuditLog'
import { useParams } from 'react-router-dom'
import Loading from '../common/Loading'
import Notifications from '../notifications/Notifications'
import Tabs from '../common/Tabs'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import { CANADIAN_PROVINCES, US_STATES } from '../../libs/constant'

const ContactModal = (props) => {
  const { updateContact, contactId, showModal, toggleModal, organization } =
    props

  const { contact } = useParams()
  const client = useApolloClient()
  const history = useHistory()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('Schedule')
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [orgSearch, setOrgSearch] = useState()
  const [updateContact_, setUpdateContact] = useState()
  const [submitting, setSubmitting] = useState(false)
  const canMutate =
    ['Scheduling Manager', 'Administrator', 'Organization Contact'].includes(
      loggedInUser?.permissions?.group
    ) || loggedInUser.permissions.isOrgContact
  const canSeeHistory = !['Subject', 'Organization Contact'].includes(
    loggedInUser?.permissions?.group
  )
  const [displayOrganizationResults, setDisplayOrganizationResults] =
    useState(false)
  const [displayGaiaUserRegion, setDisplayGaiaUserRegion] = useState(true)
  const gaiaUserRegionsValidationMessage = [
    'at least one region must be default',
    'only one region can be default',
    'region must be unique',
  ]

  const [
    searchOrganizations,
    { data: organizationData, fetchMore: fetchMoreOrganizations },
  ] = useLazyQuery(gql`
    query Organizations(
      $after: String
      $first: Int
      $nameIcontains: String
      $regions: String
    ) {
      organizations(
        after: $after
        first: $first
        name_Icontains: $nameIcontains
        regions: $regions
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
            subjectGroups {
              edges {
                node {
                  id
                  name
                }
              }
            }
            organizationRegions(first: 1, default: true) {
              edges {
                node {
                  id
                  default
                  region {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [query] = useLazyQuery(
    gql`
      query Contact($contactId: ID!) {
        gaiaUser(id: $contactId) {
          contentType {
            model
            id
          }
          gaiaUserRegions {
            edges {
              node {
                id
                default
                region {
                  id
                  name
                  timezone
                }
              }
            }
          }
          emailNotificationsEnabled
          smsNotificationsEnabled
          notes
          email
          firstName
          lastName
          secondaryEmail
          fullName
          phoneNumber
          jobTitle
          id
          receiveInvoiceEmails
          secondaryPhoneNumber
          tasks {
            edges {
              node {
                description
                taskCollection {
                  name
                }
                dueDate
                eventDate
                finishedDate
                organization {
                  name
                  id
                }
                employees {
                  edges {
                    node {
                      gaiaUser {
                        fullName
                        email
                      }
                    }
                  }
                }
                contacts {
                  edges {
                    node {
                      fullName
                      email
                    }
                  }
                }
                status {
                  name
                  id
                }
              }
            }
          }
          organizationContacts {
            nodeCount
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        setUpdateContact(data.gaiaUser)
        formik.setValues({
          id: data.gaiaUser.id,
          firstName: data.gaiaUser.firstName,
          lastName: data.gaiaUser.lastName,
          jobTitle: data.gaiaUser.jobTitle,
          email: data.gaiaUser.email,
          receiveInvoiceEmails: data.gaiaUser.receiveInvoiceEmails,
          phoneNumber: data.gaiaUser.phoneNumber,
          secondaryPhoneNumber: data.gaiaUser.secondaryPhoneNumber,
          secondaryEmail: data.gaiaUser.secondaryEmail,
          notes: data.gaiaUser.notes,
          emailNotificationsEnabled: data.gaiaUser.emailNotificationsEnabled,
          smsNotificationsEnable: data.gaiaUser.smsNotificationsEnabled,
          gaiaUserRegions: data.gaiaUser?.gaiaUserRegions?.edges?.map(
            ({ node }) => ({
              id: node.id,
              regionId: node.region?.id,
              default: node.default,
            })
          ),
          organizations: data.gaiaUser.organizationContacts?.edges.map(
            (edge) => ({
              id: edge.node.id,
              name: edge.node.name,
            })
          ),
        })
      },
    }
  )

  useEffect(() => {
    if (contactId) {
      query({ variables: { contactId } })
    }
  }, [contactId])

  const organizationChange = (e) => {
    setDisplayOrganizationResults(true)
    setOrgSearch(e.target.value)
    searchOrganizations({
      variables: {
        after: null,
        first: 5,
        nameIcontains: e.target.value,
        regions: formik.values.gaiaUserRegions
          .map((region) => region.regionId)
          .join(','),
      },
      fetchPolicy: 'network-only',
    })
    if (e.target.value === '') {
      setDisplayOrganizationResults(false)
    }
  }

  const handleFetchMoreOrganizations = () => {
    fetchMoreOrganizations({
      variables: {
        after: organizationData.organizations.pageInfo.endCursor,
        first: 5,
        nameIcontains: orgSearch,
        regions: formik.values.gaiaUserRegions
          .map((region) => region.regionId)
          .join(','),
      },
    })
  }

  const handleOrganizationClick = (node, formik) => {
    setDisplayOrganizationResults(false)
    const updateOrganizations = [
      {
        name: node.name,
        id: node.id,
      },
    ].concat(formik.values.organizations)
    setOrgSearch()
    formik.setFieldValue('organizations', updateOrganizations)
    if (
      node?.organizationRegions?.edges.length > 0 &&
      node?.organizationRegions?.edges[0]?.node?.region &&
      formik.values.gaiaUserRegions?.length <= 1
    ) {
      formik.setFieldValue('gaiaUserRegions[0]', {
        regionId: node?.organizationRegions?.edges[0]?.node?.region.id,
        default: true,
      })
    }
  }
  const [update] = useMutation(
    gql`
      mutation UpdateGaiaUser($updateGaiaUserInput: UpdateGaiaUserInput!) {
        updateGaiaUser(input: $updateGaiaUserInput) {
          gaiaUser {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Contact Saved')
        setSubmitting(false)
        hideModal()
      },
      onError: (e) => {
        setSubmitting(false)
      },
      errorPolicy: 'all',
      refetchQueries: ['OrgContacts', 'Organization'],
    }
  )
  const [create] = useMutation(
    gql`
      mutation CreateGaiaUser($createGaiaUserInput: CreateGaiaUserInput!) {
        createGaiaUser(input: $createGaiaUserInput) {
          gaiaUser {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Contact Saved')
        setSubmitting(false)
        hideModal()
      },
      onError: (e) => {
        setSubmitting(false)
      },
      refetchQueries: ['OrgContacts', 'Organization'],
    }
  )

  useEffect(() => {
    if (organization) {
      const orgDef = {
        name: organization.name,
        id: organization.id,
      }
      if (!formik.values.organizations.includes(orgDef)) {
        formik.setFieldValue('organizations', [orgDef])
      }
      if (
        organization?.organizationRegions?.edges.length > 0 &&
        organization?.organizationRegions?.edges[0]?.node?.region &&
        formik.values.gaiaUserRegions?.length <= 1
      ) {
        formik.setFieldValue('gaiaUserRegions[0]', {
          regionId:
            organization?.organizationRegions?.edges[0]?.node?.region.id,
          default: true,
        })
      }
    }
  }, [organization])

  const formik = useFormik({
    initialValues: updateContact
      ? {
          id: updateContact.id,
          firstName: updateContact.firstName,
          lastName: updateContact.lastName,
          jobTitle: updateContact.jobTitle,
          email: updateContact.email,
          receiveInvoiceEmails: updateContact.receiveInvoiceEmails,
          phoneNumber: updateContact.phoneNumber,
          secondaryPhoneNumber: updateContact.secondaryPhoneNumber,
          secondaryEmail: updateContact.secondaryEmail,
          notes: updateContact.notes,
          emailNotificationsEnabled: updateContact.emailNotificationsEnabled,
          smsNotificationsEnabled: updateContact.smsNotificationsEnabled,
          gaiaUserRegions: updateContact?.gaiaUserRegions?.edges?.map(
            ({ node }) => ({
              id: node.id,
              regionId: node.region?.id,
              default: node.default,
            })
          ),
          active: updateContact.isActive,
          addressLineOne: updateContact.addressLineOne,
          addressLineTwo: updateContact.addressLineTwo,
          city: updateContact.city,
          state: updateContact.state,
          zipCode: updateContact.zipCode,
          organizations: updateContact.organizationContacts?.edges.map(
            (edge) => ({
              id: edge.node.id,
              name: edge.node.name,
            })
          ),
        }
      : {
          id: '',
          firstName: '',
          lastName: '',
          jobTitle: '',
          email: '',
          phoneNumber: '',
          secondaryPhoneNumber: '',
          secondaryEmail: '',
          password: '',
          confirmPassword: '',
          skipWelcomeEmail: true,
          receiveInvoiceEmails: false,
          notes: '',
          emailNotificationsEnabled: true,
          smsNotificationsEnabled: true,
          active: true,
          addressLineOne: '',
          addressLineTwo: '',
          city: '',
          state: '',
          zipCode: '',
          gaiaUserRegions: [
            {
              regionId: loggedInUser?.defaultRegion?.id,
              default: true,
            },
          ],
          organizations: organization
            ? [
                {
                  name: organization.name,
                  id: organization.id,
                },
              ]
            : [],
        },
    validationSchema: Yup.object().shape({
      id: Yup.string(),
      firstName: Yup.string().required('First Name is required'),
      jobTitle: Yup.string().nullable(),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string()
        .required('Email required')
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        })
        .test(
          'isUnique',
          'Employees cannot be contacts',
          async (value, context) => {
            if (value) {
              const { data } = await client.query({
                query: gql`
                  query GaiaUsersEmailCheckQuery($emailIexact: String) {
                    gaiaUsers(email_Iexact: $emailIexact) {
                      edges {
                        node {
                          employee {
                            id
                          }
                        }
                      }
                    }
                  }
                `,
                fetchPolicy: 'network-only',
                variables: {
                  emailIexact: value,
                },
              })
              if (
                data.gaiaUsers.edges.length > 0 &&
                data.gaiaUsers.edges[0].node?.employee?.id
              ) {
                return false
              }
              return true
            }
            return false
          }
        ),
      phoneNumber: Yup.string().nullable(),
      secondaryEmail: Yup.string().nullable(),
      secondaryPhoneNumber: Yup.string().nullable(),
      password: Yup.string()
        .nullable()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
        )
        .max(100, 'Password is too long'),
      skipWelcomeEmail: Yup.bool().nullable(),
      confirmPassword: Yup.string()
        .nullable()
        .test('isNotRepeat', 'passwords must match', (value, context) => {
          let valid = true
          if (
            context.parent.password &&
            value &&
            context.parent.password != value
          ) {
            valid = false
          }
          return valid
        }),
      gaiaUserRegions: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().nullable(),
            regionId: Yup.string().required('required'),
            default: Yup.boolean().nullable(),
          })
        )
        .test(
          'at-least-one-default',
          'at least one region must be default',
          (value) => {
            if (!value || value.length === 0) {
              return true
            }
            const defaultCount = value.filter((region) => region.default).length
            return defaultCount >= 1
          }
        )
        .test('only-one-default', 'only one region can be default', (value) => {
          if (!value || value.length === 0) {
            return true
          }
          const defaultCount = value.filter((region) => region.default).length
          return defaultCount <= 1
        })
        .test('unique-region-id', 'region must be unique', (value) => {
          if (!value || value.length === 0) {
            return true
          }
          const regionIds = value.map((region) => region.regionId)
          const uniqueRegionIds = new Set(regionIds)
          return regionIds.length === uniqueRegionIds.size
        }),
      notes: Yup.string().nullable(),
      receiveInvoiceEmails: Yup.bool().nullable(),
      emailNotificationsEnabled: Yup.bool(),
      smsNotificationsEnabled: Yup.bool(),
      active: Yup.bool().nullable(),
      addressLineOne: Yup.string().nullable(),
      addressLineTwo: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
      organizations: Yup.array().of(
        Yup.object()
          .shape({
            id: Yup.string().required('Organization Required'),
            name: Yup.string().required('Organization Required'),
          })
          .required('Organization required')
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      let password = values.password
      if (values.id && values.password == '') {
        password = null
      }
      if (values.organizations.length < 1) {
        formik.setFieldError('organizations', 'required')
        setSubmitting(false)
      } else {
        if (updateContact_) {
          update({
            variables: {
              updateGaiaUserInput: {
                gaiaUserInput: {
                  id: updateContact_.id,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  secondaryEmail: values.secondaryEmail,
                  phoneNumber: values.phoneNumber,
                  receiveInvoiceEmails: values.receiveInvoiceEmails,
                  secondaryPhoneNumber: values.secondaryPhoneNumber,
                  notes: values.notes,
                  jobTitle: values.jobTitle,
                  password,
                  emailNotificationsEnabled: values.emailNotificationsEnabled,
                  smsNotificationsEnabled: values.smsNotificationsEnabled,
                  active: values.active,
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  city: values.city,
                  state: values.state,
                  zipCode: values.zipCode,
                  gaiaUserRegions: values.gaiaUserRegions,
                  contactOrganizationIds: values.organizations.map(
                    (org) => org.id
                  ),
                },
              },
            },
          })
        } else {
          create({
            variables: {
              createGaiaUserInput: {
                gaiaUserInput: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  receiveInvoiceEmails: values.receiveInvoiceEmails,
                  skipWelcomeEmail: values.skipWelcomeEmail,
                  secondaryEmail: values.secondaryEmail,
                  phoneNumber: values.phoneNumber,
                  secondaryPhoneNumber: values.secondaryPhoneNumber,
                  notes: values.notes,
                  jobTitle: values.jobTitle,
                  gaiaUserRegions: values.gaiaUserRegions,
                  password,
                  orgContact: true,
                  groups: ['Organization Contact'],
                  emailNotificationsEnabled: values.emailNotificationsEnabled,
                  smsNotificationsEnabled: values.smsNotificationsEnabled,
                  active: values.active,
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  city: values.city,
                  state: values.state,
                  zipCode: values.zipCode,
                  contactOrganizationIds: values.organizations.map(
                    (org) => org.id
                  ),
                },
              },
            },
          })
        }
      }
    },
  })

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(updateContact)
      formik.setFieldValue(
        'gaiaUserRegions',
        updateContact.gaiaUserRegions.edges.map(({ node }) => ({
          id: node.id,
          regionId: node.region?.id,
          default: node.default,
        }))
      )
    }
  }, [updateContact])

  const hideModal = () => {
    formik.resetForm()
    toggleModal()
    setOrgSearch()
  }

  const [deleteContact] = useMutation(
    gql`
      mutation DeleteContact($deleteGaiaUserInput: DeleteGaiaUserInput!) {
        deleteGaiaUser(input: $deleteGaiaUserInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Contact Deleted')
        hideModal()
        setSubmitting(false)
      },
      errorPolicy: 'all',
      refetchQueries: ['OrgContacts', 'Organization'],
    }
  )

  const handleDelete = () => {
    deleteContact({
      variables: {
        deleteGaiaUserInput: {
          gaiaUserIds: updateContact_.id,
        },
      },
    })
  }
  if (showModal && (updateContact_ || !contactId)) {
    return (
      <>
        <div className="locationModal">
          <Modal
            id="editStudio"
            size={updateContact_ ? 'xl' : 'lg'}
            className={activeTab === 'Notifications' ? 'full-screen-modal' : ''}
            show={showModal}
            onHide={hideModal}
          >
            <Form onSubmit={formik.handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="new-title">
                  <PersonRolodex className="mr-2" />
                  {updateContact_ ? <>Edit Contact</> : <>New Contact</>}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      readOnly={!canMutate}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      readOnly={!canMutate}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <div className="d-flex align-items-center">
                      <Form.Label>Job Title</Form.Label>
                    </div>
                    <Form.Control
                      type="text"
                      name="jobTitle"
                      readOnly={!canMutate}
                      value={formik.values.jobTitle}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.jobTitle}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.jobTitle}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Login Access</Form.Label>
                    <Form.Row>
                      <Col xs={12} md={4} style={{ marginLeft: '35px' }}>
                        <Form.Check
                          type="checkbox"
                          checked={formik.values.active}
                          onChange={(e) => {
                            formik.setFieldValue('active', e.target.checked)
                          }}
                          isInvalid={formik.errors.active}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.active}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      readOnly={!canMutate}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Secondary Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="secondaryEmail"
                      readOnly={!canMutate}
                      value={formik.values.secondaryEmail}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.secondaryEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.secondaryEmail}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                      country="us"
                      className="phone-layout"
                      placeholder=""
                      name="phoneNumber"
                      disabled={!canMutate}
                      regions={['north-america']}
                      value={formik.values.phoneNumber}
                      onChange={(phone, country) =>
                        formik.setFieldValue('phoneNumber', phone)
                      }
                    />
                    {formik.errors.phoneNumber ? (
                      <span className="text-danger">
                        {formik.errors.phoneNumber}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Secondary Phone Number</Form.Label>
                    <PhoneInput
                      country="us"
                      className="phone-layout"
                      name="secondaryPhoneNumber"
                      regions={['north-america']}
                      placeholder=""
                      autoComplete="new-Phone"
                      disabled={!canMutate}
                      value={formik.values.secondaryPhoneNumber}
                      onChange={(phone, country) =>
                        formik.setFieldValue('secondaryPhoneNumber', phone)
                      }
                    />
                    {formik.errors.secondaryPhoneNumber ? (
                      <span className="text-danger">
                        {formik.errors.secondaryPhoneNumber}
                      </span>
                    ) : null}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Password</Form.Label>

                    <Form.Control
                      type="password"
                      name="password"
                      autoComplete="new-password"
                      readOnly={!canMutate}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      readOnly={!canMutate}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Address Line One</Form.Label>
                    <Form.Control
                      type="text"
                      name="addressLineOne"
                      readOnly={!canMutate}
                      value={formik.values.addressLineOne}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.addressLineOne}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.addressLineOne}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Address Line Two</Form.Label>
                    <Form.Control
                      type="text"
                      name="addressLineTwo"
                      readOnly={!canMutate}
                      value={formik.values.addressLineTwo}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.addressLineTwo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.addressLineOne}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      readOnly={!canMutate}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>
                      {settings.locationFormat === 'US' ? 'State' : 'Province'}
                    </Form.Label>
                    <Form.Control
                      name="state"
                      as="select"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      isInvalid={formik.errors.state}
                    >
                      <option>---</option>
                      {settings.locationFormat === 'US'
                        ? US_STATES.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))
                        : CANADIAN_PROVINCES.map((province) => (
                            <option key={province} value={province}>
                              {province}
                            </option>
                          ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="zipCode"
                      readOnly={!canMutate}
                      value={formik.values.zipCode}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.zipCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.zipCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {!organization && (
                    <Form.Group as={Col} md={4}>
                      {canMutate &&
                        loggedInUser?.permissions?.group !==
                          'Organization Contact' && (
                          <>
                            <Form.Label>Add Organizations</Form.Label>
                            <Form.Control
                              value={orgSearch}
                              placeholder="Search Organizations"
                              onChange={organizationChange}
                            />
                            {formik.errors.organizations && (
                              <p className="text-danger">
                                {formik.errors.organizations}
                              </p>
                            )}
                            {organizationData && displayOrganizationResults && (
                              <InfiniteScroll
                                height={100}
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  position: 'absolute',
                                  zIndex: 80,
                                }}
                                dataLength={
                                  organizationData.organizations.edges.length
                                } //This is important field to render the next data
                                next={handleFetchMoreOrganizations}
                                hasMore={
                                  organizationData?.organizations.pageInfo
                                    .hasNextPage
                                }
                                loader={<Loading />}
                              >
                                <Table size="sm" hover>
                                  <tbody>
                                    {organizationData.organizations.edges.map(
                                      (org) => {
                                        const { node } = org
                                        let display = true
                                        formik.values.organizations.forEach(
                                          (org) => {
                                            if (org.id == node.id) {
                                              display = false
                                            }
                                          }
                                        )
                                        if (display) {
                                          return (
                                            <tr
                                              onMouseDown={() =>
                                                handleOrganizationClick(
                                                  node,
                                                  formik
                                                )
                                              }
                                              key={node.id}
                                              className="hover text-decoration-none"
                                            >
                                              <td>
                                                <small>{node.name}</small>
                                              </td>
                                            </tr>
                                          )
                                        }
                                      }
                                    )}
                                  </tbody>
                                </Table>
                              </InfiniteScroll>
                            )}
                            <div className="mt-2" style={{ fontSize: '20px' }}>
                              {formik.values.organizations?.map(
                                ({ name, id }) => (
                                  <div key={id} className="d-inline-block mt-2">
                                    <small
                                      style={{
                                        padding: '5px',
                                        border: '.5px solid #6c757d',
                                        borderRadius: '0.25rem',
                                      }}
                                      className="rounded boarder mr-2 p-1 d-flex align-items-center"
                                    >
                                      {canMutate &&
                                        loggedInUser?.permissions?.group !==
                                          'Organization Contact' && (
                                          <Trash
                                            size={16}
                                            style={{ cursor: 'pointer' }}
                                            className="mr-2 mt-1 btn-link"
                                            onClick={() => {
                                              const updatedOrganizations = []
                                              formik.values.organizations.forEach(
                                                (org) => {
                                                  if (org.id !== id) {
                                                    updatedOrganizations.push(
                                                      org
                                                    )
                                                  }
                                                }
                                              )
                                              formik.setFieldValue(
                                                'organizations',
                                                updatedOrganizations
                                              )
                                            }}
                                          />
                                        )}

                                      <span style={{ fontSize: '16px' }}>
                                        {name}
                                      </span>
                                    </small>
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        )}
                    </Form.Group>
                  )}
                </Form.Row>
                {loggedInUser?.canManageRegions && settings?.tenantRegions && (
                  <FormikProvider value={formik}>
                    <Form.Row className="mb-3">
                      <Col className="d-flex align-items-center">
                        <button
                          type="button"
                          onClick={() =>
                            setDisplayGaiaUserRegion(!displayGaiaUserRegion)
                          }
                          className="px-0 btn-link mr-2"
                          style={{ marginTop: '-10px' }}
                        >
                          <>
                            {displayGaiaUserRegion ? (
                              <>
                                <CaretDown size={17} />
                              </>
                            ) : (
                              <>
                                <CaretRight size={17} />
                              </>
                            )}
                          </>
                        </button>
                        <Form.Label>Regions</Form.Label>
                        <ErrorMessage name="gaiaUserRegions">
                          {(msg) => {
                            return gaiaUserRegionsValidationMessage.includes(
                              msg
                            ) ? (
                              <span className="text-danger d-block mb-2 ml-2">
                                {msg}
                              </span>
                            ) : (
                              <></>
                            )
                          }}
                        </ErrorMessage>
                      </Col>
                    </Form.Row>
                    {displayGaiaUserRegion && (
                      <FieldArray
                        name="gaiaUserRegions"
                        render={(gaiaUserRegionArrayHelpers) => (
                          <Row>
                            <Col md={6}>
                              <div
                                style={
                                  formik.values.gaiaUserRegions.length > 1
                                    ? {
                                        overflowY: 'scroll',
                                        maxHeight: '350px',
                                        overflowX: 'hidden',
                                      }
                                    : null
                                }
                                className={
                                  formik.values.gaiaUserRegions.length > 1
                                    ? 'border p-3 mt-2'
                                    : ''
                                }
                              >
                                <div className="gaia-user-region-form">
                                  {formik.values.gaiaUserRegions.map(
                                    (gaiaUserRegion, i) => (
                                      <div
                                        key={i}
                                        className="border border-secondary rounded mb-3 p-2"
                                      >
                                        <Form.Group as={Row}>
                                          <Col md={2}>
                                            <Form.Label>Region</Form.Label>
                                          </Col>
                                          <Col md={10}>
                                            <RegionSearchInput
                                              dropdown
                                              formik={formik}
                                              formikValue={`gaiaUserRegions[${i}].region`}
                                              variables={{
                                                gaiaUser: formik.values.id,
                                              }}
                                              query={gql`
                                                query Regions(
                                                  $first: Int
                                                  $after: String
                                                  $search: String
                                                  $gaiaUser: String
                                                ) {
                                                  regions(
                                                    first: $first
                                                    after: $after
                                                    orderBy: "name"
                                                    search: $search
                                                    gaiaUser: $gaiaUser
                                                  ) {
                                                    nodeCount
                                                    pageInfo {
                                                      hasNextPage
                                                      endCursor
                                                    }
                                                    edges {
                                                      node {
                                                        id
                                                        name
                                                        timezone
                                                      }
                                                    }
                                                  }
                                                }
                                              `}
                                              error={
                                                gaiaUserRegionsValidationMessage.includes(
                                                  formik.errors.gaiaUserRegions
                                                )
                                                  ? ''
                                                  : formik.errors
                                                        .gaiaUserRegions
                                                        ?.length > 0
                                                    ? formik.errors
                                                        .gaiaUserRegions[i]
                                                        ?.regionId
                                                    : ''
                                              }
                                            />
                                          </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                          <Col md={2}>
                                            <Form.Label>Default</Form.Label>
                                          </Col>
                                          <Col md={10}>
                                            <Field
                                              type="checkbox"
                                              name={`gaiaUserRegions[${i}].default`}
                                              onChange={(e) => {
                                                const newValue =
                                                  e.target.checked
                                                if (newValue) {
                                                  formik.setFormikState(
                                                    (prevState) => ({
                                                      ...prevState,
                                                      values: {
                                                        ...prevState.values,
                                                        gaiaUserRegions:
                                                          prevState.values.gaiaUserRegions.map(
                                                            (
                                                              region,
                                                              index
                                                            ) => ({
                                                              ...region,
                                                              default:
                                                                index === i,
                                                            })
                                                          ),
                                                      },
                                                    })
                                                  )
                                                } else {
                                                  formik.setFieldValue(
                                                    `gaiaUserRegions[${i}].default`,
                                                    false
                                                  )
                                                }
                                              }}
                                            />
                                          </Col>
                                        </Form.Group>
                                        <div
                                          className="d-flex align-items-center mt-2 btn-link hover"
                                          onClick={() =>
                                            gaiaUserRegionArrayHelpers.remove(i)
                                          }
                                        >
                                          <Trash className="mr-2" />
                                          <small>Remove</small>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <Button
                                size="sm"
                                className="mt-1"
                                variant="link"
                                onClick={() => {
                                  gaiaUserRegionArrayHelpers.push({
                                    regionId: '',
                                    default: false,
                                  })
                                  let element = document.querySelector(
                                    '.gaia-user-region-form'
                                  )
                                  element.lastChild?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'end',
                                    inline: 'nearest',
                                  })
                                }}
                              >
                                <PlusCircle className="mr-2" />
                                Add Region
                              </Button>
                            </Col>
                          </Row>
                        )}
                      />
                    )}
                  </FormikProvider>
                )}
                <Form.Row className="mt-1">
                  {!updateContact_ && (
                    <Form.Group as={Col} md={4} className="ml-2">
                      <Form.Check
                        inline
                        label="Receive Welcome Email"
                        checked={!formik.values.skipWelcomeEmail}
                        disabled={!canMutate}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'skipWelcomeEmail',
                            !e.target.checked
                          )
                        }
                      />
                    </Form.Group>
                  )}
                  <Form.Group as={Col} md={3} className="ml-2">
                    <Form.Check
                      type="checkbox"
                      disabled={!canMutate}
                      checked={formik.values.receiveInvoiceEmails}
                      label="Receive Invoice Emails"
                      onChange={(e) => {
                        formik.setFieldValue(
                          `receiveInvoiceEmails`,
                          e.target.checked
                        )
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={2} className="ml-2">
                    <Form.Check
                      type="checkbox"
                      disabled={!canMutate}
                      checked={formik.values.smsNotificationsEnabled}
                      label="Receive SMS"
                      onChange={(e) => {
                        formik.setFieldValue(
                          `smsNotificationsEnabled`,
                          e.target.checked
                        )
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Form.Check
                      type="checkbox"
                      disabled={!canMutate}
                      checked={formik.values.emailNotificationsEnabled}
                      label="Receive Email"
                      onChange={(e) => {
                        formik.setFieldValue(
                          `emailNotificationsEnabled`,
                          e.target.checked
                        )
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    type="textarea"
                    name="notes"
                    style={{ height: '80px' }}
                    readOnly={!canMutate}
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.notes}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.notes}
                  </Form.Control.Feedback>
                </Form.Group>
                {updateContact_ && (
                  <Tabs
                    activeKey={activeTab}
                    onSelect={(tab) => setActiveTab(tab)}
                  >
                    <Tab
                      key="Schedule"
                      eventKey="Schedule"
                      style={{ marginTop: '10px' }}
                      title={
                        <b>
                          <CalendarDate className="mr-2" />
                          Schedule
                        </b>
                      }
                    >
                      <Schedule
                        contactId={updateContact_.id}
                        tableHeight={300}
                      />
                    </Tab>
                    {updateContact_ && !contact && canMutate && (
                      <Tab
                        key="Notifications"
                        eventKey="Notifications"
                        style={{ marginTop: '10px' }}
                        title={
                          <b>
                            <Bell className="mr-2" />
                            Notifications
                          </b>
                        }
                      >
                        {activeTab === 'Notifications' && (
                          <div className="mt-4">
                            <Notifications contact={updateContact_} />
                          </div>
                        )}
                      </Tab>
                    )}
                    {!contact && settings.tenantSubscription !== 'Basic' && (
                      <Tab
                        key="Tasks"
                        eventKey="Tasks"
                        style={{ marginTop: '10px' }}
                        title={
                          <b>
                            <CardChecklist className="mr-2" />
                            Tasks
                          </b>
                        }
                      >
                        <div className="mt-4">
                          {updateContact_?.tasks?.edges && (
                            <SortableInfiniteTable
                              onRowClick={(row) => {
                                history.push(
                                  `/organization/${row.original.node.organization.id}`
                                )
                              }}
                              rowPointer
                              tableData={updateContact_.tasks.edges}
                              tableColumns={[
                                {
                                  Header: 'Organization',
                                  accessor: 'node.organization.name',
                                },
                                {
                                  Header: 'Group',
                                  accessor: 'node.taskCollection.name',
                                },
                                {
                                  Header: 'Description',
                                  accessor: 'node.description',
                                },
                                {
                                  Header: 'Status',
                                  accessor: (row) => row.node.status?.name,
                                },
                                {
                                  Header: 'Event On',
                                  accessor: 'node.eventDate',
                                },
                                {
                                  Header: 'Due On',
                                  accessor: 'node.dueDate',
                                },
                                {
                                  Header: 'Completed On',
                                  accessor: 'node.finishedDate',
                                },
                                {
                                  Header: 'Employees',
                                  accessor: (row) => (
                                    <>
                                      {row.node.employees.edges.map(
                                        (employee) => (
                                          <div key={employee.node.id}>
                                            <p>
                                              <Dot className="mr-1" />
                                              <span
                                                style={{
                                                  fontSize: '12px',
                                                }}
                                              >
                                                {
                                                  employee.node.gaiaUser
                                                    .fullName
                                                }
                                                , {employee.node.gaiaUser.email}
                                              </span>
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </>
                                  ),
                                },
                                {
                                  Header: 'Contact',
                                  accessor: (row) => (
                                    <>
                                      {row.node.contacts.edges.map(
                                        (contact) => (
                                          <div key={contact.node.id}>
                                            <p>
                                              <Dot className="mr-1" />
                                              <span
                                                style={{
                                                  fontSize: '12px',
                                                }}
                                              >
                                                {contact.node.fullName},{' '}
                                                {contact.node.email}
                                              </span>
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </>
                                  ),
                                },
                              ]}
                              hasMoreTableData={false}
                              tableHeight={200}
                            />
                          )}
                        </div>
                      </Tab>
                    )}

                    {canSeeHistory && canMutate && !contact && (
                      <Tab
                        key="History"
                        eventKey="History"
                        style={{ marginTop: '10px' }}
                        title={
                          <b>
                            <ClockHistory className="mr-2" />
                            History
                          </b>
                        }
                      >
                        {activeTab === 'History' && (
                          <div className="mt-4">
                            <AuditLog
                              contentType={updateContact_.contentType.model}
                              id={updateContact_.id}
                            />
                          </div>
                        )}
                      </Tab>
                    )}
                  </Tabs>
                )}
                {canMutate && (
                  <Row className="mt-5 mb-3">
                    <Col md={3}>
                      <Button
                        type="submit"
                        variant="outline-primary"
                        block
                        disabled={submitting}
                      >
                        <PlusCircle className="mr-2" />
                        Save
                      </Button>
                    </Col>
                    {updateContact_ && canMutate && !contact && (
                      <Col md={3}>
                        <Button
                          variant="outline-danger"
                          block
                          disabled={submitting}
                          onClick={handleDelete}
                        >
                          <Trash className="mr-2" />
                          Delete
                        </Button>
                      </Col>
                    )}
                  </Row>
                )}
                {submitting && <Loading />}
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default ContactModal
