import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import {
  Trash,
  PlusCircle,
  Briefcase,
  ClockHistory,
} from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import * as Yup from 'yup'
import Loading from '../../common/Loading'
import Bags from '../Bag/Bags'
import { Tab } from 'react-bootstrap'
import Tabs from '../../common/Tabs'
import AuditLog from '../../audit_log/AuditLog'

const BagTypeForm = (props) => {
  const { bagType, afterSubmit } = props
  const [submitting, setSubmitting] = useState(false)
  const [displayItemTypesResults, setDisplayItemTypesResults] = useState(false)
  const [selectableItemTypes, setSelectableItemTypes] = useState([])
  const [activeTab, setActiveTab] = useState('bags')
  const [deleteEquipmentBagType] = useMutation(
    gql`
      mutation DeleteEquipmentBagType(
        $deleteEquipmentBagTypeInput: DeleteEquipmentBagTypeInput!
      ) {
        deleteEquipmentBagType(input: $deleteEquipmentBagTypeInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Bag Type Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [createEquipmentBagType] = useMutation(
    gql`
      mutation CreateEquipmentBagType($input: CreateEquipmentBagTypeInput!) {
        createEquipmentBagType(input: $input) {
          equipmentBagType {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Bag Type Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [updateEquipmentBagType] = useMutation(
    gql`
      mutation UpdateEquipmentBagType($input: UpdateEquipmentBagTypeInput!) {
        updateEquipmentBagType(input: $input) {
          equipmentBagType {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Bag Type Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [
    searchItemTypes,
    { data: itemTypesData, fetchMore: fetchMoreItemTypes },
  ] = useLazyQuery(
    gql`
      query EquipmentItemTypesSearch(
        $after: String
        $first: Int
        $nameIcontains: String
      ) {
        equipmentItemTypes(
          after: $after
          first: $first
          name_Icontains: $nameIcontains
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              miscellaneous
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  useEffect(() => {
    if (itemTypesData) {
      const formikIds = formik.values.itemTypes.map((item) => item.id)
      const uniqueItemTypes = []
      itemTypesData.equipmentItemTypes.edges.forEach((edge) => {
        if (!formikIds.includes(edge.node.id)) {
          uniqueItemTypes.push(edge)
        }
      })
      setSelectableItemTypes(uniqueItemTypes)
    }
  }, [itemTypesData])

  let itemTypes = []
  if (bagType?.equipmentItemTypesList) {
    const allItems = bagType.equipmentItemTypesList.map((edge) => {
      return {
        id: edge.id,
        name: edge.name,
        miscellaneous: edge.miscellaneous,
      }
    })
    itemTypes = allItems.reduce((unique, item) => {
      const index = unique.findIndex((uniqueItem) => uniqueItem.id === item.id)
      if (index !== -1) {
        unique[index] = { ...item, count: unique[index].count + 1 }
        return unique
      } else {
        return [...unique, { ...item, count: 1 }]
      }
    }, [])
  }

  const formik = useFormik({
    initialValues: bagType
      ? {
          name: bagType.name,
          categoryName: bagType.equipmentCategory
            ? bagType.equipmentCategory.name
            : '',
          categoryId: bagType.equipmentCategory
            ? bagType.equipmentCategory.id
            : '',
          itemTypes: itemTypes,
        }
      : {
          name: '',
          categoryName: '',
          categoryId: '',
          itemTypes: [],
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('required'),
      categoryName: Yup.string().nullable(),
      categoryId: Yup.string().nullable(),
      itemTypes: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          name: Yup.string().nullable(),
          category: Yup.string().nullable(),
          count: Yup.number().required('required'),
        })
      ),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      setSubmitting(true)
      const equipmentItemTypeIds = []
      values.itemTypes.forEach((itemType) => {
        for (let i = 0; i < itemType.count; i++) {
          equipmentItemTypeIds.push(itemType.id)
        }
      })
      if (bagType) {
        updateEquipmentBagType({
          variables: {
            input: {
              equipmentBagTypeInput: {
                id: bagType.id,
                name: values.name,
                equipmentCategoryId: values.categoryId,
                equipmentItemTypeIds,
              },
            },
          },
        })
      } else {
        createEquipmentBagType({
          variables: {
            input: {
              equipmentBagTypeInput: {
                name: values.name,
                equipmentCategoryId: values.categoryId,
                equipmentItemTypeIds,
              },
            },
          },
        })
      }
    },
  })

  const handleDelete = () => {
    setSubmitting(true)
    deleteEquipmentBagType({
      variables: {
        deleteEquipmentBagTypeInput: {
          equipmentBagTypeIds: bagType.id,
        },
      },
    })
  }

  const handleItemTypeChange = (e) => {
    formik.setFieldValue(`itemTypeName`, e.target.value)
    if (!e.target.value) {
      setDisplayItemTypesResults(false)
    } else {
      setDisplayItemTypesResults(true)
    }
    searchItemTypes({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const deleteItemTypeClick = (id) => {
    const index = formik.values.itemTypes.findIndex((item) => item.id === id)
    if (index !== -1) {
      const filteredItemTypes = [...formik.values.itemTypes]
      filteredItemTypes.splice(index, 1)
      formik.setFieldValue('itemTypes', filteredItemTypes)
    }
  }

  const handleItemTypeClick = (node) => {
    setDisplayItemTypesResults(false)
    const updatedItems = [
      ...formik.values.itemTypes,
      {
        id: node.id,
        name: node.name,
        count: 1,
        miscellaneous: node.miscellaneous,
      },
    ]
    formik.setFieldValue('itemTypes', updatedItems)
  }

  const handleItemTypeCountChange = (e, row) => {
    const newCount = parseInt(e.target.value, 10)
    if (!isNaN(newCount)) {
      const updatedItemTypeRows = formik.values.itemTypes.map((item) =>
        item.id === row.id ? { ...item, count: newCount } : item
      )
      formik.setFieldValue('itemTypes', updatedItemTypeRows)
    } else if (!e.target.value) {
      const updatedItemTypeRows = formik.values.itemTypes.map((item) =>
        item.id === row.id ? { ...item, count: '' } : item
      )
      formik.setFieldValue('itemTypes', updatedItemTypeRows)
    }
  }

  return (
    <>
      <div id="equipmentBagTypeForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="name"
                className="form-control-sm"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Item Types
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                placeholder="Search Item Types"
                value={formik.values.itemTypeName}
                onChange={(e) => handleItemTypeChange(e)}
                className={'form-control form-control-sm'}
              />
              {selectableItemTypes && displayItemTypesResults && (
                <InfiniteScroll
                  height={100}
                  dataLength={selectableItemTypes.length}
                  next={() => {
                    fetchMoreItemTypes({
                      variables: {
                        after:
                          itemTypesData.equipmentItemTypes.pageInfo.endCursor,
                        first: 10,
                        nameIcontains: formik.values.itemTypeName,
                      },
                    })
                  }}
                  hasMore={
                    itemTypesData?.equipmentItemTypes.pageInfo.hasNextPage
                  }
                  loader={<Loading />}
                >
                  <Table style={{ zIndex: 1000 }} size="sm" hover>
                    <tbody>
                      {selectableItemTypes.map((item) => {
                        const { node } = item
                        return (
                          <tr
                            onMouseDown={() => handleItemTypeClick(node)}
                            key={node.id}
                            className="hover text-decoration-none"
                          >
                            <td>
                              <small>
                                {node.name} {node.miscellaneous && <>(misc)</>}
                              </small>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              )}
            </Col>
          </Form.Group>
          {formik.values.itemTypes.length > 0 && (
            <Form.Group as={Row}>
              <Col sm="12" md={{ span: 6, offset: 3 }}>
                <SortableInfiniteTable
                  displayHeader={false}
                  tableData={formik.values.itemTypes}
                  tableHeight={200}
                  hideGlobalFilter={true}
                  hideGlobalFilterRecordCount={true}
                  hasMoreTableData={false}
                  tableColumns={[
                    {
                      Header: '',
                      id: 'name',
                      accessor: (row) => (
                        <>
                          {row.name} {row.miscellaneous && <>(misc)</>}
                        </>
                      ),
                    },
                    {
                      Header: '',
                      id: 'count',
                      accessor: (row) => {
                        return (
                          <>
                            <Form.Control
                              type="text"
                              value={row.count}
                              onChange={(e) =>
                                handleItemTypeCountChange(e, row)
                              }
                            />
                          </>
                        )
                      },
                    },
                    {
                      Header: '',
                      id: 'action',
                      accessor: (row) => (
                        <>
                          <span className="btn-link">
                            <Trash
                              onClick={() => {
                                deleteItemTypeClick(row.id)
                              }}
                            >
                              Delete
                            </Trash>
                          </span>
                        </>
                      ),
                    },
                  ]}
                />
              </Col>
            </Form.Group>
          )}
          {bagType && (
            <>
              <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                <Tab
                  eventKey="bags"
                  title={
                    <b>
                      <Briefcase className="mr-2" />
                      Bags
                    </b>
                  }
                >
                  {activeTab === 'bags' && (
                    <div className="mt-3">
                      <Bags equipmentBagTypeId={bagType.id} tableHeight={300} />
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="history"
                  title={
                    <b>
                      <ClockHistory className="mr-2" />
                      History
                    </b>
                  }
                >
                  {activeTab === 'history' && (
                    <div className="mt-3">
                      <AuditLog
                        contentType={bagType.contentType.model}
                        id={bagType.id}
                        tableHeight={300}
                      />
                    </div>
                  )}
                </Tab>
              </Tabs>
            </>
          )}
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <PlusCircle className="mr-2" />
                Save
              </Button>
            </Col>
            {bagType && (
              <Col md={3}>
                <Button
                  block
                  variant="outline-danger"
                  onClick={handleDelete}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
        </Form>
      </div>
    </>
  )
}

export default BagTypeForm
