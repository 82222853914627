import React, { useState } from 'react'
import { Row, Col, Form, Table } from 'react-bootstrap'
import { gql, useLazyQuery } from '@apollo/client'
import { XCircle, Trash, ExclamationTriangle } from 'react-bootstrap-icons'
import Loading from '../../../common/Loading'
import InfiniteScroll from 'react-infinite-scroll-component'

const JobEquipmentItemTypeCard = (props) => {
  const { formik, index } = props
  const [display, setDisplay] = useState(false)

  const [searchEquipmentItemTypes, { data: data, fetchMore: fetchMore }] =
    useLazyQuery(
      gql`
        query EquipmentItemTypes(
          $after: String
          $first: Int
          $nameIcontains: String
        ) {
          equipmentItemTypes(
            after: $after
            first: $first
            name_Icontains: $nameIcontains
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
      }
    )
  return (
    <>
      <div className="border border-secondary rounded my-3 p-2 bg-white">
        <Row
          className={
            formik.values.jobEquipmentItemTypes.length > 1
              ? 'mb-1 mt-1'
              : 'mb-3'
          }
        >
          <Col md={4}>
            <Form.Label>
              Item Type
              {formik.errors?.jobEquipmentItemTypes?.length > index &&
                formik.errors.jobEquipmentItemTypes[index] &&
                formik.errors.jobEquipmentItemTypes[index]
                  .equipmentItemTypeName && (
                  <small className="text-danger ml-2">
                    <ExclamationTriangle className="mr-1" />
                    {
                      formik.errors?.jobEquipmentItemTypes[index]
                        ?.equipmentItemTypeName
                    }
                  </small>
                )}
              {formik.values.jobEquipmentItemTypes[index]
                .equipmentItemTypeId && (
                <span
                  type="button"
                  className="p-0 ml-2 btn-link"
                  onClick={() => {
                    formik.setFieldValue(
                      `jobEquipmentItemTypes[${index}].equipmentItemTypeId`,
                      ''
                    )
                    formik.setFieldValue(
                      `jobEquipmentItemTypes[${index}].equipmentItemTypeName`,
                      ''
                    )
                  }}
                >
                  <XCircle />
                </span>
              )}
            </Form.Label>
            <Form.Control
              placeholder="Search Item Types"
              value={
                formik.values.jobEquipmentItemTypes[index].equipmentItemTypeName
              }
              onBlur={() => {
                setDisplay(false)
                formik.setFieldTouched(
                  `jobEquipmentItemTypes[${index}].equipmentItemTypeId`,
                  true
                )
              }}
              onChange={(e) => {
                setDisplay(true)
                formik.setFieldValue(
                  `jobEquipmentItemTypes[${index}].equipmentItemTypeName`,
                  e.target.value
                )
                searchEquipmentItemTypes({
                  variables: {
                    after: null,
                    first: 250,
                    nameIcontains: e.target.value,
                  },
                })
              }}
              readOnly={Boolean(
                formik.values.jobEquipmentItemTypes[index].equipmentItemTypeId
              )}
              className={
                formik.values.productId
                  ? ' border  form-control form-control-sm'
                  : 'form-control form-control-sm'
              }
            />
            {data?.equipmentItemTypes && display && (
              <InfiniteScroll
                height={100}
                dataLength={data.equipmentItemTypes.edges.length}
                next={() => {
                  fetchMore({
                    variables: {
                      after: data.equipmentItemTypes.pageInfo.endCursor,
                      first: 250,
                      nameIcontains: formik.values.productName,
                    },
                  })
                }}
                hasMore={data?.equipmentItemTypes.pageInfo.hasNextPage}
                loader={<Loading />}
              >
                <Table size="sm" hover>
                  <tbody>
                    {data.equipmentItemTypes.edges.map(
                      (jobEquipmentItemType) => {
                        const { node } = jobEquipmentItemType
                        if (
                          formik.values.jobEquipmentItemTypes.filter(
                            (jobEquipmentItemType) =>
                              jobEquipmentItemType.equipmentItemTypeId ===
                              node.id
                          ).length === 0
                        ) {
                          return (
                            <tr
                              onMouseDown={() => {
                                setDisplay(false)
                                formik.setFieldValue(
                                  `jobEquipmentItemTypes[${index}].equipmentItemTypeId`,
                                  node.id
                                )
                                formik.setFieldValue(
                                  `jobEquipmentItemTypes[${index}].equipmentItemTypeName`,
                                  node.name
                                )
                              }}
                              key={node.id}
                              className="hover text-decoration-none"
                            >
                              <td>
                                <small>{node.name}</small>
                              </td>
                            </tr>
                          )
                        }
                      }
                    )}
                  </tbody>
                </Table>
              </InfiniteScroll>
            )}
          </Col>
          <Col md={3}>
            <Form.Label>Required</Form.Label>
            <Form.Control
              type="number"
              min={1}
              step={1}
              className="form-control-sm"
              name={`jobEquipmentItemTypes[${index}].requiredQuantity`}
              value={
                formik.values.jobEquipmentItemTypes[index].requiredQuantity
              }
              onChange={formik.handleChange}
              isInvalid={
                formik.errors?.products?.length > index &&
                formik.errors.products[index]?.requiredQuantity
              }
            />
            {formik.errors?.products?.length > index &&
              formik.errors.products[index]?.requiredQuantity && (
                <small className="text-danger">
                  {formik.errors.products[index]?.requiredQuantity}
                </small>
              )}
          </Col>
          <Col md={3}>
            <Form.Label>Included</Form.Label>
            <Form.Control
              type="number"
              style={{
                backgroundColor:
                  formik.values.jobEquipmentItemTypes[index].includedQuantity >=
                  formik.values.jobEquipmentItemTypes[index].requiredQuantity
                    ? 'rgba(192,255,192,0.5)'
                    : 'rgb(255,192,192)',
              }}
              className="form-control-sm"
              name={`jobEquipmentItemTypes[${index}].includedQuantity`}
              disabled={true}
              value={
                formik.values.jobEquipmentItemTypes[index].includedQuantity
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <p size="sm">
                <small
                  className="btn-link"
                  onClick={() => {
                    const newJobEquipmentItemTypes = [
                      ...formik.values.jobEquipmentItemTypes,
                    ]
                    newJobEquipmentItemTypes.splice(index, 1)
                    formik.setFieldValue(
                      'jobEquipmentItemTypes',
                      newJobEquipmentItemTypes
                    )
                  }}
                >
                  <Trash className="me-1" /> Remove
                </small>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default JobEquipmentItemTypeCard
