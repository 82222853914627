import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import CancelSessionModal from './CancelSessionModal'
import CreateSessionModal from './CreateSessionModal'
import RescheduleSessionModal from './RescheduleSessionModal'
import ChangeSessionPackageModal from './ChangeSessionPackageModal'
import ScheduleResitModal from './ScheduleResitModal'
import SessionDetailModal from './SessionDetailModal'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import JobDetailModal from '../schedule/JobDetailModal'
import AdminSessionDetailModal from '../sessions/AdminSessionDetailModal'
import { ExclamationCircle } from 'react-bootstrap-icons'
import { useAWSS3 } from '../../libs/aws'
import './SelectSessionPackage.css'
import { isNullableType } from 'graphql'
import { Camera, PlusCircle } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useImagequixEnabled } from '../../libs/imagequix'
import Loading from '../common/Loading'
import { useParams } from 'react-router-dom'
import LocationModal from '../location/LocationModal'
import MapModal from '../location/MapModal'
import FotomerchantGalleryPasswordModal from '../fotomerchant/FotomerchantGalleryPasswordModal'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { formatRegion, formatTimezone } from '../../libs/utils'
import { useFormik } from 'formik'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'

const SubjectSessions = (props) => {
  const {
    subjectId,
    adminViewing: adminViewing_,
    setQueriesLoading,
    queriesLoading,
    techFlow,
    hideName,
    setHideParentCardElement,
  } = props
  const { contact } = useParams()
  const iqEnabled = useImagequixEnabled()
  const { toTimezone } = useDateTimeConverter()
  const settings = useReactiveVar(settingsVar)
  const [fotomerchantGalleryPassword, setFotomerchantGalleryPassword] =
    useState()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const awsS3 = useAWSS3()
  const canMutate = [
    'Scheduling Manager',
    'Administrator',
    'Scheduling Analyst',
    'Subject',
    'Organization Contact',
  ].includes(loggedInUser?.permissions?.group)
  let adminViewing = adminViewing_
  if (
    !adminViewing_ &&
    canMutate &&
    loggedInUser?.permissions?.group != 'Subject'
  ) {
    adminViewing = true
  }
  const isEmployee = [
    'Scheduling Manager',
    'Administrator',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  let hasMoreSessions = true
  let sessionsCursor
  let subjectGroupsNotBooked = []
  const [location, setLocation] = useState()
  const [showLocationModal, setShowLocationModal] = useState()
  const [showMapModal, setShowMapModal] = useState()
  const [subjectName, setSubjectName] = useState()
  const [jobDetailId, setJobDetailId] = useState()
  const [reschedulingNoShow, setReschedulingNoShow] = useState()
  const [freePackageResitFee, setFreePackageResitFee] = useState()
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [hasMoreSessionTableData, setHasMoreSessionTableData] = useState(false)
  const [subjectSessionTableCursor, setSubjectSessionTableCursor] = useState()
  const [sessionTableData, setSessionTableData] = useState([])
  const [showCancelSessionModal, setShowCancelSessionModal] = useState(false)
  const [cancelSessionId, setCancelSessionId] = useState(null)
  const [cancelSessionStartDateTime, setCancelSessionStartDateTime] =
    useState(null)
  const [cancelSessionPackagePrice, setCancelSessionPackagePrice] =
    useState(null)
  const [showBookSessionModal, setShowBookSessionModal] = useState(false)
  const [bookSessionLatestSessionId, setBookSessionLatestSessionId] = useState()
  const [bookSessionSubjectGroupName, setBookSessionSubjectGroupName] =
    useState()
  const [bookSessionSubjectGroupId, setBookSessionSubjectGroupId] = useState()
  const [organizationId, setOrganizationId] = useState('')
  const [packageCategoryId, setPackageCategoryId] = useState('')
  const [showRescheduleSessionModal, setShowRescheduleSessionModal] = useState()
  const [rescheduleSessionSubjectGroupId, setRescheduleSessionSubjectGroupId] =
    useState()
  const [
    rescheduleSessionSubjectGroupName,
    setRescheduleSessionSubjectGroupName,
  ] = useState()
  const [rescheduleSessionId, setRescheduleSessionId] = useState()
  const [rescheduleSessionPackageId, setRescheduleSessionPackageId] = useState()
  const [stripeCard, setStripeCard] = useState(isNullableType)
  const [showChangeSessionPackageModal, setShowChangeSessionPackageModal] =
    useState()
  const [
    changeSessionPackageSubjectGroupId,
    setChangeSessionPackageSubjectGroupId,
  ] = useState()
  const [
    changeSessionPackageSubjectGroupName,
    setChangeSessionPackageSubjectGroupName,
  ] = useState()
  const [changeSessionPackageSessionId, setChangeSessionPackageSessionId] =
    useState()
  const [
    changeSessionPackageCurrentPackageId,
    setChangeSessionPackageCurrentPackageId,
  ] = useState()
  const [showScheduleResitModal, setShowScheduleResitModal] = useState()
  const [scheduleResitSubjectGroupId, setScheduleResitSubjectGroupId] =
    useState()
  const [scheduleResitSubjectGroupName, setScheduleResitSubjectGroupName] =
    useState()
  const [scheduleResitSessionId, setScheduleResitSessionId] = useState()
  const [resitsIncludeFreeSessions, setResitsIncludeFreeSessions] = useState()
  const [
    scheduleResitPreviousSessionResit,
    setScheduleResitPreviousSessionResit,
  ] = useState()

  const [showBookedSessionDetailModal, setShowBookedSessionDetailModal] =
    useState(false)

  const [sessionDetailId, setSessionDetailId] = useState()
  const [filterCancelledSessions, setFilterCancelledSessions] = useState(false)
  const history = useHistory()

  const toggleLocationModal = () => {
    if (showLocationModal) {
      setLocation(null)
    }
    setShowLocationModal(!showLocationModal)
  }

  const toggleMapModal = () => {
    if (showMapModal) {
      setLocation(null)
    }
    setShowMapModal(!showMapModal)
  }

  const SUBJECT_SESSIONS_QUERY = gql`
    query SubjectSessionsQuery(
      $subjectId: ID
      $regionId: [ID]
      $cursor: String
      $cancelled: Boolean
    ) {
      sessions(
        subject: $subjectId
        region: $regionId
        after: $cursor
        first: 10
        cancelled: $cancelled
        sessionPackageChanged: false
        rescheduled: false
        orderBy: "-start_date_time"
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            region {
              timezone
            }
            imagequixSubject {
              imagequixId
              idQrCodeFile {
                fileName
                displayName
              }
              galleryQrCodeFile {
                fileName
                displayName
              }
            }
            fotomerchantSubject {
              fotomerchantId
              fotomerchantPassword
              fotomerchantClientSession {
                fotomerchantId
                fotomerchantClient {
                  fotomerchantId
                }
              }
            }
            organization {
              name
              category
            }
            schedulingPolicies {
              resitsIncludeFreeSessions
              refundPolicy
              freePackageResitFee
              timeRefundSessionPackageCost
              timeRefundFee
              timeRefundHours
              applyPolicyFree
              applyPolicyPaid
              applyNoShowPolicyFree
              applyNoShowPolicyPaid
              noShowFee
            }
            paid
            refunded
            packageCategory {
              id
              name
              resitsAvailable
            }
            stripePaymentIntents(orderBy: "created", first: 1) {
              edges {
                node {
                  id
                  stripeId
                  refunded
                  stripeRefundIntents(orderBy: "-created", first: 1) {
                    edges {
                      node {
                        refundedBySystem
                        refundedBy {
                          fullName
                        }
                      }
                    }
                  }
                }
              }
            }
            resitSession {
              startDateTime
              cancelled
            }
            noShowRescheduleSession {
              startDateTime
              cancelled
            }
            sessionPackage {
              id
              title
              price
              customPriceAndDuration
              description
              durationHighMinutes
              durationLowMinutes
            }
            billSubject
            coupon {
              code
              percentSavings
              dollarSavings
            }
            subject {
              gaiaUser {
                firstName
                lastName
                fullName
                gaiaUserRegions(default: true, first: 1) {
                  edges {
                    node {
                      region {
                        id
                        name
                        timezone
                      }
                    }
                  }
                }
                stripeCustomer {
                  stripePaymentMethods(primary: true) {
                    edges {
                      node {
                        stripeId
                        stripeResource
                        created
                      }
                    }
                  }
                }
              }
            }
            startDateTime
            endDateTime
            job {
              name
              startDateTime
              id
              region {
                timezone
              }
              location {
                id
                name
                fullAddress
                addressLineOne
                shippingAddress
                billingAddress
                addressLineTwo
                city
                state
                zipCode
                archived
                latitude
                mapDefault
                longitude
                studio
                contentType {
                  model
                  id
                }
                subject {
                  id
                  gaiaUser {
                    firstName
                    lastName
                  }
                  organization {
                    name
                  }
                }
                organization {
                  id
                  name
                }
              }
              completed
            }
            noShow
            cancelled
            resitScheduled
            rescheduled
            sessionPackageChanged
            completed
            waiveRescheduleCancelFee
            waiveBookingFee
            subjectGroup {
              id
              name
              endDateTime
              resitsAvailable
              subjectGroupRegions(default: true) {
                edges {
                  node {
                    region {
                      id
                      timezone
                    }
                  }
                }
              }
              organization {
                id
                name
              }
            }
            created
          }
        }
      }
    }
  `
  const SUBJECT_SUBJECT_GROUPS_NOT_BOOKED_QUERY = gql`
    query SubjectGroupsNotBookedQuery($subjectId: ID!) {
      subject(id: $subjectId) {
        id
        gaiaUser {
          firstName
          lastName
          fullName
          email
        }
        subjectGroups {
          nodeCount
        }
        studentId
        subjectGroupsNotBooked {
          subjectGroup {
            id
            name
            startDateTime
            endDateTime
            subjectGroupRegions(default: true) {
              edges {
                node {
                  id
                  default
                  region {
                    id
                    timezone
                  }
                }
              }
            }
          }
          latestCompleteSession {
            id
            noShow
          }
        }
      }
    }
  `
  const adminTableColumns = [
    {
      Header: 'Status',
      id: 'status',
      accessor: 'status',
    },
    {
      Header: 'Job',
      id: 'job',
      accessor: 'job',
    },
    {
      Header: 'Session Package',
      id: 'sessionPackage',
      accessor: 'sessionPackage',
    },
    {
      Header: 'Subject Group',
      id: 'subjectGroup',
      accessor: 'subjectGroup',
    },
    {
      Header: 'Session Package Category',
      id: 'sessionPackageCategory',
      accessor: 'packageCategory',
    },
    {
      Header: 'Organization',
      id: 'organization',
      accessor: 'organization',
    },
    {
      Header: 'Location',
      id: 'location',
      accessor: 'location',
    },
  ]

  const onTdClick = (cell) => {
    setSessionDetailId(cell.row.original.id)
    toggleSessionDetailModal()
  }

  const subjectTableColumns = [
    {
      Header: 'Status',
      accessor: 'status',
      id: 'status',
    },
    {
      Header: 'Session Package',
      accessor: 'sessionPackage',
      id: 'sessionPackage',
    },
    {
      Header: 'Booking',
      accessor: 'subjectGroup',
      id: 'subjectGroup',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
  ]

  const {
    error: sessionsError,
    data: sessionsData,
    fetchMore: sessionsFetchMore,
    refetch: sessionsRefetch,
  } = useQuery(SUBJECT_SESSIONS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      subjectId,
      cancelled: filterCancelledSessions,
    },
    onCompleted: () => {
      if (setQueriesLoading && queriesLoading && queriesLoading.sessions) {
        setQueriesLoading((prevState) => ({
          ...prevState,
          sessions: false,
        }))
      }
    },
    errorPolicy: 'all',
  })

  const { data: stripePaymentMethodData, error: stripePaymentMethodError } =
    useQuery(
      gql`
        query StripePaymentMethod($subjectId: ID) {
          stripePaymentMethods(
            stripeCustomer_GaiaUser_Subject_Id: $subjectId
            primary: true
          ) {
            edges {
              node {
                stripeResource
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        variables: {
          subjectId,
        },
        errorPolicy: 'all',
      }
    )

  const {
    error: subjectSubjectGroupsNotBookedError,
    data: subjectSubjectGroupsNotBookedData,
  } = useQuery(SUBJECT_SUBJECT_GROUPS_NOT_BOOKED_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      subjectId,
    },
    onCompleted: () => {
      if (
        setQueriesLoading &&
        queriesLoading &&
        queriesLoading.subjectGroupsNotBooked
      ) {
        setQueriesLoading((prevState) => ({
          ...prevState,
          subjectGroupsNotBooked: false,
        }))
      }
    },
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (
      stripePaymentMethodData &&
      stripePaymentMethodData?.stripePaymentMethods?.edges[0]?.node
    ) {
      const stripeCard = JSON.parse(
        stripePaymentMethodData?.stripePaymentMethods?.edges[0]?.node
          ?.stripeResource
      )
      setStripeCard(stripeCard)
    }
  }, [stripePaymentMethodData])

  const handleSubjectGroupsNotBookedQueryData = () => {
    const currentSubjectGroupsNotBookedResult = []
    subjectSubjectGroupsNotBookedData.subject?.subjectGroupsNotBooked.forEach(
      (subjectGroupNotBooked) => {
        const { subjectGroup } = subjectGroupNotBooked
        let timezone
        if (subjectGroup.subjectGroupRegions.edges.length > 0) {
          timezone = formatTimezone(
            subjectGroup.subjectGroupRegions.edges[0].node.region.timezone
          )
        }
        const subjectGroupStartDateTime = toTimezone(
          subjectGroup.startDateTime,
          { timezone: timezone }
        )
        const subjectGroupEndDateTime = toTimezone(subjectGroup.endDateTime, {
          timezone: timezone,
        })
        currentSubjectGroupsNotBookedResult.push({
          name: subjectGroup.name,
          startDateTime: subjectGroupStartDateTime,
          endDateTime: subjectGroupEndDateTime,
          id: subjectGroup.id,
          latestCompleteSession: subjectGroupNotBooked.latestCompleteSession,
        })
      }
    )
    return currentSubjectGroupsNotBookedResult
  }

  useEffect(() => {
    if (subjectSubjectGroupsNotBookedData?.subject) {
      const { gaiaUser } = subjectSubjectGroupsNotBookedData.subject
      if (gaiaUser.fullName) {
        setSubjectName(`${gaiaUser.fullName}'s`)
      }
    }
  }, [subjectSubjectGroupsNotBookedData])

  const constructCompletedCanResitSession = (
    sessionNode,
    sessionId,
    previousSessionResit,
    sessionTimezone
  ) => {
    const sessionStartDateTimeStr = toTimezone(sessionNode.startDateTime, {
      humanReadableSort: true,
      timezone: sessionTimezone,
    })
    const status = `Complete on ${sessionStartDateTimeStr}`
    const action = (
      <>
        <div className="sessions-action-buttons">
          {(sessionNode?.packageCategory?.resitsAvailable ||
            sessionNode?.subjectGroup?.resitsAvailable) &&
            !sessionNode.sessionPackage.customPriceAndDuration && (
              <Button
                type="link"
                className="link-btn-layout btn-link mb-2"
                size="sm"
                style={{ display: 'block' }}
                onClick={() => {
                  handleScheduleResitClick(
                    sessionId,
                    sessionNode.subjectGroup,
                    previousSessionResit,
                    sessionNode.subjectGroup?.organization.id,
                    sessionNode.packageCategory?.id,
                    sessionNode.schedulingPolicies.resitsIncludeFreeSessions,
                    sessionNode.schedulingPolicies.freePackageResitFee
                  )
                }}
              >
                Schedule Resit
              </Button>
            )}
          {sessionNode?.imagequixSubject?.imagequixId && iqEnabled && (
            <>
              <Button
                type="link"
                className="link-btn-layout btn-link mt-2"
                size="sm"
                style={{ display: 'block' }}
                onClick={() => {
                  window.open(
                    `https://vando.imagequix.com/s${sessionNode.imagequixSubject.imagequixId}`,
                    '_blank'
                  )
                }}
              >
                Gallery
              </Button>
            </>
          )}
        </div>
      </>
    )
    return {
      status,
      action,
    }
  }

  const construstCurrentSession = (sessionNode) => {
    let status
    let action
    let sessionPackage
    let paid
    let sessionTimezone = formatTimezone(sessionNode.region?.timezone)
    if (sessionNode.waiveBookingFee) {
      paid = '$0'
    } else {
      paid = `$${sessionNode.paid}`
    }
    let refundIntent
    if (sessionNode.stripePaymentIntents.edges.length > 0) {
      const paymentIntent = sessionNode.stripePaymentIntents.edges[0].node
      if (paymentIntent.stripeRefundIntents.edges.length > 0) {
        refundIntent = paymentIntent.stripeRefundIntents.edges[0].node
        const refundLabel = (systemRefund, refundedBy) => {
          if (systemRefund) return 'refunded by system'
          if (refundedBy) return `refunded by ${refundedBy?.fullName}`
          return ''
        }

        const isRefunded = paymentIntent.refunded
        const refundBySystem = refundIntent.refundedBySystem
        const refundedBy = refundIntent.refundedBy
        if (isEmployee) {
          if (isRefunded) {
            paid = `${paid} ${refundLabel(refundBySystem, refundedBy)}`
          } else {
            paid = `${paid} partially ${refundLabel(
              refundBySystem,
              refundedBy
            )}`
          }
        } else {
          if (refundBySystem || refundedBy) {
            paid = isRefunded
              ? `${paid} refunded`
              : `${paid} partially refunded`
          }
        }
      }
    }
    if (sessionNode.noShow) {
      if (sessionNode.noShowRescheduleSession) {
        const resitSessionStartDateTimeStr = toTimezone(
          sessionNode.noShowRescheduleSession.startDateTime,
          { humanReadableSort: true, timezone: sessionTimezone }
        )
        const sessionStartDateTimeStr = toTimezone(sessionNode.startDateTime, {
          humanReadableSort: true,
          timezone: sessionTimezone,
        })
        status = (
          <>
            <span style={{ fontSize: '12px' }}>
              Missed on {sessionStartDateTimeStr}
              <br />
              Rescheduled on {resitSessionStartDateTimeStr}
            </span>
          </>
        )
      } else {
        const sessionStartDateTimeStr = toTimezone(sessionNode.startDateTime, {
          humanReadableSort: true,
          timezone: sessionTimezone,
        })
        status = (
          <span>
            <span style={{ color: 'red' }}>
              <ExclamationCircle className="mr-1" />
            </span>
            Missed on {sessionStartDateTimeStr}
          </span>
        )
        action = (
          <>
            <div className="sessions-action-buttons">
              {(sessionNode?.packageCategory?.resitsAvailable ||
                sessionNode?.subjectGroup?.resitsAvailable) &&
                !sessionNode.sessionPackage.customPriceAndDuration && (
                  <Button
                    type="link"
                    className="link-btn-layout btn-link mb-2"
                    size="sm"
                    style={{ display: 'block' }}
                    onClick={() => {
                      if (sessionNode?.subjectGroup) {
                        handleBookSessionClick(
                          sessionNode?.subjectGroup.id,
                          sessionNode?.subjectGroup.name,
                          sessionNode?.id,
                          true
                        )
                      } else {
                        handleRescheduleSessionClick(
                          sessionNode.id,
                          sessionNode.subjectGroup,
                          sessionNode.sessionPackage.id,
                          sessionNode.packageCategory?.id,
                          true
                        )
                      }
                    }}
                  >
                    Reschedule
                  </Button>
                )}
              {sessionNode?.imagequixSubject?.imagequixId && iqEnabled && (
                <>
                  <Button
                    type="link"
                    className="link-btn-layout btn-link mt-2"
                    size="sm"
                    style={{ display: 'block' }}
                    onClick={() => {
                      window.open(
                        `https://vando.imagequix.com/s${sessionNode.imagequixSubject.imagequixId}`,
                        '_blank'
                      )
                    }}
                  >
                    Gallery
                  </Button>
                </>
              )}
            </div>
          </>
        )
      }
    } else if (sessionNode.completed) {
      if (sessionNode.resitSession) {
        const resitSessionStartDateTimeStr = toTimezone(
          sessionNode.resitSession.startDateTime,
          { humanReadableSort: true, timezone: sessionTimezone }
        )
        const sessionStartDateTimeStr = toTimezone(sessionNode.startDateTime, {
          humanReadableSort: true,
          timezone: sessionTimezone,
        })
        status = (
          <>
            <span style={{ fontSize: '12px' }}>
              Complete on {sessionStartDateTimeStr}
              <br />
              Resit on {resitSessionStartDateTimeStr}
            </span>
          </>
        )
      } else {
        const statusAction = constructCompletedCanResitSession(
          sessionNode,
          sessionNode.id,
          true,
          sessionTimezone
        )
        status = statusAction.status
        action = statusAction.action
        sessionPackage = statusAction.sessionPackage
      }
    } else if (sessionNode.cancelled) {
      status = 'Canceled'
    } else {
      // For upcoming session this condition works
      const sessionStartDateTimeStr = toTimezone(sessionNode.startDateTime, {
        humanReadableSort: true,
        timezone: sessionTimezone,
      })
      if (sessionNode.job.completed) {
        status = `Upcoming on ${sessionStartDateTimeStr}`
      } else {
        status = `Upcoming on ${sessionStartDateTimeStr}`
        action = (
          <>
            <div className="sessions-action-buttons">
              {!sessionNode.sessionPackage.customPriceAndDuration && (
                <>
                  <Button
                    className="link-btn-layout btn-link mb-2"
                    size="sm"
                    onClick={() =>
                      handleChangeSessionPackageClick(
                        sessionNode.id,
                        sessionNode.subjectGroup,
                        sessionNode.sessionPackage.id,
                        sessionNode.subjectGroup?.organization.id,
                        sessionNode.packageCategory?.id
                      )
                    }
                    style={{ display: 'block' }}
                  >
                    Change Session
                  </Button>
                  <Button
                    className="link-btn-layout btn-link mb-2"
                    size="sm"
                    onClick={() => {
                      handleRescheduleSessionClick(
                        sessionNode.id,
                        sessionNode.subjectGroup,
                        sessionNode.sessionPackage.id,
                        sessionNode.packageCategory?.id
                      )
                    }}
                    style={{ display: 'block' }}
                  >
                    Reschedule
                  </Button>
                </>
              )}
              <Button
                type="link"
                className="link-btn-layout btn-link"
                size="sm"
                onClick={() =>
                  handleCancelSessionClick(
                    sessionNode,
                    sessionNode.startDateTime,
                    sessionNode.paid
                  )
                }
                style={{ display: 'block' }}
              >
                Cancel
              </Button>
              {sessionNode?.imagequixSubject?.imagequixId && iqEnabled && (
                <>
                  <Button
                    type="link"
                    className="link-btn-layout btn-link mt-2"
                    size="sm"
                    style={{ display: 'block' }}
                    onClick={() => {
                      window.open(
                        `https://vando.imagequix.com/s${sessionNode.imagequixSubject.imagequixId}`,
                        '_blank'
                      )
                    }}
                  >
                    Gallery
                  </Button>
                </>
              )}
            </div>
          </>
        )
      }
    }

    sessionPackage = (
      <>
        <div
          className="sessions-buttons"
          onClick={() => handleBookedSessionDetailClick(sessionNode)}
        >
          {sessionNode.sessionPackage.title}
        </div>
      </>
    )
    const currentSession = {
      id: sessionNode.id,
      fotomerchantSubject: sessionNode.fotomerchantSubject,
      created: toTimezone(sessionNode.created, {
        humanReadableSort: true,
        timezone: sessionTimezone,
      }),
      sessionPackage,
      locationNode: sessionNode?.job.location,
      imagequixSubject: sessionNode.imagequixSubject,
      packageCategory: sessionNode.packageCategory?.name
        ? sessionNode.packageCategory.name
        : null,
      location: (
        <>
          <div
            className="sessions-buttons"
            onClick={() => handleBookedSessionDetailClick(sessionNode)}
          >
            {sessionNode.job.location.fullAddress}
          </div>
        </>
      ),
      event: sessionNode.subjectGroup?.name,
      subjectGroupId: sessionNode.subjectGroup?.id,
      subjectGroup: sessionNode.subjectGroup?.name,
      organization: sessionNode.subjectGroup?.organization?.name,
      organizationId: sessionNode.subjectGroup?.organization?.id,
      billSubject: sessionNode.billSubject,
      session: sessionNode.sessionPackage,
      coupon: sessionNode.coupon,
      job: sessionNode.job.name,
      node: sessionNode,
      paid,
      jobId: sessionNode.job.id,
      status,
      action,
    }
    return currentSession
  }

  useEffect(() => {
    if (sessionsData?.sessions) {
      hasMoreSessions = sessionsData.sessions.pageInfo.hasNextPage
      setHasMoreSessionTableData(hasMoreSessions)
      if (sessionsData.sessions.pageInfo.endCursor) {
        sessionsCursor = sessionsData.sessions.pageInfo.endCursor
        setSubjectSessionTableCursor(sessionsCursor)
      }
      const currentSessions = []
      sessionsData.sessions.edges.forEach((session) => {
        const sessionNode = session.node
        const currentSession = construstCurrentSession(sessionNode)
        currentSessions.push(currentSession)
      })
      setSessionTableData(currentSessions)
    }
  }, [sessionsData])

  const formik = useFormik({
    initialValues: {
      regionId: loggedInUser?.defaultRegion?.id,
    },
  })

  useEffect(() => {
    setSubjectSessionTableCursor(null)
    setSessionTableData([])
    if (setQueriesLoading) {
      setQueriesLoading((prevState) => ({
        ...prevState,
        sessions: true,
      }))
    }
    let regionId
    if (formik.values.regionId) {
      regionId = formik.values.regionId
    }
    sessionsRefetch({
      subjectId,
      regionId,
      cancelled: filterCancelledSessions,
    })
  }, [filterCancelledSessions])

  const fetchMoreSessions = () => {
    let regionId
    if (formik.values.regionId) {
      regionId = formik.values.regionId
    }
    sessionsFetchMore({
      variables: {
        cursor: subjectSessionTableCursor,
        subjectId,
        regionId,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        hasMoreSessions = fetchMoreResult.sessions.pageInfo.hasNextPage
        setHasMoreSessionTableData(hasMoreSessions)
        if (fetchMoreResult.sessions.pageInfo.endCursor) {
          sessionsCursor = fetchMoreResult.sessions.pageInfo.endCursor
          setSubjectSessionTableCursor(sessionsCursor)
        }
        const currentSessions = []
        fetchMoreResult.sessions.edges.forEach((session) => {
          const sessionNode = session.node
          if (
            sessionNode.noShow ||
            (!sessionNode.sessionPackageChanged && !sessionNode.rescheduled)
          ) {
            const currentSession = construstCurrentSession(sessionNode)
            currentSessions.push(currentSession)
          }
        })
        setSessionTableData((prevState) => {
          const currentIds = new Set(currentSessions.map((item) => item.id))
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.id)
          )
          return [...filteredPrevState, ...currentSessions]
        })
      },
    })
  }

  const toggleSessionDetailModal = () => {
    if (sessionDetailId) {
      setSessionDetailId(null)
      setShowBookedSessionDetailModal(false)
    } else {
      setShowBookedSessionDetailModal(true)
    }
  }

  const handleBookedSessionDetailClick = (session) => {
    toggleSessionDetailModal()
    setSessionDetailId(session.id)
  }

  const toggleCancelSessionModal = () => {
    setShowCancelSessionModal(!showCancelSessionModal)
  }

  const handleCancelSessionClick = (
    session,
    sessionStartDateTime,
    sessionPackagePrice
  ) => {
    setCancelSessionId(session)
    setCancelSessionStartDateTime(sessionStartDateTime)
    setCancelSessionPackagePrice(sessionPackagePrice)
    toggleCancelSessionModal()
  }

  const handleBookSessionClick = (
    subjectGroupId,
    subjectGroupName,
    latestSessionId,
    reschedulingNoShow_ = null
  ) => {
    setBookSessionLatestSessionId(latestSessionId)
    setBookSessionSubjectGroupName(subjectGroupName)
    setBookSessionSubjectGroupId(subjectGroupId)
    toggleBookSessionModal()
    if (reschedulingNoShow_) {
      setReschedulingNoShow(reschedulingNoShow_)
    }
  }

  const handleRescheduleSessionClick = (
    sessionId,
    subjectGroup,
    sessionPackageId,
    packageCategoryId = null,
    reschedulingNoShow_ = null
  ) => {
    let subjectGroupId
    let subjectGroupName
    if (subjectGroup) {
      subjectGroupId = subjectGroup.id
      subjectGroupName = subjectGroup.name
    }
    setPackageCategoryId(packageCategoryId)
    setOrganizationId(organizationId)
    setRescheduleSessionId(sessionId)
    setRescheduleSessionSubjectGroupId(subjectGroupId)
    setRescheduleSessionSubjectGroupName(subjectGroupName)
    setRescheduleSessionPackageId(sessionPackageId)
    toggleRescheduleSessionModal(true)
    if (reschedulingNoShow_) {
      setReschedulingNoShow(reschedulingNoShow_)
    }
  }

  const handleChangeSessionPackageClick = (
    sessionId,
    subjectGroup,
    currentSessionPackageId,
    organizationId,
    packageCategoryId
  ) => {
    let subjectGroupId
    let subjectGroupName
    if (subjectGroup) {
      subjectGroupId = subjectGroup.id
      subjectGroupName = subjectGroup.name
    }
    if (packageCategoryId) {
      setPackageCategoryId(packageCategoryId)
    }
    setOrganizationId(organizationId)
    setChangeSessionPackageSessionId(sessionId)
    setChangeSessionPackageSubjectGroupId(subjectGroupId)
    setChangeSessionPackageSubjectGroupName(subjectGroupName)
    setChangeSessionPackageCurrentPackageId(currentSessionPackageId)
    toggleChangeSessionPackageModal(true)
  }

  const handleScheduleResitClick = (
    sessionId,
    subjectGroup,
    previousSessionResit,
    organizationId,
    packageCategoryId,
    resitsIncludeFreeSessions,
    freePackageResitFee
  ) => {
    let subjectGroupId
    let subjectGroupName
    if (subjectGroup) {
      subjectGroupId = subjectGroup.id
      subjectGroupName = subjectGroup.name
    }
    if (!subjectGroup) {
      setPackageCategoryId(packageCategoryId)
    }
    setOrganizationId(organizationId)
    setScheduleResitSessionId(sessionId)
    setScheduleResitSubjectGroupId(subjectGroupId)
    setScheduleResitSubjectGroupName(subjectGroupName)
    setScheduleResitPreviousSessionResit(previousSessionResit)
    setResitsIncludeFreeSessions(resitsIncludeFreeSessions)
    setFreePackageResitFee(freePackageResitFee)
    toggleScheduleResitModal(true)
  }

  const toggleBookSessionModal = () => {
    setShowBookSessionModal((prevState) => !prevState)
  }

  const toggleRescheduleSessionModal = (show = null) => {
    if (!show) {
      setPackageCategoryId(null)
      setOrganizationId(null)
      setRescheduleSessionId(null)
      setRescheduleSessionSubjectGroupId(null)
      setRescheduleSessionSubjectGroupName(null)
      setRescheduleSessionPackageId(null)
    }
    setShowRescheduleSessionModal((prevState) => !prevState)
  }

  const toggleChangeSessionPackageModal = (show = null) => {
    if (!show) {
      setOrganizationId(null)
      setChangeSessionPackageSessionId(null)
      setChangeSessionPackageSubjectGroupId(null)
      setChangeSessionPackageSubjectGroupName(null)
      setChangeSessionPackageCurrentPackageId(null)
      setPackageCategoryId(null)
    }
    setShowChangeSessionPackageModal((prevState) => !prevState)
  }

  const toggleScheduleResitModal = (show = null) => {
    if (!show) {
      setOrganizationId(null)
      setScheduleResitSessionId(null)
      setScheduleResitSubjectGroupId(null)
      setScheduleResitSubjectGroupName(null)
      setScheduleResitPreviousSessionResit(null)
      setResitsIncludeFreeSessions(null)
      setFreePackageResitFee(null)
    }
    setShowScheduleResitModal((prevState) => !prevState)
  }

  const toggleJobDetailModal = () => {
    if (showJobDetailModal) {
      setJobDetailId(null)
    }
    setShowJobDetailModal(!showJobDetailModal)
  }

  if (
    sessionsError ||
    subjectSubjectGroupsNotBookedError ||
    stripePaymentMethodError
  )
    return <>Error loading sessions</>

  if (!awsS3?.client) return <></>

  if (
    !subjectSubjectGroupsNotBookedData ||
    !stripePaymentMethodData ||
    !awsS3?.client
  )
    return (
      <>
        <Loading />
      </>
    )

  let tableColumns
  if (adminViewing) {
    tableColumns = adminTableColumns
  } else {
    tableColumns = subjectTableColumns
  }
  if (loggedInUser?.canManageRegions && settings?.tenantRegions) {
    tableColumns.push({
      Header: 'Region',
      id: 'region',
      accessor: 'region',
    })
  }
  if (canMutate) {
    tableColumns.push({
      Header: '',
      accessor: 'action',
    })
  }
  subjectGroupsNotBooked = handleSubjectGroupsNotBookedQueryData()

  return (
    <>
      <div className="subjectSessions">
        {!techFlow && !hideName && (
          <h3 style={{ fontSize: '30px' }} className="d-inline mb-4">
            {subjectName && subjectName} Sessions
          </h3>
        )}
        <>
          <div className="mt-3 mb-4">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {canMutate && (
                <>
                  {subjectGroupsNotBooked.length > 0 &&
                    subjectGroupsNotBooked.map((subjectGroup) => (
                      <button
                        type="button"
                        style={{ width: 'auto' }}
                        className="btn-link"
                        key={subjectGroup.id}
                        onClick={() => {
                          let reschedulingNoShow = false
                          if (subjectGroup?.latestCompleteSession?.noShow) {
                            reschedulingNoShow = true
                          }
                          handleBookSessionClick(
                            subjectGroup.id,
                            subjectGroup.name,
                            subjectGroup?.latestCompleteSession?.id,
                            reschedulingNoShow
                          )
                        }}
                      >
                        {adminViewing ? (
                          <PlusCircle className="mr-2 mb-1" />
                        ) : (
                          <Camera className="mr-2 mb-1" />
                        )}
                        Book {subjectGroup.name} Session
                      </button>
                    ))}

                  <button
                    type="button"
                    style={{ width: 'auto' }}
                    className="btn-link"
                    onClick={() => {
                      contact
                      if (isEmployee) {
                        history.push({
                          pathname: '/book-new-session',
                          state: {
                            subjectNonSchoolSession: true,
                            subject: {
                              id: subjectId,
                              gaiaUser: {
                                fullName: subjectName,
                              },
                            },
                          },
                        })
                      } else if (contact) {
                        history.push({
                          pathname: '/session-type/contact',
                          state: {
                            subjectNonSchoolSession: true,
                            subject: {
                              id: subjectId,
                              gaiaUser: {
                                fullName: subjectName,
                              },
                            },
                          },
                        })
                      } else {
                        history.push({
                          pathname: '/session-type',
                          state: {
                            subjectNonSchoolSession: true,
                            subject: {
                              id: subjectId,
                              gaiaUser: {
                                fullName: subjectName,
                              },
                            },
                          },
                        })
                      }
                    }}
                  >
                    {adminViewing ? (
                      <PlusCircle className="mr-2 mb-1" />
                    ) : (
                      <Camera className="mr-2 mb-1" />
                    )}
                    Book {subjectGroupsNotBooked?.length > 0 && <>Other </>}{' '}
                    Session
                  </button>
                </>
              )}
              <span
                onClick={() => {
                  setFilterCancelledSessions(!filterCancelledSessions)
                }}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginLeft: '-20px',
                  padding: '10px 20px',
                }}
              >
                <Form.Check
                  name="filterCancelledSessions"
                  type="switch"
                  label="Cancelled Sessions"
                  checked={filterCancelledSessions}
                  style={{
                    fontSize: '1rem',
                    color: '#333',
                  }}
                />
              </span>
            </div>
          </div>
          {loggedInUser?.canManageRegions && settings?.tenantRegions && (
            <Row>
              <Col md={3}>
                <RegionSearchInput dropdown mustHaveValue formik={formik} />
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <SortableInfiniteTable
                tableHeight={500}
                loading={queriesLoading?.sessions}
                tableData={sessionTableData}
                tableColumns={tableColumns}
                searchWidth={3}
                fetchMoreTableData={fetchMoreSessions}
                hasMoreTableData={hasMoreSessionTableData}
                onTdClicks={{
                  subjectGroup: onTdClick,
                  status: onTdClick,
                  sessionPackage: onTdClick,
                  organization: onTdClick,
                  sessionPackageCategory: onTdClick,
                  job: onTdClick,
                  region: onTdClick,
                }}
                rowPointer
                hideGlobalFilter={!adminViewing}
                loadingMessage="Loading Sessions..."
              />
            </Col>
          </Row>
          <CancelSessionModal
            showModal={showCancelSessionModal}
            toggleModal={toggleCancelSessionModal}
            session={cancelSessionId}
            sessionStartDateTime={cancelSessionStartDateTime}
            sessionPackagePrice={cancelSessionPackagePrice}
            adminViewing={adminViewing}
          />
          <CreateSessionModal
            latestSessionId={bookSessionLatestSessionId}
            subjectId={subjectId}
            stripeCard={stripeCard}
            subjectGroupId={bookSessionSubjectGroupId}
            subjectGroupName={bookSessionSubjectGroupName}
            showModal={showBookSessionModal}
            toggleModal={toggleBookSessionModal}
            rescheduleSessionId={rescheduleSessionId}
            setReschedulingNoShow={setReschedulingNoShow}
            reschedulingNoShow={reschedulingNoShow}
            adminViewing={adminViewing}
            setHideParentCardElement={setHideParentCardElement}
          />
          <RescheduleSessionModal
            subjectId={subjectId}
            rescheduleSessionId={rescheduleSessionId}
            subjectGroupName={rescheduleSessionSubjectGroupName}
            subjectGroupId={rescheduleSessionSubjectGroupId}
            stripeCard={stripeCard}
            sessionPackageId={rescheduleSessionPackageId}
            showModal={showRescheduleSessionModal}
            setReschedulingNoShow={setReschedulingNoShow}
            reschedulingNoShow={reschedulingNoShow}
            toggleModal={toggleRescheduleSessionModal}
            adminViewing={adminViewing}
            organizationId={organizationId}
            packageCategoryId={packageCategoryId}
            setHideParentCardElement={setHideParentCardElement}
          />
          <ChangeSessionPackageModal
            currentSessionPackageId={changeSessionPackageCurrentPackageId}
            currentSessionId={changeSessionPackageSessionId}
            subjectId={subjectId}
            subjectGroupId={changeSessionPackageSubjectGroupId}
            subjectGroupName={changeSessionPackageSubjectGroupName}
            showModal={showChangeSessionPackageModal}
            toggleModal={toggleChangeSessionPackageModal}
            adminViewing={adminViewing}
            stripeCard={stripeCard}
            organizationId={organizationId}
            packageCategoryId={packageCategoryId}
            setHideParentCardElement={setHideParentCardElement}
          />
          <ScheduleResitModal
            scheduleResitSessionId={scheduleResitSessionId}
            scheduleResitPreviousSessionResit={
              scheduleResitPreviousSessionResit
            }
            freePackageResitFee={freePackageResitFee}
            subjectId={subjectId}
            organizationId={organizationId}
            subjectGroupId={scheduleResitSubjectGroupId}
            packageCategoryId={packageCategoryId}
            subjectGroupName={scheduleResitSubjectGroupName}
            showModal={showScheduleResitModal}
            toggleModal={toggleScheduleResitModal}
            stripeCard={stripeCard}
            adminViewing={adminViewing}
            resitsIncludeFreeSessions={resitsIncludeFreeSessions}
            setHideParentCardElement={setHideParentCardElement}
          />
          {adminViewing ? (
            <AdminSessionDetailModal
              showModal={showBookedSessionDetailModal}
              toggleModal={toggleSessionDetailModal}
              sessionId={sessionDetailId}
            />
          ) : (
            <SessionDetailModal
              showModal={showBookedSessionDetailModal}
              toggleModal={toggleSessionDetailModal}
              sessionId={sessionDetailId}
            />
          )}
          <JobDetailModal
            jobId={jobDetailId}
            showModal={showJobDetailModal}
            toggleModal={toggleJobDetailModal}
          />
          <LocationModal
            location={location}
            showModal={showLocationModal}
            toggleModal={toggleLocationModal}
          />
          <MapModal
            location={location}
            showModal={showMapModal}
            toggleModal={toggleMapModal}
          />
          <FotomerchantGalleryPasswordModal
            hideFotomerchantLogo
            showModal={fotomerchantGalleryPassword}
            toggleModal={setFotomerchantGalleryPassword}
            fotomerchantPassword={fotomerchantGalleryPassword}
          />
        </>
      </div>
    </>
  )
}

export default SubjectSessions
