import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { Trash, PlusCircle } from 'react-bootstrap-icons'
import DeleteFormModal from './DeleteFormModal'
import { loggedInUserVar } from '../../libs/apollo'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Forms = (props) => {
  const {
    sessionId,
    sessionDescription,
    subjectId,
    subjectDesciption,
    employeeId,
    employeeDescription,
    jobId,
    jobDescription,
    subjectGroupId,
    subjectGroupDescription,
    organizationId,
    organizationDescription,
    employeeJobId,
    employeeJobDescription,
  } = props
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedFormIds, setCheckedFormIds] = useState([])
  const [debounceTimeout, setDebounceTimeout] = useState(null)

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Forms(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $subject: [ID]
        $employee: [ID]
        $job: [ID]
        $subjectGroup: [ID]
        $organization: [ID]
        $employeeJob: [ID]
        $session: [ID]
      ) {
        forms(
          first: 20
          after: $cursor
          search: $searchTerm
          orderBy: $orderBy
          employee: $employee
          subject: $subject
          job: $job
          subjectGroup: $subjectGroup
          organization: $organization
          employeeJob: $employeeJob
          session: $session
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              employee {
                gaiaUser {
                  fullName
                }
              }
              employeeJob {
                id
                description
              }
              subject {
                id
                gaiaUser {
                  fullName
                }
              }
              organization {
                id
                name
              }
              job {
                id
                description
              }
              subjectGroup {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const constructQueryVariables = useCallback(() => {
    const variables = {
      cursor: queryData?.forms?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (sessionId) {
      variables.session = sessionId
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    if (employeeId) {
      variables.employee = employeeId
    }
    if (jobId) {
      variables.job = jobId
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
    }
    if (organizationId) {
      variables.organization = organizationId
    }
    if (employeeJobId) {
      variables.employeeJob = employeeJobId
    }
    return variables
  }, [
    queryData?.forms?.pageInfo?.endCursor,
    searchTerm,
    orderBy,
    sessionId,
    subjectId,
    employeeId,
    jobId,
    subjectGroupId,
    organizationId,
    employeeJobId,
  ])

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = constructQueryVariables()
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.forms) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    queryFetchMore({
      variables,
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = constructQueryVariables()
    variables.cursor = null
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = constructQueryVariables()
      variables.cursor = null
      variables.searchTerm = searchValue || undefined
      setLoadingSearch(true)
      query({ variables })
    },
    [constructQueryVariables, query]
  )

  const handleSearchTermChange = useCallback(
    (event) => {
      const currentSearchTerm = event.target.value || undefined
      setSearchTerm(currentSearchTerm)

      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      const newTimeout = setTimeout(() => {
        executeSearchQuery(currentSearchTerm)
      }, 500)

      setDebounceTimeout(newTimeout)
    },
    [executeSearchQuery, debounceTimeout]
  )

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const onTdClick = (cell) => {
    history.push(`/form/${cell.row.original.node.id}`)
  }

  const handleFormCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedFormIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedFormIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
      serverSort: true,
    },
    {
      Header: 'Job',
      id: 'job',
      accessor: (row) => {
        if (row.node.job) {
          return row.node.job.description
        }
      },
      orderBy: 'job__start_date_time',
      serverSort: true,
    },
    {
      Header: 'Subject Group',
      id: 'subjectGroup',
      accessor: (row) => {
        if (row.node.subjectGroup) {
          return row.node.subjectGroup.name
        }
      },
      orderBy: 'subject_group__name',
      serverSort: true,
    },
    {
      Header: 'Organization',
      id: 'organization',
      accessor: (row) => {
        if (row.node.organization) {
          return row.node.organization.name
        }
      },
      orderBy: 'organization__name',
      serverSort: true,
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: (row) => {
        if (row.node.subject) {
          return row.node.subject.gaiaUser.fullName
        }
      },
      orderBy: 'subject__gaia_user__full_name',
      serverSort: true,
    },
    {
      Header: 'Employee',
      id: 'employee',
      accessor: (row) => {
        if (row.node.employee) {
          return row.node.employee.gaiaUser.fullName
        }
      },
      orderBy: 'employee__gaia_user__full_name',
      serverSort: true,
    },
    {
      Header: 'Employee Job',
      id: 'employeeJob',
      accessor: (row) => {
        if (row.node.employeeJob) {
          return row.node.employeeJob.description
        }
      },
      orderBy: 'employee_job__job__start_date_time',
      serverSort: true,
    },
  ]
  if (canMutate) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.forms?.edges.forEach((edge) => {
                    if (!checkedFormIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedFormIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedFormIds([])
                }
              }}
            />
            {checkedFormIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedFormIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedFormIds.includes(row.node.id)}
                onChange={(e) => handleFormCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={'Search Forms'}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          {canMutate && (
            <Button
              variant="link"
              onClick={() => {
                history.push('/form')
              }}
            >
              <PlusCircle className="mr-2" />
              New Form
            </Button>
          )}
          {checkedFormIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedFormIds.length === 1 ? (
                    <>Delete Form</>
                  ) : (
                    <>Delete Forms</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={queryData?.forms?.edges ? queryData?.forms?.edges : []}
            loadingMessage="Loading Forms..."
            tableColumns={tableColumns}
            loading={loadingSearch || !queryData}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.forms?.pageInfo?.hasNextPage}
            onTdClicks={{
              name: onTdClick,
              job: onTdClick,
              subjectGroup: onTdClick,
              organization: onTdClick,
              subject: onTdClick,
              employee: onTdClick,
              employeeJob: onTdClick,
            }}
            tableHeight={700}
            rowPointer
            hideGlobalFilter
            handleSortByChange={handleSortByChange}
          />
        </Col>
      </Row>
      <DeleteFormModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedFormIds={setCheckedFormIds}
        ids={checkedFormIds.length > 1 ? checkedFormIds : null}
        id={checkedFormIds.length === 1 ? checkedFormIds[0] : null}
      />
    </>
  )
}
export default Forms
