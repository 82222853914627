import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import NewSessionPackageModal from './NewSessionPackageModal'
import EditSessionPackageModal from './EditSessionPackageModal'
import CategoriesModal from './CategoriesModal'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import { Images, PlusCircle, Tag, Trash } from 'react-bootstrap-icons'
import Page from '../layout/Page'
import DeleteSessionPackageModal from './DeleteSessionPackageModal'
import {
  fotomerchantClientSessionAdminUrl,
  useFotomerchantEnabled,
} from '../../libs/fotomerchant'
import Loading from '../common/Loading'

const SessionPackages = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )
  const [loadingSearch, setLoadingSearch] = useState(false)
  const defaultOrderBy = 'title'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const fotomerchantEnabled = useFotomerchantEnabled()
  const [hasMoreTableData, setHasMoreTableData] = useState(true)
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const [cursor, setCursor] = useState()
  const [sessionPackages, setSessionPackages] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [showAddSessionPackageModal, setShowAddSessionPackageModal] =
    useState(false)
  const [showEditSessionPackageModal, setShowEditSessionPackageModal] =
    useState(false)
  const [editSessionPackageId, setEditSessionPackageId] = useState()
  const [showCategoriesModal, setShowCategoriesModal] = useState(false)
  const [checkedPackageIds, setCheckedPackageIds] = useState([])
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const SESSION_PACKAGES_QUERY = gql`
    query SessionPackagesQuery(
      $cursor: String
      $searchTerm: String
      $orderBy: String
    ) {
      sessionPackages(
        first: 25
        after: $cursor
        title_Icontains: $searchTerm
        orderBy: $orderBy
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            title
            price
            sessionCount
            customPriceAndDuration
            durationLowMinutes
            durationHighMinutes
            packageCategory {
              id
              name
              archived
              fotomerchantClientSession {
                fotomerchantId
                fotomerchantClient {
                  fotomerchantId
                }
                fotomerchantClientSessionTemplate {
                  fotomerchantId
                }
              }
            }
          }
        }
      }
    }
  `
  const tableColumns = [
    {
      Header: 'Title',
      id: 'title',
      accessor: 'title',
      serverSort: true,
    },
    {
      Header: 'Price',
      id: 'price',
      accessor: 'price',
      serverSort: true,
      orderBy: 'price',
    },
    {
      Header: 'Duration',
      id: 'duration',
      accessor: 'duration',
      serverSort: true,
      orderBy: 'duration_high_minutes',
    },
    {
      Header: 'Category',
      id: 'category',
      accessor: 'packageCategory',
      serverSort: true,
      orderBy: 'package_category__name',
    },
    {
      Header: 'Custom Price & Duration',
      id: 'customPriceAndDuration',
      accessor: 'customPriceAndDuration',
      serverSort: true,
      orderBy: 'custom_price_and_duration',
    },
  ]
  if (fotomerchantEnabled) {
    tableColumns.push({
      Header: 'Fotomerchant',
      id: 'fotomerchantClient',
      accessor: (row) => {
        if (row.node.packageCategory?.fotomerchantClientSession) {
          return (
            <>
              <Button variant="link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={fotomerchantClientSessionAdminUrl(
                    row.node.packageCategory.fotomerchantClientSession
                      .fotomerchantClient.fotomerchantId,
                    row.node.packageCategory.fotomerchantClientSession
                      .fotomerchantId
                  )}
                >
                  <img
                    className="mr-2"
                    src={window.location.origin + '/fotomerchant.svg'}
                    style={{ height: '20px' }}
                    alt="Organization logo"
                  />
                  <span style={{ fontSize: '12px' }}>Client Session</span>
                </a>
              </Button>
            </>
          )
        }
      },
    })
  }
  if (canMutate) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  sessionPackages.forEach((sessionPackage) => {
                    if (
                      !sessionPackage.node.custom &&
                      !checkedPackageIds.includes(sessionPackage.node.id)
                    ) {
                      appendIds.push(sessionPackage.node.id)
                    }
                  })
                  setCheckedPackageIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedPackageIds([])
                }
              }}
            />
            {checkedPackageIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedPackageIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        if (!row.node.custom) {
          return (
            <>
              <Form.Group as={ButtonGroup} className="align-items-center">
                <Form.Check
                  className="ml-2 mt-2"
                  type="checkbox"
                  checked={checkedPackageIds.includes(row.node.id)}
                  onChange={(e) => handleCheck(e, row)}
                />
              </Form.Group>
            </>
          )
        }
      },
    })
  }
  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedPackageIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedPackageIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(SESSION_PACKAGES_QUERY, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          orderBy: defaultOrderBy,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])
  useEffect(() => {
    if (queryData?.sessionPackages) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      if (queryData.sessionPackages.pageInfo.endCursor) {
        setCursor(queryData.sessionPackages.pageInfo.endCursor)
      }
      setHasMoreTableData(queryData.sessionPackages.pageInfo.hasNextPage)
      const currentSessionPackages = queryData.sessionPackages.edges.map(
        (sessionPackage) => {
          return {
            node: sessionPackage.node,
            id: sessionPackage.node.id,
            customPriceAndDuration: sessionPackage.node.customPriceAndDuration
              ? 'Yes'
              : 'No',
            title: sessionPackage.node.title,
            sessionCount: sessionPackage.node.sessionCount.toLocaleString(),
            packageCategory:
              sessionPackage.node.packageCategory &&
              !sessionPackage.node.packageCategory?.archived
                ? sessionPackage.node.packageCategory.name
                : '',
            price:
              !sessionPackage.node.customPriceAndDuration &&
              `$${sessionPackage.node.price}`,
            duration:
              !sessionPackage.node.customPriceAndDuration &&
              `${sessionPackage.node.durationLowMinutes} - ${sessionPackage.node.durationHighMinutes} Minutes`,
          }
        }
      )
      setSessionPackages(currentSessionPackages)
    }
  }, [queryData])
  const fetchMore = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  const executeSearchQuery = useCallback(
    (searchValue) => {
      setCursor(null)
      setLoadingSearch(true)
      const variables = {
        searchTerm: searchValue,
      }
      if (orderBy) {
        variables.orderBy = orderBy
      }
      query({ variables })
    },
    [orderBy, query]
  )

  const handleSearchTermChange = useCallback(
    (event) => {
      const currentSearchTerm = event.target.value
      setSearchTerm(currentSearchTerm)

      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      const newTimeout = setTimeout(() => {
        executeSearchQuery(currentSearchTerm)
      }, 1000) // 1 second delay

      setDebounceTimeout(newTimeout)
    },
    [executeSearchQuery, debounceTimeout]
  )

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const toggleShowAddSessionPackageModal = () => {
    setShowAddSessionPackageModal(!showAddSessionPackageModal)
  }
  const toggleShowEditSessionPackageModal = () => {
    setShowEditSessionPackageModal(!showEditSessionPackageModal)
  }
  const onCellClick = (cell) => {
    setEditSessionPackageId(cell.row.original.id)
    toggleShowEditSessionPackageModal()
  }
  const toggleCategoriesModal = () => {
    setShowCategoriesModal((prevState) => !prevState)
  }
  const actions = canMutate
    ? [
        {
          text: 'New Session Package',
          icon: <PlusCircle />,
          onClick: toggleShowAddSessionPackageModal,
        },
        {
          text: 'Session Package Categories',
          icon: <PlusCircle />,
          onClick: toggleCategoriesModal,
        },
      ]
    : []

  if (canMutate && checkedPackageIds.length > 0) {
    actions.push({
      icon: <Trash />,
      text:
        checkedPackageIds.length === 1
          ? 'Delete Session Package'
          : 'Delete Session Packages',
      onClick: () => setShowDeleteForm(true),
    })
  }
  if (!initialQueryRun && !queryData)
    return (
      <Row>
        <Col>
          <Loading />
        </Col>
      </Row>
    )
  if (queryError) return <>Error loading session packages</>
  return (
    <>
      <Page title="Session Packages" actions={actions} actionsNewRow>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                size="sm"
                type="text"
                name="searchTerm"
                placeholder={'Search Session Packages'}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <SortableInfiniteTable
              tableData={sessionPackages}
              tableColumns={tableColumns}
              loading={loadingSearch}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={hasMoreTableData}
              loadingMessage="Loading Session Packages..."
              onTdClicks={{
                title: (cell) => onCellClick(cell),
                price: (cell) => onCellClick(cell),
                duration: (cell) => onCellClick(cell),
                category: (cell) => onCellClick(cell),
                customPriceAndDuration: (cell) => onCellClick(cell),
              }}
              tdStyle={{ minWidth: '75px' }}
              tableHeight={800}
              rowPointer
              hideGlobalFilter
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
      </Page>
      <CategoriesModal
        showModal={showCategoriesModal}
        toggleModal={toggleCategoriesModal}
      />
      <NewSessionPackageModal
        showModal={showAddSessionPackageModal}
        toggleModal={toggleShowAddSessionPackageModal}
        searchTerm={searchTerm}
      />
      <EditSessionPackageModal
        showModal={showEditSessionPackageModal}
        toggleModal={toggleShowEditSessionPackageModal}
        sessionPackageId={editSessionPackageId}
        searchTerm={searchTerm}
      />
      {showDeleteForm && (
        <DeleteSessionPackageModal
          sessionPackageIds={
            checkedPackageIds.length > 1 ? checkedPackageIds : null
          }
          sessionPackageId={
            checkedPackageIds.length === 1 ? checkedPackageIds[0] : null
          }
          setCheckedPackageIds={setCheckedPackageIds}
          showModal={showDeleteForm}
          toggleModal={setShowDeleteForm}
        />
      )}
    </>
  )
}
export default SessionPackages
