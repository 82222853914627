import React from 'react'
import { Trash, Clock, PlusCircle } from 'react-bootstrap-icons'
import { Field, ErrorMessage, FieldArray } from 'formik'
import { Form, Col, Row, Button } from 'react-bootstrap'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'

export default function Buffer(props) {
  const { formik, displayBuffers, setDisplayBuffers } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )

  return (
    <>
      {formik.values.buffers.length > 0 && (
        <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
          <Form.Group as={Col} md={8}>
            <h5>Buffers</h5>
            {formik.errors.buffers && (
              <p className="text-danger mt-2">{formik.errors.buffers}</p>
            )}
          </Form.Group>
        </Form.Row>
      )}
      <FieldArray
        name="buffers"
        render={(arrayHelpers) => (
          <Row>
            <Col>
              <Row>
                <Col md="4">
                  <div
                    style={
                      formik.values.buffers.length > 1
                        ? {
                            overflowY: 'scroll',
                            maxHeight: '350px',
                            overflowX: 'hidden',
                          }
                        : null
                    }
                    className={'px-4'}
                  >
                    {displayBuffers && (
                      <div>
                        {formik.values.buffers.map((buffer, index) => (
                          <div key={index}>
                            <Row>
                              <Col
                                className={
                                  formik.values.buffers.length > 1 ? 'px-0' : ''
                                }
                              >
                                <div className="border border-secondary rounded my-3 p-3 bg-white">
                                  <Form.Row>
                                    <Form.Group as={Col} md={6}>
                                      <Form.Label>Start Time</Form.Label>
                                      <Field
                                        disabled={!canMutate}
                                        name={`buffers[${index}].startDateTime`}
                                        className="form-control form-control-sm"
                                      />
                                      <ErrorMessage
                                        name={`buffers[${index}].startDateTime`}
                                      >
                                        {(msg) => (
                                          <span className="text-danger">
                                            {msg}
                                          </span>
                                        )}
                                      </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group as={Col} md={6}>
                                      <Form.Label>End Time</Form.Label>
                                      <Field
                                        disabled={!canMutate}
                                        name={`buffers[${index}].endDateTime`}
                                        className="form-control form-control-sm"
                                      />
                                      <ErrorMessage
                                        name={`buffers[${index}].endDateTime`}
                                      >
                                        {(msg) => (
                                          <span className="text-danger">
                                            {msg}
                                          </span>
                                        )}
                                      </ErrorMessage>
                                    </Form.Group>
                                  </Form.Row>

                                  {canMutate && (
                                    <div
                                      className="d-flex align-items-center hover btn-link"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Trash className="mr-2" />
                                      <small>Remove</small>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              {canMutate && (
                <Button
                  variant="link"
                  className={
                    formik.values.buffers.length > 1
                      ? 'p-0 btn-link mt-2'
                      : 'p-0 btn-link'
                  }
                  onClick={() => {
                    if (!displayBuffers) {
                      setDisplayBuffers(true)
                    }
                    arrayHelpers.push({
                      startDateTime: '',
                      endDateTime: '',
                    })
                  }}
                >
                  <PlusCircle className="mr-2" />
                  Add Buffer
                </Button>
              )}
              <Row>
                <Col md={6}>
                  {formik.errors.buffers ===
                  'Buffers must be between job start and end time and on either the 0th or 5th minute' ? (
                    <p className="text-danger mt-2">{formik.errors.buffers}</p>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      />
    </>
  )
}
