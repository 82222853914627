import { DateTime } from 'luxon'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from './apollo'

export const useDateTimeConverter = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const userTimezone = loggedInUser?.defaultRegion?.formattedTimezone

  const toTimezone = (
    input,
    {
      format = null,
      onlyDate = false,
      onlyDateSort = false,
      onlyTime = false,
      standard = false,
      humanReadable = false,
      humanReadableSort = false,
      humanReadableDate = false,
      calendarDate = false,
      longCalendarDate = false,
      isoDateOnly = false,
      timezone = null,
      timestamp = false,
      settingTimezone = false,
      noTimezone = false,
      returnDateType = false,
      setTime = null,
      beginningOfDay = false,
      endOfDay = false,
    } = {}
  ) => {
    let targetTimezone = timezone || userTimezone
    if (settingTimezone) {
      targetTimezone = settings?.timezone
    }

    let dateTime
    const sourceZone = 'UTC'
    if (input instanceof Date) {
      dateTime = DateTime.fromJSDate(input).setZone(sourceZone, {
        keepLocalTime: true,
      })
    } else if (input instanceof DateTime) {
      dateTime = input.setZone(sourceZone, { keepLocalTime: true })
    } else if (timestamp) {
      dateTime = DateTime.fromSeconds(input, { zone: sourceZone })
    } else if (noTimezone) {
      dateTime = DateTime.fromISO(input)
    } else {
      dateTime = DateTime.fromISO(input, { zone: sourceZone })
    }
    if (beginningOfDay) {
      dateTime = dateTime.startOf('day')
    } else if (endOfDay) {
      dateTime = dateTime.endOf('day')
    }
    if (setTime) {
      dateTime = dateTime.set(setTime)
    }
    let convertedDateTime
    if (!noTimezone) {
      convertedDateTime = dateTime.setZone(targetTimezone)
    } else {
      convertedDateTime = dateTime
    }

    if (returnDateType) {
      return convertedDateTime
    }
    return formatDateTime(convertedDateTime, {
      onlyDate,
      onlyDateSort,
      onlyTime,
      standard,
      humanReadable,
      humanReadableSort,
      humanReadableDate,
      calendarDate,
      longCalendarDate,
      isoDateOnly,
      format,
    })
  }

  const formatDateTime = (
    dateTime,
    {
      onlyDate,
      onlyDateSort,
      onlyTime,
      standard,
      humanReadable,
      humanReadableSort,
      humanReadableDate,
      calendarDate,
      longCalendarDate,
      isoDateOnly,
      format,
    }
  ) => {
    if (onlyDate) {
      return dateTime.toFormat('MMMM d, yyyy')
    }
    if (onlyDateSort) {
      return dateTime.toFormat('MMM d yyyy')
    }
    if (onlyTime) {
      return dateTime.toFormat('h:mma')
    }
    if (standard) {
      return dateTime.toFormat('MMMM d, yyyy h:mma')
    }
    if (humanReadable) {
      return dateTime.toFormat('EEEE, MMMM d, yyyy h:mma')
    }
    if (humanReadableSort) {
      return dateTime.toFormat('ccc LLL d yyyy h:mma')
    }
    if (humanReadableDate) {
      return dateTime.toFormat('EEEE, MMMM d, yyyy')
    }
    if (calendarDate) {
      return dateTime.toFormat('EEE, MMM d')
    }
    if (longCalendarDate) {
      return dateTime.toFormat('EEEE, MMMM d')
    }
    if (isoDateOnly) {
      return new Date(dateTime.toFormat('MM/dd/yyyy'))
    }
    if (format) {
      return dateTime.toFormat(format)
    }
    return dateTime
  }

  const toUTC = (
    input,
    {
      timezone = null,
      isoFormat = false,
      startOfDay = false,
      endOfDay = false,
      settingTimezone = false,
      userTimezone = false,
    } = {}
  ) => {
    let sourceTimezone = timezone || userTimezone
    if (settingTimezone) {
      sourceTimezone = settings?.timezone
    }

    let dateTime
    if (input instanceof Date) {
      const datetimeString = input.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      })
      dateTime = DateTime.fromFormat(
        datetimeString,
        'MM/dd/yyyy, HH:mm:ss'
      ).setZone(sourceTimezone, { keepLocalTime: true })
    } else if (input instanceof DateTime) {
      dateTime = input.setZone(sourceTimezone, { keepLocalTime: true })
    } else {
      dateTime = DateTime.fromISO(input, { zone: sourceTimezone })
    }

    if (startOfDay) {
      dateTime = dateTime.startOf('day')
    }

    if (endOfDay) {
      dateTime = dateTime.endOf('day')
    }

    let utcDateTime = dateTime.toUTC()
    if (isoFormat) {
      utcDateTime = utcDateTime.toISO()
    }

    return utcDateTime
  }

  const combineDateAndTime = (
    dateInput,
    timeInput,
    { utc = false, ...rest } = {}
  ) => {
    let sourceTimezone = rest.timezone || userTimezone
    if (rest.settingTimezone) {
      sourceTimezone = settings?.timezone
    }

    let date
    if (dateInput instanceof Date) {
      const dateString = dateInput.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      date = DateTime.fromFormat(dateString, 'MM/dd/yyyy').setZone(
        sourceTimezone,
        { keepLocalTime: true }
      )
    } else if (dateInput instanceof DateTime) {
      date = dateInput.setZone(sourceTimezone)
    } else {
      date = DateTime.fromISO(dateInput, { zone: sourceTimezone })
    }

    let time
    if (timeInput instanceof DateTime) {
      time = timeInput
    } else {
      time = DateTime.fromFormat(`${timeInput}`, 'h:ma')
    }
    if (!time.isValid || !date.isValid) {
      return
    }

    const dateTime = DateTime.fromObject(
      {
        year: date.year,
        month: date.month,
        day: date.day,
        hour: time.hour,
        minute: time.minute,
      },
      { zone: sourceTimezone }
    )

    if (utc) {
      return toUTC(dateTime, { ...rest })
    }

    return dateTime
  }

  return {
    toTimezone,
    toUTC,
    combineDateAndTime,
  }
}
