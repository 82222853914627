import React, { useEffect, useState } from 'react'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import DeleteTimesheetModal from './DeleteTimesheetModal'
import { Modal, Form, Col, Row, Button } from 'react-bootstrap'
import { Formik, Field, FieldArray, ErrorMessage } from 'formik'
import EmployeeSearchInput from '../../common/node_search_input/EmployeeSearchInput'
import EmployeeJobSearchInput from '../../common/node_search_input/EmployeeJobSearchInput'
import EmployeeScheduleSearchInput from '../../common/node_search_input/EmployeeScheduleSearchInput'
import TimeInput from '../../common/TimeInput'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import Loading from '../../common/Loading'
import { format } from 'date-fns'
import { Clock, PlusCircle, Trash } from 'react-bootstrap-icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDateTimeConverter } from '../../../libs/useDateTime'
import { formatTimezone } from '../../../libs/utils'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'
import moment from 'moment'

export default function TimesheetModal(props) {
  const { showTimesheetModal, updatingTimesheet, timesheet, toggleModal } =
    props
  const { toTimezone, combineDateAndTime } = useDateTimeConverter()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const [submitting, setIsSubmitting] = useState(false)
  const [display, setDisplay] = useState(true)
  const [timezone, setTimezone] = useState(
    updatingTimesheet
      ? formatTimezone(timesheet?.region?.timezone)
      : loggedInUser?.defaultRegion?.formattedTimezone
  )
  const dropDownOptions = [
    {
      key: 'Employee',
      value: 'employee',
    },
    {
      key: 'Employee Job Assignment',
      value: 'employeeJob',
    },
    {
      key: 'Employee Work Event',
      value: 'employeeSchedule',
    },
  ]

  const [createTimesheet, { error: timesheetError }] = useMutation(
    gql`
      mutation CreateTimesheetMutation($input: CreateTimesheetInput!) {
        createTimesheet(input: $input) {
          timesheet {
            id
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setIsSubmitting(false)
        if (!timesheetError) {
          toggleModal()
          toast.success('Timesheet Created')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['TimesheetsQuery'],
    }
  )

  const [updateTimesheet, { error: updateTimesheetError }] = useMutation(
    gql`
      mutation UpdateTimesheetMutation($input: UpdateTimesheetInput!) {
        updateTimesheet(input: $input) {
          timesheet {
            id
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setIsSubmitting(false)
        if (!updateTimesheetError) {
          toggleModal()
          toast.success('Timesheet Saved')
        }
      },
      onError(error) {
        setIsSubmitting(false)
        toast.error('An Error Occured')
      },
      refetchQueries: ['TimesheetsQuery'],
    }
  )

  const editJobDate = (dateString) => {
    if (dateString) {
      const lDate = toTimezone(dateString, { timezone: timezone })
      return new Date(lDate.c.year, lDate.c.month - 1, lDate.c.day, 0, 0, 0)
    }
  }

  const timeSheetInitialValues = updatingTimesheet
    ? {
        associate: timesheet?.employee?.id
          ? 'employee'
          : timesheet?.employeeJob?.id
            ? 'employeeJob'
            : timesheet?.employeeSchedule?.id
              ? 'employeeSchedule'
              : '',
        employeeId: timesheet?.employee?.id || '',
        employeeDescription: timesheet?.employee?.id
          ? timesheet?.employee.gaiaUser.fullName
          : '',
        employeeJobId: timesheet?.employeeJob?.id || '',
        employeeJobDescription: timesheet?.employeeJob?.id
          ? timesheet?.employeeJob.job.name
          : '',
        employeeScheduleId: timesheet?.employeeSchedule?.id || '',
        employeeScheduleDescription: timesheet?.employeeSchedule?.id
          ? timesheet?.employeeSchedule.employee.gaiaUser.fullName
          : '',
        date: timesheet.startDateTime
          ? editJobDate(timesheet.startDateTime)
          : '',
        startTime: timesheet.startDateTime
          ? toTimezone(timesheet.startDateTime, {
              onlyTime: true,
              timezone: timezone,
            })
          : '',
        endTime: timesheet.endDateTime
          ? toTimezone(timesheet.endDateTime, {
              onlyTime: true,
              timezone: timezone,
            })
          : '',
        timesheetEvents: timesheet?.timesheetEvents?.edges?.map((edge) => {
          const node = edge.node
          return {
            id: node.id,
            date: editJobDate(node.dateTime),
            time: toTimezone(node.dateTime, {
              timezone: timezone,
              onlyTime: true,
            }),
            eventType: node.eventType,
          }
        }),
        regionId: timesheet?.region?.id,
      }
    : {
        associate: 'employee',
        employeeId: '',
        employeeJobId: '',
        employeeScheduleId: '',
        date: '',
        startTime: '',
        endTime: '',
        timesheetEvents: [],
        regionId: loggedInUser?.defaultRegion?.id,
      }

  return (
    <>
      <Formik
        initialValues={timeSheetInitialValues}
        validationSchema={Yup.object().shape({
          associate: Yup.string().required('required'),
          employeeId: Yup.string().nullable(),
          employeeJobId: Yup.string().nullable(),
          employeeScheduleId: Yup.string().nullable(),
          date: Yup.string()
            .nullable()
            .when('associate', {
              is: (associate) => associate === 'employee',
              then: Yup.string().required('required'),
            }),
          startTime: Yup.string()
            .nullable()
            .when('associate', {
              is: (associate) => associate === 'employee',
              then: Yup.string()
                .matches(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                )
                .required('required')
                .test('modFive', 'minutes must be divisible by 5', (value) => {
                  let valid = false
                  if (value) {
                    try {
                      if (
                        value === 0 ||
                        parseInt(value.split(':')[1]) % 5 === 0
                      ) {
                        valid = true
                      }
                    } catch {
                      //
                    }
                  }
                  return valid
                })
                .required('required'),
            }),
          endTime: Yup.string()
            .nullable()
            .when('associate', {
              is: (associate) => associate === 'employee',
              then: Yup.string()
                .matches(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                )
                .required('required')
                .test('modFive', 'minutes must be divisible by 5', (value) => {
                  let valid = false
                  if (value) {
                    try {
                      if (
                        value === 0 ||
                        parseInt(value.split(':')[1]) % 5 === 0
                      ) {
                        valid = true
                      }
                    } catch {
                      //
                    }
                  }
                  return valid
                })
                .required('required'),
            }),
          timesheetEvents: Yup.array().of(
            Yup.object().shape({
              date: Yup.string().nullable(),
              time: Yup.string()
                .matches(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                )
                .required('required')
                .test('modFive', 'minutes must be divisible by 5', (value) => {
                  let valid = false
                  if (value) {
                    try {
                      if (
                        value === 0 ||
                        parseInt(value.split(':')[1]) % 5 === 0
                      ) {
                        valid = true
                      }
                    } catch {
                      //
                    }
                  }
                  return valid
                })
                .required('required'),
              eventType: Yup.string().nullable(),
            })
          ),
          regionId: Yup.string().nullable(),
        })}
        onSubmit={async (values) => {
          setIsSubmitting(true)
          let startDateTime
          let endDateTime
          if (values.associate === 'employee') {
            startDateTime = combineDateAndTime(values.date, values.startTime, {
              utc: true,
              isoFormat: true,
              timezone: timezone,
            })
            endDateTime = combineDateAndTime(values.date, values.endTime, {
              utc: true,
              isoFormat: true,
              timezone: timezone,
            })
          }
          if (!updatingTimesheet) {
            const timesheetInput = {
              employeeId: values.employeeId,
              employeeJobId: values.employeeJobId,
              employeeScheduleId: values.employeeScheduleId,
              startDateTime,
              endDateTime,
              regionId: values.regionId,
              timesheetEvents: values.timesheetEvents
                ? values.timesheetEvents.map((timesheetEvent) => {
                    return {
                      clockIn:
                        timesheetEvent.eventType === 'CLOCK_IN' ? true : false,
                      dateTime: combineDateAndTime(
                        timesheetEvent.date,
                        timesheetEvent.time,
                        { utc: true, isoFormat: true, timezone: timezone }
                      ),
                    }
                  })
                : [],
            }
            createTimesheet({
              variables: {
                input: {
                  input: timesheetInput,
                },
              },
            })
          } else {
            let employeeId
            if (timesheet.employee?.id != values.employeeId) {
              employeeId = values.employeeId
            }

            let employeeJobId
            if (timesheet.employeeJob?.id != values.employeeJobId) {
              employeeJobId = values.employeeJobId
            }

            let employeeScheduleId
            if (timesheet.employeeSchedule?.id != values.employeeScheduleId) {
              employeeScheduleId = values.employeeScheduleId
            }
            let addTimesheetEvents = []
            let updateTimesheetEvents = []
            let deleteTimesheetEventIds = []
            timesheet.timesheetEvents?.edges?.map((edge) => {
              const node = edge.node
              const timesheetEvent = values.timesheetEvents.find(
                (timesheetEvent) =>
                  timesheetEvent && timesheetEvent?.id === node.id
              )
              if (timesheetEvent) {
                const update = {}
                const dateTime = combineDateAndTime(
                  timesheetEvent.date,
                  timesheetEvent.time,
                  { utc: true, isoFormat: true, timezone: timezone }
                )

                if (
                  editJobDate(node.dateTime).toString() !==
                    timesheetEvent.date.toString() ||
                  timesheetEvent.time !==
                    toTimezone(node.dateTime, {
                      timezone: timezone,
                      onlyTime: true,
                    })
                ) {
                  update.dateTime = dateTime
                }

                if (timesheetEvent.eventType !== node.eventType) {
                  update.clockIn =
                    timesheetEvent.eventType === 'CLOCK_IN' ? true : false
                }

                if (Object.keys(update).length > 0) {
                  updateTimesheetEvents.push({
                    ...update,
                    id: node.id,
                  })
                }
              } else {
                deleteTimesheetEventIds.push(node.id)
              }
            })
            values.timesheetEvents?.map((timesheetEvent) => {
              if (!timesheetEvent.id) {
                addTimesheetEvents.push({
                  clockIn:
                    timesheetEvent.eventType === 'CLOCK_IN' ? true : false,
                  dateTime: combineDateAndTime(
                    timesheetEvent.date,
                    timesheetEvent.time,
                    { utc: true, isoFormat: true, timezone: timezone }
                  ),
                })
              }
            })
            const timesheetInput = {
              id: timesheet.id,
              employeeId,
              employeeJobId,
              employeeScheduleId,
              startDateTime,
              endDateTime,
              addTimesheetEvents,
              updateTimesheetEvents,
              deleteTimesheetEventIds,
              regionId: values.regionId,
            }
            updateTimesheet({
              variables: {
                input: {
                  input: timesheetInput,
                },
              },
            })
          }
        }}
      >
        {(formik) => (
          <Modal
            show={showTimesheetModal}
            onHide={() => {
              toggleModal()
              setIsSubmitting(false)
              formik.resetForm()
            }}
            animation={false}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <Clock className="mr-2" />
                {updatingTimesheet ? (
                  <span>Edit Timesheet</span>
                ) : (
                  <span>New Timesheet</span>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} md={4} className="mb-0 pb-0">
                    <Field
                      as="select"
                      name={'associate'}
                      className="form-control-sm form-select"
                      onChange={(e) => {
                        formik.setFormikState((prevState) => ({
                          ...prevState,
                          values: {
                            ...prevState.values,
                            associate: e.target.value,
                            employeeId: '',
                            employeeDescription: '',
                            employeeJobId: '',
                            employeeJobDescription: '',
                            employeeScheduleId: '',
                            employeeScheduleDescription: '',
                          },
                        }))
                      }}
                    >
                      {dropDownOptions.map(({ key, value }) => (
                        <option key={value} value={value}>
                          {key}
                        </option>
                      ))}
                    </Field>
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-2 pb-2">
                    {formik.values.associate === 'employee' && (
                      <EmployeeSearchInput
                        formik={formik}
                        error={formik.errors.employeeId}
                      />
                    )}
                    {formik.values.associate === 'employeeJob' && (
                      <EmployeeJobSearchInput
                        formik={formik}
                        error={formik.errors.employeeJobId}
                      />
                    )}
                    {formik.values.associate === 'employeeSchedule' && (
                      <EmployeeScheduleSearchInput
                        formik={formik}
                        error={formik.errors.employeeScheduleId}
                      />
                    )}
                  </Form.Group>
                </Form.Row>
                {formik.values.associate === 'employee' && (
                  <Form.Row>
                    <Form.Group as={Col} md={3}>
                      <Form.Label className="d-block">Date</Form.Label>
                      <DatePicker
                        name="date"
                        className="form-control form-control-sm"
                        showPopperArrow={false}
                        popperPlacement="bottom-start"
                        selected={formik.values.date}
                        onChange={(date) => {
                          formik.setFieldValue('date', date)
                        }}
                        popperModifiers={{
                          flip: {
                            behavior: ['bottom'],
                          },
                          preventOverflow: {
                            enabled: true,
                          },
                          hide: {
                            enabled: true,
                          },
                        }}
                      />
                      <ErrorMessage name="date">
                        {(msg) => (
                          <span className="text-danger d-block">{msg}</span>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>Start Time</Form.Label>
                      <TimeInput
                        formik={formik}
                        fieldName="startTime"
                        placeholder="9:00am"
                        lt={formik.values.endTime}
                      />
                      <ErrorMessage name="startTime">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>End Time</Form.Label>
                      <TimeInput
                        formik={formik}
                        fieldName="endTime"
                        placeholder="5:00pm"
                        gt={formik.values.startTime}
                      />
                      <ErrorMessage name="endTime">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                )}
                {loggedInUser?.canManageRegions && settings?.tenantRegions && (
                  <Form.Row>
                    <Form.Group as={Col} md={3}>
                      <Form.Label className="d-block">Region</Form.Label>
                      <RegionSearchInput
                        dropdown
                        formik={formik}
                        error={formik.errors.regionId}
                        setAdditionalFields={(node, _) => {
                          if (node?.timezone) {
                            setTimezone(formatTimezone(node.timezone))
                          }
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                )}
                {formik.values.timesheetEvents.length > 0 && (
                  <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
                    <Form.Group as={Col} md={8}>
                      <h5>Clock In / Out</h5>
                    </Form.Group>
                  </Form.Row>
                )}
                <FieldArray
                  name="timesheetEvents"
                  render={(arrayHelpers) => (
                    <div>
                      {display && (
                        <Row>
                          <Col md="6">
                            <div
                              style={
                                formik.values.timesheetEvents.length > 1
                                  ? {
                                      overflowY: 'scroll',
                                      maxHeight: '350px',
                                      overflowX: 'hidden',
                                    }
                                  : null
                              }
                              className={
                                formik.values.timesheetEvents.length > 1
                                  ? 'border p-3 mt-2'
                                  : ''
                              }
                            >
                              {formik.values.timesheetEvents &&
                                formik.values.timesheetEvents.map(
                                  (timesheetEvent, timesheetEventIndex) => {
                                    return (
                                      <Row key={timesheetEventIndex}>
                                        <Col md={12}>
                                          <div className="border border-secondary border-rounded p-3 rounded mb-3">
                                            <Form.Row>
                                              <Form.Group as={Col} md={5}>
                                                <Form.Label>Time</Form.Label>
                                                <TimeInput
                                                  formik={formik}
                                                  fieldName={`timesheetEvents[${timesheetEventIndex}].time`}
                                                  gt={
                                                    formik.values.startTime
                                                      ? formik.values.startTime
                                                      : undefined
                                                  }
                                                  lt={
                                                    formik.values.endTime
                                                      ? formik.values.endTime
                                                      : undefined
                                                  }
                                                />
                                                <ErrorMessage
                                                  name={`timesheetEvents[${timesheetEventIndex}].time`}
                                                >
                                                  {(msg) => (
                                                    <span className="text-danger">
                                                      {msg}
                                                    </span>
                                                  )}
                                                </ErrorMessage>
                                              </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                              <Form.Group
                                                as={Col}
                                                md={4}
                                                className="mb-2 pb-2"
                                              >
                                                <Field
                                                  as="select"
                                                  name={`timesheetEvents[${timesheetEventIndex}].eventType`}
                                                  className="form-control-sm form-select"
                                                >
                                                  <option value="">----</option>
                                                  <option value="CLOCK_IN">
                                                    Clock In
                                                  </option>
                                                  <option value="CLOCK_OUT">
                                                    Clock Out
                                                  </option>
                                                </Field>
                                              </Form.Group>
                                            </Form.Row>
                                            {canMutate && (
                                              <div
                                                className="d-flex align-items-center btn-link hover"
                                                onClick={() =>
                                                  arrayHelpers.remove(
                                                    timesheetEventIndex
                                                  )
                                                }
                                                role="presentation"
                                                onKeyDown={() =>
                                                  arrayHelpers.remove(
                                                    timesheetEventIndex
                                                  )
                                                }
                                              >
                                                <Trash className="mr-1" />
                                                <small>Remove</small>
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                )}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {canMutate && (
                        <Button
                          type="button"
                          className={
                            formik.values.timesheetEvents.length > 1
                              ? 'mt-2 p-0 btn-link'
                              : 'mt-1 p-0 btn-link'
                          }
                          onClick={() => {
                            if (!display) {
                              setDisplay(true)
                            }
                            const now = new Date()
                            const minutes = Math.floor(now.getMinutes() / 5) * 5
                            now.setMinutes(minutes)
                            now.setSeconds(0)
                            now.setMilliseconds(0)
                            let formattedTime = format(
                              now,
                              'h:mma'
                            ).toLowerCase()

                            if (
                              formik.values.startTime &&
                              formik.values.endTime
                            ) {
                              const startTime = moment(
                                formik.values.startTime,
                                'h:mma'
                              )
                              const endTime = moment(
                                formik.values.endTime,
                                'h:mma'
                              )
                              const currentTime = moment(formattedTime, 'h:mma')

                              if (
                                currentTime.isBefore(startTime) ||
                                currentTime.isAfter(endTime)
                              ) {
                                formattedTime = formik.values.startTime
                              }
                            }

                            arrayHelpers.push({
                              date: formik.values.date,
                              time: formattedTime,
                              eventType: '',
                            })
                          }}
                        >
                          <PlusCircle className="mr-2" />
                          Add Clock In / Out
                        </Button>
                      )}
                    </div>
                  )}
                />
                {canMutate && (
                  <Form.Row className="mt-5">
                    <Col md={3}>
                      <Button
                        type="submit"
                        block
                        variant="outline-primary"
                        disabled={submitting}
                      >
                        <PlusCircle className="mr-2" />
                        Save
                      </Button>
                    </Col>
                    {/* {updatingTimesheet && (
                      <>
                        <Col md={3}>
                          <Button
                            block
                            variant="outline-danger"
                            onClick={() => setShowDeleteForm(true)}
                            disabled={submitting}
                          >
                            <Trash className="mr-2" />
                            Delete
                          </Button>
                        </Col>
                      </>
                    )} */}
                  </Form.Row>
                )}
                {submitting && <Loading message="Saving Location..." />}
              </Form>
            </Modal.Body>
          </Modal>
        )}
      </Formik>

      {updatingTimesheet && showDeleteForm && (
        <DeleteTimesheetModal
          timesheetId={timesheet.id}
          showModal={showDeleteForm}
          toggleModal={setShowDeleteForm}
          timesheetDeleted={() => {
            toggleModal()
            setIsSubmitting(false)
          }}
        />
      )}
    </>
  )
}
