import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Globe, PlusCircle, Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { formatTimezone } from '../../../libs/utils'
import { TIMEZONES } from '../../../libs/constant'

const RegionModal = (props) => {
  const { showModal, region, toggleModal } = props
  const [submitting, setSubmitting] = useState(false)

  const [deleteRegion] = useMutation(
    gql`
      mutation DeleteRegion($deleteRegionInput: DeleteRegionInput!) {
        deleteRegion(input: $deleteRegionInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success('Region Deleted')
        toggleModal()
      },
      refetchQueries: ['Regions'],
      errorPolicy: 'all',
    }
  )

  const [createRegion] = useMutation(
    gql`
      mutation CreateRegion($input: CreateRegionInput!) {
        createRegion(input: $input) {
          region {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Region Saved`)
        toggleModal()
      },
      refetchQueries: ['Regions'],
    }
  )

  const [updateRegion] = useMutation(
    gql`
      mutation UpdateRegion($input: UpdateRegionInput!) {
        updateRegion(input: $input) {
          region {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Region Saved`)
        toggleModal()
      },
      refetchQueries: ['Regions'],
    }
  )

  const handleDeleteClick = () => {
    setSubmitting(true)
    deleteRegion({
      variables: {
        deleteRegionInput: {
          regionIds: [region.id],
        },
      },
    })
  }

  const formik = useFormik({
    initialValues: region
      ? {
          name: region.name,
          timezone: formatTimezone(region.timezone),
          default: region.default,
        }
      : {
          name: '',
          timezone: '',
          default: false,
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('required'),
      timezone: Yup.string().required('required'),
      default: Yup.boolean().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (region) {
        updateRegion({
          variables: {
            input: {
              regionInput: {
                id: region.id,
                name: values.name,
                timezone: values.timezone,
                default: values.default,
              },
            },
          },
        })
      } else {
        createRegion({
          variables: {
            input: {
              regionInput: {
                name: values.name,
                timezone: values.timezone,
                default: values.default,
              },
            },
          },
        })
      }
    },
  })

  if (!showModal) return <></>
  return (
    <div className="addEditRegionsModal">
      <Modal size={'md'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Globe className="mr-2" />
            {region ? <>Edit Region</> : <>New Region</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Name
                </Form.Label>
              </Col>
              <Col sm="12" md={7}>
                <Form.Control
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Timezone
                </Form.Label>
              </Col>
              <Col sm="12" md={7}>
                <select
                  className={`form-control form-select ${formik.errors.timezone ? 'is-invalid' : ''}`}
                  name="timezone"
                  value={formik.values.timezone}
                  onChange={formik.handleChange}
                >
                  <option key="" value="">
                    ---
                  </option>
                  {TIMEZONES?.map((timezone, index) => (
                    <option key={index} value={timezone}>
                      {timezone}
                    </option>
                  ))}
                </select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.timezone}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Default
                </Form.Label>
              </Col>
              <Col sm="12" md={7}>
                <Form.Check
                  className="ml-1 mt-1"
                  type="checkbox"
                  checked={formik.values.default}
                  onChange={(e) => {
                    formik.setFieldValue('default', e.target.checked)
                  }}
                  isInvalid={formik.errors.default}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.default}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Row className="mt-2">
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  type="submit"
                  variant="outline-primary"
                  block
                  disabled={submitting}
                >
                  <PlusCircle className="mr-2" />
                  Save
                </Button>
              </Col>
            </Form.Row>

            {region && !region.default && (
              <Form.Row className="mt-2">
                <Col md={{ span: 8, offset: 2 }}>
                  <Button
                    variant="outline-danger"
                    onClick={handleDeleteClick}
                    block
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              </Form.Row>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default RegionModal
