import React, { useState, useEffect } from 'react'
import { Cloud, QuestionCircle } from 'react-bootstrap-icons'
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Table,
} from 'react-bootstrap'
import { DNA } from 'react-loader-spinner'
import validator from 'validator'
import { gql, useMutation, useLazyQuery, useReactiveVar } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './book_view_home/Homepage.css'
import { client } from '../libs/apollo'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import config from '../config'
import { useInterval } from '../libs/utils'
import { loggedInUserVar } from '../libs/apollo'
import EnterpriseSubscriptionModal from './settings/EnterpriseSubscriptionModal'

const SignUp = () => {
  const stripe = useStripe()
  const stripeElements = useElements()
  const [showEnterpriseSubscriptionModal, setShowEnterpriseSubscriptionModal] =
    useState(false)
  const [createTenantId, setCreateTenantId] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState('Basic')
  const [stripeCardElementErrors, setStripeCardElementErrors] = useState()
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0)

  const getTdStyle = (subscription, columnIndex) => {
    if (
      (subscription === 'Basic' && columnIndex === 1) ||
      (subscription === 'Essential' && columnIndex === 2) ||
      (subscription === 'Premium' && columnIndex === 3) ||
      (subscription === 'Enterprise' && columnIndex === 4)
    ) {
      return { backgroundColor: '#f0f0f0' }
    }
    return {}
  }

  useEffect(() => {
    let intervalId
    if (createTenantId) {
      intervalId = setInterval(() => {
        setLoadingMessageIndex(
          (prevIndex) => (prevIndex + 1) % loadingMessages.length
        )
      }, 15000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [createTenantId])

  const [getCreateTenant] = useLazyQuery(
    gql`
      query CreateTenant($id: ID!) {
        createTenant(id: $id) {
          creating
          tenantDomain
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        if (!data.createTenant.creating) {
          setLoading(false)
          let tenantDomain = data.createTenant.tenantDomain
          if (config.ENVIRONMENT === 'development') {
            tenantDomain = `http://${tenantDomain}:3000/?created`
          } else if (['qa', 'prod'].includes(config.ENVIRONMENT)) {
            tenantDomain = `https://${tenantDomain}/?created`
          }
          window.location.href = tenantDomain
        }
      },
    }
  )

  useInterval(() => {
    if (createTenantId) {
      getCreateTenant({
        variables: {
          id: createTenantId,
        },
      })
    }
  }, 1000 * 5)

  const [create] = useMutation(
    gql`
      mutation CreateTenant($createTenantInput: CreateTenantInput!) {
        createTenant(input: $createTenantInput) {
          createTenant {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setCreateTenantId(data.createTenant.createTenant.id)
      },
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: {
      tenantName: '',
      tenantDomain: '',
      adminUserEmail: '',
      adminUserFirstName: '',
      adminUserLastName: '',
      adminUserPassword: '',
      adminUserConfirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      tenantName: Yup.string().required('Required'),
      adminUserEmail: Yup.string()
        .required('Required')
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      adminUserFirstName: Yup.string().required('Required'),
      adminUserLastName: Yup.string().required('Required'),
      adminUserPassword: Yup.string().required('Required'),
      stripePaymentMethod: Yup.string().nullable(),
      adminUserConfirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('adminUserPassword')], 'Passwords do not match'),
      tenantDomain: Yup.string()
        .required('Required')
        .matches(/^[a-zA-Z0-9]+$/, 'Only characters are allowed')
        .test('isUnique', 'URL prefix already in use', async (value) => {
          let tenantDomain
          if (config.ENVIRONMENT === 'development') {
            tenantDomain = `${value}.localhost`
          } else if (config.ENVIRONMENT === 'srp') {
            tenantDomain = `${value}.airstudio.io`
          } else if (config.ENVIRONMENT === 'qa') {
            tenantDomain = `${value}.sbx.airstudio.io`
          } else {
            return false
          }
          const { data } = await client.query({
            query: gql`
              query TenantDomainAvailable($tenantDomain: String!) {
                tenantDomainAvailable(tenantDomain: $tenantDomain) {
                  available
                }
              }
            `,
            fetchPolicy: 'no-cache',
            variables: {
              tenantDomain: tenantDomain,
            },
          })
          return data.tenantDomainAvailable.available
        }),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      if (selectedSubscription === 'Enterprise') {
        setShowEnterpriseSubscriptionModal(true)
      } else {
        setLoading(true)
        const cardElement = stripeElements.getElement(CardElement)
        const stripeResponse = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        if (stripeResponse.error) {
          setStripeCardElementErrors(stripeResponse.error.message)
        } else {
          create({
            variables: {
              createTenantInput: {
                tenantInput: {
                  tenantName: values.tenantName,
                  tenantDomain: values.tenantDomain,
                  adminUserEmail: values.adminUserEmail,
                  adminUserPassword: values.adminUserPassword,
                  adminUserFirstName: values.adminUserFirstName,
                  adminUserLastName: values.adminUserLastName,
                  stripePaymentMethodId: stripeResponse.paymentMethod.id,
                },
              },
            },
          })
        }
      }
    },
  })

  const loadingMessages = [
    `Hang tight... Constructing your digital workspace may take around five minutes. We'll email ${formik?.values?.adminUserEmail} when its ready. 🚀`,
    'Crafting your workspace with a sprinkle of magic and a dash of tech! ✨🔧',
    "Brewing a custom concoction for your workspace... It's almost potion time! 🧙‍♂️🔮",
    "Knitting together the fabric of your digital realm. It's cozy in here! 🧶🖼️",
    "Summoning the internet gremlins to tailor your environment. They're friendly, we promise! 🐾",
    'Assembling the pixels and bytes into your new digital playground. 🎲🖥️',
    'Rolling out the red carpet in your new digital domain. All eyes on you! 🌟👑',
    'Chiseling away at the digital marble to sculpt your perfect workspace. 🗿🎨',
    'Cultivating a fertile digital landscape for your ideas to grow. 🌱🌳',
    'Weaving the web threads for a seamless digital tapestry. Your masterpiece awaits! 🕸️🎭',
    'Laying the digital bricks to build the fortress of your productivity. Strong and steady! 🧱🏰',
    "Setting the stage for your digital debut. The spotlight's on you! 💡🎤",
    'Tuning the strings of the digital harp for a symphony of efficiency. Music to your ears! 🎵🎶',
    'Igniting the forge to hammer out the details of your custom workspace. Sparks are flying! 🔨🔥',
  ]

  const subscriptionTableData = [
    [
      'Monthly Subscription / Annual Subscription',
      '$180 / $1,900',
      '$300 / $3,200',
      '$550 / $5,900',
      'Variable',
    ],
    ['Users', '1-5', '1-10', '1-30', '1-30+'],
    ['Employee Management & Scheduling', 'x', 'x', 'x', 'x'],
    ['Photo Day Management & Organization', 'x', 'x', 'x', 'x'],
    ['Photo Day Management', 'x', 'x', 'x', 'x'],
    ['Customer Service Management', 'x', 'x', 'x', 'x'],
    ['Client History / Audit Log', '-', 'x', 'x', 'x'],
    ['CRM Management', 'x', 'x', 'x', 'x'],
    ['Online Scheduling', 'x', 'x', 'x', 'x'],
    ['Subject Group Management', 'x', 'x', 'x', 'x'],
    ['Session Packages (add $3ea)', '1-10', '1-30', 'Unlimited', 'Unlimited'],
    ['Advanced Schedule Management Client Portal', '-', 'x', 'x', 'x'],
    ['Invoicing & Financial Reporting', 'x', 'x', 'x', 'x'],
    ['Payment Storage - No Show Protection', 'x', 'x', 'x', 'x'],
    ['Data Security', 'x', 'x', 'x', 'x'],
    [
      'Email & SMS Notifications (add .02 per)',
      '2,000',
      '10,000',
      '50,000',
      '50,000+',
    ],
    ['Recurring Automated Notifications', '-', 'x', 'x', 'x'],
    ['Targeted Marketing', 'x', 'x', 'x', 'x'],
    ['Coupons', 'x', 'x', 'x', 'x'],
    ['Equipment Management', '-', 'x', 'x', 'x'],
    ['File Storage (add $5 per 100GB)', '1 GB', '10 GB', '500 GB', '500 GB+'],
    ['Workflow & Task Management', '-', 'x', 'x', 'x'],
    ['Integrations - Chat GPT', 'x', 'x', 'x', 'x'],
    ['Integrations - Intercom', 'x', 'x', 'x', 'x'],
    ['Integrations - Twilio', 'x', 'x', 'x', 'x'],
    ['Integrations - Stripe', 'x', 'x', 'x', 'x'],
    ['Integrations - Blueprint', 'x', 'x', 'x', 'x'],
    ['Integrations - Google Calendar', '-', 'x', 'x', 'x'],
  ]

  return (
    <>
      {showEnterpriseSubscriptionModal && (
        <EnterpriseSubscriptionModal
          showModal={showEnterpriseSubscriptionModal}
          setShowModal={setShowEnterpriseSubscriptionModal}
        />
      )}
      {createTenantId && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              textAlign: 'center',
            }}
          >
            <DNA
              visible={true}
              height="300"
              width="300"
              ariaLabel="dna-loading"
            />
            <div
              style={{
                marginTop: '20px',
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#000',
              }}
            >
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                  {loadingMessages[loadingMessageIndex]}
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      {!createTenantId && (
        <Form onSubmit={formik.handleSubmit}>
          <Row className="mt-5">
            <Col md={{ span: 8, offset: 2 }}>
              <h1 className="homepage-header">Airstudio Sign Up</h1>
              <Row className="mt-5">
                <Col md={{ span: 8, offset: 2 }}>
                  <p style={{ fontSize: '20px' }}>
                    Sign up today and enjoy a 7-day free trial period!
                  </p>
                </Col>
              </Row>
              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label sm="3">Studio Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="tenantName"
                    value={formik.values.tenantName}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.tenantName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.tenantName}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label sm="4">Email</Form.Label>

                  <Form.Control
                    type="text"
                    name="adminUserEmail"
                    value={formik.values.adminUserEmail}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserEmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserEmail}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label sm="3">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="adminUserFirstName"
                    value={formik.values.adminUserFirstName}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserFirstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserFirstName}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label sm="3">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="adminUserLastName"
                    value={formik.values.adminUserLastName}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserLastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserLastName}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label sm="3">Password</Form.Label>

                  <Form.Control
                    type="password"
                    name="adminUserPassword"
                    value={formik.values.adminUserPassword}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserPassword}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label sm="3">Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="adminUserConfirmPassword"
                    value={formik.values.adminUserConfirmPassword}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminUserConfirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminUserConfirmPassword}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label sm="3">
                    URL Prefix
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <p>
                            The URL prefix will be prepended to your Airstudio
                            account URL. For example, if you choose studio, your
                            account URL will be studio.airstudio.io.
                          </p>
                        </Tooltip>
                      }
                    >
                      <QuestionCircle
                        className="ml-1"
                        style={{ fontSize: '14px' }}
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="tenantDomain"
                    value={formik.values.tenantDomain}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.tenantDomain}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.tenantDomain}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6} style={{ marginTop: '40px' }}>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '18px',
                        },
                      },
                    }}
                  />
                  {stripeCardElementErrors && (
                    <Row className="mt-3">
                      <Col xs={12} md={{ span: 8 }}>
                        <p
                          style={{
                            color: '#dc3545',
                          }}
                        >
                          {stripeCardElementErrors}
                        </p>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Form.Group>
              <Row className="mt-4">
                <Col>
                  <h4>Basic Plan</h4>
                  <p>
                    The Basic Subscription is ideal for smaller businesses with
                    a limited number of users and a focused range of services.
                    This affordable solution fits seamlessly into your current
                    operations, and scaling up is easy when your business grows.
                    It is perfectly suited for those who specialize in portrait
                    and wedding photography, along with managing a few school
                    accounts.
                  </p>
                  <h4>Essential Plan</h4>
                  <p>
                    Our Essential Subscription provides full access to all
                    features, equipping you with the tools to effectively manage
                    every aspect of your operations. It is best suited for
                    businesses serving 30-50 organizations and offering a
                    diverse range of services. With the Advanced Schedule
                    Management Portal, you empower clients to manage their
                    bookings, while the Workflow/Task feature helps you stay on
                    top of key service components. The Client History Audit
                    tracks every interaction with your clients, ensuring smooth
                    communication and personalized service. Additionally, you
                    can manage and track inventory and supplies issued to staff,
                    safeguarding your valuable equipment. With increased file
                    storage, user notifications, and robust support, your
                    business is fully covered.
                  </p>
                  <h4>Premium Plan</h4>
                  <p>
                    The Premium Plan offers everything you need for large-scale
                    operations. With unlimited service types (session packages),
                    your offerings to clients are limitless. Peak-level
                    notifications, file storage, and support provide you with
                    the highest level of functionality with the least amount of
                    distractions. Ideal for businesses serving 70-100
                    organizations with a high number of users, this subscription
                    ensures you are equipped for growth. Our flexible feature
                    add-ons allow for seamless scaling, and if you require a
                    custom subscription, we are re here to assist with an easy
                    transition.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="text-center mb-4">Select Subscription</h2>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Features</th>
                        <th>
                          <span
                            className={
                              selectedSubscription !== 'Basic' ? 'btn-link' : ''
                            }
                            onClick={() => setSelectedSubscription('Basic')}
                          >
                            Basic
                          </span>
                        </th>
                        <th>
                          <span
                            className={
                              selectedSubscription !== 'Essential'
                                ? 'btn-link'
                                : ''
                            }
                            onClick={() => setSelectedSubscription('Essential')}
                          >
                            Essential
                          </span>
                        </th>
                        <th>
                          <span
                            className={
                              selectedSubscription !== 'Premium'
                                ? 'btn-link'
                                : ''
                            }
                            onClick={() => setSelectedSubscription('Premium')}
                          >
                            Premium
                          </span>
                        </th>
                        <th>
                          <span
                            className={
                              selectedSubscription !== 'Enterprise'
                                ? 'btn-link'
                                : ''
                            }
                            onClick={() =>
                              setSelectedSubscription('Enterprise')
                            }
                          >
                            Enterprise
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptionTableData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, colIndex) => (
                            <td
                              key={colIndex}
                              style={getTdStyle(selectedSubscription, colIndex)}
                            >
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 8, offset: 2 }}>
                  <Button
                    type="submit"
                    block
                    disabled={loading}
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <Cloud className="mr-2" />
                    Sign Up
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}

export default SignUp
