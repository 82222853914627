import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import SortableTable from '../../common/SortableTable'
import { ExclamationTriangle } from 'react-bootstrap-icons'
import { useDateTimeConverter } from '../../../libs/useDateTime'

const EmployeeSchedulingConflictsModal = (props) => {
  let { showModal, toggleModal, schedulingConflicts, timezone } = props
  const { toTimezone } = useDateTimeConverter()
  if (!showModal) return <></>
  const hasEmployeeWorkingEvent = schedulingConflicts.some(
    (item) => item.eventType === 'EMPLOYEE_WORKING'
  )
  schedulingConflicts = schedulingConflicts.filter(
    (item) => item.eventType !== 'EMPLOYEE_WORKING'
  )
  return (
    <>
      <div id="employeeSchedulingConflictModal">
        <Modal className="mt-4" size="lg" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Employee Scheduling Conflicts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!hasEmployeeWorkingEvent && (
              <Row className="mb-3">
                <Col>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    <ExclamationTriangle className="ml-1 mr-2" />
                    <small>No Working Work Events During Job Time</small>
                  </span>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <SortableTable
                  columns={[
                    {
                      Header: 'Conflict',
                      accessor: (row) => {
                        let conflict
                        if (row.__typename === 'EmployeeScheduleNode') {
                          conflict = 'Work Event'
                        } else {
                          conflict = 'Job'
                        }
                        return conflict
                      },
                    },
                    {
                      Header: 'Job Time',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeJobNode') {
                          const startDateTime = toTimezone(
                            row.job.startDateTime,
                            { standard: true, timezone: timezone }
                          )
                          const endDateTime = toTimezone(row.job.endDateTime, {
                            onlyTime: true,
                            timezone: timezone,
                          })
                          return `${startDateTime} - ${endDateTime}`
                        }
                      },
                    },
                    {
                      Header: 'Shift',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeJobNode') {
                          let startDateTime
                          if (row.startDateTime) {
                            startDateTime = toTimezone(row.startDateTime, {
                              onlyTime: true,
                              timezone: timezone,
                            })
                          }
                          let endDateTime
                          if (row.endDateTime) {
                            endDateTime = toTimezone(row.endDateTime, {
                              onlyTime: true,
                              timezone: timezone,
                            })
                          }
                          if (startDateTime || endDateTime) {
                            let time
                            if (startDateTime) {
                              time = startDateTime
                            } else {
                              time = 'no start time'
                            }
                            if (endDateTime) {
                              time = `${time} - ${endDateTime}`
                            } else {
                              time = `${time} - no end time`
                            }
                            return time
                          } else {
                            return 'no start or end time'
                          }
                        }
                      },
                    },
                    {
                      Header: 'Work Event Time',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeScheduleNode') {
                          const startDateTime = toTimezone(row.startDateTime, {
                            standard: true,
                            timezone: timezone,
                          })
                          let endTime
                          if (row.endDateTime) {
                            endTime = toTimezone(row.endDateTime, {
                              onlyTime: true,
                              timezone: timezone,
                            })
                          }
                          return `${startDateTime} - ${endTime}`
                        }
                      },
                    },
                    {
                      Header: 'Work Event Type',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeScheduleNode') {
                          let eventType
                          if (row.eventType === 'EMPLOYEE_PTO') {
                            eventType = 'PTO'
                          } else if (row.eventType === 'EMPLOYEE_SICK') {
                            eventType = 'Sick'
                          }
                          return eventType
                        }
                      },
                    },
                    {
                      Header: 'Work Event Status',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeScheduleNode') {
                          let status
                          if (row.approvalStatus === 'PENDING') {
                            status = 'Pending'
                          } else if (row.approvalStatus === 'APPROVED') {
                            status = 'Approved'
                          }
                          let color
                          if (status === 'Pending') {
                            color = 'orange'
                          } else if (status === 'Approved') {
                            color = 'red'
                          }
                          return <span style={{ color }}>{status}</span>
                        }
                      },
                    },
                    {
                      Header: 'Location',
                      accessor: (row) => {
                        let location
                        if (row.__typename === 'EmployeeScheduleNode') {
                          if (row.location?.name) {
                            location = row.location?.name
                          } else if (row.location?.addressLineOne) {
                            location = row.location?.addressLineOne
                          }
                        } else {
                          if (row.job.location.name) {
                            location = row.job.location.name
                          } else if (row.job.location.addressLineOne) {
                            location = row.job.location.addressLineOne
                          }
                        }
                        return location
                      },
                    },
                  ]}
                  hideGlobalFilter={true}
                  data={schedulingConflicts}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default EmployeeSchedulingConflictsModal
