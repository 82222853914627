import React, { useState, useRef, useEffect } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import moment from 'moment'

const TimeInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  margin-top: 4px;
`

const TimeOption = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: #495057;
  &:hover {
    background-color: #f5f5f5;
  }
`

const getNestedValue = (obj, path) => {
  if (!obj || !path) return ''
  return (
    path
      .split(/[.[\]]/)
      .filter(Boolean)
      .reduce((acc, part) => acc?.[part], obj) || ''
  )
}

const TimeInput = ({ formik, fieldName, placeholder, gt, lt }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])
  const [isFocused, setIsFocused] = useState(false)
  const [isUserEditing, setIsUserEditing] = useState(false)
  const wrapperRef = useRef(null)
  const dropdownRef = useRef(null)

  const resolvedValue = getNestedValue(formik.values, fieldName) || ''

  const generateTimeOptions = () => {
    const options = []
    const date = new Date()
    date.setHours(0, 0, 0, 0)

    const startMoment = gt ? moment(gt, 'h:mma').clone() : moment(date)
    const endMoment = lt
      ? moment(lt, 'h:mma').clone()
      : moment(date).endOf('day')

    if (lt && !gt) {
      endMoment.subtract(5, 'minutes')
    } else if (gt && !lt) {
      startMoment.add(5, 'minutes')
    }

    let currentTime = startMoment.clone()
    while (currentTime.isSameOrBefore(endMoment)) {
      options.push(currentTime.format('h:mma').toLowerCase())
      currentTime.add(5, 'minutes')
    }

    return options
  }

  useEffect(() => {
    setFilteredOptions(generateTimeOptions())
  }, [gt, lt])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false)
        setIsFocused(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const options = Array.from(dropdownRef.current.children)
      const currentTime = new Date()
      const minutes = Math.floor(currentTime.getMinutes() / 5) * 5
      currentTime.setMinutes(minutes)
      currentTime.setSeconds(0)
      currentTime.setMilliseconds(0)
      const selectedTime = format(currentTime, 'h:mma').toLowerCase()

      for (const option of options) {
        if (option.textContent === selectedTime) {
          const optionHeight = option.offsetHeight
          const dropdownHeight = dropdownRef.current.offsetHeight
          const scrollPosition =
            option.offsetTop - dropdownHeight / 2 + optionHeight / 2
          dropdownRef.current.scrollTop = Math.max(0, scrollPosition)
          break
        }
      }
    }
  }, [isOpen, resolvedValue])

  useEffect(() => {
    if (!isUserEditing) {
      setInputValue(resolvedValue)
    }
  }, [resolvedValue, isUserEditing])

  const handleTimeSelect = (time) => {
    formik.setFieldValue(fieldName, time)
    setInputValue(time)
    setIsOpen(false)
    setIsFocused(false)
    setIsUserEditing(false)
  }

  const handleInputClick = () => {
    setIsOpen(true)
    setIsFocused(true)
    if (!resolvedValue && !inputValue) {
      setInputValue('')
    }
  }

  const handleInputFocus = () => {
    setIsFocused(true)
    if (!resolvedValue && !inputValue) {
      setInputValue('')
    }
  }

  const handleInputBlur = () => {
    if (!inputValue && !resolvedValue) {
      setIsFocused(false)
    }
    setIsUserEditing(false)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setInputValue(value)
    setIsUserEditing(true)

    const allOptions = generateTimeOptions()
    const filtered = allOptions.filter((time) =>
      time.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredOptions(filtered)

    if (!isOpen) {
      setIsOpen(true)
    }

    if (allOptions.includes(value.toLowerCase())) {
      formik.setFieldValue(fieldName, value.toLowerCase())
    } else if (value === '') {
      formik.setFieldValue(fieldName, '')
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      if (
        filteredOptions.length > 0 &&
        !filteredOptions.includes(inputValue.toLowerCase())
      ) {
        handleTimeSelect(filteredOptions[0])
      } else {
        setIsOpen(false)
      }
      setIsUserEditing(false)
    }

    if (e.key === 'Escape') {
      setIsOpen(false)
    }

    if (
      (e.key === 'Backspace' || e.key === 'Delete') &&
      inputValue.length <= 1
    ) {
      setIsUserEditing(true)
    }
  }

  const displayValue = () => {
    if (isUserEditing || inputValue) return inputValue
    if (resolvedValue) return resolvedValue
    if (isFocused) return ''
    return placeholder || 'Select Time'
  }

  const isPlaceholder = !resolvedValue && !inputValue && !isFocused

  return (
    <TimeInputWrapper ref={wrapperRef}>
      <Form.Control
        type="text"
        value={displayValue()}
        onClick={handleInputClick}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
        className="form-control-sm bg-white"
        style={{
          color: isPlaceholder ? '#6c757d' : 'inherit',
          fontWeight: isPlaceholder ? '300' : 'normal',
        }}
      />
      <Dropdown ref={dropdownRef} isOpen={isOpen}>
        {filteredOptions.map((time, index) => (
          <TimeOption key={index} onClick={() => handleTimeSelect(time)}>
            {time}
          </TimeOption>
        ))}
        {filteredOptions.length === 0 && (
          <TimeOption>No matching times</TimeOption>
        )}
      </Dropdown>
    </TimeInputWrapper>
  )
}

TimeInput.propTypes = {
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  gt: PropTypes.string,
  lt: PropTypes.string,
}

export default TimeInput
