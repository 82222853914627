import React, { useEffect, useState } from 'react'
import { gql, useReactiveVar, useLazyQuery } from '@apollo/client'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { PlusCircle, ArrowUpRightCircle } from 'react-bootstrap-icons'
import SubjectGroupSubjectMetadata from './SubjectGroupSubjectMetadata'
import SubjectGroupSubjectImaqeQuixId from './SubjectGroupSubjectImaqeQuixId'
import SubjectGroupSubjectCustomFields from './SubjectGroupSubjectCustomFields'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useAWSS3 } from '../../libs/aws'
import { useImagequixEnabled } from '../../libs/imagequix'
import { useParams } from 'react-router-dom'
import Loading from '../common/Loading'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { formatRegion, formatTimezone } from '../../libs/utils'
import { useFormik } from 'formik'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'

const SubjectSubjectGroupsTable = (props) => {
  const { subjectId } = props
  const { contact } = useParams()
  const { toTimezone } = useDateTimeConverter()
  let hasMoreSubjectGroups = true
  let subjectGroupsCursor
  const awsS3 = useAWSS3()
  const iqEnabled = useImagequixEnabled()
  const [showMetadataModal, setShowMetadataModal] = useState(false)
  const [showIqIdModal, setShowIqIdModal] = useState(false)
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false)
  const [sgsId, setSgsId] = useState()
  const [sgsMetadata, setSgsMetadata] = useState()
  const [iqId, setIqId] = useState()
  const [sgsNode, setSgsNode] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const history = useHistory()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canUpdate = [
    'Scheduling Analyst',
    'Administrator',
    'Scheduling Manager',
  ].includes(loggedInUser?.permissions?.group)
  const subject = ['Subject'].includes(loggedInUser?.permissions?.group)

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Bookable Between',
      accessor: 'bookableBetween',
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'status',
    },
  ]
  if (loggedInUser?.canManageRegions && settings?.tenantRegions) {
    tableColumns.push({
      Header: 'Region',
      id: 'region',
      accessor: 'region',
    })
  }
  if (!contact && !subject) {
    tableColumns.push({
      id: 'metadata',
      Header: 'Custom Fields',
      accessor: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="link"
              onClick={() => {
                setSgsId(row.sgsId)
                setSgsMetadata(row.sgsMetadata)
                toggleMetadataModal()
              }}
            >
              <PlusCircle size={12} className="mr-1" />{' '}
              <span style={{ fontSize: '14px' }}>Manage</span>
            </Button>
          </div>
        )
      },
    })
  }
  if (iqEnabled) {
    tableColumns.splice(3, 0, {
      Header: (
        <>
          <span className="ml-2">Gallery</span>
        </>
      ),
      id: 'imagequixId',
      accessor: (row) => {
        return (
          <>
            {canUpdate && (
              <Button
                variant="link"
                onClick={() => {
                  setSgsId(row.sgsId)
                  setIqId(row?.imagequixSubject?.imagequixId)
                  toggleIqIdModal()
                }}
              >
                <PlusCircle size={12} className="mr-1" />{' '}
                <span style={{ fontSize: '14px' }}>Manage</span>
              </Button>
            )}
            {row?.imagequixSubject?.imagequixId && (
              <>
                <Button
                  variant="link"
                  onClick={() => {
                    window.open(
                      `https://vando.imagequix.com/s${row.imagequixSubject.imagequixId}`,
                      '_blank'
                    )
                  }}
                >
                  <ArrowUpRightCircle size={12} className="mr-1" />
                  <span style={{ fontSize: '14px' }}>Gallery</span>
                </Button>
              </>
            )}
          </>
        )
      },
    })
  }
  const [
    query,
    {
      error: subjectGroupsQueryError,
      data: subjectGroupsQueryData,
      fetchMore: subjectGroupsQueryFetchMore,
    },
  ] = useLazyQuery(
    gql`
      query SubjectGroupsQuery(
        $subjectId: ID
        $cursor: String
        $regions: String
      ) {
        subjectGroupSubjects(
          subject: $subjectId
          first: 20
          after: $cursor
          orderBy: "-subject_group__end_date_time"
          regions: $regions
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              imagequixSubject {
                imagequixId
                idQrCodeFile {
                  fileName
                  displayName
                }
                galleryQrCodeFile {
                  fileName
                  displayName
                }
              }
              metadata
              customOne
              customTwo
              customThree
              status
              customFour
              customFive
              subjectGroup {
                name
                id
                startDateTime
                endDateTime
                subjectGroupRegions(default: true) {
                  edges {
                    node {
                      id
                      default
                      region {
                        id
                        name
                        timezone
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        subjectId,
      },
    }
  )
  const toggleMetadataModal = () => {
    if (showMetadataModal) {
      setSgsId(null)
      setSgsMetadata(null)
    }
    setShowMetadataModal((prevState) => !prevState)
  }
  const toggleIqIdModal = () => {
    if (showIqIdModal) {
      setSgsId(null)
      setIqId(null)
    }
    setShowIqIdModal((prevState) => !prevState)
  }

  const toggleCustomFieldModal = (sgs = null) => {
    setSgsNode(sgs)
    setShowCustomFieldModal((prevState) => !prevState)
  }

  const handleSubjectGroupsQueryData = () => {
    if (!subjectGroupsQueryData) return
    hasMoreSubjectGroups =
      subjectGroupsQueryData.subjectGroupSubjects.pageInfo.hasNextPage
    if (subjectGroupsQueryData.subjectGroupSubjects.pageInfo.endCursor) {
      subjectGroupsCursor =
        subjectGroupsQueryData.subjectGroupSubjects.pageInfo.endCursor
    }
    const subjectGroupTableRows = []
    subjectGroupsQueryData.subjectGroupSubjects.edges.forEach(
      (subjectGroup) => {
        let region
        let timezone
        if (
          subjectGroup.node.subjectGroup?.subjectGroupRegions?.edges?.length > 0
        ) {
          region =
            subjectGroup.node.subjectGroup?.subjectGroupRegions.edges[0].node
              ?.region
          timezone = formatTimezone(region?.timezone)
        }
        const startZone = toTimezone(
          subjectGroup.node.subjectGroup.startDateTime,
          { onlyDate: true, timezone: timezone }
        )
        const endZone = toTimezone(subjectGroup.node.subjectGroup.endDateTime, {
          onlyDate: true,
          timezone: timezone,
        })
        subjectGroupTableRows.push({
          node: subjectGroup.node,
          id: subjectGroup.node.subjectGroup.id,
          imagequixSubject: subjectGroup.node?.imagequixSubject,
          sgsId: subjectGroup.node.id,
          sgsMetadata: subjectGroup.node.metadata,
          name: subjectGroup.node.subjectGroup.name,
          status: subjectGroup.node.status,
          bookableBetween: `${startZone} - ${endZone}`,
          region: formatRegion(region),
        })
      }
    )
    return subjectGroupTableRows
  }
  const formik = useFormik({
    initialValues: {
      regions: loggedInUser?.defaultRegions,
    },
  })

  useEffect(() => {
    formik.setFieldValue('regions', loggedInUser?.defaultRegions)
  }, [loggedInUser?.defaultRegions])

  const constructQueryVariables = () => {
    const variables = { subjectId: subjectId }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (formik.values.regions.length > 0) {
      variables.regions = formik.values.regions
        .map((region) => region.id)
        .join(',')
    }

    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = constructQueryVariables()
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    const variables = constructQueryVariables()
    query({ variables })
  }, [formik.values.regions])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = constructQueryVariables()
    variables.searchTerm = currentSearchTerm
    query({ variables })
  }

  const fetchMoreSubjectGroups = () => {
    const variables = constructQueryVariables()
    variables.cursor = subjectGroupsCursor
    subjectGroupsQueryFetchMore({ variables })
  }
  const onTdClick = (cell) => {
    if (!subject) {
      if (loggedInUser.permissions.isOrgContact) {
        history.push(`/subject-group/${cell.row.original.id}/contact`)
      } else {
        history.push(`/subject-group/${cell.row.original.id}`)
      }
    }
  }
  const onTdClicks = {
    name: onTdClick,
    bookableBetween: onTdClick,
    status: onTdClick,
    region: onTdClick,
  }
  if (!awsS3?.client)
    return (
      <Row>
        <Col>
          <Loading />
        </Col>
      </Row>
    )
  if (subjectGroupsQueryError) return <>Error loading subject groups</>
  const subjectGroups = handleSubjectGroupsQueryData()
  return (
    <>
      <div>
        <Row className="mb-3">
          <Col md={4} className="mt-2">
            <Form.Group>
              <Form.Control
                size="sm"
                type="text"
                name="searchTerm"
                placeholder="Search Records"
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          {loggedInUser?.canManageRegions && settings?.tenantRegions && (
            <Col className="mt-2" md={2}>
              <RegionSearchInput
                formik={formik}
                dropdown
                multiple
                mustHaveValue={
                  loggedInUser.permissions.group === 'Administrator'
                    ? false
                    : true
                }
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12}>
            <div id="subjectGroupsCollapse">
              {subjectGroups && (
                <SortableInfiniteTable
                  tableData={subjectGroups}
                  hideGlobalFilter
                  loading={!subjectGroupsQueryData}
                  loadingMessage="Loading Subject Groups..."
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMoreSubjectGroups}
                  hasMoreTableData={hasMoreSubjectGroups}
                  onTdClicks={onTdClicks}
                  tableHeight={500}
                  rowPointer={!subject}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <SubjectGroupSubjectMetadata
        sgsId={sgsId}
        sgsMetadata={sgsMetadata}
        showModal={showMetadataModal}
        refetch={'SubjectGroupsQuery'}
        toggleModal={toggleMetadataModal}
      />
      <SubjectGroupSubjectImaqeQuixId
        sgsId={sgsId}
        imagequixId={iqId}
        showModal={showIqIdModal}
        refetch={'SubjectGroupsQuery'}
        toggleModal={toggleIqIdModal}
      />
      <SubjectGroupSubjectCustomFields
        sgs={sgsNode}
        showModal={showCustomFieldModal}
        refetch={'SubjectGroupsQuery'}
        toggleModal={toggleCustomFieldModal}
      />
    </>
  )
}

export default SubjectSubjectGroupsTable
