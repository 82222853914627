import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EquipmentBagSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    multiple,
    dropdown,
    fetchPolicy,
    variables,
    query,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="bag"
      searchDescription={searchDescription ? searchDescription : 'Bags'}
      nodeNamePlural="equipmentBags"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      multiple={multiple}
      dropdown={dropdown}
      fetchPolicy={fetchPolicy}
      variables={variables}
      gql={
        query
          ? query
          : gql`
              query SearchBags(
                $first: Int
                $after: String
                $search: String
                $employeeIsNull: Boolean
                $excludeAssignedEmployeeJob: String
              ) {
                equipmentBags(
                  first: $first
                  after: $after
                  orderBy: "name"
                  name_Icontains: $search
                  employee_Isnull: $employeeIsNull
                  excludeAssignedEmployeeJob: $excludeAssignedEmployeeJob
                ) {
                  nodeCount
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            `
      }
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default EquipmentBagSearchInput
