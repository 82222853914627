import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useLazyQuery, gql, useReactiveVar, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import SubjectGroupRemoveSubjectModal from './SubjectGroupRemoveSubjectModal'
import SubjectGroupAddSubjectsModal from './SubjectGroupAddSubjectsModal'
import SubjectGroupSubjectMetadata from '../subject/SubjectGroupSubjectMetadata'
import SubjectGroupSubjectImaqeQuixId from '../subject/SubjectGroupSubjectImaqeQuixId'
import SubjectGroupSubjectCustomFields from '../subject/SubjectGroupSubjectCustomFields'
import NewSubjectModal from '../subject/NewSubjectModal'
import {
  Trash,
  Key,
  CloudArrowDown,
  ArrowUpRightCircle,
  PlusCircle,
} from 'react-bootstrap-icons'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useAWSS3 } from '../../libs/aws'
import toast from 'react-hot-toast'
import { useImagequixEnabled } from '../../libs/imagequix'
import Loading from '../common/Loading'
import {
  useFotomerchantEnabled,
  fotomerchantSubjectAdminUrl,
  useFotomerchantSubjectGalleryUrl,
} from '../../libs/fotomerchant'
import { useParams } from 'react-router-dom'
import FotomerchantGalleryPasswordModal from '../fotomerchant/FotomerchantGalleryPasswordModal'
import { useDownloadFile } from '../../libs/downloadFile'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import { useFormik } from 'formik'
import { formatRegion } from '../../libs/utils'

const SubjectGroupSubjectsTable = (props) => {
  const {
    subjectGroupId,
    subjectGroupName,
    organizationId,
    organizationName,
    organization,
    setQueriesLoading,
    queriesLoading,
    subjectGroupCategory,
  } = props

  const history = useHistory()
  const awsS3 = useAWSS3()
  const { contact } = useParams()
  const iqEnabled = useImagequixEnabled()
  const fotomerchantEnabled = useFotomerchantEnabled()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { downloadAndDeleteFile, downloadFile } = useDownloadFile()
  const [showSubjectModal, setShowSubjectModal] = useState(false)
  const fotomerchantSubejctGalleryUrl = useFotomerchantSubjectGalleryUrl()
  const [fotomerchantGalleryPassword, setFotomerchantGalleryPassword] =
    useState()
  const canUpdate = [
    'Scheduling Analyst',
    'Administrator',
    'Scheduling Manager',
  ].includes(loggedInUser?.permissions?.group)
  const defaultOrderBy = 'subject__gaia_user__full_name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [sorting, setSorting] = useState(false)
  const [searching, setSearching] = useState(false)
  const [showRemoveSubjectModal, setShowRemoveSubjectModal] = useState(false)
  const [removeSubjectId, setRemoveSubjectId] = useState()
  const [removeSubjectName, setRemoveSubjectName] = useState()
  const [showAddSubjectsModal, setShowAddSubjectsModal] = useState(false)
  const [showIqIdModal, setShowIqIdModal] = useState(false)
  const [iqId, setIqId] = useState()
  const [showMetadataModal, setShowMetadataModal] = useState(false)
  const [sgsId, setSgsId] = useState()
  const [sgsMetadata, setSgsMetadata] = useState()
  const [sgsNode, setSgsNode] = useState()
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [regionFilter, setRegionFilter] = useState(false)
  const [subjects, setSubjects] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [cursor, setCursor] = useState()
  const [hasMoreSubjects, setHasMoreSubjects] = useState(false)

  const toggleMetadataModal = () => {
    if (showMetadataModal) {
      setSgsId(null)
      setSgsMetadata(null)
    }
    setShowMetadataModal((prevState) => !prevState)
  }
  const toggleIqIdModal = () => {
    if (showMetadataModal) {
      setSgsId(null)
      setIqId(null)
    }
    setShowIqIdModal((prevState) => !prevState)
  }
  const toggleCustomFieldModal = (sgs = null) => {
    setSgsNode(sgs)
    setShowCustomFieldModal((prevState) => !prevState)
  }

  const [downloadSubjects] = useMutation(
    gql`
      mutation DownloadSubjectGroupSubjects(
        $input: DownloadSubjectGroupSubjectsInput!
      ) {
        downloadSubjectGroupSubjects(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadSubjectGroupSubjects.file.fileName,
          data.downloadSubjectGroupSubjects.file.displayName,
          data.downloadSubjectGroupSubjects.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const [
    query,
    {
      error: subjectsQueryError,
      data: subjectsQueryData,
      fetchMore: subjectsQueryFetchMore,
    },
  ] = useLazyQuery(
    gql`
      query SubjectsQuery(
        $cursor: String
        $subjectGroup: ID
        $searchTerm: String
        $orderBy: String
        $regionIds: String
      ) {
        subjectGroupSubjects(
          first: 20
          after: $cursor
          subjectGroup: $subjectGroup
          orderBy: $orderBy
          search: $searchTerm
          regions: $regionIds
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              metadata
              imagequixSubject {
                imagequixId
                idQrCodeFile {
                  fileName
                  displayName
                }
                galleryQrCodeFile {
                  fileName
                  displayName
                }
              }
              fotomerchantSubject {
                fotomerchantId
                fotomerchantPassword
                fotomerchantClientSession {
                  fotomerchantId
                  fotomerchantClient {
                    fotomerchantId
                  }
                }
              }
              customOne
              customTwo
              customThree
              customFour
              status
              customFive
              subject {
                id
                studentId
                gaiaUser {
                  email
                  phoneNumber
                  jobTitle
                  dummyUsername
                  firstName
                  lastName
                  gaiaUserRegions(default: true, first: 1) {
                    edges {
                      node {
                        region {
                          id
                          name
                          timezone
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        if (queriesLoading.subjects) {
          setQueriesLoading((prevState) => ({
            ...prevState,
            subjects: false,
          }))
        }
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      regions: loggedInUser?.defaultRegions,
    },
    validateOnChange: false,
  })

  useEffect(() => {
    formik.setFieldValue('regions', loggedInUser?.defaultRegions)
  }, [loggedInUser?.defaultRegions])

  const constructQueryVariables = () => {
    const variables = { subjectGroup: subjectGroupId }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (formik.values.regions.length > 0) {
      variables.regionIds = formik.values.regions
        .map((region) => region.id)
        .join(',')
    }

    return variables
  }
  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = constructQueryVariables()
      variables.orderBy = defaultOrderBy
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  const handleSearchTermChange = (event) => {
    setSearching(true)
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = constructQueryVariables()
    variables.searchTerm = currentSearchTerm
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    setSorting(true)
    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = constructQueryVariables()
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  useEffect(() => {
    const variables = constructQueryVariables()
    setRegionFilter(true)
    query({ variables })
  }, [formik.values.regions])

  const handleQueryData = (queryData) => {
    setHasMoreSubjects(queryData.subjectGroupSubjects.pageInfo.hasNextPage)
    if (queryData.subjectGroupSubjects.pageInfo.endCursor) {
      setCursor(queryData.subjectGroupSubjects.pageInfo.endCursor)
    }
    const currentSubjects = []
    let name
    queryData.subjectGroupSubjects.edges.forEach((subject) => {
      const subjectNode = subject.node.subject
      if (subjectNode?.gaiaUser.firstName) {
        name = subjectNode?.gaiaUser.firstName
      }
      if (subjectNode?.gaiaUser.lastName) {
        if (name) {
          name = `${name} ${subjectNode?.gaiaUser.lastName}`
        } else {
          name = subjectNode?.gaiaUser.lastName
        }
      }
      let email
      if (!subjectNode.gaiaUser.dummyUsername) {
        email = subjectNode.gaiaUser.email
      }
      currentSubjects.push({
        fotomerchantSubject: subject.node.fotomerchantSubject,
        name,
        phone: subjectNode?.gaiaUser.phoneNumber,
        jobTitle: subjectNode?.gaiaUser.jobTitle,
        studentId: subjectNode?.studentId,
        id: subjectNode?.id,
        status: subject.node.status,
        sgsId: subject.node.id,
        node: subject.node,
        imagequixId: subject.node.imagequixSubject?.imagequixId,
        sgsMetadata: subject.node.metadata,
        imagequixSubject: subject.node?.imagequixSubject,
        email,
      })
    })
    if (searching || sorting || regionFilter) {
      setSubjects(currentSubjects)
      setSearching(false)
      setSorting(false)
      setRegionFilter(false)
    } else {
      setSubjects((prevState) => {
        const currentIds = new Set(currentSubjects.map((item) => item.id))
        const filteredPrevState = prevState.filter(
          (item) => !currentIds.has(item.id)
        )
        return [...filteredPrevState, ...currentSubjects]
      })
    }
  }

  useEffect(() => {
    if (subjectsQueryData) {
      handleQueryData(subjectsQueryData)
    }
  }, [subjectsQueryData])

  const fetchMoreSubjects = () => {
    const variables = constructQueryVariables()
    variables.cursor = cursor
    subjectsQueryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        handleQueryData(fetchMoreResult)
        return fetchMoreResult
      },
    })
  }

  const toggleRemoveSubjectModal = () => {
    if (showRemoveSubjectModal) {
      setRemoveSubjectId(null)
    }
    setShowRemoveSubjectModal(!showRemoveSubjectModal)
  }
  const toggleAddSubjectsModal = () => {
    setShowAddSubjectsModal(!showAddSubjectsModal)
  }
  const onRemoveSubjectTdClick = (cell) => {
    setRemoveSubjectId(cell.row.original.id)
    setRemoveSubjectName(cell.row.original.name)
    toggleRemoveSubjectModal()
  }
  const onSubjectTdClick = (cell) => {
    if (contact) {
      history.push(`/subject/${cell.row.original.id}/contact`)
    } else {
      history.push(`/subject/${cell.row.original.id}`)
    }
  }
  const onMetadataClick = (cell) => {
    setSgsId(cell.row.original.sgsId)
    setSgsMetadata(cell.row.original.sgsMetadata)
    toggleMetadataModal()
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
      serverSort: true,
      orderBy: 'subject__gaia_user__full_name',
    },
    {
      Header: 'Email',
      id: 'email',
      serverSort: true,
      orderBy: 'subject__gaia_user__email',
      accessor: (row) => {
        const gaiaUser = row.node.subject.gaiaUser
        let email
        if (!gaiaUser.dummyUsername) {
          email = gaiaUser.email
          if (gaiaUser.secondaryEmail) {
            email = `${email}, ${gaiaUser.secondaryEmail}`
          }
        } else {
          email = ''
        }
        return <>{email}</>
      },
    },
    {
      Header: 'Phone Number',
      id: 'phone',
      accessor: 'phone',
      serverSort: true,
      orderBy: 'subject__gaia_user__phone_number',
    },
    {
      Header: 'ID Number',
      accessor: 'studentId',
      id: 'studentId',
      serverSort: true,
      orderBy: 'subject__student_id',
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'status',
      serverSort: true,
      orderBy: 'status',
    },
    {
      id: 'metadata',
      Header: 'Custom Fields',
      accessor: () => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="link">
              <PlusCircle size={12} className="mr-1" />{' '}
              <span style={{ fontSize: '14px' }}>Manage</span>
            </Button>
          </div>
        )
      },
    },
  ]
  if (loggedInUser?.canManageRegions && settings?.tenantRegions) {
    tableColumns.splice(5, 0, {
      Header: 'Region',
      id: 'region',
      serverSort: true,
      orderBy: 'subject__gaia_user__gaia_user_regions__region__name',
      accessor: (row) => {
        let region
        if (row.node.subject.gaiaUser.gaiaUserRegions.edges.length > 0) {
          region =
            row.node.subject.gaiaUser.gaiaUserRegions.edges[0].node.region
        }
        return formatRegion(region)
      },
    })
  }
  if (subjectGroupCategory === 'Other') {
    tableColumns.splice(2, 0, {
      Header: 'Job Title',
      id: 'title',
      accessor: 'jobTitle',
    })
  }
  if (canUpdate) {
    tableColumns.push({
      id: 'remove',
      Header: 'Remove',
      accessor: () => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="link">
              <Trash />
            </Button>
          </div>
        )
      },
    })
  }
  if (fotomerchantEnabled) {
    tableColumns.splice(4, 0, {
      Header: 'Fotomerchant',
      id: 'fotomerchant',
      accessor: (row) => {
        if (row.fotomerchantSubject) {
          const { fotomerchantSubject } = row
          const fotomerchantSubjectUrl = fotomerchantSubjectAdminUrl(
            fotomerchantSubject.fotomerchantClientSession.fotomerchantClient
              .fotomerchantId,
            fotomerchantSubject.fotomerchantClientSession.fotomerchantId,
            fotomerchantSubject.fotomerchantId
          )
          return (
            <>
              {!contact && (
                <>
                  <Button
                    variant="link"
                    onClick={() => {
                      window.open(fotomerchantSubjectUrl, '_blank')
                    }}
                  >
                    <img
                      className="mr-2"
                      src={window.location.origin + '/fotomerchant.svg'}
                      style={{ height: '20px' }}
                      alt="Organization logo"
                    />
                    <span style={{ fontSize: '14px' }}>Subject</span>
                  </Button>
                </>
              )}

              <Button
                variant="link"
                onClick={() => {
                  window.open(fotomerchantSubejctGalleryUrl, '_blank')
                }}
              >
                <img
                  className="mr-2"
                  src={window.location.origin + '/fotomerchant.svg'}
                  style={{ height: '20px' }}
                  alt="Organization logo"
                />
                <span style={{ fontSize: '14px' }}>Gallery</span>
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  setFotomerchantGalleryPassword(
                    fotomerchantSubject.fotomerchantPassword
                  )
                }}
              >
                <Key size={16} />
              </Button>
            </>
          )
        }
      },
    })
  }
  if (iqEnabled) {
    tableColumns.splice(5, 0, {
      Header: 'Gallery',
      id: 'imagequixId',
      accessor: (row) => {
        return (
          <>
            {canUpdate && (
              <Button
                variant="link"
                onClick={() => {
                  setSgsId(row.sgsId)
                  setIqId(row?.imagequixSubject?.imagequixId)
                  toggleIqIdModal()
                }}
              >
                <PlusCircle size={12} className="mr-1" />{' '}
                <span style={{ fontSize: '14px' }}>Manage</span>
              </Button>
            )}
            {row?.imagequixSubject && (
              <>
                <Button
                  variant="link"
                  onClick={() => {
                    window.open(
                      `https://vando.imagequix.com/s${row.imagequixId}`,
                      '_blank'
                    )
                  }}
                >
                  <ArrowUpRightCircle size={12} className="mr-1" />
                  <span style={{ fontSize: '14px' }}>Gallery</span>
                </Button>
              </>
            )}
          </>
        )
      },
    })
  }

  if (
    (!initialQueryRun && !subjectsQueryData) ||
    subjects === null ||
    !awsS3?.client
  )
    return (
      <Row>
        <Col>
          <Loading />
        </Col>
      </Row>
    )
  if (subjectsQueryError) return <>Error loading subjects</>
  return (
    <div id="subjectsCollapse">
      <Row className="mb-3">
        <Col md={4} className="mt-2">
          <Form.Group>
            <Form.Control
              size="sm"
              type="text"
              name="searchTerm"
              placeholder="Search Records"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        {loggedInUser?.canManageRegions && settings?.tenantRegions && (
          <Col className="mt-2" md={2}>
            <RegionSearchInput
              formik={formik}
              dropdown
              multiple
              mustHaveValue={
                loggedInUser.permissions.group === 'Administrator'
                  ? false
                  : true
              }
            />
          </Col>
        )}
        {([
          'Administrator',
          'Scheduling Manager',
          'Scheduling Analyst',
          'Organization Contact',
        ].includes(loggedInUser?.permissions?.group) ||
          loggedInUser.permissions.isOrgContact) && (
          <Col className="d-md-flex justify-content-md-end">
            <Button
              variant="link"
              onClick={() => {
                setShowSubjectModal(true)
              }}
            >
              <PlusCircle className="mr-2" />
              New Subject
            </Button>
            {!contact && (
              <>
                <Button
                  variant="link"
                  disabled={downloadingPdf || downloadingExcel}
                  onClick={() => {
                    setDownloadingPdf(true)
                    downloadSubjects({
                      variables: {
                        input: {
                          subjectGroupIds: [subjectGroupId],
                          fileType: 'pdf',
                        },
                      },
                    })
                  }}
                >
                  {downloadingPdf && <Loading height={'20'} />}
                  {!downloadingPdf && (
                    <>
                      <CloudArrowDown className="mr-2" />
                      Download PDF
                    </>
                  )}
                </Button>
                <Button
                  variant="link"
                  disabled={downloadingExcel || downloadingPdf}
                  onClick={() => {
                    setDownloadingExcel(true)
                    downloadSubjects({
                      variables: {
                        input: {
                          subjectGroupIds: [subjectGroupId],
                          fileType: 'xlsx',
                        },
                      },
                    })
                  }}
                >
                  {downloadingExcel && <Loading height={'20'} />}
                  {!downloadingExcel && (
                    <>
                      <CloudArrowDown className="mr-2" />
                      Download Excel
                    </>
                  )}
                </Button>
              </>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <SortableInfiniteTable
            tableColumns={tableColumns}
            tableData={subjects === null ? [] : subjects}
            tableHeight={500}
            loading={!subjectsQueryData}
            fetchMoreTableData={fetchMoreSubjects}
            hasMoreTableData={hasMoreSubjects}
            hideGlobalFilter
            loadingMessage="Loading Subjects..."
            onTdClicks={{
              name: onSubjectTdClick,
              email: onSubjectTdClick,
              phone: onSubjectTdClick,
              status: onSubjectTdClick,
              studentId: onSubjectTdClick,
              remove: onRemoveSubjectTdClick,
              metadata: onMetadataClick,
              region: onMetadataClick,
            }}
            rowPointer
            handleSortByChange={handleSortByChange}
          />
        </Col>
      </Row>
      <SubjectGroupRemoveSubjectModal
        subjectId={removeSubjectId}
        subjects={subjects}
        setSubjects={setSubjects}
        subjectGroupName={subjectGroupName}
        subjectName={removeSubjectName}
        subjectGroupId={subjectGroupId}
        showModal={showRemoveSubjectModal}
        toggleModal={toggleRemoveSubjectModal}
      />
      <SubjectGroupAddSubjectsModal
        subjectGroupId={subjectGroupId}
        organizationId={organizationId}
        showModal={showAddSubjectsModal}
        toggleModal={toggleAddSubjectsModal}
        organizationName={organizationName}
      />
      <SubjectGroupSubjectMetadata
        sgsId={sgsId}
        sgsMetadata={sgsMetadata}
        showModal={showMetadataModal}
        toggleModal={toggleMetadataModal}
        refetch={'SubjectsQuery'}
      />
      <SubjectGroupSubjectImaqeQuixId
        sgsId={sgsId}
        imagequixId={iqId}
        showModal={showIqIdModal}
        refetch={'SubjectsQuery'}
        toggleModal={toggleIqIdModal}
      />
      <SubjectGroupSubjectCustomFields
        sgs={sgsNode}
        showModal={showCustomFieldModal}
        refetch={'SubjectsQuery'}
        toggleModal={toggleCustomFieldModal}
      />
      <FotomerchantGalleryPasswordModal
        showModal={fotomerchantGalleryPassword}
        toggleModal={setFotomerchantGalleryPassword}
        fotomerchantPassword={fotomerchantGalleryPassword}
      />
      {showSubjectModal && (
        <NewSubjectModal
          organization={organization}
          subjectGroupId={subjectGroupId}
          subjectGroupName={subjectGroupName}
          showSubjectModal={showSubjectModal}
          subjectModalShowChange={setShowSubjectModal}
          setShowSubjectModal={setShowSubjectModal}
          displayBilling
        />
      )}
    </div>
  )
}
export default SubjectGroupSubjectsTable
