import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Table } from 'react-bootstrap'
import { FilePond } from 'react-filepond'
import {
  CaretDown,
  CaretRight,
  ExclamationCircle,
  ExclamationTriangle,
  Image,
  XCircle,
  PlusCircle,
  ArrowCounterclockwise,
} from 'react-bootstrap-icons'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Field, ErrorMessage, FieldArray } from 'formik'
import {
  CANADIAN_PROVINCES,
  ORGANIZATION_CATEGORY as organizations,
  US_STATES,
} from '../../libs/constant'
import { Trash } from 'react-bootstrap-icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loading from '../common/Loading'
import SubjectCsvImport from '../subject/SubjectCsvImport'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { useInterval } from '../../libs/utils'
import AutocompleteAddress from '../location/AddressAutocomplete'
import Policies from '../common/Policies'
import OrganizationStageSearchInput from '../common/node_search_input/OrganizationStageSearchInput'
import OrganizationStage from './OrganizationStage'
import TagSearchInput from '../common/node_search_input/TagSearchInput'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'

export default function OrgForm(props) {
  const {
    formik,
    orgError,
    isOrgCreated,
    updatingOrg,
    logoImage,
    isSubmitting,
    setIsSubmitting,
    setCsvRecordSync,
    showModal,
    onComplete,
  } = props
  console.log('formik', formik)
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  useInterval(() => {
    document
      .querySelectorAll('.CSVImporter_IconButton')
      .forEach((importerButton) => {
        if (!importerButton.hasAttribute('type')) {
          importerButton.setAttribute('type', 'button')
        }
      })
    document
      .querySelectorAll('.CSVImporter_TextButton')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Import') {
          importerTextButton.innerHTML = 'Validate Import'
        }
      })
    document
      .querySelectorAll('.CSVImporter_ProgressDisplay__status')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Complete') {
          importerTextButton.innerHTML = ''
        }
      })
  }, 100)

  useState(false)
  const [showAccountManagerResults, setShowAccountManagerResults] =
    useState(false)
  const [csvDataTransformed, setCsvDataTransformed] = useState([])
  const [importerKey, setImporterKey] = useState('5678')
  const [groupOptionValue, setGroupOptionValue] = useState('dash')
  const [rowsMissingInfo, setRowsMissingInfo] = useState([])
  const [rowFatalError, setRowFatalError] = useState(false)
  const [showOrganizationStageModal, setShowOrganizationStageModal] =
    useState(false)

  const [displayContacts, setDisplayContacts] = useState(false)
  const [displayLocation, setDisplayLocation] = useState(false)
  const [displaySessionPackages, setDisplaySessionPackages] = useState(false)
  const [displayFiles, setDisplayFiles] = useState(false)
  const [displayGaiaUserRegions, setDisplayGaiaUserRegions] = useState({})
  const [displayOrganizationRegion, setDisplayOrganizationRegion] =
    useState(false)
  const regionsValidationMessage = [
    'at least one region must be default',
    'only one region can be default',
    'region must be unique',
  ]

  const { data: sessionPackageData } = useQuery(
    gql`
      query SessionPackages {
        sessionPackages(orderBy: "title") {
          edges {
            node {
              id
              customPriceAndDuration
              durationHighMinutes
              durationLowMinutes
              price
              title
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const { data: collectionData } = useQuery(
    gql`
      query TaskCollectionsQuery {
        taskCollections(
          orderBy: "name"
          discludeSubjectGroupCollections: true
          discludeJobCollections: true
        ) {
          edges {
            node {
              id
              name
              default
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [searchEmployee, { data: employeesData }] = useLazyQuery(
    gql`
      query EmployeesQuery(
        $cursor: String
        $searchTerm: String
        $regions: String
      ) {
        employees(
          first: 20
          after: $cursor
          gaiaUser_FullName_Icontains: $searchTerm
          regions: $regions
          orderBy: "gaia_user__full_name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                firstName
                lastName
                email
                phoneNumber
                fullName
              }
              defaultRole {
                name
              }
              internalEmployeeId
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!showModal) {
      setIsSubmitting(false)
      formik.resetForm()
    }
  }, [showModal])

  const handleFileUpload = (updateLogoImage) => {
    formik.setFieldValue('logoImage', updateLogoImage)
  }

  const handleAccManagerChange = (formik, e) => {
    searchEmployee({
      variables: {
        searchTerm: e.target.value,
        regions: formik.values.organizationRegions
          .map((region) => region.regionId)
          .join(','),
      },
      fetchPolicy: 'network-only',
    })
    formik.setFieldValue(`accountManagerSearch`, e.target.value)
    setShowAccountManagerResults(true)
  }

  const handleGroupClick = (e) => {
    let taskCollectionData = collectionData.taskCollections.edges
    const index = taskCollectionData.findIndex(
      (x) => x.node.id === e.target.value
    )

    if (index > -1) {
      const currentGroup = formik.values.group
      currentGroup.push(taskCollectionData[index])
      formik.setFieldValue('group', currentGroup)

      setGroupOptionValue('dash')
    }
  }

  const handleRemoveGroup = (group) => {
    const currentGroup = formik.values.group
    currentGroup.splice(currentGroup.indexOf(group), 1)
    formik.setFieldValue('group', currentGroup)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      formik.setFieldValue(`accountManagerSearch`, '')
      formik.setFieldValue('accountManager', null)
      setShowAccountManagerResults(false)
    }
  }

  const updateCsvData = (transformedData) => {
    setCsvDataTransformed(transformedData)
    formik.setFieldValue('csvSubjects', transformedData)
  }

  const newUploadClick = () => {
    setImporterKey(Math.random())
    setRowsMissingInfo([])
    setRowFatalError(false)
    setCsvDataTransformed([])
  }

  const onAccountManagerClick = (row) => {
    formik.setFieldValue(
      'accountManagerSearch',
      row.original.node.gaiaUser.fullName
    )
    formik.setFieldValue('accountManager', row.original.node.id)
    setShowAccountManagerResults(false)
  }
  useEffect(() => {
    if (!orgError && isOrgCreated) {
      formik.handleReset()
    }
  }, [isOrgCreated])

  let availableBackgroundColorPackages = []
  if (sessionPackageData) {
    sessionPackageData.sessionPackages.edges.forEach((sp) => {
      let used = false
      formik.values.sessionPackageBackgrounds.forEach((spb) => {
        if (sp.node.id === spb.sessionPackageId) {
          used = true
        }
      })
      if (!used) {
        availableBackgroundColorPackages.push(sp.node)
      }
    })
  }
  const handleAddressSelect = (addressData, locationIndexValue) => {
    // Use dot/bracket notation for nested fields in Formik.
    formik.setFieldValue(
      `locations.${locationIndexValue}.addressLineOne`,
      addressData?.name || ''
    )
    formik.setFieldValue(
      `locations.${locationIndexValue}.addressLineTwo`,
      addressData?.neighborhood || ''
    )
    formik.setFieldValue(
      `locations.${locationIndexValue}.city`,
      addressData?.address?.locality || ''
    )
    formik.setFieldValue(
      `locations.${locationIndexValue}.state`,
      addressData?.address?.administrative_area_level_1 || ''
    )
    formik.setFieldValue(
      `locations.${locationIndexValue}.zipCode`,
      addressData?.address?.postal_code || ''
    )
  
    // If you still need to mark `isSelected = false`:
    formik.setFieldValue(`locations.${locationIndexValue}.isSelected`, false)
  }
  
  if (!sessionPackageData) return <></>
  return (
    <>
      <OrganizationStage
        showModal={showOrganizationStageModal}
        toggleMogal={setShowOrganizationStageModal}
      />
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>Name</Form.Label>
            <Field name="name" className="form-control form-control-sm" />
            <ErrorMessage name="name">
              {(msg) => <span className="text-danger">{msg}</span>}
            </ErrorMessage>
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label>
              Account Manager
              {formik.values.accountManager && (
                <span
                  type="button"
                  className="p-0 ml-2 btn-link"
                  onClick={() => {
                    formik.setFieldValue(`accountManagerSearch`, '')
                    formik.setFieldValue('accountManager', null)
                  }}
                >
                  <Trash />
                </span>
              )}
            </Form.Label>
            <Form.Control
              className="form-control-sm"
              value={formik.values.accountManagerSearch}
              readOnly={Boolean(formik.values.accountManager)}
              onChange={(e) => handleAccManagerChange(formik, e)}
              placeholder="Search"
            />
            <div className={showAccountManagerResults ? '' : 'd-none'}>
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  zIndex: 3000,
                  width: '95%',
                  top: '100%',
                }}
              >
                <SortableInfiniteTable
                  hideGlobalFilter
                  tableData={
                    employeesData?.employees?.edges
                      ? employeesData?.employees?.edges
                      : []
                  }
                  tableColumns={[
                    {
                      Header: '',
                      accessor: 'node.gaiaUser.fullName',
                    },
                  ]}
                  hasMoreTableData={false}
                  rowPointer
                  onRowClick={onAccountManagerClick}
                  tableHeight={100}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>Category</Form.Label>
            <select
              className="form-select form-control-sm"
              name={formik.values.category}
              value={formik.values.category}
              onChange={(e) => {
                formik.setFieldValue('category', e.target.value)
              }}
            >
              <>
                {organizations.map((org) => (
                  <>
                    <option key={org} value={org}>
                      {org}
                    </option>
                  </>
                ))}
              </>
            </select>
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>
              Stage
              <span
                type="button"
                className="p-0 ml-1 btn-link"
                onClick={() => {
                  setShowOrganizationStageModal(true)
                }}
              >
                <span style={{ fontSize: '13px' }}>
                  <PlusCircle />
                </span>
              </span>
            </Form.Label>
            <OrganizationStageSearchInput
              formik={formik}
              dropdown
              error={formik.errors.organizationStageId}
            />
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>Tags</Form.Label>
            <TagSearchInput formik={formik} multiple create dropdown />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Row>
            <Col xs={12}>
              <Form.Label>Logo</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {updatingOrg ? (
                <>
                  {logoImage ? (
                    <img
                      className="d-block mx-auto img-fluid w-25"
                      src={`data:image/jpeg;base64,${logoImage}`}
                      alt="Organization logo"
                    />
                  ) : (
                    <div>
                      <Image className="d-block mx-auto w-25 h-25 text-secondary" />
                    </div>
                  )}
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-3">
              <FilePond
                files={formik.values.logoImage}
                onupdatefiles={handleFileUpload}
                acceptedFileTypes={['image/png', 'image/jpeg']}
                allowFileSizeValidation
                maxFileSize="15MB"
                labelMaxFileSize="Maximum preview image size is {filesize}"
                maxFiles={1}
                name="files"
                labelIdle='Drag and drop a logo image or <span class="filepond--label-action">Browse</span>'
              />
            </Col>
          </Row>
        </Form.Group>
        <Row className="mt-3">
          <Form.Group as={Col} md={12}>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              value={formik.values.notes}
              onChange={(e) => {
                formik.setFieldValue(`notes`, e.target.value)
              }}
              as="textarea"
              rows={6}
              className="form-text"
            />
            <ErrorMessage name={`notes`}>
              {(msg) => (
                <div>
                  <span className="text-danger">{msg}</span>
                </div>
              )}
            </ErrorMessage>
          </Form.Group>
        </Row>
        <Row className="mt-3">
          <Col>
            <p className="m-0">Subject CSV Import</p>
            {loggedInUser?.canManageRegions && settings?.tenantRegions && (
              <Row className="mt-3">
                <Col md={3}>
                  <Form.Label>Subjects Region</Form.Label>
                  <RegionSearchInput
                    dropdown
                    formik={formik}
                    formikValue="csvSubjectsRegion"
                    error={formik.errors.csvSubjectsRegionId}
                    variables={{
                      organization: formik.values.id,
                    }}
                    query={gql`
                      query Regions(
                        $first: Int
                        $after: String
                        $search: String
                        $organization: String
                      ) {
                        regions(
                          first: $first
                          after: $after
                          orderBy: "name"
                          search: $search
                          organization: $organization
                        ) {
                          nodeCount
                          pageInfo {
                            hasNextPage
                            endCursor
                          }
                          edges {
                            node {
                              id
                              name
                              timezone
                            }
                          }
                        }
                      }
                    `}
                  />
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col md={3}>
                <Form.Label>CSV Import Existing Record Sync Field</Form.Label>
                <Form.Group>
                  <select
                    className="form-control-sm form-select"
                    name="csvRecordSync"
                    value={formik.values.csvRecordSync}
                    onChange={(e) => {
                      setCsvRecordSync(e.target.value || undefined)
                    }}
                  >
                    <option value="">- - -</option>
                    <option value="subject full name">Subject Full Name</option>
                    <option value="subject id">Subject ID</option>
                    <option value="subject email">Subject Email</option>
                    <option value="custom one">Custom One</option>
                    <option value="custom two">Custom Two</option>
                    <option value="custom three">Custom Three</option>
                    <option value="custom four">Custom Four</option>
                    <option value="custom five">Custom Five</option>
                  </select>
                </Form.Group>
              </Col>
            </Row>
            {csvDataTransformed.length > 0 && (
              <Button
                className="mt-2 mb-1"
                onClick={newUploadClick}
                size="sm"
                variant="outline-primary"
              >
                <ArrowCounterclockwise className="mr-2" />
                New Upload
              </Button>
            )}

            <div className="mt-2">
              <SubjectCsvImport
                importerKey={importerKey}
                rowsMissingInfo={rowsMissingInfo}
                setRowsMissingInfo={setRowsMissingInfo}
                setRowFatalError={setRowFatalError}
                rowFatalError={rowFatalError}
                updateCsvData={updateCsvData}
              />
            </div>
          </Col>
        </Row>
        {rowsMissingInfo.length > 0 && (
          <>
            <Row className="mt-3">
              <Col>
                <h4>
                  <ExclamationTriangle className="mr-2" />
                  Import Warnings and Errors
                </h4>
              </Col>
            </Row>
            <Row
              className="mt-3"
              style={{
                overflowY: 'scroll',
                maxHeight: '200px',
                overflowX: 'hidden',
              }}
            >
              <Col>
                <Table responsive size="sm" bordered>
                  <thead>
                    <tr>
                      <th>
                        <small className="font-weight-bold">Type</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Row</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Column</small>
                      </th>
                      <th>
                        <small className="font-weight-bold">Error</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowsMissingInfo.map((csvError, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <small className="d-block">
                              {csvError.fatal ? <>Fatal</> : <>Warning</>}
                            </small>
                          </td>
                          <td>
                            <small
                              className="d-block"
                              style={{
                                width: '150px',
                              }}
                            >
                              {csvError.row}
                            </small>
                          </td>
                          <td>
                            <small
                              className="d-block"
                              style={{
                                width: '150px',
                              }}
                            >
                              {csvError.column}
                            </small>
                          </td>
                          <td>
                            <small className="d-block">{csvError.error}</small>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        <Row
          className={
            csvDataTransformed.length > 0 && rowFatalError === false
              ? 'text-secondary'
              : 'd-none'
          }
        >
          <Col>
            <p
              className={
                csvDataTransformed.length > 0
                  ? 'text-secondary mt-3 mb-0'
                  : 'd-none'
              }
            >
              CSV Data
            </p>
            <small
              className={
                csvDataTransformed.length > 0 ? 'text-secondary' : 'd-none'
              }
            >
              {csvDataTransformed.length} rows
            </small>
            <div
              style={{
                overflowY: 'scroll',
                maxHeight: '300px',
                overflowX: 'hidden',
              }}
            >
              <Table
                responsive
                size="sm"
                bordered
                style={{ maxHeight: '400px' }}
                className={
                  csvDataTransformed.length > 0 ? 'mt-2 d-block' : 'd-none'
                }
              >
                <thead>
                  <tr>
                    <th>
                      <small className="font-weight-bold">
                        Subject First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Job Title
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">ID Number</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Subject Email</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Password
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Subject Phone</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Secondary Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Fields
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Address Line One
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Address Line Two
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">City</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">State</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Zip Code</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Password
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Secondary Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Password
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Secondary Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom One
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Two
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Three
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Four
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Five
                      </small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {csvDataTransformed.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectFirstName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectLastName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectJobTitle}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.studentId}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.subjectEmail}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.subjectSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.subjectPassword}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectSecondaryPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block">{row.metadata}</small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.addressLineOne}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.addressLineTwo}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.city}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.state}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.zipCode}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentOneFirstName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentOneLastName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.parentOneEmail}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.parentOneSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentOnePassword}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentOnePhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentOneSecondaryPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentTwoFirstName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentTwoLastName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.parentTwoEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentTwoPassword}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '200px' }}>
                            {row.parentTwoSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentTwoPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.parentTwoSecondaryPhone}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomOne}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomTwo}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomThree}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomFour}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectCustomFive}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomOne}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomTwo}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomThree}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomFour}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectGroupCustomFive}
                          </small>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        {settings.tenantSubscription !== 'Basic' && (
          <>
            <Row className="mt-3">
              <Col>
                <Form.Label>Add Tasks From Collection</Form.Label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={3}>
                {collectionData &&
                collectionData.taskCollections.edges.length > 0 ? (
                  <>
                    <Form.Control
                      name="group"
                      as="select"
                      className="form-control-sm"
                      value={groupOptionValue}
                      onChange={(e) => handleGroupClick(e)}
                    >
                      <option value="dash">- - -</option>
                      {collectionData.taskCollections.edges
                        .filter((groupData) => {
                          let show = true
                          formik.values.group.forEach((selected) => {
                            if (selected?.node.id === groupData?.node.id) {
                              show = false
                            }
                          })
                          return show
                        })
                        .map((data) => (
                          <option key={data.node.id} value={data.node.id}>
                            {data.node.name}
                          </option>
                        ))}
                    </Form.Control>{' '}
                    <div>
                      {formik.values.group.map((data, index) => (
                        <Row key={index}>
                          <Col md={12}>
                            <div
                              key={index}
                              className="p-3 border border-dark rounded mt-2 d-flex"
                            >
                              <div
                                className="hover btn-link px-2"
                                onClick={() => {
                                  handleRemoveGroup(data)
                                }}
                              >
                                <Trash className="mr-2" />
                              </div>
                              <p className="mb-0">
                                {data.node.name} Collection
                              </p>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>{' '}
                  </>
                ) : (
                  <Form.Label>No Task Collections Exists</Form.Label>
                )}
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={3}>
            <Form.Label>Stripe Customer Email</Form.Label>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Form.Control
              type="text"
              name="invoiceEmail"
              className="form-control-sm"
              value={formik.values.invoiceEmail}
              onChange={formik.handleChange}
              isInvalid={formik.errors.invoiceEmail}
            />
          </Col>
        </Row>
        <Policies formik={formik} nodeType="organization" />

        <Row className="mt-3">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayFiles(!displayFiles)}
              className="px-0 btn-link mr-2"
              style={{ marginTop: '-10px' }}
            >
              <>
                {displayFiles ? (
                  <CaretDown size={17} />
                ) : (
                  <CaretRight size={17} />
                )}
              </>
            </button>
            <Form.Label>Files </Form.Label>
          </Col>
        </Row>
        {displayFiles && (
          <>
            <Row className="ml-2">
              <Col>
                <span
                  onClick={() => {
                    formik.setFieldValue(
                      `sharedCanSeeFiles`,
                      !formik.values.sharedCanSeeFiles
                    )
                  }}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '10px 20px',
                  }}
                >
                  <Form.Check
                    name="sharedCanSeeFiles"
                    type="switch"
                    label="Contacts Can See Files"
                    checked={formik.values.sharedCanSeeFiles}
                    onChange={(e) => {
                      e.stopPropagation()
                      formik.setFieldValue(
                        `sharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </span>
              </Col>
              {formik.values.sharedCanSeeFiles && (
                <>
                  <Col>
                    <span
                      onClick={() => {
                        formik.setFieldValue(
                          `sharedCanCreateFiles`,
                          !formik.values.sharedCanCreateFiles
                        )
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="sharedCanCreateFiles"
                        type="switch"
                        label="Contacts Can Create Files"
                        checked={formik.values.sharedCanCreateFiles}
                        onChange={(e) => {
                          e.stopPropagation()
                          formik.setFieldValue(
                            `sharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </span>
                  </Col>
                  <Col>
                    <span
                      onClick={() => {
                        formik.setFieldValue(
                          `sharedCanCreateFolders`,
                          !formik.values.sharedCanCreateFolders
                        )
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="sharedCanCreateFolders"
                        type="switch"
                        label="Contacts Can Create Folders"
                        checked={formik.values.sharedCanCreateFolders}
                        onChange={(e) => {
                          e.stopPropagation()
                          formik.setFieldValue(
                            `sharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </span>
                  </Col>
                </>
              )}
            </Row>

            <Row className="ml-2 mt-3">
              <Col>
                <span
                  onClick={() => {
                    formik.setFieldValue(
                      `subjectGroupSharedCanSeeFiles`,
                      !formik.values.subjectGroupSharedCanSeeFiles
                    )
                  }}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '10px 20px',
                  }}
                >
                  <Form.Check
                    name="subjectGroupSharedCanSeeFiles"
                    type="switch"
                    label="Contacts Can See Subject Group Files"
                    checked={formik.values.subjectGroupSharedCanSeeFiles}
                    onChange={(e) => {
                      e.stopPropagation()
                      formik.setFieldValue(
                        `subjectGroupSharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </span>
              </Col>
              {formik.values.subjectGroupSharedCanSeeFiles && (
                <>
                  <Col>
                    <span
                      onClick={() => {
                        formik.setFieldValue(
                          `subjectGroupSharedCanCreateFiles`,
                          !formik.values.subjectGroupSharedCanCreateFiles
                        )
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="subjectGroupSharedCanCreateFiles"
                        type="switch"
                        label="Contacts Can Create Subject Group Files"
                        checked={formik.values.subjectGroupSharedCanCreateFiles}
                        onChange={(e) => {
                          e.stopPropagation()
                          formik.setFieldValue(
                            `subjectGroupSharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </span>
                  </Col>
                  <Col>
                    <span
                      onClick={() => {
                        formik.setFieldValue(
                          `subjectGroupSharedCanCreateFolders`,
                          !formik.values.subjectGroupSharedCanCreateFolders
                        )
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="subjectGroupSharedCanCreateFolders"
                        type="switch"
                        label="Contacts Can Create Subject Group Folders"
                        checked={
                          formik.values.subjectGroupSharedCanCreateFolders
                        }
                        onChange={(e) => {
                          e.stopPropagation()
                          formik.setFieldValue(
                            `subjectGroupSharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </span>
                  </Col>
                </>
              )}
            </Row>

            <Row className="ml-2 mt-3">
              <Col>
                <span
                  onClick={() => {
                    formik.setFieldValue(
                      `subjectSharedCanSeeFiles`,
                      !formik.values.subjectSharedCanSeeFiles
                    )
                  }}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '10px 20px',
                  }}
                >
                  <Form.Check
                    name="subjectSharedCanSeeFiles"
                    type="switch"
                    label="Subjects Can See Files"
                    checked={formik.values.subjectSharedCanSeeFiles}
                    onChange={(e) => {
                      e.stopPropagation()
                      formik.setFieldValue(
                        `subjectSharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </span>
              </Col>
              {formik.values.subjectSharedCanSeeFiles && (
                <>
                  <Col>
                    <span
                      onClick={() => {
                        formik.setFieldValue(
                          `subjectSharedCanCreateFiles`,
                          !formik.values.subjectSharedCanCreateFiles
                        )
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="subjectSharedCanCreateFiles"
                        type="switch"
                        label="Subjects Can Create Files"
                        checked={formik.values.subjectSharedCanCreateFiles}
                        onChange={(e) => {
                          e.stopPropagation()
                          formik.setFieldValue(
                            `subjectSharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </span>
                  </Col>
                  <Col>
                    <span
                      onClick={() => {
                        formik.setFieldValue(
                          `subjectSharedCanCreateFolders`,
                          !formik.values.subjectSharedCanCreateFolders
                        )
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="subjectSharedCanCreateFolders"
                        type="switch"
                        label="Subjects Can Create Folders"
                        checked={formik.values.subjectSharedCanCreateFolders}
                        onChange={(e) => {
                          e.stopPropagation()
                          formik.setFieldValue(
                            `subjectSharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </span>
                  </Col>
                </>
              )}
            </Row>

            <Row className="ml-2 mt-3">
              <Col>
                <span
                  onClick={() => {
                    formik.setFieldValue(
                      `taskSharedCanSeeFiles`,
                      !formik.values.taskSharedCanSeeFiles
                    )
                  }}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '10px 20px',
                  }}
                >
                  <Form.Check
                    name="taskSharedCanSeeFiles"
                    type="switch"
                    label="Contacts Can See Task Files"
                    checked={formik.values.taskSharedCanSeeFiles}
                    onChange={(e) => {
                      e.stopPropagation()
                      formik.setFieldValue(
                        `taskSharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </span>
              </Col>
              {formik.values.taskSharedCanSeeFiles && (
                <>
                  <Col>
                    <span
                      onClick={() => {
                        formik.setFieldValue(
                          `taskSharedCanCreateFiles`,
                          !formik.values.taskSharedCanCreateFiles
                        )
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="taskSharedCanCreateFiles"
                        type="switch"
                        label="Contacts Can Create Task Files"
                        checked={formik.values.taskSharedCanCreateFiles}
                        onChange={(e) => {
                          e.stopPropagation()
                          formik.setFieldValue(
                            `taskSharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </span>
                  </Col>
                  <Col>
                    <span
                      onClick={() => {
                        formik.setFieldValue(
                          `taskSharedCanCreateFolders`,
                          !formik.values.taskSharedCanCreateFolders
                        )
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="taskSharedCanCreateFolders"
                        type="switch"
                        label="Contacts Can Create Task Folders"
                        checked={formik.values.taskSharedCanCreateFolders}
                        onChange={(e) => {
                          e.stopPropagation()
                          formik.setFieldValue(
                            `taskSharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </span>
                  </Col>
                </>
              )}
            </Row>
          </>
        )}

        <Row className="">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayContacts(!displayContacts)}
              className="px-0 btn-link mr-2"
              style={{ marginTop: '-10px' }}
            >
              <>
                {displayContacts ? (
                  <CaretDown size={17} />
                ) : (
                  <CaretRight size={17} />
                )}
              </>
            </button>
            <Form.Label>Contacts </Form.Label>
          </Col>
        </Row>
        {displayContacts && (
          <Form.Row>
            <Form.Group as={Col} md={12}>
              <FieldArray
                name="contacts"
                render={(arrayHelpers) => (
                  <div>
                    <div
                      style={
                        formik.values.contacts.length > 1
                          ? {
                              overflowY: 'scroll',
                              maxHeight: '450px',
                              overflowX: 'hidden',
                            }
                          : null
                      }
                      className={
                        formik.values.contacts.length > 1
                          ? 'border p-3 mt-2'
                          : ''
                      }
                    >
                      <div className="contact-form">
                        {formik.values.contacts.map((contact, i) => {
                          return (
                            <div
                              key={i}
                              className="border border-secondary border-rounded p-3 rounded mb-3"
                            >
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>First Name</Form.Label>
                                  <Field
                                    name={`contacts[${i}].firstName`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].firstName`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Last Name</Form.Label>
                                  <Field
                                    name={`contacts[${i}].lastName`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].lastName`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Job Title</Form.Label>
                                  <Field
                                    name={`contacts[${i}].jobTitle`}
                                    className="form-control form-control-sm"
                                    type="text"
                                  />

                                  <ErrorMessage
                                    name={`contacts[${i}].jobTitle`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Login Access</Form.Label>
                                  <Form.Row>
                                    <Col
                                      xs={12}
                                      md={4}
                                      style={{ marginLeft: '35px' }}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        checked={
                                          formik.values.contacts[i].active
                                        }
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            `contacts[${i}].active`,
                                            e.target.checked
                                          )
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`contacts[${i}].active`}
                                      >
                                        {(msg) => (
                                          <span className="text-danger">
                                            {msg}
                                          </span>
                                        )}
                                      </ErrorMessage>
                                    </Col>
                                  </Form.Row>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Email</Form.Label>
                                  <Field
                                    name={`contacts[${i}].email`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage name={`contacts[${i}].email`}>
                                    {(msg) => {
                                      return (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )
                                    }}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Secondary Email</Form.Label>
                                  <Field
                                    name={`contacts[${i}].secondaryEmail`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].secondaryEmail`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Phone Number</Form.Label>
                                  <PhoneInput
                                    placeholder=""
                                    inputClass="w-100 form-control"
                                    country="us"
                                    name={`contacts[${i}].phoneNumber`}
                                    regions={['north-america']}
                                    value={
                                      formik.values.contacts[i].phoneNumber
                                    }
                                    onChange={(phone) =>
                                      formik.setFieldValue(
                                        `contacts[${i}].phoneNumber`,
                                        phone
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].phoneNumber`}
                                  >
                                    {(msg) => (
                                      <div>
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>
                                    Secondary Phone Number
                                  </Form.Label>
                                  <PhoneInput
                                    placeholder=""
                                    inputClass="w-100 form-control"
                                    country="us"
                                    name={`contacts[${i}].secondaryPhoneNumber`}
                                    regions={['north-america']}
                                    value={
                                      formik.values.contacts[i]
                                        .secondaryPhoneNumber
                                    }
                                    onChange={(phone) =>
                                      formik.setFieldValue(
                                        `contacts[${i}].secondaryPhoneNumber`,
                                        phone
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].secondaryPhoneNumber`}
                                  >
                                    {(msg) => (
                                      <div>
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <div className="d-flex align-items-center">
                                    <Form.Label>Password</Form.Label>
                                  </div>

                                  <Field
                                    name={`contacts[${i}].password`}
                                    className="form-control form-control-sm"
                                    type="password"
                                  />

                                  <ErrorMessage
                                    name={`contacts[${i}].password`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Confirm Password</Form.Label>
                                  <Field
                                    name={`contacts[${i}].confirmPassword`}
                                    className="form-control form-control-sm"
                                    type="password"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].confirmPassword`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Address Line One</Form.Label>
                                  <Field
                                    name={`contacts[${i}].addressLineOne`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].addressLineOne`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                  <Form.Label>Address Line Two</Form.Label>
                                  <Field
                                    name={`contacts[${i}].addressLineTwo`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage
                                    name={`contacts[${i}].addressLineTwo`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md={4}>
                                  <Form.Label>City</Form.Label>
                                  <Field
                                    name={`contacts[${i}].city`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage name={`contacts[${i}].city`}>
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={4}>
                                  <Form.Label>
                                    {settings.locationFormat === 'US'
                                      ? 'State'
                                      : 'Province'}
                                  </Form.Label>
                                  <select
                                    className="form-select form-select-sm"
                                    name={`contacts[${i}].state`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.contacts[i].state ?? ''
                                    }
                                  >
                                    <option>---</option>
                                    {settings.locationFormat === 'US'
                                      ? US_STATES.map((state) => (
                                          <option key={state} value={state}>
                                            {state}
                                          </option>
                                        ))
                                      : CANADIAN_PROVINCES.map((province) => (
                                          <option
                                            key={province}
                                            value={province}
                                          >
                                            {province}
                                          </option>
                                        ))}
                                  </select>
                                  <ErrorMessage name={`contacts[${i}].state`}>
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group as={Col} md={4}>
                                  <Form.Label>Zip Code</Form.Label>
                                  <Field
                                    name={`contacts[${i}].zipCode`}
                                    className="form-control form-control-sm"
                                  />
                                  <ErrorMessage name={`contacts[${i}].zipCode`}>
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Form.Row>
                              {loggedInUser?.canManageRegions &&
                                settings?.tenantRegions &&
                                formik.values.contacts.length > 0 && (
                                  <>
                                    <Form.Row className="mb-3">
                                      <Col className="d-flex align-items-center">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            setDisplayGaiaUserRegions(
                                              (prev) => ({
                                                ...prev,
                                                [i]: !prev[i],
                                              })
                                            )
                                          }
                                          className="px-0 btn-link mr-2"
                                          style={{ marginTop: '-10px' }}
                                        >
                                          <>
                                            {displayGaiaUserRegions[i] ? (
                                              <>
                                                <CaretDown size={17} />
                                              </>
                                            ) : (
                                              <>
                                                <CaretRight size={17} />
                                              </>
                                            )}
                                          </>
                                        </button>
                                        <Form.Label>Regions</Form.Label>
                                        <ErrorMessage
                                          name={`contacts[${i}].gaiaUserRegions[${i}]`}
                                        >
                                          {(msg) => {
                                            return regionsValidationMessage.includes(
                                              msg
                                            ) ? (
                                              <span className="text-danger d-block mb-2 ml-2">
                                                {msg}
                                              </span>
                                            ) : (
                                              <></>
                                            )
                                          }}
                                        </ErrorMessage>
                                      </Col>
                                    </Form.Row>
                                    {displayGaiaUserRegions[i] && (
                                      <FieldArray
                                        name={`contacts[${i}].gaiaUserRegions`}
                                        render={(
                                          gaiaUserRegionArrayHelpers
                                        ) => (
                                          <Row>
                                            <Col md={6}>
                                              <div
                                                style={
                                                  formik.values.contacts[i]
                                                    ?.gaiaUserRegions.length > 1
                                                    ? {
                                                        overflowY: 'scroll',
                                                        maxHeight: '350px',
                                                        overflowX: 'hidden',
                                                      }
                                                    : null
                                                }
                                                className={
                                                  formik.values.contacts[i]
                                                    ?.gaiaUserRegions.length > 1
                                                    ? 'border p-3 mt-2'
                                                    : ''
                                                }
                                              >
                                                <div className="gaia-user-region-form">
                                                  {formik.values.contacts[
                                                    i
                                                  ]?.gaiaUserRegions.map(
                                                    (gaiaUserRegion, index) => (
                                                      <div
                                                        key={index}
                                                        className="border border-secondary rounded mb-3 p-2"
                                                      >
                                                        <Form.Group as={Row}>
                                                          <Col md={2}>
                                                            <Form.Label>
                                                              Region
                                                            </Form.Label>
                                                          </Col>
                                                          <Col md={10}>
                                                            <RegionSearchInput
                                                              dropdown
                                                              formik={formik}
                                                              variables={
                                                                formik.values
                                                                  .contacts[i]
                                                                  ?.id
                                                                  ? {
                                                                      gaiaUser:
                                                                        formik
                                                                          .values
                                                                          .contacts[
                                                                          i
                                                                        ]?.id,
                                                                    }
                                                                  : {
                                                                      organization:
                                                                        formik
                                                                          .values
                                                                          .id,
                                                                    }
                                                              }
                                                              query={gql`
                                                                query Regions(
                                                                  $first: Int
                                                                  $after: String
                                                                  $search: String
                                                                  $organization: String
                                                                  $gaiaUser: String
                                                                ) {
                                                                  regions(
                                                                    first: $first
                                                                    after: $after
                                                                    orderBy: "name"
                                                                    search: $search
                                                                    organization: $organization
                                                                    gaiaUser: $gaiaUser
                                                                  ) {
                                                                    nodeCount
                                                                    pageInfo {
                                                                      hasNextPage
                                                                      endCursor
                                                                    }
                                                                    edges {
                                                                      node {
                                                                        id
                                                                        name
                                                                        timezone
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              `}
                                                              formikValue={`contacts[${i}].gaiaUserRegions[${index}].region`}
                                                              error={
                                                                formik.errors
                                                                  ?.contacts?.[
                                                                  i
                                                                ]
                                                                  ?.gaiaUserRegions?.[
                                                                  index
                                                                ] &&
                                                                !regionsValidationMessage.includes(
                                                                  formik.errors
                                                                    .contacts[i]
                                                                    .gaiaUserRegions[
                                                                    index
                                                                  ]
                                                                )
                                                                  ? formik
                                                                      .errors
                                                                      .contacts[
                                                                      i
                                                                    ]
                                                                      .gaiaUserRegions[
                                                                      index
                                                                    ].regionId
                                                                  : ''
                                                              }
                                                            />
                                                          </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                          <Col md={2}>
                                                            <Form.Label>
                                                              Default
                                                            </Form.Label>
                                                          </Col>
                                                          <Col md={10}>
                                                            <Field
                                                              type="checkbox"
                                                              name={`contacts[${i}].gaiaUserRegions[${index}].default`}
                                                              onChange={(e) => {
                                                                const newValue =
                                                                  e.target
                                                                    .checked
                                                                if (newValue) {
                                                                  formik.setFormikState(
                                                                    (
                                                                      prevState
                                                                    ) => ({
                                                                      ...prevState,
                                                                      values: {
                                                                        ...prevState.values,
                                                                        contacts:
                                                                          prevState.values.contacts.map(
                                                                            (
                                                                              contact,
                                                                              contactIndex
                                                                            ) => {
                                                                              if (
                                                                                contactIndex ===
                                                                                i
                                                                              ) {
                                                                                return {
                                                                                  ...contact,
                                                                                  gaiaUserRegions:
                                                                                    contact.gaiaUserRegions.map(
                                                                                      (
                                                                                        region,
                                                                                        regionIndex
                                                                                      ) => ({
                                                                                        ...region,
                                                                                        default:
                                                                                          regionIndex ===
                                                                                          index,
                                                                                      })
                                                                                    ),
                                                                                }
                                                                              }
                                                                              return contact
                                                                            }
                                                                          ),
                                                                      },
                                                                    })
                                                                  )
                                                                } else {
                                                                  formik.setFieldValue(
                                                                    `contacts[${i}].gaiaUserRegions[${index}].default`,
                                                                    false
                                                                  )
                                                                }
                                                              }}
                                                            />
                                                          </Col>
                                                        </Form.Group>
                                                        <div
                                                          className="d-flex align-items-center mt-2 btn-link hover"
                                                          onClick={() =>
                                                            gaiaUserRegionArrayHelpers.remove(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <Trash className="mr-2" />
                                                          <small>Remove</small>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                              <Button
                                                size="sm"
                                                className="mt-1"
                                                variant="link"
                                                onClick={() => {
                                                  gaiaUserRegionArrayHelpers.push(
                                                    {
                                                      regionId: '',
                                                      default: false,
                                                    }
                                                  )
                                                  let element =
                                                    document.querySelector(
                                                      '.gaia-user-region-form'
                                                    )
                                                  element.lastChild?.scrollIntoView(
                                                    {
                                                      behavior: 'smooth',
                                                      block: 'end',
                                                      inline: 'nearest',
                                                    }
                                                  )
                                                }}
                                              >
                                                <PlusCircle className="mr-2" />
                                                Add Region
                                              </Button>
                                            </Col>
                                          </Row>
                                        )}
                                      />
                                    )}
                                  </>
                                )}
                              <Form.Row>
                                <Form.Row>
                                  <Form.Group as={Col} md={12}>
                                    <Form.Label>
                                      Notification Settings
                                    </Form.Label>
                                  </Form.Group>
                                  <Form.Group as={Col} md={6}>
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        formik.values.contacts[i]
                                          .smsNotificationsEnabled
                                      }
                                      label="Receive SMS"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `contacts[${i}].smsNotificationsEnabled`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md={6}>
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        formik.values.contacts[i]
                                          .emailNotificationsEnabled
                                      }
                                      label="Receive Email"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `contacts[${i}].emailNotificationsEnabled`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md={12}>
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control
                                      name={`contacts[${i}].notes`}
                                      value={formik.values.contacts[i].notes}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `contacts[${i}].notes`,
                                          e.target.value
                                        )
                                      }}
                                      as="textarea"
                                      rows={6}
                                      className="form-text"
                                    />
                                    <ErrorMessage name={`contacts[${i}].notes`}>
                                      {(msg) => (
                                        <div>
                                          <span className="text-danger">
                                            {msg}
                                          </span>
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                  <div
                                    className="d-flex align-items-center mt-2 hover btn-link"
                                    onClick={() => arrayHelpers.remove(i)}
                                  >
                                    <Trash className="mr-2" />
                                    <small>Remove</small>
                                  </div>
                                </Form.Row>
                              </Form.Row>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => {
                        arrayHelpers.push({
                          id: '',
                          firstName: '',
                          lastName: '',
                          email: '',
                          phoneNumber: '',
                          emailNotificationsEnabled: true,
                          smsNotificationsEnabled: true,
                          password: '',
                          confirmPassword: '',
                          notes: '',
                          gaiaUserRegions: [
                            {
                              regionId: loggedInUser?.defaultRegion?.id,
                              default: true,
                            },
                          ],
                          active: true,
                          addressLineOne: '',
                          addressLineTwo: '',
                          city: '',
                          state: '',
                          zipCode: '',
                        })
                        let element = document.querySelector('.contact-form')

                        element.lastChild?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'end',
                          inline: 'nearest',
                        })
                      }}
                    >
                      <PlusCircle className="mr-2" />
                      Add Contact
                    </Button>
                  </div>
                )}
              />
            </Form.Group>
          </Form.Row>
        )}

        <Form.Row>
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayLocation(!displayLocation)}
              className="px-0 btn-link mr-2"
              style={{ marginTop: '-10px' }}
            >
              <>
                {displayLocation ? (
                  <>
                    <CaretDown size={17} />
                  </>
                ) : (
                  <>
                    <CaretRight size={17} />
                  </>
                )}
              </>
            </button>
            <Form.Label>Locations </Form.Label>

            <ErrorMessage name="locations">
              {(msg) => {
                return msg ===
                  'Only One shipping address and one billing address across all locations is allowed' ? (
                  <span className="text-danger d-block mb-2">{msg}</span>
                ) : (
                  <></>
                )
              }}
            </ErrorMessage>
          </Col>
        </Form.Row>
        {displayLocation && (
          <FieldArray
            name="locations"
            render={(locationArrayHelpers) => (
              <>
                <div
                  style={
                    formik.values.locations.length > 1
                      ? {
                          overflowY: 'scroll',
                          maxHeight: '350px',
                          overflowX: 'hidden',
                        }
                      : null
                  }
                  className={
                    formik.values.locations.length > 1 ? 'border p-3 mt-2' : ''
                  }
                >
                  <div className="address-form">
                    {formik.values.locations.map((location, i) => (
                      <div
                        key={i}
                        className="border border-secondary rounded mb-3 p-2"
                      >
                        <Form.Row>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>Name</Form.Label>
                            <Field
                              name={`locations[${i}].name`}
                              className="form-control form-control-sm"
                            />
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>Location</Form.Label>
                            <div className="d-flex">
                              {location.isSelected ? (
                                <AutocompleteAddress
                                  locationIndexValue={i}
                                  onSelect={handleAddressSelect}
                                />
                              ) : (
                                <Form.Control
                                  style={
                                    location && !location.isSelected
                                      ? {
                                          width: '80%',
                                        }
                                      : null
                                  }
                                  disabled={!location.isSelected}
                                  placeholder="Search Locations"
                                  className="form-control-sm "
                                  value={
                                    location.addressLineOne +
                                    ' ' +
                                    location.city +
                                    ' ' +
                                    location.state +
                                    ' ' +
                                    location.zipCode
                                  }
                                />
                              )}

                              {location && !location.isSelected && (
                                <>
                                  <button
                                    type="button"
                                    className="btn-link mb-1 ms-2"
                                    onClick={() =>
                                      formik.setFieldValue(`locations[${i}]`, {
                                        addressLineOne: '',
                                        name: location.name,
                                        addressLineTwo: '',
                                        isSelected: true,
                                        city: '',
                                        state: '',
                                        zipCode: '',
                                        billingAddress: location.billingAddress,
                                        shippingAddress:
                                          location.shippingAddress,
                                      })
                                    }
                                  >
                                    <XCircle />
                                  </button>
                                </>
                              )}
                            </div>
                          </Form.Group>
                          {location && !location.isSelected && (
                            <Form.Group as={Col} md={4}>
                              <Form.Label>Address Line Two</Form.Label>
                              <Field
                                name={`locations[${i}].addressLineTwo`}
                                className="form-control form-control-sm"
                              />
                            </Form.Group>
                          )}
                        </Form.Row>

                        <Form.Group>
                          <Row>
                            <Col xs="auto">
                              <Field
                                type="checkbox"
                                name={`locations[${i}].billingAddress`}
                              />
                              <Form.Label className="ml-2">
                                Billing Address
                              </Form.Label>
                            </Col>
                            <Col>
                              <Field
                                type="checkbox"
                                name={`locations[${i}].shippingAddress`}
                              />
                              <Form.Label className="ml-2">
                                Shipping Address
                              </Form.Label>
                            </Col>
                          </Row>
                        </Form.Group>
                        <div
                          className="d-flex align-items-center mt-2 btn-link hover"
                          onClick={() => locationArrayHelpers.remove(i)}
                        >
                          <Trash className="mr-2" />
                          <small>Remove</small>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <Button
                  size="sm"
                  className="mt-1"
                  variant="link"
                  onClick={() => {
                    locationArrayHelpers.push({
                      name: '',
                      addressLineOne: '',
                      addressLineTwo: '',
                      isSelected: true,
                      city: '',
                      state: '',
                      zipCode: '',
                      billingAddress: false,
                      shippingAddress: false,
                    })
                    let element = document.querySelector('.address-form')

                    element.lastChild?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'end',
                      inline: 'nearest',
                    })
                  }}
                >
                  <PlusCircle className="mr-2" />
                  Add Location
                </Button>
              </>
            )}
          />
        )}
        <Form.Row>
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplaySessionPackages(!displaySessionPackages)}
              className="px-0 btn-link mr-2"
              style={{ marginTop: '-10px' }}
            >
              <>
                {displaySessionPackages ? (
                  <>
                    <CaretDown size={17} />
                  </>
                ) : (
                  <>
                    <CaretRight size={17} />
                  </>
                )}
              </>
            </button>
            <Form.Label>Session Packages Backgrounds</Form.Label>
          </Col>
        </Form.Row>
        {displaySessionPackages && (
          <Row className="mt-3">
            <Col>
              <FieldArray
                className="mt-3"
                name="sessionPackageBackgrounds"
                render={(arrayHelpers) => (
                  <div>
                    {formik.values.sessionPackageBackgrounds.map(
                      (sessionPackageBackground, index) => {
                        const { node } =
                          sessionPackageData.sessionPackages.edges.find(
                            (el) =>
                              el.node.id ===
                              sessionPackageBackground.sessionPackageId
                          )
                        let className = ''
                        if (
                          formik.values.sessionPackageBackgrounds.length > 1 &&
                          index > 0
                        ) {
                          className = 'mt-2'
                        }
                        let description
                        if (node.customPriceAndDuration) {
                          description = `${node.title}  ⚙️ `
                        } else {
                          description = `${node.title}, $${node.price}, ${node.durationLowMinutes}-${node.durationHighMinutes} minutes`
                        }
                        return (
                          <>
                            <div key={index} className={className}>
                              <Row>
                                <Col
                                  md="4"
                                  className="border border-secondary p-2 ml-3"
                                >
                                  <Form.Row>
                                    <Form.Group as={Col} md={12}>
                                      <Form.Label>Session Package</Form.Label>
                                      <Field
                                        name={`sessionPackageBackgrounds.${index}.sessionPackageId`}
                                        as="select"
                                        className="form-control-sm form-select mb-1"
                                      >
                                        <option value={node.id} key={node.id}>
                                          {description}
                                        </option>
                                        {availableBackgroundColorPackages.map(
                                          (node) => {
                                            let description
                                            if (node.customPriceAndDuration) {
                                              description = `${node.title}  ⚙️ `
                                            } else {
                                              description = `${node.title}, $${node.price}, ${node.durationLowMinutes}-${node.durationHighMinutes} minutes`
                                            }
                                            return (
                                              <option
                                                value={node.id}
                                                key={node.id}
                                              >
                                                {description}
                                              </option>
                                            )
                                          }
                                        )}
                                      </Field>
                                    </Form.Group>
                                  </Form.Row>
                                  <Form.Row>
                                    <Form.Group as={Col} md={12}>
                                      <Form.Label>Color</Form.Label>
                                      <Field
                                        name={`sessionPackageBackgrounds.${index}.backgroundColor`}
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={`sessionPackageBackgrounds.${index}.backgroundColor`}
                                      >
                                        {(msg) => (
                                          <span className="text-danger">
                                            {msg}
                                          </span>
                                        )}
                                      </ErrorMessage>
                                    </Form.Group>
                                  </Form.Row>
                                  <Form.Row>
                                    <Form.Group as={Col} md={12}>
                                      <Button
                                        className="p-0 m-0 mt-1"
                                        variant="link"
                                        onClick={() => {
                                          arrayHelpers.remove(index)
                                        }}
                                      >
                                        <Trash className="mr-1" /> Remove
                                      </Button>
                                    </Form.Group>
                                  </Form.Row>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )
                      }
                    )}
                    {availableBackgroundColorPackages.length > 0 ? (
                      <Button
                        variant="link"
                        onClick={() => {
                          const firstNode = availableBackgroundColorPackages[0]
                          arrayHelpers.push({
                            sessionPackageId: firstNode.id,
                            backgroundColor: '',
                            id: '',
                          })
                        }}
                      >
                        <PlusCircle className="mr-2" />
                        Add Package Background
                      </Button>
                    ) : null}
                  </div>
                )}
              />
            </Col>
          </Row>
        )}
        {loggedInUser?.canManageRegions && settings?.tenantRegions && (
          <>
            <Form.Row>
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() =>
                    setDisplayOrganizationRegion(!displayOrganizationRegion)
                  }
                  className="px-0 btn-link mr-2"
                  style={{ marginTop: '-10px' }}
                >
                  <>
                    {displayOrganizationRegion ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label>Regions </Form.Label>
                <ErrorMessage name="organizationRegions">
                  {(msg) => {
                    return regionsValidationMessage.includes(msg) ? (
                      <span className="text-danger d-block mb-2 ml-2">
                        {msg}
                      </span>
                    ) : (
                      <></>
                    )
                  }}
                </ErrorMessage>
              </Col>
            </Form.Row>
            {displayOrganizationRegion && (
              <FieldArray
                name="organizationRegions"
                render={(organizationRegionArrayHelpers) => (
                  <Row>
                    <Col md={6}>
                      <div
                        style={
                          formik.values.organizationRegions.length > 1
                            ? {
                                overflowY: 'scroll',
                                maxHeight: '350px',
                                overflowX: 'hidden',
                              }
                            : null
                        }
                        className={
                          formik.values.organizationRegions.length > 1
                            ? 'border p-3 mt-2'
                            : ''
                        }
                      >
                        <div className="organization-region-form">
                          {formik.values.organizationRegions.map(
                            (organizationRegion, i) => (
                              <div
                                key={i}
                                className="border border-secondary rounded mb-3 p-2"
                              >
                                <Form.Group as={Row}>
                                  <Col md={2}>
                                    <Form.Label>Region</Form.Label>
                                  </Col>
                                  <Col md={10}>
                                    <RegionSearchInput
                                      dropdown
                                      formik={formik}
                                      variables={{
                                        organization: formik.values.id,
                                      }}
                                      query={gql`
                                        query Regions(
                                          $first: Int
                                          $after: String
                                          $search: String
                                          $organization: String
                                        ) {
                                          regions(
                                            first: $first
                                            after: $after
                                            orderBy: "name"
                                            search: $search
                                            organization: $organization
                                          ) {
                                            nodeCount
                                            pageInfo {
                                              hasNextPage
                                              endCursor
                                            }
                                            edges {
                                              node {
                                                id
                                                name
                                                timezone
                                              }
                                            }
                                          }
                                        }
                                      `}
                                      formikValue={`organizationRegions[${i}].region`}
                                      error={
                                        regionsValidationMessage.includes(
                                          formik.errors.organizationRegions
                                        )
                                          ? ''
                                          : formik.errors.organizationRegions
                                                ?.length > 0
                                            ? formik.errors.organizationRegions[
                                                i
                                              ]?.regionId
                                            : ''
                                      }
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Col md={2}>
                                    <Form.Label>Default</Form.Label>
                                  </Col>
                                  <Col md={10}>
                                    <Field
                                      type="checkbox"
                                      name={`organizationRegions[${i}].default`}
                                      onChange={(e) => {
                                        const newValue = e.target.checked
                                        if (newValue) {
                                          formik.setFormikState(
                                            (prevState) => ({
                                              ...prevState,
                                              values: {
                                                ...prevState.values,
                                                organizationRegions:
                                                  prevState.values.organizationRegions.map(
                                                    (region, index) => ({
                                                      ...region,
                                                      default: index === i,
                                                    })
                                                  ),
                                              },
                                            })
                                          )
                                        } else {
                                          formik.setFieldValue(
                                            `organizationRegions[${i}].default`,
                                            false
                                          )
                                        }
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                                <div
                                  className="d-flex align-items-center mt-2 btn-link hover"
                                  onClick={() =>
                                    organizationRegionArrayHelpers.remove(i)
                                  }
                                >
                                  <Trash className="mr-2" />
                                  <small>Remove</small>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <Button
                        size="sm"
                        className="mt-1"
                        variant="link"
                        onClick={() => {
                          organizationRegionArrayHelpers.push({
                            regionId: '',
                            default: false,
                          })
                          let element = document.querySelector(
                            '.organization-region-form'
                          )
                          element.lastChild?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'nearest',
                          })
                        }}
                      >
                        <PlusCircle className="mr-2" />
                        Add Region
                      </Button>
                    </Col>
                  </Row>
                )}
              />
            )}
          </>
        )}
        {rowFatalError && (
          <div className="text-danger mt-3">
            <ExclamationCircle className="mr-2" /> Resolve the fatal subject CSV
            import issues before saving
          </div>
        )}
        <Row>
          <Col md={2}>
            <Button
              className="mt-4"
              type="submit"
              block
              variant="outline-primary"
              disabled={isSubmitting || rowFatalError}
            >
              <PlusCircle className="mr-2" />
              Save
            </Button>
          </Col>
        </Row>
        {isSubmitting ? (
          <Row className="mt-3">
            <Col>
              <Loading message="Saving Organization..." />
            </Col>
          </Row>
        ) : null}
      </Form>
    </>
  )
}
