import React, { useState } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Row, Col, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { CloudArrowDown, Dot } from 'react-bootstrap-icons'
import Loading from '../common/Loading'
import { useDownloadFile } from '../../libs/downloadFile'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { formatTimezone } from '../../libs/utils'

const SubjectPaymentsTable = (props) => {
  const { subjectId } = props
  const { toTimezone } = useDateTimeConverter()
  const { downloadAndDeleteFile } = useDownloadFile()
  const [downloadingReceipt, setDownloadingReceipt] = useState()

  const [downloadPayments] = useMutation(
    gql`
      mutation DownloadStripePaymentIntents(
        $input: DownloadStripePaymentIntentsInput!
      ) {
        downloadStripePaymentIntents(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadStripePaymentIntents.file.fileName,
          data.downloadStripePaymentIntents.file.displayName,
          data.downloadStripePaymentIntents.file.id,
          () => {
            setDownloadingReceipt(false)
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  let hasMorePayments = true
  let paymentsCursor
  const tableColumns = [
    {
      Header: 'Description',
      id: 'description',
      accessor: 'node.description',
    },
    {
      Header: 'Session',
      id: 'session',
      accessor: (row) => {
        if (row.node.session) {
          const timezone = formatTimezone(row.node.session?.region.timezone)
          const sessionStartDateTime = toTimezone(
            row.node.session?.startDateTime,
            { standard: true, timezone: timezone }
          )
          const sessionEndTime = toTimezone(row.node.session?.endDateTime, {
            onlyTime: true,
            timezone: timezone,
          })
          return `${sessionStartDateTime}-${sessionEndTime}`
        }
      },
    },
    {
      Header: 'Paid',
      id: 'paid',
      accessor: (row) => {
        return `$${row.node.amount / 100}`
      },
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => {
        const timezone = formatTimezone(row.node.region?.timezone)
        const on = toTimezone(row.node.updated, {
          format: 'h:mma LLL dd yyyy',
          timezone: timezone,
        })
        if (row.node.declined) {
          return `Declined on ${on}`
        }
        if (
          !row.node.refunded &&
          row.node.stripeRefundIntents.nodeCount === 0
        ) {
          return `Paid on ${on}`
        }
        return (
          <>
            <Dot />
            {`Paid on ${on}`}
            {row.node.stripeRefundIntents.edges.map((edge) => (
              <>
                <br />
                <Dot />
                {(edge.node.amount / 100).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}{' '}
                Refund on{' '}
                {toTimezone(edge.node.created, {
                  format: 'h:mma LLL dd yyyy',
                  timezone: timezone,
                })}
              </>
            ))}
          </>
        )
      },
    },
    {
      Header: 'Receipt',
      id: 'receipt',
      accessor: (row) => {
        return (
          <Button
            className="btn-link"
            disabled={downloadingReceipt}
            onClick={() => {
              setDownloadingReceipt(true)
              downloadPayments({
                variables: {
                  input: {
                    fileType: 'receipt',
                    stripePaymentIntentId: row.node.id,
                  },
                },
              })
            }}
          >
            <span style={{ fontSize: '20px' }}>
              <CloudArrowDown />
            </span>
          </Button>
        )
      },
    },
  ]

  const {
    error: queryError,
    data: queryData,
    fetchMore: queryFetchMore,
  } = useQuery(
    gql`
      query StripePaymentIntentsQuery($cursor: String, $subjectId: String) {
        stripePaymentIntents(
          first: 20
          after: $cursor
          subject: $subjectId
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              stripeId
              refunded
              declined
              refundedAmount
              stripeInvoice {
                stripeInvoiceId
                id
              }
              stripeRefundIntents {
                nodeCount
                edges {
                  node {
                    created
                    amount
                  }
                }
              }
              amount
              description
              session {
                id
                archived
                startDateTime
                endDateTime
                subject {
                  gaiaUser {
                    fullName
                  }
                }
                job {
                  id
                  name
                }
                region {
                  timezone
                }
              }
              updated
              region {
                timezone
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        subjectId,
      },
      errorPolicy: 'all',
    }
  )
  const fetchMoreQueryData = () => {
    queryFetchMore({
      variables: {
        subjectId,
        cursor: paymentsCursor,
      },
    })
  }

  if (queryError) return <>Error loading payments</>
  if (queryData?.stripePaymentIntents) {
    hasMorePayments = queryData.stripePaymentIntents.pageInfo.hasNextPage
    if (queryData.stripePaymentIntents.pageInfo.endCursor) {
      paymentsCursor = queryData.stripePaymentIntents.pageInfo.endCursor
    }
  }
  return (
    <>
      <div className="subjectPayments">
        {!queryData && <Loading />}
        {queryData && (
          <Row className="mt-3">
            <Col xs={12} md={12}>
              {queryData?.stripePaymentIntents && (
                <SortableInfiniteTable
                  tableData={queryData.stripePaymentIntents.edges}
                  tableColumns={tableColumns}
                  loadingMessage="Loading Payments..."
                  fetchMoreTableData={fetchMoreQueryData}
                  hasMoreTableData={hasMorePayments}
                  rowPointer
                  height={300}
                />
              )}
            </Col>
          </Row>
        )}
      </div>
    </>
  )
}

export default SubjectPaymentsTable
