import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import SelectSessionPackage from './SelectSessionPackage'
import CreateSessionSelectSession from './CreateSessionSelectSession'
import SessionConfirmation from './SessionConfirmation'

const ChangeSessionPackageModal = (props) => {
  const {
    currentSessionPackageId,
    currentSessionId,
    subjectId,
    subjectGroupName,
    subjectGroupId,
    showModal,
    toggleModal,
    adminViewing,
    organizationId,
    stripeCard,
    setHideParentCardElement,
    packageCategoryId,
  } = props
  const [bookingStage, setBookingStage] = useState('sessionPackage')
  const [sessionPackage, setSessionPackage] = useState()
  const [jobId, setJobId] = useState()
  const [sessionStartDateTime, setSessionStartDateTime] = useState()
  const [timezone, setTimezone] = useState()
  const [isUpsellSelected, setIsUpsellSelected] = useState(false)

  useEffect(() => {
    if (showModal === false) {
      setSessionPackage()
      setBookingStage('sessionPackage')
    }
    if (showModal && setHideParentCardElement) {
      setHideParentCardElement(true)
    }
    if (!showModal && setHideParentCardElement) {
      setHideParentCardElement(false)
    }
  }, [showModal])

  useEffect(() => {
    if (props && props.setBookingStage) {
      setBookingStage(props.setBookingStage)
    }
  }, [props])

  if (showModal) {
    return (
      <>
        <div className="bookSessionModal">
          <Modal
            size="lg"
            show={showModal}
            onHide={() => toggleModal()}
            aria-labelledby="new-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Change {subjectGroupName && <>{subjectGroupName} Session</>}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {bookingStage === 'sessionPackage' ? (
                <SelectSessionPackage
                  changeSessionPackageSessionId={currentSessionId}
                  setIsUpsellSelected={setIsUpsellSelected}
                  isUpsellSelected={isUpsellSelected}
                  subjectGroupId={subjectGroupId}
                  setSessionPackage={setSessionPackage}
                  sessionPackage={sessionPackage}
                  setBookingStage={setBookingStage}
                  currentSessionPackageId={currentSessionPackageId}
                  toggleModal={toggleModal}
                  subjectId={subjectId}
                  packageCategoryId={!subjectGroupId && packageCategoryId}
                />
              ) : null}
              {bookingStage === 'session' ? (
                <CreateSessionSelectSession
                  showModal={showModal}
                  subjectId={subjectId}
                  subjectGroupId={subjectGroupId}
                  setSessionPackage={setSessionPackage}
                  sessionPackage={sessionPackage}
                  setBookingStage={setBookingStage}
                  toggleModal={toggleModal}
                  previousSessionId={currentSessionId}
                  changeSessionPackageSessionId={currentSessionId}
                  setJobId={setJobId}
                  setSessionStartDateTime={setSessionStartDateTime}
                  timezone={timezone}
                  setTimezone={setTimezone}
                />
              ) : null}
              {bookingStage === 'confirmation' ? (
                <SessionConfirmation
                  showModal={showModal}
                  jobId={jobId}
                  isUpsellSelected={isUpsellSelected}
                  sessionStartDateTime={sessionStartDateTime}
                  sessionPackageId={sessionPackage.id}
                  setSessionStartDateTime={setSessionStartDateTime}
                  setSessionPackage={setSessionPackage}
                  setBookingStage={setBookingStage}
                  setJobId={setJobId}
                  subjectGroupId={subjectGroupId}
                  subjectId={subjectId}
                  toggleModal={toggleModal}
                  adminViewing={adminViewing}
                  previousSessionPackageChange
                  previousSessionId={currentSessionId}
                  organizationId={organizationId}
                  stripeCard={stripeCard}
                  timezone={timezone}
                />
              ) : null}
            </Modal.Body>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default ChangeSessionPackageModal
