import React, { useEffect, useRef, useState } from 'react'
import { useGoogleMaps } from '../../libs/googleMaps'
import { gql, useQuery } from '@apollo/client'
import DateFilter from './DateFilter'
import { Row, Col } from 'react-bootstrap'
import { useDateTimeConverter } from '../../libs/useDateTime'

const LoginLocationsMap = ({ gaiaUserId, timezone }) => {
  const { googleMaps } = useGoogleMaps()
  const mapRef = useRef(null)
  const { toTimezone } = useDateTimeConverter()
  const mapInstanceRef = useRef(null)
  const today = new Date()
  const sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(today.getDate() - 7)
  const [startDateFilter, setStartDateFilter] = useState(sevenDaysAgo)
  const [endDateFilter, setEndDateFilter] = useState(today)
  const [allLocations, setAllLocations] = useState([])

  const LOGIN_LOCATIONS_QUERY = gql`
    query GaiaUserLoginLocations(
      $user: ID
      $created_Gte: DateTime
      $created_Lte: DateTime
      $after: String
    ) {
      gaiaUserLoginLocations(
        first: 500
        user: $user
        created_Gte: $created_Gte
        created_Lte: $created_Lte
        after: $after
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            city
            state
            country
            latitude
            longitude
            created
          }
        }
      }
    }
  `

  const { data, loading, error, fetchMore } = useQuery(LOGIN_LOCATIONS_QUERY, {
    variables: {
      user: gaiaUserId,
      created_Gte: startDateFilter,
      created_Lte: endDateFilter,
      after: null,
    },
    fetchPolicy: 'network-only',
  })

  const fetchAllLocations = async () => {
    if (!data?.gaiaUserLoginLocations) return

    let allEdges = [...(data.gaiaUserLoginLocations.edges || [])]
    let hasNextPage = data.gaiaUserLoginLocations.pageInfo?.hasNextPage
    let endCursor = data.gaiaUserLoginLocations.pageInfo?.endCursor

    while (hasNextPage) {
      const result = await fetchMore({
        variables: { after: endCursor },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          return {
            gaiaUserLoginLocations: {
              ...fetchMoreResult.gaiaUserLoginLocations,
              edges: [
                ...prev.gaiaUserLoginLocations.edges,
                ...fetchMoreResult.gaiaUserLoginLocations.edges,
              ],
            },
          }
        },
      })

      const newData = result.data.gaiaUserLoginLocations
      allEdges = [...allEdges, ...newData.edges]
      hasNextPage = newData.pageInfo.hasNextPage
      endCursor = newData.pageInfo.endCursor
    }

    setAllLocations(allEdges)
  }

  useEffect(() => {
    if (data?.gaiaUserLoginLocations) {
      fetchAllLocations()
    }
  }, [data])

  const calculateAverageCenter = (locations) => {
    if (locations.length === 0) return null

    const total = locations.reduce(
      (acc, loc) => ({
        lat: acc.lat + parseFloat(loc.node.latitude),
        lng: acc.lng + parseFloat(loc.node.longitude),
      }),
      { lat: 0, lng: 0 }
    )

    return {
      lat: total.lat / locations.length,
      lng: total.lng / locations.length,
    }
  }

  const groupLocationsByCoordinates = (locations) => {
    const grouped = new Map()
    locations.forEach((location) => {
      const key = `${location.node.latitude},${location.node.longitude}`
      if (!grouped.has(key)) {
        grouped.set(key, [])
      }
      grouped.get(key).push(location.node)
    })

    grouped.forEach((group) => {
      group.sort((a, b) => new Date(b.created) - new Date(a.created))
    })

    return grouped
  }

  useEffect(() => {
    if (!googleMaps || !mapRef.current || allLocations === undefined) return

    const averageCenter = calculateAverageCenter(allLocations)
    mapInstanceRef.current = new googleMaps.Map(mapRef.current, {
      center: averageCenter || { lat: 39.8283, lng: -98.5795 },
      zoom: averageCenter ? 10 : 4,
    })

    if (!averageCenter) {
      const usBounds = {
        north: 49.382808,
        south: 24.396308,
        west: -125.0,
        east: -66.93457,
      }
      mapInstanceRef.current.fitBounds(usBounds)
    }

    const groupedLocations = groupLocationsByCoordinates(allLocations)

    groupedLocations.forEach((locationGroup, coords) => {
      const [lat, lng] = coords.split(',').map(parseFloat)
      if (isNaN(lat) || isNaN(lng)) return

      const latLng = { lat, lng }
      const loginTime = toTimezone(locationGroup[0].created, {
        humanReadableSort: true,
        timezone: timezone,
      })
      const content = `
        <div class="custom-info-window" style="max-height: 300px; overflow-y: auto; min-width: 300px;">
          <h5>${locationGroup.length} Login${locationGroup.length > 1 ? 's' : ''} at ${locationGroup[0].city}, ${locationGroup[0].state}, ${locationGroup[0].country}</h5>
          ${locationGroup
            .map(
              (loc) => `
                <div style="border-bottom: 1px solid #ddd; padding: 5px 0;">
                  <p style="margin: 0;">${loginTime}</p>
                </div>
              `
            )
            .join('')}
        </div>
      `

      const marker = new googleMaps.Marker({
        position: latLng,
        map: mapInstanceRef.current,
        title: `${locationGroup[0].city}, ${locationGroup[0].state}, ${locationGroup[0].country} (${locationGroup.length} logins)`,
      })

      const infoWindow = new googleMaps.InfoWindow({
        content: content,
        maxWidth: 400,
      })

      marker.addListener('click', () => {
        infoWindow.open(mapInstanceRef.current, marker)
      })
    })
    return () => {
      if (mapInstanceRef.current) {
        googleMaps.event.clearInstanceListeners(mapInstanceRef.current)
        mapInstanceRef.current = null
      }
    }
  }, [googleMaps, allLocations])

  return (
    <>
      <Row className="mb-3">
        <Col>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Logins From"
            placeholderEnd="Logins Until"
            timezone={timezone}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div ref={mapRef} style={{ height: '800px', width: '100%' }} />
        </Col>
      </Row>
    </>
  )
}

export default LoginLocationsMap
