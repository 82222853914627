import React, { useEffect } from 'react'
import {
  EMPLOYEE_GROUPS,
  SUBJECT_GROUPS,
  ORGANIZATION_CONTACT_GROUPS,
  CONSTANTS,
} from './constant'
import { DateTime } from 'luxon'
import moment from 'moment-timezone'

export const setLocalStorageObj = (key, obj) => {
  localStorage.setItem(key, JSON.stringify(obj))
}

export const downloadJSON = (data, filename) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: 'application/json',
  })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const dayOrdinal = (n) => {
  const lastDigit = n % 10
  const lastTwoDigits = n % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return n + 'th'
  }

  switch (lastDigit) {
    case 1:
      return n + 'st'
    case 2:
      return n + 'nd'
    case 3:
      return n + 'rd'
    default:
      return n + 'th'
  }
}

export const humanDate = (date, year = true) => {
  let formatted = `${date.toFormat('cccc, LLLL')} ${dayOrdinal(date.day)}`
  if (year) {
    formatted = `${formatted} ${date.toFormat('yyyy')}`
  }
  return formatted
}

export const formatDateTime = (
  dateTime,
  onlyDate = false,
  timeOnly = false
) => {
  if (!dateTime) {
    return
  }
  try {
    const time = DateTime.fromISO(dateTime).toFormat('hh:mma')
    const date = DateTime.fromISO(dateTime).toFormat('EEEE, MMMM d, yyyy')
    if (onlyDate) return `${date}`
    if (timeOnly) return `${time.toLowerCase()}`
    return `${date} ${time.toLowerCase()} `
  } catch {
    return ''
  }
}

export const downloadBase64Data = (base64Data, fileName) => {
  const binaryString = window.atob(base64Data)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  const blob = new Blob([bytes], { type: 'application/zip' })
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(url)
  link.remove()
}

export const getLocalStorageObj = (key) => {
  const item = localStorage.getItem(key)
  if (item) {
    return JSON.parse(item)
  }
}

export const base64UrlDecode = (str) => {
  let base64 = str.replace(/-/g, '+').replace(/_/g, '/')
  const padding = '='.repeat((4 - (base64.length % 4)) % 4)
  base64 += padding
  return JSON.parse(atob(base64))
}

export const decodeJwtPayload = (jwt) => {
  const parts = jwt.split('.')
  return base64UrlDecode(parts[1])
}

export const setJwtLocalStorage = (auth) => {
  const now = new Date().getTime()
  localStorage.setItem(
    CONSTANTS.JWT,
    JSON.stringify({
      jwt: auth.token,
      refreshOn: now + 2.5 * 60 * 1000,
      refreshedOn: now,
    })
  )
}

export const getJwtLocalStorage = () => {
  const jwt = localStorage.getItem(CONSTANTS.JWT)
  if (jwt) {
    try {
      return JSON.parse(jwt)
    } catch {
      //
    }
  }
}

export const isEmployee = (group) => {
  let employee = false
  if (EMPLOYEE_GROUPS.includes(group)) {
    employee = true
  }
  return employee
}

export const isSubject = (group) => {
  let subject = false
  if (SUBJECT_GROUPS.includes(group)) {
    subject = true
  }
  return subject
}

export const isOrgContact = (group) => {
  let orgContact = false
  if (ORGANIZATION_CONTACT_GROUPS.includes(group)) {
    orgContact = true
  }
  return orgContact
}

export const formatDateStr = (date) => {
  const dateString = `${date.getUTCFullYear()}-${`0${
    date.getUTCMonth() + 1
  }`.slice(-2)}-${`0${date.getUTCDate()}`.slice(
    -2
  )} ${`0${date.getUTCHours()}`.slice(-2)}:${`0${date.getUTCMinutes()}`.slice(
    -2
  )}:${`0${date.getUTCSeconds()}`.slice(-2)}`

  return dateString
}

export const formatTimezone = (unformattedTimezone) => {
  if (!unformattedTimezone) return ''
  const splitTimezone = unformattedTimezone.split('_')
  let formattedTimezone =
    `${splitTimezone[0]}/${splitTimezone[1]}`.toLowerCase()
  return moment.tz
    .names()
    .find((timezone) => timezone.toLowerCase() === formattedTimezone)
}

export const formatRegion = (region) => {
  if (!region) return ''

  return `${region.name} (${formatTimezone(region.timezone)})`
}

export const formatCurrency = (currency) => {
  let formattedCurreny = '$0'
  if (currency) {
    formattedCurreny = `$${currency
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }

  return formattedCurreny
}

export const formatDateTimeToString = (dateTime) => {
  let hours = dateTime.getHours()
  let minutes = dateTime.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  return `${dateTime.toDateString()} ${hours}:${minutes}${ampm}`
}

export const getInstant = () => {
  const instant = new Date()
  return DateTime.utc(
    instant.getFullYear(),
    instant.getMonth() + 1,
    instant.getDate(),
    instant.getHours(),
    instant.getMinutes(),
    instant.getSeconds()
  ).toISO()
}

export const formatDateTimeToTimeString = (dateTime) => {
  let hours = dateTime.getHours()
  let minutes = dateTime.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  return `${hours}:${minutes}${ampm}`
}

export const formatDateTimeToStringOmmitYear = (dateTime) => {
  const datePart = `${dateTime.toDateString(undefined, {
    month: 'short',
    day: 'numeric',
  })}`
  const timePart = `${dateTime.toDateString()}`
  return `${datePart} - ${timePart}`
}

export const formatDateToStringOmmitYear = (dateTime) => {
  return `${dateTime.toDateString(undefined, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  })}`
}

export const objEmpty = (obj) => {
  return Object.keys(obj).length === 0
}

export const objFull = (obj) => {
  if (obj) {
    return !objEmpty(obj)
  }
  return false
}

export const lastArrayItem = (array) => {
  return array.slice(-1).pop()
}

export const addDaysToDate = (date, days) => {
  return date.setDate(date.getDate() + days)
}

export const subtractDaysFromDate = (date, days) => {
  return date.setDate(date.getDate() - days)
}

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const apiToLuxonTimezone = (apiTz) => {
  let luxonTz = ''
  switch (apiTz) {
    case 'US_ALASKA':
      luxonTz = 'US/ALASKA'
      break
    case 'US_CENTRAL':
      luxonTz = 'US/CENTRAL'
      break
    case 'US_EASTERN':
      luxonTz = 'US/EASTERN'
      break
    case 'US_HAWAII':
      luxonTz = 'US/EASTERN'
      break
    case 'US/PACIFIC':
      luxonTz = 'US/PACIFIC'
      break
    default:
      break
  }
  return luxonTz
}

export const useInterval = (callback, delay) => {
  const savedCallback = React.useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return undefined
  }, [delay])
}

export const jobTypeToRGBA = (color) => {
  if (color === 'Aqua' || color === 'aqua') {
    return 'rgba(0,255,255, .3)'
  } else if (color === 'Lime' || color === 'lime') {
    return 'rgba(50, 205, 50, .3)'
  } else if (color === 'Red' || color === 'red') {
    return 'rgba(255, 0, 0, .2)'
  } else if (color === 'Black' || color === 'black') {
    return 'rgba(0, 0, 0, .2)'
  } else if (color === 'Blue' || color === 'blue') {
    return 'rgba(3, 138, 255, .2)'
  } else if (color === 'Fuchsia' || color === 'fuchsia') {
    return 'rgba(217,2,125, .2)'
  } else if (color === 'Green' || color === 'green') {
    return 'rgba(0,100,0, .2)'
  } else if (color === 'Maroon' || color === 'maroon') {
    return 'rgba(128,0,0, .2)'
  } else if (color === 'Navy' || color === 'navy') {
    return 'rgba(0, 0, 128, .2)'
  } else if (color === 'Olive' || color === 'olive') {
    return 'rgba(128, 128, 0, .2)'
  } else if (color === 'Orange' || color === 'orange') {
    return 'rgba(255,165,0, .2)'
  } else if (color === 'Purple' || color === 'purple') {
    return 'rgba(128,0,128, .2)'
  } else if (color === 'Silver' || color === 'silver') {
    return 'rgba(192,192,192, .2)'
  } else if (color === 'Teal' || color === 'teal') {
    return 'rgba(0,128,128, .2)'
  } else if (color === 'Yellow' || color === 'yellow') {
    return 'rgba(255,255,0, .2)'
  }
}

export const componentToHex = (c) => {
  var hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}
export const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export const downloadFile = (uri, fileName) => {
  const link = document.createElement('a')
  link.download = fileName
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getStripePaymentMethodResource = (gaiaUser) => {
  let stripePaymentMethodResource

  const stripeCustomer = gaiaUser?.stripeCustomer
  if (stripeCustomer) {
    const stripePaymentMethodNodes = stripeCustomer.stripePaymentMethods
    if (stripePaymentMethodNodes.edges.length > 0) {
      stripePaymentMethodResource = JSON.parse(
        stripePaymentMethodNodes.edges[
          stripePaymentMethodNodes.edges.length - 1
        ].node.stripeResource
      )
    }
  }

  return stripePaymentMethodResource
}
