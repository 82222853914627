import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql, useReactiveVar, useMutation } from '@apollo/client'
import { Row, Col, Table, Form, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import DateFilter from '../common/DateFilter'
import { settingsVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import toast from 'react-hot-toast'
import { CloudArrowDown } from 'react-bootstrap-icons'
import { useDownloadFile } from '../../libs/downloadFile'
import { useDateTimeConverter } from '../../libs/useDateTime'

const AuditLog = (props) => {
  const { apiKeyId, fetchPolicy, apiCallId, settingsNode } = props
  const { toTimezone } = useDateTimeConverter()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [cursor, setCursor] = useState()
  const [events, setEvents] = useState([])
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [hasMoreEvents, setHasMoreEvents] = useState(true)
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const { downloadAndDeleteFile } = useDownloadFile()
  const settings = useReactiveVar(settingsVar)

  const [getSettings] = useLazyQuery(
    gql`
      query PublicSettingsQuery {
        publicSettings {
          id
          name
          tenantId
          airstudioInvoiceRecipient
          chargeProductStateSalesTax
          productTaxRate
          displayLogoInNav
          displayNameInNav
          websiteUrl
          phoneNumber
          auditLogEnabled
          openAiEnabled
          thumbnailImageName
          timezone
          intercomAppId
          showLastNameIdLogin
          tenantActive
          chargeStateSalesTax
          email
          openAiEnabled
          colorScheme
          facebookUrl
          equipmentBarcodes
          equipmentQrCodes
          twitterUrl
          linkedinUrl
          instagramUrl
          instagramInFooter
          facebookInFooter
          twitterInFooter
          linkedinInFooter
          tenantRegions
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        settingsVar(data.publicSettings)
      },
    }
  )

  const [updateSettingsMutation] = useMutation(
    gql`
      mutation UpdateSettings($updateSettingsInput: UpdateSettingsInput!) {
        updateSettings(input: $updateSettingsInput) {
          settings {
            id
            auditLogEnabled
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        getSettings()
        if (data.updateSettings.settings?.auditLogEnabled) {
          toast.success('History Enabled')
        } else {
          toast.success('History Disabled')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['PublicSettingsQuery', 'SettingsQuery', 'LogEntries'],
    }
  )

  const [downloadLogEntries] = useMutation(
    gql`
      mutation DownloadLogEntries($input: DownloadLogEntriesInput!) {
        downloadLogEntries(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadLogEntries.file.fileName,
          data.downloadLogEntries.file.displayName,
          data.downloadLogEntries.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query LogEntries(
        $cursor: String
        $searchTerm: String
        $startDateTimeGte: DateTime
        $startDateTimeLte: DateTime
        $apiKey: String
        $apiCall: String
      ) {
        logEntries(
          first: 50
          after: $cursor
          timestamp_Gte: $startDateTimeGte
          timestamp_Lte: $startDateTimeLte
          objectRepr_Icontains: $searchTerm
          apiKey: $apiKey
          apiCall: $apiCall
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              timestamp
              objectPk
              changes
              action
              objectRepr
              apiCalls {
                edges {
                  node {
                    id
                    apiKey {
                      name
                    }
                  }
                }
              }
              actor {
                fullName
                email
                subject {
                  id
                }
                employee {
                  id
                }
                organizationContacts {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
              contentType {
                model
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: fetchPolicy ? fetchPolicy : 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      const variables = {}
      if (apiKeyId) {
        variables.apiKey = apiKeyId
      }
      if (apiCallId) {
        variables.apiCall = apiCallId
      }
      setInitialQueryRun(true)
      query({ variables })
    }
  }, [initialQueryRun, query])

  useEffect(() => {
    if (queryData?.logEntries) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      if (queryData?.logEntries?.pageInfo) {
        setCursor(queryData.logEntries.pageInfo.endCursor)
        setHasMoreEvents(queryData.logEntries.pageInfo.hasNextPage)
      }
      setEvents(queryData.logEntries.edges)
    }
  }, [queryData])

  const fetchMoreEvents = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (apiKeyId) {
      variables.apiKey = apiKeyId
    }
    if (apiCallId) {
      variables.apiCall = apiCallId
    }
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        setCursor(fetchMoreResult.logEntries.pageInfo.endCursor)
        setHasMoreEvents(fetchMoreResult.logEntries.pageInfo.hasNextPage)
        setEvents((prevEvents) => [
          ...prevEvents,
          ...fetchMoreResult.logEntries.edges,
        ])
        return prev
      },
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      setCursor(null)
      const variables = {
        cursor: null,
        searchTerm,
        startDateTimeGte: startDateFilter,
        startDateTimeLte: endDateFilter,
        apiKey: apiKeyId,
        apiCall: apiCallId,
      }
      query({ variables })
    }
  }, [startDateFilter, endDateFilter, query, initialQueryRun])

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = {
        cursor: null, // Reset cursor for fresh search
        searchTerm: searchValue || undefined,
        startDateTimeGte: startDateFilter,
        startDateTimeLte: endDateFilter,
        apiKey: apiKeyId,
        apiCall: apiCallId,
      }
      setLoadingSearch(true)
      query({ variables })
    },
    [startDateFilter, endDateFilter, apiKeyId, apiCallId, query]
  )

  const handleSearchTermChange = useCallback(
    (event) => {
      const currentSearchTerm = event.target.value || undefined
      setSearchTerm(currentSearchTerm)

      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      const newTimeout = setTimeout(() => {
        executeSearchQuery(currentSearchTerm)
      }, 500) // 500ms delay

      setDebounceTimeout(newTimeout)
    },
    [executeSearchQuery, debounceTimeout]
  )

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  if ((!initialQueryRun && !queryData) || !settings)
    return <Loading message="Loading History..." />
  if (queryError) return <>Error loading audit log</>

  return (
    <>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              size="sm"
              type="text"
              name="searchTerm"
              placeholder={'Search Records'}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        <Col md={'auto'} style={{ marginTop: '-8px' }}>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="History From"
            placeholderEnd={'History Until'}
          />
        </Col>
        {((startDateFilter && endDateFilter) || searchTerm) && (
          <Col className="d-flex justify-content-end align-items-center">
            <Button
              variant="link"
              disabled={downloadingPdf}
              onClick={() => {
                setDownloadingPdf(true)
                downloadLogEntries({
                  variables: {
                    input: {
                      startDate: startDateFilter
                        ? startDateFilter.toISO()
                        : null,
                      endDate: endDateFilter ? endDateFilter.toISO() : null,
                      objectRepr: searchTerm,
                      apiKey: apiKeyId || null,
                      apiCall: apiCallId || null,
                    },
                  },
                })
              }}
            >
              {downloadingPdf && <Loading />}
              {!downloadingPdf && (
                <>
                  <CloudArrowDown className="mr-2" />
                  Download PDF
                </>
              )}
            </Button>
          </Col>
        )}
      </Row>
      <Row className="mt-4">
        <Col>
          <SortableInfiniteTable
            hideGlobalFilter
            loading={loadingSearch}
            loadingMessage="Loading History..."
            tableColumns={[
              {
                Header: 'Time',
                accessor: (row) => {
                  return toTimezone(row.node.timestamp, {
                    standard: true,
                    timezone: settings.timezone,
                  })
                },
              },
              {
                Header: 'Record Type',
                accessor: (row) => {
                  return row.node.contentType?.model === 'gaiauser'
                    ? 'user'
                    : row.node.contentType?.model
                },
              },
              {
                Header: 'Record',
                accessor: 'node.objectRepr',
              },
              {
                Header: 'Action',
                accessor: (row) => {
                  let action
                  if (row.node.action.includes('0')) {
                    action = 'Create'
                  } else if (row.node.action.includes('1')) {
                    action = 'Update'
                  } else if (row.node.action.includes('2')) {
                    action = 'Delete'
                  } else if (row.node.action.includes('3')) {
                    action = 'Access'
                  }
                  return action
                },
              },
              {
                Header: 'User',
                accessor: (row) => {
                  if (row.node.actor?.fullName) {
                    return row.node.actor?.fullName
                  } else if (row.node?.apiCalls?.edges.length > 0) {
                    return row.node.apiCalls.edges[0].node.apiKey.name
                  }
                },
              },
              {
                Header: 'User Type',
                accessor: (row) => {
                  let type
                  if (row.node.actor?.employee?.id) {
                    type = 'Employee'
                  } else if (row.node.actor?.subject?.id) {
                    type = 'Subject'
                  } else if (
                    row.node.actor?.organizationContacts?.edges?.length > 0
                  ) {
                    type = 'Organization Contact'
                  } else if (row.node?.apiCalls?.edges.length > 0) {
                    type = 'API'
                  } else {
                    type = 'System'
                  }
                  return type
                },
              },
              {
                Header: 'Changes',
                accessor: (row) => {
                  if (row.node.changes) {
                    let action
                    if (row.node.action.includes('0')) {
                      action = 'Create'
                    } else if (row.node.action.includes('1')) {
                      action = 'Update'
                    } else if (row.node.action.includes('2')) {
                      action = 'Delete'
                    } else if (row.node.action.includes('3')) {
                      action = 'Access'
                    }
                    if (action === 'Create') {
                      return (
                        <Table>
                          <thead>
                            <tr>
                              <th>Field</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(JSON.parse(row.node.changes)).map(
                              (values, i) => {
                                let field = values[0]
                                if (field === 'gaia_user') {
                                  field = 'user'
                                }
                                return (
                                  <tr key={i}>
                                    <td>{field}</td>
                                    <td>{values[1][1]}</td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </Table>
                      )
                    } else if (action === 'Update') {
                      return (
                        <Table>
                          <thead>
                            <tr>
                              <th>Field</th>
                              <th>Initial</th>
                              <th>Update</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(JSON.parse(row.node.changes)).map(
                              (values, i) => {
                                let field = values[0]
                                if (field === 'gaia_user') {
                                  field = 'user'
                                }
                                return (
                                  <tr key={i}>
                                    <td>{field}</td>
                                    <td>{values[1][0]}</td>
                                    <td>{values[1][1]}</td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </Table>
                      )
                    }
                  }
                },
              },
            ]}
            tableData={events}
            fetchMoreTableData={fetchMoreEvents}
            hasMoreTableData={hasMoreEvents}
            tableHeight={800}
          />
        </Col>
      </Row>
    </>
  )
}

export default AuditLog
