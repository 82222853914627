import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap'
import { Globe, PlusCircle } from 'react-bootstrap-icons'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'
import { formatTimezone, formatRegion } from '../../../libs/utils'
import { loggedInUserVar, updateDefaultRegions } from '../../../libs/apollo'
import toast from 'react-hot-toast'

const DefaultRegionsModal = (props) => {
  const { showModal, toggleModal } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [checkedRegions, setCheckedRegions] = useState(
    loggedInUser?.defaultRegions || []
  )

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore, loading },
  ] = useLazyQuery(
    gql`
      query Regions($cursor: String) {
        regions(first: 50, after: $cursor, orderBy: "name") {
          nodeCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              name
              timezone
              default
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.regions?.pageInfo?.endCursor,
    }
    queryFetchMore({ variables })
  }
  const handleSetDefaultRegions = () => {
    if (checkedRegions.length > 0) {
      const sortedRegions = checkedRegions.sort((a, b) =>
        a.region.name.localeCompare(b.region.name)
      )
      updateDefaultRegions(sortedRegions)
      toggleModal()
      toast.success('Default Regions Saved')
    }
  }

  useEffect(() => {
    if (showModal) {
      setCheckedRegions(loggedInUser?.defaultRegions || [])
      query()
    }
  }, [showModal])

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedRegions((prevState) => [
        ...prevState,
        {
          id: row.node.id,
          description: formatRegion(row.node),
          region: row.node,
        },
      ])
    } else if (checkedRegions?.length > 1) {
      setCheckedRegions((prevState) =>
        prevState.filter((region) => region.id !== row.node.id)
      )
    }
  }

  if (!showModal) return <></>
  if (queryError) {
    return (
      <Modal size={'md'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Globe className="mr-2" />
            Default Regions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-2 mb-3">
            <Col md="6">Error loading</Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
  return (
    <div className="defaultRegionsModal">
      <Modal size={'md'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Globe className="mr-2" />
            Default Regions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-2 mb-3">
            <Col>
              <SortableInfiniteTable
                loading={loading || !queryData}
                tableData={queryData?.regions?.edges || []}
                loadingMessage="Loading Regions..."
                tableColumns={[
                  {
                    Header: 'Name',
                    id: 'name',
                    accessor: 'node.name',
                    disableSortBy: true,
                  },
                  {
                    Header: 'Timezone',
                    id: 'timezone',
                    accessor: (row) => <>{formatTimezone(row.node.timezone)}</>,
                    disableSortBy: true,
                  },
                  {
                    Header: 'Default',
                    id: 'default',
                    disableSortBy: true,
                    accessor: (row) => {
                      const checked = checkedRegions.find(
                        (region) => region.id == row.node.id
                      )
                      return (
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            disabled={checkedRegions?.length <= 1 && checked}
                            checked={checked}
                            onChange={(e) => handleCheck(e, row)}
                          />
                        </Form.Group>
                      )
                    },
                  },
                ]}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={queryData?.regions?.pageInfo?.hasNextPage}
                tableHeight={queryData?.regions?.edges?.length > 4 ? 400 : 200}
                rowPointer
                hideGlobalFilter
              />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <Button
                type="submit"
                variant="outline-primary"
                block
                onClick={handleSetDefaultRegions}
              >
                <PlusCircle className="mr-2" />
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DefaultRegionsModal
