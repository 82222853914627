import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql, useQuery, useMutation } from '@apollo/client'
import { Row, Col, Form, Tab, Button, ButtonGroup } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import OrganizationModal from './OrganizationModal'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { useFormik } from 'formik'
import {
  Buildings,
  Trash,
  ArrowRepeat,
  CloudArrowDown,
  PersonLinesFill,
  PlusCircle,
  Dot,
  Globe,
} from 'react-bootstrap-icons'
import Contacts from './Contacts'
import Page from '../layout/Page'
import Tabs from '../common/Tabs'
import DeleteOrganizationModal from './DeleteOrganizationModal'
import {
  fotomerchantClientAdminUrl,
  useFotomerchantEnabled,
} from '../../libs/fotomerchant'
import CopyTriggerModal from '../notifications/CopyTriggerModal'
import StatusCircle from '../common/StatusCircle'
import toast from 'react-hot-toast'
import { useDownloadFile } from '../../libs/downloadFile'
import OrganizationStageSearchInput from '../common/node_search_input/OrganizationStageSearchInput'
import TagSearchInput from '../common/node_search_input/TagSearchInput'
import OrganizationCsvModal from './OrganizationCsvModal'
import OrganizationRegions from './OrganizationRegions'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import { formatRegion } from '../../libs/utils'
import Loading from '../common/Loading'

const Organizations = () => {
  const { contact } = useParams()
  const history = useHistory()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [activeTab, setActiveTab] = useState('Organizations')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showDeleteOrgModal, setShowDeleteOrgModal] = useState(false)
  const [showSyncNotificationsModal, setShowSyncNotificationsModal] =
    useState(false)
  const [checkedOrganizationIds, setCheckedOrganizationIds] = useState([])
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const defaultOrderBy = 'name'
  const [hasMoreOrganizations, setHasMoreOrganizations] = useState(true)
  const [cursor, setCursor] = useState()
  const [newOrgIsSubmitting, setNewOrgIsSubmitting] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [showOrgModal, setShowOrgModal] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [showImportOrganizationModal, setShowImportOrganizationModal] =
    useState(false)
  const [pollInterval, setPollInterval] = useState(false)
  const fotomerchantEnabled = useFotomerchantEnabled()
  const { downloadAndDeleteFile } = useDownloadFile()

  const formik = useFormik({
    initialValues: {
      tags: [],
      regions: loggedInUser?.defaultRegions,
      organizationStageId: '',
      organizationStageDescription: '',
    },
    validateOnChange: false,
  })

  useEffect(() => {
    formik.setFieldValue('regions', loggedInUser?.defaultRegions)
  }, [loggedInUser?.defaultRegions])

  const { data: settingsData } = useQuery(
    gql`
      query OrganizationSettingsQuery {
        settings {
          edges {
            node {
              id
              syncingOrganizationNotifications
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 2000,
    }
  )
  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedOrganizationIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedOrganizationIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
      serverSort: true,
    },
    {
      Header: 'Stage',
      id: 'stage',
      accessor: (cell) => {
        if (cell?.node?.organizationStage?.name) {
          return cell.node.organizationStage.name
        }
      },
      serverSort: true,
      orderBy: 'organization_stage__name',
    },
    {
      Header: 'Category',
      id: 'category',
      accessor: 'category',
      serverSort: true,
    },
    {
      Header: 'Account Manager',
      id: 'acctManager',
      accessor: 'acctManager',
      serverSort: true,
      orderBy: 'account_manager',
    },
    {
      Header: 'Subject Groups',
      id: 'subjectGroupCount',
      accessor: 'subjectGroupCount',
    },
    {
      Header: 'Subjects',
      id: 'subjectCount',
      accessor: 'subjectCount',
    },
  ]

  if (loggedInUser?.canManageRegions && settings?.tenantRegions) {
    tableColumns.push({
      Header: 'Region',
      id: 'region',
      serverSort: true,
      orderBy: 'organization_regions__region__name',
      accessor: (row) => {
        if (row.node.organizationRegions.edges.length > 0) {
          return (
            <>
              <div
                style={{
                  maxHeight: '75px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  fontSize: '14px',
                }}
                className="mt-2"
              >
                {row.node.organizationRegions.edges.map((region) => {
                  return (
                    <>
                      {formatRegion(region?.node?.region)}
                      {region?.node?.default && <span> (Default)</span>}
                      <br />
                    </>
                  )
                })}
              </div>
            </>
          )
        }
      },
    })
  }
  if (canMutate) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2 form-control-sm"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  organizations.forEach((org) => {
                    if (!checkedOrganizationIds.includes(org.node.id)) {
                      appendIds.push(org.node.id)
                    }
                  })
                  setCheckedOrganizationIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedOrganizationIds([])
                }
              }}
            />
            {checkedOrganizationIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedOrganizationIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2 form-control-sm"
                type="checkbox"
                checked={checkedOrganizationIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }
  if (fotomerchantEnabled && !contact) {
    tableColumns.splice(5, 0, {
      Header: 'Fotomerchant',
      id: 'fotomerchantSubject',
      accessor: (row) => {
        if (row.node?.fotomerchantClient) {
          return (
            <>
              <Button variant="link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={fotomerchantClientAdminUrl(
                    row.node.fotomerchantClient.fotomerchantId
                  )}
                >
                  <img
                    className="mr-2"
                    src={window.location.origin + '/fotomerchant.svg'}
                    style={{ height: '20px' }}
                    alt="Organization logo"
                  />
                  <span style={{ fontSize: '14px' }}>Client</span>
                </a>
              </Button>
            </>
          )
        }
      },
    })
  }

  if (!contact && !loggedInUser.permissions.isSubject) {
    tableColumns.splice(tableColumns.length - 1, 0, {
      Header: 'Tags',
      id: 'tags',
      accessor: (row) => {
        return (
          <>
            {row.node.tags.map((tag) => (
              <>
                <Dot className="mr-2" />
                {tag.name}
                <br />
              </>
            ))}
          </>
        )
      },
    })
  }
  if (contact) {
    tableColumns.splice(1, 1)
  }
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Organizations(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $stage: ID
        $tags: String
        $regions: String
      ) {
        organizations(
          first: 50
          after: $cursor
          search_Icontains: $searchTerm
          orderBy: $orderBy
          organizationStage: $stage
          tags: $tags
          regions: $regions
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              category
              organizationStage {
                color
                name
              }
              fotomerchantClient {
                fotomerchantId
              }
              subjects {
                nodeCount
              }
              accountManager {
                gaiaUser {
                  id
                  fullName
                }
              }
              subjectGroups {
                nodeCount
              }
              organizationRegions {
                nodeCount
                edges {
                  node {
                    region {
                      id
                      name
                      timezone
                    }
                    default
                  }
                }
              }
              tags {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: pollInterval ? 3000 : null,
    }
  )

  const [contactQuery, { error: contactQueryError, data: contactQueryData }] =
    useLazyQuery(
      gql`
        query ContactOrganizations($id: ID!) {
          gaiaUser(id: $id) {
            organizationContacts {
              nodeCount
              edges {
                node {
                  id
                  name
                  category
                  subjects {
                    nodeCount
                  }
                  accountManager {
                    gaiaUser {
                      id
                      fullName
                    }
                  }
                  subjectGroups {
                    nodeCount
                  }
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        // pollInterval: 30000,
        variables: {
          id: loggedInUser.id,
        },
      }
    )

  const constructQueryVariables = () => {
    const variables = {}
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (formik.values.organizationStageId) {
      variables.stage = formik.values.organizationStageId
    }
    if (formik.values.tags.length > 0) {
      variables.tags = formik.values.tags
        .map((tag) => tag.description)
        .join(',')
    }
    if (formik.values.regions.length > 0) {
      variables.regions = formik.values.regions
        .map((region) => region.id)
        .join(',')
    }

    return variables
  }

  const executeSearchQuery = useCallback(
    (searchValue) => {
      setCursor(null)
      setLoadingSearch(true)
      const variables = {
        searchTerm: searchValue,
      }
      if (orderBy) {
        variables.orderBy = orderBy
      }
      if (formik.values.regions.length > 0) {
        variables.regions = formik.values.regions
          .map((region) => region.id)
          .join(',')
      }
      if (formik.values.organizationStageId) {
        variables.stage = formik.values.organizationStageId
      }
      if (formik.values.tags.length > 0) {
        variables.tags = formik.values.tags
          .map((tag) => tag.description)
          .join(',')
      }
      query({ variables })
    },
    [
      orderBy,
      formik.values.organizationStageId,
      formik.values.tags,
      query,
      formik.values.regions,
    ]
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      if (contact) {
        contactQuery()
      } else {
        const variables = constructQueryVariables()
        variables.orderBy = defaultOrderBy
        query({ variables })
      }
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.organizations) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      if (queryData.organizations.pageInfo.endCursor) {
        setCursor(queryData.organizations.pageInfo.endCursor)
      }
      setHasMoreOrganizations(queryData.organizations.pageInfo.hasNextPage)
      const currentOrganizations = queryData.organizations.edges.map(
        (organization) => ({
          name: organization.node.name,
          node: organization.node,
          acctManager: organization.node.accountManager?.gaiaUser.fullName ?? (
            <></>
          ),
          category:
            organization.node.category.charAt(0).toUpperCase() +
            organization.node.category.slice(1).toLowerCase(),
          subjectGroupCount: organization.node.subjectGroups.nodeCount,
          subjectCount: organization.node.subjects.nodeCount,
          id: organization.node.id,
          tags: organization.node.tags,
        })
      )
      setOrganizations(currentOrganizations)
    }
  }, [queryData])

  useEffect(() => {
    if (contactQueryData) {
      setHasMoreOrganizations(false)
      const currentOrganizations =
        contactQueryData.gaiaUser.organizationContacts.edges.map(
          (organization) => ({
            name: organization.node.name,
            acctManager: organization.node.accountManager?.gaiaUser
              .fullName ?? <span className="text-secondary">- - -</span>,
            category:
              organization.node.category.charAt(0).toUpperCase() +
              organization.node.category.slice(1).toLowerCase(),
            subjectGroupCount: organization.node.subjectGroups.nodeCount,
            subjectCount: organization.node.subjects.nodeCount,
            id: organization.node.id,
          })
        )
      setOrganizations(currentOrganizations)
    }
  }, [contactQueryData])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    variables.cursor = cursor
    queryFetchMore({ variables })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const newTimeout = setTimeout(() => {
      executeSearchQuery(currentSearchTerm)
    }, 500)

    setDebounceTimeout(newTimeout)
  }

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return
    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = constructQueryVariables()
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  const openOrganization = (cell) => {
    if (contact) {
      history.push(`/organization/${cell.row.original.id}/contact`)
    } else {
      history.push(`/organization/${cell.row.original.id}`)
    }
  }

  const [downloadSubjects] = useMutation(
    gql`
      mutation DownloadOrganizations($input: DownloadOrganizationsInput!) {
        downloadOrganizations(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadOrganizations.file.fileName,
          data.downloadOrganizations.file.displayName,
          data.downloadOrganizations.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )
  const orgModalShowChange = () => {
    setShowOrgModal((prevShowOrgModal) => !prevShowOrgModal)
  }

  useEffect(() => {
    if (
      formik.values.organizationStageId ||
      formik.values?.tags ||
      formik.values?.regions
    ) {
      setLoadingSearch(true)
      const variables = constructQueryVariables()
      query({ variables })
    }
  }, [
    formik.values.organizationStageId,
    formik.values?.tags,
    formik.values?.regions,
  ])

  if (queryError || contactQueryError) return <>Error loading organization</>
  const titleComponent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <h1 className="mb-0" style={{ marginRight: '10px' }}>
        Organizations
      </h1>
      {settingsData?.settings.edges[0].node
        .syncingOrganizationNotifications && (
        <span style={{ marginTop: '5px' }}>
          <StatusCircle message="Syncing Notifications" />
        </span>
      )}
    </div>
  )

  return (
    <>
      <CopyTriggerModal
        showModal={showSyncNotificationsModal}
        toggleModal={() => {
          setShowSyncNotificationsModal(false)
        }}
        all={true}
        postSubmit={() => {
          setCheckedOrganizationIds([])
        }}
        organizationIds={checkedOrganizationIds}
      />
      <Page titleComponent={titleComponent}>
        <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
          <Tab
            key="Organizations"
            eventKey="Organizations"
            style={{ marginTop: '10px' }}
            title={
              <b>
                <Buildings className="mr-1" />
                Organizations
              </b>
            }
          >
            {!contact && (
              <>
                <Row className="mt-2 mb-2">
                  {canMutate && (
                    <Col>
                      <Button variant="link" onClick={orgModalShowChange}>
                        <PlusCircle className="mr-2" />
                        New Organization
                      </Button>
                      <Button
                        variant="link"
                        onClick={() => setShowImportOrganizationModal(true)}
                      >
                        <PlusCircle className="mr-2" />
                        Import Organizations
                      </Button>
                      <Button
                        variant="link"
                        disabled={downloadingPdf || downloadingExcel}
                        onClick={() => {
                          setDownloadingPdf(true)
                          downloadSubjects({
                            variables: {
                              input: {
                                organizationIds: checkedOrganizationIds,
                                fileType: 'pdf',
                              },
                            },
                          })
                        }}
                      >
                        {downloadingPdf && <Loading height={'20'} />}
                        {!downloadingPdf && (
                          <>
                            <CloudArrowDown className="mr-2" />
                            Download PDF
                          </>
                        )}
                      </Button>
                      <Button
                        variant="link"
                        disabled={downloadingExcel || downloadingPdf}
                        onClick={() => {
                          setDownloadingExcel(true)
                          downloadSubjects({
                            variables: {
                              input: {
                                organizationIds: checkedOrganizationIds,
                                fileType: 'xlsx',
                              },
                            },
                          })
                        }}
                      >
                        {downloadingExcel && <Loading height={'20'} />}
                        {!downloadingExcel && (
                          <>
                            <CloudArrowDown className="mr-2" />
                            Download Excel
                          </>
                        )}
                      </Button>

                      {checkedOrganizationIds.length > 0 && (
                        <>
                          <Button
                            variant="link"
                            onClick={setShowDeleteOrgModal}
                          >
                            <Trash className="mr-2" />
                            {checkedOrganizationIds.length === 1 ? (
                              <>Delete Organization</>
                            ) : (
                              <>Delete Organizations</>
                            )}
                          </Button>
                          <Button
                            variant="link"
                            onClick={setShowSyncNotificationsModal}
                          >
                            <ArrowRepeat className="mr-2" />
                            Sync Notifications
                          </Button>
                        </>
                      )}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="searchTerm"
                        className="form-control-sm"
                        placeholder={'Search Organizations'}
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <TagSearchInput
                      formik={formik}
                      multiple
                      placeholder="Select Tags"
                      dropdown
                    />
                  </Col>
                  <Col md={2}>
                    <OrganizationStageSearchInput
                      formik={formik}
                      dropdown
                      placeholder="All Stages"
                    />
                  </Col>
                  {loggedInUser?.canManageRegions &&
                    settings?.tenantRegions && (
                      <Col md={2}>
                        <RegionSearchInput
                          formik={formik}
                          dropdown
                          multiple
                          placeholder="Select Region"
                          mustHaveValue={
                            loggedInUser.permissions.group === 'Administrator'
                              ? false
                              : true
                          }
                        />
                      </Col>
                    )}
                </Row>
              </>
            )}
            <Row className="mt-2 mb-3">
              <Col>
                <SortableInfiniteTable
                  tableData={organizations}
                  loading={loadingSearch}
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMore}
                  hasMoreTableData={hasMoreOrganizations}
                  trStyleGenerator={(row) => {
                    const style = {
                      cursor: 'pointer',
                    }
                    if (row.original.node?.organizationStage?.color) {
                      style.backgroundColor =
                        row.original.node?.organizationStage?.color
                    }
                    return style
                  }}
                  loadingMessage="Loading Organizations..."
                  onTdClicks={{
                    name: (row) => openOrganization(row),
                    stage: (row) => openOrganization(row),
                    category: (row) => openOrganization(row),
                    acctManager: (row) => openOrganization(row),
                    subjectGroupCount: (row) => openOrganization(row),
                    subjectCount: (row) => openOrganization(row),
                    region: (row) => openOrganization(row),
                  }}
                  tableHeight={700}
                  rowPointer
                  hideGlobalFilter
                  handleSortByChange={handleSortByChange}
                />
              </Col>
            </Row>
          </Tab>
          {!contact && (
            <Tab
              key="Contacts"
              eventKey="Contacts"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <PersonLinesFill className="mr-1" />
                  Contacts
                </b>
              }
            >
              <div className="mt-4">
                <Contacts />
              </div>
            </Tab>
          )}
          {loggedInUser?.canManageRegions && settings?.tenantRegions && (
            <Tab
              key="OrganizationRegions"
              eventKey="OrganizationRegions"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Globe className="mr-1" />
                  Regions
                </b>
              }
            >
              <OrganizationRegions />
            </Tab>
          )}
        </Tabs>
      </Page>
      {showOrgModal && (
        <OrganizationModal
          showOrgModal={showOrgModal}
          orgModalShowChange={orgModalShowChange}
          updatingOrg={false}
          setIsSubmitting={setNewOrgIsSubmitting}
          isSubmitting={newOrgIsSubmitting}
        />
      )}
      <DeleteOrganizationModal
        organizationIds={
          checkedOrganizationIds.length > 1 ? checkedOrganizationIds : null
        }
        organizationId={
          checkedOrganizationIds.length === 1 ? checkedOrganizationIds[0] : null
        }
        setCheckedOrganizationIds={setCheckedOrganizationIds}
        showModal={showDeleteOrgModal}
        toggleModal={setShowDeleteOrgModal}
      />
      <OrganizationCsvModal
        showModal={showImportOrganizationModal}
        toggleModal={setShowImportOrganizationModal}
        setPollInterval={setPollInterval}
      />
    </>
  )
}
export default Organizations
