import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql, useMutation, useReactiveVar } from '@apollo/client'
import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  Table,
  Dropdown,
} from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import {
  QrCode,
  FileEarmarkText,
  Trash,
  Tag,
  Funnel,
  CloudArrowDown,
  PlusCircle,
  Dot,
  Upc,
} from 'react-bootstrap-icons'
import BagModal from './BagModal'
import toast from 'react-hot-toast'
import DeleteBagModal from './DeleteBagModal'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { useDownloadFile } from '../../../libs/downloadFile'
import ScanModal from '../ScanModal'
import EmployeeAssignmentsModal from '../../schedule/job/EmployeeAssignmentsModal'
import { useDateTimeConverter } from '../../../libs/useDateTime'
import { formatRegion, formatTimezone } from '../../../libs/utils'
import { useFormik } from 'formik'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'

const Bags = (props) => {
  const { employeeId, employeeName, equipmentBagTypeId, tableHeight } = props
  const { toTimezone } = useDateTimeConverter()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editBag, setEditBag] = useState()
  const [downloading, setDownloading] = useState(false)
  const [checkedBagIds, setCheckedBags] = useState([])
  const [downloadingLabels, setDownloadingLabels] = useState(false)
  const [openScanModal, setOpenScanModel] = useState(false)
  const [scannedBags, setScannedBags] = useState([])
  const [scannedItems, setScannedItems] = useState([])
  const [employeeFilter, setEmployeeFilter] = useState('Current')
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [filterPastDue, setFilterPastDue] = useState(false)
  const [employeeAssignmentsModal, setEmployeeAssignmentsModal] =
    useState(false)
  const [filterReturned, setFilterReturned] = useState('all')
  const { downloadAndDeleteFile, downloadFile } = useDownloadFile()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query EquipmentBags(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $pastDue: Boolean
        $currentAssignmentsEmployeeId: String
        $employeeAssignments: String
        $returned: Boolean
        $equipmentBagType: ID
        $regionIds: [ID]
        $includeQrCode: Boolean!
        $includeBarcode: Boolean!
      ) {
        equipmentBags(
          first: 20
          after: $cursor
          returned: $returned
          search: $searchTerm
          pastDue: $pastDue
          currentEmployeeAssignments: $currentAssignmentsEmployeeId
          employeeAssignments: $employeeAssignments
          orderBy: $orderBy
          equipmentBagType: $equipmentBagType
          region: $regionIds
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              contentType {
                model
                id
              }
              employeeEmployeeJobs {
                job {
                  id
                  name
                  region {
                    timezone
                  }
                }
                startDateTime
                endDateTime
              }
              returned
              notes
              equipmentCategory {
                id
                name
              }
              dueDate
              ...qrCodeFields @include(if: $includeQrCode)
              ...barcodeFields @include(if: $includeBarcode)
              metadata
              equipmentBagType {
                id
                name
                equipmentItemTypesList {
                  id
                  name
                  miscellaneous
                }
                equipmentItemTypes {
                  edges {
                    node {
                      id
                      name
                      miscellaneous
                      quantityAvailable
                    }
                  }
                }
              }
              nextEmployeeJob {
                job {
                  id
                  name
                  region {
                    timezone
                    name
                  }
                }
                startDateTime
                endDateTime
                employee {
                  id
                  gaiaUser {
                    fullName
                  }
                }
              }
              lastEmployeeJob {
                job {
                  id
                  name
                }
                startDateTime
                endDateTime
                employee {
                  id
                  gaiaUser {
                    fullName
                  }
                }
              }
              employee {
                gaiaUser {
                  fullName
                }
                id
              }
              region {
                id
                name
                timezone
              }
              equipmentItems {
                edges {
                  node {
                    id
                    name
                    serialNumber
                    equipmentBag {
                      id
                      name
                    }
                    equipmentItemType {
                      name
                      id
                    }
                  }
                }
              }
              itemTypes
            }
          }
        }
      }

      fragment qrCodeFields on EquipmentBagNode {
        qrCodeFile {
          fileName
          displayName
          fileUrl
        }
        qrCodeLabelFile {
          fileName
          displayName
          fileUrl
        }
      }

      fragment barcodeFields on EquipmentBagNode {
        barcodeFile {
          fileName
          displayName
          fileUrl
        }
        barcodeLabelFile {
          fileName
          displayName
          fileUrl
        }
      }
    `,
    {
      fetchPolicy: equipmentBagTypeId ? 'no-cache' : 'network-only',
      errorPolicy: 'all',
    }
  )

  const determineReturnedVariable = () => {
    let returned
    if (filterReturned === 'checkedOut') {
      returned = false
    } else if (filterReturned === 'checkedIn') {
      returned = true
    }
    return returned
  }

  const formik = useFormik({
    initialValues: {
      regions: loggedInUser?.defaultRegions,
    },
    validateOnChange: false,
  })

  const constructQueryVariables = useCallback(() => {
    const variables = {
      employeeId,
      returned: determineReturnedVariable(),
      cursor: queryData?.equipmentBags?.pageInfo?.endCursor,
      includeQrCode: settings.equipmentQrCodes,
      includeBarcode: settings.equipmentBarcodes,
    }

    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (equipmentBagTypeId) {
      variables.equipmentBagType = equipmentBagTypeId
    }
    if (formik.values.regions.length > 0) {
      variables.regionIds = formik.values.regions.map((region) => region.id)
    }
    if (filterPastDue) {
      variables.pastDue = true
    }
    if (employeeId) {
      if (employeeFilter === 'Current') {
        variables.currentAssignmentsEmployeeId = employeeId
      } else if (employeeFilter === 'All') {
        variables.employeeAssignments = employeeId
      }
    }

    return variables
  }, [
    employeeId,
    determineReturnedVariable,
    queryData?.equipmentBags?.pageInfo?.endCursor,
    settings.equipmentQrCodes,
    settings.equipmentBarcodes,
    searchTerm,
    orderBy,
    equipmentBagTypeId,
    formik.values.regions,
    filterPastDue,
    employeeFilter,
  ])

  useEffect(() => {
    formik.setFieldValue('regions', loggedInUser?.defaultRegions)
  }, [loggedInUser?.defaultRegions])

  useEffect(() => {
    if (initialQueryRun) {
      const variables = constructQueryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterReturned, employeeFilter])

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = constructQueryVariables()
      variables.searchTerm = searchValue || undefined
      variables.cursor = null
      setLoadingSearch(true)
      query({ variables })
    },
    [constructQueryVariables, query]
  )

  const [downloadBagsPdf] = useMutation(
    gql`
      mutation DownloadEquipmentBag($input: DownloadEquipmentBagInput!) {
        downloadEquipmentBag(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadEquipmentBag?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadEquipmentBag.file.fileName,
            data.downloadEquipmentBag.file.displayName,
            data.downloadEquipmentBag.file.id,
            () => {
              setDownloading(false)
              if (downloadingLabels) {
                toast.success(`QR Labels Downloaded`)
                setDownloadingLabels(false)
              } else {
                toast.success(`Bags Downloaded`)
              }
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = constructQueryVariables()
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.equipmentBags) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    queryFetchMore({ variables })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value || undefined
    setSearchTerm(currentSearchTerm)

    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const newTimeout = setTimeout(() => {
      executeSearchQuery(currentSearchTerm)
    }, 500) // 1 second delay

    setDebounceTimeout(newTimeout)
  }

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = constructQueryVariables()
    variables.cursor = null
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  useEffect(() => {
    setLoadingSearch(true)
    const variables = constructQueryVariables()
    variables.cursor = null
    query({ variables })
  }, [formik.values.regions])

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditBag(node)
    } else {
      setEditBag()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleBagCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedBags((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedBags((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  useEffect(() => {
    const variables = constructQueryVariables()
    variables.cursor = null
    if (filterPastDue) {
      variables.pastDue = true
    } else {
      variables.pastDue = null
    }
    query({ variables })
  }, [filterPastDue])

  const toggleScanModal = () => {
    setOpenScanModel((prevModal) => !prevModal)
    setScannedBags([])
    setScannedItems([])
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
      serverSort: true,
    },
    {
      Header: 'Bag Type',
      id: 'bagType',
      accessor: (row) => {
        if (row.node.equipmentBagType) {
          return row.node.equipmentBagType.name
        }
      },
      serverSort: true,
      orderBy: 'equipment_bag_type__name',
    },
    {
      Header: 'Checked In',
      id: 'returned',
      accessor: (row) => {
        return row.node.returned ? 'Yes' : 'No'
      },
      serverSort: true,
    },
    {
      Header: 'Long Term Assignment',
      id: 'longTermAssignment',
      accessor: (row) => {
        if (row.node.employee) {
          return row.node.employee.gaiaUser.fullName
        }
      },
      serverSort: true,
      orderBy: 'employee__gaia_user__full_name',
    },
    {
      Header: 'Items',
      id: 'items',
      accessor: (row) => {
        if (row.node.equipmentItems.edges.length > 0) {
          return (
            <div
              style={{
                maxHeight: '75px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                fontSize: '12px',
              }}
              className="mt-2"
            >
              <Table className="compact-table table-bordered" responsive>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {row.node.equipmentItems.edges.map((edge, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {edge.node.name}
                          {edge.node.serialNumber && (
                            <> #{edge.node.serialNumber}</>
                          )}
                        </td>
                        <td>
                          {edge.node.equipmentItemType && (
                            <>{edge.node.equipmentItemType.name}</>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )
        }
      },
    },
    {
      Header: 'Custom Fields',
      id: 'customFields',
      accessor: (row) => {
        const metadata = JSON.parse(row.node.metadata)
        if (Object.keys(metadata).length > 0) {
          return (
            <div
              style={{
                maxHeight: '75px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                fontSize: '12px',
              }}
              className="mt-2"
            >
              <Table className="compact-table table-bordered" responsive>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Field Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(metadata).map((key, i) => {
                    return (
                      <tr key={i}>
                        <td>{key}</td>
                        <td>{metadata[key]}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )
        }
      },
    },
    {
      Header: 'Next Job Assignment',
      id: 'nextJobAssignment',
      accessor: (row) => {
        const timezone = formatTimezone(
          row.node.nextEmployeeJob?.job?.region?.timezone
        )
        if (row.node.nextEmployeeJob) {
          return (
            <div
              style={{ fontSize: '12px' }}
              className="btn-link"
              onClick={() => {
                setEmployeeAssignmentsModal(row.node.nextEmployeeJob.job.id)
              }}
            >
              <Dot className="mr-2" />
              Employee -{' '}
              {row.node.nextEmployeeJob.employee ? (
                <>{row.node.nextEmployeeJob.employee.gaiaUser.fullName}</>
              ) : (
                <>Unassigned</>
              )}
              <br />
              <Dot className="mr-2" />
              Job - {row.node.nextEmployeeJob.job.name}
              <br />
              <Dot className="mr-2" />
              Shift -{' '}
              {toTimezone(row.node.nextEmployeeJob.startDateTime, {
                humanReadable: true,
                timezone: timezone,
              })}{' '}
              -{' '}
              {toTimezone(row.node.nextEmployeeJob.endDateTime, {
                onlyTime: true,
                timezone: timezone,
              })}
            </div>
          )
        }
      },
    },
    {
      disableSortBy: true,
      Header: 'Download',
      id: 'downloads',
      accessor: (row) => {
        return (
          <>
            <Form.Group
              as={ButtonGroup}
              className="d-flex flex-column align-items-start"
              style={{ gap: '2px' }}
            >
              {settings?.equipmentQrCodes && (
                <>
                  <Button
                    variant="link"
                    style={{ marginBottom: '-20px', marginTop: '-10px' }}
                    disabled={downloading}
                    onClick={() => {
                      setDownloading(true)
                      downloadFile(
                        row.node.qrCodeFile.fileName,
                        row.node.qrCodeFile.displayName,
                        () => {
                          setDownloading(false)
                          toast.success(`QR Code Downloaded`)
                        }
                      )
                    }}
                  >
                    <span style={{ fontSize: '12px' }}>
                      <QrCode className="mr-1" /> QR Code
                    </span>
                  </Button>
                  <Button
                    style={{ marginBottom: '-20px' }}
                    variant="link"
                    disabled={downloading}
                    onClick={() => {
                      setDownloadingLabels(true)
                      setDownloading(true)
                      downloadFile(
                        row.node.qrCodeLabelFile.fileName,
                        row.node.qrCodeLabelFile.displayName,
                        () => {
                          setDownloading(false)
                          toast.success(`QR Code Label Downloaded`)
                        }
                      )
                    }}
                  >
                    <span style={{ fontSize: '12px' }}>
                      <QrCode className="mr-1" /> QR Code Label
                    </span>
                  </Button>
                </>
              )}
              {settings?.equipmentBarcodes && (
                <>
                  <Button
                    variant="link"
                    style={
                      !settings?.equipmentQrCodes
                        ? {
                            marginBottom: '-20px',
                            marginTop: '-10px',
                            marginLeft: '-4px',
                          }
                        : { marginBottom: '-20px', marginLeft: '-4px' }
                    }
                    disabled={downloading}
                    onClick={() => {
                      setDownloading(true)
                      downloadFile(
                        row.node.barcodeFile.fileName,
                        row.node.barcodeFile.displayName,
                        () => {
                          setDownloading(false)
                          toast.success(`Barcode Downloaded`)
                        }
                      )
                    }}
                  >
                    <span style={{ fontSize: '12px' }}>
                      <Upc size={18} className="mr-1" /> Barcode
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    style={{ marginBottom: '-20px', marginLeft: '-4px' }}
                    disabled={downloading}
                    onClick={() => {
                      setDownloading(true)
                      downloadFile(
                        row.node.barcodeLabelFile.fileName,
                        row.node.barcodeLabelFile.displayName,
                        () => {
                          setDownloading(false)
                          toast.success(`Barcode Label Downloaded`)
                        }
                      )
                    }}
                  >
                    <span style={{ fontSize: '12px' }}>
                      <Upc size={18} className="mr-1" /> Barcode Label
                    </span>
                  </Button>
                </>
              )}
              <Button
                variant="link"
                style={{ marginBottom: '-20px' }}
                disabled={downloading}
                onClick={() => {
                  setDownloading(true)
                  downloadBagsPdf({
                    variables: {
                      input: {
                        equipmentBagIds: [row.node.id],
                        type: 'pdf',
                      },
                    },
                  })
                }}
              >
                <span style={{ fontSize: '12px' }}>
                  <FileEarmarkText className="mr-1" /> PDF
                </span>
              </Button>
            </Form.Group>
          </>
        )
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  if (queryData?.equipmentBags) {
                    queryData?.equipmentBags?.edges.forEach((bag) => {
                      if (!checkedBagIds.includes(bag.node.id)) {
                        appendIds.push(bag.node.id)
                      }
                    })
                    setCheckedBags((prevState) => {
                      return [...prevState, ...appendIds]
                    })
                  }
                } else {
                  setCheckedBags([])
                }
              }}
            />
            {checkedBagIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedBagIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'delete',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedBagIds.includes(row.node.id)}
                onChange={(e) => handleBagCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]
  if (employeeId) {
    if (employeeFilter === 'Current') {
      tableColumns.splice(7, 1)
      tableColumns.splice(7, 0, {
        Header: `Job Assignment`,
        id: 'jobAssignments',
        accessor: (row) => {
          const jobs = []
          if (row.node?.lastEmployeeJob?.employee?.id === employeeId) {
            jobs.push(row.node.lastEmployeeJob)
          }
          if (row.node?.nextEmployeeJob?.employee?.id === employeeId) {
            jobs.push(row.node.nextEmployeeJob)
          }
          if (jobs.length) {
            return (
              <>
                <div
                  style={{
                    maxHeight: '75px',
                    maxWidth: '300px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    fontSize: '12px',
                  }}
                  className="mt-2"
                >
                  <Table className="compact-table table-bordered" responsive>
                    <thead>
                      <tr>
                        <th>Job</th>
                        <th>Shift</th>
                      </tr>
                    </thead>
                    <tbody className="border">
                      {jobs.map((empJob, i) => {
                        const timezone = formatTimezone(
                          empJob?.job?.region?.timezone
                        )
                        return (
                          <tr
                            key={i}
                            onClick={() => {
                              setEmployeeAssignmentsModal(empJob.job.id)
                            }}
                            className="border"
                          >
                            <td className="btn-link">{empJob.job.name}</td>
                            <td className="btn-link">
                              {toTimezone(empJob.startDateTime, {
                                humanReadable: true,
                                timezone: timezone,
                              })}{' '}
                              -{' '}
                              {toTimezone(empJob.endDateTime, {
                                onlyTime: true,
                                timezone: timezone,
                              })}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </>
            )
          }
        },
      })
    } else if (employeeFilter === 'All') {
      tableColumns.splice(8, 0, {
        Header: `${employeeName} Job Assignments`,
        id: 'jobAssignments',
        accessor: (row) => {
          if (row.node.employeeEmployeeJobs.length > 0) {
            return (
              <>
                <div
                  style={{
                    maxHeight: '75px',
                    maxWidth: '300px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    fontSize: '12px',
                  }}
                  className="mt-2"
                >
                  <Table className="compact-table table-bordered" responsive>
                    <thead>
                      <tr>
                        <th>Job</th>
                        <th>Shift</th>
                      </tr>
                    </thead>
                    <tbody className="border">
                      {row.node.employeeEmployeeJobs.map((empJob, i) => {
                        const timezone = formatTimezone(
                          empJob?.job?.region?.timezone
                        )
                        return (
                          <tr
                            key={i}
                            onClick={() => {
                              setEmployeeAssignmentsModal(empJob.job.id)
                            }}
                            className="border"
                          >
                            <td className="btn-link">{empJob.job.name}</td>
                            <td className="btn-link">
                              {toTimezone(empJob.startDateTime, {
                                humanReadable: true,
                                timezone: timezone,
                              })}{' '}
                              -{' '}
                              {toTimezone(empJob.endDateTime, {
                                onlyTime: true,
                                timezone: timezone,
                              })}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </>
            )
          }
        },
      })
    }
  }

  if (
    loggedInUser?.canManageRegions &&
    settings?.tenantRegions &&
    formik?.values.regions.length > 1
  ) {
    tableColumns.splice(tableColumns.length - 1, 0, {
      Header: 'Region',
      id: 'region',
      accessor: (row) => <>{formatRegion(row.node.region)}</>,
      serverSort: true,
      orderBy: 'region__name',
    })
  }

  const CheckInOutFilter = () => (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="filter-dropdown">
        <Funnel className="mr-2" />
        {filterReturned === 'all' && !filterPastDue && <>Filter</>}
        {filterReturned === 'checkedOut' && !filterPastDue && <>Checked Out</>}
        {filterReturned === 'checkedIn' && !filterPastDue && <>Checked In</>}
        {filterPastDue && <>Past Due</>}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            setFilterReturned('all')
            setFilterPastDue(null)
          }}
        >
          All
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setFilterReturned('checkedOut')
            setFilterPastDue(null)
          }}
        >
          Checked Out
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setFilterReturned('checkedIn')
            setFilterPastDue(null)
          }}
        >
          Checked In
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setFilterPastDue(true)
            setFilterReturned('all')
          }}
        >
          Past Due
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="searchTerm"
                  className="form-control-sm"
                  placeholder={'Search Bags'}
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Form.Group>
            </Col>
            {loggedInUser?.canManageRegions && settings?.tenantRegions && (
              <Col md={2}>
                <RegionSearchInput
                  formik={formik}
                  dropdown
                  placeholder="Select Region"
                  multiple
                  mustHaveValue={
                    loggedInUser.permissions.group === 'Administrator'
                      ? false
                      : true
                  }
                />
              </Col>
            )}
            <Col className="d-flex justify-content-end align-items-center">
              {canMutate && !equipmentBagTypeId && (
                <span
                  style={
                    checkedBagIds.length > 0
                      ? { fontSize: '0.875rem', display: 'inline-block' }
                      : {}
                  }
                >
                  <Button
                    variant="link"
                    onClick={() => toggleModal()}
                    style={
                      checkedBagIds.length > 0
                        ? {
                            padding: '0.25rem 0.5rem !important',
                            lineHeight: '1.2 !important',
                            border: 'none',
                          }
                        : {}
                    }
                  >
                    <PlusCircle
                      className="mr-2"
                      style={
                        checkedBagIds.length > 0
                          ? {
                              width: '16px !important',
                              height: '16px !important',
                              marginRight: '0.25rem !important',
                            }
                          : {}
                      }
                    />
                    New Bag
                  </Button>
                </span>
              )}
              {!employeeId && <CheckInOutFilter />}
              {employeeId && (
                <>
                  <span
                    style={
                      checkedBagIds.length > 0
                        ? { fontSize: '0.875rem', display: 'inline-block' }
                        : {}
                    }
                  >
                    <Button
                      variant="link"
                      onClick={toggleScanModal}
                      style={
                        checkedBagIds.length > 0
                          ? {
                              padding: '0.25rem 0.5rem !important',
                              lineHeight: '1.2 !important',
                              border: 'none',
                            }
                          : {}
                      }
                    >
                      <PlusCircle
                        className="mr-2"
                        style={
                          checkedBagIds.length > 0
                            ? {
                                width: '16px !important',
                                height: '16px !important',
                                marginRight: '0.25rem !important',
                              }
                            : {}
                        }
                      />
                      Assign
                    </Button>
                  </span>
                  {employeeFilter === 'All' && <CheckInOutFilter />}
                  <span
                    style={
                      checkedBagIds.length > 0
                        ? { fontSize: '0.875rem', display: 'inline-block' }
                        : {}
                    }
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="link"
                        id="filter-dropdown"
                        style={
                          checkedBagIds.length > 0
                            ? {
                                padding: '0.25rem 0.5rem !important',
                                lineHeight: '1.2 !important',
                                border: 'none',
                              }
                            : {}
                        }
                      >
                        <Funnel
                          className="mr-2"
                          style={
                            checkedBagIds.length > 0
                              ? {
                                  width: '16px !important',
                                  height: '16px !important',
                                  marginRight: '0.25rem !important',
                                }
                              : {}
                          }
                        />
                        {employeeFilter}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setEmployeeFilter('All')}>
                          All Assignments
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setEmployeeFilter('Current')
                            setFilterPastDue(null)
                          }}
                        >
                          Current Assignments
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </>
              )}
              {checkedBagIds.length > 0 && (
                <>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="filter-dropdown">
                      <CloudArrowDown className="mr-2" />
                      Download
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {settings?.equipmentQrCodes && (
                        <Dropdown.Item
                          onClick={() => {
                            setDownloadingLabels(true)
                            setDownloading(true)
                            downloadBagsPdf({
                              variables: {
                                input: {
                                  equipmentBagIds: checkedBagIds,
                                  type: 'qrLabels',
                                },
                              },
                            })
                          }}
                        >
                          QR Code Labels
                        </Dropdown.Item>
                      )}
                      {settings?.equipmentBarcodes && (
                        <Dropdown.Item
                          onClick={() => {
                            setDownloadingLabels(true)
                            setDownloading(true)
                            downloadBagsPdf({
                              variables: {
                                input: {
                                  equipmentBagIds: checkedBagIds,
                                  type: 'barcodeLabels',
                                },
                              },
                            })
                          }}
                        >
                          Barcode Labels
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        onClick={() => {
                          setDownloading(true)
                          downloadBagsPdf({
                            variables: {
                              input: {
                                equipmentBagIds: checkedBagIds,
                                type: 'pdf',
                              },
                            },
                          })
                        }}
                      >
                        Info Sheets
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {canMutate && (
                    <span
                      style={{ fontSize: '0.875rem', display: 'inline-block' }}
                    >
                      <Button
                        variant="link"
                        onClick={() => setShowDeleteModal(true)}
                        style={{
                          padding: '0.25rem 0.5rem !important',
                          lineHeight: '1.2 !important',
                          border: 'none',
                        }}
                      >
                        <Trash
                          className="mr-2"
                          style={{
                            width: '16px !important',
                            height: '16px !important',
                            marginRight: '0.25rem !important',
                          }}
                        />
                        {checkedBagIds.length === 1 ? (
                          <>Delete Bag</>
                        ) : (
                          <>Delete Bags</>
                        )}
                      </Button>
                    </span>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col md="12">
              <SortableInfiniteTable
                tableData={
                  queryData?.equipmentBags?.edges
                    ? queryData?.equipmentBags?.edges
                    : []
                }
                loadingMessage="Loading Bags..."
                tableColumns={tableColumns}
                loading={loadingSearch || !queryData}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={
                  queryData?.equipmentBags?.pageInfo?.hasNextPage
                }
                onTdClicks={{
                  longTermAssignment: (cell) => onTdClick(cell),
                  returned: (cell) => onTdClick(cell),
                  items: (cell) => onTdClick(cell),
                  bagType: (cell) => onTdClick(cell),
                  category: (cell) => onTdClick(cell),
                  name: (cell) => onTdClick(cell),
                  customFields: (cell) => onTdClick(cell),
                  region: (cell) => onTdClick(cell),
                }}
                tableHeight={tableHeight || 700}
                rowPointer
                hideGlobalFilter
                handleSortByChange={handleSortByChange}
              />
            </Col>
          </Row>
        </>
        {showForm ? (
          <BagModal
            showModal={showForm}
            bag={editBag}
            employeeId={employeeId}
            employeeName={employeeName}
            toggleModal={toggleModal}
          />
        ) : null}
        <DeleteBagModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedBags={setCheckedBags}
          ids={checkedBagIds.length > 1 ? checkedBagIds : null}
          id={checkedBagIds.length === 1 ? checkedBagIds[0] : null}
        />
        {openScanModal && (
          <ScanModal
            showModal={openScanModal}
            employeeId={employeeId}
            hideDropdown={true}
            toggleModal={toggleScanModal}
            setScannedBags={setScannedBags}
            scannedBags={scannedBags}
            setScannedItems={setScannedItems}
            scannedItems={scannedItems}
          />
        )}
        {employeeAssignmentsModal && (
          <EmployeeAssignmentsModal
            displayEquipment={true}
            showModal={true}
            toggleModal={setEmployeeAssignmentsModal}
            jobId={employeeAssignmentsModal}
          />
        )}
      </div>
    </>
  )
}
export default Bags
