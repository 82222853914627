import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'

const DELETE_CUSTOM_FIELD_SET = gql`
  mutation DeleteCustomFieldSet($input: DeleteCustomFieldSetInput!) {
    deleteCustomFieldSet(input: $input) {
      deleted
    }
  }
`

const DeleteCustomFieldSetModal = ({
  showModal,
  toggleModal,
  ids,
  refetch,
}) => {
  const [submitting, setSubmitting] = useState(false)

  const [deleteCustomFieldSet] = useMutation(DELETE_CUSTOM_FIELD_SET, {
    onCompleted: () => {
      setSubmitting(false)
      toast.success('Custom Field Set(s) Deleted')
      refetch()
      toggleModal()
    },
    onError: (error) => {
      setSubmitting(false)
      toast.error(error.message || 'Failed to delete custom field set')
    },
  })

  const handleDeleteClick = () => {
    setSubmitting(true)
    deleteCustomFieldSet({
      variables: {
        input: { ids }, // Correct input structure
      },
    })
  }

  if (!showModal) return null

  return (
    <Modal show={showModal} onHide={toggleModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Delete Custom Field Set{ids.length > 1 ? 's' : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete the selected custom field set
          {ids.length > 1 ? 's' : ''}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal} disabled={submitting}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDeleteClick}
          disabled={submitting}
        >
          {submitting ? <Loading /> : 'Delete'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteCustomFieldSetModal
