import React, { useState, useEffect } from 'react'
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { Form, Row, Col, Button, Badge, Modal } from 'react-bootstrap'
import {
  ErrorMessage,
  Field,
  FieldArray,
  FormikProvider,
  useFormik,
} from 'formik'
import validator from 'validator'
import PhoneInput from 'react-phone-input-2'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { CaretDown, CaretRight, Search } from 'react-bootstrap-icons'
import DeleteEmployeeModal from './DeleteEmployeeModal'
import { PlusCircle, Trash } from 'react-bootstrap-icons'
import { US_STATES } from '../../libs/constant'
import { client, settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import { formatTimezone } from '../../libs/utils'
import Loading from '../common/Loading'
import PermissionsModal from './PermissionsModal'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import { useDateTimeConverter } from '../../libs/useDateTime'

const EditEmployeeModal = (props) => {
  const { employee } = props
  let roles = []
  let groups = []
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { toTimezone } = useDateTimeConverter()
  const cantMutate = [
    'Equipment Manager',
    'Scheduling Analyst',
    'General Staff',
  ].includes(loggedInUser?.permissions?.group)

  const [submitting, setSubmitting] = useState(false)
  const [showPermissions, setShowPermissions] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [displayGaiaUserRegion, setDisplayGaiaUserRegion] = useState(true)
  const gaiaUserRegionsValidationMessage = [
    'at least one region must be default',
    'only one region can be default',
    'region must be unique',
  ]
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const [rolesQuery, { error: rolesQueryError, data: rolesQueryData }] =
    useLazyQuery(
      gql`
        query RolesQuery {
          roles(archived: false) {
            edges {
              node {
                id
                name
                hourlyRate
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )
  const [groupsQuery, { error: groupsQueryError, data: groupsQueryData }] =
    useLazyQuery(
      gql`
        query GroupsQuery {
          groups {
            edges {
              node {
                name
                id
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  const [updateEmployeeMutation] = useMutation(
    gql`
      mutation UpdateEmployee($updateEmployeeInput: UpdateEmployeeInput!) {
        updateEmployee(input: $updateEmployeeInput) {
          employee {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Employee Saved`)
      },
      errorPolicy: 'all',
      refetchQueries: ['EmployeeQuery'],
    }
  )

  function handleRoleClick(e) {
    const selectedValue = e.target.value
    if (!selectedRoles.find((role) => role.id === selectedValue)) {
      const roleData = roles.find((role) => role.id === selectedValue)
      setSelectedRoles([...selectedRoles, roleData])
    }
  }

  function deleteRoleClick(id) {
    if (selectedRoles.find((role) => role.id === id)) {
      const filteredArray = selectedRoles.filter((role) => role.id !== id)
      setSelectedRoles(filteredArray)
    }
  }
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      abbreviatedName: '',
      email: '',
      phoneNumber: '',
      secondaryPhoneNumber: '',
      secondaryEmail: '',
      groupId: '',
      active: '',
      addressLineTwo: '',
      addressLineOne: '',
      city: '',
      state: '',
      smsNotificationsEnabled: true,
      emailNotificationsEnabled: true,
      zipCode: '',
      hourlyPay: null,
      salesPercent: '',
      photoPercent: '',
      payCommission: '',
      password: '',
      confirmPassword: '',
      rainPercent: '',
      taskPercent: '',
      servicePercent: '',
      smtpHost: '',
      smtpPort: 0,
      smtpUser: '',
      smtpTls: false,
      smtpSsl: false,
      smtpPassword: '',
      smtpSendgridSender: '',
      smtpGmail: false,
      gaiaUserRegions: [
        {
          regionId: loggedInUser?.defaultRegion?.id,
          default: true,
        },
      ],
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .nullable()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
        )
        .max(100, 'Password is too long'),
      confirmPassword: Yup.string()
        .nullable()
        .min(2, 'Confirm password is too short')
        .max(100, 'Confirm password is too long')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      firstName: Yup.string()
        .min(2, 'First name is too short')
        .max(100, 'First name  is too long')
        .required('First name  is required'),
      lastName: Yup.string()
        .min(2, 'Last name  is too short')
        .max(100, 'Last name  is too long')
        .required('Last name  is required'),
      abbrevietedName: Yup.string()
        .max(100, 'Abbrevieted name  is too long')
        .nullable(),
      secondaryEmail: Yup.string()
        .min(2, 'Email  is too short')
        .max(100, 'Email  is too long')
        .nullable()
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      email: Yup.string()
        .min(2, 'Email  is too short')
        .max(100, 'Email  is too long')
        .required('Email  is required')
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        })
        .test(
          'isUnique',
          'Account with this email already exists',
          async (value) => {
            let valid = true
            if (value) {
              const { data } = await client.query({
                query: gql`
                  query NewSubjectFormGaiaUsersQuery($emailIexact: String) {
                    gaiaUsers(email_Iexact: $emailIexact) {
                      nodeCount
                      edges {
                        node {
                          email
                        }
                      }
                    }
                  }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                  emailIexact: value,
                },
              })
              let matchesCurrent = false
              if (data.gaiaUsers.edges.length > 0) {
                data.gaiaUsers.edges.forEach((node) => {
                  if (
                    !matchesCurrent &&
                    node.node.email === employee.gaiaUser.email
                  ) {
                    matchesCurrent = true
                  }
                })
                if (!matchesCurrent) {
                  valid = false
                }
              }
            }
            return valid
          }
        ),
      phoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .nullable()
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
      secondaryPhoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .nullable()
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
      smsNotificationsEnabled: Yup.bool().required('Required'),
      emailNotificationsEnabled: Yup.bool().required('Required'),
      addressLineOne: Yup.string()
        .min(1, 'Address line one is too short')
        .max(100, 'Address line one is too long')
        .nullable(),
      addressLineTwo: Yup.string()
        .min(1, 'Address line two is too short')
        .nullable()
        .max(100, 'Address line two is too long'),
      city: Yup.string()
        .min(1, 'City is too short')
        .max(100, 'City is too long')
        .nullable(),
      hourlyPay: Yup.mixed().nullable(),
      state: Yup.string()
        .min(3, 'State is too short')
        .max(100, 'State is too long')
        .nullable()
        .test('isState', 'Invalid state', (value) => {
          let valid = true
          if (value && !US_STATES.includes(value)) {
            valid = false
          }
          return valid
        }),
      zipCode: Yup.string()
        .min(3, 'Zip code is too short')
        .max(100, 'Zip code is too long')
        .nullable(),
      smtpTls: Yup.boolean().nullable(),
      smtpSsl: Yup.boolean().nullable(),
      smtpHost: Yup.string().nullable(),
      smtpPort: Yup.number().nullable(),
      smtpUser: Yup.string().nullable(),
      smtpPassword: Yup.string().nullable(),
      smtpSendgridSender: Yup.string().nullable(),
      smtpGmail: Yup.string().nullable(),
      salesPercent: Yup.number().integer().nullable().min(0).max(100),
      photoPercent: Yup.number().integer().nullable().min(0).max(100),
      payCommission: Yup.number().integer().nullable().min(0).max(100),
      rainPercent: Yup.number().integer().nullable().min(0).max(100),
      taskPercent: Yup.number().integer().nullable().min(0).max(100),
      servicePercent: Yup.number().integer().nullable().min(0).max(100),
      groupId: Yup.string()
        .required('Permissions are required')
        .test('isGroup', 'Invalid permissions', (value) => {
          let valid = false
          if (value) {
            groups.map((group) => {
              if (!valid && value === group.id) {
                valid = true
              }
            })
          }
          return valid
        }),
      active: Yup.bool().required('Enablement status is required'),
      gaiaUserRegions: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().nullable(),
            regionId: Yup.string().required('required'),
            default: Yup.boolean().nullable(),
          })
        )
        .test(
          'at-least-one-default',
          'at least one region must be default',
          (value) => {
            if (!value || value.length === 0) {
              return true
            }
            const defaultCount = value.filter((region) => region.default).length
            return defaultCount >= 1
          }
        )
        .test('only-one-default', 'only one region can be default', (value) => {
          if (!value || value.length === 0) {
            return true
          }
          const defaultCount = value.filter((region) => region.default).length
          return defaultCount <= 1
        })
        .test('unique-region-id', 'region must be unique', (value) => {
          if (!value || value.length === 0) {
            return true
          }
          const regionIds = value.map((region) => region.regionId)
          const uniqueRegionIds = new Set(regionIds)
          return regionIds.length === uniqueRegionIds.size
        }),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      updateEmployeeMutation({
        variables: {
          updateEmployeeInput: {
            employeeInput: {
              id: employee.id,
              // defaultRoleId: values.defaultRoleId,
              hourlyPay: values.hourlyPay,
              gaiaUserFirstName: values.firstName,
              gaiaUserLastName: values.lastName,
              gaiaUserAbbreviatedName: values.abbreviatedName,
              gaiaUserSecondaryPhoneNumber: values.secondaryPhoneNumber,
              gaiaUserSecondaryEmail: values.secondaryEmail,
              gaiaUserEmail: values.email,
              gaiaUserPassword: values.password,
              gaiaUserPhoneNumber: values.phoneNumber,
              gaiaUserActive: values.active,
              gaiaUserRegions: values.gaiaUserRegions,
              roleIds: selectedRoles.map((role) => role.id),
              groupId: values.groupId,
              gaiaUserAddressLineOne: values.addressLineOne,
              gaiaUserAddressLineTwo: values.addressLineTwo,
              gaiaUserCity: values.city,
              gaiaUserState: values.state,
              gaiaUserZipCode: values.zipCode,
              salesPercent: values.salesPercent,
              photoPercent: values.photoPercent,
              gaiaUserEmailNotificationsEnabled:
                values.emailNotificationsEnabled,
              gaiaUserSmsNotificationsEnabled: values.smsNotificationsEnabled,
              payCommission: values.payCommission,
              rainPercent: values.rainPercent,
              taskPercent: values.taskPercent,
              servicePercent: values.servicePercent,
              smtpUser: values.smtpUser,
              smtpHost: values.smtpGmail ? null : values.smtpHost,
              smtpPort: values.smtpGmail ? null : values.smtpPort,
              smtpTls: values.smtpGmail ? null : values.smtpTls,
              smtpSsl: values.smtpGmail ? null : values.smtpSsl,
              smtpPassword: values.smtpGmail ? null : values.smtpPassword,
              smtpGmail: values.smtpGmail,
              smtpSendgridSender:
                values.smtpHost === 'smtp.sendgrid.net'
                  ? values.smtpSendgridSender
                  : null,
            },
          },
        },
      })
    },
  })
  const handleRolesQueryData = () => {
    const currentRoles = []
    rolesQueryData.roles.edges.forEach((role) => {
      currentRoles.push(role.node)
    })
    return currentRoles
  }
  const handleGroupsQueryData = () => {
    const currentGroups = []
    groupsQueryData.groups.edges.forEach((group) => {
      if (
        group.node.name !== 'Subject' &&
        group.node.name !== 'Organization Contact'
      ) {
        currentGroups.push(group.node)
      }
    })
    return currentGroups
  }
  const { data: smtpData } = useQuery(
    gql`
      query SMTPConnector {
        smtpConnectors {
          edges {
            node {
              customHost
              customPort
              customUser
              customTls
              customPassword
              customValid
              customSsl
              customGmail
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (employee && smtpData) {
      rolesQuery()
      groupsQuery()
      const existingRoleIds = []
      const existingRoles = []
      employee.roles.edges.forEach((node) => {
        existingRoleIds.push(node.node.id)
        existingRoles.push(node.node)
      })
      setSelectedRoles(existingRoles)
      const smtpConnector = smtpData.smtpConnectors.edges[0].node
      formik.setValues({
        firstName: employee.gaiaUser.firstName,
        // defaultRoleId: '',
        lastName: employee.gaiaUser.lastName,
        password: '',
        confirmPassword: '',
        hourlyPay: employee.hourlyPay,
        abbreviatedName: employee.gaiaUser.abbreviatedName,
        email: employee.gaiaUser.email,
        secondaryEmail: employee.gaiaUser.secondaryEmail,
        phoneNumber: employee.gaiaUser.phoneNumber,
        secondaryPhoneNumber: employee.gaiaUser.secondaryPhoneNumber,
        internalEmployeeId: employee.internalEmployeeId,
        roleIds: existingRoleIds,
        groupId: employee.gaiaUser.groups.edges[0].node.id,
        active: employee.gaiaUser.isActive,
        addressLineOne: employee.gaiaUser.addressLineOne,
        addressLineTwo: employee.gaiaUser.addressLineTwo,
        city: employee.gaiaUser.city,
        state: employee.gaiaUser.state,
        zipCode: employee.gaiaUser.zipCode,
        smsNotificationsEnabled: employee.gaiaUser.smsNotificationsEnabled,
        emailNotificationsEnabled: employee.gaiaUser.emailNotificationsEnabled,
        payCommission: employee.payCommission,
        photoPercent: employee.photoPercent,
        rainPercent: employee.rainPercent,
        salesPercent: employee.salesPercent,
        servicePercent: employee.servicePercent,
        taskPercent: employee.taskPercent,
        smtpHost: employee.smtpHost
          ? employee.smtpHost
          : smtpConnector.customHost,
        smtpPort: employee.smtpPort
          ? employee.smtpPort
          : smtpConnector.customPort,
        smtpUser: employee.smtpUser,
        smtpTls: [true, false].includes(employee.smtpTls)
          ? employee.smtpTls
          : smtpConnector.customTls,
        smtpSsl: [true, false].includes(employee.smtpSsl)
          ? employee.smtpSsl
          : smtpConnector.customSsl,
        smtpPassword: employee.smtpPassword,
        smtpSendgridSender: employee.smtpSendgridSender,
        smtpGmail: employee.smtpGmail,
        gaiaUserRegions: employee.gaiaUser?.gaiaUserRegions?.edges?.map(
          ({ node }) => ({
            id: node.id,
            regionId: node.region?.id,
            default: node.default,
          })
        ),
      })
    }
  }, [employee, smtpData])
  if (rolesQueryError) return <>Error loading roles</>
  if (groupsQueryError) return <>Error loading roles</>
  if (!groupsQueryData || !rolesQueryData) return <></>
  roles = handleRolesQueryData()
  groups = handleGroupsQueryData()
  return (
    <>
      <div className="editEmployeeModal">
        <DeleteEmployeeModal
          showModal={showDeleteModal}
          toggleModal={toggleDeleteModal}
          employeeId={employee.id}
        />
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Row>
              <Col md={3}>
                <Form.Label className="mb-0">First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  readOnly={cantMutate}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={3}>
                <Form.Label className="mb-0">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  readOnly={cantMutate}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={2}>
                <Form.Label className="mb-0">Abbreviated Name</Form.Label>
                <Form.Control
                  type="text"
                  name="abbreviatedName"
                  readOnly={cantMutate}
                  value={formik.values.abbreviatedName}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.abbreviatedName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.abbreviatedName}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  readOnly={cantMutate}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Secondary Email</Form.Label>
                <Form.Control
                  type="text"
                  name="secondaryEmail"
                  readOnly={cantMutate}
                  value={formik.values.secondaryEmail}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.secondaryEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.secondaryEmail}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="pt-1 d-inline-block">
                  Phone Number
                </Form.Label>
                <PhoneInput
                  country="us"
                  placeholder=""
                  name="phoneNumber"
                  disabled={cantMutate}
                  regions={['north-america']}
                  value={formik.values.phoneNumber}
                  onChange={(phone, country) =>
                    formik.setFieldValue('phoneNumber', phone)
                  }
                />
                {formik.errors.phoneNumber ? (
                  <span className="text-danger">
                    {formik.errors.phoneNumber}
                  </span>
                ) : null}
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="pt-1 d-inline-block">
                  Secondary Phone Number
                </Form.Label>
                <PhoneInput
                  country="us"
                  placeholder=""
                  name="secondaryPhoneNumber"
                  disabled={cantMutate}
                  regions={['north-america']}
                  value={formik.values.secondaryPhoneNumber}
                  onChange={(phone, country) =>
                    formik.setFieldValue('secondaryPhoneNumber', phone)
                  }
                />
                {formik.errors.secondaryPhoneNumber ? (
                  <span className="text-danger">
                    {formik.errors.secondaryPhoneNumber}
                  </span>
                ) : null}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label>Address Line One</Form.Label>
                <Form.Control
                  type="text"
                  name="addressLineOne"
                  readOnly={cantMutate}
                  value={formik.values.addressLineOne}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.addressLineOne}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.addressLineOne}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Address Line Two</Form.Label>
                <Form.Control
                  type="text"
                  name="addressLineTwo"
                  readOnly={cantMutate}
                  value={formik.values.addressLineTwo}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.addressLineTwo}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.addressLineTwo}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  readOnly={cantMutate}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.city}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.city}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>State</Form.Label>
                <Form.Control
                  name="state"
                  as="select"
                  readOnly={cantMutate}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  isInvalid={formik.errors.state}
                >
                  {US_STATES.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.state}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zipCode"
                  readOnly={cantMutate}
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.zipCode}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.zipCode}
                </Form.Control.Feedback>
              </Col>
              {!cantMutate && (
                <Col xs={12} md={4}>
                  <Form.Label>Hourly Pay</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    min="0"
                    name="hourlyPay"
                    value={formik.values.hourlyPay}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.hourlyPay}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.hourlyPay}
                  </Form.Control.Feedback>
                </Col>
              )}
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Last Login</Form.Label>
                <Form.Control
                  disabled={true}
                  value={
                    employee.gaiaUser.lastLogin
                      ? toTimezone(employee.gaiaUser.lastLogin, {
                          humanReadableSort: true,
                          timezone: formatTimezone(
                            employee.gaiaUser.region?.timezone
                          ),
                        })
                      : 'Never'
                  }
                />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Last Login Location</Form.Label>
                <Form.Control
                  disabled={true}
                  value={
                    employee.gaiaUser.loginLocation
                      ? employee.gaiaUser.loginLocation
                      : ''
                  }
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Confirm Password</Form.Label>

                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirmPassword}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Roles</Form.Label>
                {!cantMutate && (
                  <Form.Control
                    as="select"
                    value={'def'}
                    name="roles"
                    onChange={handleRoleClick}
                  >
                    <option key="def" value="def">
                      Select Roles
                    </option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </Form.Control>
                )}
                <Form.Text className="mt-2">
                  {selectedRoles.length > 0 && (
                    <>
                      <div style={{ maxHeight: '215px', overflowY: 'auto' }}>
                        {selectedRoles?.map(({ name, id }, i) => (
                          <div
                            style={{
                              border: '.5px solid',
                              borderRadius: '0.25rem',
                              color: '#000000',
                              padding: '0.25rem 0.5rem',
                              fontSize: '1rem',
                              marginTop: i > 0 ? '0.5rem' : '0',
                            }}
                            key={id}
                          >
                            {!cantMutate && (
                              <Trash
                                className="mr-2 btn-link"
                                onClick={() => {
                                  if (!cantMutate) {
                                    deleteRoleClick(id)
                                  }
                                }}
                              />
                            )}
                            {name}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {selectedRoles.length === 0 && <>- - -</>}
                </Form.Text>
              </Col>
              {['Scheduling Manager', 'Administrator'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <Col xs={12} md={4} style={{ marginTop: '-10px' }}>
                  <Form.Label className="mb-0">Permissions</Form.Label>
                  {!cantMutate && (
                    <Button
                      type="button"
                      onClick={() => setShowPermissions(!showPermissions)}
                      className="px-0 btn-link ml-2"
                    >
                      <>
                        <Search size={15} />{' '}
                      </>
                    </Button>
                  )}

                  <Form.Control
                    name="groupId"
                    as="select"
                    style={{ marginTop: '-10px' }}
                    disabled={cantMutate}
                    value={formik.values.groupId}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.groupId}
                  >
                    {groups.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.groupId}
                  </Form.Control.Feedback>
                </Col>
              )}
            </Row>
          </Form.Group>
          {loggedInUser?.canManageRegions && settings?.tenantRegions && (
            <FormikProvider value={formik}>
              <Form.Row className="mb-3">
                <Col className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() =>
                      setDisplayGaiaUserRegion(!displayGaiaUserRegion)
                    }
                    className="px-0 btn-link mr-2"
                    style={{ marginTop: '-10px' }}
                  >
                    <>
                      {displayGaiaUserRegion ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label>Regions</Form.Label>
                  <ErrorMessage name="gaiaUserRegions">
                    {(msg) => {
                      return gaiaUserRegionsValidationMessage.includes(msg) ? (
                        <span className="text-danger d-block mb-2 ml-2">
                          {msg}
                        </span>
                      ) : (
                        <></>
                      )
                    }}
                  </ErrorMessage>
                </Col>
              </Form.Row>
              {displayGaiaUserRegion && (
                <FieldArray
                  name="gaiaUserRegions"
                  render={(gaiaUserRegionArrayHelpers) => (
                    <Row>
                      <Col md={6}>
                        <div
                          style={
                            formik.values.gaiaUserRegions.length > 1
                              ? {
                                  overflowY: 'scroll',
                                  maxHeight: '350px',
                                  overflowX: 'hidden',
                                }
                              : null
                          }
                          className={
                            formik.values.gaiaUserRegions.length > 1
                              ? 'border p-3 mt-2'
                              : ''
                          }
                        >
                          <div className="gaia-user-region-form">
                            {formik.values.gaiaUserRegions.map(
                              (gaiaUserRegion, i) => (
                                <div
                                  key={i}
                                  className="border border-secondary rounded mb-3 p-2"
                                >
                                  <Form.Group as={Row}>
                                    <Col md={2}>
                                      <Form.Label>Region</Form.Label>
                                    </Col>
                                    <Col md={10}>
                                      <RegionSearchInput
                                        dropdown
                                        formik={formik}
                                        formikValue={`gaiaUserRegions[${i}].region`}
                                        error={
                                          gaiaUserRegionsValidationMessage.includes(
                                            formik.errors.gaiaUserRegions
                                          )
                                            ? ''
                                            : formik.errors.gaiaUserRegions
                                                  ?.length > 0
                                              ? formik.errors.gaiaUserRegions[i]
                                                  ?.regionId
                                              : ''
                                        }
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row}>
                                    <Col md={2}>
                                      <Form.Label>Default</Form.Label>
                                    </Col>
                                    <Col md={10}>
                                      <Field
                                        type="checkbox"
                                        name={`gaiaUserRegions[${i}].default`}
                                        onChange={(e) => {
                                          const newValue = e.target.checked
                                          if (newValue) {
                                            formik.setFormikState(
                                              (prevState) => ({
                                                ...prevState,
                                                values: {
                                                  ...prevState.values,
                                                  gaiaUserRegions:
                                                    prevState.values.gaiaUserRegions.map(
                                                      (region, index) => ({
                                                        ...region,
                                                        default: index === i,
                                                      })
                                                    ),
                                                },
                                              })
                                            )
                                          } else {
                                            formik.setFieldValue(
                                              `gaiaUserRegions[${i}].default`,
                                              false
                                            )
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>
                                  <div
                                    className="d-flex align-items-center mt-2 btn-link hover"
                                    onClick={() =>
                                      gaiaUserRegionArrayHelpers.remove(i)
                                    }
                                  >
                                    <Trash className="mr-2" />
                                    <small>Remove</small>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <Button
                          size="sm"
                          className="mt-1"
                          variant="link"
                          onClick={() => {
                            gaiaUserRegionArrayHelpers.push({
                              regionId: '',
                              default: false,
                            })
                            let element = document.querySelector(
                              '.gaia-user-region-form'
                            )
                            element.lastChild?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'end',
                              inline: 'nearest',
                            })
                          }}
                        >
                          <PlusCircle className="mr-2" />
                          Add Region
                        </Button>
                      </Col>
                    </Row>
                  )}
                />
              )}
            </FormikProvider>
          )}
          {!cantMutate && (
            <Form.Group>
              <Form.Row>
                <Col xs={12} md={2} className="d-flex align-items-center">
                  <Form.Label className="mb-0">Enabled</Form.Label>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Check
                    name="active"
                    disabled={cantMutate}
                    style={{ width: '20px', height: '20px' }}
                    checked={formik.values.active}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.active}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.active}
                  </Form.Control.Feedback>
                </Col>
                <Col xs={12} md={{ span: 6, offset: 2 }}>
                  <Form.Label>
                    {formik.values.active ? (
                      <>
                        Enabled employees can login, be assigned to jobs and
                        will receive notifications
                      </>
                    ) : (
                      <>
                        Disabled employees cannot login or be assigned to jobs,
                        and will not receive notifications
                      </>
                    )}
                  </Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.active}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
            </Form.Group>
          )}

          <Form.Group>
            <>
              <Row>
                <Col md={8}>
                  <p className="text-secondary title-space mb-1">
                    NOTIFCATIONS
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <hr />
                </Col>
              </Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Check
                    inline
                    label="Receive SMS"
                    disabled={cantMutate}
                    checked={formik.values.smsNotificationsEnabled}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'smsNotificationsEnabled',
                        e.target.checked
                      )
                    }
                  />

                  <Form.Check
                    inline
                    label="Receive Email"
                    disabled={cantMutate}
                    checked={formik.values.emailNotificationsEnabled}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'emailNotificationsEnabled',
                        e.target.checked
                      )
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Row className="mt-3">
                <Col md={8}>
                  <p className="text-secondary title-space mb-1">
                    PERSONAL EMAIL SENDER
                    {(employee.smtpHost ||
                      employee.smtpPassword ||
                      employee.smtpPort ||
                      employee.smtpUser) &&
                      employee.smtpValid && (
                        <Badge
                          className="ml-2"
                          style={{ fontSize: '12px' }}
                          variant="success"
                        >
                          Enabled
                        </Badge>
                      )}
                    {(employee.smtpHost ||
                      employee.smtpPassword ||
                      employee.smtpPort ||
                      employee.smtpUser) &&
                      employee.smtpValid === false && (
                        <Badge
                          className="ml-2"
                          style={{ fontSize: '12px' }}
                          variant="danger"
                        >
                          Disabled
                        </Badge>
                      )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <hr />
                </Col>
              </Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Check
                    inline
                    label="Gmail"
                    checked={formik.values.smtpGmail}
                    onChange={(e) =>
                      formik.setFieldValue('smtpGmail', e.target.checked)
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Row>
                  <Col
                    xs={12}
                    md={formik.values.smtpHost === 'smtp.sendgrid.net' ? 2 : 4}
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="string"
                      name="smtpUser"
                      disabled={cantMutate}
                      value={formik.values.smtpUser}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.smtpUser}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.smtpUser}
                    </Form.Control.Feedback>
                  </Col>
                  {!formik.values.smtpGmail && (
                    <>
                      {formik.values.smtpHost === 'smtp.sendgrid.net' && (
                        <Col xs={12} md={3}>
                          <Form.Label>SendGrid Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="smtpSendgridSender"
                            value={formik.values.smtpSendgridSender}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.smtpSendgridSender}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.smtpSendgridSender}
                          </Form.Control.Feedback>
                        </Col>
                      )}
                      <Col
                        xs={12}
                        md={
                          formik.values.smtpHost === 'smtp.sendgrid.net' ? 3 : 4
                        }
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="smtpPassword"
                          disabled={cantMutate}
                          value={formik.values.smtpPassword}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.smtpPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.smtpPassword}
                        </Form.Control.Feedback>
                      </Col>
                    </>
                  )}
                </Row>
              </Form.Group>
              {!formik.values.smtpGmail && (
                <Form.Group>
                  <Row>
                    <Col xs={12} md={3}>
                      <Form.Label>Email Server Host</Form.Label>
                      <Form.Control
                        type="string"
                        name="smtpHost"
                        disabled={cantMutate}
                        value={formik.values.smtpHost}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.smtpHost}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpHost}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Email Server Port</Form.Label>
                      <Form.Control
                        type="number"
                        name="smtpPort"
                        disabled={cantMutate}
                        value={formik.values.smtpPort}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.smtpPort}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpPort}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Use TLS</Form.Label>
                      <Form.Check
                        name="smtpTls"
                        type="checkbox"
                        disabled={cantMutate}
                        checked={formik.values.smtpTls}
                        onChange={(e) => {
                          formik.setFieldValue(`smtpTls`, e.target.checked)
                          if (e.target.checked && formik.values.smtpSsl) {
                            formik.setFieldValue(`smtpSsl`, false)
                          }
                        }}
                        isInvalid={formik.errors.smtpTls}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpTls}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Use SSL</Form.Label>
                      <Form.Check
                        name="smtpSsl"
                        type="checkbox"
                        disabled={cantMutate}
                        checked={formik.values.smtpSsl}
                        onChange={(e) => {
                          formik.setFieldValue(`smtpSsl`, e.target.checked)
                          if (e.target.checked && formik.values.smtpTls) {
                            formik.setFieldValue(`smtpTls`, false)
                          }
                        }}
                        isInvalid={formik.errors.smtpSsl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpSsl}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              )}
            </>
          </Form.Group>

          {!cantMutate &&
            window.location.hostname.includes('srschedule.com') && (
              <>
                <Row>
                  <Col md={8}>
                    <p className="text-secondary title-space mb-1">
                      PROFIT SHARING
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <hr />
                  </Col>
                </Row>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={2}>
                      <Form.Label>Photo Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="photoPercent"
                        disabled={cantMutate}
                        value={formik.values.photoPercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.photoPercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.photoPercent}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Sales Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="salesPercent"
                        disabled={cantMutate}
                        value={formik.values.salesPercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.salesPercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.salesPercent}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Rain Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="rainPercent"
                        disabled={cantMutate}
                        value={formik.values.rainPercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.rainPercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.rainPercent}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Service Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="servicePercent"
                        disabled={cantMutate}
                        value={formik.values.servicePercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.servicePercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.servicePercent}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={2}>
                      <Form.Label>Task Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="taskPercent"
                        disabled={cantMutate}
                        value={formik.values.taskPercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.taskPercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.taskPercent}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Pay Commission</Form.Label>
                      <Form.Control
                        type="number"
                        name="payCommission"
                        disabled={cantMutate}
                        value={formik.values.payCommission}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.payCommission}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.payCommission}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </>
            )}

          {!cantMutate && (
            <div className="mb-3">
              <Row>
                <Col md={3}>
                  <Button
                    block
                    type="submit"
                    variant="outline-primary"
                    className="mt-2"
                    disabled={submitting}
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Row>
              {!(loggedInUser?.employee?.id === employee.id) && (
                <Row>
                  <Col md={3}>
                    <Button
                      block
                      disabled={submitting}
                      variant="outline-danger"
                      onClick={toggleDeleteModal}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          )}
          {submitting && (
            <Row>
              <Col>
                <Loading message="Saving Employee..." />
              </Col>
            </Row>
          )}
        </Form>
        <PermissionsModal
          showModal={showPermissions}
          hideModal={() => setShowPermissions()}
        />
      </div>
    </>
  )
}

export default EditEmployeeModal
