import React from 'react'
import { Modal } from 'react-bootstrap'
import { DateTime } from 'luxon'
import AuditLog from '../../audit_log/AuditLog'
import { useDateTimeConverter } from '../../../libs/useDateTime'

export default function JobHistoryModal(props) {
  const { job, showModal, toggleModal, timezone } = props
  const { toTimezone } = useDateTimeConverter()
  if (!showModal) return null

  return (
    <Modal show={showModal} onHide={toggleModal} size="xl">
      <Modal.Header closeButton>
        <h1>Job History</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="d-block mb-2">
          <small className="font-weight-bold d-block">Created By</small>
          <small className="d-block">
            {`${job?.createdBy?.fullName}, ${job?.createdBy?.email}`}
          </small>
          <small>
            {toTimezone(job?.created, {
              onlyDate: true,
              timezone: timezone,
            })}
          </small>
        </div>
        <div className="mb-2">
          <small className="font-weight-bold">Last Modified By</small>
          {job?.modifiedBy ? (
            <>
              <small className="d-block">{` ${job?.modifiedBy?.fullName}, ${job?.modifiedBy?.email}`}</small>
              <small>
                {toTimezone(job?.updated, {
                  onlyDate: true,
                  timezone: timezone,
                })}
              </small>
            </>
          ) : (
            <small className="text-secondary d-block">-</small>
          )}
        </div>
        <AuditLog contentType={job.contentType.model} id={job.id} />
      </Modal.Body>
    </Modal>
  )
}
