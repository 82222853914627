import React, { useEffect, useState } from 'react'
import { Router, useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Elements } from '@stripe/react-stripe-js'
import {
  timezoneVar,
  settingsVar,
  loggedInUserVar,
  getNewToken,
  logoLoadedVar,
} from './libs/apollo'
import { Row, Col } from 'react-bootstrap'
import Routes from './routes/Routes'
import { setupFilePondPlugins } from './libs/filepond'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Toaster, ToastBar, toast } from 'react-hot-toast'
import { apiToLuxonTimezone } from './libs/utils'
import { XCircle } from 'react-bootstrap-icons'
import './App.css'
import { initSentry } from './libs/sentry'
import { initStripe } from './libs/stripe'
import { Settings as LuxonSettings } from 'luxon'
import { clearLoginVars } from './libs/apollo'
import {
  getJwtLocalStorage,
  useInterval,
  getLocalStorageObj,
  decodeJwtPayload,
} from './libs/utils'
import { useIdleTimer } from 'react-idle-timer'
import { CONSTANTS } from './libs/constant'
import { ProSidebarProvider } from 'react-pro-sidebar'
import { IntercomProvider } from 'react-use-intercom'
import { GoogleMapsProvider } from './libs/googleMaps'
import WebSocketProvider from './libs/websocket'
import { UploadProvider } from './components/files/UploadContext'
import Loading from './components/common/Loading'
import { DownloadProvider } from './components/files/DownloadContext'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import config from './config'

initSentry()
const stripePromise = initStripe()

function App() {
  const settings = useReactiveVar(settingsVar)
  let loggedInUser = useReactiveVar(loggedInUserVar)
  const logoLoaded = useReactiveVar(logoLoadedVar)
  const signUpRoute = window.location.pathname === '/sign-up'
  LuxonSettings.defaultZoneName = 'utc'
  const location = useLocation()
  const history = useHistory()
  const [jwt, setJwt] = useState(null)
  const [componentDidMount, setComponentDidMount] = useState(false)
  if (!loggedInUser) {
    loggedInUser = getLocalStorageObj(CONSTANTS.USER_VAR)
    loggedInUserVar(loggedInUser)
  }

  if (config.ENVIRONMENT === 'development') {
    loadDevMessages()
    loadErrorMessages()
  }

  useEffect(() => {
    if (settings) {
      document.title = settings.name
      const authorMetaTag = document.querySelector('meta[name="author"]')
      authorMetaTag.setAttribute('content', settings.name)
      if (
        settings.tenantDomain === 'https://signup.airstudio.io/' &&
        window.location.pathname !== '/'
      ) {
        history.push('/')
      }
    }
  }, [settings])

  const [getSettings, { data: settingsData }] = useLazyQuery(
    gql`
      query PublicSettingsQuery {
        publicSettings {
          id
          name
          airstudioInvoiceRecipient
          chargeProductStateSalesTax
          productTaxRate
          displayLogoInNav
          displayNameInNav
          websiteUrl
          phoneNumber
          openAiEnabled
          showSubjectGroupBookingLogin
          subjectGroupBookingLogin
          tenantSubscription
          thumbnailImageName
          timezone
          locationFormat
          showLocationBooking
          intercomAppId
          showLastNameIdLogin
          tenantActive
          anonymousLogin
          tenantId
          chargeStateSalesTax
          email
          openAiEnabled
          colorScheme
          facebookUrl
          equipmentBarcodes
          equipmentQrCodes
          twitterUrl
          tenantUuid
          linkedinUrl
          instagramUrl
          instagramInFooter
          facebookInFooter
          twitterInFooter
          linkedinInFooter
          tenantDomain
          apiDomain
          regionsVisible
          sessionStages
          jobStages
          requireSubjectEmail
          requireSubjectPhone
          googleCalendarConnectorEnabled
          googleCalendarEnabledForSubjectSessions
          googleCalendarEnabledForEmployeeJobs
          googleCalendarEnabledForOrganizationSessions
          tenantRegions
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (
          !data.publicSettings.tenantActive &&
          location.pathname !== '/inactive'
        ) {
          history.push('/inactive')
        }
        settingsVar(data.publicSettings)
        if (data.publicSettings) {
          timezoneVar(apiToLuxonTimezone(data.publicSettings.timezone))
        } else {
          timezoneVar('US/CENTRAL')
        }
      },
    }
  )

  useEffect(() => {
    if (!componentDidMount) {
      if (!signUpRoute) {
        getSettings()
      }
      setupFilePondPlugins()
      setComponentDidMount(true)
    }
  }, [componentDidMount])

  useInterval(() => {
    if (jwt) {
      const now = new Date().getTime()
      const decodedJwt = decodeJwtPayload(jwt.jwt)
      if (now > decodedJwt.refreshExpiresOn * 1000) {
        loggedInUser.autoLogOut = true
        loggedInUserVar(loggedInUser)
        localStorage.clear()
        history.push('/')
        toast.success('Login Session Has Expired')
        clearLoginVars()
        setJwt()
      } else if (now > jwt.refreshOn) {
        getNewToken()
      }
    }
  }, 1000 * 30)

  useIdleTimer({
    timeout: 1000 * 60 * 60 * 2,
    onIdle: () => {
      if (loggedInUser) {
        loggedInUser.autoLogOut = true
        loggedInUserVar(loggedInUser)
        localStorage.clear()
        history.push('/')
        toast.success('Logged Out Due to Inactivity')
        clearLoginVars()
        setJwt()
      }
    },
    debounce: 500,
  })

  useEffect(() => {
    if (loggedInUser) {
      setJwt(getJwtLocalStorage())
    }
  }, [loggedInUser])

  if (
    (!settingsData && !signUpRoute) ||
    (settingsData?.settings?.thumbnailImageName && !logoLoaded)
  )
    return (
      <Row
        className="justify-content-center align-items-center mt-3"
        style={{ height: '100%' }}
      >
        <Col className="text-center">
          <Loading />
          <div className="mt-2" style={{ fontSize: '14px' }}>
            Loading...
          </div>
        </Col>
      </Row>
    )
  return (
    <div className="App">
      <GoogleMapsProvider>
        <WebSocketProvider>
          <UploadProvider>
            <DownloadProvider>
              <IntercomProvider
                appId={
                  !signUpRoute && settingsData.publicSettings.intercomAppId
                }
              >
                <ProSidebarProvider>
                  <Toaster
                    toastOptions={{
                      duration: 5000,
                      style: {
                        fontSize: '16px',
                      },
                      success: {
                        style: {
                          backgroundColor: '#e6ffe6',
                        },
                      },
                      error: {
                        style: {
                          backgroundColor: '#ffcccb',
                        },
                      },
                    }}
                  >
                    {(t) => (
                      <ToastBar toast={t}>
                        {({ icon, message }) => (
                          <>
                            {icon}
                            {message}
                            {t.type !== 'loading' && (
                              <XCircle onClick={() => toast.dismiss(t.id)} />
                            )}
                          </>
                        )}
                      </ToastBar>
                    )}
                  </Toaster>
                  <Elements stripe={stripePromise}>
                    <Router history={history}>
                      <Routes settings={settings} />
                    </Router>
                  </Elements>
                </ProSidebarProvider>
              </IntercomProvider>
            </DownloadProvider>
          </UploadProvider>
        </WebSocketProvider>
      </GoogleMapsProvider>
    </div>
  )
}

export default App
