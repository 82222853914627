import React, { useState } from 'react'
import { Row, Col, Form, Table } from 'react-bootstrap'
import { gql, useLazyQuery } from '@apollo/client'
import { XCircle, Trash, ExclamationTriangle } from 'react-bootstrap-icons'
import Loading from '../../../common/Loading'
import InfiniteScroll from 'react-infinite-scroll-component'

const JobEquipmentBagTypeCard = (props) => {
  const { formik, index } = props
  const [display, setDisplay] = useState(false)

  const [searchEquipmentBagTypes, { data: data, fetchMore: fetchMore }] =
    useLazyQuery(
      gql`
        query EquipmentBagTypes(
          $after: String
          $first: Int
          $nameIcontains: String
        ) {
          equipmentBagTypes(
            after: $after
            first: $first
            name_Icontains: $nameIcontains
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
      }
    )
  return (
    <>
      <div className="border border-secondary rounded my-3 p-2 bg-white">
        <Row
          className={
            formik.values.jobEquipmentBagTypes.length > 1 ? 'mb-3 mt-3' : 'mb-3'
          }
        >
          <Col md={4}>
            <Form.Label>
              Bag Type
              {formik.errors?.jobEquipmentBagTypes?.length > index &&
                formik.errors.jobEquipmentBagTypes[index] &&
                formik.errors.jobEquipmentBagTypes[index]
                  .equipmentBagTypeName && (
                  <span className="text-danger ml-2">
                    <ExclamationTriangle className="mr-1" />
                    {
                      formik.errors?.jobEquipmentBagTypes[index]
                        ?.equipmentBagTypeName
                    }
                  </span>
                )}
              {formik.values.jobEquipmentBagTypes[index].equipmentBagTypeId && (
                <span
                  type="button"
                  className="p-0 ml-2 btn-link"
                  onClick={() => {
                    formik.setFieldValue(
                      `jobEquipmentBagTypes[${index}].equipmentBagTypeId`,
                      ''
                    )
                    formik.setFieldValue(
                      `jobEquipmentBagTypes[${index}].equipmentBagTypeName`,
                      ''
                    )
                  }}
                >
                  <XCircle />
                </span>
              )}
            </Form.Label>
            <Form.Control
              placeholder="Search Bag Types"
              value={
                formik.values.jobEquipmentBagTypes[index].equipmentBagTypeName
              }
              onBlur={() => {
                setDisplay(false)
                formik.setFieldTouched(
                  `jobEquipmentBagTypes[${index}].equipmentBagTypeId`,
                  true
                )
              }}
              onChange={(e) => {
                setDisplay(true)
                formik.setFieldValue(
                  `jobEquipmentBagTypes[${index}].equipmentBagTypeName`,
                  e.target.value
                )
                searchEquipmentBagTypes({
                  variables: {
                    after: null,
                    first: 250,
                    nameIcontains: e.target.value,
                  },
                })
              }}
              readOnly={Boolean(
                formik.values.jobEquipmentBagTypes[index].equipmentBagTypeId
              )}
              className={
                formik.values.productId
                  ? ' border  form-control form-control-sm'
                  : 'form-control form-control-sm'
              }
            />
            {data?.equipmentBagTypes && display && (
              <InfiniteScroll
                height={100}
                dataLength={data.equipmentBagTypes.edges.length}
                next={() => {
                  fetchMore({
                    variables: {
                      after: data.equipmentBagTypes.pageInfo.endCursor,
                      first: 250,
                      nameIcontains: formik.values.productName,
                    },
                  })
                }}
                hasMore={data?.equipmentBagTypes.pageInfo.hasNextPage}
                loader={<Loading />}
              >
                <Table size="sm" hover>
                  <tbody>
                    {data.equipmentBagTypes.edges.map((jobEquipmentBagType) => {
                      const { node } = jobEquipmentBagType
                      if (
                        formik.values.jobEquipmentBagTypes.filter(
                          (jobEquipmentBagType) =>
                            jobEquipmentBagType.equipmentBagTypeId === node.id
                        ).length === 0
                      ) {
                        return (
                          <tr
                            onMouseDown={() => {
                              setDisplay(false)
                              formik.setFieldValue(
                                `jobEquipmentBagTypes[${index}].equipmentBagTypeId`,
                                node.id
                              )
                              formik.setFieldValue(
                                `jobEquipmentBagTypes[${index}].equipmentBagTypeName`,
                                node.name
                              )
                            }}
                            key={node.id}
                            className="hover text-decoration-none"
                          >
                            <td>
                              <small>{node.name}</small>
                            </td>
                          </tr>
                        )
                      }
                    })}
                  </tbody>
                </Table>
              </InfiniteScroll>
            )}
          </Col>
          <Col md={3}>
            <Form.Label>Required</Form.Label>
            <Form.Control
              type="number"
              className="form-control-sm"
              min={1}
              step={1}
              name={`jobEquipmentBagTypes[${index}].requiredQuantity`}
              value={formik.values.jobEquipmentBagTypes[index].requiredQuantity}
              onChange={formik.handleChange}
              isInvalid={
                formik.errors?.products?.length > index &&
                formik.errors.products[index]?.requiredQuantity
              }
            />
            {formik.errors?.products?.length > index &&
              formik.errors.products[index]?.requiredQuantity && (
                <small className="text-danger">
                  {formik.errors.products[index]?.requiredQuantity}
                </small>
              )}
          </Col>
          <Col md={3}>
            <Form.Label>Included</Form.Label>
            <Form.Control
              type="number"
              style={{
                backgroundColor:
                  formik.values.jobEquipmentBagTypes[index].includedQuantity >=
                  formik.values.jobEquipmentBagTypes[index].requiredQuantity
                    ? 'rgba(192,255,192,0.5)'
                    : 'rgb(255,192,192)',
              }}
              className="form-control-sm"
              name={`jobEquipmentBagTypes[${index}].includedQuantity`}
              disabled={true}
              value={formik.values.jobEquipmentBagTypes[index].includedQuantity}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <p size="sm">
                <small
                  className="btn-link"
                  onClick={() => {
                    const newJobEquipmentBagTypes = [
                      ...formik.values.jobEquipmentBagTypes,
                    ]
                    newJobEquipmentBagTypes.splice(index, 1)
                    formik.setFieldValue(
                      'jobEquipmentBagTypes',
                      newJobEquipmentBagTypes
                    )
                  }}
                >
                  <Trash className="me-1" /> Remove
                </small>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default JobEquipmentBagTypeCard
