// GoogleCalendarConnector.js
import React, { useState } from 'react'
import { Badge, Button, Row, Col } from 'react-bootstrap'
import { Plug } from 'react-bootstrap-icons'
import { useQuery, gql } from '@apollo/client'
import IntegrationCard from '../IntegrationCard'
import EditGoogleCalendarConnector from './EditGoogleCalendarConnector'

const GoogleCalendarConnector = () => {
  const [showEditModal, setShowEditModal] = useState(false)

  const { data } = useQuery(
    gql`
      query GoogleCalendarConnector {
        googleCalendarConnectors {
          edges {
            node {
              id
              enabled
              authValid
              serviceAccount
              adminCalendarId
              enabledForSubjectSessions
              enabledForEmployeeJobs
              enabledForOrganizationSessions
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  if (!data) return null

  const connector = data.googleCalendarConnectors.edges[0].node

  return (
    <>
      {showEditModal && (
        <EditGoogleCalendarConnector
          gCalConnector={connector}
          showModal={showEditModal}
          toggleModal={() => setShowEditModal(!showEditModal)}
        />
      )}

      <IntegrationCard
        logo={`${window.location.origin}/google-calendar.png`}
        name="Google Calendar"
        description="Integrate with Google Calendar to automatically sync your photography sessions and bookings. Keep your schedule organized and up-to-date across all your devices."
      >
        <Row>
          <Col>
            {connector.authValid ? (
              <Badge variant="success">Enabled</Badge>
            ) : (
              <Badge variant="danger">Disabled</Badge>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="link" onClick={() => setShowEditModal(true)}>
              <Plug className="mr-2" />
              Configure
            </Button>
          </Col>
        </Row>
      </IntegrationCard>
    </>
  )
}

export default GoogleCalendarConnector
