import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Table, Form, Button } from 'react-bootstrap'
import { useQuery, useMutation, gql } from '@apollo/client'
import { PlusCircle, Trash, SlashCircle } from 'react-bootstrap-icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { ChromePicker } from 'react-color'
import DeleteTaskStatusModal from './DeleteTaskStatusModal'

const TaskStatusModal = (props) => {
  const { showModal, toggleMogal } = props
  const [showNew, setShowNew] = useState(false)
  const [taskStatusData, setTaskStatusData] = useState([])
  const [editTaskStatus, setEditTaskStatus] = useState(false)
  const [deleteTaskStatus, setDeleteTaskStatus] = useState()

  const { data: queryData } = useQuery(
    gql`
      query TaskStatuses {
        taskStatuses(first: 250, orderBy: "name") {
          edges {
            node {
              id
              name
              color
              initial
              completed
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const [createTaskStatus] = useMutation(
    gql`
      mutation CreateTaskStatusMutations(
        $createTaskStatusInput: CreateTaskStatusInput!
      ) {
        createTaskStatus(input: $createTaskStatusInput) {
          taskStatus {
            id
          }
        }
      }
    `,
    {
      refetchQueries: ['TaskStatuses'],
      onCompleted: () => {
        formik.resetForm()
        setShowNew(false)
        toast.success('Status Saved')
      },
    }
  )

  const [updateTaskStatus] = useMutation(
    gql`
      mutation UpdateTaskStatus(
        $updateTaskStatusInput: UpdateTaskStatusInput!
      ) {
        updateTaskStatus(input: $updateTaskStatusInput) {
          taskStatus {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setShowNew(false)
        formik.resetForm()
        setEditTaskStatus()
        toast.success('Status Saved')
      },
      errorPolicy: 'all',
      refetchQueries: ['TaskStatuses'],
    }
  )

  useEffect(() => {
    if (queryData?.taskStatuses) {
      const data = queryData?.taskStatuses?.edges ?? []
      const transformedData = data.map((status) => ({
        id: status.node.id,
        name: status.node.name,
        color: status.node.color
          ? status.node.color.charAt(0).toUpperCase() +
            status.node.color.slice(1)
          : '',
        originalName: status.node.name,
        originalColor: status.node.color
          ? status.node.color.charAt(0).toUpperCase() +
            status.node.color.slice(1)
          : '',
        initial: status.node.initial,
        completed: status.node.completed,
      }))
      setTaskStatusData(transformedData)
    }
  }, [queryData])

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '',
      id: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      color: Yup.string().required('Required'),
      id: Yup.mixed().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      if (values.id) {
        updateTaskStatus({
          variables: {
            updateTaskStatusInput: {
              taskStatusInput: {
                id: values.id,
                name: values.name,
                color: values.color,
              },
            },
          },
        })
      } else {
        createTaskStatus({
          variables: {
            createTaskStatusInput: {
              taskStatusInput: {
                name: values.name,
                color: values.color,
              },
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (editTaskStatus) {
      formik.setFieldValue('name', editTaskStatus.name)
      formik.setFieldValue('color', editTaskStatus.color)
      formik.setFieldValue('id', editTaskStatus.id)
      setShowNew(true)
    }
  }, [editTaskStatus])

  const onHide = () => {
    formik.resetForm()
    setShowNew(false)
    toggleMogal()
  }

  if (!taskStatusData) {
    return (
      <Modal size="lg" show={showModal} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body />
      </Modal>
    )
  }

  return (
    <>
      <DeleteTaskStatusModal
        showModal={deleteTaskStatus}
        toggleModal={setDeleteTaskStatus}
        taskStatus={deleteTaskStatus}
      />
      <Modal size="lg" show={showModal} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title closeButton>Task Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {!showNew && (
                <Button
                  variant="link"
                  className="mb-2 pl-0"
                  onClick={() => {
                    setShowNew(true)
                    formik.setFieldValue('color', '#000000')
                  }}
                >
                  <PlusCircle className="mr-2" />
                  New Task Status
                </Button>
              )}
              <Form className={showNew ? 'mb-3' : 'd-none'}>
                <Row className="justify-content-center">
                  <Col md={4}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      size="sm"
                      name="name"
                      placeholder="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                  <Col md={4}>
                    <Form.Label>Color</Form.Label>
                    <div>
                      {formik.errors.color && (
                        <small className="text-danger">
                          {formik.errors.color}
                        </small>
                      )}
                    </div>
                    <ChromePicker
                      onChange={(e) => {
                        formik.setFieldValue('color', e.hex)
                      }}
                      color={formik.values.color}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                  <Col md={4} className="d-flex justify-content-around">
                    <Button
                      variant="link"
                      size="sm"
                      className="mt-n1"
                      onClick={formik.handleSubmit}
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                    <Button
                      variant="link"
                      size="sm"
                      className="mt-n1"
                      onClick={() => {
                        setShowNew(false)
                        setEditTaskStatus()
                        formik.resetForm()
                      }}
                    >
                      <SlashCircle className="mr-2" />
                      Close
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Color</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {taskStatusData.map((taskStatus, index) => (
                    <tr key={taskStatus.id}>
                      <td>{taskStatus.name}</td>
                      <td>
                        <div style={{ color: taskStatus.originalColor }}>
                          {taskStatus.originalColor}
                        </div>
                      </td>
                      <td>
                        <Button
                          variant="link"
                          size="sm"
                          className="ps-0 mt-n1"
                          onClick={() => {
                            setEditTaskStatus(taskStatus)
                            setShowNew(true)
                          }}
                        >
                          <PlusCircle className="mr-2" />
                          Edit
                        </Button>
                      </td>
                      <td>
                        {!(taskStatus.initial || taskStatus.completed) && (
                          <span
                            onClick={() => setDeleteTaskStatus(taskStatus)}
                            className="btn-link"
                            style={{ cursor: 'pointer' }}
                          >
                            <Trash /> Delete
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default TaskStatusModal
