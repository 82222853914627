import React from 'react'
import { Modal } from 'react-bootstrap'
import CustomFieldSetForm from './CustomFieldSetForm'
import { Database } from 'react-bootstrap-icons'

const CustomFieldSetModal = (props) => {
  const { showModal, toggleModal, fieldSet, onSubmit } = props
  if (!showModal) return <></>

  return (
    <>
      <div id="customFieldSetModal">
        <Modal size="lg" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Database className="mr-2" />
              {fieldSet ? (
                <>Update Custom Field Set</>
              ) : (
                <>New Custom Field Set</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomFieldSetForm
              onSubmit={onSubmit}
              fieldSet={fieldSet}
              afterSubmit={toggleModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default CustomFieldSetModal
