import * as Yup from 'yup'
import { DateTime } from 'luxon'
import validator from 'validator'
import { client } from '../../../libs/apollo'
import { gql } from '@apollo/client'

function isMultipleOfFive(timeString) {
  const timeRegex = /^(\d{1,2}):?(\d{2})([ap]m)$/i
  const match = timeString.match(timeRegex)

  if (!match) {
    return false
  }

  const [_, hours, minutes, period] = match

  let hourValue = parseInt(hours)
  if (hourValue === 12) {
    hourValue = 0
  }

  if (period.toLowerCase() === 'pm') {
    hourValue += 12
  }

  const totalMinutes = hourValue * 60 + parseInt(minutes)
  return totalMinutes % 5 === 0
}

export const schema = (settings, loggedInUser) => {
  return Yup.object().shape({
    id: Yup.string().nullable(),
    multiDayJobCopySessions: Yup.boolean().nullable(),
    name: Yup.string()
      .required('required')
      .test('locationId', 'job names cannot include commas', (value) => {
        if (value && value.includes(',')) {
          return false
        } else {
          return true
        }
      }),
    regionId: Yup.string().test('regionId', 'required', (value) => {
      let valid = true
      if (loggedInUser?.canManageRegions && settings.tenantRegions && !value) {
        valid = false
      }
      return valid
    }),
    jobTypeId: Yup.string(),
    copyTasks: Yup.bool().nullable(),
    sharedCanSeeFiles: Yup.bool(),
    sharedCanCreateFiles: Yup.bool(),
    sharedCanCreateFolders: Yup.bool(),
    sessionSharedCanSeeFiles: Yup.bool(),
    sessionSharedCanCreateFiles: Yup.bool(),
    sessionSharedCanCreateFolders: Yup.bool(),
    notificationsEnabled: Yup.bool(),
    locationValue: Yup.string(),
    locationId: Yup.string().test(
      'locationId',
      'required',
      (value, context) => {
        if (!context.parent.manualLocation.addressLineOne && !value) {
          return false
        } else {
          return true
        }
      }
    ),
    manualLocation: Yup.object().nullable(),
    jobDate: Yup.date().required('required'),
    bookingStartDate: Yup.date()
      .nullable()
      .test('required', 'required', (value, context) => {
        if (
          (context.parent.subjectGroups.length > 0 ||
            context.parent.packageCategories.length > 0) &&
          !value
        ) {
          return false
        } else {
          return true
        }
      }),
    bookingEndDate: Yup.date().nullable(),
    jobStartTime: Yup.string()
      .matches(
        /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
        'required'
      )
      .required('required')
      .test('modFive', 'minutes must be divisible by 5', (value) => {
        let valid = false
        if (value) {
          try {
            if (value === 0 || parseInt(value.split(':')[1]) % 5 === 0) {
              valid = true
            }
          } catch {
            //
          }
        }
        return valid
      })
      .required('required'),
    jobEndTime: Yup.string()
      .matches(
        /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
        'required'
      )
      .required('required')
      .test('modFive', 'minutes must be divisible by 5', (value) => {
        let valid = false
        if (value) {
          try {
            if (value === 0 || parseInt(value.split(':')[1]) % 5 === 0) {
              valid = true
            }
          } catch {
            //
          }
        }
        return valid
      })
      .test('state', 'end time is before start time', (value, context) => {
        if (
          context.parent.jobStartTime?.match(
            /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
          ) &&
          value?.match(/^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i)
        ) {
          const startTimeLuxon = DateTime.fromFormat(
            `${context.parent.jobStartTime}`,
            'h:ma'
          )
          const endTimeLuxon = DateTime.fromFormat(`${value}`, 'h:ma')
          if (endTimeLuxon.diff(startTimeLuxon, 'minutes').minutes < 0) {
            return false
          }
        }
        return true
      }),
    setups: Yup.number().required('required').positive().integer(),
    packageCategories: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          id: Yup.string(),
          packages: Yup.array().of(
            Yup.object().shape({
              id: Yup.string(),
              title: Yup.string(),
            })
          ),
        })
      )
      .test(
        'category test',
        'cannot add duplicate package categories',
        (value) => {
          return (
            value.filter(
              (e, i, a) => a.findIndex((cat) => cat.id === e.id) !== i
            ).length === 0
          )
        }
      ),
    subjectGroups: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().test('isRequired', 'required', (value) => {
            let valid = true
            if (!value) {
              valid = false
            }
            return valid
          }),
          serialNumber: Yup.string().nullable(),
          notes: Yup.string().nullable(),
          categoryName: Yup.string().nullable(),
          categoryId: Yup.string().nullable(),
          employeeName: Yup.string().nullable(),
          employeeId: Yup.string().nullable(),
          itemTypeName: Yup.string().nullable(),
          returned: Yup.boolean().nullable(),
          itemTypeId: Yup.string().nullable(),
        })
      )
      .test(
        'subjectGroupUnique',
        'cannot add the same subject group twice',
        (value) => {
          let noDups = true
          const sgIds = []
          value.forEach((value) => {
            if (value.subjectGroupId && !sgIds.includes(value.subjectGroupId)) {
              sgIds.push(value.subjectGroupId)
            } else if (value.id && !sgIds.includes(value.id)) {
              sgIds.push(value.subjectGroupId)
            } else {
              noDups = false
            }
          })
          return noDups
        }
      ),
    employeeJobs: Yup.array()
      .of(
        Yup.object().shape({
          sendNotification: Yup.boolean(),
          sendEquipmentNotification: Yup.boolean(),
          employeeId: Yup.string(),
          roleId: Yup.string(),
          hourlyPay: Yup.mixed().nullable(),
          equipmentItems: Yup.array().of(
            Yup.object().shape({
              id: Yup.string(),
              name: Yup.string(),
              dueDate: Yup.date().nullable(),
              serialNumber: Yup.string(),
              category: Yup.string(),
              itemType: Yup.string(),
              scanned: Yup.boolean().nullable(),
              returned: Yup.boolean().nullable(),
              checkedIn: Yup.boolean().nullable(),
            })
          ),
          equipmentBags: Yup.array().of(
            Yup.object().shape({
              id: Yup.string(),
              bagTypeId: Yup.string(),
              name: Yup.string(),
              dueDate: Yup.date().nullable(),
              scanned: Yup.boolean().nullable(),
              returned: Yup.boolean().nullable(),
              checkedIn: Yup.boolean().nullable(),
            })
          ),
          equipmentPickupArea: Yup.string(),
          empJobStartTime: Yup.string(),
          empJobEndTime: Yup.string(),
        })
      )
      .test(
        'duplicate empJob',
        'Cannot include duplicate staff or equipment',
        (value) => {
          if (value.length > 0) {
            const assignedStaff = value.filter((empJob) => {
              return empJob.employeeId
            })
            const duplicateStaff =
              assignedStaff.filter(
                (empJob, i, array) =>
                  array.findIndex(
                    (el) => el.employeeId === empJob.employeeId
                  ) !== i
              ).length > 0
            const items = value.map((empJob) => empJob.equipmentItems).flat()
            const duplicateItems = items.some((item, index) => {
              return (
                items.findIndex((el, i) => i !== index && el.id === item.id) !==
                -1
              )
            })
            const bags = value.map((empJob) => empJob.equipmentBags).flat()
            const duplicateBags = bags.some((bag, index) => {
              return (
                bags.findIndex((b, i) => i !== index && b.id === bag.id) !== -1
              )
            })
            if (duplicateStaff || duplicateItems || duplicateBags) {
              return false
            }
          }
          return true
        }
      )
      .test(
        'Invalid time format',
        'Employee start and end times minutes must be divisible by 5',
        (value, context) => {
          let isValid = true
          if (value.length > 0) {
            value.map((empJob) => {
              if (
                empJob.empJobStartTime &&
                !empJob?.empJobStartTime?.match(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                )
              ) {
                isValid = false
              } else if (
                empJob.empJobStartTime &&
                empJob?.empJobStartTime?.match(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                ) &&
                context.parent.jobStartTime &&
                context?.parent?.jobStartTime.match(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                )
              ) {
                let isStartTimeValid =
                  empJob.empJobStartTime &&
                  isMultipleOfFive(empJob?.empJobStartTime)
                if (!isStartTimeValid) {
                  isValid = false
                }
              }

              if (
                empJob.empJobEndTime &&
                !empJob?.empJobEndTime.match(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                )
              ) {
                isValid = false
              } else if (
                empJob.empJobEndTime &&
                empJob?.empJobEndTime?.match(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                ) &&
                context.parent.jobEndTime &&
                context?.parent?.jobEndTime.match(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
                  'required'
                )
              ) {
                let isEndTimeValid =
                  empJob.empJobEndTime &&
                  isMultipleOfFive(empJob?.empJobEndTime)
                if (!isEndTimeValid) {
                  isValid = false
                }
              }
            })
          }
          return isValid
        }
      ),
    jobNotes: Yup.string(),
    buffers: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          startDateTime: Yup.string()
            .matches(
              /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
              'required'
            )
            .required('required')
            .test('modFive', 'minutes must be divisible by 5', (value) => {
              let valid = true
              if (value) {
                try {
                  if (value === 0 || parseInt(value.split(':')[1]) % 5 === 0) {
                    valid = true
                  } else {
                    valid = false
                  }
                } catch {
                  valid = false
                }
              }
              return valid
            }),
          endDateTime: Yup.string()
            .matches(
              /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
              'required'
            )
            .test('modFive', 'minutes must be divisible by 5', (value) => {
              let valid = true
              if (value) {
                try {
                  if (value === 0 || parseInt(value.split(':')[1]) % 5 === 0) {
                    valid = true
                  } else {
                    valid = false
                  }
                } catch {
                  valid = false
                }
              }
              return valid
            })
            .required('required'),
        })
      )

      .test(
        'time conflict buffer start',
        'Buffers must be between job start and end time and their minutes must be divisible by 5',
        (value, context) => {
          let jobStartTimeLuxon
          let jobEndTimeLuxon
          let result = true
          if (
            context.parent.jobStartTime?.match(
              /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
            )
          ) {
            jobStartTimeLuxon = DateTime.fromFormat(
              `${context.parent.jobStartTime}`,
              'h:ma'
            )
            jobEndTimeLuxon = DateTime.fromFormat(
              `${context.parent.jobEndTime}`,
              'h:ma'
            )
          }
          value.forEach((buffer) => {
            if (
              buffer &&
              buffer.startDateTime &&
              buffer.endDateTime &&
              buffer.startDateTime.match(
                /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
              ) &&
              buffer.endDateTime.match(
                /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
              )
            ) {
              let isStartTimeValid =
                buffer.startDateTime && isMultipleOfFive(buffer.startDateTime)
              let isEndTimeValid =
                buffer.endDateTime && isMultipleOfFive(buffer.endDateTime)
              if (!isEndTimeValid || !isStartTimeValid) {
                result = false
              }
            }
          })
          if (jobStartTimeLuxon && jobEndTimeLuxon) {
            value.forEach((buff) => {
              if (
                buff.startDateTime &&
                buff.endDateTime &&
                buff.startDateTime?.match(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
                ) &&
                buff.endDateTime?.match(
                  /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
                )
              ) {
                const startTimeLuxon = DateTime.fromFormat(
                  `${buff.startDateTime}`,
                  'h:ma'
                )
                const endTimeLuxon = DateTime.fromFormat(
                  `${buff.endDateTime}`,
                  'h:ma'
                )
                if (endTimeLuxon.diff(startTimeLuxon, 'minutes').minutes < 0) {
                  result = false
                }
                if (
                  startTimeLuxon.diff(jobStartTimeLuxon, 'minutes').minutes < 0
                ) {
                  result = false
                }
                if (endTimeLuxon.diff(jobEndTimeLuxon, 'minutes').minutes > 0) {
                  result = false
                }
              }
            })
          }

          return result
        }
      ),
    organizationContacts: Yup.array().of(Yup.string()),
    sessions: Yup.array().of(
      Yup.object().shape({
        newSession: Yup.boolean(),
        sharedCanSeeFiles: Yup.bool(),
        sharedCanCreateFiles: Yup.bool(),
        sharedCanCreateFolders: Yup.bool(),
        customSessionPackage: Yup.boolean().nullable(),
        subjectId: Yup.string()
          .test('subjectId', 'required', (value, context) => {
            if (context.parent.subjectSession && !value) {
              return false
            } else {
              return true
            }
          })
          .test('subjectId', 'Credit Card Required', async (value, context) => {
            let valid = true
            if (value && context.parent.subjectSession) {
              let schedulingPolicies
              if (context.parent.subjectGroupId) {
                const { data } = await client.query({
                  query: gql`
                    query SubjectGroup($subjectGroupId: ID!) {
                      subjectGroup(id: $subjectGroupId) {
                        id
                        schedulingPolicies {
                          applyPolicyFree
                          applyPolicyPaid
                          applyNoShowPolicyPaid
                          applyNoShowPolicyFree
                          noShowFee
                          refundPolicy
                          timeRefundFee
                        }
                      }
                    }
                  `,
                  fetchPolicy: 'cache-first',
                  variables: {
                    subjectGroupId: context.parent.subjectGroupId,
                  },
                })
                schedulingPolicies = data.subjectGroup.schedulingPolicies
              } else if (context.parent.sessionOrgId) {
                const { data } = await client.query({
                  query: gql`
                    query OrganizationDetail($organizationId: ID!) {
                      organization(id: $organizationId) {
                        id
                        schedulingPolicies {
                          applyPolicyFree
                          applyPolicyPaid
                          applyNoShowPolicyPaid
                          applyNoShowPolicyFree
                          noShowFee
                          refundPolicy
                          timeRefundFee
                        }
                      }
                    }
                  `,
                  fetchPolicy: 'cache-first',
                  variables: {
                    organizationId: context.parent.sessionOrgId,
                  },
                })
                schedulingPolicies = data.organization.schedulingPolicies
              } else {
                const { data } = await client.query({
                  query: gql`
                    query SettingsQuery {
                      settings {
                        edges {
                          node {
                            id
                            applyPolicyFree
                            applyPolicyPaid
                            applyNoShowPolicyPaid
                            applyNoShowPolicyFree
                            noShowFee
                            refundPolicy
                            timeRefundFee
                          }
                        }
                      }
                    }
                  `,
                  fetchPolicy: 'cache-first',
                })
                schedulingPolicies = data.settings.edges[0].node
              }
              if (
                typeof context.parent.sessionPackagePrice === 'number' &&
                context.parent.subjectSession &&
                context.parent.sessionPackageId &&
                context.parent.billSubject == true &&
                !context.parent.stripePaymentMethodResource &&
                ((context.parent.finalDollarAmount > 0 &&
                  !context.parent.waiveBookingFee &&
                  !context.parent.ogWaiveBookingFee) ||
                  (context.parent.finalDollarAmount > 0 &&
                    schedulingPolicies?.applyNoShowPolicyPaid &&
                    schedulingPolicies?.noShowFee > 0 &&
                    !context.parent.waiveNoShowFee &&
                    !context.parent.ogWaiveNoShowFee) ||
                  (context.parent.finalDollarAmount == 0 &&
                    schedulingPolicies?.applyNoShowPolicyFree &&
                    !context.parent.waiveNoShowFee &&
                    schedulingPolicies?.noShowFee > 0 &&
                    !context.parent.ogWaiveNoShowFee) ||
                  (context.parent.finalDollarAmount > 0 &&
                    schedulingPolicies?.applyPolicyPaid &&
                    schedulingPolicies?.refundPolicy !== 'ALWAYS' &&
                    schedulingPolicies?.timeRefundFee > 0 &&
                    !context.parent.waiveRescheduleCancelFee &&
                    !context.parent.ogWaiveRescheduleCancelFee) ||
                  (context.parent.finalDollarAmount === 0 &&
                    schedulingPolicies?.applyPolicyFree &&
                    schedulingPolicies?.refundPolicy !== 'ALWAYS' &&
                    schedulingPolicies?.timeRefundFee > 0 &&
                    !context.parent.waiveRescheduleCancelFee &&
                    !context.parent.ogWaiveRescheduleCancelFee))
              ) {
                valid = false
              }
            }
            return valid
          }),
        // stripePaymentMethodResource: Yup.object(),
        subjectOrgName: Yup.string(),
        orgContacts: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            email: Yup.string(),
            fullName: Yup.string(),
            phoneNumber: Yup.string(),
          })
        ),
        subjectOrgId: Yup.string(),
        sessionOrgId: Yup.string(),
        subjectSession: Yup.boolean(),
        codesToDisplay: Yup.array(),
        subjectGroupId: Yup.string(),
        subjectName: Yup.string(),
        sessionId: Yup.string().nullable(),
        organizationId: Yup.string().test(
          'subjectId',
          'required',
          (value, context) => {
            if (!context.parent.subjectSession && !value) {
              return false
            } else {
              return true
            }
          }
        ),
        organizationName: Yup.string(),
        finalDollarAmount: Yup.number().nullable(),
        sessionPackageId: Yup.string().required('required'),
        sessionPackageName: Yup.string(),
        sessionPackagePrice: Yup.number().nullable(),
        displayPromo: Yup.boolean(),
        packageCategoryId: Yup.string(),
        couponId: Yup.string(),
        startTime: Yup.string()
          .matches(
            /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
            'required'
          )
          .test('modFive', 'minutes must be divisible by 5', (value) => {
            let valid = true
            if (value) {
              try {
                if (value === 0 || parseInt(value.split(':')[1]) % 5 === 0) {
                  valid = true
                } else {
                  valid = false
                }
              } catch {
                valid = false
              }
            }
            return valid
          })
          .required('required'),
        endTime: Yup.string()
          .nullable()
          .when('customSessionPackage', {
            is: true,
            then: Yup.string().required('required'),
          })
          .matches(
            /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
            'required'
          )
          .test(
            'modFive',
            'minutes must be divisible by 5',
            (value, context) => {
              if (!context.parent.customSessionPackage) {
                return true
              }
              let valid = true
              if (value) {
                try {
                  if (value === 0 || parseInt(value.split(':')[1]) % 5 === 0) {
                    valid = true
                  } else {
                    valid = false
                  }
                } catch {
                  valid = false
                }
              }
              return valid
            }
          )
          .test('state', 'end time is before start time', (value, context) => {
            if (!context.parent.customSessionPackage) {
              return true
            }
            if (
              context.parent.jobStartTime?.match(
                /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
              ) &&
              value?.match(
                /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
              )
            ) {
              const startTimeLuxon = DateTime.fromFormat(
                `${context.parent.jobStartTime}`,
                'h:ma'
              )
              const endTimeLuxon = DateTime.fromFormat(`${value}`, 'h:ma')
              if (endTimeLuxon.diff(startTimeLuxon, 'minutes').minutes < 0) {
                return false
              }
            }
            return true
          }),
      })
    ),
    adHocContacts: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        firstName: Yup.string().required('required'),
        lastName: Yup.string().nullable(),
        secondaryPhone: Yup.string().test(
          'isPhoneNumber',
          'Invalid phone number',
          (value) => {
            let valid = true
            if (value && !validator.isMobilePhone(value, 'en-US')) {
              valid = false
            }
            return valid
          }
        ),
        primaryPhone: Yup.string().test(
          'isPhoneNumber',
          'Invalid phone number',
          (value) => {
            let valid = true
            if (value && !validator.isMobilePhone(value, 'en-US')) {
              valid = false
            }
            return valid
          }
        ),
        primaryEmail: Yup.string().test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
        secondaryEmail: Yup.string().test(
          'isEmail',
          'Invalid email',
          (value) => {
            let valid = true
            if (value && !validator.isEmail(value)) {
              valid = false
            }
            return valid
          }
        ),
        notes: Yup.string().nullable(),
      })
    ),
    jobEquipmentBagTypes: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        requiredQuantity: Yup.number().required('required').min(1),
        includedQuantity: Yup.number().nullable(),
        equipmentBagTypeId: Yup.string().nullable().required('required'),
        equipmentBagTypeName: Yup.string().nullable().required('required'),
      })
    ),
    jobEquipmentItemTypes: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        requiredQuantity: Yup.number().required('required').min(1),
        includedQuantity: Yup.number().nullable(),
        equipmentItemTypeId: Yup.string().nullable().required('required'),
        equipmentItemTypeName: Yup.string().nullable().required('required'),
      })
    ),
    multiDayJobDates: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required('required'),
      })
    ),
  })
}
