import React, { createContext, useState, useEffect, useContext } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { useQuery, gql } from '@apollo/client'
import config from '../config'

export function useGoogleMapsKey() {
  const [googleMapsKey, setGoogleMapsKey] = useState()
  useQuery(
    gql`
      query DecryptSecrets($encryptedSecrets: [String]!) {
        decryptSecrets(encryptedSecrets: $encryptedSecrets) {
          encryptedSecret
          decryptedSecret
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      variables: {
        encryptedSecrets: [config.GOOGLE_MAPS_KEY],
      },
      onCompleted(data) {
        if (data?.decryptSecrets) {
          setGoogleMapsKey(data.decryptSecrets[0].decryptedSecret)
        }
      },
    }
  )
  if (!googleMapsKey) return
  return googleMapsKey
}

export const GoogleMapsContext = createContext(null)

export const GoogleMapsProvider = ({ children }) => {
  const [googleMaps, setGoogleMapsKey] = useState(null)
  const googleMapsKey = useGoogleMapsKey()

  useEffect(() => {
    if (googleMapsKey) {
      const loader = new Loader({
        apiKey: googleMapsKey,
        version: 'weekly',
        libraries: ['places'],
      })

      loader.load().then((google) => {
        setGoogleMapsKey(google.maps)
      })
    }
  }, [googleMapsKey])

  const contextValue = {
    googleMaps,
  }

  return (
    <GoogleMapsContext.Provider value={contextValue}>
      {children}
    </GoogleMapsContext.Provider>
  )
}

export const useGoogleMaps = () => {
  const context = useContext(GoogleMapsContext)

  if (!context) {
    throw new Error('useGoogleMaps must be used within a GoogleMapsProvider')
  }

  return context
}
