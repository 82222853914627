import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import SelectSessionPackage from './SelectSessionPackage'
import CreateSessionSelectSession from './CreateSessionSelectSession'
import SelectResitReason from './SelectResitReason'
import SessionConfirmation from './SessionConfirmation'

const ScheduleResitModal = (props) => {
  const {
    scheduleResitSessionId,
    subjectId,
    subjectGroupName,
    organizationId,
    subjectGroupId,
    packageCategoryId,
    showModal,
    toggleModal,
    adminViewing,
    stripeCard,
    setHideParentCardElement,
    resitsIncludeFreeSessions,
    freePackageResitFee,
  } = props
  const [bookingStage, setBookingStage] = useState('resitReason')
  const [sessionPackage, setSessionPackage] = useState()
  const [resitReason, setResitReason] = useState()
  const [jobId, setJobId] = useState()
  const [sessionStartDateTime, setSessionStartDateTime] = useState()
  const [isUpsellSelected, setIsUpsellSelected] = useState(false)
  const [timezone, setTimezone] = useState()
  useEffect(() => {
    if (showModal === false) {
      setResitReason()
      setSessionPackage()
      setJobId()
      setSessionStartDateTime()
      setTimezone()
      setBookingStage('resitReason')
    }
    if (showModal && setHideParentCardElement) {
      setHideParentCardElement(true)
    }
    if (!showModal && setHideParentCardElement) {
      setHideParentCardElement(false)
    }
  }, [showModal])
  if (showModal) {
    return (
      <>
        <div className="bookSessionModal">
          <Modal
            size="lg"
            show={showModal}
            onHide={() => toggleModal()}
            aria-labelledby="new-title"
          >
            <Modal.Header closeButton>
              <h4>
                {subjectGroupName ? (
                  <>Schedule {subjectGroupName} Resit</>
                ) : (
                  <>Schedule Resit</>
                )}
              </h4>
            </Modal.Header>
            <Modal.Body>
              <>
                {bookingStage === 'resitReason' && (
                  <SelectResitReason
                    setResitReason={setResitReason}
                    setBookingStage={setBookingStage}
                    sessionPackage={sessionPackage}
                  />
                )}
                {bookingStage === 'sessionPackage' && (
                  <SelectSessionPackage
                    subjectId={subjectId}
                    freePackageResitFee={freePackageResitFee}
                    subjectGroupId={subjectGroupId}
                    packageCategoryId={!subjectGroupId && packageCategoryId}
                    setIsUpsellSelected={setIsUpsellSelected}
                    isUpsellSelected={isUpsellSelected}
                    resitReason={resitReason}
                    setResitReason={setResitReason}
                    setSessionPackage={setSessionPackage}
                    sessionPackage={sessionPackage}
                    setBookingStage={setBookingStage}
                    resitSessionId={scheduleResitSessionId}
                    resitsIncludeFreeSessions={resitsIncludeFreeSessions}
                  />
                )}
                {bookingStage === 'session' && (
                  <CreateSessionSelectSession
                    subjectId={subjectId}
                    showModal={showModal}
                    subjectGroupId={subjectGroupId}
                    setSessionPackage={setSessionPackage}
                    sessionPackage={sessionPackage}
                    setBookingStage={setBookingStage}
                    resitReasonId={resitReason.id}
                    setResitReason={setResitReason}
                    toggleModal={toggleModal}
                    setJobId={setJobId}
                    previousSessionId={scheduleResitSessionId}
                    setSessionStartDateTime={setSessionStartDateTime}
                    resitSessionId={scheduleResitSessionId}
                    timezone={timezone}
                    setTimezone={setTimezone}
                  />
                )}
                {bookingStage === 'confirmation' && (
                  <SessionConfirmation
                    showModal={showModal}
                    isUpsellSelected={isUpsellSelected}
                    jobId={jobId}
                    sessionStartDateTime={sessionStartDateTime}
                    sessionPackageId={sessionPackage.id}
                    setSessionStartDateTime={setSessionStartDateTime}
                    setSessionPackage={setSessionPackage}
                    setBookingStage={setBookingStage}
                    setJobId={setJobId}
                    subjectGroupId={subjectGroupId}
                    subjectId={subjectId}
                    toggleModal={toggleModal}
                    previousSessionId={scheduleResitSessionId}
                    previousSessionResit
                    adminViewing={adminViewing}
                    resitReason={resitReason}
                    organizationId={organizationId}
                    stripeCard={stripeCard}
                    timezone={timezone}
                  />
                )}
                {/* {bookingStage === 'confirmation'
                ? (
                    <SessionConfirmation
                      showModal={showModal}
                      jobId={jobId}
                      sessionStartDateTime={sessionStartDateTime}
                      sessionPackageId={sessionPackage.id}
                      setSessionStartDateTime={setSessionStartDateTime}
                      setBookingStage={setBookingStage}
                      setJobId={setJobId}
                      subjectGroupId={subjectGroupId}
                      subjectId={subjectId}
                      toggleModal={toggleModal}
                      previousSessionId={scheduleResitSessionId}
                      previousSessionResit
                      adminViewing={adminViewing}
                      resitReason={resitReason}
                      setSessionPackage={setSessionPackage}
                      setResitReason={setResitReason}
                      stripeCard={stripeCard}
                    />
                  )
                : null
              } */}
              </>
            </Modal.Body>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default ScheduleResitModal
