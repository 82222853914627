import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import DateFilter from '../../common/DateFilter'
import { Trash } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { useFormik } from 'formik'
import { formatTimezone } from '../../../libs/utils'

const EmployeeRoleAssignments = (props) => {
  const { showModal } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [startDateFilter, setStartDateFilter] = useState()
  const [selectedRoles, setSelectedRoles] = useState([])

  const { data: roleData } = useQuery(
    gql`
      query Roles {
        roles(orderBy: "name", first: 250) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [getRoleAssignments, { data: roleAssignmentsData, loading }] =
    useLazyQuery(
      gql`
        query RoleAssignments(
          $startDate: DateTime!
          $roleIds: [ID]
          $regionId: ID
        ) {
          roleAssignments(
            startDate: $startDate
            roleIds: $roleIds
            regionId: $regionId
          ) {
            date
            roleAssignments {
              role {
                name
                id
              }
              count
            }
          }
        }
      `,
      {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      }
    )

  const formik = useFormik({
    initialValues: {
      regionId: loggedInUser?.defaultRegion?.id,
      timezone: loggedInUser?.defaultRegion?.formattedTimezone,
    },
    validateOnChange: false,
  })

  useEffect(() => {
    if (startDateFilter) {
      const variables = {
        startDate: startDateFilter,
        roleIds: selectedRoles.map((role) => role.id),
      }
      if (formik.values.regionId) {
        variables.regionId = formik.values.regionId
      }
      getRoleAssignments({ variables })
    }
  }, [startDateFilter, selectedRoles, formik.values.regionId])

  useEffect(() => {
    if (!showModal) {
      setStartDateFilter()
      setSelectedRoles()
    }
  }, [showModal])

  if (!roleData) return <></>

  return (
    <>
      <Row className="mt-4 mb-1">
        <Col md={2}>
          <DateFilter
            oneDate
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            placeholderStart="Date"
            timezone={formik.values.timezone}
          />
        </Col>
        <Col md={2} className="mt-2">
          <Form.Group>
            <Form.Control
              as="select"
              name="bookSession"
              className="form-control form-control-sm"
              onChange={(e) => {
                if (e.target.value) {
                  const role = roleData.roles.edges.filter(
                    (edge) => edge.node.id === e.target.value
                  )[0]
                  setSelectedRoles((prevState) => [...prevState, role.node])
                }
              }}
            >
              <option value="">Select Roles</option>
              {roleData.roles.edges.map((role) => {
                const selected = selectedRoles.some(
                  (roleItem) => roleItem.id === role.node.id
                )
                if (!selected) {
                  return (
                    <option key={role.node.id} value={role.node.id}>
                      {role.node.name}
                    </option>
                  )
                }
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        {loggedInUser?.canManageRegions && settings?.tenantRegions && (
          <Col md={2} className="mt-2">
            <RegionSearchInput
              formik={formik}
              placeholder="Select Region"
              mustHaveValue={
                loggedInUser.permissions.group === 'Administrator'
                  ? false
                  : true
              }
              dropdown
              setAdditionalFields={(node, _) => {
                if (node?.timezone) {
                  formik.setFieldValue(
                    'timezone',
                    formatTimezone(node.timezone)
                  )
                }
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <div style={{ fontSize: '18px' }} className="mb-2">
            {selectedRoles?.map(({ name, id }, i) => (
              <span
                style={{
                  padding: '5px',
                  border: '.5px solid #6c757d',
                  borderRadius: '0.25rem',
                  marginLeft: i > 0 ? '0.5rem' : '0',
                }}
                key={i}
              >
                <Trash
                  className="mr-2 btn-link"
                  size={14}
                  onClick={() => {
                    const updatedRoles = []
                    selectedRoles.forEach((role) => {
                      if (role.id !== id) {
                        updatedRoles.push(role)
                      }
                    })
                    setSelectedRoles(updatedRoles)
                  }}
                />
                <span style={{ fontSize: '14px' }}>{name}</span>
              </span>
            ))}
          </div>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <Loading message="Loading Role Assignments..." />
          </Col>
        </Row>
      )}
      {roleAssignmentsData && startDateFilter && (
        <Row className="mt-2">
          <Col>
            <SortableInfiniteTable
              tableData={roleAssignmentsData.roleAssignments[0].roleAssignments.filter(
                (roleAssignments) => roleAssignments.count > 0
              )}
              tableColumns={[
                {
                  Header: 'Role',
                  id: 'role',
                  accessor: (row) => {
                    return row.role.name
                  },
                },
                {
                  Header: 'Assignments',
                  id: 'assignments',
                  accessor: (row) => {
                    return row.count
                  },
                },
              ]}
              tableHeight={350}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default EmployeeRoleAssignments
