import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import BusinessInfo from './business_info/BusinessInfo'
import EmailSetting from './email/EmailSetting'
import OpenAI from './OpenAI'
import Blueprint from './blueprint/Blueprint'
import TwilioConnector from './twilio/TwilioConnector'
import IntegrationCard from './IntegrationCard'
import Stripe from './stripe/Stripe'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import Intercom from './intercom/Intercom'
import Tabs from '../common/Tabs'
import { Tab, Row, Col, Button } from 'react-bootstrap'
import GoogleCalendarConnector from './google_calendar/GoogleCalendarConnector'
import TenantInvoiceTable from '../payments/tenant/TenantInvoiceTable'
import AuditLog from './AuditLog'
import API from './api/API'
import {
  Gear,
  Plug,
  ClockHistory,
  Cash,
  Cloud,
  Tags,
  ShieldCheck,
  EmojiFrown,
  Database,
  QuestionCircle,
  Globe,
} from 'react-bootstrap-icons'
import Loading from '../common/Loading'
import Subscription from './Subscription'
import TagTable from '../tag/TagTable'
import Policies from './policies/Policies'
import SessionResitReasons from './resit_reason/SessionResitReasons'
import Regions from './region/Regions'
import CustomFieldSets from './custom_field_set/CustomFieldSets'

const StudioSettings = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const cachedSettings = useReactiveVar(settingsVar)
  const [activeTab, setActiveTab] = useState('StudioInfo')
  const [subscriptionActiveTab, setSubscriptionActiveTab] = useState('Plan')
  const admin = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const { loading, error, data } = useQuery(
    gql`
      query SettingsQuery {
        settings {
          edges {
            node {
              id
              name
              email
              colorScheme
              phoneNumber
              website
              facebookUrl
              twitterUrl
              showSubjectGroupBookingLogin
              subjectGroupBookingLogin
              linkedinUrl
              currency
              anonymousLogin
              auditLogEnabled
              instagramUrl
              intercomAppId
              regionsVisible
              instagramInFooter
              equipmentBarcodes
              equipmentQrCodes
              facebookInFooter
              twitterInFooter
              jobBookingEndHours
              jobBookingEndsEndOfJob
              linkedinInFooter
              openAiEnabled
              refundPolicy
              locationFormat
              showLocationBooking
              applyNoShowPolicyFree
              applyNoShowPolicyPaid
              applyPolicyFree
              applyPolicyPaid
              timeRefundHours
              autoCompleteJobs
              primaryContactEmail
              primaryContactName
              autoCompleteSessions
              timeRefundFee
              resitFeePaidSessions
              resitFeeFreeSessions
              timeRefundSessionPackageCost
              noShowFee
              showLastNameIdLogin
              resitFee
              productTaxRate
              freePackageResitFee
              resitsIncludeFreeSessions
              chargeStateSalesTax
              chargeProductStateSalesTax
              timezone
              displayLogoInNav
              displayNameInNav
              sessionStages
              jobStages
              requireSubjectEmail
              requireSubjectPhone
              logoImage {
                defaultImageName
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      // pollInterval: 30000,
    }
  )

  if (loading || !data)
    return (
      <div className="mt-3">
        <Loading />
      </div>
    )
  if (error) return <p>Error loading studio information</p>
  const settings = data.settings.edges[0].node
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              Settings
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs onSelect={(tab) => setActiveTab(tab)} activeKey={activeTab}>
        <Tab
          eventKey="StudioInfo"
          title={
            <b>
              <Gear className="mr-2" />
              Studio Details
            </b>
          }
        >
          <BusinessInfo settings={settings} />
        </Tab>
        {admin && (
          <Tab
            eventKey="Integrations"
            title={
              <b>
                <Plug className="mr-2" />
                Integrations
              </b>
            }
          >
            <div className="mt-3" />
            <Row>
              <Col md={4}>
                <Stripe />
              </Col>
              {cachedSettings.tenantSubscription !== 'Basic' && (
                <Col md={4}>
                  <GoogleCalendarConnector />
                </Col>
              )}
              {cachedSettings.tenantSubscription === 'Basic' && (
                <Col md={4}>
                  <EmailSetting />
                </Col>
              )}
              <Col md={4}>
                <OpenAI settings={settings} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <TwilioConnector />
              </Col>
              {cachedSettings.tenantSubscription !== 'Basic' && (
                <Col md={4}>
                  <EmailSetting />
                </Col>
              )}
              <Col md={4}>
                <Intercom settings={settings} />
              </Col>
              {cachedSettings.tenantSubscription === 'Basic' && (
                <Col md={4}>
                  <Blueprint />
                </Col>
              )}
            </Row>
            <Row className="mt-3">
              {cachedSettings.tenantSubscription !== 'Basic' && (
                <Col md={4}>
                  <Blueprint />
                </Col>
              )}

              <Col md={4}>
                <API />
              </Col>
            </Row>
          </Tab>
        )}
        {admin && (
          <Tab
            eventKey="Policies"
            title={
              <b>
                <ShieldCheck className="mr-2" />
                Policies
              </b>
            }
          >
            <Policies settings={settings} />
          </Tab>
        )}
        {admin && (
          <Tab
            eventKey="ResitReasons"
            title={
              <b>
                <EmojiFrown className="mr-2" />
                Resit Reasons
              </b>
            }
          >
            <SessionResitReasons />
          </Tab>
        )}
        {loggedInUser?.canManageRegions && cachedSettings?.tenantRegions && (
          <Tab
            eventKey="Regions"
            title={
              <b>
                <Globe className="mr-2" />
                Regions
              </b>
            }
          >
            {activeTab === 'Regions' && (
              <div className="mt-1">
                <Regions />
              </div>
            )}
          </Tab>
        )}
        <Tab
          eventKey="CustomFieldSets"
          title={
            <b>
              <Database className="mr-2" />
              Custom Field Sets
            </b>
          }
        >
          <Row>
            <Col md={12}>
              <div className="mt-4">
                <CustomFieldSets />
              </div>
            </Col>
          </Row>
        </Tab>
        {admin && (
          <Tab
            eventKey="Tags"
            title={
              <b>
                <Tags className="mr-2" />
                Tags
              </b>
            }
          >
            {activeTab === 'Tags' && (
              <div className="mt-1">
                <TagTable />
              </div>
            )}
          </Tab>
        )}
        {admin && (
          <Tab
            eventKey="History"
            title={
              <b>
                <ClockHistory className="mr-2" />
                History
              </b>
            }
          >
            {activeTab === 'History' && (
              <div className="mt-3">
                <AuditLog settingsNode={settings} />
              </div>
            )}
          </Tab>
        )}
        <Tab
          eventKey="UserGuide"
          title={
            <b>
              <QuestionCircle className="mr-2" />
              User Guide
            </b>
          }
        >
          <div className="mt-3">
            <Row>
              <Col md={6}>
                <IntegrationCard
                  name="User Guide"
                  description="Access detailed documentation to learn how to maximize the features and functionality of our platform. Get step-by-step instructions and best practices tailored for your needs."
                >
                  <Button
                    variant="link"
                    onClick={() =>
                      window.open(
                        'https://airstudio.notion.site/20a1c962eb5f452bb9e4575b63328884?v=df3934bba6654ec1ab4891057ec1e2d1',
                        '_blank'
                      )
                    }
                  >
                    <QuestionCircle className="mr-2" />
                    User Guide
                  </Button>
                </IntegrationCard>
              </Col>
            </Row>
          </div>
        </Tab>
        {loggedInUser?.permissions?.group === 'Administrator' && (
          <Tab
            className="mt-2"
            eventKey="Billing"
            title={
              <b>
                <img
                  className="img-fluid mr-2"
                  src={window.location.origin + '/airstudio.png'}
                  style={{ height: '20px' }}
                  alt="Organization logo"
                />
                Subscription
              </b>
            }
          >
            <Tabs
              onSelect={(tab) => setSubscriptionActiveTab(tab)}
              activeKey={subscriptionActiveTab}
            >
              <Tab
                eventKey="Plan"
                title={
                  <b>
                    <Cloud className="mr-2" />
                    Subscription
                  </b>
                }
              >
                <Subscription />
              </Tab>
              <Tab
                eventKey="Invoices"
                title={
                  <b>
                    <Cash className="mr-2" />
                    Invoices
                  </b>
                }
              >
                <TenantInvoiceTable />
              </Tab>
            </Tabs>
          </Tab>
        )}
      </Tabs>
    </>
  )
}

export default StudioSettings
