import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form, ButtonGroup } from 'react-bootstrap'
import { gql, useLazyQuery } from '@apollo/client'
import { PlusCircle, Trash } from 'react-bootstrap-icons'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import DeleteRegionModal from './DeleteRegionModal'
import RegionModal from './RegionModal'
import { formatTimezone } from '../../../libs/utils'

const Regions = (props) => {
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [checkedIds, setCheckedIds] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [region, setRegion] = useState()
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Regions($cursor: String, $searchTerm: String, $orderBy: String) {
        regions(
          first: 50
          after: $cursor
          orderBy: $orderBy
          name_Icontains: $searchTerm
        ) {
          nodeCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              name
              timezone
              default
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          orderBy: defaultOrderBy,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.regions) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.regions?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    query({
      variables,
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  const onCellClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setRegion(node)
    } else {
      setRegion()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div className="studioInfo">
        <Row className="mb-1">
          <Col>
            <div>
              <Button
                variant="link"
                onClick={() => {
                  toggleModal()
                }}
              >
                <PlusCircle className="mr-2" />
                New Region
              </Button>
              {checkedIds.length > 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedIds.length === 1 ? (
                    <>Delete Region</>
                  ) : (
                    <>Delete Regions</>
                  )}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={'Search Regions'}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2 mb-3">
          <Col md="6">
            <SortableInfiniteTable
              loading={loadingSearch || !queryData}
              tableData={
                queryData?.regions?.edges ? queryData.regions.edges : []
              }
              loadingMessage="Loading Regions..."
              tableColumns={[
                {
                  Header: 'Name',
                  id: 'name',
                  accessor: 'node.name',
                  serverSort: true,
                },
                {
                  Header: 'Timezone',
                  id: 'timezone',
                  accessor: (row) => <>{formatTimezone(row.node.timezone)}</>,
                  serverSort: true,
                },
                {
                  Header: 'Default',
                  id: 'default',
                  accessor: (row) => {
                    let defaultValue = 'No'
                    if (row.node.default) {
                      defaultValue = 'Yes'
                    }
                    return defaultValue
                  },
                  serverSort: true,
                },
                {
                  disableSortBy: true,
                  Header: (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const appendIds = []
                              queryData.regions.edges.forEach((region) => {
                                if (
                                  !checkedIds.includes(region.node.id) &&
                                  !region.node.default
                                ) {
                                  appendIds.push(region.node.id)
                                }
                              })
                              setCheckedIds((prevState) => {
                                return [...prevState, ...appendIds]
                              })
                            } else {
                              setCheckedIds([])
                            }
                          }}
                        />
                        {checkedIds.length > 0 && (
                          <span style={{ fontSize: '14px', marginTop: '5px' }}>
                            ({checkedIds.length})
                          </span>
                        )}
                      </Form.Group>
                    </>
                  ),
                  id: 'actions',
                  accessor: (row) => {
                    return (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            disabled={row.node.default}
                            checked={checkedIds.includes(row.node.id)}
                            onChange={(e) => handleCheck(e, row)}
                          />
                        </Form.Group>
                      </>
                    )
                  },
                },
              ]}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={queryData?.regions?.pageInfo?.hasNextPage}
              onTdClicks={{
                name: (cell) => onCellClick(cell),
                timezone: (cell) => onCellClick(cell),
                default: (cell) => onCellClick(cell),
              }}
              tableHeight={700}
              rowPointer
              hideGlobalFilter
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
      </div>
      {showForm && (
        <RegionModal
          showModal={showForm}
          region={region}
          toggleModal={toggleModal}
        />
      )}
      <DeleteRegionModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedIds={setCheckedIds}
        ids={checkedIds.length > 1 ? checkedIds : null}
        id={checkedIds.length === 1 ? checkedIds[0] : null}
      />
    </>
  )
}

export default Regions
