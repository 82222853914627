import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Table, Form, Button } from 'react-bootstrap'
import { useQuery, useMutation, gql } from '@apollo/client'
import { PlusCircle, Trash, SlashCircle } from 'react-bootstrap-icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { ChromePicker } from 'react-color'
import DeleteJobStageModal from './DeleteJobStageModal'

const JobStage = (props) => {
  const { showModal, toggleMogal } = props
  const [showNewJob, setShowNew] = useState(false)
  const [jobStageData, setJobStageData] = useState([])
  const [editStage, setEditStage] = useState(false)
  const [deleteJobStage, setDeleteJobStage] = useState()

  const { data: queryData } = useQuery(
    gql`
      query JobStages {
        jobStages(orderBy: "name") {
          edges {
            node {
              id
              name
              color
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const [createJobStage] = useMutation(
    gql`
      mutation CreateJobStageMutations(
        $createJobStageInput: CreateJobStageInput!
      ) {
        createJobStage(input: $createJobStageInput) {
          jobStage {
            id
            name
            color
          }
        }
      }
    `,
    {
      refetchQueries: ['JobStages'],
      onCompleted: () => {
        formik.resetForm()
        setShowNew(false)
        toast.success('Stage Saved')
      },
    }
  )

  const [updateJobStage] = useMutation(
    gql`
      mutation UpdateJobStage($updateJobStageInput: UpdateJobStageInput!) {
        updateJobStage(input: $updateJobStageInput) {
          jobStage {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        formik.resetForm()
        setShowNew(false)
        toast.success('Stage Saved')
      },
      errorPolicy: 'all',
      refetchQueries: ['JobStages'],
    }
  )

  useEffect(() => {
    if (queryData?.jobStages) {
      const data = queryData?.jobStages?.edges ?? []
      const transformedData = data.map((jStage) => ({
        id: jStage.node.id,
        name: jStage.node.name,
        color:
          jStage.node.color.charAt(0).toUpperCase() +
          jStage.node.color.slice(1),
        originalName: jStage.node.name,
        originalColor:
          jStage.node.color.charAt(0).toUpperCase() +
          jStage.node.color.slice(1),
        error: '',
      }))
      setJobStageData(transformedData)
    }
  }, [queryData])

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '',
      id: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      color: Yup.string().required('Required'),
      id: Yup.mixed().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      if (values.id) {
        updateJobStage({
          variables: {
            updateJobStageInput: {
              jobStageInput: {
                id: values.id,
                name: values.name,
                color: values.color,
              },
            },
          },
        })
      } else {
        createJobStage({
          variables: {
            createJobStageInput: {
              jobStageInput: {
                name: values.name,
                color: values.color,
              },
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (editStage) {
      formik.resetForm()
      formik.setFieldValue('name', editStage.name)
      formik.setFieldValue('color', editStage.color)
      formik.setFieldValue('id', editStage.id)
      setShowNew(true)
    }
  }, [editStage])

  const onHide = () => {
    formik.resetForm()
    setShowNew(false)
    toggleMogal()
  }

  if (!jobStageData) {
    return (
      <Modal size="lg" show={showModal} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body />
      </Modal>
    )
  }
  return (
    <>
      <DeleteJobStageModal
        showModal={deleteJobStage}
        toggleModal={setDeleteJobStage}
        jobStage={deleteJobStage}
      />
      <Modal size="lg" show={showModal} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title closeButton>Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {!showNewJob && (
                <Button
                  variant="link"
                  className="mb-2 pl-0"
                  onClick={() => {
                    formik.resetForm()
                    setShowNew(true)
                  }}
                >
                  <PlusCircle className="mr-2" />
                  New Stage
                </Button>
              )}
              <Form className={showNewJob ? 'mb-3' : 'd-none'}>
                <Row className="justify-content-center">
                  <Col md={4}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      size="sm"
                      name="name"
                      placeholder="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                  <Col md={4}>
                    <Form.Label>Color</Form.Label>
                    <div>
                      {formik.errors.color && (
                        <small className="text-danger">
                          {formik.errors.color}
                        </small>
                      )}
                    </div>
                    <ChromePicker
                      onChange={(e) => {
                        formik.setFieldValue('color', e.hex)
                      }}
                      color={formik.values.color}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                  <Col md={4} className="d-flex justify-content-around">
                    <Button
                      variant="link"
                      size="sm"
                      className="mt-n1"
                      onClick={formik.handleSubmit}
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                    <Button
                      variant="link"
                      size="sm"
                      className="mt-n1"
                      onClick={() => {
                        setShowNew(false)
                        setEditStage()
                      }}
                    >
                      <SlashCircle className="mr-2" />
                      Close
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Color</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {jobStageData.map((jStage, index) => (
                    <tr key={jStage.id}>
                      <td>{jStage.name}</td>
                      <td>
                        <div style={{ color: jStage.originalColor }}>
                          {jStage.originalColor}
                        </div>
                      </td>
                      <td>
                        <Button
                          variant="link"
                          size="sm"
                          className="ps-0 mt-n1"
                          onClick={() => {
                            setEditStage(jStage)
                          }}
                        >
                          <PlusCircle className="mr-2" />
                          Edit
                        </Button>
                      </td>
                      <td>
                        <span
                          onClick={() => setDeleteJobStage(jStage)}
                          className="text-danger"
                          style={{ cursor: 'pointer' }}
                        >
                          <Trash /> Delete
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default JobStage
