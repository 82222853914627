import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Form, Row, Col } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'
import produce from 'immer'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import './JobsSearchFilter.css'
import Loading from '../common/Loading'
import { useParams } from 'react-router-dom'
import { settingsVar } from '../../libs/apollo'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { formatTimezone } from '../../libs/utils'

const JobsSearchFilter = (props) => {
  const {
    appliedSearchFilters,
    setAppliedSearchFilters,
    currentEmployeeId,
    setCurrentEmployeeId,
    region,
  } = props
  const { contact } = useParams()
  const { toTimezone } = useDateTimeConverter()
  const settings = useReactiveVar(settingsVar)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRecordType, setSelectedRecordType] = useState()
  const [searchFilters, setSearchFilters] = useState([])
  const [hasMoreSearchFilters, setHasMoreSearchFilters] = useState(true)
  const [jobsCursor, setJobsCursor] = useState()
  const [locationsCursor, setLocationsCursor] = useState()
  const [employeesCursor, setEmployeesCursor] = useState()
  const [subjectGroupsCursor, setSubjectGroupsCursor] = useState()
  const [organizationsCursor, setOrganizationsCursor] = useState()
  const [jobStagesCursor, setJobStagesCursor] = useState()
  const [jobTypeCursor, setJobTypeCursor] = useState()
  const [subjectsCursor, setSubjectsCursor] = useState()
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [
    jobFiltersQuery,
    { data: jobFiltersData, fetchMore: jobFiltersFetchMore, loading },
  ] = useLazyQuery(
    gql`
      query JobsFilterQuery(
        $jobsCursor: String
        $jobTypesCursor: String
        $locationsCursor: String
        $employeesCursor: String
        $subjectGroupsCursor: String
        $organizationsCursor: String
        $subjectsCursor: String
        $jobStagesCursor: String
        $searchTerm: String
        $employees: [ID]
        $includeJobs: Boolean!
        $includeJobTypes: Boolean!
        $includeLocations: Boolean!
        $includeEmployees: Boolean!
        $includeSubjects: Boolean!
        $includeSubjectGroups: Boolean!
        $includeOrganizations: Boolean!
        $includeJobStages: Boolean!
        $regionId: ID
        $regionString: String
      ) {
        jobs(
          first: 10
          after: $jobsCursor
          search: $searchTerm
          employees: $employees
          orderBy: "name"
          region: $regionId
        ) @include(if: $includeJobs) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              recordId
              startDateTime
              endDateTime
              region {
                timezone
                name
              }
            }
          }
        }
        jobTypes(
          first: 10
          after: $jobTypesCursor
          name_Icontains: $searchTerm
          orderBy: "name"
        ) @include(if: $includeJobTypes) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
        jobStages(
          first: 10
          after: $jobStagesCursor
          name_Icontains: $searchTerm
          orderBy: "name"
        ) @include(if: $includeJobStages) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
        locations(
          first: 10
          after: $locationsCursor
          fullAddress_Icontains: $searchTerm
          orderBy: "full_address"
          regions: $regionString
        ) @include(if: $includeLocations) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              fullAddress
            }
          }
        }
        employees(
          first: 10
          after: $employeesCursor
          search: $searchTerm
          orderBy: "gaia_user__full_name"
          regions: $regionString
        ) @include(if: $includeEmployees) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                id
                firstName
                lastName
              }
            }
          }
        }
        subjects(
          first: 10
          after: $subjectsCursor
          search: $searchTerm
          orderBy: "gaia_user__full_name"
          regions: $regionString
        ) @include(if: $includeSubjects) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                id
                firstName
                lastName
              }
            }
          }
        }
        subjectGroups(
          first: 10
          after: $subjectGroupsCursor
          subjectGroupOrganizationName: $searchTerm
          orderBy: "name"
          regions: $regionString
        ) @include(if: $includeSubjectGroups) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
        organizations(
          first: 10
          after: $organizationsCursor
          name_Icontains: $searchTerm
          orderBy: "name"
          regions: $regionString
        ) @include(if: $includeOrganizations) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )
  const [employeeQuery] = useLazyQuery(
    gql`
      query EmployeeQuery($employeeId: ID!) {
        employee(id: $employeeId) {
          id
          gaiaUser {
            id
            fullName
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        if (data?.employee?.id) {
          setAppliedSearchFilters((prevState) => {
            const prevSearchFilters = { ...prevState }
            prevSearchFilters[data.employee.id] = {
              nodeType: 'Employee',
              name: data.employee.gaiaUser.fullName,
              id: data.employee.id,
            }
            return prevSearchFilters
          })
        }
      },
    }
  )

  useEffect(() => {
    if (currentEmployeeId) {
      employeeQuery({
        variables: {
          employeeId: currentEmployeeId,
        },
      })
    }
  }, [currentEmployeeId])

  useEffect(() => {
    if (jobFiltersData) {
      handleQueryResults(jobFiltersData)
    }
  }, [jobFiltersData])

  useEffect(() => {
    setSearchFilters([])
    setJobsCursor()
    setJobTypeCursor()
    setLocationsCursor()
    setSubjectGroupsCursor()
    setSubjectsCursor()
    setOrganizationsCursor()
    setJobStagesCursor()
    if (selectedRecordType && searchTerm.length > 0) {
      jobFiltersQuery({
        variables: {
          includeJobs: selectedRecordType === 'Job',
          includeJobTypes: selectedRecordType === 'Job Type',
          includeLocations: selectedRecordType === 'Location',
          includeEmployees: selectedRecordType === 'Employee',
          includeSubjects: selectedRecordType === 'Subject',
          includeSubjectGroups: selectedRecordType === 'Subject Group',
          includeOrganizations: selectedRecordType === 'Organization',
          includeJobStages: selectedRecordType === 'Stage',
          searchTerm,
          jobsCursor,
          jobTypeCursor,
          locationsCursor,
          organizationsCursor,
          employeesCursor,
          subjectsCursor,
          subjectGroupsCursor,
          jobStagesCursor,
          regionId: region,
          regionString: region,
        },
      })
    }
  }, [searchTerm, selectedRecordType])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setEmployeesCursor()
    setJobsCursor()
    setJobTypeCursor()
    setLocationsCursor()
    setSubjectGroupsCursor()
    setSubjectsCursor()
    setOrganizationsCursor()
    setJobStagesCursor()
    setSearchTerm(currentSearchTerm)
    setDisplaySearchResults(true)
  }

  const fetchMoreSearchTerms = () => {
    jobFiltersFetchMore({
      variables: {
        includeJobs: selectedRecordType === 'Job',
        includeJobTypes: selectedRecordType === 'Job Type',
        includeLocations: selectedRecordType === 'Location',
        includeEmployees: selectedRecordType === 'Employee',
        includeSubjects: selectedRecordType === 'Subject',
        includeSubjectGroups: selectedRecordType === 'Subject Group',
        includeOrganizations: selectedRecordType === 'Organization',
        includeJobStages: selectedRecordType === 'Stage',
        searchTerm,
        jobsCursor,
        jobTypeCursor,
        locationsCursor,
        employeesCursor,
        subjectGroupsCursor,
        subjectsCursor,
        organizationsCursor,
        jobStagesCursor,
        regionId: region,
        regionString: region,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        handleQueryResults(fetchMoreResult, true)
        return fetchMoreResult
      },
    })
  }

  const handleControlBlur = () => {
    setDisplaySearchResults(false)
  }

  const handleQueryResults = (queryResults, fromFetchMore) => {
    if (searchTerm.length === 0) {
      setSearchFilters([])
    } else {
      const currentSearchFilters = []
      const appliedSearchFilterIds = Object.keys(appliedSearchFilters)
      if (
        selectedRecordType === 'Employee' &&
        queryResults.employees.pageInfo.endCursor
      ) {
        setEmployeesCursor(queryResults.employees.pageInfo.endCursor)
        queryResults.employees.edges.forEach((employee) => {
          const employeeNode = employee.node
          if (!appliedSearchFilterIds.includes(employeeNode.id)) {
            let name = employeeNode.gaiaUser.firstName
            if (employeeNode.gaiaUser.lastName) {
              name = `${name} ${employeeNode.gaiaUser.lastName}`
            }
            currentSearchFilters.push({
              nodeType: 'Employee',
              id: employeeNode.id,
              name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Job' &&
        queryResults.jobs.pageInfo.endCursor
      ) {
        setJobsCursor(queryResults.jobs.pageInfo.endCursor)
        queryResults.jobs.edges.forEach((job) => {
          const jobNode = job.node
          if (!appliedSearchFilterIds.includes(jobNode.id)) {
            const timezone = formatTimezone(jobNode.region?.timezone)
            const description = `${jobNode.name} | ID ${jobNode.recordId} | ${toTimezone(
              jobNode.startDateTime,
              {
                onlyDate: true,
                timezone: timezone,
              }
            )} ${toTimezone(jobNode.startDateTime, {
              onlyTime: true,
              timezone: timezone,
            })} - ${toTimezone(jobNode.endDateTime, {
              onlyTime: true,
              timezone: timezone,
            })}`
            currentSearchFilters.push({
              nodeType: 'Job',
              id: jobNode.id,
              filterName: jobNode.name,
              resultName: description,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Job Type' &&
        queryResults.jobTypes.pageInfo.endCursor
      ) {
        setJobTypeCursor(queryResults.jobTypes.pageInfo.endCursor)
        queryResults.jobTypes.edges.forEach((jobType) => {
          const jobTypeNode = jobType.node
          if (!appliedSearchFilterIds.includes(jobTypeNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Job Type',
              id: jobTypeNode.id,
              name: jobTypeNode.name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Location' &&
        queryResults.locations.pageInfo.endCursor
      ) {
        setLocationsCursor(queryResults.locations.pageInfo.endCursor)
        queryResults.locations.edges.forEach((location) => {
          const locationNode = location.node
          if (!appliedSearchFilterIds.includes(locationNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Location',
              id: locationNode.id,
              name: locationNode.fullAddress,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Subject Group' &&
        queryResults.subjectGroups.pageInfo.endCursor
      ) {
        setSubjectGroupsCursor(queryResults.subjectGroups.pageInfo.endCursor)
        queryResults.subjectGroups.edges.forEach((subjectGroup) => {
          const subjectGroupNode = subjectGroup.node
          if (!appliedSearchFilterIds.includes(subjectGroupNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Subject Group',
              id: subjectGroupNode.id,
              name: subjectGroupNode.name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Organization' &&
        queryResults.organizations.pageInfo.endCursor
      ) {
        setOrganizationsCursor(queryResults.organizations.pageInfo.endCursor)
        queryResults.organizations.edges.forEach((organization) => {
          const organizationNode = organization.node
          if (!appliedSearchFilterIds.includes(organizationNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Organization',
              id: organizationNode.id,
              name: organizationNode.name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Subject' &&
        queryResults.subjects.pageInfo.endCursor
      ) {
        setSubjectsCursor(queryResults.subjects.pageInfo.endCursor)
        queryResults.subjects.edges.forEach((subject) => {
          const subjectNode = subject.node
          if (!appliedSearchFilterIds.includes(subjectNode.id)) {
            let name = subjectNode.gaiaUser.firstName
            if (subjectNode.gaiaUser.lastName) {
              name = `${name} ${subjectNode.gaiaUser.lastName}`
            }
            currentSearchFilters.push({
              nodeType: 'Subject',
              id: subjectNode.id,
              name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Stage' &&
        queryResults.jobStages.pageInfo.endCursor
      ) {
        setJobStagesCursor(queryResults.jobStages.pageInfo.endCursor)
        queryResults.jobStages.edges.forEach((jobStage) => {
          const jobStageNode = jobStage.node
          if (!appliedSearchFilterIds.includes(jobStageNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Stage',
              id: jobStageNode.id,
              name: jobStageNode.name,
            })
          }
        })
      }
      if (
        (selectedRecordType === 'Employee' &&
          queryResults.employees.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Job' &&
          queryResults.jobs.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Location' &&
          queryResults.locations.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Subject Group' &&
          queryResults.subjectGroups.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Organization' &&
          queryResults.organizations.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Job Type' &&
          queryResults.jobTypes.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Subject' &&
          queryResults.subjects.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Stage' &&
          queryResults.jobStages.pageInfo.hasNextPage)
      ) {
        setHasMoreSearchFilters(true)
      } else {
        setHasMoreSearchFilters(false)
      }
      setSearchFilters((prevState) => [...prevState, ...currentSearchFilters])
    }
  }

  const handleSearchFilterClick = (searchFilterRow) => {
    const tableRow = document.getElementById(`tableRow${searchFilterRow.id}`)
    const nodeId = searchFilterRow.original.id
    setSearchTerm('')
    if (Object.keys(appliedSearchFilters).includes(nodeId)) {
      tableRow.classList.remove('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        delete prevSearchFilters[nodeId]
        localStorage.setItem(
          'airstudioJobSearchFilters',
          JSON.stringify(prevSearchFilters)
        )
        return prevSearchFilters
      })
    } else {
      tableRow.classList.add('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        prevSearchFilters[searchFilterRow.original.id] =
          searchFilterRow.original
        localStorage.setItem(
          'airstudioJobSearchFilters',
          JSON.stringify(prevSearchFilters)
        )
        return prevSearchFilters
      })
    }
  }

  const handleRemoveSearchFilterClick = (searchFilter) => {
    setAppliedSearchFilters((prevState) => {
      const prevSearchFilters = { ...prevState }
      delete prevSearchFilters[searchFilter[0]]
      if (Object.keys(prevSearchFilters).length === 0) {
        localStorage.removeItem('airstudioJobSearchFilters')
      } else {
        localStorage.setItem(
          'airstudioJobSearchFilters',
          JSON.stringify(prevSearchFilters)
        )
      }
      return prevSearchFilters
    })
    if (searchFilter[0] === currentEmployeeId) {
      setCurrentEmployeeId()
    }
    if (searchFilters.length > 0) {
      const nextSearchFilters = produce(searchFilters, (draftState) => {
        draftState.push(searchFilter[1])
      })
      setSearchFilters(nextSearchFilters)
    }
  }

  const recordTypeOptions = [
    'Job',
    'Location',
    'Organization',
    'Subject',
    'Subject Group',
  ]
  if (!contact) {
    recordTypeOptions.splice(0, 0, 'Employee')
    recordTypeOptions.splice(2, 0, 'Job Type')
  }
  if (settings && settings.jobStages) {
    recordTypeOptions.splice(5, 0, 'Stage')
  }

  return (
    <>
      <Row>
        <Col className="mt-1 mb-1" md={2}>
          <select
            className="form-control-sm form-select"
            name="group"
            onChange={(e) => {
              if (!e.target.value) {
                setSearchTerm('')
              }
              setSelectedRecordType(e.target.value || undefined)
            }}
            value={selectedRecordType}
          >
            <option value="">Select Record Type</option>
            {recordTypeOptions.map((recordType) => (
              <option key={recordType} value={recordType}>
                {recordType}
              </option>
            ))}
          </select>
        </Col>
        <Col
          className="mt-1 mb-1"
          md={props.regionFilter ? 3 : 4}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Form.Control
            className="form-control-sm"
            type="text"
            name="searchTerm"
            value={searchTerm}
            disabled={!selectedRecordType}
            placeholder="Search Jobs"
            onBlur={handleControlBlur}
            onChange={handleSearchTermChange}
            style={{ width: '100%', boxSizing: 'border-box' }}
          />
          {loading && (
            <div id="jobSearchTable" className="border">
              <Loading height={'25'} width={'25'} />
            </div>
          )}
        </Col>
        {props.dateFilters && (
          <Col className="mt-1 mb-1" md={'auto'}>
            {props.dateFilters}
          </Col>
        )}
        {props.regionFilter && (
          <Col className="mt-1 mb-1" md={2}>
            {props.regionFilter}
          </Col>
        )}
      </Row>
      {displaySearchResults && searchFilters.length > 0 && (
        <Row style={{ position: 'relative', marginTop: '0px' }}>
          <Col
            md={{ span: 4, offset: 2 }}
            style={{
              position: 'absolute',
              width: '100%',
              top: 0,
              zIndex: 1050,
              paddingLeft: 0,
              paddingRight: 0,
              backgroundColor: 'white',
            }}
          >
            <SortableInfiniteTable
              hideGlobalFilter
              displayHeader={false}
              infiniteTableId="jobSearchFilterInfiniteTable"
              tableHeight={searchFilters.length > 5 ? 200 : 100}
              tableData={searchFilters}
              tableColumns={[
                {
                  Header: '',
                  id: 'name',
                  accessor: (row) => {
                    if (row.nodeType === 'Job') {
                      return row.resultName
                    } else {
                      return row.name
                    }
                  },
                },
              ]}
              fetchMoreTableData={fetchMoreSearchTerms}
              hasMoreTableData={hasMoreSearchFilters}
              onRowClick={handleSearchFilterClick}
              rowPointer
              tableSize="lg"
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12}>
          {Object.keys(appliedSearchFilters).length > 0 ? (
            <div className="mt-3">
              {Object.entries(appliedSearchFilters).map(
                (appliedSearchFilter, index) => {
                  let name = appliedSearchFilter[1].name
                  if (appliedSearchFilter[1].nodeType === 'Job') {
                    name = appliedSearchFilter[1].filterName
                  }
                  return (
                    <span
                      style={{
                        padding: '5px',
                        border: '.5px solid #6c757d',
                        borderRadius: '0.25rem',
                        marginLeft: index > 0 ? '0.5rem' : '0',
                      }}
                      key={index}
                    >
                      <Trash
                        className="mr-2 btn-link"
                        onClick={() =>
                          handleRemoveSearchFilterClick(appliedSearchFilter)
                        }
                      />
                      <span style={{ fontSize: '14px' }}>{`${name}`}</span>
                      <span style={{ fontSize: '14px' }} className="ml-1">
                        | {appliedSearchFilter[1].nodeType}
                      </span>
                    </span>
                  )
                }
              )}
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  )
}

export default JobsSearchFilter
