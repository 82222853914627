import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import SessionType from './SessionType'
import { useReactiveVar } from '@apollo/client'
import LoginBox from './LoginBox'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import './Homepage.css'
import toast from 'react-hot-toast'

export default function Homepage() {
  const history = useHistory()
  const settings = useReactiveVar(settingsVar)

  const location = useLocation()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const initialLoginView = localStorage.getItem('initialLoginView')
  if (initialLoginView) {
    localStorage.removeItem('initialLoginView')
  }

  useEffect(() => {
    if (location && location.search === '?created') {
      toast.success('Account Created')
      localStorage.setItem('initialLoginView', 'email')
      history.push('/')
    }
  }, [location])

  useEffect(() => {
    if (loggedInUser && !loggedInUser.autoLogOut) {
      if (loggedInUser.permissions.isEmployee) {
        history.push('/schedule')
      } else if (loggedInUser.permissions.isSubject) {
        history.push(`/subject/sessions`)
      } else if (loggedInUser.permissions.isOrgContact) {
        if (loggedInUser.organizationContacts.edges.length === 1) {
          const orgId = loggedInUser.organizationContacts.edges[0].node.id
          history.push(`/organization/${orgId}/contact`)
        } else {
          history.push(`/organizations/contact`)
        }
      }
    }
  }, [loggedInUser])

  return (
    <>
      <Row>
        <Col md={{ span: 4, offset: 4 }} className="text-center mt-5">
          <h1>Book Session</h1>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          <SessionType homepage />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={{ span: 4, offset: 4 }}>
          <div className="text-center">
            <h1>Login</h1>
          </div>

          <LoginBox initialLoginView={initialLoginView} />
        </Col>
      </Row>
    </>
  )
}
