import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { gql, useMutation } from '@apollo/client'
import { setJwtLocalStorage } from '../../libs/utils'
import { useParams } from 'react-router-dom'
import { loggedInUserVar, settingsVar, setLoginVars } from '../../libs/apollo'

export default function AnonymousLogin() {
  const { loginToken, booking } = useParams()
  const history = useHistory()
  const user = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [login] = useMutation(
    gql`
      mutation CreateJwtAnonymous(
        $createJwtTokenAnonymousInput: CreateJSONWebTokenAnonymousInput!
      ) {
        createJwtTokenAnonymous(input: $createJwtTokenAnonymousInput) {
          token
          refreshExpiresIn
          gaiaUser {
            id
            fullName
            email
            gaiaUserRegions {
              nodeCount
              edges {
                node {
                  default
                  region {
                    id
                    name
                    timezone
                  }
                }
              }
            }
            employee {
              id
            }
            organizationContacts {
              edges {
                node {
                  id
                }
              }
            }
            subject {
              id
              subjectGroupsNotBooked {
                subjectGroup {
                  id
                  name
                  startDateTime
                  endDateTime
                }
              }
              studentId
              organization {
                id
                name
              }
            }
            groups {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      onCompleted(data) {
        if (data?.createJwtTokenAnonymous?.gaiaUser) {
          setJwtLocalStorage(data.createJwtTokenAnonymous)
          setLoginVars(
            data.createJwtTokenAnonymous.gaiaUser,
            settings.regionsVisible
          )
          if (booking) {
            const subjectNode = data.createJwtTokenAnonymous.gaiaUser.subject
            const userNode = data.createJwtTokenAnonymous.gaiaUser
            history.push({
              pathname: '/book',
              state: {
                initialStage: 'review',
                bookingType: 'schoolFlow',
                incommingSubject: {
                  subjectGroupsNotBooked: subjectNode.subjectGroupsNotBooked,
                  studentId: subjectNode.studentId,
                  id: subjectNode.id,
                  fullName: userNode.fullName,
                  org: {
                    name: subjectNode.organization
                      ? subjectNode.organization.name
                      : '',
                    id: subjectNode.organization
                      ? subjectNode.organization.id
                      : '',
                  },
                },
              },
            })
          } else {
            history.push('/')
          }
        }
      },
      onError: () => {
        history.push('/')
      },
    }
  )

  useEffect(() => {
    if (user || (settings && !settings.anonymousLogin)) {
      history.push('/')
    } else if (loginToken) {
      login({
        variables: {
          createJwtTokenAnonymousInput: {
            loginToken,
          },
        },
      })
    }
  }, [user, settings, loginToken])

  return <></>
}
