import React, { useState, useEffect } from 'react'
import { Tab, Row, Col } from 'react-bootstrap'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import Tabs from '../common/Tabs'
import ESignRequests from './box/ESignRequests'
import { useHistory } from 'react-router-dom'
import Page from '../layout/Page'
import BoxFolder from './box/BoxFolder'
import { FileEarmarkText, VectorPen } from 'react-bootstrap-icons'
import FTPUpload from '../settings/ftp/FtpUpload'
import { useFtpEnabled } from '../../libs/ftp'
import { useBoxEnabled } from '../../libs/box'
import FilesTable from './FilesTable'

const Files = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [currentBoxFolderId, setCurrentBoxFolderId] = useState()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('Files')
  const ftpEnabled = useFtpEnabled()
  const boxEnabled = useBoxEnabled()
  const { data: boxConnector } = useQuery(
    gql`
      query BoxConnector {
        boxConnectors {
          edges {
            node {
              rootBoxFolderId
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  useEffect(() => {
    if (
      loggedInUser &&
      history &&
      !['Scheduling Manager', 'Scheduling Analyst', 'Administrator'].includes(
        loggedInUser?.permissions?.group
      )
    ) {
      history.push('/')
    }
  }, [history, loggedInUser])

  return (
    <>
      <Page title={'Files'}>
        {!boxEnabled && !ftpEnabled && <FilesTable />}
        {(boxEnabled || ftpEnabled) && (
          <Tabs onSelect={(key) => setActiveTab(key)} activeKey={activeTab}>
            <Tab
              eventKey="Files"
              title={
                <b>
                  <FileEarmarkText className="mr-1" />
                  Files
                </b>
              }
            >
              {boxEnabled && (
                <BoxFolder
                  rootBoxFolderId={
                    boxConnector?.boxConnectors.edges[0].node.rootBoxFolderId
                  }
                  setCurrentBoxFolderId={setCurrentBoxFolderId}
                  hideDownload
                />
              )}
              {!boxEnabled && <FilesTable />}
            </Tab>
            {boxEnabled && (
              <Tab
                eventKey="Sign Requests"
                title={
                  <b>
                    <VectorPen className="mr-1" />
                    Sign Requests
                  </b>
                }
              >
                <ESignRequests currentBoxFolderId={currentBoxFolderId} />
              </Tab>
            )}

            {ftpEnabled && (
              <Tab
                eventKey="FTP"
                title={
                  <b>
                    <VectorPen className="mr-1" />
                    FTP
                  </b>
                }
              >
                <Row className="mt-4">
                  <Col md={6}>
                    <FTPUpload />
                  </Col>
                </Row>
              </Tab>
            )}
          </Tabs>
        )}
      </Page>
    </>
  )
}

export default Files
