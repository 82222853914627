import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Globe, PlusCircle, Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import SubjectGroupSearchInput from '../common/node_search_input/SubjectGroupSearchInput'

const SubjectGroupRegionModal = (props) => {
  const { showModal, subjectGroupRegion, subjectGroupId, toggleModal } = props
  const [submitting, setSubmitting] = useState(false)

  const [deleteSubjectGroupRegion] = useMutation(
    gql`
      mutation DeleteSubjectGroupRegion(
        $deleteSubjectGroupRegionInput: DeleteSubjectGroupRegionInput!
      ) {
        deleteSubjectGroupRegion(input: $deleteSubjectGroupRegionInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success('Subject Group Region Deleted')
        toggleModal()
      },
      refetchQueries: ['SubjectGroupRegions'],
      errorPolicy: 'all',
    }
  )

  const [createSubjectGroupRegion] = useMutation(
    gql`
      mutation CreateSubjectGroupRegion(
        $input: CreateSubjectGroupRegionInput!
      ) {
        createSubjectGroupRegion(input: $input) {
          subjectGroupRegion {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Subject Group Region Saved`)
        toggleModal()
      },
      onError: (data) => {
        formik.setErrors({
          regionId: `region already exists`,
        })
        setSubmitting(false)
      },
      refetchQueries: ['SubjectGroupRegions'],
    }
  )

  const [updateSubjectGroupRegion] = useMutation(
    gql`
      mutation UpdateSubjectGroupRegion(
        $input: UpdateSubjectGroupRegionInput!
      ) {
        updateSubjectGroupRegion(input: $input) {
          subjectGroupRegion {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Subject Group Region Saved`)
        toggleModal()
      },
      onError: (data) => {
        formik.setErrors({
          regionId: `region already exists`,
        })
        setSubmitting(false)
      },
      refetchQueries: ['SubjectGroupRegions'],
    }
  )

  const handleDeleteClick = () => {
    setSubmitting(true)
    deleteSubjectGroupRegion({
      variables: {
        deleteSubjectGroupRegionInput: {
          subjectGroupRegionIds: [subjectGroupRegion.id],
        },
      },
    })
  }

  const formik = useFormik({
    initialValues: subjectGroupRegion
      ? {
          regionId: subjectGroupRegion.region.id,
          subjectGroupId: subjectGroupRegion.subjectGroup.id,
          default: subjectGroupRegion.default,
        }
      : {
          regionId: '',
          subjectGroupId: subjectGroupId || '',
          default: false,
        },
    validationSchema: Yup.object().shape({
      regionId: Yup.string().required('required'),
      subjectGroupId: Yup.string().required('required'),
      default: Yup.boolean().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (subjectGroupRegion) {
        updateSubjectGroupRegion({
          variables: {
            input: {
              subjectGroupRegionInput: {
                id: subjectGroupRegion.id,
                regionId: values.regionId,
                subjectGroupId: values.subjectGroupId,
                default: values.default,
              },
            },
          },
        })
      } else {
        createSubjectGroupRegion({
          variables: {
            input: {
              subjectGroupRegionInput: {
                regionId: values.regionId,
                subjectGroupId: values.subjectGroupId,
                default: values.default,
              },
            },
          },
        })
      }
    },
  })

  if (!showModal) return <></>
  return (
    <div className="addEditSubjectGroupRegionsModal">
      <Modal size={'md'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Globe className="mr-2" />
            {subjectGroupRegion ? (
              <>Edit Subject Group Region</>
            ) : (
              <>New Region</>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            {!subjectGroupId && (
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Subject Group
                  </Form.Label>
                </Col>
                <Col sm="12" md={7}>
                  <SubjectGroupSearchInput
                    dropdown
                    formik={formik}
                    error={formik.errors.subjectGroupId}
                  />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Region
                </Form.Label>
              </Col>
              <Col sm="12" md={7}>
                <RegionSearchInput
                  dropdown
                  formik={formik}
                  error={formik.errors.regionId}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Default
                </Form.Label>
              </Col>
              <Col sm="12" md={7}>
                <Form.Check
                  className="ml-1 mt-1"
                  type="checkbox"
                  checked={formik.values.default}
                  onChange={(e) => {
                    formik.setFieldValue('default', e.target.checked)
                  }}
                  isInvalid={formik.errors.default}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.default}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Row className="mt-2">
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  type="submit"
                  variant="outline-primary"
                  block
                  disabled={submitting}
                >
                  <PlusCircle className="mr-2" />
                  Save
                </Button>
              </Col>
            </Form.Row>

            {subjectGroupRegion && !subjectGroupRegion.default && (
              <Form.Row className="mt-2">
                <Col md={{ span: 8, offset: 2 }}>
                  <Button
                    variant="outline-danger"
                    onClick={handleDeleteClick}
                    block
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              </Form.Row>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SubjectGroupRegionModal
