import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Calendar, Trash } from 'react-bootstrap-icons'
import { DateTime, FixedOffsetZone } from 'luxon'
import { useDateTimeConverter } from '../../libs/useDateTime'

const JobsDateFilter = (props) => {
  const {
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    prevNextDateClick,
    setPrevNextDateClick,
    prevNextStartDateFilter,
    prevNextEndDateFilter,
    hideIcons,
    timezone,
  } = props
  const { toUTC } = useDateTimeConverter()
  let jsStartDateFilter = ''
  if (startDateFilter && startDateFilter.c) {
    jsStartDateFilter = new Date(
      startDateFilter.c.year,
      startDateFilter.c.month - 1,
      startDateFilter.c.day
    )
  }
  let jsEndDateFilter = ''
  if (endDateFilter && endDateFilter.c) {
    jsEndDateFilter = new Date(
      endDateFilter.c.year,
      endDateFilter.c.month - 1,
      endDateFilter.c.day
    )
  }
  const JobDateTimeFilterSchema = Yup.object().shape({
    startDateFilter: Yup.date().test(
      'isLessThanEndDate',
      'Start date must not exceed end date',
      (value, context) => {
        let valid = true
        if (prevNextDateClick) {
          return valid
        }
        if (value && context.parent.endDateFilter) {
          const startDate = new Date(value)
          const endDate = new Date(context.parent.endDateFilter)
          endDate.setHours(23)
          endDate.setMinutes(59)
          endDate.setSeconds(59)
          if (endDate < startDate) {
            valid = false
          }
        }
        return valid
      }
    ),
    endDateFilter: Yup.date(),
  })

  const formik = useFormik({
    initialValues: {
      startDateFilter: jsStartDateFilter,
      endDateFilter: jsEndDateFilter,
    },
    validationSchema: JobDateTimeFilterSchema,
  })

  useEffect(() => {
    let startDate
    if (formik.values.startDateFilter) {
      startDate = toUTC(formik.values.startDateFilter, {
        timezone: timezone,
        startOfDay: true,
      })
    }
    let endDate
    if (formik.values.endDateFilter) {
      endDate = toUTC(formik.values.endDateFilter, {
        timezone: timezone,
        endOfDay: true,
      })
    }
    if (!endDate) {
      let cachedFilters = localStorage.getItem('airstudioJobDateFilters')
      if (cachedFilters) {
        cachedFilters = JSON.parse(cachedFilters)
        if (cachedFilters.end) {
          cachedFilters.end = null
          localStorage.setItem(
            'airstudioJobDateFilters',
            JSON.stringify(cachedFilters)
          )
        }
      }
    }
    if (!startDate) {
      let cachedFilters = localStorage.getItem('airstudioJobDateFilters')
      if (cachedFilters) {
        cachedFilters = JSON.parse(cachedFilters)
        if (cachedFilters.start) {
          cachedFilters.start = null
          localStorage.setItem(
            'airstudioJobDateFilters',
            JSON.stringify(cachedFilters)
          )
        }
      }
    }
    if (
      startDate &&
      (!endDate || startDate < endDate) &&
      startDate !== startDateFilter
    ) {
      let cachedFilters = localStorage.getItem('airstudioJobDateFilters')
      if (!cachedFilters) {
        localStorage.setItem(
          'airstudioJobDateFilters',
          JSON.stringify({
            start: startDate,
          })
        )
      } else {
        cachedFilters = JSON.parse(cachedFilters)
        cachedFilters.start = startDate
        localStorage.setItem(
          'airstudioJobDateFilters',
          JSON.stringify(cachedFilters)
        )
      }
      const offsetInMinutes = startDate.offset
      const fixedZone = new FixedOffsetZone(offsetInMinutes)
      const fixedOffsetStartDate = DateTime.fromMillis(startDate.ts, {
        zone: fixedZone,
      })
      setStartDateFilter(fixedOffsetStartDate)
    }
    if (
      endDate &&
      (!startDate || startDate < endDate) &&
      endDate !== endDateFilter
    ) {
      let cachedFilters = localStorage.getItem('airstudioJobDateFilters')
      if (!cachedFilters) {
        localStorage.setItem(
          'airstudioJobDateFilters',
          JSON.stringify({ end: endDate })
        )
      } else {
        cachedFilters = JSON.parse(cachedFilters)
        cachedFilters.end = endDate
        localStorage.setItem(
          'airstudioJobDateFilters',
          JSON.stringify(cachedFilters)
        )
      }
      const offsetInMinutes = endDate.offset
      const fixedZone = new FixedOffsetZone(offsetInMinutes)
      const fixedOffsetEndDate = DateTime.fromMillis(endDate.ts, {
        zone: fixedZone,
      })
      setEndDateFilter(fixedOffsetEndDate)
    }
  }, [formik.values])

  useEffect(() => {
    if (prevNextDateClick && prevNextStartDateFilter && prevNextEndDateFilter) {
      formik.setFieldValue('endDateFilter', prevNextEndDateFilter)
      formik.setFieldValue('startDateFilter', prevNextStartDateFilter)
      setPrevNextDateClick(false)
    }
  }, [prevNextEndDateFilter, prevNextStartDateFilter, prevNextDateClick])

  const handleRemoveDateFilterClick = (dateType) => {
    if (dateType === 'start') {
      formik.setFieldValue('startDateFilter', '')
      setStartDateFilter(null)
    } else if (dateType === 'end') {
      formik.setFieldValue('endDateFilter', '')
      setEndDateFilter(null)
    }
  }
  return (
    <>
      <Row>
        <Col
          md="auto"
          className="d-flex align-items-center pr-0 ml-3"
          style={{ zIndex: 2 }}
        >
          {formik.values.startDateFilter ? (
            <div
              style={{ cursor: 'pointer' }}
              className="btn-link"
              onClick={() => handleRemoveDateFilterClick('start')}
            >
              <Trash className="mr-2" />
            </div>
          ) : null}

          <DatePicker
            placeholderText="Jobs From"
            name="startDateFilter"
            className="form-control form-control-sm"
            showPopperArrow={false}
            popperPlacement="bottom"
            selected={formik.values.startDateFilter}
            onChange={(date) => formik.setFieldValue('startDateFilter', date)}
            popperModifiers={{
              flip: {
                behavior: ['bottom'],
              },
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            }}
          />
          {!hideIcons && (
            <Calendar
              className="position-absolute mr-2 small text-secondary"
              style={{ right: 0 }}
            />
          )}
        </Col>
        <Col
          md="auto"
          className="d-flex align-items-center pl-0 ml-3 pr-0"
          style={{ zIndex: 2 }}
        >
          {formik.values.endDateFilter ? (
            <div
              style={{ cursor: 'pointer' }}
              className="btn-link"
              onClick={() => handleRemoveDateFilterClick('end')}
            >
              <Trash className="mr-2" />
            </div>
          ) : (
            <></>
          )}
          <DatePicker
            name="endDateFilter"
            className="form-control form-control-sm"
            showPopperArrow={false}
            placeholderText={'Jobs Until'}
            selected={formik.values.endDateFilter}
            onChange={(date) => formik.setFieldValue('endDateFilter', date)}
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ['bottom'],
              },
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            }}
          />
          {!hideIcons && (
            <Calendar
              className="position-absolute mr-2 small text-secondary"
              style={{ right: 0 }}
            />
          )}
        </Col>
      </Row>
      {formik.errors.startDateFilter ? (
        <Row>
          <Col className="ml-4">
            <span>
              <small className="text-danger">
                {formik.errors.startDateFilter}
              </small>
            </span>
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export default JobsDateFilter
