import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { Trash } from 'react-bootstrap-icons'
import DeleteFormSubmissionModal from './DeleteFormSubmissionModal'
import { loggedInUserVar } from '../../libs/apollo'
import FormSubmissionModal from './FormSubmissionModal'
import { useLocation } from 'react-router-dom'
import { useDateTimeConverter } from '../../libs/useDateTime'

const FormSubmissions = (props) => {
  const {
    formId,
    submitterId,
    sessionId,
    subjectId,
    employeeId,
    jobId,
    subjectGroupId,
    organizationId,
    employeeJobId,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const location = useLocation()
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const { toTimezone } = useDateTimeConverter()
  const [loadingSearch, setLoadingSearch] = useState(false)
  const defaultOrderBy = '-created'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedFormIds, setCheckedFormIds] = useState([])
  const [editSubmitForm, setEditSubmitForm] = useState(null)
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query FormSubmissions(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $subject: String
        $employee: String
        $form: [ID]
        $job: String
        $subjectGroup: String
        $organization: String
        $employeeJob: String
        $session: String
        $gaiaUser: String
      ) {
        submitForms(
          first: 20
          after: $cursor
          search: $searchTerm
          form: $form
          orderBy: $orderBy
          employee: $employee
          subject: $subject
          job: $job
          subjectGroup: $subjectGroup
          organization: $organization
          employeeJob: $employeeJob
          session: $session
          gaiaUser: $gaiaUser
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              created
              submission
              gaiaUser {
                fullName
                email
                subject {
                  id
                }
                employee {
                  id
                }
              }
              session {
                id
                subject {
                  gaiaUser {
                    fullName
                  }
                }
                organization {
                  name
                }
                job {
                  description
                }
              }
              employee {
                id
                gaiaUser {
                  fullName
                }
              }
              employeeJob {
                id
                description
                job {
                  id
                }
              }
              subject {
                id
                gaiaUser {
                  fullName
                }
              }
              organization {
                id
                name
              }
              job {
                id
                description
              }
              subjectGroup {
                id
                name
              }
              form {
                id
                name
                form
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const constructQueryVariables = useCallback(() => {
    const variables = {
      cursor: queryData?.submitForms?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (sessionId) {
      variables.session = sessionId
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    if (employeeId) {
      variables.employee = employeeId
    }
    if (formId) {
      variables.form = formId
    }
    if (jobId) {
      variables.job = jobId
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
    }
    if (organizationId) {
      variables.organization = organizationId
    }
    if (employeeJobId) {
      variables.employeeJob = employeeJobId
    }
    if (submitterId) {
      variables.gaiaUser = submitterId
    }
    return variables
  }, [
    queryData?.submitForms?.pageInfo?.endCursor,
    searchTerm,
    orderBy,
    sessionId,
    subjectId,
    employeeId,
    formId,
    jobId,
    subjectGroupId,
    organizationId,
    employeeJobId,
  ])

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = constructQueryVariables()
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.submitForms) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    queryFetchMore({
      variables,
    })
  }

  const handleFormCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedFormIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedFormIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = constructQueryVariables()
    variables.cursor = null
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = constructQueryVariables()
      variables.cursor = null
      variables.searchTerm = searchValue || undefined
      setLoadingSearch(true)
      query({ variables })
    },
    [constructQueryVariables, query]
  )

  const handleSearchTermChange = useCallback(
    (event) => {
      const currentSearchTerm = event.target.value || undefined
      setSearchTerm(currentSearchTerm)

      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      const newTimeout = setTimeout(() => {
        executeSearchQuery(currentSearchTerm)
      }, 500) // 1 second delay

      setDebounceTimeout(newTimeout)
    },
    [executeSearchQuery, debounceTimeout]
  )

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const onTdClick = (cell) => {
    setEditSubmitForm(cell.row.original.node)
  }

  const tableColumns = [
    {
      Header: 'Form',
      id: 'name',
      accessor: 'node.form.name',
      serverSort: true,
      orderBy: 'form__name',
    },
    {
      Header: 'Submitted By',
      id: 'submittedBy',
      accessor: (row) => {
        let submittedBy = 'Anonymous'
        if (row.node.gaiaUser) {
          if (row.node.gaiaUser) {
            submittedBy = row.node.gaiaUser.fullName
          }
          if (row.node.gaiaUser.employee) {
            submittedBy = `${submittedBy} | Employee`
          } else if (row.node.gaiaUser.subject) {
            submittedBy = `${submittedBy} | Subject`
          }
        }
        return `${submittedBy}`
      },
      serverSort: true,
      orderBy: 'gaia_user__full_name',
    },
    {
      Header: 'Submitted On',
      id: 'created',
      accessor: (row) => {
        return toTimezone(row.node.created, {
          standard: true,
          settingTimezone: true,
        })
      },
      orderBy: 'created',
      serverSort: true,
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: (row) => {
        if (row.node.subject) {
          return row.node.subject.gaiaUser.fullName
        }
      },
      orderBy: 'subject__gaia_user__full_name',
      serverSort: true,
    },
    {
      Header: 'Session',
      id: 'session',
      accessor: (row) => {
        const { session } = row.node
        let description = ''
        if (session) {
          if (session.subject) {
            description = session.subject.gaiaUser.fullName
          }
          if (session.organization) {
            description = `${description} | ${session.organization.name}`
          }
          description = `${description} | ${session.job.description}`
        }
        return description
      },
      orderBy: 'session__start_date_time',
      serverSort: true,
    },
    {
      Header: 'Job',
      id: 'job',
      accessor: (row) => {
        if (row.node.job) {
          return row.node.job.description
        }
      },
      orderBy: 'job__start_date_time',
      serverSort: true,
    },
    {
      Header: 'Subject Group',
      id: 'subjectGroup',
      accessor: (row) => {
        if (row.node.subjectGroup) {
          return row.node.subjectGroup.name
        }
      },
      orderBy: 'subject_group__name',
      serverSort: true,
    },
    {
      Header: 'Organization',
      id: 'organization',
      accessor: (row) => {
        if (row.node.organization) {
          return row.node.organization.name
        }
      },
      orderBy: 'organization__name',
      serverSort: true,
    },
    {
      Header: 'Employee',
      id: 'employee',
      accessor: (row) => {
        if (row.node.employee) {
          return row.node.employee.gaiaUser.fullName
        }
      },
      orderBy: 'employee__gaia_user__full_name',
      serverSort: true,
    },
    {
      Header: 'Employee Job',
      id: 'employeeJob',
      accessor: (row) => {
        if (row.node.employeeJob) {
          return row.node.employeeJob.description
        }
      },
      orderBy: 'employee_job__job__start_date_time',
      serverSort: true,
    },
  ]
  if (location.pathname.includes('form')) {
    tableColumns.splice(0, 1)
  }
  if (canMutate) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.submitForms?.edges.forEach((edge) => {
                    if (!checkedFormIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedFormIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedFormIds([])
                }
              }}
            />
            {checkedFormIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedFormIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedFormIds.includes(row.node.id)}
                onChange={(e) => handleFormCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      {editSubmitForm && (
        <FormSubmissionModal
          showModal={true}
          closeModal={setEditSubmitForm}
          submitForm={editSubmitForm}
        />
      )}
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={'Search Form Submissions'}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          {checkedFormIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedFormIds.length === 1 ? (
                    <>Delete Form Submission</>
                  ) : (
                    <>Delete Form Submissions</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.submitForms?.edges ? queryData?.submitForms?.edges : []
            }
            loadingMessage="Loading Form Submissions..."
            tableColumns={tableColumns}
            loading={loadingSearch || !queryData}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.submitForms?.pageInfo?.hasNextPage}
            onTdClicks={{
              name: onTdClick,
              created: onTdClick,
              submittedBy: onTdClick,
              session: onTdClick,
              job: onTdClick,
              subjectGroup: onTdClick,
              organization: onTdClick,
              subject: onTdClick,
              employee: onTdClick,
              employeeJob: onTdClick,
            }}
            tableHeight={700}
            rowPointer
            hideGlobalFilter
            handleSortByChange={handleSortByChange}
          />
        </Col>
      </Row>
      <DeleteFormSubmissionModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedFormIds={setCheckedFormIds}
        ids={checkedFormIds.length > 1 ? checkedFormIds : null}
        id={checkedFormIds.length === 1 ? checkedFormIds[0] : null}
      />
    </>
  )
}
export default FormSubmissions
