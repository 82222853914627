import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Row, Col, Button, Modal, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import * as Yup from 'yup'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  CaretDown,
  CaretRight,
  PlusCircle,
  Trash,
  CalendarWeek,
  Alarm,
} from 'react-bootstrap-icons'
import AuditLog from '../../audit_log/AuditLog'
import Loading from '../../common/Loading'
import NotificationsTable from '../../notifications/NotificationsTable'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { useDateTimeConverter } from '../../../libs/useDateTime'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'
import { formatTimezone } from '../../../libs/utils'

const EmployeeScheduleModal = (props) => {
  const {
    showModal,
    toggleModal,
    editNode,
    employeeNode,
    employeeEditing,
    setEmployeeEditing,
  } = props

  const { toTimezone, combineDateAndTime } = useDateTimeConverter()
  const [submitting, setSubmitting] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displayNotifications, setDisplayNotifications] = useState(false)
  const [displayWorkEvents, setDisplayWorkEvents] = useState(false)
  const [showEmployeeSearchResults, setShowEmployeeSearchResults] =
    useState(false)
  const [displayLocationResults, setDisplayLocationResults] = useState(true)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [timezone, setTimezone] = useState(
    formatTimezone(editNode?.region?.timezone) ||
      loggedInUser?.defaultRegion?.formattedTimezone
  )
  const canSeeHistoryNotifications = [
    'Administrator',
    'Scheduling Manager',
  ].includes(loggedInUser?.permissions?.group)
  const [searchEmployee, { data: employeesData }] = useLazyQuery(
    gql`
      query EmployeesQuery(
        $cursor: String
        $searchTerm: String
        $regionIds: String
      ) {
        employees(
          first: 20
          after: $cursor
          gaiaUser_FullName_Icontains: $searchTerm
          orderBy: "gaia_user__full_name"
          regions: $regionIds
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              hourlyPay
              gaiaUser {
                email
                fullName
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const [deleteEmployeeSchedule] = useMutation(
    gql`
      mutation DeleteEmployeeSchedule(
        $deleteEmployeeScheduleInput: DeleteEmployeeScheduleInput!
      ) {
        deleteEmployeeSchedule(input: $deleteEmployeeScheduleInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Employee Schedule Event Deleted`)
        toggleModal()
      },
      refetchQueries: ['EmployeeSchedule'],
    }
  )

  const [createEmployeeSchedule] = useMutation(
    gql`
      mutation CreateEmployeeSchedule($input: CreateEmployeeScheduleInput!) {
        createEmployeeSchedule(input: $input) {
          employeeSchedule {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toggleModal()
        toast.success(`Employee Schedule Event Saved`)
      },
      refetchQueries: ['EmployeeSchedule'],
    }
  )
  const [
    searchLocations,
    { data: locationData, fetchMore: fetchMoreLocations },
  ] = useLazyQuery(
    gql`
      query Locations(
        $nameIcontains: String
        $first: Int
        $after: String
        $regionIds: [ID]
      ) {
        locations(
          fullAddress_Icontains: $nameIcontains
          first: $first
          after: $after
          region: $regionIds
        ) {
          edges {
            node {
              id
              addressLineOne
              addressLineTwo
              city
              state
              name
              studio
              subject {
                gaiaUser {
                  fullName
                }
              }
              zipCode
              organization {
                id
                name
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [updateEmployeeSchedule] = useMutation(
    gql`
      mutation UpdateEmployeeSchedule($input: UpdateEmployeeScheduleInput!) {
        updateEmployeeSchedule(input: $input) {
          employeeSchedule {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toggleModal()
        toast.success(`Employee Schedule Event Saved`)
      },
      refetchQueries: ['EmployeeSchedule'],
    }
  )
  const [createTimesheetEventMutation] = useMutation(
    gql`
      mutation CreateTimesheetEvent($input: CreateTimesheetEventInput!) {
        createTimesheetEvent(input: $input) {
          timesheetEvent {
            id
            eventType
          }
        }
      }
    `,
    {
      refetchQueries: ['EmployeeSchedule'],
      onCompleted: (data) => {
        const eventType = data.createTimesheetEvent.timesheetEvent.eventType
        if (eventType === 'CLOCK_IN') {
          toast.success('Clocked In')
        } else if (eventType === 'CLOCK_OUT') {
          toast.success('Clocked Out')
        }
      },
    }
  )

  const handleFetchMoreLocations = () => {
    fetchMoreLocations({
      variables: {
        nameIcontains: formik.values.locationValue,
        first: 10,
        after: locationData.locations.pageInfo.endCursor,
        regionIds: formik.values.regionId,
      },
    })
  }
  const formatAddress = (locationNode) => {
    let displayAddress
    if (locationNode) {
      const addressName = locationNode.name ? `${locationNode.name}, ` : ''
      const orgName = locationNode.organization?.name
        ? `, ${locationNode.organization.name}`
        : ''
      const addressLineTwo = locationNode.addressLineTwo
        ? `${locationNode.addressLineTwo}, `
        : ''
      displayAddress = `${addressName}${locationNode.addressLineOne}, ${addressLineTwo}${locationNode.city}, ${locationNode.state}, ${locationNode.zipCode} ${orgName}`
    }

    return displayAddress
  }
  const handleLocationClick = (node, formik) => {
    const displayAddress = formatAddress(node)
    formik.setFieldValue('locationValue', displayAddress)
    formik.setFieldValue('locationId', node.id)
    setDisplayLocationResults(false)
  }
  const handleLocationChange = (e, formik) => {
    setDisplayLocationResults(true)
    formik.setFieldValue('locationValue', e.target.value)
    searchLocations({
      variables: {
        nameIcontains: e.target.value,
        first: 10,
        regionIds: [formik.values.regionId],
      },
    })
  }
  const handleLocationXClick = (formik) => {
    formik.setFieldValue('locationId', '')
    formik.setFieldValue('locationValue', '')
  }

  const displayCheckInOut =
    editNode &&
    editNode.employee.id === loggedInUser.employee.id &&
    editNode.eventType === 'EMPLOYEE_WORKING' &&
    editNode.approvalStatus === 'APPROVED'

  const displayCheckInButton =
    editNode?.timesheet === null ||
    editNode?.timesheet?.timesheetEvents.edges.length === 0 ||
    editNode?.timesheet?.timesheetEvents.edges[
      editNode?.timesheet?.timesheetEvents.edges.length - 1
    ].node.eventType === 'CLOCK_OUT'

  const getDate = (dateTime) => {
    let date = new Date()
    if (dateTime) {
      date = toTimezone(dateTime, { timezone: timezone, isoDateOnly: true })
    }
    return date
  }

  const formik = useFormik({
    initialValues: editNode
      ? {
          startTime: editNode.startDateTime
            ? toTimezone(editNode.startDateTime, {
                onlyTime: true,
                timezone: timezone,
              })
            : null,
          startDate: getDate(editNode.startDateTime),
          endTime: editNode.endDateTime
            ? toTimezone(editNode.endDateTime, {
                onlyTime: true,
                timezone: timezone,
              })
            : null,
          endDate: getDate(editNode.endDateTime),
          employee: editNode.employee.id,
          locationId: editNode.location?.id,
          locationValue: editNode.location
            ? formatAddress(editNode.location)
            : '',
          approvalStatus: editNode.approvalStatus,
          eventType: editNode.eventType,
          hourlyPay: editNode.hourlyPay,
          employeeSearch: editNode.employee.gaiaUser.fullName,
          notes: editNode.notes,
          regionId: editNode.region?.id,
        }
      : {
          startTime: '7:00am',
          startDate: new Date(),
          hourlyPay:
            employeeNode?.hourlyPay && parseFloat(employeeNode.hourlyPay),
          locationId: '',
          locationValue: '',
          endTime: '11:59pm',
          endDate: new Date(),
          employee: employeeNode ? employeeNode.id : '',
          approvalStatus: 'PENDING',
          eventType: 'EMPLOYEE_WORKING',
          employeeSearch: employeeNode ? employeeNode.gaiaUser.fullName : '',
          notes: '',
          regionId: loggedInUser?.defaultRegion?.id,
        },
    validationSchema: Yup.object().shape({
      startTime: Yup.string()
        .matches(
          /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
          'required'
        )
        .nullable()
        .test('exists', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      endTime: Yup.string()
        .matches(
          /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i,
          'required'
        )
        .nullable()
        .test('exists', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      notes: Yup.string().nullable(),
      employee: Yup.string()
        .nullable()
        .test('exists', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      eventType: Yup.string()
        .test('exists', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        })
        .test('exists', 'invalid event type', (value) => {
          let valid = true
          if (
            !['EMPLOYEE_WORKING', 'EMPLOYEE_SICK', 'EMPLOYEE_PTO'].includes(
              value
            )
          ) {
            valid = false
          }
          return valid
        }),
      approvalStatus: Yup.string()
        .nullable()
        .test('exists', 'invalid approval status', (value) => {
          let valid = true
          if (value && !['PENDING', 'APPROVED', 'DENIED'].includes(value)) {
            valid = false
          }
          return valid
        }),
      regionId: Yup.string().nullable(),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      setSubmitting(true)
      let startDateTime
      if (values.startTime) {
        startDateTime = combineDateAndTime(values.startDate, values.startTime, {
          utc: true,
          isoFormat: true,
          timezone: timezone,
        })
      }

      let endDateTime
      if (values.endTime) {
        endDateTime = combineDateAndTime(values.endDate, values.endTime, {
          utc: true,
          isoFormat: true,
          timezone: timezone,
        })
      }
      if (editNode) {
        updateEmployeeSchedule({
          variables: {
            input: {
              employeeScheduleInput: {
                id: editNode.id,
                startDateTime,
                endDateTime,
                employeeId: values.employee,
                locationId: values.locationId ? values.locationId : null,
                eventType: values.eventType,
                approvalStatus: values.approvalStatus,
                hourlyPay: values.hourlyPay,
                notes: values.notes,
                regionId: values.regionId,
              },
            },
          },
        })
      } else {
        createEmployeeSchedule({
          variables: {
            input: {
              employeeScheduleInput: {
                startDateTime,
                endDateTime,
                locationId: values.locationId,
                employeeId: values.employee,
                eventType: values.eventType,
                approvalStatus: values.approvalStatus,
                hourlyPay: values.hourlyPay,
                notes: values.notes,
                regionId: values.regionId,
              },
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (!showModal) {
      formik.resetForm()
    }
  }, [showModal])

  const handleEmployeeSearchChange = (formik, e) => {
    searchEmployee({
      variables: {
        searchTerm: e.target.value,
        regionIds: formik.values.regionId,
      },
      fetchPolicy: 'network-only',
    })
    formik.setFieldValue(`employeeSearch`, e.target.value)
    setShowEmployeeSearchResults(true)
  }

  const handleLocationBlur = (formik) => {
    setDisplayLocationResults(false)
    formik.setFieldTouched('locationId', true)
  }

  const handleEmployeeSearchKeyDown = (e) => {
    if (e.key === 'Backspace') {
      formik.setFieldValue(`employeeSearch`, '')
      formik.setFieldValue('employee', null)
      setShowEmployeeSearchResults(false)
    }
  }

  const hanndleEmployeeSearchClick = (row) => {
    formik.setFieldValue('employeeSearch', row.original.node.gaiaUser.fullName)
    formik.setFieldValue('employee', row.original.node.id)
    if (row.original.node.hourlyPay) {
      formik.setFieldValue('hourlyPay', row.original.node.hourlyPay)
    }
    setShowEmployeeSearchResults(false)
  }

  const handleDelete = () => {
    setSubmitting(true)
    deleteEmployeeSchedule({
      variables: {
        deleteEmployeeScheduleInput: {
          employeeScheduleIds: editNode.id,
        },
      },
    })
  }

  const innerToggle = () => {
    toggleModal()
    formik.resetForm()
    setEmployeeEditing(false)
  }

  if (!showModal) return <></>

  return (
    <>
      <div className="newSessionPackage">
        <Modal size="xl" show={showModal} onHide={innerToggle}>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <CalendarWeek className="mr-2" />
                {editNode ? (
                  <>Edit Work Schedule Event</>
                ) : (
                  <>New Work Schedule Event</>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Row>
                {!employeeNode && (
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Employee</Form.Label>
                    <Form.Control
                      value={formik.values.employeeSearch}
                      onChange={(e) => handleEmployeeSearchChange(formik, e)}
                      onKeyDown={handleEmployeeSearchKeyDown}
                      placeholder="Search"
                      className="form-control-sm"
                    />
                    <div className={showEmployeeSearchResults ? '' : 'd-none'}>
                      <Row style={{ position: 'relative', marginTop: '0px' }}>
                        <Col
                          md={{ span: 10, offset: 1 }}
                          style={{
                            position: 'absolute',
                            width: '100%',
                            top: 0,
                            zIndex: 1050,
                            paddingLeft: 0,
                            paddingRight: 0,
                            backgroundColor: 'white',
                          }}
                        >
                          <SortableInfiniteTable
                            hideGlobalFilter
                            displayHeader={false}
                            tableData={
                              employeesData?.employees?.edges
                                ? employeesData?.employees?.edges
                                : []
                            }
                            tableColumns={[
                              {
                                Header: '',
                                accessor: 'node.gaiaUser.fullName',
                              },
                            ]}
                            hasMoreTableData={false}
                            onRowClick={hanndleEmployeeSearchClick}
                            tableHeight={100}
                            rowPointer
                          />
                        </Col>
                      </Row>
                    </div>
                    {formik.errors.employee && (
                      <small className="text-danger">
                        {formik.errors.employee}
                      </small>
                    )}
                  </Form.Group>
                )}
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <>
                      <div className="d-flex">
                        <Form.Control
                          onBlur={() => handleLocationBlur(formik)}
                          disabled={Boolean(
                            formik.values.locationId || employeeEditing
                          )}
                          placeholder="Search Locations"
                          className={'form-control-sm'}
                          value={formik.values.locationValue}
                          onChange={(e) => handleLocationChange(e, formik)}
                        />

                        {formik.values.locationId && (
                          <>
                            <button
                              type="button"
                              className="btn-link mb-1 ms-2"
                              onClick={() => handleLocationXClick(formik)}
                            >
                              <Trash />
                            </button>
                          </>
                        )}
                      </div>

                      {locationData && displayLocationResults ? (
                        <Row style={{ position: 'relative', marginTop: '0px' }}>
                          <Col
                            md={12}
                            style={{
                              position: 'absolute',
                              width: '100%',
                              top: 0,
                              zIndex: 1050,
                              paddingLeft: 0,
                              paddingRight: 0,
                              backgroundColor: 'white',
                            }}
                          >
                            <InfiniteScroll
                              height={100}
                              dataLength={locationData.locations.edges.length} //This is important field to render the next data
                              next={handleFetchMoreLocations}
                              hasMore={
                                locationData?.locations.pageInfo.hasNextPage
                              }
                              loader={<Loading />}
                            >
                              <Table size="sm" hover>
                                <tbody>
                                  {locationData.locations.edges.map(
                                    (location) => {
                                      const { node } = location
                                      return (
                                        <tr
                                          onMouseDown={() =>
                                            handleLocationClick(node, formik)
                                          }
                                          key={node.id}
                                          className="hover text-decoration-none"
                                        >
                                          <td>
                                            {node.addressLineTwo &&
                                            node.name ? (
                                              <small>
                                                {`${node.name}, ${node.addressLineOne}, ${node.addressLineTwo}, ${node.city} ${node.zipCode}`}
                                              </small>
                                            ) : (
                                              <>
                                                {node.name ? (
                                                  <small>
                                                    {`${node.name}, ${node.addressLineOne}, ${node.city} ${node.zipCode}`}{' '}
                                                  </small>
                                                ) : (
                                                  <small>
                                                    {`${node.addressLineOne}, ${node.city} ${node.zipCode}`}{' '}
                                                  </small>
                                                )}
                                              </>
                                            )}
                                            <>
                                              {node.organization ? (
                                                <small>
                                                  | {node.organization?.name} |
                                                  Organization
                                                </small>
                                              ) : null}
                                            </>
                                            <>
                                              {node.studio ? (
                                                <small>| Studio</small>
                                              ) : null}
                                            </>
                                            <>
                                              {node?.subject?.gaiaUser
                                                ?.fullName ? (
                                                <small>
                                                  |{' '}
                                                  {
                                                    node?.subject?.gaiaUser
                                                      ?.fullName
                                                  }{' '}
                                                  | Subject
                                                </small>
                                              ) : null}
                                            </>
                                          </td>
                                        </tr>
                                      )
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </InfiniteScroll>
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Group>
                <Row>
                  <Col md={6} className="d-flex align-items-center">
                    <Form.Label className="mr-2">Starts</Form.Label>
                    <DatePicker
                      name="startDate"
                      className="form-control form-control-sm"
                      disabled={employeeEditing}
                      showPopperArrow={false}
                      popperPlacement="auto"
                      selected={formik.values.startDate}
                      onChange={(date) =>
                        formik.setFieldValue('startDate', date)
                      }
                      popperModifiers={{
                        flip: {
                          behavior: ['bottom'],
                        },
                        preventOverflow: {
                          enabled: false,
                        },
                        hide: {
                          enabled: false,
                        },
                      }}
                    />
                    {formik.errors.startDate && (
                      <small className="text-danger">
                        {formik.errors.startDate}
                      </small>
                    )}
                    <Form.Control
                      type="text"
                      name="startTime"
                      className="form-control-sm"
                      disabled={employeeEditing}
                      placeholder="e.g. 11:00am"
                      value={formik.values.startTime}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.startTime}
                    />
                    {formik.errors.startTime && (
                      <small className="text-danger">
                        {formik.errors.startTime}
                      </small>
                    )}
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <Form.Label className="mr-2">Ends</Form.Label>
                    <DatePicker
                      name="endDate"
                      className="form-control form-control-sm"
                      disabled={employeeEditing}
                      showPopperArrow={false}
                      popperPlacement="auto"
                      selected={formik.values.endDate}
                      onChange={(date) => formik.setFieldValue('endDate', date)}
                      popperModifiers={{
                        flip: {
                          behavior: ['bottom'],
                        },
                        preventOverflow: {
                          enabled: false,
                        },
                        hide: {
                          enabled: false,
                        },
                      }}
                    />
                    {formik.errors.endDate && (
                      <small className="text-danger">
                        {formik.errors.endDate}
                      </small>
                    )}
                    <Form.Control
                      type="text"
                      name="endTime"
                      className="form-control-sm"
                      disabled={employeeEditing}
                      placeholder="e.g. 11:00pm"
                      value={formik.values.endTime}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.endTime}
                    />
                    {formik.errors.endTime && (
                      <small className="text-danger">
                        {formik.errors.endTime}
                      </small>
                    )}
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Form.Group as={Col} md={4}>
                  <Form.Label>Hourly Pay</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    min="0"
                    name="hourlyPay"
                    className="form-control-sm"
                    value={formik.values.hourlyPay}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.hourlyPay}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.hourlyPay}
                  </Form.Control.Feedback>
                </Form.Group>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Event Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="eventType"
                      disabled={employeeEditing}
                      className="form-control-sm form-select"
                      value={formik.values.eventType}
                      onChange={formik.handleChange}
                    >
                      <option key="EMPLOYEE_WORKING" value="EMPLOYEE_WORKING">
                        Working
                      </option>
                      <option key="EMPLOYEE_PTO" value="EMPLOYEE_PTO">
                        Time Off
                      </option>
                      <option key="EMPLOYEE_SICK" value="EMPLOYEE_SICK">
                        Sick
                      </option>
                    </Form.Control>
                    {formik.errors.eventType && (
                      <small className="text-danger">
                        {formik.errors.eventType}
                      </small>
                    )}
                  </Form.Group>
                </Col>
                {(!employeeNode || employeeEditing) && (
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        disabled={employeeEditing}
                        name="approvalStatus"
                        className="form-control-sm form-select"
                        value={formik.values.approvalStatus}
                        onChange={formik.handleChange}
                      >
                        <option key="PENDING" value="PENDING">
                          Pending
                        </option>
                        <option key="APPROVED" value="APPROVED">
                          Approved
                        </option>
                        <option key="DENIED" value="DENIED">
                          Denied
                        </option>
                      </Form.Control>
                      {formik.errors.approvalStatus && (
                        <small className="text-danger">
                          {formik.errors.approvalStatus}
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                )}
              </Row>
              {loggedInUser?.canManageRegions && settings?.tenantRegions && (
                <Form.Row>
                  <Form.Group as={Col} md={3}>
                    <Form.Label className="d-block">Region</Form.Label>
                    <RegionSearchInput
                      dropdown
                      formik={formik}
                      error={formik.errors.regionId}
                      setAdditionalFields={(node, _) => {
                        if (node?.timezone) {
                          setTimezone(formatTimezone(node.timezone))
                        }
                      }}
                    />
                  </Form.Group>
                </Form.Row>
              )}
              <Form.Group>
                <Row>
                  <Col md={1}>
                    <Form.Label>Notes</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      style={{ height: '150px' }}
                      as="textarea"
                      name="notes"
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.notes}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.notes}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              {editNode && (
                <>
                  {displayCheckInOut && (
                    <Row className="mt-3 mb-2">
                      <Col md={12} className="d-flex align-items-center">
                        <button
                          type="button"
                          onClick={() =>
                            setDisplayWorkEvents(!displayWorkEvents)
                          }
                          className="px-0 btn-link mr-1"
                        >
                          <>
                            {displayWorkEvents ? (
                              <>
                                <CaretDown size={15} />
                              </>
                            ) : (
                              <>
                                <CaretRight size={15} />
                              </>
                            )}
                          </>
                        </button>
                        <Form.Label className="mb-0">Clock In / Out</Form.Label>
                      </Col>
                    </Row>
                  )}
                  {displayCheckInOut && displayWorkEvents && (
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            maxHeight: '100px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            fontSize: '12px',
                          }}
                          className="mt-2"
                        >
                          <Table
                            className="compact-table table-bordered"
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>Clock</th>
                                <th>Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {editNode?.timesheet?.timesheetEvents.edges.map(
                                ({ node }) => (
                                  <tr key={node.id}>
                                    <td>
                                      {node.eventType === 'CLOCK_OUT'
                                        ? 'Out'
                                        : 'In'}
                                    </td>
                                    <td>
                                      {toTimezone(node.dateTime, {
                                        onlyTime: true,
                                        timezone: timezone,
                                      })}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-3 mb-2">
                    <Col md={12} className="d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() =>
                          setDisplayNotifications(!displayNotifications)
                        }
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {displayNotifications ? (
                            <>
                              <CaretDown size={15} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={15} />
                            </>
                          )}
                        </>
                      </button>
                      <Form.Label className="mb-0">Notifications</Form.Label>
                    </Col>
                  </Row>
                  {displayNotifications && (
                    <Row>
                      <Col md={12}>
                        <NotificationsTable employeeSchedule={editNode} />
                      </Col>
                    </Row>
                  )}
                  <>
                    <Row className="mt-3 mb-2">
                      <Col md={12} className="d-flex align-items-center">
                        <button
                          type="button"
                          onClick={() => setDisplayAuditLog(!displayAuditLog)}
                          className="px-0 btn-link mr-1"
                        >
                          <>
                            {displayAuditLog ? (
                              <>
                                <CaretDown size={15} />
                              </>
                            ) : (
                              <>
                                <CaretRight size={15} />
                              </>
                            )}
                          </>
                        </button>
                        <Form.Label className="mb-0">History</Form.Label>
                      </Col>
                    </Row>
                    {displayAuditLog && (
                      <Row>
                        <Col md={12}>
                          <AuditLog
                            contentType={editNode.contentType.model}
                            id={editNode.id}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                </>
              )}

              <Row>
                <Col md={3}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    block
                    disabled={submitting}
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
                {displayCheckInOut && (
                  <Col md={3}>
                    {displayCheckInButton ? (
                      <Button
                        variant="outline-primary"
                        block
                        onClick={() => {
                          createTimesheetEventMutation({
                            variables: {
                              input: {
                                employeeScheduleId: editNode.id,
                                clockIn: true,
                              },
                            },
                          })
                        }}
                      >
                        <Alarm className="mr-2" />
                        Clock In
                      </Button>
                    ) : (
                      <Button
                        variant="outline-primary"
                        block
                        onClick={() => {
                          createTimesheetEventMutation({
                            variables: {
                              input: {
                                employeeScheduleId: editNode.id,
                                clockIn: false,
                              },
                            },
                          })
                        }}
                      >
                        <Alarm className="mr-2" />
                        Clock Out
                      </Button>
                    )}
                  </Col>
                )}
                {editNode && !employeeEditing && (
                  <Col md={3}>
                    <Button
                      variant="outline-danger"
                      onClick={handleDelete}
                      block
                      disabled={submitting}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                )}
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EmployeeScheduleModal
