import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Report from '../chart/LineChart'
import DateFilter from '../common/DateFilter'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import Loading from '../common/Loading'
import moment from 'moment'
import { useFormik } from 'formik'
import RegionSearchInput from '../common/node_search_input/RegionSearchInput'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { formatRegion } from '../../libs/utils'
import { formatTimezone } from '../../libs/utils'

const SessionReport = (props) => {
  const {
    showModal,
    organizationId,
    couponId,
    subjectGroupId,
    jobReports,
    sessionPackageId,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [chartSeries, setChartSeries] = useState([])
  const [chartCategories, setChartCategories] = useState([])
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [viewType, setViewType] = useState('Sessions Per Day')
  const [reportType, setReportType] = useState('sessionsBooked')
  const [sessions, setSessions] = useState([])
  const [fetchedAllSessions, setFetchedAllSessions] = useState(false)
  const [averageDays, setAverageDays] = useState(1)

  const generateDateRange = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const dates = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }
    return dates
  }

  const SESSIONS_QUERY = gql`
    query SessionsChartQuery(
      $cursor: String
      $organizationId: ID
      $subjectGroupId: ID
      $sessionPackageId: ID
      $couponId: ID
      $bookedStartDateGte: DateTime
      $bookedEndDateLte: DateTime
      $occuringStartDateGte: DateTime
      $occuringEndDateLte: DateTime
      $regionIds: [ID]
    ) {
      sessions(
        first: 250
        after: $cursor
        organization_Id: $organizationId
        subjectGroup_Id: $subjectGroupId
        coupon_Id: $couponId
        cancelled: false
        rescheduled: false
        sessionPackageChanged: false
        sessionPackage_Id: $sessionPackageId
        startDateTime_Gte: $occuringStartDateGte
        endDateTime_Lte: $occuringEndDateLte
        created_Gte: $bookedStartDateGte
        created_Lte: $bookedEndDateLte
        live: true
        region: $regionIds
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            startDateTime
            created
            mobile
            region {
              id
              name
              timezone
            }
          }
        }
      }
    }
  `

  const [querySessions, { data: sessionsData }] = useLazyQuery(SESSIONS_QUERY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const formik = useFormik({
    initialValues: {
      regions: [],
      timezone: loggedInUser?.defaultRegion?.formattedTimezone,
    },
    validateOnChange: false,
  })

  const queryVariables = () => {
    const variables = {
      organizationId,
      sessionPackageId,
      couponId,
      subjectGroupId,
    }
    if (reportType === 'sessionsBooked') {
      variables.bookedStartDateGte = startDateFilter
      variables.bookedEndDateLte = endDateFilter
    } else if (reportType === 'sessionsOccuring') {
      variables.occuringStartDateGte = startDateFilter
      variables.occuringEndDateLte = endDateFilter
    }
    if (formik.values.regions.length > 0) {
      variables.regionIds = formik.values.regions.map((region) => region.id)
    }
    return variables
  }

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      const daysDiff = Math.max(
        1,
        Math.ceil(
          (new Date(endDateFilter) - new Date(startDateFilter)) /
            (1000 * 60 * 60 * 24)
        ) + 1
      )
      setAverageDays(daysDiff)
      querySessions({ variables: queryVariables() })
    }
    setFetchedAllSessions(false)
    setChartSeries([])
    setSessions([])
    setChartCategories([])
  }, [startDateFilter, endDateFilter, reportType, formik.values.regions])

  const processSessionData = (sessionsData) => {
    if (sessionsData?.sessions) {
      setSessions((prevState) => [
        ...prevState,
        ...sessionsData.sessions.edges.map((edge) => edge.node),
      ])
      if (sessionsData.sessions.pageInfo.hasNextPage) {
        const variables = {
          ...queryVariables(),
          cursor: sessionsData.sessions.pageInfo.endCursor,
        }
        querySessions({ variables })
      } else {
        setFetchedAllSessions(true)
      }
    }
  }

  useEffect(() => {
    processSessionData(sessionsData)
  }, [sessionsData])

  useEffect(() => {
    if (fetchedAllSessions) {
      const dateRange = generateDateRange(startDateFilter, endDateFilter)
      const sessionCountsByRegion = {}
      sessions.forEach((session) => {
        let startDate
        if (reportType === 'sessionsBooked') {
          startDate = moment(session.created).toISOString().split('T')[0]
        } else if (reportType === 'sessionsOccuring') {
          startDate = moment(session.startDateTime).toISOString().split('T')[0]
        }

        const region = formatRegion(session.region)
        const mobileType =
          session.mobile === null
            ? 'webMobile'
            : session.mobile === false
              ? 'webOnly'
              : 'mobileOnly'

        if (!sessionCountsByRegion[region]) {
          sessionCountsByRegion[region] = {
            webMobile: {},
            webOnly: {},
            mobileOnly: {},
          }
        }

        sessionCountsByRegion[region][mobileType][startDate] =
          (sessionCountsByRegion[region][mobileType][startDate] || 0) + 1
      })

      setChartCategories(dateRange)

      let series = []

      Object.keys(sessionCountsByRegion).forEach((region) => {
        const webMobileCounts = sessionCountsByRegion[region].webMobile
        const webOnlyCounts = sessionCountsByRegion[region].webOnly
        const mobileOnlyCounts = sessionCountsByRegion[region].mobileOnly

        const createSeriesData = (counts) =>
          viewType === 'Sessions Per Day'
            ? dateRange.map((date) => counts[date] || 0)
            : dateRange.map((date, index) => {
                if (index === 0) {
                  return counts[date] || 0
                }
                return dateRange
                  .slice(0, index + 1)
                  .reduce(
                    (total, previousDate) =>
                      total + (counts[previousDate] || 0),
                    0
                  )
              })

        const regionSuffix =
          loggedInUser?.canManageRegions && settings?.tenantRegions
            ? ` (${region})`
            : ''
        series.push(
          {
            name: `Web & Mobile${regionSuffix}`,
            data: createSeriesData(webMobileCounts),
          },
          {
            name: `Web Only${regionSuffix}`,
            data: createSeriesData(webOnlyCounts),
          },
          {
            name: `Mobile Only${regionSuffix}`,
            data: createSeriesData(mobileOnlyCounts),
          }
        )
      })

      setChartSeries(series)
    }
  }, [fetchedAllSessions, startDateFilter, endDateFilter, viewType, sessions])

  useEffect(() => {
    if (!showModal) {
      setChartCategories([])
      setChartSeries([])
    }
  }, [showModal])

  return (
    <>
      <Row className={jobReports ? 'mb-4 mt-4' : 'mb-4'}>
        <Col md={'auto'}>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Sessions From"
            placeholderEnd={'Sessions Until'}
            timezone={formik.values.timezone}
          />
        </Col>
        <Col md={2} className="mt-2">
          <select
            className="form-control-sm form-select"
            name="group"
            onChange={(e) => {
              setReportType(e.target.value)
            }}
            value={reportType}
          >
            <option value="sessionsBooked">Sessions Booked</option>
            <option value="sessionsOccuring">Sessions Occuring</option>
          </select>
        </Col>
        <Col md={2} className="mt-2">
          <select
            className="form-control-sm form-select"
            name="group"
            value={viewType}
            onChange={(e) => setViewType(e.target.value)}
          >
            <option value="Sessions Per Day">Sessions Per Day</option>
            <option value="Total Sessions">Total Sessions</option>
          </select>
        </Col>
        {loggedInUser?.canManageRegions && settings?.tenantRegions && (
          <Col md={2} className="mt-2">
            <RegionSearchInput
              formik={formik}
              dropdown
              placeholder="Select Region"
              multiple
              setAdditionalFields={(node, _) => {
                if (node?.timezone) {
                  formik.setFieldValue(
                    'timezone',
                    formatTimezone(node.timezone)
                  )
                }
              }}
            />
          </Col>
        )}
      </Row>
      {startDateFilter && endDateFilter && !fetchedAllSessions && (
        <Row className="mt-4">
          <Col>
            <Loading message="Loading Sessions Chart..." />
          </Col>
        </Row>
      )}
      {fetchedAllSessions && (
        <>
          <div className="statistics-summary">
            <Row className="ml-2 mb-4 mt-3">
              <Col md={4} className="statistic">
                <h5>
                  {viewType === 'Sessions Per Day'
                    ? `Average ${
                        reportType === 'sessionsBooked'
                          ? 'Sessions Booked'
                          : 'Sessions Occurring'
                      } Per Day`
                    : `Total ${
                        reportType === 'sessionsBooked'
                          ? 'Sessions Booked'
                          : 'Sessions Occurring'
                      }`}
                </h5>
                <p>
                  {viewType === 'Sessions Per Day'
                    ? (sessions.length / averageDays).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : sessions.length}
                </p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="d-flex justify-content-center">
              <Report
                series={chartSeries}
                categories={chartCategories}
                yAxisFormatter={(value) => `${value}`}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default SessionReport
