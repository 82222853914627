import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Row, Col } from 'react-bootstrap'
import { useMutation, gql, useReactiveVar } from '@apollo/client'
import toast from 'react-hot-toast'
import MetaDataTable from '../common/MetaData'
import { loggedInUserVar } from '../../libs/apollo'
import { PlusCircle } from 'react-bootstrap-icons'

const SubjectGroupSubjectMetadata = (props) => {
  const { sgsId, sgsMetadata, toggleModal, showModal, refetch } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canUpdate = [
    'Scheduling Analyst',
    'Administrator',
    'Scheduling Manager',
  ].includes(loggedInUser?.permissions?.group)
  const [metaData, setMetadata] = useState()
  const [update] = useMutation(
    gql`
      mutation UpdateSubjectGroupSubject(
        $updateSubjectGroupSubjectInput: UpdateSubjectGroupSubjectInput!
      ) {
        updateSubjectGroupSubject(input: $updateSubjectGroupSubjectInput) {
          subjectGroupSubject {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Custom Fields Saved')
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: [refetch],
    }
  )
  useEffect(() => {
    if (showModal && sgsMetadata) {
      setMetadata(
        sgsMetadata === '{}' ? {} : JSON.parse(sgsMetadata ? sgsMetadata : '{}')
      )
    }
  }, [showModal])
  const handleSubmit = () => {
    if ('' in metaData) {
      setMetadata((prevState) => {
        delete prevState['']
        return prevState
      })
    }
    update({
      variables: {
        updateSubjectGroupSubjectInput: {
          subjectGroupSubjectInput: {
            id: sgsId,
            metadata: JSON.stringify(metaData),
          },
        },
      },
    })
  }
  if (showModal && metaData) {
    return (
      <>
        <div className="editSessionNoShowPolicyModal">
          <Modal
            id="editSessionNoShowPolicyModal"
            size="lg"
            show={showModal}
            onHide={toggleModal}
            aria-labelledby="new-title"
            className="invmodal detail"
          >
            <Form>
              <Modal.Header closeButton>
                <Modal.Title id="new-title">Custom Fields</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <MetaDataTable
                      metaData={metaData}
                      setMetaData={setMetadata}
                      noMessage
                      edit={canUpdate}
                    />
                  </Col>
                </Row>
                {canUpdate && (
                  <Row className="mt-2">
                    <Col md={3}>
                      <Button
                        block
                        variant="outline-primary"
                        className="mt-2"
                        onClick={handleSubmit}
                      >
                        <PlusCircle size={12} className="mr-1" />
                        Save
                      </Button>
                    </Col>
                  </Row>
                )}
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default SubjectGroupSubjectMetadata
