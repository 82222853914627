import React from 'react'
import { Row, Col, Table, Button } from 'react-bootstrap'
import { useQuery, gql, useMutation } from '@apollo/client'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { useReactiveVar } from '@apollo/client'
import { Alarm } from 'react-bootstrap-icons'
import { loggedInUserVar } from '../../libs/apollo'
import toast from 'react-hot-toast'
import { settingsVar } from '../../libs/apollo'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { formatTimezone } from '../../libs/utils'

const JobDetailEmployeeAssignments = (props) => {
  const { jobId } = props
  let hasMoreEmployeeAssignments = true
  let employeeAssignmentsCursor
  const { toTimezone } = useDateTimeConverter()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [createTimesheetEventMutation] = useMutation(
    gql`
      mutation CreateTimesheetEvent($input: CreateTimesheetEventInput!) {
        createTimesheetEvent(input: $input) {
          timesheetEvent {
            id
            eventType
          }
        }
      }
    `,
    {
      refetchQueries: ['EmployeeAssignmentsQuery'],
      onCompleted: (data) => {
        const eventType = data.createTimesheetEvent.timesheetEvent.eventType
        if (eventType === 'CLOCK_IN') {
          toast.success('Clocked In')
        } else if (eventType === 'CLOCK_OUT') {
          toast.success('Clocked Out')
        }
      },
    }
  )
  const tableColumns = [
    {
      Header: 'Employee',
      accessor: 'employee',
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
    {
      Header: 'Shift',
      accessor: (row) => {
        let shift
        if (row.startDateTime) {
          shift = `${row.startDateTime}`
        }
        if (row.endDateTime) {
          if (shift) {
            shift = `${shift} - ${row.endDateTime}`
          } else {
            shift = `End ${row.endDateTime}`
          }
        } else if (shift) {
          shift = `Start ${shift}`
        }
        return shift
      },
    },
    {
      Header: 'Clocked In / Out',
      id: 'clockedInOut',
      accessor: (row) => {
        return (
          <>
            {row.timesheetEvents.length > 0 && (
              <div
                style={{
                  maxHeight: '80px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  fontSize: '13px',
                }}
                className="mt-2"
              >
                <Table className="compact-table table-bordered" responsive>
                  <thead>
                    <tr>
                      <th>Clocked</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  {row.timesheetEvents.map((timesheetEvent, i) => (
                    <tr key={i} style={{ borderTop: i !== 0 ? 'none' : '' }}>
                      <td>
                        {timesheetEvent.eventType === 'CLOCK_OUT' ? (
                          <>Out</>
                        ) : (
                          <>In</>
                        )}
                      </td>
                      <td>{timesheetEvent.dateTime}</td>
                    </tr>
                  ))}
                </Table>
              </div>
            )}
          </>
        )
      },
    },
  ]
  if (settings.tenantSubscription !== 'Basic') {
    tableColumns.push({
      Header: 'Equipment',
      accessor: 'equipment',
    })
    tableColumns.push({
      Header: 'Equipment Pickup Area',
      accessor: 'equipmentPickupArea',
    })
  }
  const {
    loading: employeeAssignmentsQueryLoading,
    error: employeeAssignmentsQueryError,
    data: employeeAssignmentsQueryData,
    fetchMore: employeeAssignmentsQueryFetchMore,
  } = useQuery(
    gql`
      query EmployeeAssignmentsQuery($sessionsJob: ID, $cursor: String) {
        employeeJobs(first: 250, after: $cursor, job: $sessionsJob) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              job {
                region {
                  timezone
                }
              }
              employee {
                id
                gaiaUser {
                  firstName
                  lastName
                }
              }
              role {
                name
              }
              startDateTime
              endDateTime
              equipmentPickupArea
              equipmentItems {
                edges {
                  node {
                    name
                    serialNumber
                    returned
                  }
                }
              }
              equipmentBags {
                edges {
                  node {
                    name
                    returned
                    employee {
                      id
                    }
                  }
                }
              }
              timesheet {
                id
                region {
                  timezone
                }
                timesheetEvents(orderBy: "created") {
                  edges {
                    node {
                      id
                      dateTime
                      eventType
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        sessionsJob: jobId,
      },
    }
  )
  const handleEmployeeAssignmentsQueryData = () => {
    hasMoreEmployeeAssignments =
      employeeAssignmentsQueryData.employeeJobs.pageInfo.hasNextPage
    if (employeeAssignmentsQueryData.employeeJobs.pageInfo.endCursor) {
      employeeAssignmentsCursor =
        employeeAssignmentsQueryData.employeeJobs.pageInfo.endCursor
    }
    const currentEmployeeAssignments = []
    employeeAssignmentsQueryData.employeeJobs.edges.forEach((employeeJob) => {
      const employeeJobNode = employeeJob.node
      const timesheetEvents = []
      const timesheetTimezone = formatTimezone(
        employeeJobNode?.timesheet?.region?.timezone
      )
      employeeJobNode?.timesheet?.timesheetEvents.edges.forEach(
        (timesheetEvent) => {
          const timesheetEventNode = timesheetEvent.node
          timesheetEvents.push({
            dateTime: timesheetEventNode.dateTime
              ? toTimezone(timesheetEventNode.dateTime, {
                  onlyTime: true,
                  timezone: timesheetTimezone,
                })
              : '',
            eventType: timesheetEventNode.eventType,
            id: timesheetEventNode.id,
          })
        }
      )
      const equipmentRows = []
      employeeJobNode.equipmentBags.edges.forEach((equipmentBag) => {
        equipmentRows.push({
          type: 'Bag',
          name: equipmentBag.node.name,
          checkedOut: equipmentBag.node.returned ? 'No' : 'Yes',
          longTerm: equipmentBag.node.employee && 'Yes',
        })
      })
      employeeJobNode.equipmentItems.edges.forEach((equipmentItem) => {
        equipmentRows.push({
          type: 'Item',
          name: equipmentItem.node.name,
          checkedOut: equipmentItem.node.returned ? 'No' : 'Yes',
          longTerm: null,
        })
      })
      const equipment = (
        <>
          {equipmentRows.length > 0 && (
            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
              <Table className="compact-table table-bordered" responsive>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Checked Out</th>
                    <th>Long Term</th>
                  </tr>
                </thead>
                {equipmentRows.map((equipmentRow, index) => {
                  return (
                    <tr
                      key={index}
                      style={{ borderTop: index !== 0 ? 'none' : '' }}
                    >
                      <td>{equipmentRow.type}</td>
                      <td>{equipmentRow.name}</td>
                      <td>{equipmentRow.checkedOut}</td>
                      <td>{equipmentRow.longTerm}</td>
                    </tr>
                  )
                })}
              </Table>
            </div>
          )}
        </>
      )
      let employeeAssignment = 'Unassigned'
      if (employeeJobNode.employee) {
        const gaiaUserNode = employeeJobNode.employee.gaiaUser
        employeeAssignment = `${gaiaUserNode.firstName} ${gaiaUserNode.lastName}`
      }
      const role = employeeJobNode.role
        ? employeeJobNode.role.name
        : 'Unassigned'
      let employeeId
      if (employeeJobNode.employee) {
        employeeId = employeeJobNode.employee.id
      }
      const timezone = formatTimezone(employeeJobNode.job?.region?.timezone)
      const startDateTime = employeeJobNode.startDateTime
        ? toTimezone(employeeJobNode.startDateTime, {
            onlyTime: true,
            timezone: timezone,
          })
        : ''
      const endDateTime = employeeJobNode.endDateTime
        ? toTimezone(employeeJobNode.endDateTime, {
            onlyTime: true,
            timezone: timezone,
          })
        : ''
      currentEmployeeAssignments.push({
        employee: employeeAssignment,
        equipmentPickupArea: employeeJobNode.equipmentPickupArea,
        role: role,
        equipment,
        employeeId,
        startDateTime,
        endDateTime,
        id: employeeJobNode.id,
        timesheetEvents,
      })
    })
    return currentEmployeeAssignments
  }
  const fetchMoreEmployeeEquipment = () => {
    employeeAssignmentsQueryFetchMore({
      variables: {
        sessionsJob: jobId,
        cursor: employeeAssignmentsCursor,
      },
    })
  }
  if (employeeAssignmentsQueryLoading || !loggedInUser) return <></>
  if (employeeAssignmentsQueryError)
    return <>Error loading employee equipment</>
  const employeeAssignments = handleEmployeeAssignmentsQueryData()
  let loggedInUserAssignment = employeeAssignments.filter(
    (employeeAssignment) =>
      employeeAssignment.employeeId === loggedInUser.employee.id
  )
  if (loggedInUserAssignment) {
    loggedInUserAssignment = loggedInUserAssignment[0]
  }
  return (
    <>
      <div className="equipment mt-3">
        <>
          {loggedInUserAssignment && (
            <>
              <Row>
                <Col>
                  {loggedInUserAssignment.timesheetEvents &&
                  (loggedInUserAssignment.timesheetEvents.length === 0 ||
                    loggedInUserAssignment.timesheetEvents[
                      loggedInUserAssignment.timesheetEvents.length - 1
                    ].eventType === 'CLOCK_OUT') ? (
                    <Button
                      variant="link"
                      onClick={() => {
                        createTimesheetEventMutation({
                          variables: {
                            input: {
                              employeeJobId: loggedInUserAssignment.id,
                              clockIn: true,
                            },
                          },
                        })
                      }}
                    >
                      <Alarm className="mr-2" />
                      Clock In
                    </Button>
                  ) : (
                    <Button
                      variant="link"
                      onClick={() => {
                        createTimesheetEventMutation({
                          variables: {
                            input: {
                              employeeJobId: loggedInUserAssignment.id,
                              clockIn: false,
                            },
                          },
                        })
                      }}
                    >
                      <Alarm className="mr-2" />
                      Clock Out
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}
          {employeeAssignments.length > 0 ? (
            <Row className="mt-2">
              <Col md={12}>
                <SortableInfiniteTable
                  tableData={employeeAssignments}
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMoreEmployeeEquipment}
                  hasMoreTableData={hasMoreEmployeeAssignments}
                  tableHeight={550}
                />
              </Col>
            </Row>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                backgroundColor: '#f9f9f9',
                borderRadius: '10px',
                padding: '20px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                color: '#555',
              }}
            >
              No Employee Assignments
            </div>
          )}
        </>
      </div>
    </>
  )
}
export default JobDetailEmployeeAssignments
