import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap'
import { PlusCircle, Trash } from 'react-bootstrap-icons'
import SubjectGroupRegionModal from './SubjectGroupRegionModal'
import DeleteSubjectGroupRegionModal from './DeleteSubjectGroupRegionModal'
import { formatRegion } from '../../libs/utils'
import SortableInfiniteTable from '../common/SortableInfiniteTable'

const SubjectGroupRegions = (props) => {
  const { subjectGroupId } = props
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [checkedIds, setCheckedIds] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [subjectGroupRegion, setSubjectGroupRegion] = useState()
  const defaultOrderBy = 'region__name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query SubjectGroupRegions(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $subjectGroupId: ID
      ) {
        subjectGroupRegions(
          first: 50
          after: $cursor
          orderBy: $orderBy
          region_Name_Icontains: $searchTerm
          subjectGroup: $subjectGroupId
        ) {
          nodeCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              default
              region {
                id
                name
                timezone
              }
              subjectGroup {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {
        orderBy: defaultOrderBy,
      }
      if (subjectGroupId) {
        variables.subjectGroupId = subjectGroupId
      }
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.subjectGroupRegions) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.subjectGroupRegions?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (subjectGroupId) {
      variables.subjectGroupId = subjectGroupId
    }
    queryFetchMore({ variables })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (subjectGroupId) {
      variables.subjectGroupId = subjectGroupId
    }
    query({
      variables,
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (subjectGroupId) {
      variables.subjectGroupId = subjectGroupId
    }
    query({ variables })
  }

  const onCellClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setSubjectGroupRegion(node)
    } else {
      setSubjectGroupRegion()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div className="SubjectGroupRegions">
        <Row className="mb-1">
          <Col>
            <div>
              <Button
                variant="link"
                onClick={() => {
                  toggleModal()
                }}
              >
                <PlusCircle className="mr-2" />
                New Region
              </Button>
              {checkedIds.length > 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedIds.length === 1 ? (
                    <>Delete Subject Group Region</>
                  ) : (
                    <>Delete Subject Group Regions</>
                  )}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={'Search Subject Group Regions'}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2 mb-3">
          <Col md={6}>
            <SortableInfiniteTable
              loading={loadingSearch || !queryData}
              tableData={
                queryData?.subjectGroupRegions?.edges
                  ? queryData.subjectGroupRegions.edges
                  : []
              }
              loadingMessage="Loading Subject Group Regions..."
              tableColumns={[
                {
                  Header: 'Region',
                  id: 'region',
                  orderBy: 'region__name',
                  serverSort: true,
                  accessor: (row) => <>{formatRegion(row.node.region)}</>,
                },
                {
                  Header: 'Default',
                  id: 'default',
                  accessor: (row) => {
                    let defaultValue = 'No'
                    if (row.node.default) {
                      defaultValue = 'Yes'
                    }
                    return defaultValue
                  },
                  serverSort: true,
                },
                {
                  disableSortBy: true,
                  Header: (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const appendIds = []
                              queryData.subjectGroupRegions.edges.forEach(
                                (subjectGroupRegion) => {
                                  if (
                                    !checkedIds.includes(
                                      subjectGroupRegion.node.id
                                    ) &&
                                    !subjectGroupRegion.node.default
                                  ) {
                                    appendIds.push(subjectGroupRegion.node.id)
                                  }
                                }
                              )
                              setCheckedIds((prevState) => {
                                return [...prevState, ...appendIds]
                              })
                            } else {
                              setCheckedIds([])
                            }
                          }}
                        />
                        {checkedIds.length > 0 && (
                          <span style={{ fontSize: '14px', marginTop: '5px' }}>
                            ({checkedIds.length})
                          </span>
                        )}
                      </Form.Group>
                    </>
                  ),
                  id: 'actions',
                  accessor: (row) => {
                    return (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            disabled={row.node.default}
                            checked={checkedIds.includes(row.node.id)}
                            onChange={(e) => handleCheck(e, row)}
                          />
                        </Form.Group>
                      </>
                    )
                  },
                },
              ]}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={
                queryData?.subjectGroupRegions?.pageInfo?.hasNextPage
              }
              onTdClicks={{
                region: (cell) => onCellClick(cell),
                default: (cell) => onCellClick(cell),
                subjectGroup: (cell) => onCellClick(cell),
              }}
              tableHeight={700}
              rowPointer
              hideGlobalFilter
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
      </div>
      {showForm && (
        <SubjectGroupRegionModal
          showModal={showForm}
          subjectGroupRegion={subjectGroupRegion}
          subjectGroupId={subjectGroupId}
          toggleModal={toggleModal}
        />
      )}
      <DeleteSubjectGroupRegionModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedIds={setCheckedIds}
        ids={checkedIds.length > 1 ? checkedIds : null}
        id={checkedIds.length === 1 ? checkedIds[0] : null}
      />
    </>
  )
}

export default SubjectGroupRegions
