import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Report from '../../chart/LineChart'
import DateFilter from '../../common/DateFilter'
import { useLazyQuery, gql, useQuery, useReactiveVar } from '@apollo/client'
import Loading from '../../common/Loading'
import { Trash } from 'react-bootstrap-icons'
import moment from 'moment'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'
import { useFormik } from 'formik'
import { formatTimezone } from '../../../libs/utils'
const EmployeeAssignmentsChart = (props) => {
  const { showModal } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [chartSeries, setChartSeries] = useState([])
  const [chartCategories, setChartCategories] = useState([])
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [viewType, setViewType] = useState('Assignments Per Day')
  const [nodes, setNodes] = useState([])
  const [fetchedAllNodes, setFetchedAllNodes] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState([])
  const [totalAssignments, setTotalAssignments] = useState(0)

  const generateDateRange = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const dates = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }
    return dates
  }

  const { data: roleData } = useQuery(
    gql`
      query Roles {
        roles(orderBy: "name", first: 250) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [query, { data: nodeData }] = useLazyQuery(
    gql`
      query EmployeeJobAssignmentChartQuery(
        $cursor: String
        $startDateGte: DateTime
        $endDateLte: DateTime
        $regionId: ID
      ) {
        employeeJobs(
          first: 250
          after: $cursor
          startDateTime_Gte: $startDateGte
          endDateTime_Lte: $endDateLte
          job_Region: $regionId
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              startDateTime
              endDateTime
              role {
                name
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: {
      regionId: loggedInUser?.defaultRegion?.id,
      timezone: loggedInUser?.defaultRegion?.formattedTimezone,
    },
    validateOnChange: false,
  })

  const queryVariables = () => {
    return {
      startDateGte: startDateFilter,
      endDateLte: endDateFilter,
      regionId: formik.values.regionId,
    }
  }

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      query({
        variables: queryVariables(),
      })
    }
    setFetchedAllNodes(false)
    setChartSeries([])
    setNodes([])
    setChartCategories([])
  }, [startDateFilter, endDateFilter, formik.values.regionId])

  useEffect(() => {
    if (nodeData?.employeeJobs) {
      setNodes((prevState) => [
        ...prevState,
        ...nodeData.employeeJobs.edges.map((edge) => edge.node),
      ])
      if (nodeData.employeeJobs.pageInfo.hasNextPage) {
        const variables = {
          ...queryVariables(),
          cursor: nodeData.employeeJobs.pageInfo.endCursor,
        }
        query({ variables })
      } else {
        setFetchedAllNodes(true)
      }
    }
  }, [nodeData])

  useEffect(() => {
    if (fetchedAllNodes) {
      const dateRange = generateDateRange(startDateFilter, endDateFilter)
      const nodesCount = nodes.reduce((acc, session) => {
        const startDate = moment(session.startDateTime)
          .toISOString()
          .split('T')[0]
        acc[startDate] = (acc[startDate] || 0) + 1
        return acc
      }, {})

      const roleCounts = nodes.reduce((acc, session) => {
        const startDate = moment(session.startDateTime)
          .toISOString()
          .split('T')[0]
        if (!acc[session.role.name]) {
          acc[session.role.name] = Array(dateRange.length).fill(0)
        }
        const index = dateRange.indexOf(startDate)
        if (index !== -1) {
          acc[session.role.name][index] += 1
        }
        return acc
      }, {})

      let roleSeriesData = Object.entries(roleCounts).map(([role, counts]) => {
        let cumulativeCount = 0
        const data = counts.map((count) => {
          if (viewType === 'Total Assignments') {
            cumulativeCount += count
            return cumulativeCount
          }
          return count
        })
        return { name: role, data }
      })

      if (selectedRoles.length > 0) {
        roleSeriesData = roleSeriesData.filter((role) =>
          selectedRoles.map((r) => r.name).includes(role.name)
        )
      }

      setChartCategories(dateRange)

      let cumulativeTotal = 0
      const seriesData =
        viewType === 'Assignments Per Day'
          ? dateRange.map((date) => nodesCount[date] || 0)
          : dateRange.map((date) => {
              cumulativeTotal += nodesCount[date] || 0
              return cumulativeTotal
            })

      setTotalAssignments(cumulativeTotal)

      if (selectedRoles.length > 0) {
        setChartSeries(roleSeriesData)
      } else {
        setChartSeries([
          { name: viewType, data: seriesData },
          ...roleSeriesData,
        ])
      }
    }
  }, [
    fetchedAllNodes,
    startDateFilter,
    endDateFilter,
    viewType,
    nodes,
    selectedRoles,
  ])

  useEffect(() => {
    if (!showModal) {
      setChartCategories([])
      setChartSeries([])
    }
  }, [showModal])

  return (
    <>
      <Row className="mb-4">
        <Col md={'auto'}>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Assignments From"
            placeholderEnd="Assignments Until"
            timezone={formik.values.timezone}
          />
        </Col>
        <Col md={2} className="mt-2">
          <Form.Control
            as="select"
            className="form-control-sm"
            value={viewType}
            onChange={(e) => setViewType(e.target.value)}
          >
            <option value="Assignments Per Day">Assignments Per Day</option>
            <option value="Total Assignments">Total Assignments</option>
          </Form.Control>
        </Col>
        <Col md={2} className="mt-2">
          <Form.Group>
            <Form.Control
              as="select"
              name="bookSession"
              className="form-control form-control-sm"
              onChange={(e) => {
                if (e.target.value) {
                  const role = roleData.roles.edges.filter(
                    (edge) => edge.node.id === e.target.value
                  )[0]
                  setSelectedRoles((prevState) => [...prevState, role.node])
                }
              }}
            >
              <option value="">Select Roles</option>
              {roleData?.roles?.edges.map((role) => {
                const selected = selectedRoles.some(
                  (roleItem) => roleItem.id === role.node.id
                )
                if (!selected) {
                  return (
                    <option key={role.node.id} value={role.node.id}>
                      {role.node.name}
                    </option>
                  )
                }
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        {loggedInUser?.canManageRegions && settings?.tenantRegions && (
          <Col md={2} className="mt-2">
            <RegionSearchInput
              formik={formik}
              placeholder="Select Region"
              mustHaveValue
              dropdown
              setAdditionalFields={(node, _) => {
                if (node?.timezone) {
                  formik.setFieldValue(
                    'timezone',
                    formatTimezone(node.timezone)
                  )
                }
              }}
            />
          </Col>
        )}
        <Col>
          <div style={{ fontSize: '18px' }} className="mb-2">
            {selectedRoles?.map(({ name, id }, i) => (
              <span
                style={{
                  padding: '5px',
                  border: '.5px solid #6c757d',
                  borderRadius: '0.25rem',
                  marginLeft: i > 0 ? '0.5rem' : '0',
                }}
                key={i}
              >
                <Trash
                  className="mr-2 btn-link"
                  size={14}
                  onClick={() => {
                    const updatedRoles = selectedRoles.filter(
                      (role) => role.id !== id
                    )
                    setSelectedRoles(updatedRoles)
                  }}
                />
                <span style={{ fontSize: '14px' }}>{name}</span>
              </span>
            ))}
          </div>
        </Col>
      </Row>
      {startDateFilter && endDateFilter && !fetchedAllNodes && (
        <Row>
          <Col>
            <Loading message="Loading Assignments Chart..." />
          </Col>
        </Row>
      )}
      {fetchedAllNodes && (
        <>
          <div className="statistics-summary">
            <Row className="mb-4">
              <Col md={4} className="statistic">
                <h5>Total Assignments</h5>
                <p>{totalAssignments}</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="d-flex justify-content-center">
              <Report
                series={chartSeries}
                categories={chartCategories}
                yAxisFormatter={(value) => `${value}`}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default EmployeeAssignmentsChart
