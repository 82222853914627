import React, { useState } from 'react'
import { Modal, Button, Row, Col, Dropdown } from 'react-bootstrap'
import { Survey } from 'survey-react-ui'
import { Model } from 'survey-core'
import { CloudArrowDown, XCircle } from 'react-bootstrap-icons'
import { downloadJSON } from '../../libs/utils'
import SortableTable from '../common/SortableTable'
import { useHistory } from 'react-router-dom'
import AdminSessionDetailModal from '../sessions/AdminSessionDetailModal'
import JobDetailModal from '../schedule/JobDetailModal'
import { SurveyPDF } from 'survey-pdf'
import { useDateTimeConverter } from '../../libs/useDateTime'

const FormSubmissionModal = ({ showModal, closeModal, submitForm }) => {
  const history = useHistory()
  const { toTimezone } = useDateTimeConverter()
  const surveyJson = submitForm?.form?.form
    ? JSON.parse(submitForm.form.form)
    : {}
  const surveyData = submitForm?.submission
    ? JSON.parse(submitForm.submission)
    : {}

  const [jobDetailId, setJobDetailId] = useState(null)
  const [sessionDetailId, setSessionDetailId] = useState(null)

  const downloadPdf = (surveyJson, surveyData) => {
    const surveyPdf = new SurveyPDF(surveyJson, {
      fontSize: 14,
      margins: { left: 10, right: 10, top: 10, bottom: 10 },
    })
    surveyPdf.data = surveyData
    surveyPdf.save(`${submitForm?.form?.name}.pdf`)
  }

  const surveyModel = new Model(surveyJson)
  surveyModel.data = surveyData
  surveyModel.mode = 'display'

  let session = null
  if (submitForm?.form?.session) {
    if (submitForm.form.session.subject) {
      session = submitForm.form.session.subject.gaiaUser.fullName
    }
    if (submitForm.form.session.organization) {
      session = `${session} | ${submitForm.form.session.organization.name}`
    }
    session = `${session} | ${session.job.description}`
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          closeModal()
          document.body.classList.remove('form-submission-modal')
        }}
        size={'xl'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Form Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h5>Form</h5>
            </Col>
            <Col>
              <h5>Submitted</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="btn-link"
                onClick={() => {
                  history.push(`/form/${submitForm?.form?.id}`)
                }}
              >
                {submitForm?.form?.name}
              </div>
            </Col>
            <Col>
              {`${submitForm?.gaiaUser?.fullName ? submitForm?.gaiaUser?.fullName : 'Anonymous'} on 
              ${toTimezone(submitForm?.created, {
                humanReadableSort: true,
                settingTimezone: true,
              })}`}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Survey model={surveyModel} />
            </Col>
          </Row>
          <Row className="mb-5 mt-3">
            <Col>
              <SortableTable
                columns={[
                  {
                    Header: 'Subject',
                    accessor: (row) => {
                      if (row?.subject?.gaiaUser?.fullName) {
                        return (
                          <div
                            className="btn-link"
                            onClick={() => {
                              history.push(`/subject/${row?.subject?.id}`)
                            }}
                          >
                            {row?.subject?.gaiaUser?.fullName}
                          </div>
                        )
                      }
                    },
                  },
                  {
                    Header: 'Session',
                    accessor: (row) => {
                      if (session) {
                        return (
                          <div
                            className="btn-link"
                            onClick={() => {
                              setSessionDetailId(row.session.id)
                            }}
                          >
                            {session}
                          </div>
                        )
                      }
                    },
                  },
                  {
                    Header: 'Job',
                    accessor: (row) => {
                      if (row.job) {
                        return (
                          <div
                            className="btn-link"
                            onClick={() => {
                              setJobDetailId(row.job.id)
                            }}
                          >
                            {row.job.description}
                          </div>
                        )
                      }
                    },
                  },
                  {
                    Header: 'Organization',
                    accessor: (row) => {
                      if (row?.organization) {
                        return (
                          <div
                            className="btn-link"
                            onClick={() => {
                              history.push(
                                `/organization/${row?.organization?.id}`
                              )
                            }}
                          >
                            {row?.organization?.name}
                          </div>
                        )
                      }
                    },
                  },
                  {
                    Header: 'Subject Group',
                    accessor: (row) => {
                      if (row?.subjectGroup) {
                        return (
                          <div
                            className="btn-link"
                            onClick={() => {
                              history.push(
                                `/subject-group/${row?.subjectGroup?.id}`
                              )
                            }}
                          >
                            {row?.subjectGroup?.name}
                          </div>
                        )
                      }
                    },
                  },
                  {
                    Header: 'Employee',
                    accessor: (row) => {
                      if (row?.employee?.gaiaUser?.fullName) {
                        return (
                          <div
                            className="btn-link"
                            onClick={() => {
                              history.push(`/employee/${row?.employee?.id}`)
                            }}
                          >
                            {row?.employee?.gaiaUser?.fullName}
                          </div>
                        )
                      }
                    },
                  },
                  {
                    Header: 'Employee Job',
                    accessor: (row) => {
                      if (row?.employeeJob) {
                        return (
                          <div
                            className="btn-link"
                            onClick={() => {
                              setJobDetailId(row.job.id)
                            }}
                          >
                            {row?.employeeJob?.description}
                          </div>
                        )
                      }
                    },
                  },
                ]}
                data={[submitForm]}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button
                variant="outline-primary"
                onClick={() => {
                  closeModal()
                  document.body.classList.remove('form-submission-modal')
                }}
                className="mr-2"
              >
                <XCircle className="mr-2" />
                Close
              </Button>
              <Dropdown className="d-inline ml-2">
                <Dropdown.Toggle
                  variant="outline-primary"
                  id="download-dropdown"
                >
                  <CloudArrowDown className="mr-2" /> Download
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      downloadPdf(surveyJson, surveyData)
                    }}
                  >
                    PDF
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      if (submitForm?.submission) {
                        downloadJSON(
                          JSON.parse(submitForm.submission),
                          'submission.json'
                        )
                      }
                    }}
                  >
                    JSON
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      if (submitForm?.form?.form) {
                        downloadJSON(
                          JSON.parse(submitForm.form.form),
                          'form.json'
                        )
                      }
                    }}
                  >
                    Form JSON
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {sessionDetailId && (
        <AdminSessionDetailModal
          showModal={!!sessionDetailId}
          closeModal={() => setSessionDetailId(null)}
          sessionId={sessionDetailId}
        />
      )}
      {jobDetailId && (
        <JobDetailModal
          showModal={!!jobDetailId}
          closeModal={() => setJobDetailId(null)}
          jobId={jobDetailId}
        />
      )}
    </>
  )
}

export default FormSubmissionModal
