import React, { useState } from 'react'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { Trash, PlusCircle, ClockHistory, Camera } from 'react-bootstrap-icons'
import AuditLog from '../../audit_log/AuditLog'
import Items from '../Item/Items'
import { Tab } from 'react-bootstrap'
import Tabs from '../../common/Tabs'

const ItemTypeForm = (props) => {
  const { itemType, afterSubmit } = props

  const [submitting, setSubmitting] = useState(false)
  const [activeTab, setActiveTab] = useState(
    !itemType?.miscellaneous ? 'items' : 'history'
  )
  const [deleteEquipmentItemType] = useMutation(
    gql`
      mutation DeleteEquipmentItemType(
        $deleteEquipmentItemTypeInput: DeleteEquipmentItemTypeInput!
      ) {
        deleteEquipmentItemType(input: $deleteEquipmentItemTypeInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Type Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [createEquipmentItemType] = useMutation(
    gql`
      mutation CreateEquipmentItemType($input: CreateEquipmentItemTypeInput!) {
        createEquipmentItemType(input: $input) {
          equipmentItemType {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Type Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [updateEquipmentItemType] = useMutation(
    gql`
      mutation UpdateEquipmentItemType($input: UpdateEquipmentItemTypeInput!) {
        updateEquipmentItemType(input: $input) {
          equipmentItemType {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Item Type Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )
  const formik = useFormik({
    initialValues: itemType
      ? {
          name: itemType.name,
          miscellaneous: itemType.miscellaneous,
          quantity: itemType.quantity,
          quantityAvailable: itemType.quantityAvailable,
        }
      : {
          name: '',
          miscellaneous: false,
          quantity: 0,
          quantityAvailable: 0,
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('required'),
      miscellaneous: Yup.boolean().nullable(),
      quantity: Yup.number().nullable(),
      quantityAvailable: Yup.number().nullable(),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      setSubmitting(true)
      if (itemType) {
        updateEquipmentItemType({
          variables: {
            input: {
              equipmentItemTypeInput: {
                id: itemType.id,
                name: values.name,
                miscellaneous: values.miscellaneous,
                quantity: values.miscellaneous ? values.quantity : null,
              },
            },
          },
        })
      } else {
        createEquipmentItemType({
          variables: {
            input: {
              equipmentItemTypeInput: {
                name: values.name,
                miscellaneous: values.miscellaneous,
                quantity: values.miscellaneous ? values.quantity : null,
              },
            },
          },
        })
      }
    },
  })

  const handleDelete = () => {
    setSubmitting(true)
    deleteEquipmentItemType({
      variables: {
        deleteEquipmentItemTypeInput: {
          equipmentItemTypeIds: itemType.id,
        },
      },
    })
  }

  return (
    <>
      <div id="equipmentItemTypeForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {((!itemType && formik.values.miscellaneous) || itemType) && (
            <>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Quantity
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="quantity"
                    value={
                      itemType?.miscellaneous && !formik.values.miscellaneous
                        ? 'TBD'
                        : formik.values.quantity
                    }
                    disabled={!formik.values.miscellaneous}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.quantity}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.quantity}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </>
          )}
          {itemType && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Quantity Available
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <Form.Control
                  name="quantityAvailable"
                  value={formik.values.quantityAvailable}
                  disabled={true}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.quantityAvailable}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} className="ml-2">
            <span
              onClick={() =>
                formik.setFieldValue(
                  `miscellaneous`,
                  !formik.values.miscellaneous
                )
              }
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '5px 10px',
              }}
            >
              <Form.Check
                inline
                name={`miscellaneous`}
                type="switch"
                label="Miscellaneous"
                checked={formik.values.miscellaneous}
                style={{ fontSize: '0.9rem', color: '#333' }}
              />
            </span>
          </Form.Group>
          {itemType && (
            <>
              <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                {!itemType.miscellaneous && (
                  <Tab
                    eventKey="items"
                    title={
                      <b>
                        <Camera className="mr-2" />
                        Items
                      </b>
                    }
                  >
                    {activeTab === 'items' && (
                      <div className="mt-3">
                        <Items
                          itemTypeId={itemType.id}
                          itemTypeName={itemType.name}
                          tableHeight={300}
                        />
                      </div>
                    )}
                  </Tab>
                )}
                <Tab
                  eventKey="history"
                  title={
                    <b>
                      <ClockHistory className="mr-2" />
                      History
                    </b>
                  }
                >
                  {activeTab === 'history' && (
                    <div className="mt-3">
                      <AuditLog
                        contentType={itemType.contentType.model}
                        id={itemType.id}
                        tableHeight={300}
                      />
                    </div>
                  )}
                </Tab>
              </Tabs>
            </>
          )}
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                variant="outline-primary"
                block
                disabled={submitting}
              >
                <PlusCircle className="mr-2" />
                Save
              </Button>
            </Col>

            {itemType && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  onClick={handleDelete}
                  block
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
        </Form>
      </div>
    </>
  )
}

export default ItemTypeForm
