import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Form, Row, Col } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'
import produce from 'immer'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import './../schedule/JobsSearchFilter.css'
import Loading from '../common/Loading'
import moment from 'moment'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { formatTimezone } from '../../libs/utils'
import { useLocation } from 'react-router-dom'

const TaskSearchFilter = (props) => {
  const { toTimezone } = useDateTimeConverter()
  const location = useLocation()
  const { appliedSearchFilters, setAppliedSearchFilters, setSearch, search } =
    props
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRecordType, setSelectedRecordType] = useState()
  const [searchFilters, setSearchFilters] = useState([])
  const [hasMoreSearchFilters, setHasMoreSearchFilters] = useState(true)
  const [jobsCursor, setJobsCursor] = useState()
  const [employeesCursor, setEmployeesCursor] = useState()
  const [subjectGroupsCursor, setSubjectGroupsCursor] = useState()
  const [organizationsCursor, setOrganizationsCursor] = useState()
  const [taskCollectionsCursor, setTaskCollectionsCursor] = useState()
  const [tasksCursor, setTasksCursor] = useState()
  const [taskStatusesCursor, setTaskStatusesCursor] = useState()
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [filtersQuery, { data, fetchMore, loading }] = useLazyQuery(
    gql`
      query TaskSearchFilterQuery(
        $jobsCursor: String
        $employeesCursor: String
        $subjectGroupsCursor: String
        $organizationsCursor: String
        $tasksCursor: String
        $taskCollectionsCursor: String
        $taskStatusesCursor: String
        $searchTerm: String
        $includeJobs: Boolean!
        $includeEmployees: Boolean!
        $includeSubjectGroups: Boolean!
        $includeOrganizations: Boolean!
        $includeTaskStatuses: Boolean!
        $includeTasks: Boolean!
        $includeTaskCollections: Boolean!
      ) {
        jobs(
          first: 10
          after: $jobsCursor
          name_Icontains: $searchTerm
          orderBy: "name"
        ) @include(if: $includeJobs) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
        employees(
          first: 10
          after: $employeesCursor
          search: $searchTerm
          orderBy: "gaia_user__full_name"
        ) @include(if: $includeEmployees) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                id
                firstName
                lastName
              }
            }
          }
        }
        subjectGroups(
          first: 10
          after: $subjectGroupsCursor
          subjectGroupOrganizationName: $searchTerm
          orderBy: "name"
        ) @include(if: $includeSubjectGroups) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
        taskStatuses(
          first: 10
          after: $taskStatusesCursor
          name_Icontains: $searchTerm
          orderBy: "name"
        ) @include(if: $includeTaskStatuses) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
        organizations(
          first: 10
          after: $organizationsCursor
          name_Icontains: $searchTerm
          orderBy: "name"
        ) @include(if: $includeOrganizations) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
        tasks(
          first: 10
          after: $tasksCursor
          search: $searchTerm
          orderBy: "description"
        ) @include(if: $includeTasks) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              description
              id
              region {
                timezone
                name
              }
              job {
                name
                startDateTime
                endDateTime
              }
              organization {
                name
              }
              subjectGroup {
                name
              }
              dueDate
              recordId
            }
          }
        }
        taskCollections(
          first: 10
          after: $taskCollectionsCursor
          name_Icontains: $searchTerm
          orderBy: "name"
        ) @include(if: $includeTaskCollections) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (data) {
      handleQueryResults(data)
    }
  }, [data])

  useEffect(() => {
    if (selectedRecordType === 'Task') {
      return
    }
    setEmployeesCursor(null)
    setJobsCursor(null)
    setSubjectGroupsCursor(null)
    setOrganizationsCursor(null)
    setTaskCollectionsCursor(null)
    setSearchFilters([])
    if (selectedRecordType && searchTerm.length > 0) {
      filtersQuery({
        variables: {
          includeJobs: selectedRecordType === 'Job',
          includeEmployees: ['Assigned To', 'Completed By'].includes(
            selectedRecordType
          ),
          includeSubjectGroups: selectedRecordType === 'Subject Group',
          includeOrganizations: selectedRecordType === 'Organization',
          includeTaskCollections: selectedRecordType === 'Task Collection',
          includeTaskStatuses: selectedRecordType === 'Task Status',
          includeTasks: selectedRecordType === 'Task',
          searchTerm,
          jobsCursor: null,
          organizationsCursor: null,
          employeesCursor: null,
          subjectGroupsCursor: null,
          taskCollectionsCursor: null,
        },
      })
    }
  }, [searchTerm, selectedRecordType])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setEmployeesCursor()
    setJobsCursor()
    setSubjectGroupsCursor()
    setOrganizationsCursor()
    setTaskCollectionsCursor()
    if (selectedRecordType !== 'Task') {
      setDisplaySearchResults(true)
      setSearchTerm(currentSearchTerm)
    } else {
      setSearch(currentSearchTerm)
    }
  }

  const fetchMoreSearchTerms = () => {
    fetchMore({
      variables: {
        includeJobs: selectedRecordType === 'Job',
        includeEmployees: ['Assigned To', 'Completed By'].includes(
          selectedRecordType
        ),
        includeSubjectGroups: selectedRecordType === 'Subject Group',
        includeOrganizations: selectedRecordType === 'Organization',
        includeTaskCollections: selectedRecordType === 'Task Collection',
        includeTasks: selectedRecordType === 'Task',
        includeTaskStatuses: selectedRecordType === 'Task Status',
        searchTerm,
        jobsCursor,
        employeesCursor,
        subjectGroupsCursor,
        organizationsCursor,
        taskCollectionsCursor,
        tasksCursor,
        taskStatusesCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        handleQueryResults(fetchMoreResult, true)
        return fetchMoreResult
      },
    })
  }

  const handleControlBlur = () => {
    setDisplaySearchResults(false)
  }

  const handleQueryResults = (queryResults, fromFetchMore) => {
    if (searchTerm.length === 0) {
      setSearchFilters([])
    } else {
      const currentSearchFilters = []
      const appliedSearchFilterIds = Object.keys(appliedSearchFilters)
      if (
        ['Assigned To', 'Completed By'].includes(selectedRecordType) &&
        queryResults.employees.pageInfo.endCursor
      ) {
        setEmployeesCursor(queryResults.employees.pageInfo.endCursor)
        queryResults.employees.edges.forEach((employee) => {
          const employeeNode = employee.node
          if (!appliedSearchFilterIds.includes(employeeNode.id)) {
            let name = employeeNode.gaiaUser.firstName
            if (employeeNode.gaiaUser.lastName) {
              name = `${name} ${employeeNode.gaiaUser.lastName}`
            }
            currentSearchFilters.push({
              nodeType: selectedRecordType,
              id: employeeNode.id,
              name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Job' &&
        queryResults.jobs.pageInfo.endCursor
      ) {
        setJobsCursor(queryResults.jobs.pageInfo.endCursor)
        queryResults.jobs.edges.forEach((job) => {
          const jobNode = job.node
          if (!appliedSearchFilterIds.includes(jobNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Job',
              id: jobNode.id,
              name: jobNode.name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Subject Group' &&
        queryResults.subjectGroups.pageInfo.endCursor
      ) {
        setSubjectGroupsCursor(queryResults.subjectGroups.pageInfo.endCursor)
        queryResults.subjectGroups.edges.forEach((subjectGroup) => {
          const subjectGroupNode = subjectGroup.node
          if (!appliedSearchFilterIds.includes(subjectGroupNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Subject Group',
              id: subjectGroupNode.id,
              name: subjectGroupNode.name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Task Status' &&
        queryResults.taskStatuses.pageInfo.endCursor
      ) {
        setTaskStatusesCursor(queryResults.taskStatuses.pageInfo.endCursor)
        queryResults.taskStatuses.edges.forEach((taskStatus) => {
          const taskStatusNode = taskStatus.node
          if (!appliedSearchFilterIds.includes(taskStatusNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Task Status',
              id: taskStatusNode.id,
              name: taskStatusNode.name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Organization' &&
        queryResults.organizations.pageInfo.endCursor
      ) {
        setOrganizationsCursor(queryResults.organizations.pageInfo.endCursor)
        queryResults.organizations.edges.forEach((organization) => {
          const organizationNode = organization.node
          if (!appliedSearchFilterIds.includes(organizationNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Organization',
              id: organizationNode.id,
              name: organizationNode.name,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Task' &&
        queryResults.tasks.pageInfo.endCursor
      ) {
        setTasksCursor(queryResults.tasks.pageInfo.endCursor)
        queryResults.tasks.edges.forEach((task) => {
          const taskNode = task.node
          if (!appliedSearchFilterIds.includes(taskNode.id)) {
            const timezone = formatTimezone(taskNode.region?.timezone)
            let name = `${taskNode.description} | ID ${taskNode.recordId}`
            if (taskNode.dueDate) {
              name = `${name} | Due ${moment(taskNode.dueDate).format('MMMM D, YYYY')}`
            }
            if (taskNode.job) {
              name = `${name} | Job ${taskNode.job.name}`
              if (taskNode.job.startDateTime) {
                name = `${name} ${toTimezone(taskNode.job.startDateTime, {
                  humanReadable: true,
                  timezone: timezone,
                })} - ${toTimezone(taskNode.job.endDateTime, {
                  onlyTime: true,
                  timezone: timezone,
                })}`
              }
            }
            if (taskNode.organization) {
              name = `${name} | Organization ${taskNode.organization.name}`
            }
            if (taskNode.subjectGroup) {
              name = `${name} | Subject Group ${taskNode.subjectGroup.name}`
            }
            currentSearchFilters.push({
              nodeType: 'Task',
              id: taskNode.id,
              name,
              label: `${taskNode.description} ID ${taskNode.recordId}`,
            })
          }
        })
      }
      if (
        selectedRecordType === 'Task Collection' &&
        queryResults.taskCollections.pageInfo.endCursor
      ) {
        setTaskCollectionsCursor(
          queryResults.taskCollections.pageInfo.endCursor
        )
        queryResults.taskCollections.edges.forEach((taskCollection) => {
          const taskCollectionNode = taskCollection.node
          if (!appliedSearchFilterIds.includes(taskCollectionNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Task Collection',
              id: taskCollectionNode.id,
              name: taskCollectionNode.name,
            })
          }
        })
      }
      const hasMore =
        (['Assigned To', 'Completed By'].includes(selectedRecordType) &&
          queryResults.employees?.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Job' &&
          queryResults.jobs?.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Subject Group' &&
          queryResults.subjectGroups?.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Organization' &&
          queryResults.organizations?.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Task Collection' &&
          queryResults.taskCollections?.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Task' &&
          queryResults.tasks?.pageInfo.hasNextPage)
      setHasMoreSearchFilters(hasMore)
      setSearchFilters((prevState) => [...prevState, ...currentSearchFilters])
    }
  }

  const handleSearchFilterClick = (searchFilterRow) => {
    const tableRow = document.getElementById(`tableRow${searchFilterRow.id}`)
    const nodeId = searchFilterRow.original.id
    setSearchTerm('')
    if (Object.keys(appliedSearchFilters).includes(nodeId)) {
      tableRow.classList.remove('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        delete prevSearchFilters[nodeId]
        return prevSearchFilters
      })
    } else {
      tableRow.classList.add('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        prevSearchFilters[searchFilterRow.original.id] =
          searchFilterRow.original
        return prevSearchFilters
      })
    }
  }

  const handleRemoveSearchFilterClick = (searchFilter) => {
    setAppliedSearchFilters((prevState) => {
      const prevSearchFilters = { ...prevState }
      delete prevSearchFilters[searchFilter[0]]
      return prevSearchFilters
    })
    if (searchFilters.length > 0) {
      const nextSearchFilters = produce(searchFilters, (draftState) => {
        draftState.push(searchFilter[1])
      })
      setSearchFilters(nextSearchFilters)
    }
  }

  const recordTypeOptions = [
    'Assigned To',
    'Completed By',
    'Job',
    'Organization',
    'Subject Group',
    'Task',
    'Task Collection',
    'Task Status',
  ]
  if (location.pathname.includes('organization')) {
    recordTypeOptions.splice(3, 1)
  } else if (location.pathname.includes('subject-group')) {
    recordTypeOptions.splice(3, 2)
  }
  return (
    <>
      <Row>
        <Col md={5}>
          <select
            className="form-control-sm form-select"
            name="group"
            onChange={(e) => {
              if (!e.target.value) {
                setSearchTerm('')
              }
              setSelectedRecordType(e.target.value || undefined)
            }}
            value={selectedRecordType}
          >
            <option value="">Select Record Type</option>
            {recordTypeOptions.map((recordType) => (
              <option key={recordType} value={recordType}>
                {recordType}
              </option>
            ))}
          </select>
        </Col>
        <Col md={7} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Form.Control
            className="form-control-sm"
            type="text"
            name="searchTerm"
            value={selectedRecordType === 'Task' ? search : searchTerm}
            disabled={!selectedRecordType}
            placeholder="Search Tasks"
            onBlur={handleControlBlur}
            onChange={handleSearchTermChange}
            style={{ width: '100%', boxSizing: 'border-box' }}
          />
          {loading && (
            <div id="taskSearchTable" className="border">
              <Loading height={'25'} width={'25'} />
            </div>
          )}
        </Col>
        {props.dateFilters}
      </Row>
      {displaySearchResults && searchFilters.length > 0 && (
        <Row style={{ position: 'relative', marginTop: '0px' }}>
          <Col
            md={{ span: 7, offset: 5 }}
            style={{
              position: 'absolute',
              width: '100%',
              top: 0,
              zIndex: 1050,
              paddingLeft: 0,
              paddingRight: 0,
              backgroundColor: 'white',
            }}
          >
            <SortableInfiniteTable
              hideGlobalFilter
              displayHeader={false}
              infiniteTableId="jobSearchFilterInfiniteTable"
              tableHeight={searchFilters.length > 5 ? 200 : 100}
              tableData={searchFilters}
              trStyle={{
                fontSize: '.8rem',
                cursor: 'pointer',
              }}
              tableColumns={[
                {
                  Header: '',
                  id: 'name',
                  accessor: 'name',
                },
              ]}
              fetchMoreTableData={fetchMoreSearchTerms}
              hasMoreTableData={hasMoreSearchFilters}
              onRowClick={handleSearchFilterClick}
              rowPointer
              tableSize="lg"
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12}>
          {Object.keys(appliedSearchFilters).length > 0 || search ? (
            <div className="mt-3 gap-2">
              {search && (
                <span
                  style={{
                    fontSize: '14px',
                    border: '0.3px solid',
                    padding: '2px',
                    margin: '5px',
                    borderRadius: '0.25rem',
                    marginLeft: '0.5rem',
                  }}
                >
                  <Trash
                    className="mr-2 btn-link"
                    onClick={() => setSearch('')}
                  />
                  <span style={{ fontSize: '14px' }}>{search}</span>
                  <span style={{ fontSize: '14px' }} className="ml-1">
                    | Task
                  </span>
                </span>
              )}
              {Object.entries(appliedSearchFilters).map(
                (appliedSearchFilter, index) => {
                  let name
                  if (appliedSearchFilter[1].label) {
                    name = appliedSearchFilter[1].label
                  } else {
                    name = appliedSearchFilter[1].name
                  }
                  return (
                    <span
                      style={{
                        fontSize: '14px',
                        border: '0.3px solid',
                        padding: '2px',
                        margin: '5px',
                        borderRadius: '0.25rem',
                        marginLeft: index > 0 ? '0.5rem' : '0',
                      }}
                      key={index}
                    >
                      <Trash
                        className="mr-2 btn-link"
                        onClick={() =>
                          handleRemoveSearchFilterClick(appliedSearchFilter)
                        }
                      />
                      <span style={{ fontSize: '14px' }}>{`${name}`}</span>
                      <span style={{ fontSize: '14px' }} className="ml-1">
                        | {appliedSearchFilter[1].nodeType}
                      </span>
                    </span>
                  )
                }
              )}
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  )
}

export default TaskSearchFilter
