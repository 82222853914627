import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Trash } from 'react-bootstrap-icons'
import Loading from '../common/Loading'

const DeleteOrganizationRegionModal = (props) => {
  const { showModal, toggleModal, id, ids, setCheckedIds } = props
  const [submitting, setSubmitting] = useState(false)

  let _ids = [id]
  if (ids) {
    _ids = ids
  }

  const [deleteRegion] = useMutation(
    gql`
      mutation DeleteOrganizationRegion(
        $input: DeleteOrganizationRegionInput!
      ) {
        deleteOrganizationRegion(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)

        if (ids) {
          toast.success('Organization Regions Deleted')
        } else {
          toast.success('Organization Region Deleted')
        }
        if (setCheckedIds) {
          setCheckedIds([])
        }
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['OrganizationRegions'],
    }
  )

  const handleDeleteClick = () => {
    setSubmitting(true)

    deleteRegion({
      variables: {
        input: {
          organizationRegionIds: _ids,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="DeleteOrganizationRegionModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Organization Region{_ids && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p style={{ textAlign: 'center', fontSize: '18px' }}>
                  Are you sure you want to delete the organization region
                  {ids && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  disabled={submitting}
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
            {submitting && (
              <div className="mt-3">
                <Loading />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteOrganizationRegionModal
