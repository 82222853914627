import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import {
  useMutation,
  gql,
  useApolloClient,
  useReactiveVar,
  useQuery,
} from '@apollo/client'
import {
  ExclamationCircle,
  Plus,
  ReceiptCutoff,
  PlusCircle,
} from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import Sessions from '../sessions/Sessions'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import ProductCard from './product/ProductCard'
import validator from 'validator'
import { useLocation } from 'react-router-dom'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { formatRegion } from '../../libs/utils'

const SendInvoiceModal = (props) => {
  const {
    showModal,
    toggleModal,
    sessionIds,
    organizationId,
    recipient,
    gaiaUserId,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [loading, setLoading] = useState(false)
  const [pending, setPending] = useState(false)
  const apolloClient = useApolloClient()
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
      invoiceFooter: '',
      products: [],
      daysUntilDue: 120,
      price: null,
      regionId: '',
    },
    onSubmit: (values) => {
      if (!sessionIds && values.products.length === 0) {
        toast.error('Product Required')
      } else {
        setLoading(true)
        const productCount = values.products.reduce((acc, currentProduct) => {
          return acc + currentProduct.quantity
        }, 0)
        if (
          (sessionIds && sessionIds.length + productCount >= 10) ||
          productCount >= 10
        ) {
          setPending(true)
        }
        sendInvoice({
          variables: {
            createStripeInvoiceInput: {
              stripeInvoiceInput: {
                sessionIds: sessionIds,
                organizationId,
                gaiaUserId,
                regionId: values.regionId,
                invoiceFooter: values.invoiceFooter,
                daysUntilDue: values.daysUntilDue,
                products: values.products.map((product) => ({
                  productId: product.productId,
                  price: product.price,
                  quantity: product.quantity,
                  chargeSalesTax: product.chargeSalesTax,
                  salesTaxRate: product.salesTaxRate,
                })),
              },
            },
          },
        })
      }
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      invoiceFooter: Yup.string().nullable(),
      products: Yup.array().of(
        Yup.object().shape({
          productName: Yup.string(),
          productId: Yup.string()
            .nullable()
            .test('productRequired', 'required', (value) => {
              let valid = true
              if (!sessionIds && !value) {
                valid = false
              }
              return valid
            }),
          price: Yup.number()
            .nullable()
            .min(1, 'must be greater than 0')
            .test('priceRequired', 'required', (value) => {
              let valid = true
              if (!sessionIds && !value) {
                valid = false
              }
              return valid
            }),
          chargeSalesTax: Yup.bool().nullable(),
          salesTaxRate: Yup.number()
            .nullable()
            .test('required', 'required', (value, context) => {
              let valid = true
              if (context.parent.chargeSalesTax && !value) {
                valid = false
              }
              return valid
            }),
          quantity: Yup.number()
            .nullable()
            .min(1, 'must be greater than 0')
            .test(
              'is-integer',
              'Must be a whole number',
              (value) => value == null || Number.isInteger(value)
            )
            .test('priceRequired', 'required', (value) => {
              let valid = true
              if (!sessionIds && !value) {
                valid = false
              }
              return valid
            }),
        })
      ),
      daysUntilDue: Yup.number()
        .required('required')
        .min(1, 'Must be greater than 0')
        .max(120, 'Must be less than 121'),
      regionId: Yup.string().test('regionRequired', 'required', (value) => {
        let valid = true
        if (
          loggedInUser?.canManageRegions &&
          settings.tenantRegions &&
          organizationId &&
          !value
        ) {
          valid = false
        }
        return valid
      }),
    }),
  })

  const [sendInvoice] = useMutation(
    gql`
      mutation CreateStripeInvoice(
        $createStripeInvoiceInput: CreateStripeInvoiceInput!
      ) {
        createStripeInvoice(input: $createStripeInvoiceInput) {
          created
        }
      }
    `,
    {
      onCompleted: () => {
        if (pending) {
          setPending(false)
          toast.success('Invoice Sent')
        } else {
          toast.success('Invoice Sent')
        }
        innerToggle()
      },
      onError: () => {
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: ['StripeInvoices', 'SessionsQuery'],
    }
  )

  const { data: organizationRegionsQueryData } = useQuery(
    gql`
      query OrganizationRegions($organizationId: ID) {
        organizationRegions(
          first: 50
          orderBy: "region__name"
          organization: $organizationId
        ) {
          edges {
            node {
              default
              region {
                id
                name
                timezone
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: { organizationId },
      onCompleted: (data) => {
        if (data?.organizationRegions?.edges?.length > 0) {
          const defaultRegion = data.organizationRegions.edges.find(
            ({ node }) => node?.default
          )
          if (defaultRegion?.node?.region?.id) {
            formik.setFieldValue('regionId', defaultRegion.node.region.id)
          }
        }
      },
    }
  )

  const innerToggle = () => {
    if (location.pathname.includes('organization') && sessionIds) {
      apolloClient.refetchQueries({
        include: ['SessionsQuery'],
      })
    }
    setLoading(false)
    formik.resetForm()
    toggleModal()
  }

  useEffect(() => {
    if (
      showModal &&
      organizationRegionsQueryData?.organizationRegions?.edges.length > 0
    ) {
      const defaultRegion =
        organizationRegionsQueryData.organizationRegions.edges.find(
          ({ node }) => node?.default
        )
      if (defaultRegion?.node?.region?.id) {
        formik.setFieldValue('regionId', defaultRegion.node.region.id)
      }
    }
  }, [showModal])

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size={
            location.pathname.includes('organization') && sessionIds ? '' : 'xl'
          }
          show={showModal}
          onHide={innerToggle}
          aria-labelledby="newResitReason"
          className={
            location.pathname.includes('organization') && sessionIds
              ? 'invmodal detail full-screen-modal'
              : 'invmodal detail'
          }
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              <ReceiptCutoff className="mr-2" />
              New Invoice
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!recipient && !gaiaUserId && (
              <Row>
                <Col>
                  <div className="text-danger">
                    <ExclamationCircle className="mr-2" />
                    Add Invoice Email Recipient To The Organization To Send
                    Invoices
                  </div>
                </Col>
              </Row>
            )}
            {recipient && !validator.isEmail(recipient) && !gaiaUserId && (
              <Row>
                <Col>
                  <div className="text-danger">
                    <ExclamationCircle className="mr-2" />
                    Invoice email recipient {recipient} is not a valid email
                  </div>
                </Col>
              </Row>
            )}
            {(recipient && !validator.isEmail(recipient) && gaiaUserId) ||
              (!recipient && gaiaUserId && (
                <Row>
                  <Col>
                    <div className="text-danger">
                      <ExclamationCircle className="mr-2" />
                      Subject requires valid email to receive invoices
                    </div>
                  </Col>
                </Row>
              ))}
            {recipient && validator.isEmail(recipient) && (
              <>
                <Form onSubmit={formik.handleSubmit}>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Label>Stripe Invoice Email Recipient</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control-sm"
                        disabled={true}
                        value={recipient}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Days Until Due</Form.Label>
                      <Form.Control
                        type="number"
                        name="daysUntilDue"
                        className="form-control-sm"
                        value={formik.values.daysUntilDue}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.daysUntilDue}
                      />
                      {formik.errors.daysUntilDue && (
                        <small className="text-danger">
                          {formik.errors.daysUntilDue}
                        </small>
                      )}
                    </Col>
                  </Row>
                  {loggedInUser?.canManageRegions &&
                    settings?.tenantRegions &&
                    organizationId && (
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Label>Region</Form.Label>
                          <select
                            className="form-select form-control-sm form-control"
                            name={'regionId'}
                            value={formik.values.regionId}
                            onChange={(e) => {
                              formik.setFieldValue('regionId', e.target.value)
                            }}
                          >
                            {organizationRegionsQueryData?.organizationRegions?.edges.map(
                              ({ node }) => (
                                <option
                                  key={node.region?.id}
                                  value={node.region?.id}
                                >
                                  {formatRegion(node.region)}
                                </option>
                              )
                            )}
                          </select>
                          {formik.errors.regionId && (
                            <small className="text-danger">
                              {formik.errors.regionId}
                            </small>
                          )}
                        </Col>
                      </Row>
                    )}
                  <div
                    className={
                      formik.values.products.length > 0
                        ? 'bg-light border p-4'
                        : 'd-none'
                    }
                    style={{
                      maxHeight: '700px',
                      overflowY: 'auto',
                    }}
                  >
                    {formik.values.products.map((product, index) => (
                      <ProductCard key={index} formik={formik} index={index} />
                    ))}
                  </div>
                  <Row>
                    <Col>
                      <Button
                        className={
                          formik.values.products.length > 0
                            ? ' mt-3 ps-0'
                            : 'ps-0 mt-0'
                        }
                        variant="link"
                        size="sm"
                        onClick={() => {
                          formik.setFieldValue('products', [
                            ...formik.values.products,
                            {
                              productName: null,
                              productId: null,
                              price: 1,
                              quantity: 1,
                              salesTaxRate: 0,
                              chargeSalesTax: false,
                            },
                          ])
                        }}
                      >
                        <Plus /> Add Product
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Label>Invoice Footer</Form.Label>
                      <Form.Control
                        style={{ height: '100px' }}
                        as="textarea"
                        name="invoiceFooter"
                        value={formik.values.invoiceFooter}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.invoiceFooter}
                      />
                      {formik.errors.invoiceFooter && (
                        <small className="text-danger">
                          {formik.errors.invoiceFooter}
                        </small>
                      )}
                    </Col>
                  </Row>
                  {sessionIds && (
                    <Sessions
                      sessionIds={sessionIds}
                      hideOrganization
                      showSessions={true}
                      sendInvoiceModal
                    />
                  )}
                  <Row>
                    <Col xs={4} sm={2}>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        block
                        disabled={loading}
                        type="submit"
                      >
                        <PlusCircle className="mr-2" />
                        Send Invoice
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}

            {loading && <Loading message="Sending Invoice..." />}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SendInvoiceModal
