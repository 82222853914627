import React from 'react'
import { Row, Col } from 'react-bootstrap'
import EmailLogin from './EmailLogin'
import CreateAccount from './CreateAccount'

export default function CreateLogin(props) {
  const { handleNextStage, jobId } = props

  const handlePostLogin = (user, subject) => {
    handleNextStage('createLogin')
  }

  return (
    <>
      <Row>
        <Col md="12">
          <p className="font-weight-bold">Email Login</p>
          <div className="border p-3">
            <EmailLogin colMd={6} handlePostLogin={handlePostLogin} />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="12">
          <p className="font-weight-bold">Create Account</p>
          <div className="border p-3">
            <CreateAccount handleNextStage={handleNextStage} jobId={jobId} />
          </div>
        </Col>
      </Row>
    </>
  )
}
