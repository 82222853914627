import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { PlusCircle, Trash } from 'react-bootstrap-icons'
import RepairModal from './RepairModal'
import DeleteRepairModal from './DeleteRepairModal'
import { useDateTimeConverter } from '../../../libs/useDateTime'

const Repairs = (props) => {
  const {
    itemId,
    showRepairTableOnlyforItemTable,
    prefilledRepairDetail,
    tableHeight,
  } = props
  const { toTimezone } = useDateTimeConverter()
  const [checkedIds, setCheckedIds] = useState([])
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [editRepair, setEditRepair] = useState()
  const defaultOrderBy = 'created'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query EquipmentRepairs(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $equipmentItemId: ID
      ) {
        equipmentRepairs(
          first: 100
          after: $cursor
          equipmentItem_Name_Icontains: $searchTerm
          equipmentItem: $equipmentItemId
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              problem
              notes
              contentType {
                model
                id
              }
              dateFixed
              vendor
              price
              invoiceNumber
              created
              problem
              equipmentItem {
                id
                name
                serialNumber
                notes
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  useEffect(() => {
    let variables = {
      orderBy: defaultOrderBy,
    }
    if (!initialQueryRun) {
      if (itemId) {
        variables.equipmentItemId = itemId
      }
      setInitialQueryRun(true)
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.equipmentRepairs?.edges) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.equipmentRepairs?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
    })
  }

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = {
        equipmentItemId: itemId,
      }
      variables.searchTerm = searchValue || undefined
      if (orderBy) {
        variables.orderBy = orderBy
      }
      setLoadingSearch(true)
      query({ variables })
    },
    [itemId, orderBy, query]
  )

  const handleSearchTermChange = useCallback(
    (event) => {
      const currentSearchTerm = event.target.value || undefined
      setSearchTerm(currentSearchTerm)

      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      const newTimeout = setTimeout(() => {
        executeSearchQuery(currentSearchTerm)
      }, 500)

      setDebounceTimeout(newTimeout)
    },
    [executeSearchQuery, debounceTimeout]
  )

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return
    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  const onCellClick = (row) => {
    toggleModal(row.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditRepair(node)
    } else {
      setEditRepair()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <React.Fragment>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="searchTerm"
                  className="form-control-sm"
                  placeholder={'Search Repairs'}
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    toggleModal()
                  }}
                >
                  <PlusCircle className="mr-2" />
                  New Repair
                </Button>
                {checkedIds.length > 0 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowDeleteModal(true)
                    }}
                  >
                    <Trash className="mr-2" />
                    {checkedIds.length === 1 ? (
                      <>Delete Repair</>
                    ) : (
                      <>Delete Repairs</>
                    )}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </React.Fragment>
        <Row className="mt-2 mb-3">
          <Col md={12}>
            <SortableInfiniteTable
              tableData={
                queryData?.equipmentRepairs?.edges
                  ? queryData.equipmentRepairs.edges
                  : []
              }
              loading={loadingSearch || !queryData}
              loadingMessage="Loading Repairs..."
              tableColumns={[
                {
                  Header: 'Item',
                  id: 'item',
                  accessor: (row) => {
                    if (row.node.equipmentItem.serialNumber) {
                      return `${row.node.equipmentItem.name} - ${row.node.equipmentItem.serialNumber}`
                    } else {
                      return row.node.equipmentItem.name
                    }
                  },
                  serverSort: true,
                  orderBy: 'equipment_item',
                },
                {
                  Header: 'Created',
                  id: 'created',
                  accessor: (row) => {
                    return toTimezone(row.node.created, {
                      onlyDate: true,
                      settingTimezone: true,
                    })
                  },
                  serverSort: true,
                },
                {
                  Header: 'Fixed',
                  id: 'fixed',
                  accessor: (row) => {
                    if (row.node.dateFixed) {
                      return toTimezone(row.node.dateFixed, {
                        onlyDate: true,
                        settingTimezone: true,
                      })
                    }
                  },
                  serverSort: true,
                },
                {
                  Header: 'Problem',
                  id: 'problem',
                  accessor: (row) => {
                    if (row.node.problem) {
                      return row.node.problem
                    }
                  },
                },
                {
                  disableSortBy: true,
                  Header: (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const appendIds = []
                              queryData.equipmentRepairs.edges.forEach(
                                (repair) => {
                                  if (!checkedIds.includes(repair.node.id)) {
                                    appendIds.push(repair.node.id)
                                  }
                                }
                              )
                              setCheckedIds((prevState) => {
                                return [...prevState, ...appendIds]
                              })
                            } else {
                              setCheckedIds([])
                            }
                          }}
                        />
                        {checkedIds.length > 0 && (
                          <span style={{ fontSize: '14px', marginTop: '5px' }}>
                            ({checkedIds.length})
                          </span>
                        )}
                      </Form.Group>
                    </>
                  ),
                  id: 'actions',
                  accessor: (row) => {
                    return (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            checked={checkedIds.includes(row.node.id)}
                            onChange={(e) => handleCheck(e, row)}
                          />
                        </Form.Group>
                      </>
                    )
                  },
                },
              ]}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={
                queryData?.equipmentRepairs?.pageInfo?.hasNextPage
              }
              onTdClicks={{
                item: (cell) => onCellClick(cell),
                problem: (cell) => onCellClick(cell),
                fixed: (cell) => onCellClick(cell),
                created: (cell) => onCellClick(cell),
              }}
              tableHeight={tableHeight ? tableHeight : 400}
              rowPointer
              hideGlobalFilter
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
        {showForm ? (
          <RepairModal
            showModal={showForm}
            prefilledItemDetailsByItemForm={showRepairTableOnlyforItemTable}
            prefilledDetail={prefilledRepairDetail}
            repair={editRepair}
            toggleModal={toggleModal}
          />
        ) : null}
        <DeleteRepairModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedIds={setCheckedIds}
          ids={checkedIds.length > 1 ? checkedIds : null}
          id={checkedIds.length === 1 ? checkedIds[0] : null}
        />
      </div>
    </>
  )
}
export default Repairs
