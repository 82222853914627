import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { BarChartLine, Trash } from 'react-bootstrap-icons'
import produce from 'immer'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import './NotificationsTableSearch.css'
import DateFilter from '../common/DateFilter'
import { useParams } from 'react-router-dom'
import Loading from '../common/Loading'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { useDateTimeConverter } from '../../libs/useDateTime'
import { formatTimezone } from '../../libs/utils'

const NotificationsTableSearch = (props) => {
  const {
    appliedSearchFilters,
    setAppliedSearchFilters,
    searchText,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    setShowNotificationChart,
  } = props
  const { toTimezone } = useDateTimeConverter()
  const { contact } = useParams()
  const user = useReactiveVar(loggedInUserVar)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRecordType, setSelectedRecordType] = useState()
  const [searchFilters, setSearchFilters] = useState([])
  const [hasMoreSearchFilters, setHasMoreSearchFilters] = useState(true)
  const [jobsCursor, setJobsCursor] = useState()
  const [employeesCursor, setEmployeesCursor] = useState()
  const [subjectGroupsCursor, setSubjectGroupsCursor] = useState()
  const [organizationsCursor, setOrganizationsCursor] = useState()
  const [subjectsCursor, setSubjectsCursor] = useState()
  const [tasksCursor, setTasksCursor] = useState()
  const [equipmentBagsCursor, setEquipmentBagsCursor] = useState()
  const [equipmentItemsCursor, setEquipmentItemsCursor] = useState()

  const CONTACT_QUERY = gql`
    query NotificationsJobsQueries(
      $jobsCursor: String
      $subjectGroupsCursor: String
      $organizationsCursor: String
      $subjectsCursor: String
      $tasksCursor: String
      $searchTerm: String
      $includeJobs: Boolean!
      $includeSubjects: Boolean!
      $includeSubjectGroups: Boolean!
      $includeOrganizations: Boolean!
      $includeTasks: Boolean!
    ) {
      jobs(
        first: 10
        after: $jobsCursor
        name_Icontains: $searchTerm
        contact: true
      ) @include(if: $includeJobs) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
          }
        }
      }
      subjects(
        first: 10
        after: $subjectsCursor
        search: $searchTerm
        contact: true
      ) @include(if: $includeSubjects) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      subjectGroups(
        first: 10
        after: $subjectGroupsCursor
        subjectGroupOrganizationName: $searchTerm
        contact: true
      ) @include(if: $includeSubjectGroups) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
          }
        }
      }
      organizations(
        first: 10
        after: $organizationsCursor
        name_Icontains: $searchTerm
        contact: true
      ) @include(if: $includeOrganizations) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
          }
        }
      }
      tasks(first: 10, after: $tasksCursor, search: $searchTerm)
        @include(if: $includeTasks) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            description
            id
            job {
              name
              startDateTime
              endDateTime
            }
            organization {
              name
            }
            subjectGroup {
              name
            }
            dueDate
            recordId
            region {
              timezone
            }
          }
        }
      }
    }
  `

  const NON_CONTACT_QUERY = gql`
    query NotificationsJobsQueries(
      $jobsCursor: String
      $employeesCursor: String
      $subjectGroupsCursor: String
      $organizationsCursor: String
      $subjectsCursor: String
      $tasksCursor: String
      $equipmentBagsCursor: String
      $equipmentItemsCursor: String
      $searchTerm: String
      $includeJobs: Boolean!
      $includeEmployees: Boolean!
      $includeSubjects: Boolean!
      $includeSubjectGroups: Boolean!
      $includeOrganizations: Boolean!
      $includeTasks: Boolean!
      $includeEquipmentBags: Boolean!
      $includeEquipmentItems: Boolean!
    ) {
      jobs(first: 10, after: $jobsCursor, name_Icontains: $searchTerm)
        @include(if: $includeJobs) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
          }
        }
      }
      equipmentBags(
        first: 10
        after: $equipmentBagsCursor
        search: $searchTerm
      ) @include(if: $includeEquipmentBags) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
          }
        }
      }
      equipmentItems(
        first: 10
        after: $equipmentItemsCursor
        search: $searchTerm
      ) @include(if: $includeEquipmentItems) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
          }
        }
      }
      employees(first: 10, after: $employeesCursor, search: $searchTerm)
        @include(if: $includeEmployees) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      subjects(first: 10, after: $subjectsCursor, search: $searchTerm)
        @include(if: $includeSubjects) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      subjectGroups(
        first: 10
        after: $subjectGroupsCursor
        subjectGroupOrganizationName: $searchTerm
      ) @include(if: $includeSubjectGroups) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
          }
        }
      }
      organizations(
        first: 10
        after: $organizationsCursor
        name_Icontains: $searchTerm
      ) @include(if: $includeOrganizations) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
          }
        }
      }
      tasks(first: 10, after: $tasksCursor, search: $searchTerm)
        @include(if: $includeTasks) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            description
            region {
              timezone
              name
            }
            organization {
              name
            }
            job {
              name
              startDateTime
              endDateTime
            }
          }
        }
      }
    }
  `

  const [
    jobFiltersQuery,
    { data: jobFiltersData, loading, fetchMore: jobFiltersFetchMore },
  ] = useLazyQuery(contact ? CONTACT_QUERY : NON_CONTACT_QUERY, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (jobFiltersData) {
      handleQueryResults(jobFiltersData)
    }
  }, [jobFiltersData])

  useEffect(() => {
    setSearchFilters([])
    setEmployeesCursor()
    setJobsCursor()
    setSubjectGroupsCursor()
    setSubjectsCursor()
    setOrganizationsCursor()
    setTasksCursor()
    setEquipmentBagsCursor()
    setEquipmentItemsCursor()
    if (searchTerm.length > 0 && selectedRecordType) {
      const variables = {
        searchTerm,
        jobsCursor,
        organizationsCursor,
        subjectsCursor,
        subjectGroupsCursor,
        tasksCursor,
        includeJobs: selectedRecordType === 'Job',
        includeEmployees: selectedRecordType === 'Employee',
        includeSubjectGroups: selectedRecordType === 'Subject Group',
        includeSubjects: selectedRecordType === 'Subject',
        includeOrganizations: selectedRecordType === 'Organization',
        includeTasks: selectedRecordType === 'Task',
        includeEquipmentBags: selectedRecordType === 'Equipment Bag',
        includeEquipmentItems: selectedRecordType === 'Equipment Item',
      }
      if (!contact) {
        variables.employeesCursor = employeesCursor
      }
      jobFiltersQuery({
        variables,
      })
    }
  }, [searchTerm, selectedRecordType])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setEmployeesCursor()
    setJobsCursor()
    setSubjectGroupsCursor()
    setSubjectsCursor()
    setOrganizationsCursor()
    setTasksCursor()
    setEquipmentBagsCursor()
    setEquipmentItemsCursor()
    setSearchTerm(currentSearchTerm)
  }

  const fetchMoreSearchTerms = () => {
    const variables = {
      jobsCursor,
      organizationsCursor,
      subjectsCursor,
      subjectGroupsCursor,
      tasksCursor,
      includeJobs: selectedRecordType === 'Job',
      includeSubjectGroups: selectedRecordType === 'Subject Group',
      includeSubjects: selectedRecordType === 'Subject',
      includeOrganizations: selectedRecordType === 'Organization',
      includeTasks: selectedRecordType === 'Task',
    }
    if (!contact) {
      variables.includeEmployees = selectedRecordType === 'Employee'
      variables.includeEquipmentBags = selectedRecordType === 'Equipment Bag'
      variables.includeEquipmentItems = selectedRecordType === 'Equipment Item'
      variables.equipmentBagsCursor = equipmentBagsCursor
      variables.equipmentItemsCursor = equipmentItemsCursor
      variables.employeesCursor = employeesCursor
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    jobFiltersFetchMore({
      variables,
    })
  }

  const handleQueryResults = (queryResults, fromFetchMore) => {
    if (searchTerm.length === 0) {
      setSearchFilters([])
    } else {
      if (!contact && queryResults.employees?.pageInfo.endCursor) {
        setEmployeesCursor(queryResults.employees.pageInfo.endCursor)
      }
      if (queryResults.jobs?.pageInfo.endCursor) {
        setJobsCursor(queryResults.jobs.pageInfo.endCursor)
      }
      if (queryResults.subjectGroups?.pageInfo.endCursor) {
        setSubjectGroupsCursor(queryResults.subjectGroups.pageInfo.endCursor)
      }
      if (queryResults.equipmentBags?.pageInfo.endCursor) {
        setEquipmentBagsCursor(queryResults.equipmentBags.pageInfo.endCursor)
      }
      if (queryResults.equipmentItems?.pageInfo.endCursor) {
        setEquipmentItemsCursor(queryResults.equipmentItems.pageInfo.endCursor)
      }
      if (queryResults.organizations?.pageInfo.endCursor) {
        setOrganizationsCursor(queryResults.organizations.pageInfo.endCursor)
      }
      if (queryResults.subjects?.pageInfo.endCursor) {
        setSubjectsCursor(queryResults.subjects.pageInfo.endCursor)
      }
      if (queryResults.tasks?.pageInfo.endCursor) {
        setTasksCursor(queryResults.tasks.pageInfo.endCursor)
      }
      if (
        (!contact && queryResults.employees?.pageInfo.hasNextPage) ||
        (!contact && queryResults.equipmentBags?.pageInfo.hasNextPage) ||
        (!contact && queryResults.equipmentItems?.pageInfo.hasNextPage) ||
        queryResults.jobs?.pageInfo.hasNextPage ||
        queryResults.subjectGroups?.pageInfo.hasNextPage ||
        queryResults.organizations?.pageInfo.hasNextPage ||
        queryResults.subjects?.pageInfo.hasNextPage ||
        queryResults.tasks?.pageInfo.hasNextPage
      ) {
        setHasMoreSearchFilters(true)
      } else {
        setHasMoreSearchFilters(false)
      }
      const currentSearchFilters = []
      const appliedSearchFilterIds = Object.keys(appliedSearchFilters)
      queryResults.subjects?.edges.forEach((subject) => {
        const subjectNode = subject.node
        if (!appliedSearchFilterIds.includes(subjectNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Subject',
            id: subjectNode.id,
            name: `${subjectNode.gaiaUser.firstName} ${subjectNode.gaiaUser.lastName}`,
          })
        }
      })
      if (!contact) {
        queryResults.employees?.edges.forEach((employee) => {
          const employeeNode = employee.node
          if (!appliedSearchFilterIds.includes(employeeNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Employee',
              id: employeeNode.id,
              name: `${employeeNode.gaiaUser.firstName} ${employeeNode.gaiaUser.lastName}`,
            })
          }
        })
        queryResults.equipmentBags?.edges.forEach((equipmentBag) => {
          const equipmentBagNode = equipmentBag.node
          if (!appliedSearchFilterIds.includes(equipmentBagNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Equipment Bag',
              id: equipmentBagNode.id,
              name: equipmentBagNode.name,
            })
          }
        })
        queryResults.equipmentItems?.edges.forEach((equipmentItem) => {
          const equipmentItemNode = equipmentItem.node
          if (!appliedSearchFilterIds.includes(equipmentItemNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Equipment Item',
              id: equipmentItemNode.id,
              name: equipmentItemNode.name,
            })
          }
        })
      }
      queryResults.jobs?.edges.forEach((job) => {
        const jobNode = job.node
        if (!appliedSearchFilterIds.includes(jobNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Job',
            id: jobNode.id,
            name: jobNode.name,
          })
        }
      })
      queryResults.subjectGroups?.edges.forEach((subjectGroup) => {
        const subjectGroupNode = subjectGroup.node
        if (!appliedSearchFilterIds.includes(subjectGroupNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Subject Group',
            id: subjectGroupNode.id,
            name: subjectGroupNode.name,
          })
        }
      })
      queryResults.organizations?.edges.forEach((organization) => {
        const organizationNode = organization.node
        if (!appliedSearchFilterIds.includes(organizationNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Organization',
            id: organizationNode.id,
            name: organizationNode.name,
          })
        }
      })
      queryResults.tasks?.edges.forEach((task) => {
        const taskNode = task.node
        if (!appliedSearchFilterIds.includes(taskNode.id)) {
          let name = `${taskNode.description} | ID ${taskNode.recordId}`
          const timezone = formatTimezone(taskNode.region?.timezone)
          if (taskNode.dueDate) {
            name = `${name} | Due ${toTimezone(taskNode.dueDate, {
              timezone: timezone,
            })}`
          }
          if (taskNode.job) {
            name = `${name} | ${taskNode.job.name} ${toTimezone(
              taskNode.job.startDateTime,
              { timezone: timezone, humanReadable: true }
            )} - ${toTimezone(taskNode.job.endDateTime, {
              onlyTime: true,
              timezone: timezone,
            })}`
          }
          if (taskNode.organization) {
            name = `${name} | Organization ${taskNode.organization.name}`
          }
          if (taskNode.subjectGroup) {
            name = `${name} | Subject Group ${taskNode.subjectGroup.name}`
          }
          currentSearchFilters.push({
            nodeType: 'Task',
            id: taskNode.id,
            name,
          })
        }
      })
      setSearchFilters((prevState) => [...prevState, ...currentSearchFilters])
    }
  }

  const handleSearchFilterClick = (searchFilterRow) => {
    const tableRow = document.getElementById(`tableRow${searchFilterRow.id}`)
    const nodeId = searchFilterRow.original.id
    if (Object.keys(appliedSearchFilters).includes(nodeId)) {
      tableRow.classList.remove('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        delete prevSearchFilters[nodeId]
        return prevSearchFilters
      })
    } else {
      tableRow.classList.add('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        prevSearchFilters[searchFilterRow.original.id] =
          searchFilterRow.original
        return prevSearchFilters
      })
    }
  }

  const handleRemoveSearchFilterClick = (searchFilter) => {
    setAppliedSearchFilters((prevState) => {
      const prevSearchFilters = { ...prevState }
      delete prevSearchFilters[searchFilter[0]]
      return prevSearchFilters
    })
    if (searchFilters.length > 0) {
      const nextSearchFilters = produce(searchFilters, (draftState) => {
        draftState.push(searchFilter[1])
      })
      setSearchFilters(nextSearchFilters)
    }
  }

  const handleControlBlur = () => {
    setSearchTerm('')
  }

  const recordTypeOptions = [
    'Job',
    'Employee',
    'Equipment Bag',
    'Equipment Item',
    'Subject',
    'Subject Group',
    'Organization',
    'Task',
  ]
  if (contact) {
    recordTypeOptions.splice(1, 3)
  }
  return (
    <>
      <div>
        {user.permissions.isEmployee && (
          <Row>
            <Col>
              <Button
                className="m-0"
                variant="link"
                onClick={() => {
                  setShowNotificationChart(true)
                }}
              >
                <BarChartLine className="mr-2" />
                Report
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="mt-2" xs={12} md={6}>
            <Row>
              <Col md={4}>
                <select
                  className="form-control-sm form-select"
                  name="recordType"
                  onChange={(e) => {
                    setSearchTerm('')
                    setSelectedRecordType(e.target.value || undefined)
                  }}
                  value={selectedRecordType}
                >
                  <option value="">Select Record Type</option>
                  {recordTypeOptions.map((recordType) => (
                    <option key={recordType} value={recordType}>
                      {recordType}
                    </option>
                  ))}
                </select>
              </Col>
              <Col xs={12} md={8}>
                <Form.Control
                  type="text"
                  name="searchTerm"
                  className="form-control-sm"
                  placeholder={searchText}
                  value={searchTerm}
                  disabled={!selectedRecordType}
                  onBlur={handleControlBlur}
                  onChange={handleSearchTermChange}
                />
              </Col>
            </Row>
            {loading && (
              <div
                id="globalSearchInfiniteTable"
                className="border"
                style={{ width: '62%' }}
              >
                <Loading height={'25'} width={'25'} />
              </div>
            )}
            {searchFilters.length > 0 ? (
              <>
                <div className="border">
                  <Row style={{ position: 'relative', marginTop: '0px' }}>
                    <Col
                      md={{ span: 8, offset: 4 }}
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: 0,
                        zIndex: 1050,
                        paddingLeft: 0,
                        paddingRight: 0,
                        backgroundColor: 'white',
                      }}
                    >
                      <SortableInfiniteTable
                        hideGlobalFilter
                        displayHeader={false}
                        tableData={searchFilters}
                        tableColumns={[
                          {
                            Header: 'Sort Name',
                            accessor: 'name',
                          },
                        ]}
                        tableHeight={200}
                        fetchMoreTableData={fetchMoreSearchTerms}
                        hasMoreTableData={hasMoreSearchFilters}
                        onRowClick={handleSearchFilterClick}
                        rowPointer
                        tableSize="lg"
                      />
                    </Col>
                  </Row>
                </div>
              </>
            ) : null}
            <Row>
              <Col md={12}>
                {Object.keys(appliedSearchFilters).length > 0 ? (
                  <div>
                    {Object.entries(appliedSearchFilters).map(
                      (appliedSearchFilter, index) => {
                        return (
                          <div
                            key={appliedSearchFilter[0]}
                            className="d-inline-block mt-2"
                          >
                            <small
                              style={{
                                padding: '5px',
                                border: '.5px solid #6c757d',
                                borderRadius: '0.25rem',
                              }}
                              className="rounded boarder mr-2 p-1 d-flex align-items-center"
                            >
                              <Trash
                                className="mr-2 btn-link"
                                onClick={() =>
                                  handleRemoveSearchFilterClick(
                                    appliedSearchFilter
                                  )
                                }
                              />
                              <span>{`${appliedSearchFilter[1].name}`} | </span>
                              <span className="ml-1">
                                <small>{appliedSearchFilter[1].nodeType}</small>
                              </span>
                            </small>
                          </div>
                        )
                      }
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={'auto'}>
            <DateFilter
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              placeholderStart="Sent From"
              placeholderEnd="Sent Until"
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NotificationsTableSearch
