import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col } from 'react-bootstrap'
import SortableInfiniteTable from '../../../common/SortableInfiniteTable'
import DateFilter from '../../../common/DateFilter'
import Loading from '../../../common/Loading'
import WebhookEventModal from './WebhookEventModal'
import { useDateTimeConverter } from '../../../../libs/useDateTime'

const WebhookEvents = (props) => {
  const { webhookId } = props
  const { toTimezone } = useDateTimeConverter()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [showWebhook, setShowWebhook] = useState()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query WebhookEvents(
        $cursor: String
        $startDateTimeGte: DateTime
        $startDateTimeLte: DateTime
        $webhook: ID
      ) {
        webhookEvents(
          first: 10
          after: $cursor
          orderBy: "-created"
          created_Gte: $startDateTimeGte
          created_Lte: $startDateTimeLte
          webhook: $webhook
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              recordId
              created
              responseCode
              responseBody
              webhookEvent
              webhookEventType
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      // pollInterval: 30000,
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {
        webhook: webhookId,
      }
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  const fetchMore = () => {
    const variables = {
      cursor: queryData.apiCalls.pageInfo.endCursor,
      webhook: webhookId,
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    queryFetchMore({
      variables,
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = {
        cursor: null,
        startDateTimeGte: startDateFilter,
        startDateTimeLte: endDateFilter,
        webhook: webhookId,
      }
      query({
        variables,
      })
    }
  }, [startDateFilter, endDateFilter])

  if (!initialQueryRun || !queryData?.webhookEvents)
    return <Loading message="Loading Webhook Events..." />
  if (queryError) return <>Error loading webhook events</>
  return (
    <>
      {showWebhook && (
        <WebhookEventModal
          webhookEvent={showWebhook}
          showModal={true}
          toggleModal={() => setShowWebhook()}
        />
      )}
      <Row>
        <Col md={'auto'} style={{ marginTop: '-8px' }}>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Sent From"
            placeholderEnd={'Sent Until'}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12} md={12}>
          <SortableInfiniteTable
            tableData={queryData.webhookEvents.edges}
            onRowClick={(row) => {
              setShowWebhook(row.original.node)
            }}
            tableColumns={[
              {
                Header: 'ID',
                id: 'id',
                accessor: 'node.recordId',
              },
              {
                Header: 'Event Type',
                id: 'eventType',
                accessor: 'node.webhookEventType',
              },
              {
                Header: 'Sent',
                id: 'sent',
                accessor: (row) => {
                  return toTimezone(row.node.created, {
                    standard: true,
                    settingTimezone: true,
                  })
                },
              },
              {
                Header: 'Response Status Code',
                id: 'responseCode',
                accessor: 'node.responseCode',
              },
            ]}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData.webhookEvents.pageInfo.hasNextPage}
            loadingMessage="Loading Webhook Events..."
            tableHeight={queryData.webhookEvents.edges.length < 20 ? 200 : 600}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
    </>
  )
}
export default WebhookEvents
