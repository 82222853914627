import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, gql, useReactiveVar, useMutation } from '@apollo/client'
import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  Table,
  Dropdown,
} from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import {
  QrCode,
  Trash,
  Funnel,
  PlusCircle,
  Dot,
  Upc,
  CloudArrowDown,
} from 'react-bootstrap-icons'
import ItemModal from './ItemModal'
import toast from 'react-hot-toast'
import DeleteItemModal from './DeleteItemModal'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { useDownloadFile } from '../../../libs/downloadFile'
import ScanModal from '../ScanModal'
import EmployeeAssignmentsModal from '../../schedule/job/EmployeeAssignmentsModal'
import { useDateTimeConverter } from '../../../libs/useDateTime'
import { formatRegion, formatTimezone } from '../../../libs/utils'
import RegionSearchInput from '../../common/node_search_input/RegionSearchInput'
import { useFormik } from 'formik'

const Items = (props) => {
  const { employeeId, employeeName, tableHeight, itemTypeId, itemTypeName } =
    props
  const { toTimezone } = useDateTimeConverter()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editItem, setEditItem] = useState()
  const [checkedIds, setCheckedIds] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [openScanModal, setOpenScanModel] = useState(false)
  const [scannedBags, setScannedBags] = useState([])
  const [scannedItems, setScannedItems] = useState([])
  const [filterReturned, setFilterReturned] = useState('all')
  const [filterPastDue, setFilterPastDue] = useState(false)
  const [employeeAssignmentsModal, setEmployeeAssignmentsModal] =
    useState(false)
  const [employeeFilter, setEmployeeFilter] = useState('Current')

  const { downloadFile, downloadAndDeleteFile } = useDownloadFile()
  const [downloadItems] = useMutation(
    gql`
      mutation DownloadEquipmentItems($input: DownloadEquipmentItemInput!) {
        downloadEquipmentItem(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadEquipmentItem?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadEquipmentItem.file.fileName,
            data.downloadEquipmentItem.file.displayName,
            data.downloadEquipmentItem.file.id,
            () => {
              setDownloading(false)
              toast.success('Items Downloaded')
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query EquipmentItems(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $itemTypeId: [ID]
        $currentAssignmentsEmployeeId: String
        $employeeAssignments: String
        $returned: Boolean
        $regionIds: [ID]
        $pastDue: Boolean
        $includeQrCode: Boolean!
        $includeBarcode: Boolean!
      ) {
        equipmentItems(
          first: 20
          after: $cursor
          returned: $returned
          search: $searchTerm
          orderBy: $orderBy
          region: $regionIds
          currentEmployeeAssignments: $currentAssignmentsEmployeeId
          employeeAssignments: $employeeAssignments
          pastDue: $pastDue
          equipmentItemType: $itemTypeId
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              metadata
              name
              equipmentBag {
                name
                id
                employee {
                  id
                }
              }
              notes
              serialNumber
              underRepair
              retire
              returned
              dueDate
              vendor
              contentType {
                model
                id
              }
              price
              employeeEmployeeJobs {
                job {
                  id
                  name
                  region {
                    timezone
                  }
                }
                startDateTime
                endDateTime
              }
              invoiceNumber
              ...qrCodeFields @include(if: $includeQrCode)
              ...barcodeFields @include(if: $includeBarcode)
              nextEmployeeJob {
                job {
                  id
                  name
                  region {
                    timezone
                  }
                  startDateTime
                }
                startDateTime
                endDateTime
                employee {
                  id
                  gaiaUser {
                    fullName
                  }
                }
              }
              equipmentBag {
                lastEmployeeJob {
                  job {
                    startDateTime
                    id
                    name
                  }
                  startDateTime
                  endDateTime
                  employee {
                    id
                    gaiaUser {
                      fullName
                    }
                  }
                }
                nextEmployeeJob {
                  job {
                    id
                    name
                    startDateTime
                  }
                  startDateTime
                  endDateTime
                  employee {
                    id
                    gaiaUser {
                      fullName
                    }
                  }
                }
              }
              lastEmployeeJob {
                job {
                  id
                  name
                }
                startDateTime
                endDateTime
                employee {
                  id
                  gaiaUser {
                    fullName
                  }
                }
              }
              equipmentItemType {
                id
                name
                miscellaneous
              }
              employee {
                gaiaUser {
                  fullName
                }
                id
              }
              region {
                id
                name
                timezone
              }
            }
          }
        }
      }

      fragment qrCodeFields on EquipmentItemNode {
        qrCodeFile {
          fileName
          displayName
          fileUrl
        }
        qrCodeLabelFile {
          fileName
          displayName
          fileUrl
        }
      }

      fragment barcodeFields on EquipmentItemNode {
        barcodeFile {
          fileName
          displayName
          fileUrl
        }
        barcodeLabelFile {
          fileName
          displayName
          fileUrl
        }
      }
    `,
    {
      fetchPolicy: itemTypeId ? 'no-cache' : 'network-only',
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: {
      regions: loggedInUser?.defaultRegions,
    },
    validateOnChange: false,
  })

  useEffect(() => {
    formik.setFieldValue('regions', loggedInUser?.defaultRegions)
  }, [loggedInUser?.defaultRegions])

  const determineReturnedVariable = () => {
    let returned
    if (filterReturned === 'checkedOut') {
      returned = false
    } else if (filterReturned === 'checkedIn') {
      returned = true
    }
    return returned
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = constructQueryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterReturned, employeeFilter])

  const constructQueryVariables = () => {
    const variables = {
      returned: determineReturnedVariable(),
      cursor: queryData?.equipmentItems?.pageInfo?.endCursor,
      includeQrCode: settings.equipmentQrCodes,
      includeBarcode: settings.equipmentBarcodes,
      itemTypeId: itemTypeId,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (formik.values.regions.length > 0) {
      variables.regionIds = formik.values.regions.map((region) => region.id)
    }
    if (employeeFilter === 'Current') {
      variables.currentAssignmentsEmployeeId = employeeId
    } else if (employeeFilter === 'All') {
      variables.employeeAssignments = employeeId
    }
    if (filterPastDue) {
      variables.pastDue = true
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: constructQueryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.equipmentItems) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  useEffect(() => {
    const variables = constructQueryVariables()
    variables.cursor = null
    if (filterPastDue) {
      variables.pastDue = true
    } else {
      variables.pastDue = null
    }
    query({ variables })
  }, [filterPastDue])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    queryFetchMore({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = constructQueryVariables()
    variables.cursor = null
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  useEffect(() => {
    setLoadingSearch(true)
    const variables = constructQueryVariables()
    variables.cursor = null
    query({ variables })
  }, [formik.values.regions])

  const toggleModal = (node = null) => {
    if (node) {
      setEditItem(node)
    } else {
      setEditItem()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const toggleScanModal = () => {
    setOpenScanModel((prevModal) => !prevModal)
    setScannedBags([])
    setScannedItems([])
  }

  const executeSearchQuery = useCallback(
    (searchValue) => {
      const variables = constructQueryVariables()
      variables.searchTerm = searchValue || undefined
      variables.cursor = null
      setLoadingSearch(true)
      query({ variables })
    },
    [constructQueryVariables, query]
  )

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value || undefined
    setSearchTerm(currentSearchTerm)

    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const newTimeout = setTimeout(() => {
      executeSearchQuery(currentSearchTerm)
    }, 500) // 1 second delay

    setDebounceTimeout(newTimeout)
  }

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [debounceTimeout])

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const getNearestFutureJob = (row) => {
    const directJobDateTime = row.node.nextEmployeeJob?.job?.startDateTime
    const bagJobDateTime =
      row.node.equipmentBag?.nextEmployeeJob?.job?.startDateTime
    if (directJobDateTime && !bagJobDateTime) {
      return row.node.nextEmployeeJob
    }
    if (!directJobDateTime && bagJobDateTime) {
      return row.node.equipmentBag.nextEmployeeJob
    }
    if (directJobDateTime && bagJobDateTime) {
      const directDate = new Date(directJobDateTime)
      const bagDate = new Date(bagJobDateTime)
      if (directDate < bagDate) {
        return row.node.nextEmployeeJob
      } else {
        return row.node.equipmentBag.nextEmployeeJob
      }
    }
    return null
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
      serverSort: true,
    },
    {
      Header: 'Serial Number',
      id: 'serialNumber',
      accessor: 'node.serialNumber',
      serverSort: true,
      orderBy: 'serial_number',
    },
    {
      Header: 'Item Type',
      id: 'itemType',
      accessor: (row) => {
        if (row.node.equipmentItemType) {
          if (row.node.equipmentItemType.miscellaneous) {
            return `${row.node.equipmentItemType.name} (misc)`
          } else {
            return row.node.equipmentItemType.name
          }
        }
      },
      serverSort: true,
      orderBy: 'equipment_item_type__name',
    },
    {
      Header: 'Checked In',
      id: 'returned',
      accessor: (row) => {
        return row.node.returned ? 'Yes' : 'No'
      },
      serverSort: true,
    },
    {
      Header: 'In Bag',
      id: 'inBag',
      accessor: (row) => {
        if (row.node.equipmentBag) {
          return row.node.equipmentBag.name
        }
      },
      serverSort: true,
      orderBy: 'equipment_bag__name',
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => {
        let status = ''
        if (row.node.underRepair) {
          status = 'Under Repair'
        } else if (row.node.retire) {
          status = 'Retired'
        } else if (row.node.employee || row.node.equipmentBag?.employee?.id) {
          status = 'Assigned'
        } else if (
          !row.node.underRepair &&
          !row.node.retire &&
          row.node.returned
        ) {
          status = 'Available'
        } else {
          status = 'Out'
        }
        return status
      },
    },
    {
      Header: 'Long Term Assignment',
      id: 'longTermAssignment',
      accessor: (row) => {
        if (row.node.employee) {
          return row.node.employee.gaiaUser.fullName
        }
      },
      serverSort: true,
      orderBy: 'employee',
    },
    {
      Header: 'Custom Fields',
      id: 'customFields',
      accessor: (row) => {
        const metadata = JSON.parse(row.node.metadata)
        if (Object.keys(metadata).length > 0) {
          return (
            <div
              style={{
                maxHeight: '75px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                fontSize: '12px',
              }}
              className="mt-2"
            >
              <Table className="compact-table table-bordered" responsive>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Field Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(metadata).map((key, i) => {
                    return (
                      <tr key={i}>
                        <td>{key}</td>
                        <td>{metadata[key]}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )
        }
      },
    },
    {
      Header: 'Next Job Assignment',
      id: 'nextJobAssignment',
      accessor: (row) => {
        if (
          row.node.nextEmployeeJob ||
          row.node.equipmentBag?.nextEmployeeJob
        ) {
          const nearestFutureJob = getNearestFutureJob(row)
          return (
            <div
              style={{ fontSize: '12px' }}
              className="btn-link"
              onClick={() => {
                setEmployeeAssignmentsModal(nearestFutureJob.job.id)
              }}
            >
              <Dot className="mr-2" />
              {nearestFutureJob.employee ? (
                <>{nearestFutureJob.employee.gaiaUser.fullName}</>
              ) : (
                <>Unassigned</>
              )}
              <br />
              <Dot className="mr-2" />
              {nearestFutureJob.job.name}
              <br />
              <Dot className="mr-2" />
              {toTimezone(nearestFutureJob.startDateTime, {
                humanReadable: true,
                timezone: formatTimezone(
                  nearestFutureJob?.job?.region?.timezone
                ),
              })}{' '}
              -{' '}
              {toTimezone(nearestFutureJob.endDateTime, {
                onlyTime: true,
                timezone: formatTimezone(
                  nearestFutureJob?.job?.region?.timezone
                ),
              })}
            </div>
          )
        }
      },
    },
  ]
  if (canMutate) {
    tableColumns.splice(9, 0, {
      Header: 'Download',
      id: 'downloads',
      accessor: (row) => {
        return (
          <>
            <Form.Group
              as={ButtonGroup}
              className="d-flex flex-column align-items-start"
              style={{ gap: '2px' }}
            >
              {settings?.equipmentQrCodes && (
                <Button
                  variant="link"
                  style={{ marginBottom: '-20px', marginTop: '-10px' }}
                  disabled={downloading}
                  onClick={() => {
                    setDownloading(true)
                    downloadFile(
                      row.node.qrCodeFile.fileName,
                      row.node.qrCodeFile.displayName,
                      () => {
                        setDownloading(false)
                        toast.success(`QR Code Downloaded`)
                      }
                    )
                  }}
                >
                  <span style={{ fontSize: '12px' }}>
                    <QrCode className="mr-1" /> QR Code
                  </span>
                </Button>
              )}
              {settings?.equipmentQrCodes && (
                <Button
                  style={{ marginBottom: '-20px' }}
                  variant="link"
                  disabled={downloading}
                  onClick={() => {
                    setDownloading(true)
                    downloadFile(
                      row.node.qrCodeLabelFile.fileName,
                      row.node.qrCodeLabelFile.displayName,
                      () => {
                        setDownloading(false)
                        toast.success(`QR Code Label Downloaded`)
                      }
                    )
                  }}
                >
                  <span style={{ fontSize: '12px' }}>
                    <QrCode className="mr-1" /> QR Code Label
                  </span>
                </Button>
              )}
              {settings?.equipmentBarcodes && (
                <>
                  <Button
                    variant="link"
                    style={
                      !settings?.equipmentQrCodes
                        ? {
                            marginBottom: '-20px',
                            marginLeft: '-4px',
                            marginTop: '-10px',
                          }
                        : { marginBottom: '-20px', marginLeft: '-4px' }
                    }
                    disabled={downloading}
                    onClick={() => {
                      setDownloading(true)
                      downloadFile(
                        row.node.barcodeFile.fileName,
                        row.node.barcodeFile.displayName,
                        () => {
                          setDownloading(false)
                          toast.success(`Barcode Downloaded`)
                        }
                      )
                    }}
                  >
                    <span style={{ fontSize: '12px' }}>
                      <Upc size={18} className="mr-1" /> Barcode
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    style={{ marginBottom: '-20px', marginLeft: '-4px' }}
                    disabled={downloading}
                    onClick={() => {
                      setDownloading(true)
                      downloadFile(
                        row.node.barcodeLabelFile.fileName,
                        row.node.barcodeLabelFile.displayName,
                        () => {
                          setDownloading(false)
                          toast.success(`Barcode Label Downloaded`)
                        }
                      )
                    }}
                  >
                    <span style={{ fontSize: '12px' }}>
                      <Upc size={18} className="mr-1" /> Barcode Label
                    </span>
                  </Button>
                </>
              )}
            </Form.Group>
          </>
        )
      },
    })
    tableColumns.splice(10, 0, {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.equipmentItems?.edges.forEach((equipmentItem) => {
                    if (!checkedIds.includes(equipmentItem.node.id)) {
                      appendIds.push(equipmentItem.node.id)
                    }
                  })
                  setCheckedIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedIds([])
                }
              }}
            />
            {checkedIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }
  if (employeeId) {
    if (employeeFilter === 'Current') {
      tableColumns.splice(8, 1)
      tableColumns.splice(8, 0, {
        Header: `Job Assignment`,
        id: 'jobAssignments',
        accessor: (row) => {
          const jobs = []
          if (
            row.node?.lastEmployeeJob?.employee?.id === employeeId &&
            !jobs.some((job) => job.id === row.node.lastEmployeeJob.job.id)
          ) {
            jobs.push(row.node.lastEmployeeJob)
          }

          if (
            row.node?.nextEmployeeJob?.employee?.id === employeeId &&
            !jobs.some((job) => job.id === row.node.nextEmployeeJob.job.id)
          ) {
            jobs.push(row.node.nextEmployeeJob)
          }
          if (
            row.node?.equipmentBag?.lastEmployeeJob?.employee?.id ===
              employeeId &&
            !jobs.some(
              (job) => job.id === row.node.equipmentBag.lastEmployeeJob.job.id
            )
          ) {
            jobs.push(row.node.equipmentBag.lastEmployeeJob)
          }

          if (
            row.node?.equipmentBag?.nextEmployeeJob?.employee?.id ===
              employeeId &&
            !jobs.some(
              (job) => job.id === row.node.equipmentBag.nextEmployeeJob.job.id
            )
          ) {
            jobs.push(row.node.equipmentBag.nextEmployeeJob)
          }
          if (jobs.length) {
            return (
              <>
                <div
                  style={{
                    maxHeight: '75px',
                    maxWidth: '300px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    fontSize: '12px',
                  }}
                  className="mt-2"
                >
                  <Table className="compact-table table-bordered" responsive>
                    <thead>
                      <tr>
                        <th>Job</th>
                        <th>Shift</th>
                      </tr>
                    </thead>
                    <tbody className="border">
                      {jobs.map((empJob, i) => {
                        const timezone = formatTimezone(
                          empJob?.job?.region?.timezone
                        )
                        return (
                          <tr
                            key={i}
                            onClick={() => {
                              setEmployeeAssignmentsModal(empJob.job.id)
                            }}
                            className="border"
                          >
                            <td className="btn-link">{empJob.job.name}</td>
                            <td className="btn-link">
                              {toTimezone(empJob.startDateTime, {
                                humanReadable: true,
                                timezone: timezone,
                              })}{' '}
                              -{' '}
                              {toTimezone(empJob.endDateTime, {
                                onlyTime: true,
                                timezone: timezone,
                              })}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </>
            )
          }
        },
      })
    } else if (employeeFilter === 'All') {
      tableColumns.splice(9, 0, {
        Header: `${employeeName} Job Assignments`,
        id: 'jobAssignments',
        accessor: (row) => {
          if (row.node.employeeEmployeeJobs.length > 0) {
            return (
              <>
                <div
                  style={{
                    maxHeight: '75px',
                    maxWidth: '300px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    fontSize: '12px',
                  }}
                  className="mt-2"
                >
                  <Table className="compact-table table-bordered" responsive>
                    <thead>
                      <tr>
                        <th>Job</th>
                        <th>Shift</th>
                      </tr>
                    </thead>
                    <tbody className="border">
                      {row.node.employeeEmployeeJobs.map((empJob, i) => {
                        const timezone = formatTimezone(
                          empJob?.job?.region?.timezone
                        )
                        return (
                          <tr
                            key={i}
                            onClick={() => {
                              setEmployeeAssignmentsModal(empJob.job.id)
                            }}
                            className="border"
                          >
                            <td className="btn-link">{empJob.job.name}</td>
                            <td className="btn-link">
                              {toTimezone(empJob.startDateTime, {
                                humanReadable: true,
                                timezone: timezone,
                              })}{' '}
                              -{' '}
                              {toTimezone(empJob.endDateTime, {
                                onlyTime: true,
                                timezone: timezone,
                              })}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </>
            )
          }
        },
      })
    }
  }

  if (
    loggedInUser?.canManageRegions &&
    settings?.tenantRegions &&
    formik?.values.regions.length > 1
  ) {
    tableColumns.splice(tableColumns.length - 1, 0, {
      Header: 'Region',
      id: 'region',
      accessor: (row) => <>{formatRegion(row.node.region)}</>,
      serverSort: true,
      orderBy: 'region__name',
    })
  }

  const CheckInOutFilter = () => (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="filter-dropdown">
        <Funnel className="mr-2" />
        {filterReturned === 'all' && !filterPastDue && <>Filter</>}
        {filterReturned === 'checkedOut' && !filterPastDue && <>Checked Out</>}
        {filterReturned === 'checkedIn' && !filterPastDue && <>Checked In</>}
        {filterPastDue && <>Past Due</>}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            setFilterReturned('all')
            setFilterPastDue(null)
          }}
        >
          All
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setFilterReturned('checkedOut')
            setFilterPastDue(null)
          }}
        >
          Checked Out
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setFilterReturned('checkedIn')
            setFilterPastDue(null)
          }}
        >
          Checked In
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setFilterReturned('all')
            setFilterPastDue(true)
          }}
        >
          Past Due
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="searchTerm"
                  className="form-control-sm"
                  placeholder={'Search Items'}
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Form.Group>
            </Col>
            {loggedInUser?.canManageRegions && settings?.tenantRegions && (
              <Col md={2}>
                <RegionSearchInput
                  formik={formik}
                  dropdown
                  placeholder="Select Region"
                  multiple
                  mustHaveValue={
                    loggedInUser.permissions.group === 'Administrator'
                      ? false
                      : true
                  }
                />
              </Col>
            )}
            <Col className="d-flex justify-content-end align-items-center">
              <Button
                variant="link"
                onClick={() => {
                  toggleModal()
                }}
              >
                <PlusCircle className="mr-2" />
                New Item
              </Button>
              {/* {employeeId && ( */}
              <>
                {employeeId && (
                  <Button variant="link" onClick={toggleScanModal}>
                    <PlusCircle className="mr-2" />
                    Assign
                  </Button>
                )}

                {employeeFilter == 'All' && <CheckInOutFilter />}
                {employeeId && (
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="filter-dropdown">
                      <Funnel className="mr-2" />
                      {employeeFilter}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setEmployeeFilter('All')}>
                        All Assignments
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setEmployeeFilter('Current')
                          setFilterPastDue(null)
                        }}
                      >
                        Current Assignments
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {checkedIds.length > 0 && (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="filter-dropdown">
                        <CloudArrowDown className="mr-2" />
                        Download
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {settings?.equipmentBarcodes && (
                          <Dropdown.Item
                            onClick={() => {
                              downloadItems({
                                variables: {
                                  input: {
                                    equipmentItemIds: checkedIds,
                                    type: 'barcodeLabels',
                                  },
                                },
                              })
                            }}
                          >
                            Barcode Labels
                          </Dropdown.Item>
                        )}
                        {settings?.equipmentQrCodes && (
                          <Dropdown.Item
                            onClick={() => {
                              downloadItems({
                                variables: {
                                  input: {
                                    equipmentItemIds: checkedIds,
                                    type: 'qrLabels',
                                  },
                                },
                              })
                            }}
                          >
                            QR Code Labels
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={() => {
                            setDownloading(true)
                            downloadItems({
                              variables: {
                                input: {
                                  equipmentItemIds: checkedIds,
                                  type: 'pdf',
                                },
                              },
                            })
                          }}
                        >
                          PDF
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setDownloading(true)
                            downloadItems({
                              variables: {
                                input: {
                                  equipmentItemIds: checkedIds,
                                  type: 'xlsx',
                                },
                              },
                            })
                          }}
                        >
                          Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button
                      variant="link"
                      onClick={() => {
                        setShowDeleteModal(true)
                      }}
                    >
                      <Trash className="mr-2" />
                      {checkedIds.length === 1 ? (
                        <>Delete Item</>
                      ) : (
                        <>Delete Items</>
                      )}
                    </Button>
                  </>
                )}
              </>
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col md="12">
              <SortableInfiniteTable
                tableData={
                  queryData?.equipmentItems?.edges
                    ? queryData?.equipmentItems?.edges
                    : []
                }
                loadingMessage="Loading Items..."
                tableColumns={tableColumns}
                loading={loadingSearch || !queryData}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={
                  queryData?.equipmentItems?.pageInfo?.hasNextPage
                }
                onTdClicks={{
                  longTermAssignment: (cell) => onTdClick(cell),
                  returned: (cell) => onTdClick(cell),
                  name: (cell) => onTdClick(cell),
                  itemType: (cell) => onTdClick(cell),
                  category: (cell) => onTdClick(cell),
                  status: (cell) => onTdClick(cell),
                  serialNumber: (cell) => onTdClick(cell),
                  inBag: (cell) => onTdClick(cell),
                  customFields: (cell) => onTdClick(cell),
                  region: (cell) => onTdClick(cell),
                }}
                tableHeight={tableHeight || 700}
                rowPointer
                hideGlobalFilter
                handleSortByChange={handleSortByChange}
              />
            </Col>
          </Row>
        </>
        {showForm ? (
          <ItemModal
            showModal={showForm}
            item={editItem}
            employeeId={employeeId}
            employeeName={employeeName}
            toggleModal={toggleModal}
            itemTypeId={itemTypeId}
            itemTypeName={itemTypeName}
          />
        ) : null}
        <DeleteItemModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedIds={setCheckedIds}
          ids={checkedIds.length > 1 ? checkedIds : null}
          id={checkedIds.length === 1 ? checkedIds[0] : null}
        />
        {openScanModal && (
          <ScanModal
            showModal={openScanModal}
            employeeId={employeeId}
            hideDropdown={true}
            toggleModal={toggleScanModal}
            setScannedBags={setScannedBags}
            scannedBags={scannedBags}
            setScannedItems={setScannedItems}
            scannedItems={scannedItems}
          />
        )}
        {employeeAssignmentsModal && (
          <EmployeeAssignmentsModal
            displayEquipment={true}
            showModal={true}
            toggleModal={setEmployeeAssignmentsModal}
            jobId={employeeAssignmentsModal}
          />
        )}
      </div>
    </>
  )
}
export default Items
