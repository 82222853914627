import React, { useState, useEffect } from 'react'
import { Trash, PlusCircle } from 'react-bootstrap-icons'
import { Form, Table, Button } from 'react-bootstrap'
import toast from 'react-hot-toast'

const MetaDataTable = (props) => {
  const { metaData, setMetaData, edit, noMessage, header, btnStyle, btnSize } =
    props

  // State to hold temporary key values for debouncing
  const [tempKeys, setTempKeys] = useState({})

  // Debounce timeout reference
  const [timeoutId, setTimeoutId] = useState(null)

  const addNewKeyPair = () => {
    let obj = { ...metaData }
    obj[''] = ''
    setMetaData(obj)
    setTempKeys((prev) => ({ ...prev, '': '' }))
  }

  const deleteObjectPair = (key) => {
    let obj = { ...metaData }
    delete obj[key]
    setMetaData(obj)
    setTempKeys((prev) => {
      const newTemp = { ...prev }
      delete newTemp[key]
      return newTemp
    })
  }

  const updateKey = (oldKey, newKey) => {
    if (newKey === oldKey) return

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    setTempKeys((prev) => ({ ...prev, [oldKey]: newKey }))

    const newTimeout = setTimeout(() => {
      if (newKey === '') return
      let obj = { ...metaData }
      if (Object.hasOwn(obj, newKey) && newKey !== oldKey) {
        deleteObjectPair(oldKey)
        toast.error('Duplicate Key Not Allowed')
      } else {
        Object.defineProperty(
          obj,
          newKey,
          Object.getOwnPropertyDescriptor(obj, oldKey)
        )
        delete obj[oldKey]
        setMetaData(obj)
        setTempKeys((prev) => {
          const newTemp = { ...prev }
          delete newTemp[oldKey]
          return newTemp
        })
      }
    }, 1000)
    setTimeoutId(newTimeout)
  }

  const updateValue = (key, value) => {
    let obj = { ...metaData }
    obj[key] = value
    setMetaData(obj)
  }

  return (
    <>
      {edit && (
        <Button variant="link" onClick={addNewKeyPair}>
          <PlusCircle
            size={btnSize ? btnSize : 16}
            style={{ cursor: 'pointer' }}
            className="mr-2"
          />
          <span style={btnStyle ? btnStyle : {}}>Add Custom Field</span>
        </Button>
      )}
      {Object.keys(metaData).length > 0 && (
        <Table borderless>
          <tbody>
            {header && (
              <tr>
                <td>Key</td>
                <td>Value</td>
              </tr>
            )}
            {Object.keys(metaData).map((key, index) => {
              // Use tempKeys for the input value if it exists, otherwise use the actual key
              const displayKey =
                tempKeys[key] !== undefined ? tempKeys[key] : key
              return (
                <tr key={index}>
                  <td>
                    {edit && (
                      <Form.Control
                        className="form-control-sm"
                        value={displayKey}
                        placeholder="Field Name"
                        onChange={(e) => updateKey(key, e.target.value)}
                      />
                    )}
                    {!edit && <p>{key}</p>}
                  </td>
                  <td>
                    {edit && (
                      <Form.Control
                        className="form-control-sm"
                        value={metaData[key]}
                        placeholder="Field Value"
                        onChange={(e) => updateValue(key, e.target.value)}
                      />
                    )}
                    {!edit && <p>{metaData[key]}</p>}
                  </td>
                  {edit && (
                    <td>
                      <Trash
                        className="btn-link"
                        onClick={() => deleteObjectPair(key)}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
      {Object.keys(metaData).length === 0 && !edit && (
        <>{!noMessage ? <p>- - -</p> : <p>No Custom Fields</p>}</>
      )}
    </>
  )
}

export default MetaDataTable
