import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Table, Form } from 'react-bootstrap'
import { ArrowLeftShort, Tag, Camera } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import {
  useLazyQuery,
  useMutation,
  gql,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { DateTime } from 'luxon'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { formatCurrency } from '../../libs/utils'
import Loading from '../common/Loading'
import { useHistory } from 'react-router-dom'
import { loggedInUserVar } from '../../libs/apollo'
import MapModal from '../location/MapModal'
import { useDateTimeConverter } from '../../libs/useDateTime'

const SessionConfirmation = (props) => {
  const {
    showModal,
    subjectGroupId,
    setJobId,
    setSessionStartDateTime,
    setBookingStage,
    jobId,
    sessionPackageId,
    isUpsellSelected,
    sessionStartDateTime,
    timezone,
    toggleModal,
    subjectId,
    previousSessionId,
    previousSessionPackageChange,
    previousSessionRescheduled,
    previousSessionResit,
    adminViewing,
    resitReason,
    stripeCard,
    organizationId,
    bookSessionFlow,
    packageCategoryId,
    reschedulingNoShow,
  } = props
  const history = useHistory()
  const { toTimezone } = useDateTimeConverter()
  const [stripeCardElementErrors, setStripeCardElementErrors] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [couponName, setCouponName] = useState('')
  const [couponUsablity, setCouponUsablity] = useState('')
  const [sessionPrice, setSessionPrice] = useState(null)
  const [showLocationModal, setShowLocationModal] = useState(null)
  const [couponSavings, setCouponSavings] = useState(null)
  const [stripeCardEmpty, setStripeCardEmpty] = useState(true)
  const [schedulingPolicies, setSchedulingPolicies] = useState()
  const [waiveBookingFee, setWaiveBookingFee] = useState(false)
  const [waiveRescheduleCancelFee, setWaiveRescheduleCancelFee] =
    useState(false)
  const [waiveNoShowFee, setWaiveNoShowFee] = useState(false)
  const [waiveRescheduleFee, setWaiveRescheduleFee] = useState(false)
  const [cancelReschedulePolicyChecked, setCancelReschedulePolicyChecked] =
    useState(false)
  const stripe = useStripe()
  const stripeElements = useElements()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { data: settingsData } = useQuery(
    gql`
      query SettingsQuery {
        settings {
          edges {
            node {
              id
              refundPolicy
              timeRefundFee
              resitFee
              freePackageResitFee
              chargeStateSalesTax
              timeRefundHours
              resitFeePaidSessions
              resitFeeFreeSessions
              timeRefundSessionPackageCost
              applyPolicyFree
              applyPolicyPaid
              applyNoShowPolicyPaid
              applyNoShowPolicyFree
              noShowFee
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [createSessionMutation] = useMutation(
    gql`
      mutation CreateSession($createSessionInput: CreateSessionInput!) {
        createSession(input: $createSessionInput) {
          session {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Session Booked')
        if (loggedInUser.permissions.isOrgContact) {
          history.push(`/subject/${subjectId}/contact`)
        } else if (bookSessionFlow && adminViewing) {
          history.push(`/subject/${subjectId}`)
        } else if (bookSessionFlow) {
          history.push('/subject/sessions')
        }
        if (toggleModal) {
          toggleModal()
        }
      },
      onError: (e) => {
        if (e.message.includes('card declined')) {
          toast.error('Payment Declined')
        } else if (e.message.includes('job time slot is fully booked')) {
          const sessionTime = toTimezone(sessionStartDateTime, {
            onlyDate: true,
            timezone: timezone,
          })
          toast.error(
            `${sessionTime} Was Recently Booked Please Choose Another Time`
          )
        } else if (e.message === 'session already exists') {
          toast.error(
            'You Have Already Booked This Session. Please Review Your Booked Sessions.'
          )
        } else if (e.message === 'payment method required') {
          toast.error('Payment Method Required')
        } else if (e.message === 'subject group session already exists') {
          toast.error(
            'A Session For This Group Already Exists. Please Review Your Booked Sessions.'
          )
        } else if (e.message.includes('Coupon with ID')) {
          toast.error('Coupon Cannot Be Applied')
        } else {
          toast.error('Error Booking Session')
        }
        setIsSubmitting(false)
      },
      refetchQueries: [
        'SubjectSessionsQuery',
        'SubjectGroupsNotBookedQuery',
        'StripePaymentIntentsQuery',
        'SubjectDetailQuery',
      ],
    }
  )
  const [previousSessionQuery, { data: previousSessionQueryData }] =
    useLazyQuery(
      gql`
        query PreviousSessionQuery($previousSessionId: ID!) {
          session(id: $previousSessionId) {
            startDateTime
            completed
            cancelRescheduleFee
          }
        }
      `,
      { fetchPolicy: 'network-only' }
    )

  const [sessionConfirmationQuery, { data: sessionConfirmationQueryData }] =
    useLazyQuery(
      gql`
        query SessionConfirmationQuery($jobId: ID!, $sessionPackageId: ID!) {
          job(id: $jobId) {
            location {
              fullAddress
              name
              salesTax
              latitude
              longitude
              addressLineOne
              addressLineTwo
              city
              zipCode
            }
            subjectNotes
          }
          sessionPackage(id: $sessionPackageId) {
            title
            price
            description
            durationHighMinutes
            durationLowMinutes
          }
        }
      `,
      { fetchPolicy: 'network-only' }
    )

  useEffect(() => {
    if (schedulingPolicies && jobId && sessionPackageId) {
      sessionConfirmationQuery({
        variables: {
          jobId,
          sessionPackageId,
          previousSessionId,
        },
      })
    }
  }, [jobId, sessionPackageId, schedulingPolicies])

  useEffect(() => {
    if (previousSessionId) {
      previousSessionQuery({
        variables: {
          previousSessionId,
        },
      })
    }
  }, [previousSessionId])

  const constructJobAddress = (job) => {
    let address
    if (!job.location.addressLineTwo) {
      address = `${job.location.addressLineOne}`
    } else {
      address = `${job.location.addressLineOne} ${job.location.addressLineTwo}`
    }
    return address
  }

  const handleChangeSessionTimeClick = () => {
    setJobId()
    setSessionStartDateTime()
    setBookingStage('session')
  }

  const [couponSavingQuery] = useLazyQuery(
    gql`
      query CouponSavingsQuery(
        $couponId: ID
        $couponName: String
        $amount: Float!
      ) {
        couponSavings(
          couponId: $couponId
          couponName: $couponName
          amount: $amount
        ) {
          originalAmount
          newAmount
          savings
        }
      }
    `,
    {
      onCompleted: (data) => {
        setCouponSavings(data.couponSavings.savings)
        toast.success('Coupon Applied')
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const cancelReschedulePolicy = () => {
    let cancelReschedulePolicy = null
    let schedulingPolicies
    if (subjectGroup) {
      schedulingPolicies = subjectGroup.schedulingPolicies
    } else {
      schedulingPolicies = settings
    }
    const refundPolicyRef = schedulingPolicies.refundPolicy
    if (refundPolicyRef === 'NEVER') {
      if (!schedulingPolicies.timeRefundSessionPackageCost) {
        if (
          (sessionPrice == 0 && schedulingPolicies.applyPolicyFree) ||
          (sessionPrice > 0 && schedulingPolicies.applyPolicyPaid)
        ) {
          cancelReschedulePolicy = `A $${schedulingPolicies.timeRefundFee} fee will be charged for cancelling or rescheduling.`
        } else {
          cancelReschedulePolicy =
            'Canceled and rescheduled sessions are refunded.'
        }
      } else if (sessionPrice == 0 && schedulingPolicies.applyPolicyFree) {
        cancelReschedulePolicy = `A $${schedulingPolicies.timeRefundFee} fee will be charged for cancelling or rescheduling the session.`
      } else if (sessionPrice > 0 && schedulingPolicies.applyPolicyPaid) {
        cancelReschedulePolicy = `The session package cost will not be refunded when cancelling or rescheduling.`
      } else {
        cancelReschedulePolicy =
          'Canceled and rescheduled sessions are refunded.'
      }
    } else if (refundPolicyRef === 'TIME') {
      if (schedulingPolicies.timeRefundSessionPackageCost) {
        if (
          (sessionPrice == 0 && schedulingPolicies.applyPolicyFree) ||
          (sessionPrice > 0 && schedulingPolicies.applyPolicyPaid)
        ) {
          cancelReschedulePolicy = `Sessions canceled or rescheduled less than ${
            schedulingPolicies.timeRefundHours
          } hours before they start are not refunded. If a free session is canceled or rescheduled less than ${
            schedulingPolicies.timeRefundHours
          } hours before it starts, a ${formatCurrency(
            schedulingPolicies.timeRefundFee
          )} fee is charged.`
        }
      } else {
        if (
          (sessionPrice == 0 && schedulingPolicies.applyPolicyFree) ||
          (sessionPrice > 0 && schedulingPolicies.applyPolicyPaid)
        ) {
          cancelReschedulePolicy = `If a session is changed, rescheduled, or cancelled less than ${
            schedulingPolicies.timeRefundHours
          } hours prior to its start, a fee of ${formatCurrency(
            schedulingPolicies.timeRefundFee
          )} will be charged. If the cost of the session exceeds ${formatCurrency(
            schedulingPolicies.timeRefundFee
          )}, the remaining balance will be refunded.`
        }
      }
    } else if (refundPolicyRef === 'ALWAYS') {
      cancelReschedulePolicy =
        'Canceled sessions are refunded and there is no fee to reschedule.'
    }
    if (
      schedulingPolicies.noShowFee &&
      ((sessionPrice == 0 && schedulingPolicies.applyNoShowPolicyFree) ||
        (sessionPrice > 0 && schedulingPolicies.applyNoShowPolicyPaid))
    ) {
      if (cancelReschedulePolicy) {
        cancelReschedulePolicy = `${cancelReschedulePolicy} A $${schedulingPolicies.noShowFee} fee will be charged if a session is missed.`
      } else {
        cancelReschedulePolicy = `A $${schedulingPolicies.noShowFee} fee will be charged if a session is missed.`
      }
    }
    if (
      sessionPrice >= 0 &&
      !schedulingPolicies.applyPolicyFree &&
      (!schedulingPolicies.applyNoShowPolicyFree ||
        !schedulingPolicies.noShowFee)
    ) {
      return <></>
    }
    return (
      <Row className="mt-3">
        <Col md={9}>
          <p className="mb-0 font-weight-bold small">
            Reschedule, No-Show, Change, and Cancellation Policy
          </p>
          <p>
            <small>{cancelReschedulePolicy}</small>
          </p>
          {!adminViewing && bill && (
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                style={{ verticalAlign: 'middle', marginRight: '5px' }}
                checked={cancelReschedulePolicyChecked}
                onChange={() =>
                  setCancelReschedulePolicyChecked(
                    !cancelReschedulePolicyChecked
                  )
                }
              />
              <span className="mb-0 font-weight-bold small">
                I Agree to the Terms of the Reschedule Cancellation Policy
              </span>
            </label>
          )}
        </Col>
      </Row>
    )
  }

  const [subjectGroupQuery, { data: subjectGroupData }] = useLazyQuery(
    gql`
      query SessionSubjectGroupQuery($id: ID!) {
        subjectGroup(id: $id) {
          name
          subjectsPaying
          organization {
            name
          }
          schedulingPolicies {
            resitsIncludeFreeSessions
            refundPolicy
            resitFee
            freePackageResitFee
            timeRefundSessionPackageCost
            timeRefundFee
            resitFeePaidSessions
            resitFeeFreeSessions
            timeRefundHours
            applyPolicyFree
            applyPolicyPaid
            applyNoShowPolicyFree
            applyNoShowPolicyPaid
            noShowFee
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  const [packageCategoryQuery, { data: packageCategoryData }] = useLazyQuery(
    gql`
      query packageCategory($id: ID!) {
        packageCategory(id: $id) {
          id
          archived
          created
          updated
          metadata
          name
          hidePromoCodes
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (subjectGroupId) {
      subjectGroupQuery({
        variables: {
          id: subjectGroupId,
        },
      })
    }
  }, [subjectGroupId])

  useEffect(() => {
    if (packageCategoryId) {
      packageCategoryQuery({
        variables: {
          id: packageCategoryId,
        },
      })
    }
  }, [packageCategoryId])

  const [subjectQuery, { data: subjectData }] = useLazyQuery(
    gql`
      query AdminSessionSubject($id: ID!) {
        subject(id: $id) {
          gaiaUser {
            fullName
            email
            dummyUsername
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (subjectId && bookSessionFlow) {
      subjectQuery({
        variables: {
          id: subjectId,
        },
      })
    }
  }, [subjectId, bookSessionFlow])

  const [couponUsableQuery] = useLazyQuery(
    gql`
      query CouponUsableQuery(
        $couponName: String
        $sessionPackageId: ID
        $organizationId: ID
        $subjectGroupId: ID
      ) {
        couponUsable(
          couponName: $couponName
          sessionPackageId: $sessionPackageId
          organizationId: $organizationId
          subjectGroupId: $subjectGroupId
        ) {
          coupon {
            id
          }
          usable
          details
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.couponUsable?.usable && sessionConfirmationQueryData) {
          const { sessionPackage } = sessionConfirmationQueryData
          setCouponUsablity(data.couponUsable)
          let couponId = data.couponUsable.coupon.id
          let amount = sessionPackage.price
          if (
            amount == 0 &&
            resitReason &&
            schedulingPolicies.freePackageResitFee > 0 &&
            resitReason.bill
          ) {
            amount += schedulingPolicies.freePackageResitFee
          }
          couponSavingQuery({
            variables: {
              couponId,
              couponName,
              amount,
            },
          })
        } else {
          toast.error('Invalid Coupon')
        }
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  const createSessionQueryVariables = async (handleStripe) => {
    let bill = billForSession()
    if (
      bill == true &&
      sessionTotal == 0 &&
      waiveNoShowFee &&
      waiveRescheduleCancelFee
    ) {
      bill = false
    }
    let variables
    let stripeError
    let stripePaymentMethod
    if (
      !(waiveBookingFee && waiveRescheduleCancelFee && waiveNoShowFee) &&
      handleStripe &&
      (!stripeCardEmpty || (bill && !stripeCard))
    ) {
      const cardElement = stripeElements.getElement(CardElement)
      const stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      stripeError = stripeResponse.error
      stripePaymentMethod = stripeResponse.paymentMethod
    } else {
      stripePaymentMethod = stripeCard
    }
    if (
      !(waiveBookingFee && waiveRescheduleCancelFee && waiveNoShowFee) &&
      handleStripe &&
      bill &&
      (stripeError || !stripePaymentMethod)
    ) {
      setStripeCardElementErrors(stripeError.message)
    } else {
      const endDateTimeMinutes = sessionPackage.durationHighMinutes - 1
      const endDateTime = DateTime.fromISO(sessionStartDateTime)
        .plus({ minutes: endDateTimeMinutes })
        .toISO()

      let bill_ = false
      if ((subjectGroup && subjectGroup?.subjectsPaying) || !subjectGroup) {
        bill_ = true
      }
      variables = {
        variables: {
          createSessionInput: {
            sessionInput: {
              packageCategoryId,
              startDateTime: sessionStartDateTime,
              billSubject: bill_,
              reschedulingNoShow: reschedulingNoShow,
              endDateTime,
              sessionPackageId,
              jobId,
              upsell: isUpsellSelected,
              subjectId,
              subjectGroupId,
              couponId: couponUsablity?.usable ? couponUsablity.coupon.id : '',
            },
          },
        },
      }
      if (previousSessionId) {
        variables.variables.createSessionInput.sessionInput.previousSessionId =
          previousSessionId
      }
      if (previousSessionRescheduled) {
        variables.variables.createSessionInput.sessionInput.previousSessionRescheduled = true
      } else if (previousSessionResit) {
        variables.variables.createSessionInput.sessionInput.previousSessionResit = true
        variables.variables.createSessionInput.sessionInput.previousSessionResitReasonId =
          resitReason.id
      } else if (previousSessionPackageChange) {
        variables.variables.createSessionInput.sessionInput.previousSessionPackageChange = true
      }
      variables.variables.createSessionInput.sessionInput.waiveBookingFee =
        waiveBookingFee
      variables.variables.createSessionInput.sessionInput.waiveRescheduleCancelFee =
        waiveRescheduleCancelFee
      variables.variables.createSessionInput.sessionInput.waiveNoShowFee =
        waiveNoShowFee
      variables.variables.createSessionInput.sessionInput.oneTimeWaiveRescheduleFee =
        waiveRescheduleFee
    }
    return {
      variables,
      stripePaymentMethod,
      stripeError,
    }
  }
  const handleSessionConfirmationClick = async () => {
    let bill = billForSession()
    if (
      bill == true &&
      sessionTotal == 0 &&
      waiveNoShowFee &&
      waiveRescheduleCancelFee
    ) {
      bill = false
    }
    if (
      !adminViewing &&
      !cancelReschedulePolicyChecked &&
      subjectGroup?.subjectsPaying &&
      bill
    ) {
      toast.error(
        'Please Confirm The Rescheduled And Cancellation Policy Before Booking'
      )
    } else {
      setIsSubmitting(true)
      const { variables, stripePaymentMethod, stripeError } =
        await createSessionQueryVariables(true)
      if (
        bill &&
        (stripeError || !stripePaymentMethod) &&
        !(waiveBookingFee && waiveRescheduleCancelFee && waiveNoShowFee)
      ) {
        setIsSubmitting(false)
        toast.error('Card Required')
      } else {
        if (bill) {
          variables.variables.createSessionInput.sessionInput.stripePaymentMethodId =
            stripePaymentMethod.id
        }
        createSessionMutation(variables)
      }
    }
  }

  const promoCodeHandler = () => {
    if (couponName) {
      let variables = {
        sessionPackageId: sessionPackageId,
        couponName: couponName,
      }
      if (organizationId) {
        variables.organizationId = organizationId
      }
      if (subjectGroupId) {
        variables.subjectGroupId = subjectGroupId
      }
      couponUsableQuery({
        variables,
      })
    } else {
      toast.error('Coupon Required')
    }
  }
  const billForSession = () => {
    let bill = false
    if (subjectId) {
      if ((subjectGroup && subjectGroup?.subjectsPaying) || !subjectGroup) {
        bill = true
      }
      let schedulingPolicies
      if (subjectGroup) {
        schedulingPolicies = subjectGroup.schedulingPolicies
      } else {
        schedulingPolicies = settings
      }
      const refundPolicyRef = schedulingPolicies.refundPolicy
      if (
        bill &&
        refundPolicyRef === 'ALWAYS' &&
        (!schedulingPolicies.noShowFee || schedulingPolicies.noShowFee === 0)
      ) {
        bill = false
      }
      if (
        !bill &&
        sessionPrice > 0 &&
        ((subjectGroup && subjectGroup?.subjectsPaying) || !subjectGroup)
      ) {
        bill = true
      }
    }
    return bill
  }

  const subjectPayingForSession = () => {
    let bill = false
    if (subjectId) {
      if ((subjectGroup && subjectGroup?.subjectsPaying) || !subjectGroup) {
        bill = true
      }
    }
    return bill
  }

  useEffect(() => {
    if (sessionConfirmationQueryData?.sessionPackage) {
      if (!previousSessionRescheduled) {
        if (
          resitReason?.bill &&
          sessionConfirmationQueryData.sessionPackage.price === 0 &&
          schedulingPolicies.freePackageResitFee &&
          schedulingPolicies.freePackageResitFee > 0
        ) {
          setSessionPrice(schedulingPolicies.freePackageResitFee)
        } else {
          setSessionPrice(sessionConfirmationQueryData.sessionPackage.price)
        }
      } else {
        setSessionPrice(0)
      }
    }
  }, [sessionConfirmationQueryData])

  useEffect(() => {
    if ((!subjectId && settingsData) || (settingsData && !subjectGroupId)) {
      setSchedulingPolicies(settingsData.settings.edges[0].node)
    } else if (subjectGroupId && subjectGroupData?.subjectGroup) {
      setSchedulingPolicies(subjectGroupData.subjectGroup.schedulingPolicies)
    }
  }, [subjectGroupData, subjectGroupId, settingsData])

  if (
    !settingsData ||
    !showModal ||
    !sessionConfirmationQueryData ||
    (previousSessionId && !previousSessionQueryData) ||
    (subjectGroupId && !subjectGroupData) ||
    (bookSessionFlow && subjectGroupId && !subjectGroupData) ||
    (bookSessionFlow && !subjectData) ||
    !schedulingPolicies ||
    sessionPrice == undefined ||
    !stripeElements
  )
    return <></>

  const settings = settingsData.settings.edges[0].node
  const { job, sessionPackage } = sessionConfirmationQueryData
  let subjectGroup
  if (subjectGroupData) {
    subjectGroup = subjectGroupData.subjectGroup
  }
  const bill = billForSession()
  const subjectPaying = subjectPayingForSession()
  let rescheduleFee = 0
  if (
    (previousSessionPackageChange || previousSessionRescheduled) &&
    previousSessionQueryData.session.cancelRescheduleFee &&
    !reschedulingNoShow
  ) {
    rescheduleFee = previousSessionQueryData.session.cancelRescheduleFee
  }
  let resitFee = 0
  if (
    resitReason?.bill &&
    ((sessionPackage.price > 0 && schedulingPolicies.resitFeePaidSessions) ||
      (sessionPackage.price === 0 && schedulingPolicies.resitFeeFreeSessions))
  ) {
    resitFee += schedulingPolicies.resitFee
  }

  let showPromoCodes = false
  if (sessionPackage.price > 0 || resitFee > 0) {
    if (loggedInUser.permissions.isEmployee) {
      showPromoCodes = true
    } else if (
      packageCategoryData &&
      !packageCategoryData.packageCategory.hidePromoCodes
    ) {
      showPromoCodes = true
    } else if (
      subjectGroupData &&
      !subjectGroupData.subjectGroup.hidePromoCodes
    ) {
      showPromoCodes = true
    }
  }

  let showSubtotal = false
  let sessionTotal = sessionPrice
  if (couponSavings) {
    showSubtotal = true
    sessionTotal -= couponSavings
  }
  if (resitFee) {
    showSubtotal = true
    sessionTotal += resitFee
  }

  if (rescheduleFee) {
    showSubtotal = true
    sessionTotal += rescheduleFee
  }

  let salesTax = 0
  if (settings.chargeStateSalesTax) {
    showSubtotal = true
    salesTax = sessionTotal * job.location.salesTax
    sessionTotal = sessionTotal + salesTax
  }

  return (
    <>
      {showLocationModal && (
        <MapModal
          location={showLocationModal}
          showModal={showLocationModal}
          toggleModal={setShowLocationModal}
        />
      )}
      <div className="confirmSession">
        {bookSessionFlow && (
          <Row className="mb-3">
            <Col>
              <p className="mb-0 font-weight-bold small">Subject</p>
              <small>
                {subjectData.subject.gaiaUser.fullName}{' '}
                {!subjectData.subject.gaiaUser.dummyUsername && (
                  <>- {subjectData.subject.gaiaUser.email}</>
                )}
              </small>
            </Col>
          </Row>
        )}
        {bookSessionFlow && subjectGroupId && (
          <Row className="mb-3">
            <Col>
              <p className="mb-0 font-weight-bold small">Booking For</p>
              <small>{subjectGroupData.subjectGroup.name}</small>
            </Col>
          </Row>
        )}
        {bookSessionFlow ? null : (
          <>
            <Row>
              <Col>
                <button
                  type="button"
                  onClick={() => handleChangeSessionTimeClick()}
                  className="p-0 m-0 d-flex align-items-center btn-link"
                >
                  <ArrowLeftShort /> <span>Back</span>
                </button>
              </Col>
            </Row>
            <Row className="mb-4 mt-3">
              <Col>
                <h5 className="mb-0">Confirm Session</h5>
              </Col>
            </Row>
          </>
        )}
        <>
          <Row>
            <Col>
              <p className="mb-0 font-weight-bold small">
                Appointment Location
              </p>
              <small
                className="btn-link"
                onClick={() => setShowLocationModal(job.location)}
              >
                {job.location.fullAddress}
              </small>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0 font-weight-bold small">Date & Time</p>
              <small>
                {toTimezone(sessionStartDateTime, {
                  humanReadable: true,
                  timezone: timezone,
                })}
              </small>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0 font-weight-bold small">
                {sessionPackage.title} Session Package,{' '}
                {sessionPackage.durationLowMinutes} -{' '}
                {sessionPackage.durationHighMinutes} minutes
              </p>
              <small>{sessionPackage.description}</small>
            </Col>
          </Row>
        </>
        {cancelReschedulePolicy()}
        {job?.subjectNotes && (
          <Row className="mt-2">
            <Col>
              <p className="mb-0 font-weight-bold small">Notes</p>
              <small>{job?.subjectNotes}</small>
            </Col>
          </Row>
        )}
        {showPromoCodes &&
          (!subjectGroup ||
            subjectGroup.subjectsPaying ||
            loggedInUser?.permissions?.isEmployee) &&
          sessionPrice > 0 && (
            <>
              <Row className="mt-2">
                <Col>
                  <p className="mb-0 font-weight-bold small">
                    Coupon
                    <button
                      type="button"
                      className="p-0 mr-1 ml-1 btn-link"
                      onClick={() => promoCodeHandler()}
                    >
                      <small className="ml-1">
                        <Tag className="mr-2" />
                        Apply
                      </small>
                    </button>
                  </p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex">
                  <div className="d-flex flex-column">
                    <input
                      className="form-control-sm mt-1"
                      type="text"
                      style={{ border: '1px solid #ccc' }}
                      name="couponName"
                      onChange={(e) => setCouponName(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        {sessionTotal > 0 &&
          (!subjectGroup ||
            subjectGroup.subjectsPaying ||
            loggedInUser?.permissions?.isEmployee) && (
            <>
              <Row className="mt-3">
                <Col
                  md={4}
                  className="d-flex justify-content-between mb-2 flex-column"
                >
                  <Table size="sm" style={{ width: '250px' }}>
                    <tbody>
                      {showSubtotal && (
                        <tr>
                          <td style={{ width: '100px' }}>
                            <small>Session Package</small>
                          </td>
                          <td className="text-end" style={{ width: '100px' }}>
                            <small>
                              {sessionPrice.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {couponSavings && (
                        <tr>
                          <td>
                            <small>Coupon</small>
                          </td>
                          <td className="text-end">
                            <small>
                              -{' '}
                              {couponSavings.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {rescheduleFee > 0 && (
                        <tr>
                          <td>
                            <small>Reschedule Fee</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {' '}
                              {rescheduleFee.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {resitFee > 0 && (
                        <tr>
                          <td>
                            <small>Resit Fee</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {' '}
                              {resitFee.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      {settings.chargeStateSalesTax && (
                        <tr>
                          <td>
                            <small>Tax</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {salesTax.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="table-success">
                          <small>Total</small>
                        </td>
                        <td className="text-end">
                          <small>
                            {sessionTotal.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </small>
                        </td>
                      </tr>
                      {!subjectPaying && (
                        <tr>
                          <td className="table-success">
                            <small>Billing</small>
                          </td>
                          <td className="text-end">
                            <small>{subjectGroup?.organization?.name}</small>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          )}
        {bill && (
          <>
            {stripeCard && (
              <>
                <Row className="mt-3">
                  <Col xs={12} md={{ span: 8, offset: 0 }}>
                    <p className="mb-0 font-weight-bold small">
                      Charge Card on File
                    </p>
                    <span className="text-uppercase mr-2 font-weight-bold">
                      {stripeCard.card.brand.charAt(0).toUpperCase() +
                        stripeCard.card.brand.slice(1)}
                    </span>
                    <span className="mr-2">
                      ending in {stripeCard.card.last4}
                    </span>
                    <br />
                  </Col>
                </Row>
              </>
            )}
            <Row className="mt-3 mb-1">
              <Col xs={12} md={{ span: 8, offset: 0 }}>
                {stripeCard && (
                  <>
                    <p className="mb-0 font-weight-bold small">
                      Or Add New Card
                    </p>
                  </>
                )}
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '18px',
                      },
                    },
                  }}
                  onChange={(event) => setStripeCardEmpty(event.empty)}
                />
                {stripeCardElementErrors ? (
                  <Row className="mt-3">
                    <Col xs={12} md={{ span: 8 }}>
                      <p style={{ color: '#dc3545' }}>
                        {stripeCardElementErrors}
                      </p>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            {sessionTotal == 0 && (
              <small className="mt-5">
                You will not be billed to book the session, but payment
                information is required to reserve the appointment. Failure to
                adhere to our Reschedule and Cancellation Policy will result in
                a fee being charged.
              </small>
            )}
          </>
        )}
        {adminViewing && loggedInUser.permissions.isEmployee && (
          <div
            className={'mt-4 mb-3'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              gap: '10px',
            }}
          >
            {sessionTotal > 0 && (
              <span
                onClick={() => setWaiveBookingFee(!waiveBookingFee)}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                }}
              >
                <Form.Check
                  type="switch"
                  checked={waiveBookingFee}
                  label={
                    <span style={{ marginTop: '2px', display: 'block' }}>
                      Waive Booking Fee
                    </span>
                  }
                  style={{
                    fontSize: '.8rem',
                    color: '#333',
                  }}
                />
              </span>
            )}
            <span
              onClick={() =>
                setWaiveRescheduleCancelFee(!waiveRescheduleCancelFee)
              }
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
              }}
            >
              <Form.Check
                type="switch"
                checked={waiveRescheduleCancelFee}
                label={
                  <span style={{ marginTop: '2px', display: 'block' }}>
                    Waive Reschedule Cancellation Fee
                  </span>
                }
                style={{
                  fontSize: '.8rem',
                  color: '#333',
                }}
              />
            </span>
            {rescheduleFee > 0 && (
              <span
                onClick={() => setWaiveRescheduleFee(!waiveRescheduleFee)}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                }}
              >
                <Form.Check
                  type="switch"
                  checked={waiveRescheduleFee}
                  label={
                    <span style={{ marginTop: '2px', display: 'block' }}>
                      Waive Reschedule Fee Once
                    </span>
                  }
                  style={{
                    fontSize: '.8rem',
                    color: '#333',
                  }}
                />
              </span>
            )}
            <span
              onClick={() => setWaiveNoShowFee(!waiveNoShowFee)}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
              }}
            >
              <Form.Check
                type="switch"
                checked={waiveNoShowFee}
                label={
                  <span style={{ marginTop: '2px', display: 'block' }}>
                    Waive No Show Fee
                  </span>
                }
                style={{
                  fontSize: '.8rem',
                  color: '#333',
                }}
              />
            </span>
          </div>
        )}
        <Row
          className={
            !adminViewing && !loggedInUser.permissions.isEmployee
              ? isSubmitting
                ? 'mt-3'
                : 'mt-5'
              : ''
          }
        >
          <Col md={12}>
            <Button
              block
              onClick={() => handleSessionConfirmationClick()}
              variant="outline-primary"
              className="my-3"
              disabled={isSubmitting}
            >
              <Camera className="mr-2" />
              Schedule
            </Button>
          </Col>
        </Row>
        {isSubmitting ? <Loading /> : null}
      </div>
    </>
  )
}

export default SessionConfirmation
